<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="title">
            Check <span *ngIf="data?.isRoleBack">Role Back</span> Stock Balance
        </div>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="onClose()">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content">
        <div class="form-contain">
            <div class="aside-warning" style="flex-wrap: wrap;" *ngIf="!data?.isValidDate">
                <i class="material-icons">warning</i>
                <span>
                    Application Date is not valid.
                </span>
            </div>

            <div class="not-available" *ngIf="!canSubmit || !data?.isValidDate">
                Stock not available!
            </div>
            <h4>CO Material</h4>
            <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                    <thead class="table-header">
                        <tr class="table-row">
                            <th class="table-col center">No.</th>
                            <th class="table-col center">Material Code</th>
                            <th class="table-col center">Material Name</th>
                            <!-- <th class="table-col center">UOM</th> -->
                            <th class="table-col center">{{data?.appType?.text}}</th>
                            <th class="table-col center">Current Stock</th>
                            <th class="table-col center">Balance</th>
                        </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="materials?.length>0">
                        <tr class="table-row" *ngFor="let item of materials;let i=index">
                            <td class="table-col"><span class="dot-status"
                                    [ngClass]="item?.validStock?'s1':'s4'"></span>{{i+1}}</td>
                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                            <!-- <td class="table-col"></td> -->
                            <td class="table-col">
                                {{item?.totalQty | number : '.2-2'}}
                                {{item?.uomRef|docRef:'code'|async}}
                            </td>
                            <td class="table-col">{{item?.stockQty | number : '.2-2'}}</td>
                            <td class="table-col">{{item?.balance | number : '.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="flex1"></div>
        <a mat-dialog-close class="enbutton cancel m-r16" mat-button (click)="onClose()">Cancel</a>
        <!-- *ngIf="canSubmit && data?.isValidDate" -->
        <button type="submit" class="enbutton primary" mat-raised-button (click)="onSubmit()"
            color="warn">Confirm</button>
        <!-- <button type="submit" class="enbutton warn" *ngIf="!canSubmit || !data?.isValidDate" mat-raised-button
            (click)="onClose()" color="warn">Close</button> -->
    </div>
</div>