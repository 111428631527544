import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-item-list-input',
  templateUrl: './item-list-input.component.html',
  styleUrls: ['./item-list-input.component.scss']
})
export class ItemListInputComponent implements OnInit, AfterViewInit {
  filterData: any;
  @ViewChild('focusInput1', { static: true }) inputEl1: ElementRef;
  @ViewChild('focusInput2', { static: true }) inputEl2: ElementRef;
  @ViewChild('focusInput3', { static: true }) inputEl3: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ItemListInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.filterData = this.data.filterData;
    this.dialogRef.updatePosition({
      top: `${this.filterData.top}px`,
      left: `${this.filterData.left}px`
    })

  }
  ngAfterViewInit() {
      switch (this.data.click) {
        case 1:
          this.inputEl1.nativeElement.focus();
          break;
        case 2:
          this.inputEl2.nativeElement.focus();
          break;
        case 3:
          this.inputEl3.nativeElement.focus();
          break;
        default:
          break;
      }
   
  }

}
