import { Component, OnInit, Inject } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { SubmitDetail } from 'src/app/stores/submit-detail.store';
import { ConvertService } from 'src/app/services/convert.service';
import { cancelCoOption, submitFormOption } from 'src/app/dummy/status';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-submit-application-print',
  templateUrl: './submit-application-print.component.html',
  styleUrls: ['./submit-application-print.component.scss']
})
export class SubmitApplicationPrintComponent implements OnInit {
  submitItem = [];
  printDate:Date;
  loading;
  COLUMNS: any = null;
  detailData;
  dataUOM;

  selectedItem: any;
  cancelCoPrintLists = cancelCoOption;

  submitFormPrintLists = submitFormOption;
  selectedTemplate = null
  slComment = 'យោបល់នាយកដ្ឋាន'
  slCommentLists = [
    'យោបល់នាយកដ្ឋាន',
    'យោបល់លោកប្រធាននាយកដ្ឋាន',
    'យោបល់ឯកឧត្តមប្រធាននាយកដ្ឋាន',
  ]
  constructor(
    public dialogRef: MatDialogRef<SubmitApplicationPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps: PrintService,
    public auth: Auth,
    public detail: SubmitDetail,
    private store:SettingStore

  ) { }
  slCommentChange(c){
    this.slComment = c
  }
  ngOnInit() {
    if(this.data?.printTemplateData){
      this.selectedTemplate =  this.data?.printTemplateData[0];
    }

    if(this.data.collection == 'register_submit_form'){
      this.selectedItem = this.submitFormPrintLists[0];
    }else{
      this.selectedItem = this.cancelCoPrintLists[0];
    }
    this.auth.fetchColumns('Submit_Column_Print', doc => {
      if (doc) {
        this.COLUMNS = doc;
        this.loading = false;
      }
    })
    this.printDate = new Date();
    const registerCoItems = this.data.item.registerCoItems;
    this.dataUOM = registerCoItems?registerCoItems[0] : null;

    this.submitItem = ConvertService.orderBy(registerCoItems, 'created_at');
    // this.detailData = ConvertService.orderBy(this.submitItem, 'created_at');
    const b = 12 - this.submitItem.length;
    if (this.submitItem.length <= 12) {
      for (let i = 0; i < b; i++) {
        this.submitItem.push({
          pushFeild1: null,
          pushFeild2: null,
          pushFeild3: null,
        })
      }
    } else {
      this.submitItem = ConvertService.orderBy(registerCoItems, 'created_at');
    }
  }
  onSelectTemplate(item){
    this.selectedTemplate = item;
  }
  _onSelectedItem(item){
    this.selectedItem = item;
  }
  close(){
    this.dialogRef.close()
  }
  print() {
    this.ps.print('#printms', 'a4');
    this.store.printHistory(this.data?.item, this.auth.user, success=>{})

  }
}
