import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-latter-preview',
  templateUrl: './latter-preview.component.html',
  styleUrls: ['./latter-preview.component.scss']
})
export class LatterPreviewComponent implements OnInit {
  printDate: Date;

  constructor(
    public dialogRef: MatDialogRef<LatterPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps: PrintService,
    public auth: Auth,
  ) { }

  ngOnInit(): void {
    this.printDate = new Date();
  }

  print() {
    this.ps.print('#printms', 'a4');
  }

}
