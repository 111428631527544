import { Pipe, PipeTransform } from '@angular/core';
import { driveObj } from '../dummy/status';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(size: any, ...args: any[]): any {
    if (size) {
      const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
      let j = 0;
      while (size > 900) { size /= 1024; j++; };
      const exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[j];
      return exactSize
    }
    return null;
  }

}
