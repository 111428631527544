import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { statusLists, appPrintTypes } from 'src/app/dummy/status';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-dynamic-print-option',
  templateUrl: './dynamic-print-option.component.html',
  styleUrls: ['./dynamic-print-option.component.scss']
})
export class DynamicPrintOptionComponent implements OnInit {

  @ViewChild('focusInput') inputEl: ElementRef;

  status_lists = statusLists
  form: FormGroup;
  title: AbstractControl;
  company_en: AbstractControl;
  company_kh: AbstractControl;
  address_en: AbstractControl;
  address_kh: AbstractControl;
  status: AbstractControl;
  application_type: AbstractControl;
  processNew: boolean = false;
  processClose: boolean = false;

  error: any = null;
  isCreate: boolean = false;
  ITEM;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public store: SettingStore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public ds: DataService,
    public dialogRef: MatDialogRef<DynamicPrintOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      company_en: [null, Validators.required],
      company_kh: [null, Validators.required],
      address_en: [null, Validators.required],
      address_kh: [null, Validators.required],
    })

    this.store.fetchPrintOption(this.auth.selectedCompany.key, doc => {
      this.ITEM = doc;
      const { name_en, name_kh, address } = this.auth.selectedCompany;
      const { company_en, company_kh, address_en, address_kh } = this.ITEM || {};
      this.form.patchValue({
        company_en: company_en || name_en,
        company_kh: company_kh || name_kh,
        address_en: address_en || address,
        address_kh: address_kh || this.auth.selectedCompany.address_kh,
      })
    })
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.key === o2.key : o1 === o2;
  }


  goBack() {
    this.dialogRef.close()
  }
  onSave(f: any) {
    if (this.form.valid) {
      const { company_en, company_kh, address_kh, address_en } = f;

      const item: any = {
        company_en: company_en,
        company_kh: company_kh,
        address_kh: address_kh,
        address_en: address_en,
      }
      this.store.addOptionPrint(item, this.auth.selectedCompany.key, (success, res) => {
        this.snackBar.open("Print header footer has been added successful.", "Done", { duration: 2500 });
        this.dialogRef.close();
      })
    }
  }

}
