import { Pipe, PipeTransform } from '@angular/core';
// import { formatNumber } from '../services/convert.service';

@Pipe({
  name: 'numFormat'
})
export class numFormatPipe implements PipeTransform {

  transform(number: any ): any {
    return null
  }

  // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}
