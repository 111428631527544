import { APPS } from './../../dummy/config';
import { DataService } from '../../services/data.service';
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { pushToArray, ConvertService, pushToObject, difference, generateKeywords } from 'src/app/services/convert.service';
import * as firebase from 'firebase/app';
import { IHSCODE, letterOption } from 'src/app/interfaces/settings';
import { MappingService, stockConverter, toNull } from 'src/app/services/mapping.service';
import { AuditType, REGISTER_ITEM_STATUS_OBJ } from 'src/app/dummy/status';
import { AngularFireStorage } from '@angular/fire/storage';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinct, map, shareReplay } from 'rxjs/operators';
import 'rxjs/add/observable/of';

@Injectable({ providedIn: 'root' })
export class SettingStore {
  @observable data: Array<any> = [];
  @observable auditData: Array<any> = [];
  @observable items: Array<any> = [];

  @observable singleData: any;
  @observable process: boolean = false;
  @observable deleteProcess: boolean = false;
  @observable loading: boolean = true;
  @observable empty: boolean = false;
  @observable totalPage: number = 1;
  @observable totalSize: number = 0;
  @observable totalRow: number = 0;
  @observable pageIndex: number = 1;
  @observable coFomData = [];
  @observable departmentData = [];
  @observable countryData = [];
  @observable companyData = [];


  @observable orderBy: any = "code";
  @observable filterStatus: any = 0;
  @observable registerStatus: any = null;
  @observable companyKey: any = null;
  @observable statusKey: any = null;
  @observable typeKey: any = null;


  @observable lastVisible: any = null;
  @observable firstVisible: any = null;
  @observable startIndex: number = 0;
  @observable startNo: number = 0;
  @observable appSize: number = 100;
  @observable fetching: boolean = false;
  @observable done: boolean = false;
  uploads: any[];
  allPercentage: Observable<any>;


  @observable fetchRegisterListingRef: Subscription = null;
  @observable fetchRegisterInfoListingRef: Subscription = null;
  @observable lazyRegisterOutstandingRef: Subscription = null;
  @observable fetchRegisterListingOutstandingStockRef: Subscription = null;


  infiniteOut: Observable<any[]>;
  infinite: Observable<any[]>;
  infiniteAudit: Observable<any[]>;
  constructor(
    private ds: DataService,
    private storage: AngularFireStorage,
  ) {
    // this.reInit()
  }


  @action
  printHistory(header: any, user: any, callback) {
    const batch = this.ds.batch();
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const auditData = {
      olddata: {
        ...header
      },
      companyKey: header?.companyKey || null,
      register_status: header?.register_status || null,
      updated_by: header.updated_by ? header.updated_by : null,
      created_by: header.created_by ? header.created_by : null,
      deleted_by: user,
      print_by: user,
      audit_type: AuditType.print,
      audit_date: new Date(),
      appKey: header.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  infiniteData(data) {
    this.infiniteOut = Observable.of(data).pipe(
      distinct(p => p.key),
    )

  }

  infiniteAuditData(data) {
    this.infiniteAudit = Observable.of(data).pipe(
      distinct(p => p.key),
    )
  }

  infiniteSData(data) {
    this.infinite = Observable.of(data).pipe(
      distinct(p => p.key),
    )
  }
  @action
  depose() {
    this.fetchRegisterListingOutstandingStockRef && this.fetchRegisterListingOutstandingStockRef.unsubscribe();
    this.fetchRegisterListingRef && this.fetchRegisterListingRef.unsubscribe();
    this.fetchRegisterInfoListingRef && this.fetchRegisterInfoListingRef.unsubscribe();
    this.lazyRegisterOutstandingRef && this.lazyRegisterOutstandingRef.unsubscribe();
    this.data = [];
    this.items = [];
    this.infiniteData([]);
    this.infiniteSData([]);
  }
  reInit() {
    this.data = [];
    this.lastVisible = null;
  }

  settings_department = {
    name_en: { filter: true, key: 1, name: "Department Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Department Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }

  settings_coform = {
    code: { filter: true, key: 1, name: "CO Code", value: true },
    name: { filter: true, key: 2, name: "CO Name", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }

  settings_uom = {
    code: { filter: true, key: 1, name: "UOM Code", value: true },
    description_en: { filter: true, key: 2, name: "UOM (EN)", value: true },
    description_kh: { filter: true, key: 3, name: "UOM (KH)", value: true },
    status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }

  settings_country = {
    code: { filter: true, key: 1, name: "Country Code", value: true },
    name: { filter: true, key: 2, name: "Country Name", value: true },
    department: { filter: true, key: 3, name: "Department", value: true },
    status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  };

  settings_country_port = {
    code: { filter: true, key: 1, name: "Country Port Code", value: true },
    name: { filter: true, key: 2, name: "Country Port Name", value: true },
    country: { filter: true, key: 3, name: "Country Name", value: true },
    status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  };

  settings_forwarder = {
    name_en: { filter: true, key: 1, name: "Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Name (KH)", value: true },
    short_name_en: { filter: false, key: 3, name: "Short Name (EN)", value: true },
    short_name_kh: { filter: false, key: 4, name: "Short Name (KH)", value: true },
    email: { filter: false, key: 5, name: "Email", value: true },
    tin_no: { filter: false, key: 6, name: "Tin no", value: true },
    address: { filter: false, key: 7, name: "Address", value: true },
    country: { filter: false, key: 8, name: "Country", value: true },
    postal_code: { filter: false, key: 9, name: "Postal Code", value: true },
    link_address: { filter: false, key: 10, name: "Link Address", value: true },
    telephone_number: { filter: false, key: 11, name: "Phone", value: true },
    fax_number: { filter: false, key: 12, name: "Fax Number", value: true },
    status: { filter: false, key: 13, name: "Status", value: true },
    last_updated: { filter: false, key: 14, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 15, name: "Updated By", value: true },
  };

  settings_route = {
    name: { filter: true, key: 1, name: "Route Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Route Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  };

  settings_product_type = {
    name: { filter: true, key: 1, name: "Product Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Product Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  };

  settings_title = {
    name: { filter: true, key: 1, name: "Title Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Title Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  };

  settings_currency = {
    code: { filter: true, key: 1, name: "Currency Code", value: true },
    name: { filter: true, key: 2, name: "Currency Name", value: true },
    symbol: { filter: true, key: 3, name: "Currency Symbol", value: true },
    status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  };

  users = {
    full_name: { filter: true, key: 1, name: "Name", value: true },
    email: { filter: true, key: 2, name: "Email", value: true },
    phone: { filter: true, key: 3, name: "Phone", value: true },
    position: { filter: true, key: 4, name: "Position", value: true },
    status: { filter: false, key: 5, name: "Status", value: true },
    last_updated: { filter: false, key: 6, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 7, name: "Updated By", value: true },
  };

  company = {
    name_en: { filter: true, key: 1, name: "Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Name (KH)", value: true },
    phone: { filter: true, key: 3, name: "Phone", value: true },
    email: { filter: true, key: 4, name: "Email", value: true },
    address: { filter: true, key: 5, name: "Address", value: true },
    tinNo: { filter: true, key: 6, name: "Tin No", value: true },
    country: { filter: true, key: 7, name: "Country", value: true },
    postalCode: { filter: true, key: 8, name: "Postal Code", value: true },
    fax: { filter: true, key: 9, name: "Fax Number", value: true },
    status: { filter: false, key: 10, name: "Status", value: true },
    last_updated: { filter: false, key: 11, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 12, name: "Updated By", value: true },
  };

  print_templates = {
    company_en: { filter: false, key: 1, name: "Company Name (EN)", value: true },
    company_kh: { filter: false, key: 2, name: "Company Name (KH)", value: true },
    title: { filter: true, key: 3, name: "Title", value: true },
    address_en: { filter: false, key: 4, name: "Address (EN)", value: true },
    address_kh: { filter: false, key: 5, name: "Address (KH)", value: true },
    status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  };



  hs_codes = {
    code: { filter: true, key: 1, name: "Code", value: true },
    description_en: { filter: true, key: 2, name: "Description (EN)", value: true },
    description_kh: { filter: true, key: 3, name: "Description (KH)", value: true },
    // unit: { filter: false, key: 3, name: "Unit", value: true },
    cd: { filter: false, key: 4, name: "CD", value: true },
    st: { filter: false, key: 5, name: "ST", value: true },
    vat: { filter: false, key: 6, name: "VAT", value: true },
    et: { filter: false, key: 7, name: "ET", value: true },
    status: { filter: false, key: 8, name: "Status", value: true },
    last_updated: { filter: false, key: 9, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 10, name: "Updated By", value: true },
  }
  register_product = {
    code: { filter: true, key: 1, name: "Code", value: true },
    name_en: { filter: true, key: 2, name: "Product Name (EN)", value: true },
    name_kh: { filter: true, key: 3, name: "Product Name (KH)", value: true },
    barCode: { filter: false, key: 4, name: "ST", value: true },
    price: { filter: false, key: 5, name: "VAT", value: true },
    status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  inventory_balance = {
    code: { filter: true, key: 1, name: "Code", value: true },
    name: { filter: true, key: 2, name: "Name", value: true },
    uom: { filter: false, key: 3, name: "UOM", value: true },
    stockQty: { filter: false, key: 4, name: "Stock Qty", value: true },
  }



  inventory_detail = {
    refNo: { filter: true, key: 2, name: "Import Application No", value: true },
    refDate: { filter: false, key: 3, name: "Import Application Date", value: true },
    type: { filter: false, key: 4, name: "Application Type", value: true },
    import_qty: { filter: false, key: 7, name: "Qty/UOM", value: true },
    import_cost: { filter: false, key: 8, name: "Unit Price", value: true },
    import_totalCost: { filter: false, key: 9, name: "Amount", value: true },
    qty: { filter: false, key: 10, name: "Import Qty (N.W)", value: true },

    cutStock_balance: { filter: false, key: 17, name: "Balance", value: true },
  }

  inventory_stock_detail = {
    refNo: { filter: true, key: 2, name: "Import Application No", value: true },
    refDate: { filter: false, key: 3, name: "Import Application Date", value: true },
    type: { filter: false, key: 4, name: "Application Type", value: true },
    import_qty: { filter: false, key: 7, name: "Qty/UOM", value: true },
    import_cost: { filter: false, key: 8, name: "Unit Price", value: true },
    import_totalCost: { filter: false, key: 9, name: "Amount", value: true },
    qty: { filter: false, key: 10, name: "Import Qty (N.W)", value: true },

    cutStock_refNo: { filter: true, key: 11, name: "Cut Stock Application No", value: true },
    cutStock_refDate: { filter: false, key: 12, name: "Cut Stock Application Date", value: true },
    cutStock_type: { filter: false, key: 13, name: "Application Type", value: true },
    cutStock_allocated_qty: { filter: false, key: 16, name: "Cut Stock Qty (N.W)", value: true },
    cutStock_balance: { filter: false, key: 17, name: "Balance", value: true },
  }

  register_material = {
    code: { filter: true, key: 1, name: "Code", value: true },
    name: { filter: true, key: 2, name: "Name", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }

  supplier = {
    code: { filter: true, key: 1, name: "Supplier ID", value: true },
    name: { filter: true, key: 2, name: "Supplier Name", value: true },
    customerGroupRef: { filter: true, key: 3, name: "Group Name", value: true },
    email: { filter: true, key: 4, name: "Email", value: true },
    address: { filter: true, key: 5, name: "Address", value: true },
    telephone_number: { filter: true, key: 6, name: "Phone Number", value: true },
    status: { filter: false, key: 7, name: "Status", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  customer = {
    code: { filter: true, key: 1, name: "Customer ID", value: true },
    name: { filter: true, key: 2, name: "Customer Name", value: true },
    customerGroupRef: { filter: true, key: 3, name: "Group Name", value: true },
    email: { filter: true, key: 4, name: "Email", value: true },
    address: { filter: true, key: 5, name: "Address", value: true },
    telephone_number: { filter: true, key: 6, name: "Phone Number", value: true },
    status: { filter: false, key: 7, name: "Status", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  register_invoice = {
    invoice_number: { filter: true, key: 1, name: "CO Import Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Import Invoice Date", value: true },
    cat: { filter: false, key: 3, name: "CAT", value: true },
    // purchase_number: { filter: true, key: 4, name: "Purchase Number", value: true },
    // unit: { filter: false, key: 3, name: "Unit", value: true },
    country: { filter: true, key: 5, name: "Import Country", value: true },
    // status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }


  register_custom_invoice = {
    invoice_number: { filter: true, key: 1, name: "Custom Import Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Import Invoice Date", value: true },
    cat: { filter: false, key: 3, name: "CAT", value: true },
    // purchase_number: { filter: true, key: 4, name: "Purchase Number", value: true },
    // unit: { filter: false, key: 3, name: "Unit", value: true },
    country: { filter: true, key: 5, name: "Import Country", value: true },
    // status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_local_invoice = {
    invoice_number: { filter: true, key: 1, name: "Local Purchase Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Local Purchase Invoice Date", value: true },
    cat: { filter: false, key: 3, name: "CAT", value: true },
    // purchase_number: { filter: true, key: 4, name: "Purchase Number", value: true },
    // unit: { filter: false, key: 3, name: "Unit", value: true },
    country: { filter: true, key: 5, name: "Local Purchase Country", value: true },
    // status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_invoice_info = {
    invoice_number: { filter: true, key: 1, name: "CO Import Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Import Invoice Date", value: true },
    permit_number: { filter: true, key: 3, name: "Permit Number", value: true },
    permit_date: { filter: false, key: 4, name: "permit Date", value: true },
    custom_number: { filter: true, key: 5, name: "Custom Number", value: true },
    custom_date: { filter: false, key: 6, name: "Custom Date", value: true },
    camControl_number: { filter: true, key: 7, name: "CamControl Number", value: true },
    camControl_date: { filter: false, key: 8, name: "CamControl Date", value: true },
    bill_number: { filter: true, key: 9, name: "Bill Number", value: true },
    co_form: { filter: true, key: 10, name: "Application Form", value: true },
    last_updated: { filter: false, key: 11, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 12, name: "Updated By", value: true },
  }

  register_local_invoice_info = {
    invoice_number: { filter: true, key: 1, name: "Local Purchase Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Local Purchase Invoice Date", value: true },
    permit_number: { filter: true, key: 3, name: "Permit Number", value: true },
    permit_date: { filter: false, key: 4, name: "permit Date", value: true },
    custom_number: { filter: true, key: 5, name: "Custom Number", value: true },
    custom_date: { filter: false, key: 6, name: "Custom Date", value: true },
    camControl_number: { filter: true, key: 7, name: "CamControl Number", value: true },
    camControl_date: { filter: false, key: 8, name: "CamControl Date", value: true },
    bill_number: { filter: true, key: 9, name: "Bill Number", value: true },
    co_form: { filter: true, key: 10, name: "Application Form", value: true },
    last_updated: { filter: false, key: 11, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 12, name: "Updated By", value: true },
  }

  register_import_stock_print = {
    importcountry: { filter: true, key: 1, name: "ប្រទេសនាំចូល", value: true },
    rawmaterial: { filter: false, key: 2, name: "វត្ថុធាតុដើមចំបង", value: true },
    volume: { filter: true, key: 3, name: "បរិមាណ", value: true },
    exportto: { filter: false, key: 4, name: "ទិសដៅនាំចេញ", value: true },
    invoicenumber: { filter: true, key: 5, name: "វិក័យប័ត្រលេខ", value: true },
    customspermitno: { filter: false, key: 6, name: "លិខិតអនុញ្ញាតគយលេខ", value: true },
    customsno: { filter: true, key: 7, name: "ប្រតិវេទន៍គយលេខ", value: true },
    camcontrolno: { filter: false, key: 8, name: "កាំកុងត្រូលលេខ", value: true },
    ordernumber: { filter: true, key: 9, name: "បញ្ជាទិញលេខ", value: true },
    cat: { filter: true, key: 10, name: "CAT មុខទំនិញផលិត", value: true },
    amount: { filter: false, key: 11, name: "បរិមាណផលិត PCS", value: true },
  }

  register_cut_stock_print = {
    invoicenumber: { filter: true, key: 1, name: "វិក័យប័ត្រ លេខ", value: true },
    exportitem: { filter: false, key: 2, name: "មុខទំនិញនាំចេញ", value: true },
    cat: { filter: true, key: 3, name: "CAT", value: true },
    exportvalume: { filter: false, key: 4, name: "បរិមាណនាំចេញ", value: true },
    conumber: { filter: true, key: 5, name: "C/O លេខ", value: true },
    codate: { filter: false, key: 6, name: "CO Date", value: true },
    country: { filter: true, key: 7, name: "ប្រទេសគោលដៅ", value: true },
    route: { filter: false, key: 8, name: "SEA/AIR", value: true },
  }

  register_cut_stock_print2 = {
    invoicenumber: { filter: true, key: 1, name: "វិក័យប័ត្រ លេខ", value: true },
    exportitem: { filter: false, key: 2, name: "មុខទំនិញនាំចេញ", value: true },
    cat: { filter: true, key: 3, name: "CAT", value: true },
    conumber: { filter: true, key: 5, name: "C/O លេខ", value: true },
    codate: { filter: false, key: 6, name: "CO Date", value: true },
    country: { filter: true, key: 7, name: "ប្រទេសគោលដៅ", value: true },
    route: { filter: false, key: 8, name: "SEA/AIR", value: true },
    numberofproducts: { filter: false, key: 4, name: "បរិមាណនាំចេញ", value: true },
    amountmaterial: { filter: false, key: 4, name: "បរិមាណវត្ថុធាតុដើមប្រើប្រាស់", value: true },
  }


  register_local_cut_stock_print = {
    invoicenumber: { filter: true, key: 1, name: "វិក័យប័ត្រ លេខ", value: true },
    exportitem: { filter: false, key: 2, name: "មុខទំនិញនាំចេញ", value: true },
    cat: { filter: true, key: 3, name: "CAT", value: true },
    exportvalume: { filter: false, key: 4, name: "បរិមាណនាំចេញ", value: true },
    conumber: { filter: true, key: 5, name: "C/O លេខ", value: true },
    codate: { filter: false, key: 6, name: "CO Date", value: true },
    country: { filter: true, key: 7, name: "ប្រទេសគោលដៅ", value: true },
    route: { filter: false, key: 8, name: "SEA/AIR", value: true },
  }

  register_local_cut_stock_print2 = {
    invoicenumber: { filter: true, key: 1, name: "វិក័យប័ត្រ លេខ", value: true },
    exportitem: { filter: false, key: 2, name: "មុខទំនិញនាំចេញ", value: true },
    cat: { filter: true, key: 3, name: "CAT", value: true },
    conumber: { filter: true, key: 5, name: "C/O លេខ", value: true },
    codate: { filter: false, key: 6, name: "CO Date", value: true },
    country: { filter: true, key: 7, name: "ប្រទេសគោលដៅ", value: true },
    route: { filter: false, key: 8, name: "SEA/AIR", value: true },
    numberofproducts: { filter: false, key: 4, name: "បរិមាណនាំចេញ", value: true },
    amountmaterial: { filter: false, key: 4, name: "បរិមាណវត្ថុធាតុដើមប្រើប្រាស់", value: true },
  }


  register_invoice_outstanding = {
    invoice_number: { filter: true, key: 1, name: "Invoice Number", value: true },
    invoice_date: { filter: false, key: 2, name: "Invoice Date", value: true },
    cat: { filter: false, key: 3, name: "CAT", value: true },
    country: { filter: true, key: 5, name: "Country", value: true },
    permit_number: { filter: true, key: 3, name: "Permit Number", value: true },
    permit_date: { filter: false, key: 4, name: "permit Date", value: true },
    custom_number: { filter: true, key: 5, name: "Custom Number", value: true },
    custom_date: { filter: false, key: 6, name: "Custom Date", value: true },
    camControl_number: { filter: true, key: 7, name: "CamControl Number", value: true },
    camControl_date: { filter: false, key: 8, name: "CamControl Date", value: true },
    bill_number: { filter: true, key: 9, name: "Bill Number", value: true },
    co_form: { filter: true, key: 10, name: "Application Form", value: true },
    last_updated: { filter: false, key: 11, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 12, name: "Updated By", value: true },
  }

  supplier_group = {
    name_en: { filter: true, key: 1, name: "Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }

  customer_group = {
    name_en: { filter: true, key: 1, name: "Name (EN)", value: true },
    name_kh: { filter: true, key: 2, name: "Name (KH)", value: true },
    status: { filter: false, key: 3, name: "Status", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }

  register_ob_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_adjustment_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_import_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_local_import_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_delivery_import_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_cut_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }


  local_cut_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_delivery_cut_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  local_delivery_cut_stock = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  register_item = {
    invoice_number: { filter: true, key: 1, name: "CO Export Invoice Number", value: true },
    co_form: { filter: true, key: 2, name: "CO Form", value: true },
    invoice_date: { filter: false, key: 3, name: "Invoice Date", value: true },
    amount_FOB: { filter: false, key: 4, name: "Amount (FOB)", value: true },
    country: { filter: true, key: 5, name: "Destination", value: true },
    route: { filter: true, key: 6, name: "Route", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  local_sale_inv = {
    invoice_number: { filter: true, key: 1, name: "Local Sale Invoice Number", value: true },
    co_form: { filter: true, key: 2, name: "CO Form", value: true },
    invoice_date: { filter: false, key: 3, name: "Invoice Date", value: true },
    amount_FOB: { filter: false, key: 4, name: "Amount (FOB)", value: true },
    country: { filter: true, key: 5, name: "Destination", value: true },
    route: { filter: true, key: 6, name: "Route", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  report_local_sale_inv_print = {
    invoice_number: { filter: true, key: 1, name: "Local Sale Invoice Number", value: true },
    co_form: { filter: true, key: 2, name: "CO Form", value: true },
    invoice_date: { filter: false, key: 3, name: "Invoice Date", value: true },
    amount_FOB: { filter: false, key: 4, name: "Amount (FOB)", value: true },
    country: { filter: true, key: 5, name: "Destination", value: true },
    route: { filter: true, key: 6, name: "Route", value: true },
    qty_packing: { filter: true, key: 7, name: "Qty Packing", value: true },
    qty_unit: { filter: true, key: 8, name: "Qty Unit", value: true },
    nw: { filter: true, key: 9, name: "N.W	", value: true },
    gw: { filter: true, key: 10, name: "G.W", value: true },
  }


  custom_local_sale_inv_print = {
    invoice_number: {
      filter: true, key: 1, name: "Local Sale Invoice Number", value: true,
      type: "invoice_number"

    },
    co_form: {
      filter: true, key: 2, name: "CO Form", value: true,
      type: "co_form"

    },
    invoice_date: {
      filter: false, key: 3, name: "Invoice Date", value: true,
      type: "invoice_date"

    },
    amount_FOB: {
      filter: false, key: 4, name: "Amount (FOB)", value: true,
      type: "amount_FOB"

    },
    country: {
      filter: true, key: 5, name: "Destination", value: true,
      type: "country"

    },
    route: {
      filter: true, key: 6, name: "Route", value: true,
      type: "route"

    },
    qty_packing: {
      filter: true, key: 7, name: "Qty Packing", value: true,
      type: "qty_packing"

    },
    qty_unit: {
      filter: true, key: 8, name: "Qty Unit", value: true,
      type: "qty_unit"

    },

    customs_material: {
      filter: false,
      key: 9,
      name: "Customs Material",
      value: true,
      type: "customs_material"

    },

    qty: {
      filter: false,
      key: 10,
      name: "Qty",
      value: true,
      type: "qty"

    },
    unit_price: {
      filter: false,
      key: 11,
      name: "Unit Price",
      value: true,
      type: "unit_price"

    },

    amount: {
      filter: false,
      key: 12,
      name: "Amount",
      value: true,
      type: "amount"

    },


    nw: { 
      filter: true, key: 13, name: "N.W	", value: true ,
      type: "nw"

    },
    gw: { 
      filter: true, key: 14, name: "G.W", value: true,
      type: "gw"

    },
  }



  register_export_inv = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    invoice_number: { filter: true, key: 2, name: "Export Invoice Number", value: true },
    invoice_date: { filter: false, key: 3, name: "Export Invoice Date", value: true },
    amount_FOB: { filter: false, key: 4, name: "Amount (FOB)", value: true },
    country: { filter: true, key: 5, name: "Destination", value: true },
    route: { filter: true, key: 6, name: "Route", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }


  register_co = {
    co_form: { filter: false, key: 1, name: "CO Form", value: true },
    co_number: { filter: true, key: 2, name: "Export CO Number", value: true },
    co_date: { filter: false, key: 3, name: "Export CO Date", value: true },
    invoice_number: { filter: true, key: 4, name: "Export Invoice Number", value: true },
    invoice_date: { filter: false, key: 5, name: "Invoice Date", value: true },
    country: { filter: true, key: 6, name: "Destination", value: true },
    route: { filter: true, key: 7, name: "Route", value: true },
    last_updated: { filter: false, key: 9, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 10, name: "Updated By", value: true },
  }



  pickup_bill = {
    bill_number: { filter: true, key: 1, name: "Export Bill Number", value: true },
    bill_date: { filter: false, key: 2, name: "Export Bill Date", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }


  pickup_inspect = {
    inspect_number: { filter: true, key: 1, name: "Export Inspect Number", value: true },
    inspect_date: { filter: false, key: 2, name: "Export Inspect Date", value: true },
    last_updated: { filter: false, key: 4, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 5, name: "Updated By", value: true },
  }




  register_soo = {
    soo_number: { filter: true, key: 1, name: "SOO Number", value: true },
    soo_date: { filter: false, key: 2, name: "SOO Date", value: true },
    co_number: { filter: true, key: 3, name: "Export CO Number", value: true },
    co_date: { filter: false, key: 4, name: "Export CO Date", value: true },
    last_updated: { filter: false, key: 6, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 7, name: "Updated By", value: true },
  }


  register_submit_form = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    refNo: { filter: true, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },
    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    total_service_fee: { filter: false, key: 6, name: "Total Service Fee", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_cancel_co = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    refNo: { filter: true, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },
    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    total_service_fee: { filter: false, key: 6, name: "Total Service Fee", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_submit_crf = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    refNo: { filter: true, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },
    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    total_service_fee: { filter: false, key: 6, name: "Total Service Fee", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_bom = {
    product: { filter: true, key: 2, name: "Product", value: true },
    status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_purchase_order = {
    purchase_number: { filter: true, key: 1, name: "Purchase Number", value: true },
    purchase_date: { filter: false, key: 1, name: "Purchase Date", value: true },
    supplier: { filter: true, key: 2, name: "Supplier", value: true },
    // status: { filter: false, key: 6, name: "Status", value: true },
    last_updated: { filter: false, key: 7, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 8, name: "Updated By", value: true },
  }

  register_master_list = {
    // code: { filter: true, key: 1, name: "Master List Code", value: true },
    masterId: { filter: true, key: 2, name: "Master List ID", value: true },
    approvedDate: { filter: false, key: 3, name: "Approved Date", value: true },
    fromDate: { filter: false, key: 4, name: "From Date", value: true },
    toDate: { filter: false, key: 5, name: "To Date", value: true },
    // duration: { filter: true, key: 6, name: "Duration", value: true },
    // status: { filter: false, key: 7, name: "Status", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  register_soo_statement = {
    ref: { filter: true, key: 1, name: "Reference No", value: true },
    soo_date: { filter: false, key: 2, name: "Application Date", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }

  register_delivery_export_document = {
    reference: { filter: true, key: 1, name: "Reference No", value: true },
    Application_Date: { filter: false, key: 2, name: "Application Date", value: true },
    joint_inspection: { filter: false, key: 3, name: "Joint Inspect (Set)", value: true },
    invoice: { filter: false, key: 4, name: "Invoice (Set)", value: true },
    // status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }
  register_delivery_co_application = {
    reference: { filter: true, key: 1, name: "Reference No", value: true },
    Application_Date: { filter: false, key: 2, name: "Application Date", value: true },
    set: { filter: false, key: 3, name: "Set", value: true },
    // status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }


  register_local_delivery = {
    reference: { filter: true, key: 1, name: "Reference No", value: true },
    Application_Date: { filter: false, key: 2, name: "Application Date", value: true },
    set: { filter: false, key: 3, name: "Set", value: true },
    // status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }


  delivery_return_application = {
    reference: { filter: true, key: 1, name: "Reference No", value: true },
    date: { filter: false, key: 2, name: "Application Date", value: true },
    invoice: { filter: false, key: 3, name: "Invoice (Set)", value: true },
    // status: { filter: false, key: 4, name: "Status", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }


  Submit_Column_Print = {
    co_reference: {
      filter: false,
      key: 1,
      name: "CO Reference",
      value: true,
    },

    quantity: {
      filter: false,
      key: 2,
      name: "Quantity",
      value: true,
    },
    amount: {
      filter: false,
      key: 3,
      name: "Amount",
      value: true,
    },
    cat: {
      filter: false,
      key: 4,
      name: "CAT",
      value: true,
    },
    invoice: {
      filter: false,
      key: 5,
      name: "Invoice",
      value: true,
    },

    joint_inspection: {
      filter: false,
      key: 6,
      name: "Joint Inspection",
      value: true,
    },
    bill_no: {
      filter: false,
      key: 7,
      name: "Bill No",
      value: true,
    },
    destination: {
      filter: false,
      key: 8,
      name: "Destination",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 9,
      name: " Service ADM Fee",
      value: true,
    },
    emf_fee: {
      filter: false,
      key: 10,
      name: " Service EMF Fee",
      value: true,
    },
  }



  reapply_Column_Print = {
    co_reference: {
      filter: false,
      key: 1,
      name: "CO Reference",
      value: true,
    },

    quantity: {
      filter: false,
      key: 2,
      name: "Quantity",
      value: true,
    },
    amount: {
      filter: false,
      key: 3,
      name: "Amount",
      value: true,
    },
    cat: {
      filter: false,
      key: 4,
      name: "CAT",
      value: true,
    },
    invoice: {
      filter: false,
      key: 5,
      name: "Invoice",
      value: true,
    },

    joint_inspection: {
      filter: false,
      key: 6,
      name: "Joint Inspection",
      value: true,
    },
    bill_no: {
      filter: false,
      key: 7,
      name: "Bill No",
      value: true,
    },
    destination: {
      filter: false,
      key: 8,
      name: "Destination",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 9,
      name: " Service ADM Fee",
      value: true,
    },
    emf_fee: {
      filter: false,
      key: 10,
      name: " Service EMF Fee",
      value: true,
    },
  }


  duplicate_Column_Print = {
    co_reference: {
      filter: false,
      key: 1,
      name: "CO Reference",
      value: true,
    },

    quantity: {
      filter: false,
      key: 2,
      name: "Quantity",
      value: true,
    },
    amount: {
      filter: false,
      key: 3,
      name: "Amount",
      value: true,
    },
    cat: {
      filter: false,
      key: 4,
      name: "CAT",
      value: true,
    },
    invoice: {
      filter: false,
      key: 5,
      name: "Invoice",
      value: true,
    },

    joint_inspection: {
      filter: false,
      key: 6,
      name: "Joint Inspection",
      value: true,
    },
    bill_no: {
      filter: false,
      key: 7,
      name: "Bill No",
      value: true,
    },
    destination: {
      filter: false,
      key: 8,
      name: "Destination",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 9,
      name: " Service ADM Fee",
      value: true,
    },
    emf_fee: {
      filter: false,
      key: 10,
      name: " Service EMF Fee",
      value: true,
    },
  }



  SooStatement_Column_Print = {
    soo_no: {
      filter: false,
      key: 1,
      name: "SOO NO",
      value: true,
      is_soo: true,
    },
    soo_date: {
      filter: false,
      key: 2,
      name: "SOO DATE",
      value: true,
      is_soo: true,
    },
    soo_amount_fob: {
      filter: false,
      key: 3,
      name: "Amount(FOB)",
      value: true,
      is_soo: true,
    },
    soo_quality_ctn: {
      filter: false,
      key: 4,
      name: "Quality(CTN)",
      value: true,
      is_soo: true,
    },
    soo_quality_pcs: {
      filter: false,
      key: 5,
      name: "Quality(PCS)",
      value: true,
      is_soo: true,
    },

    co_no: {
      filter: false,
      key: 6,
      name: "CO NO",
      value: true,
      is_soo: false,

    },
    co_date: {
      filter: false,
      key: 7,
      name: "CO DATE",
      value: true,
      is_soo: false,

    },
    invoice_no: {
      filter: false,
      key: 8,
      name: "INVOICE NO",
      value: true,
      is_soo: false,

    },
    invoice_date: {
      filter: false,
      key: 9,
      name: "INVOICE DATE",
      value: true,
      is_soo: false,

    },
    shipping_date: {
      filter: false,
      key: 10,
      name: "Shipping Date",
      value: true,
      is_soo: false,

    },

    amount_fob: {
      filter: false,
      key: 11,
      name: "Amount(FOB)",
      value: true,
      is_soo: false,

    },
    quality_ctn: {
      filter: false,
      key: 12,
      name: "Quality(CTN)",
      value: true,
      is_soo: false,

    },
    quality_pcs: {
      filter: false,
      key: 13,
      name: "Quality(PCS)",
      value: true,
      is_soo: false,

    },
    destination: {
      filter: false,
      key: 14,
      name: "DESTINATION",
      value: true,
      is_soo: false,

    },
    inspect_no: {
      filter: false,
      key: 9,
      name: "INSPECT NO",
      value: true,
      is_soo: false,

    },
    inspect_date: {
      filter: false,
      key: 10,
      name: "INSPECT DATE",
      value: true,
      is_soo: false,

    },
  }




  Submitreport_Column_Print = {
    co_number: {
      filter: false,
      key: 1,
      name: "CO Number",
      value: true,
    },
    co_date: {
      filter: false,
      key: 2,
      name: "CO Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 3,
      name: "Shipping Date",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 4,
      name: "ADM Fee",
      value: true,
    },

    emf_fee: {
      filter: false,
      key: 5,
      name: "EMF Fee",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 6,
      name: "Quantity (PCS)",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 7,
      name: "Quantity (Packing)",
      value: true,
    },
    amount: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
    },
    invoice_number: {
      filter: false,
      key: 9,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 10,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 11,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 12,
      name: "Destination",
      value: true,
    },

    inspect_number: {
      filter: false,
      key: 13,
      name: "Inspect Number",
      value: true,
    },
    inspect_date: {
      filter: false,
      key: 14,
      name: "Inspect Date",
      value: true,
    },

  }


  Outstanding_Release_Cut_Stock_Report = {
    reference_number: {
      filter: false,
      key: 1,
      name: "Reference Number",
      value: true,
    },
    application_date: {
      filter: false,
      key: 2,
      name: "Application date",
      value: true,
    },
    co_number: {
      filter: false,
      key: 3,
      name: "CO Number",
      value: true,
    },
    co_date: {
      filter: false,
      key: 4,
      name: "CO Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 5,
      name: "Shipping Date",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 6,
      name: "ADM Fee",
      value: true,
    },

    emf_fee: {
      filter: false,
      key: 7,
      name: "EMF Fee",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 8,
      name: "Quantity (PCS)",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 9,
      name: "Quantity (Packing)",
      value: true,
    },
    amount: {
      filter: false,
      key: 10,
      name: "Amount",
      value: true,
    },
    invoice_number: {
      filter: false,
      key: 11,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 12,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 13,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 14,
      name: "Destination",
      value: true,
    },
  }


  Duplicate_Invoice_Report = {
    co_number: {
      filter: false,
      key: 1,
      name: "CO Number",
      value: true,
    },
    co_date: {
      filter: false,
      key: 2,
      name: "CO Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 3,
      name: "Shipping Date",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 4,
      name: "ADM Fee",
      value: true,
    },

    emf_fee: {
      filter: false,
      key: 5,
      name: "EMF Fee",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 6,
      name: "Qty Unit",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 7,
      name: "Qty Packing",
      value: true,
    },
    amount: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
    },
    invoice_number: {
      filter: false,
      key: 9,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 10,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 11,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 12,
      name: "Destination",
      value: true,
    },

    inspect_number: {
      filter: false,
      key: 13,
      name: "Inspect Number",
      value: true,
    },
    inspect_date: {
      filter: false,
      key: 14,
      name: "Inspect Date",
      value: true,
    },

  }


  Cut_Stock_Report = {
    co_number: {
      filter: false,
      key: 1,
      name: "CO Number",
      value: true,
    },
    co_date: {
      filter: false,
      key: 2,
      name: "CO Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 3,
      name: "Shipping Date",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 4,
      name: "ADM Fee",
      value: true,
    },

    emf_fee: {
      filter: false,
      key: 5,
      name: "EMF Fee",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 6,
      name: "Qty Unit",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 7,
      name: "Qty Packing",
      value: true,
    },
    amount: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
    },
    invoice_number: {
      filter: false,
      key: 9,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 10,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 11,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 12,
      name: "Destination",
      value: true,
    },



  }


  Local_Purchase_Stock_Report = {
    purchase_country: {
      filter: false,
      key: 1,
      name: "Purchase Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 2,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 4,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },
  }
  Import_Stock_Report = {
    import_country: {
      filter: false,
      key: 1,
      name: "Import Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 2,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 4,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

  }


  Outstanding_Local_Purchase_Stock_Report = {
    import_country: {
      filter: false,
      key: 1,
      name: "Import Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 2,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 4,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

  }

  Outstanding_Release_Local_Purchase_Stock_Report = {
    reference_number: {
      filter: false,
      key: 1,
      name: "Reference Number",
      value: true,
    },
    application_date: {
      filter: false,
      key: 2,
      name: "Application date",
      value: true,
    },
    purchase_country: {
      filter: false,
      key: 3,
      name: "Purchase Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 4,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 5,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

  }

  Outstanding_Release_Import_Stock_Report = {
    reference_number: {
      filter: false,
      key: 1,
      name: "Reference Number",
      value: true,
    },
    application_date: {
      filter: false,
      key: 2,
      name: "Application date",
      value: true,
    },
    import_country: {
      filter: false,
      key: 3,
      name: "Import Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 4,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 5,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

  }




  Local_Purchase_Invoice_Report = {
    import_country: {
      filter: false,
      key: 1,
      name: "Import Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 2,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 4,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },
    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

    nw: {
      filter: false,
      key: 14,
      name: "	N.W",
      value: true,
    },

    gw: {
      filter: false,
      key: 15,
      name: "	G.W",
      value: true,
    },
  }


  Local_custom_purchase_invoice_report = {
    import_country: {
      filter: false,
      key: 1,
      name: "Import Country",
      value: true,
    },

    invoice: {
      filter: false,
      key: 2,
      name: "Invoice",
      value: true,
    },
    permit: {
      filter: false,
      key: 4,
      name: "Permit",
      value: true,
    },

    custom: {
      filter: false,
      key: 6,
      name: "Custom",
      value: true,
    },


    cam_control: {
      filter: false,
      key: 8,
      name: "Cam Control",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 10,
      name: "Bill Number",
      value: true,
    },
    cat: {
      filter: false,
      key: 11,
      name: "CAT",
      value: true,
    },

    qty: {
      filter: false,
      key: 12,
      name: "Qty(Order)",
      value: true,
    },

    export_to: {
      filter: false,
      key: 13,
      name: "	Export to",
      value: true,
    },

    nw: {
      filter: false,
      key: 14,
      name: "	N.W",
      value: true,
    },

    gw: {
      filter: false,
      key: 15,
      name: "	G.W",
      value: true,
    },

    customs_material: {
      filter: false,
      key: 16,
      name: "Customs Material",
      value: true,
      type: "customs_material"

    },

    m_qty: {
      filter: false,
      key: 17,
      name: "Qty",
      value: true,
      type: "m_qty"

    },
    unit_price: {
      filter: false,
      key: 18,
      name: "Unit Price",
      value: true,
      type: "unit_price"

    },

    amount: {
      filter: false,
      key: 19,
      name: "Amount",
      value: true,
      type: "amount"

    },

  }

  Inventory_CO_Balance_Report = {
    application_type: {
      filter: false,
      key: 1,
      name: "	Application Type",
      value: true,
    },

    reference_no: {
      filter: false,
      key: 2,
      name: "Reference No.",
      value: true,
    },
    application_date: {
      filter: false,
      key: 3,
      name: "	Application Date",
      value: true,
    },

    release_date: {
      filter: false,
      key: 4,
      name: "	Release Date",
      value: true,
    },
    qty: {
      filter: false,
      key: 5,
      name: "Qty",
      value: true,
    },


  }


  reapply_item_Column_Print = {
    old_co_number: {
      filter: false,
      key: 1,
      name: "Old CO Number",
      value: true,
    },
    old_co_date: {
      filter: false,
      key: 2,
      name: "Old CO Date",
      value: true,
    },
    co_number: {
      filter: false,
      key: 3,
      name: "CO Number",
      value: true,
    },
    co_date: {
      filter: false,
      key: 4,
      name: "CO Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 5,
      name: "Shipping Date",
      value: true,
    },
    adm_fee: {
      filter: false,
      key: 6,
      name: "ADM Fee",
      value: true,
    },

    emf_fee: {
      filter: false,
      key: 7,
      name: "EMF Fee",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 8,
      name: "Qty Unit",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 9,
      name: "Qty Packing",
      value: true,
    },
    amount: {
      filter: false,
      key: 10,
      name: "Amount",
      value: true,
    },
    invoice_number: {
      filter: false,
      key: 11,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 12,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 13,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 14,
      name: "Destination",
      value: true,
    },

    inspect_number: {
      filter: false,
      key: 15,
      name: "Inspect Number",
      value: true,
    },
    inspect_date: {
      filter: false,
      key: 16,
      name: "Inspect Date",
      value: true,
    },

  }


  local_cut_stock_report_Column_Print = {
    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 3,
      name: "Shipping Date",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 4,
      name: "Qty Unit",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 5,
      name: "Qty Packing",
      value: true,
    },
    amount: {
      filter: false,
      key: 6,
      name: "Amount",
      value: true,
    },

    route: {
      filter: false,
      key: 7,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 8,
      name: "Destination",
      value: true,
    },

    // inspect_number: {
    //   filter: false,
    //   key: 12,
    //   name: "Inspect Number",
    //   value: true,
    // },
    // inspect_date: {
    //   filter: false,
    //   key: 13,
    //   name: "Inspect Date",
    //   value: true,
    // },

  }


  Outstanding_Release_Local_Sale_Stock_Report = {
    reference_number: {
      filter: false,
      key: 1,
      name: "Reference Number",
      value: true,
    },
    application_date: {
      filter: false,
      key: 2,
      name: "Application date",
      value: true,
    },

    invoice_number: {
      filter: false,
      key: 3,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 4,
      name: "Invoice Date",
      value: true,
    },
    shipping_date: {
      filter: false,
      key: 5,
      name: "Shipping Date",
      value: true,
    },

    quantity_pcs: {
      filter: false,
      key: 6,
      name: "Qty Unit",
      value: true,
    },

    quantity_p: {
      filter: false,
      key: 7,
      name: "Qty Packing",
      value: true,
    },
    amount: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
    },

    route: {
      filter: false,
      key: 9,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 10,
      name: "Destination",
      value: true,
    },

    

  }



  inspect_report_column_print = {
    inspect_number: {
      filter: false,
      key: 1,
      name: "Inspect Number",
      value: true,
      type: 'inspect_number'
    },
    inspect_date: {
      filter: false,
      key: 2,
      name: "Inspect Date",
      value: true,
      type: 'inspect_date'
    },

    invoice_number: {
      filter: false,
      key: 3,
      name: "Invoice Number",
      value: true,
      type: 'invoice_number'
    },
    invoice_date: {
      filter: false,
      key: 4,
      name: "Invoice Date",
      value: true,
      type: 'invoice_date'

    },
    route: {
      filter: false,
      key: 5,
      name: "Route",
      value: true,
      type: 'route'

    },
    destination: {
      filter: false,
      key: 6,
      name: "Destination",
      value: true,
      type: 'destination'
    },
    co_form: {
      filter: false,
      key: 7,
      name: "Co Form",
      value: true,
      type: 'co_form'

    },
    amount_fob: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
      type: 'amount_fob'

    },
    qty_packing: {
      filter: false,
      key: 9,
      name: "Qty Packing",
      value: true,
      type: "qty_packing"
    },
    qty_unit: {
      filter: false,
      key: 10,
      name: "Qty Unit",
      value: true,
      type: "qty_unit"
    },
    co_number: {
      filter: false,
      key: 11,
      name: "CO Number",
      value: true,
      type: "co_number"

    },
    co_date: {
      filter: false,
      key: 12,
      name: "CO Date",
      value: true,
      type: "co_date"

    },

    nw: {
      filter: false,
      key: 13,
      name: "N.W",
      value: true,
      type: 'nw'
    },

    gw: {
      filter: false,
      key: 14,
      name: "G.W",
      value: true,
      type:'gw'
    },

  }


  custom_inspect_report_column_print = {
    inspect_number: {
      filter: false,
      key: 1,
      name: "Inspect Number",
      value: true,
      type: 'inspect_number'
    },
    inspect_date: {
      filter: false,
      key: 2,
      name: "Inspect Date",
      value: true,
      type: 'inspect_date'
    },

    invoice_number: {
      filter: false,
      key: 3,
      name: "Invoice Number",
      value: true,
      type: 'invoice_number'
    },
    invoice_date: {
      filter: false,
      key: 4,
      name: "Invoice Date",
      value: true,
      type: 'invoice_date'

    },
    route: {
      filter: false,
      key: 5,
      name: "Route",
      value: true,
      type: 'route'

    },
    destination: {
      filter: false,
      key: 6,
      name: "Destination",
      value: true,
      type: 'destination'
    },
    co_form: {
      filter: false,
      key: 7,
      name: "Co Form",
      value: true,
      type: 'co_form'

    },
    amount_fob: {
      filter: false,
      key: 8,
      name: "Amount",
      value: true,
      type: 'amount_fob'

    },
    qty_packing: {
      filter: false,
      key: 9,
      name: "Qty Packing",
      value: true,
      type: "qty_packing"
    },
    qty_unit: {
      filter: false,
      key: 10,
      name: "Qty Unit",
      value: true,
      type: "qty_unit"
    },
    co_number: {
      filter: false,
      key: 11,
      name: "CO Number",
      value: true,
      type: "co_number"

    },
    co_date: {
      filter: false,
      key: 12,
      name: "CO Date",
      value: true,
      type: "co_date"

    },
    customs_material: {
      filter: false,
      key: 13,
      name: "Customs Material",
      value: true,
      type: "customs_material"

    },

    qty: {
      filter: false,
      key: 14,
      name: "Qty",
      value: true,
      type: "qty"

    },
    unit_price: {
      filter: false,
      key: 15,
      name: "Unit Price",
      value: true,
      type: "unit_price"

    },

    amount: {
      filter: false,
      key: 16,
      name: "Amount",
      value: true,
      type: "amount"

    },

    nw: {
      filter: false,
      key: 17,
      name: "N.W",
      value: true,
      type: 'nw'
    },

    gw: {
      filter: false,
      key: 18,
      name: "G.W",
      value: true,
      type:'gw'
    },

  }


  invoice_report_column_print = {

    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
    },
    route: {
      filter: false,
      key: 3,
      name: "Route",
      value: true,
    },
    destination: {
      filter: false,
      key: 4,
      name: "Destination",
      value: true,
    },
    co_form: {
      filter: false,
      key: 5,
      name: "Co Form",
      value: true,
    },
    amount_fob: {
      filter: false,
      key: 6,
      name: "Amount",
      value: true,
    },
    qty_packing: {
      filter: false,
      key: 7,
      name: "Qty Packing",
      value: true,
    },
    qty_unit: {
      filter: false,
      key: 8,
      name: "Qty Unit",
      value: true,
    },
    co_number: {
      filter: false,
      key: 9,
      name: "CO Number",
      value: false,
    },
    co_date: {
      filter: false,
      key: 10,
      name: "CO Date",
      value: false,
    },
    inspect_number: {
      filter: false,
      key: 11,
      name: "Inspect Number",
      value: false,
    },
    inspect_date: {
      filter: false,
      key: 12,
      name: "Inspect Date",
      value: false,
    },

    bill_number: {
      filter: false,
      key: 13,
      name: "Bill Number",
      value: false,
    },
    bill_date: {
      filter: false,
      key: 14,
      name: "Bill Date",
      value: false,
    },


    customs_material: {
      filter: false,
      key: 15,
      name: "Customs Material",
      value: true,
      type: "customs_material"

    },

    qty: {
      filter: false,
      key: 16,
      name: "Qty",
      value: true,
      type: "qty"

    },
    unit_price: {
      filter: false,
      key: 17,
      name: "Unit Price",
      value: true,
      type: "unit_price"

    },

    amount: {
      filter: false,
      key: 18,
      name: "Amount",
      value: true,
      type: "amount"

    },

    nw: {
      filter: false,
      key: 19,
      name: "N.W",
      value: true,
      type: 'nw'
    },

    gw: {
      filter: false,
      key: 20,
      name: "G.W",
      value: true,
      type: 'gw'
    },



  }

  Adjustment_CO_Report = {
    Application_Type: {
      filter: false,
      key: 1,
      name: "Application Type",
      value: true,
    },
    Reference_No: {
      filter: false,
      key: 2,
      name: "Reference No",
      value: true,
    },

    Date: {
      filter: false,
      key: 7,
      name: "Date",
      value: true,
    },

    Memo: {
      filter: false,
      key: 9,
      name: "Memo",
      value: true,
    },

    Qty: {
      filter: false,
      key: 10,
      name: "Qty",
      value: true,
    },


  }


  Adjustment_Customs_Report = {
    Application_Type: {
      filter: false,
      key: 1,
      name: "Application Type",
      value: true,
    },
    Reference_No: {
      filter: false,
      key: 2,
      name: "Reference No",
      value: true,
    },

    Date: {
      filter: false,
      key: 7,
      name: "Date",
      value: true,
    },

    Memo: {
      filter: false,
      key: 9,
      name: "Memo",
      value: true,
    },

    customs_material: {
      filter: false,
      key: 10,
      name: "Customs Material",
      value: true,
      type: "customs_material"

    },

    qty: {
      filter: false,
      key: 11,
      name: "Qty",
      value: true,
      type: "qty"

    },
    unit_price: {
      filter: false,
      key: 12,
      name: "Unit Price",
      value: true,
      type: "unit_price"

    },

    amount: {
      filter: false,
      key: 13,
      name: "Amount",
      value: true,
      type: "amount"

    },


    Adjustment_Over: {
      filter: false,
      key: 14,
      name: "Adjustment Over",
      value: true,
      type: "Adjustment_Over"
    },
    Adjustment_Under: {
      filter: false,
      key: 15,
      name: "Adjustment Under",
      value: true,
      type: "Adjustment_Under"
    },


  }


  Import_Invoice_Column_Print = {
    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
    },

    import_country: {
      filter: false,
      key: 3,
      name: "Import Country",
      value: true,
    },

    nw: {
      filter: false,
      key: 4,
      name: "N.W",
      value: true,
      type: 'nw',
    },

    gw: {
      filter: false,
      key: 5,
      name: "G.W",
      value: true,
      type: 'gw'
    },

    amount: {
      filter: false,
      key: 6,
      name: "Amount",
      value: true,
      type: 'amount'
    },
  }


  Custom_Import_Invoice_Column_Print = {
    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
      type: 'invoice_number'
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
      type: 'invoice_date'

    },

    import_country: {
      filter: false,
      key: 3,
      name: "Import Country",
      value: true,
      type: 'import_country'

    },

    customs_material: {
      filter: false,
      key: 4,
      name: "Customs Material",
      value: true,
      type: 'customs_material'

    },

    qty: {
      filter: false,
      key: 5,
      name: "Qty",
      value: true,
      type: 'qty'

    },
    unit_price: {
      filter: false,
      key: 6,
      name: "Unit Price",
      value: true,
      type: 'unit_price'

    },
   

    nw: {
      filter: false,
      key: 7,
      name: "N.W",
      value: true,
      type: 'nw'
    },

    gw: {
      filter: false,
      key: 8,
      name: "G.W",
      value: true,
      type: 'gw'
    },
    amount: {
      filter: false,
      key: 9,
      name: "Amount",
      value: true,
      type: 'amount'
    },



  }

  Custom_Import_Custom_Column_Print = {
    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
      type: 'invoice_number'
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
      type: 'invoice_date'

    },
    customs_number: {
      filter: false,
      key: 3,
      name: "Customs Number",
      value: true,
      type: 'customs_number'

    },
    customs_date: {
      filter: false,
      key: 4,
      name: "Customs Date",
      value: true,
      type: 'customs_date'


    },

    permit_number: {
      filter: false,
      key: 5,
      name: "Permit Number",
      value: true,
      type: 'permit_number'

    },
    permit_date: {
      filter: false,
      key: 6,
      name: "Permit Date",
      value: true,
      type: 'permit_date'

    },

    import_country: {
      filter: false,
      key: 7,
      name: "Import Country",
      value: true,
      type: 'import_country'

    },

    bill_number: {
      filter: false,
      key: 8,
      name: "Bill Number",
      value: true,
      type: 'bill_number'

    },

    customs_material: {
      filter: false,
      key: 9,
      name: "Customs Material",
      value: true,
      type: 'customs_material'

    },
    qty: {
      filter: false,
      key: 10,
      name: "Qty",
      value: true,
      type: 'qty'

    },
    unit_price: {
      filter: false,
      key: 11,
      name: "Unit Price",
      value: true,
      type: 'unit_price'

    },

    amount: {
      filter: false,
      key: 12,
      name: "Amount",
      value: true,
      type: 'amount'

    },

    nw: {
      filter: false,
      key: 13,
      name: "N.W",
      value: true,
      type: 'nw'

    },

    gw: {
      filter: false,
      key: 14,
      name: "G.W",
      value: true,
      type: 'gw'

    },


  }

  Import_Custom_Column_Print = {
    invoice_number: {
      filter: false,
      key: 1,
      name: "Invoice Number",
      value: true,
    },
    invoice_date: {
      filter: false,
      key: 2,
      name: "Invoice Date",
      value: true,
    },
    customs_number: {
      filter: false,
      key: 3,
      name: "Customs Number",
      value: true,
    },
    customs_date: {
      filter: false,
      key: 4,
      name: "Customs Date",
      value: true,
    },

    permit_number: {
      filter: false,
      key: 5,
      name: "Permit Number",
      value: true,
    },
    permit_date: {
      filter: false,
      key: 6,
      name: "Permit Date",
      value: true,
    },

    import_country: {
      filter: false,
      key: 7,
      name: "Import Country",
      value: true,
    },

    bill_number: {
      filter: false,
      key: 8,
      name: "Bill Number",
      value: true,
    },
    nw: {
      filter: false,
      key: 9,
      name: "N.W",
      value: true,
      type: 'nw'
    },

    gw: {
      filter: false,
      key: 10,
      name: "G.W",
      value: true,
      type:'gw'
    },

    amount: {
      filter: false,
      key: 11,
      name: "Amount",
      value: true,
      type: 'amount'

    },


  }




  Sooreport_Column_Print = {
    soo_no: {
      filter: false,
      key: 1,
      name: "SOO NO",
      value: true,
      is_soo: true,
    },
    soo_date: {
      filter: false,
      key: 2,
      name: "SOO DATE",
      value: true,
      is_soo: true,
    },
    soo_amount_fob: {
      filter: false,
      key: 3,
      name: "Amount(FOB)",
      value: true,
      is_soo: true,
    },
    soo_quality_ctn: {
      filter: false,
      key: 4,
      name: "Quality(CTN)",
      value: true,
      is_soo: true,
    },
    soo_quality_pcs: {
      filter: false,
      key: 5,
      name: "Quality(PCS)",
      value: true,
      is_soo: true,
    },

    co_no: {
      filter: false,
      key: 6,
      name: "CO NO",
      value: true,
      is_soo: false,

    },
    co_date: {
      filter: false,
      key: 7,
      name: "CO DATE",
      value: true,
      is_soo: false,

    },
    invoice_no: {
      filter: false,
      key: 8,
      name: "INVOICE NO",
      value: true,
      is_soo: false,

    },
    invoice_date: {
      filter: false,
      key: 9,
      name: "INVOICE DATE",
      value: true,
      is_soo: false,

    },
    shipping_date: {
      filter: false,
      key: 10,
      name: "Shipping Date",
      value: true,
      is_soo: false,

    },

    amount_fob: {
      filter: false,
      key: 11,
      name: "Amount(FOB)",
      value: true,
      is_soo: false,

    },
    quality_ctn: {
      filter: false,
      key: 12,
      name: "Quality(CTN)",
      value: true,
      is_soo: false,

    },
    quality_pcs: {
      filter: false,
      key: 13,
      name: "Quality(PCS)",
      value: true,
      is_soo: false,

    },
    destination: {
      filter: false,
      key: 14,
      name: "DESTINATION",
      value: true,
      is_soo: false,

    },
    inspect_no: {
      filter: false,
      key: 9,
      name: "INSPECT NO",
      value: true,
      is_soo: false,

    },
    inspect_date: {
      filter: false,
      key: 10,
      name: "INSPECT DATE",
      value: true,
      is_soo: false,

    },
  }



  Soo_Column_Print = {
    soo_no: {
      filter: false,
      key: 1,
      name: "SOO NO",
      value: true,
      is_soo: true,
    },
    soo_date: {
      filter: false,
      key: 2,
      name: "SOO DATE",
      value: true,
      is_soo: true,
    },
    soo_amount_fob: {
      filter: false,
      key: 3,
      name: "Amount(FOB)",
      value: true,
      is_soo: true,
    },
    soo_quality_ctn: {
      filter: false,
      key: 4,
      name: "Quality(CTN)",
      value: true,
      is_soo: true,
    },
    soo_quality_pcs: {
      filter: false,
      key: 5,
      name: "Quality(PCS)",
      value: true,
      is_soo: true,
    },

    co_no: {
      filter: false,
      key: 6,
      name: "CO NO",
      value: true,
      is_soo: false,

    },
    co_date: {
      filter: false,
      key: 7,
      name: "CO DATE",
      value: true,
      is_soo: false,

    },
    invoice_no: {
      filter: false,
      key: 8,
      name: "INVOICE NO",
      value: true,
      is_soo: false,

    },
    invoice_date: {
      filter: false,
      key: 9,
      name: "INVOICE DATE",
      value: true,
      is_soo: false,

    },
    shipping_date: {
      filter: false,
      key: 10,
      name: "Shipping Date",
      value: true,
      is_soo: false,

    },

    amount_fob: {
      filter: false,
      key: 11,
      name: "Amount(FOB)",
      value: true,
      is_soo: false,

    },
    quality_ctn: {
      filter: false,
      key: 12,
      name: "Quality(CTN)",
      value: true,
      is_soo: false,

    },
    quality_pcs: {
      filter: false,
      key: 13,
      name: "Quality(PCS)",
      value: true,
      is_soo: false,

    },
    destination: {
      filter: false,
      key: 14,
      name: "DESTINATION",
      value: true,
      is_soo: false,

    },
    inspect_no: {
      filter: false,
      key: 9,
      name: "INSPECT NO",
      value: true,
      is_soo: false,

    },
    inspect_date: {
      filter: false,
      key: 10,
      name: "INSPECT DATE",
      value: true,
      is_soo: false,

    },
  }



  register_return_application = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    reference: { filter: true, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },

    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    joint_inspection: { filter: false, key: 6, name: "Joint Inspection", value: true },
    bill_leading: { filter: false, key: 7, name: "Bill Leading", value: true },
    status: { filter: false, key: 8, name: "Status", value: true },
    last_updated: { filter: false, key: 9, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 10, name: "Updated By", value: true },
  }

  register_duplicate_item = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    co_number: { filter: true, key: 2, name: "CO Number", value: true },
    co_date: { filter: false, key: 3, name: "CO Date", value: true },
    invoice_number: { filter: true, key: 4, name: "Invoice Number", value: true },
    invoice_date: { filter: false, key: 5, name: "Invoice Date", value: true },
    country: { filter: true, key: 6, name: "Country", value: true },
    route: { filter: true, key: 7, name: "Route", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  register_duplicate = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    reference: { filter: false, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },
    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    joint_inspection: { filter: false, key: 6, name: " Joint Inspection", value: true },
    bill_leading: { filter: false, key: 7, name: "Bill Leading", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  register_reapply_item = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    co_number: { filter: true, key: 2, name: "CO Number", value: true },
    co_date: { filter: false, key: 3, name: "CO Date", value: true },
    invoice_number: { filter: true, key: 4, name: "Invoice Number", value: true },
    invoice_date: { filter: false, key: 5, name: "Invoice Date", value: true },
    country: { filter: true, key: 6, name: "Country", value: true },
    route: { filter: true, key: 7, name: "Route", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  register_reapply = {
    co_form: { filter: true, key: 1, name: "CO Form", value: true },
    reference: { filter: false, key: 2, name: "Reference No.", value: true },
    Application_Date: { filter: false, key: 3, name: "Application Date", value: true },
    set: { filter: false, key: 4, name: "Set", value: true },
    route: { filter: true, key: 5, name: "Route", value: true },
    joint_inspection: { filter: false, key: 6, name: " Joint Inspection", value: true },
    bill_leading: { filter: false, key: 7, name: "Bill Leading", value: true },
    last_updated: { filter: false, key: 8, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 9, name: "Updated By", value: true },
  }

  reapply_letter = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    memo: { filter: false, key: 3, name: "Memo", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }

  duplicate_letter = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    memo: { filter: false, key: 3, name: "Memo", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }

  cancel_co_letter = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    memo: { filter: false, key: 3, name: "Memo", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }


  other_letter = {
    refNo: { filter: true, key: 1, name: "Reference No.", value: true },
    application_date: { filter: false, key: 2, name: "Application Date", value: true },
    memo: { filter: false, key: 3, name: "Memo", value: true },
    last_updated: { filter: false, key: 5, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 6, name: "Updated By", value: true },
  }

  user_roles = {
    title: { filter: true, key: 2, name: "Title", value: true },
    description: { filter: false, key: 2, name: "Description", value: true },
    last_updated: { filter: false, key: 3, name: "Last Updated", value: true },
    modified_by: { filter: false, key: 4, name: "Updated By", value: true },
  }

  @action
  addUserColumn(key: string, callback) {
    const batch = this.ds.batch();

    const settings_departmentRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_department");
    const settings_coformRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_coform");
    const settings_uomRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_uom");
    const settings_countryRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_country");
    const settings_country_portRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_country_port");
    const settings_forwarderRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_forwarder");
    const settings_routeRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_route");
    const settings_product_typeRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_product_type");
    const settings_titleRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_title");
    const settings_currencyRef = this.ds.userFireRef().doc(key).collection("columns").doc("settings_currency");
    const usersRef = this.ds.userFireRef().doc(key).collection("columns").doc("users");
    const companyRef = this.ds.userFireRef().doc(key).collection("columns").doc("company");
    const hscodeRef = this.ds.userFireRef().doc(key).collection("columns").doc("hs_codes");
    const registerMaterialRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_material");
    const registerImportMaterialRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_import_material");
    const supplierRef = this.ds.userFireRef().doc(key).collection("columns").doc("supplier");
    const customerRef = this.ds.userFireRef().doc(key).collection("columns").doc("customer");
    const registerinvoiceRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_invoice");
    const registerCustomInvoiceRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_custom_invoice");


    const suppliergroupRef = this.ds.userFireRef().doc(key).collection("columns").doc("supplier_group");
    const customergroupRef = this.ds.userFireRef().doc(key).collection("columns").doc("customer_group");
    const registerproductRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_product");
    const registeritemRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_item");
    const registerexportinvRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_export_inv");
    const registercoRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_co");
    const pickupbillRef = this.ds.userFireRef().doc(key).collection("columns").doc("pickup_bill");
    const pickupinspectRef = this.ds.userFireRef().doc(key).collection("columns").doc("pickup_inspect");
    const registersooRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_soo");
    const register_submit_crfRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_submit_crf");
    const register_submit_formRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_submit_form");
    const register_master_listRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_master_list");
    const register_bomRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_bom");
    const register_purchase_orderRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_purchase_order");
    const register_soo_statementRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_soo_statement");
    const register_delivery_export_documentRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_delivery_export_document");
    const Submit_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Submit_Column_Print");
    const register_ob_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_ob_stock");
    const register_adjustment_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_adjustment_stock");
    const inventory_balanceRef = this.ds.userFireRef().doc(key).collection("columns").doc("inventory_balance");
    const inventory_detailRef = this.ds.userFireRef().doc(key).collection("columns").doc("inventory_detail");
    const inventory_stock_detailRef = this.ds.userFireRef().doc(key).collection("columns").doc("inventory_stock_detail");
    const register_return_applicationRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_return_application");
    const register_invoice_infoRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_invoice_info");
    const register_local_invoice_infoRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_invoice_info");

    const register_invoice_outstandingRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_invoice_outstanding");
    const register_import_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_import_stock");
    const register_delivery_co_applicationRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_delivery_co_application");
    const register_import_stock_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_import_stock_print");
    const register_cut_stock_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_cut_stock_print");
    const register_cut_stock_print2Ref = this.ds.userFireRef().doc(key).collection("columns").doc("register_cut_stock_print2");
    const register_delivery_import_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_delivery_import_stock");
    const delivery_return_applicationRef = this.ds.userFireRef().doc(key).collection("columns").doc("delivery_return_application");
    const register_cut_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_cut_stock");
    const register_delivery_cut_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_delivery_cut_stock");
    const SooStatement_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("SooStatement_Column_Print");
    const Soo_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Soo_Column_Print");
    const register_duplicate_itemRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_duplicate_item");
    const register_duplicateRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_duplicate");
    const register_reapply_itemRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_reapply_item");
    const register_reapplyRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_reapply");
    const register_cancel_coRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_cancel_co");
    const soo_report_column_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("soo_report_Column_Print");
    const submit_report_column_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("submit_report_column_Print");
    const inspect_report_column_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("inspect_report_column_print");
    const custom_inspect_report_column_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("custom_inspect_report_column_print");
    const invoice_report_column_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("invoice_report_column_print");

    const reapply_letterRef = this.ds.userFireRef().doc(key).collection("columns").doc("reapply_letter");
    const duplicate_letterRef = this.ds.userFireRef().doc(key).collection("columns").doc("duplicate_letter");
    const cancel_co_letterRef = this.ds.userFireRef().doc(key).collection("columns").doc("cancel_co_letter");
    const other_letterRef = this.ds.userFireRef().doc(key).collection("columns").doc("other_letter");
    const user_rolesRef = this.ds.userFireRef().doc(key).collection("columns").doc("user_roles");
    const reapply_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("reapply_Column_Print");
    const duplicate_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("duplicate_Column_Print");
    const print_templatesRef = this.ds.userFireRef().doc(key).collection("columns").doc("print_templates");
    const local_sale_invRef = this.ds.userFireRef().doc(key).collection("columns").doc("local_sale_inv");
    const register_local_deliveryRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_delivery");
    const local_cut_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("local_cut_stock");
    const local_delivery_cut_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("local_delivery_cut_stock");
    const report_local_sale_inv_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("report_local_sale_inv_print");
    const custom_local_sale_inv_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("custom_local_sale_inv_print");


    const local_cut_stock_report_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("local_cut_stock_report_column_print");
    const Local_Purchase_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Local_Purchase_Stock_Report");
    const Local_Purchase_Invoice_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Local_Purchase_Invoice_Report");
    const Local_custom_purchase_invoice_reportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Local_custom_purchase_invoice_report");

    
    const Import_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Import_Stock_Report");
    const Outstanding_Local_Purchase_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Outstanding_Local_Purchase_Stock_Report");
    const Outstanding_Release_Local_Purchase_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Outstanding_Release_Local_Purchase_Stock_Report");
    const Outstanding_Release_Import_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Outstanding_Release_Import_Stock_Report");
    const Outstanding_Release_Cut_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Outstanding_Release_Cut_Stock_Report");
    const Outstanding_Release_Local_Sale_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Outstanding_Release_Local_Sale_Stock_Report");


    const reapply_item_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("reapply_item_Column_Print");
    const register_local_cut_stock_printRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_cut_stock_print");
    const register_local_cut_stock_print2Ref = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_cut_stock_print2");
    const register_local_import_stockRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_import_stock");
    const register_local_invoiceRef = this.ds.userFireRef().doc(key).collection("columns").doc("register_local_invoice");
    const Import_Invoice_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Import_Invoice_Column_Print");
    const Custom_Import_Invoice_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Custom_Import_Invoice_Column_Print");

    const Import_Custom_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Import_Custom_Column_Print");
    const Custom_Import_Custom_Column_PrintRef = this.ds.userFireRef().doc(key).collection("columns").doc("Custom_Import_Custom_Column_Print");
    const Cut_Stock_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Cut_Stock_Report");
    const Duplicate_Invoice_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Duplicate_Invoice_Report");
    const Inventory_CO_Balance_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Inventory_CO_Balance_Report");
    const Adjustment_Customs_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Adjustment_Customs_Report");
    const Adjustment_CO_ReportRef = this.ds.userFireRef().doc(key).collection("columns").doc("Adjustment_CO_Report");



    batch.set(Adjustment_Customs_ReportRef, this.Adjustment_Customs_Report);
    batch.set(Adjustment_CO_ReportRef, this.Adjustment_CO_Report);
    batch.set(Outstanding_Release_Local_Sale_Stock_ReportRef, this.Outstanding_Release_Local_Sale_Stock_Report);
    batch.set(Outstanding_Release_Cut_Stock_ReportRef, this.Outstanding_Release_Cut_Stock_Report);
    batch.set(Outstanding_Release_Import_Stock_ReportRef, this.Outstanding_Release_Import_Stock_Report);
    batch.set(Outstanding_Local_Purchase_Stock_ReportRef, this.Outstanding_Local_Purchase_Stock_Report);
    batch.set(Outstanding_Release_Local_Purchase_Stock_ReportRef, this.Outstanding_Release_Local_Purchase_Stock_Report);
    batch.set(Inventory_CO_Balance_ReportRef, this.Inventory_CO_Balance_Report);
    batch.set(Duplicate_Invoice_ReportRef, this.Duplicate_Invoice_Report);
    batch.set(Import_Custom_Column_PrintRef, this.Import_Custom_Column_Print);
    batch.set(Custom_Import_Custom_Column_PrintRef, this.Custom_Import_Custom_Column_Print);
    batch.set(Import_Invoice_Column_PrintRef, this.Import_Invoice_Column_Print);
    batch.set(Custom_Import_Invoice_Column_PrintRef, this.Custom_Import_Invoice_Column_Print);

    batch.set(register_local_invoiceRef, this.register_local_invoice);
    batch.set(register_local_import_stockRef, this.register_local_import_stock);
    batch.set(register_local_cut_stock_print2Ref, this.register_local_cut_stock_print2);
    batch.set(register_local_cut_stock_printRef, this.register_local_cut_stock_print);
    batch.set(reapply_item_Column_PrintRef, this.reapply_item_Column_Print);
    batch.set(local_cut_stock_report_Column_PrintRef, this.local_cut_stock_report_Column_Print);
    batch.set(Local_Purchase_Stock_ReportRef, this.Local_Purchase_Stock_Report);
    batch.set(Local_Purchase_Invoice_ReportRef, this.Local_Purchase_Invoice_Report);
    batch.set(Local_custom_purchase_invoice_reportRef, this.Local_custom_purchase_invoice_report);
    
    batch.set(Import_Stock_ReportRef, this.Import_Stock_Report);
    batch.set(Cut_Stock_ReportRef, this.Cut_Stock_Report);

    batch.set(report_local_sale_inv_printRef, this.report_local_sale_inv_print);
    batch.set(custom_local_sale_inv_printRef, this.custom_local_sale_inv_print);

    batch.set(local_delivery_cut_stockRef, this.local_delivery_cut_stock);
    batch.set(local_cut_stockRef, this.local_cut_stock);
    batch.set(register_local_deliveryRef, this.register_local_delivery);
    batch.set(local_sale_invRef, this.local_sale_inv);
    batch.set(print_templatesRef, this.print_templates);
    batch.set(duplicate_Column_PrintRef, this.duplicate_Column_Print);
    batch.set(reapply_Column_PrintRef, this.reapply_Column_Print);
    batch.set(user_rolesRef, this.user_roles);
    batch.set(other_letterRef, this.other_letter);
    batch.set(reapply_letterRef, this.reapply_letter);
    batch.set(duplicate_letterRef, this.duplicate_letter);
    batch.set(cancel_co_letterRef, this.cancel_co_letter);

    batch.set(invoice_report_column_printRef, this.invoice_report_column_print);
    batch.set(inspect_report_column_printRef, this.inspect_report_column_print);
    batch.set(custom_inspect_report_column_printRef, this.custom_inspect_report_column_print);

    batch.set(submit_report_column_printRef, this.Submitreport_Column_Print);
    batch.set(soo_report_column_printRef, this.Sooreport_Column_Print);
    batch.set(register_cancel_coRef, this.register_cancel_co);
    batch.set(Soo_Column_PrintRef, this.Soo_Column_Print);
    batch.set(SooStatement_Column_PrintRef, this.SooStatement_Column_Print);
    batch.set(delivery_return_applicationRef, this.delivery_return_application);
    batch.set(register_delivery_co_applicationRef, this.register_delivery_co_application);
    batch.set(register_return_applicationRef, this.register_return_application);
    batch.set(Submit_Column_PrintRef, this.Submit_Column_Print);
    batch.set(register_delivery_export_documentRef, this.register_delivery_export_document);
    batch.set(register_soo_statementRef, this.register_soo_statement);
    batch.set(register_purchase_orderRef, this.register_purchase_order);
    batch.set(register_master_listRef, this.register_master_list);
    batch.set(register_bomRef, this.register_bom);
    batch.set(register_submit_formRef, this.register_submit_form);
    batch.set(register_submit_crfRef, this.register_submit_crf);
    batch.set(registersooRef, this.register_soo);
    batch.set(pickupinspectRef, this.pickup_inspect);
    batch.set(pickupbillRef, this.pickup_bill);
    batch.set(registercoRef, this.register_co);
    batch.set(registerexportinvRef, this.register_export_inv);
    batch.set(registeritemRef, this.register_item);
    batch.set(registerproductRef, this.register_product);
    batch.set(settings_departmentRef, this.settings_department);
    batch.set(settings_coformRef, this.settings_coform);
    batch.set(settings_uomRef, this.settings_uom);
    batch.set(settings_countryRef, this.settings_country);
    batch.set(settings_country_portRef, this.settings_country_port);
    batch.set(settings_forwarderRef, this.settings_forwarder);
    batch.set(settings_routeRef, this.settings_route);
    batch.set(settings_product_typeRef, this.settings_product_type);
    batch.set(settings_titleRef, this.settings_title);
    batch.set(settings_currencyRef, this.settings_currency);
    batch.set(usersRef, this.users);
    batch.set(companyRef, this.company);
    batch.set(hscodeRef, this.hs_codes);
    batch.set(registerMaterialRef, this.register_material);
    batch.set(registerImportMaterialRef, this.register_material);
    batch.set(supplierRef, this.supplier);
    batch.set(customerRef, this.customer);
    batch.set(registerinvoiceRef, this.register_invoice);
    batch.set(registerCustomInvoiceRef, this.register_custom_invoice);
    batch.set(suppliergroupRef, this.supplier_group);
    batch.set(customergroupRef, this.customer_group);
    batch.set(register_ob_stockRef, this.register_ob_stock);
    batch.set(register_adjustment_stockRef, this.register_adjustment_stock);
    batch.set(inventory_balanceRef, this.inventory_balance);
    batch.set(inventory_detailRef, this.inventory_detail);
    batch.set(inventory_stock_detailRef, this.inventory_stock_detail);
    batch.set(register_invoice_infoRef, this.register_invoice_info);
    batch.set(register_local_invoice_infoRef, this.register_local_invoice_info);

    batch.set(register_invoice_outstandingRef, this.register_invoice_outstanding);
    batch.set(register_import_stockRef, this.register_import_stock);
    batch.set(register_import_stock_printRef, this.register_import_stock_print);
    batch.set(register_cut_stock_printRef, this.register_cut_stock_print);
    batch.set(register_cut_stock_print2Ref, this.register_cut_stock_print2);
    batch.set(register_delivery_import_stockRef, this.register_delivery_import_stock);
    batch.set(register_cut_stockRef, this.register_cut_stock);
    batch.set(register_delivery_cut_stockRef, this.register_delivery_cut_stock);
    batch.set(register_duplicate_itemRef, this.register_duplicate_item);
    batch.set(register_duplicateRef, this.register_duplicate);
    batch.set(register_reapply_itemRef, this.register_reapply_item);
    batch.set(register_reapplyRef, this.register_reapply);

    batch.commit().then(() => {
      callback(true, null)
    }).catch(error => {
      callback(false, error)
    })
  }


  @action
  async fetchOption(companyKey, callback) {
    this.ds.sysConfigCompanyRef(companyKey).valueChanges().subscribe(docs => {
      callback(docs)
    })
  }

  @action
  async fetchPrintOption(companyKey, callback) {
    this.ds.sysPrintConfigCompanyRef(companyKey).valueChanges().subscribe(docs => {
      callback(docs)
    })
  }

  @action
  audit_trail(companyKey, appKey, callback) {
    this.ds.audit_trailByuserRef(companyKey, appKey).valueChanges().subscribe(data => {
      callback(data)
    })
  }



  subscriptionAudit: Subscription;
  @action
  async auditTrail(companyKey, appKey, callback) {
    this.subscriptionAudit && this.subscriptionAudit.unsubscribe();
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;
    const ref = this.ds.AuditTrailByuserRef(this.lastVisible, companyKey, appKey)
    this.subscriptionAudit = ref.snapshotChanges().subscribe(response => {
      this.auditData = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.auditData.push(item.payload.doc.data());
      }
      // this.infiniteAuditData(this.auditData);
      callback(this.auditData)
      this.loading = false
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async auditTrailMore(companyKey, appKey, callback) {
    this.fetching = true;
    this.subscriptionAudit && this.subscriptionAudit.unsubscribe();
    this.subscriptionAudit = this.ds.AuditTrailByuserRef(this.lastVisible, companyKey, appKey).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.auditData.push(item.data());
      }
      // this.infiniteAuditData(this.auditData);
      callback(this.auditData)
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }

  @action
  audit_trailRef(appKey, callback) {
    this.ds.audit_trailRef(appKey).valueChanges().subscribe(data => {
      callback(data)
    })
  }

  @action
  addOptionLetter(item: letterOption, companyKey, callback) {
    this.process = true;
    const { otherLetter, cancelCOLetter, reapplyLetter, duplicateLetter, LetterForm } = item
    this.ds.sysConfigCompanyRef(companyKey).set({
      cancelCOLetter: toNull(cancelCOLetter),
      reapplyLetter: toNull(reapplyLetter),
      duplicateLetter: toNull(duplicateLetter),
      otherLetter: toNull(otherLetter),
      LetterForm: toNull(LetterForm),
    }, { merge: true }).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }


  @action
  addOptionPrint(item, companyKey, callback) {
    this.process = true;
    this.ds.sysPrintConfigCompanyRef(companyKey).set({ ...item }, { merge: true }).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async fetchSetting(collectionName: string) {
    const data = await this.ds.settingRef(collectionName).get().toPromise();
    return pushToArray(data);
  }

  @action
  fetchSettingRef(collectionName: string) {
    return this.ds.settingRef(collectionName)
  }
  @action
  fetchSettingRefByCompany(collectionName: string, companyKey) {
    return this.ds.settingByCompanyRef(collectionName, companyKey)
  }
  @action
  fetchDataByCompanyDocRef(collectionName: string, id, companyKey, callback) {
    this.ds.settingDBByCompanyRef(collectionName, id, companyKey).valueChanges().subscribe(data => {
      if (data) {
        callback(data)
      }
    })
  }

  @action
  fetchSettingCallback(collectionName: string, callback: any) {
    this.ds.settingRef(collectionName).valueChanges().subscribe(docs => {
      callback(docs);
    })
  }

  @action
  async fetchRegisterItem(campanyKey: string) {
    const data = await this.ds.registerItemRef(campanyKey).get().toPromise();
    return pushToArray(data);
  }

  @action
  fetchCOForm(callback) {
    this.ds.coformRef().valueChanges().subscribe(data => {
      this.coFomData = data;
      callback(data)
    })
  }
  @action
  fetchHsCode(callback) {
    this.ds.hscodeRef().valueChanges().subscribe(data => {
      callback(data)
    })
  }
  @action
  fetchCompany(callback) {
    this.ds.companyCollectRef().valueChanges().subscribe(data => {
      this.companyData = data;
      callback(data)
    })
  }

  @action
  fetchDepartment(callback) {
    this.ds.departmentRef().valueChanges().subscribe(data => {
      this.departmentData = data;
      callback(data)
    })
  }
  @action
  fetchCountry(callback) {
    this.ds.countryRef().valueChanges().subscribe(data => {
      this.countryData = data;
      callback(data)
    })
  }

  @action
  save(collectionName: string, f: any, callback) {
    this.ds.settingRef(collectionName).doc(f.key).set(f).then(() => {
      callback(true);
    }).catch(error => {
      alert(error);
      callback(false, null)
    })
  }


  @action
  fetchUserRolePermission(userKey, callback) {
    this.process = true;
    if (userKey) {
      this.ds.settingRef("users").doc(userKey).collection('roles').doc(userKey).valueChanges().subscribe(data => {
        callback(data)
        this.process = false;
      });
    }
  }
  @action
  asignUserRolePermission(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.settingBatchRef("users").doc(item.key).collection('roles').doc(item.key);
    batch.set(ref, item)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  addUserRolePermission(item: any, currentRegister: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.settingBatchRef("user_roles").doc(item.key);
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())

    const newData = currentRegister ? difference(currentRegister, item) : null;

    const auditData = {
      olddata: {
        ...currentRegister,
      },
      newdata: {
        ...newData,
        permissions: newData && newData.permissions ? item.permissions : null,
      },

      // companyKey: item.companyKey,
      // register_status: item.register_status,
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.update,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);

    batch.set(ref, item)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }
  @action
  addUserRole(statiticFeild: string, item: any, currentRegister: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.settingBatchRef("user_roles").doc(item.key);
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())

    const newData = currentRegister ? difference(currentRegister, item) : null;
    const od = currentRegister ? currentRegister : item;
    const auditData = {
      olddata: {
        ...od
      },
      newdata: {
        ...newData,
        permissions: newData && newData.permissions ? item.permissions : null,

      },
      // companyKey: item.companyKey,
      // register_status: item.register_status,
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: currentRegister ? AuditType.update : AuditType.create,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.set(ref, item)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })

  }

  @action
  async addItem(collectionName: string, statiticFeild: string, item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(item.key);

    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const auditData = {
      olddata: {
        ...item
      },
      newdata: {
        // ...newData,
      },

      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.create,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.set(settingRef, item);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async addItemPrint(collectionName: string, f: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRefByCompany(collectionName, f.companyKey).doc(f.key);
    batch.set(settingRef, f, { merge: true });
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async addUser(collectionName: string, statiticFeild: string, item: any, role, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(item.key);
    const ref = this.ds.settingBatchRef("users").doc(role.key).collection('roles').doc(role.key);
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const auditData = {
      olddata: {
        ...item,
        roles: role.roles
      },
      newdata: {
        // ...newData,
      },
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.create,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.set(ref, role)
    batch.set(settingRef, item);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  deleteFile(collection, filename) {
    const ext = filename.split('.').pop()
    const name = filename.split('.').slice(0, -1).join('.')
    const fileRef = this.storage.ref(collection + '/' + filename);
    fileRef.delete()
  }
  @action
  changeAvatar(filelist, user, callback) {
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef('users').doc(user.key);

    if (filelist) {
      this.process = true;
      this.uploads = [];
      const allPercentage: Observable<number>[] = [];
      const filename = Math.random().toString(36).substring(7) + new Date().getTime() + filelist.name;
      const path = `users/${filename}`;
      const task = this.storage.upload(path, filelist);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);

      const uploadTrack = {
        fileName: filename,
        percentage: _percentage$
      }
      this.uploads.push(uploadTrack);
      const _t = task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const data = {
            filename: filename,
            fileurl: url,
            photoURL: url,
          }
          batch.update(settingRef, data);
          batch.commit().then(() => {
            callback(true, null);
          }).catch((error) => {
            alert(error);
            callback(false, error)
          }).finally(() => {
            this.process = false;
          })
        })
      })

      this.allPercentage = combineLatest(allPercentage)
        .pipe(
          map((percentages) => {
            let result = 0;
            for (const percentage of percentages) {
              result = result + percentage;
            }
            return result / percentages.length;
          }),
        );

    }
  }

  @action
  async updateUser(collectionName: string, item: any, oldItem: any, role, oldRole, newRoles, filelist, oldfile, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(item.key);
    const ref = this.ds.settingBatchRef("users").doc(role.key).collection('roles').doc(role.key);
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const newData = oldItem ? difference(oldItem, item) : null;
    const newRolesData = oldRole ? difference(oldRole, newRoles) : null;
    if (oldfile && filelist) {
      this.deleteFile('users', oldfile)
    }
    const auditData = {
      olddata: {
        ...oldItem,
        roles: oldRole,
      },
      newdata: {
        ...newData,
        company: newData.company ? item.company : null,
        roles: newRolesData ? newRoles : null,
      },

      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.update,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    if (filelist) {
      this.process = true;
      this.uploads = [];
      const allPercentage: Observable<number>[] = [];
      const filename = Math.random().toString(36).substring(7) + new Date().getTime() + filelist.name;
      const path = `users/${filename}`;
      const task = this.storage.upload(path, filelist);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);

      const uploadTrack = {
        fileName: filename,
        percentage: _percentage$
      }
      this.uploads.push(uploadTrack);
      const _t = task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const data = {
            ...item,
            filename: filename,
            fileurl: url,
            photoURL: url,
          }
          batch.update(ref, role)
          batch.update(settingRef, data);
          batch.commit().then(() => {
            callback(true, null);
          }).catch((error) => {
            alert(error);
            callback(false, error)
          }).finally(() => {
            this.process = false;
          })
        })
      })

      this.allPercentage = combineLatest(allPercentage)
        .pipe(
          map((percentages) => {
            let result = 0;
            for (const percentage of percentages) {
              result = result + percentage;
            }
            return result / percentages.length;
          }),
        );

    } else {
      batch.update(ref, role)
      batch.update(settingRef, item);
      batch.commit().then(() => {
        callback(true, null);
      }).catch((error) => {
        alert(error);
        callback(false, error)
      }).finally(() => {
        this.process = false;
      })
    }


  }

  @action
  async addItemByCompany(collectionName: string, statiticFeild: string, f: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRefByCompany(collectionName, f.companyKey).doc(f.key);
    const StatiticCollection = f.companykey + '_' + statiticFeild
    batch.set(settingRef, f);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async addRegisterCo(collectionName: string, statiticFeild: string, f: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(f.key);
    const registerItemRef = this.ds.settingBatchRef("register_item").doc(f.register_item.key);
    const { register_item_status } = f;

    if (register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key) {
      batch.update(registerItemRef, { pending_co_form: true });
    }

    batch.set(settingRef, f);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async saveData(f: any, statiticFeild: string, callback) {
    const docsData = await this.ds.hscodeIndexRef().get().toPromise();
    var batch = this.ds.batch();
    const hscodeRef = this.ds.itemHsCodeRef();
    const configRef = this.ds.sysConfigSettingRef();
    const node = docsData.empty ? 0 : pushToArray(docsData)[0].index;
    const item: IHSCODE = f
    const id = this.ds.createId();
    item.key = id;
    item.index = node + 1;
    item.node = (node + 1).toString() + ".";
    item.parent = (node + 1).toString();
    const { subItem } = f;
    if (subItem === null) {
      batch.update(configRef, { index: node + 1 });
    } else {
      item.node = f.subItem.node + (f.subItem.total + 1).toString() + ".";
      item.level = f.subItem.level + 1;
      item.parent = f.subItem.parent;
      batch.update(hscodeRef.doc(f.subItem.key), {
        total: f.subItem.total + 1
      });
    }
    batch.set(hscodeRef.doc(id), item);
    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });

  }

  @action
  deleteBatchData(collectionName: string, statiticFeild: string, item: any, user: any, callback) {
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(item.key)
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const auditData = {
      olddata: {
        ...item
      },
      // companyKey: item.companyKey,
      // register_status: REGISTER_ITEM_STATUS_OBJ.deleted,
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: user,
      audit_type: AuditType.delete,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.update(settingRef, { isDelete: true })
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }


  @action
  deleteBatchDataPrint(collectionName: string, f: any, callback) {
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRefByCompany(collectionName, f.companyKey).doc(f.key)
    batch.update(settingRef, { isDelete: true })
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }



  @action
  deleteBatchDataDPrint(collectionName: string, f: any, callback) {
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRefByCompany(collectionName, f.companyKey).doc(f.key)
    batch.delete(settingRef)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }



  @action
  deleteRegisterItemData(collectionName: string, statiticFeild: string, item: any, user, callback) {
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRefByCompany(collectionName, item.companyKey).doc(item.key)
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const auditData = {
      olddata: {
        ...item
      },
      // companyKey: item.companyKey,
      // register_status: REGISTER_ITEM_STATUS_OBJ.deleted,
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: user,
      audit_type: AuditType.delete,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);
    batch.update(settingRef, { isDelete: true, register_status: REGISTER_ITEM_STATUS_OBJ.deleted, deleted_at: new Date(), deleted_by: user })
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  update(collectionName: string, item: any, oldItem: any, callback) {
    this.process = true;

    const batch = this.ds.batch();
    const ref = this.ds.settingFireRef(collectionName).doc(item.key)
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const newData = oldItem ? difference(oldItem, item) : null;

    const auditData = {
      olddata: {
        ...oldItem
      },
      newdata: {
        ...newData,
      },
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.update,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);

    batch.update(ref, item)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }



  @action
  updateCountry(collectionName: string, item: any, oldItem: any, callback) {
    this.process = true;

    const batch = this.ds.batch();
    const ref = this.ds.settingFireRef(collectionName).doc(item.key)
    const audit_trailRef = this.ds.settingFireRef('audit_trail').doc(this.ds.createId())
    const newData = oldItem ? difference(oldItem, item) : null;
    const auditData = {
      olddata: {
        ...oldItem
      },
      newdata: {
        ...newData,
        co_form: newData.co_form ? item.co_form : null,
      },
      // companyKey: item.companyKey,
      // register_status: item.register_status,
      updated_by: item.updated_by ? item.updated_by : null,
      created_by: item.created_by ? item.created_by : null,
      deleted_by: item.deleted_by ? item.deleted_by : null,
      audit_type: AuditType.update,
      audit_date: new Date(),
      appKey: item.key,
    }
    batch.set(audit_trailRef, auditData);

    batch.update(ref, item)
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  updateByCompany(collectionName: string, f: any, callback) {
    this.ds.settingBatchRefByCompany(collectionName, f.companyKey).doc(f.key).update(f).then(() => {
      callback(true);
    }).catch(error => {
      alert(error);
      callback(false, null)
    })
  }

  @action
  updateRegisterCO(collectionName: string, f: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const settingRef = this.ds.settingBatchRef(collectionName).doc(f.key);
    const registerItemRef = this.ds.settingBatchRef("register_item").doc(f.register_item.key);
    const { register_item_status } = f;

    if (register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key) {
      batch.update(registerItemRef, { pending_co_form: true });
    }

    batch.update(settingRef, f);
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      alert(error);
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  deleteData(collectionName: string, f: any, callback) {
    this.ds.settingRef(collectionName).doc(f.key).update({ isDelete: true }).then(() => {
      callback(true);
    }).catch(error => {
      alert(error);
      callback(false, null)
    })
  }

  @action
  deleteDataUser(collectionName: string, f: any, callback) {
    this.ds.settingRef(collectionName).doc(f.key).delete().then(() => {
      callback(true);
    }).catch(error => {
      alert(error);
      callback(false, null)
    })
  }


  @action
  delete(data: any, callback) {
    this.deleteProcess = true;
    this.ds.uomRef().doc(data.key).update({ isDelete: true }).then(success => {
      callback(true, null)
    }).catch(error => {
      callback(false, error)
    })
  }

  @action
  detailUOM(id, callback) {
    this.process = true;
    this.ds.uomRef().doc(id).valueChanges().subscribe(data => {
      this.process = false
      this.singleData = data;
      callback(data);
    })
  }

  @action
  async detailCollection(collectionName: string, id, callback) {
    this.process = true;
    this.ds.settingRef(collectionName).doc(id).valueChanges().subscribe(data => {
      this.singleData = data
      this.process = false;
      callback(this.singleData);
    })

  }


  @action
  async companyUsers(companyKey, callback) {
    this.process = true;
    const userCompany = pushToArray(await this.ds.collectionFireRef('users').where('companyKey', 'array-contains', companyKey).get());
    callback(userCompany);
    this.process = false;

  }


  @action
  async fetchCollectionPrintTemplate(companyKey, appKey, callback) {
    this.process = true;
    this.ds.print_templatesRef(companyKey, appKey).valueChanges().subscribe(data => {
      this.singleData = data
      this.process = false;
      callback(this.singleData);
    })

  }

  @action
  async detailCollectionByCompany(collectionName: string, id, companyKey, callback) {
    this.process = true;
    const data = await this.ds.settingFireByCompanyRef(collectionName, companyKey).doc(id).get()
    this.singleData = pushToObject(data)
    this.process = false;
    callback(this.singleData);
  }

  @action
  fetchRowIndex(index: number) {
    if (index === 0) {
      this.startNo = this.startIndex;
    }
    return this.startIndex + index;
  }

  @action
  resoleSetting(collectionName) {
    const batch = this.ds.batch()
    const ref = this.ds.settingFireRef(collectionName)
    this.data.forEach(m => {
      const { code, name, key, updated_at,
        updated_by, country
      } = m;

      let updatedBy = null;
      if (!updatedBy) {

        updatedBy = {
          key: "bpVwK0mZgGsosSXfm8Fs",
          displayName: "Admin",
          uid: "bpVwK0mZgGsosSXfm8Fs",
          full_name: "Admin",
          email: "admin@gmail.com",
        }
      }
      const tag = [
        key,
        updatedBy.key,
        ConvertService.toDateKey(new Date()),
        ConvertService.toCapitalize(`${code}`.trim()),
        ConvertService.toCapitalize(`${name}`.trim()),
        country ? country.id : null,
        `STATUS_ACTIVE`,
        `~N/A~`,
      ]

      batch.update(ref.doc(m.key), {
        date_key: ConvertService.toDateKey(new Date()),
        name_en: ConvertService.toCapitalize(`${code}`.trim()),
        name_kh: ConvertService.toCapitalize(`${name}`.trim()),
        country: country ? country : null,
        countryRef: country ? country : null,
        countryKey: country ? country.id : null,
        tag: tag,
        updated_by: updatedBy,
        updated_at: new Date(),
        status: {
          key: 1,
          text: "Active"
        }
      })
    })

  }


  @action
  async resoleCountry() {
    const ref = this.ds.settingFireRef('settings_country')
    const data = pushToArray(await ref.get());
    console.log(data.length);
    if (data && data.length > 0) {
      data.forEach(m => {
        const { code, name } = m;
        ref.doc(m.key).update({
          keyword: generateKeywords([name, code]),
        }).then(() => {
          console.log('success');
        }).catch(error => {
          console.log('error', error);
        })

      })
    }
  }

  @action
  async fetchListing(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.fetching = false;
    const docsData = await this.ds.lazySettingsRef(collectionName, this.lastVisible, filterForm).get().toPromise();
    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }

    this.data = docs;
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);

    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }





  @action
  async fetchReportHistoryListing(orderBy: string, filterForm: any, typeKey, companyKey) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;
    const ref = this.ds.lazyLoadReportHistoryRef(this.lastVisible, filterForm, typeKey, companyKey, orderBy)
    this.fetchRegisterListingRef = ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteSData(this.data);
      this.loading = false
      this.fetching = false;

    }, error => {
      console.log('error', error);
      this.loading = false;
    });

  }
  @action
  async fetchReportHistoryMore(filterForm: any, typeKey, companyKey, orderBy) {
    this.fetching = true;
    this.fetchRegisterListingRef = this.ds.lazyLoadReportHistoryRef(this.lastVisible, filterForm, typeKey, companyKey, orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteSData(this.data);
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }


  @action
  async fetchSettingListingAll(collectionName: string, orderBy: string, statusKey, filterForm: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.fetching = false;
    this.statusKey = statusKey;
    const ref = this.ds.lazyLoadSettingAllRef(collectionName, statusKey);

    this.fetchRegisterListingRef = ref.snapshotChanges().pipe(
      shareReplay()
    ).subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      callback(this.data);
      this.loading = false
      this.fetching = false;

    }, error => {
      console.log('error', error);
      this.loading = false;
    });

  }





  @action
  async fetchSettingListing(collectionName: string, orderBy: string, statusKey, filterForm: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.fetching = false;
    this.statusKey = statusKey;
    const ref = this.ds.lazyLoadSettingRef(collectionName, this.lastVisible, statusKey, filterForm, orderBy)
    this.fetchRegisterListingRef = ref.snapshotChanges().pipe(
      shareReplay()
    ).subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteSData(this.data);

      callback(this.data);
      this.loading = false
      this.fetching = false;

    }, error => {
      console.log('error', error);
      this.loading = false;
    });

  }
  @action
  async fetchSettingMore(collectionName: any, filterForm: any, orderBy, callback) {
    this.fetching = true;
    this.fetchRegisterListingRef = this.ds.lazyLoadSettingRef(collectionName, this.lastVisible, this.statusKey, filterForm, orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteSData(this.data);

      callback(this.data);
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }

  @action
  async fetchRegisterListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterRef(collectionName, this.lastVisible, filterForm, this.registerStatus, orderBy, this.companyKey).withConverter(stockConverter).get();
    const docs = pushToArray(docsData)
    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }

    this.data = docs;

    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);

  }
  @action
  async fetchRegisterMore(collectionName: any, filterForm: any, callback) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.orderBy, this.companyKey).withConverter(stockConverter).get();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))

    this.infiniteSData(this.data);
    callback(this.data);
    this.fetching = false;
  }

  @action
  async fetchRegisterListingByType(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, registerStatus: any, typeKey: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.typeKey = typeKey
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterRefByType(collectionName, this.lastVisible, filterForm, this.registerStatus, this.typeKey, this.orderBy, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData)
    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }
    this.data = docs;
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }


  @action
  async fetchRegisterListingOutstandingCOSOO(collectionName: string, outtandingField: string, outtandingOtherField: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterOutstandingCOSOORef(collectionName, outtandingField, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();

    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }

    this.data = docs;
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }
  @action
  async fetchRegisterOutstandingCOSOOMore(collectionName: any, outtandingField, outtandingOtherField, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterOutstandingCOSOORef(collectionName, outtandingField, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.fetching = false;
  }


  @action
  async fetchRegisterListingOutstandingCO(collectionName: string, outtandingField: string, outtandingOtherField: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const ref = this.ds.lazyRegisterOutstandingCORef(collectionName, outtandingField, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy)
    ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }

      callback(this.data);

      this.loading = false
      this.fetching = false;

    }, error => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchRegisterOutstandingCOMore(collectionName: any, outtandingField, outtandingOtherField, filterForm: any, callback) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    this.ds.lazyRegisterOutstandingCORef(collectionName, outtandingField, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }

      callback(this.data)

      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }


  @action
  async fetchRegisterListingOutstandingStock(collectionName: string, outtandingField: string, outtandingFieldKey: string, outtandingOtherField: string, filterForm: any, registerStatus: any, companyKey: any, orderBy, callback) {
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    this.orderBy = orderBy
    const ref = this.ds.lazyRegisterOutstandingStockRef(collectionName, outtandingField, outtandingFieldKey, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy)
    this.fetchRegisterListingOutstandingStockRef = ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      callback(this.data);

      this.loading = false
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchRegisterOutstandingStockMore(collectionName: any, outtandingField, outtandingFieldKey, outtandingOtherField, filterForm: any, callback) {
    this.fetching = true;
    this.fetchRegisterListingOutstandingStockRef = this.ds.lazyRegisterOutstandingStockRef(collectionName, outtandingField, outtandingFieldKey, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))

      this.infiniteData(this.data);
      callback(this.data)
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }

  @action
  async fetchRegisterListingOutstanding(collectionName: string, outtandingField: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const ref = this.ds.lazyRegisterOutstandingRef(collectionName, outtandingField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy)
    this.lazyRegisterOutstandingRef = ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteData(this.data);
      callback(this.data);

      this.loading = false
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchRegisterOutstandingMore(collectionName: any, outtandingField, filterForm: any, callback) {
    this.fetching = true;
    this.lazyRegisterOutstandingRef = this.ds.lazyRegisterOutstandingRef(collectionName, outtandingField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.data = this.data.map((f, index) => ({
        ...f,
        rowIndex: index + 1
      }))
      this.infiniteData(this.data);
      callback(this.data)
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }



  @action
  async fetchRegisterMoreByType(collectionName: any, filterForm: any, callback) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterRefByType(collectionName, this.lastVisible, filterForm, this.registerStatus, this.typeKey, this.orderBy, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    callback(this.data)
    this.fetching = false;
  }


  @action
  async fetchRegisterNSListing(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    // const docsData = await this.ds.settingListingRef(collectionName, this.lastVisible, this.orderBy, this.filterStatus).get().toPromise();
    const docsData = await this.ds.lazyRegisterNSRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();

    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }

    this.data = docs;
    this.empty = docsData.empty;
    this.loading = false;
    // this.clearDelete(collectionName)
    // this.resoleSetting(collectionName)
    callback(this.data);
  }

  @action
  async fetchRegisterNSMore(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterNSRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.fetching = false;
  }

  @action
  async fetchMore(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazySettingsRef(collectionName, this.lastVisible, filterForm).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.fetching = false;
  }

  @action
  clearDelete(collectionName) {
    const batch = this.ds.batch();
    const ref = this.ds.settingFireRef(collectionName)
    const rows = this.data.filter(m => m.isDelete);
    rows.forEach(m => {
      batch.delete(ref.doc(m.key))
    })
    batch.commit()
  }

  @action
  async fetchSHListing(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, filterForm: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.fetching = false;
    const docsData = await this.ds.lazySHSettingsRef(collectionName, this.lastVisible, filterForm).get().toPromise();
    const docs = pushToArray(docsData)
    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }

    this.data = docs;
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }

  @action
  async fetchSHMore(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazySHSettingsRef(collectionName, this.lastVisible, filterForm).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.fetching = false;

  }

  @action
  async fetchListingMaterial(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterMaterialRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }
    this.data = docs;
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }



  @action
  async fetchMoreMaterial(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterMaterialRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);

    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);
    this.fetching = false;
  }
  @action
  async fetchListingByCm(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterNSRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }
    this.data = docs;
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }



  @action
  async fetchMoreByCM(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterNSRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);

    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.data = this.data.map((f, index) => ({
      ...f,
      rowIndex: index + 1
    }))
    this.infiniteSData(this.data);
    this.fetching = false;
  }



  @action
  async fetchDataByCm(collectionName: string, orderBy: string, filterForm: any, application_type: number, companyKey: any, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.companyKey = companyKey;
    this.fetching = false;
    const docsData = await this.ds.lazyRegisterDataPrintRef(collectionName, this.lastVisible, filterForm, application_type, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData)

    if (!docsData.empty) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.lastVisible = null;
      this.done = true;
    }
    this.data = docs;
    this.empty = docsData.empty;
    this.loading = false;
    callback(this.data);
  }



  @action
  async fetchDataMoreByCM(collectionName: any, filterForm: any) {
    if (this.fetching || this.lastVisible === null || this.loading) { return; }
    this.fetching = true;
    const docsData = await this.ds.lazyRegisterDataPrintRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().toPromise();
    const docs = pushToArray(docsData);

    if (docs.length > 0 && docs.length === APPS.settingSize) {
      this.lastVisible = docs[docs.length - 1];
    } else {
      this.done = true;
      this.lastVisible = null;
    }
    this.data = this.data.slice().concat(docs);
    this.fetching = false;
  }

  @action
  async fetchData(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, callback) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    const docs = await this.ds.settingListingRef(collectionName, this.lastVisible, this.orderBy, this.filterStatus).get().toPromise();

    const statisticDoc = await this.ds.environmentRef().get().toPromise();
    const statisticData = pushToObject(statisticDoc);
    this.data = pushToArray(docs);
    this.items = this.data;
    if (!docs.empty) {
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
    }

    if (statisticData && statisticData.length > 0) {

    }

    const totalRow = statisticData[`${statisticName}`];
    this.totalRow = totalRow;
    this.totalSize = totalRow;
    const i = parseInt(`${this.totalSize / APPS.size}`);
    const j = this.totalSize % APPS.size > 0 ? 1 : 0;
    this.totalPage = i + j;
    this.pageIndex = 1;
    this.startIndex = 1;
    this.empty = this.data.length === 0;
    this.loading = false;
    this.ds.userprofileRef().doc(uid).collection("histories").doc(routerName).set({
      url: url,
      name: menuName,
      login_date: new Date(),
      page_key: ConvertService.pageKey(),
      key: routerName,
      icon: icon,
    })
    callback(this.data);

  }

  @action
  async fetchNext(collectionName: string) {
    if (this.pageIndex === this.totalPage) return;
    this.loading = true;
    const docs = await this.ds.settingListingRef(collectionName, this.lastVisible, this.orderBy, this.filterStatus).get().toPromise();
    if (!docs.empty) {
      this.data = pushToArray(docs);
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
      this.items = this.items.concat(this.data);
    }
    this.startIndex = (this.pageIndex * APPS.size) + 1;
    this.pageIndex = this.pageIndex + 1;
    this.loading = false;
  }


  @action
  async fetchRegisterItemData(collectionName: string, routerName: string, url: any, icon: any, menuName: string, uid: string, statisticName: string, orderBy: string, registerStatusKey: string, companyKey: string) {
    this.orderBy = orderBy;
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    const docs = await this.ds.resisterItemListingRef(collectionName, this.lastVisible, this.orderBy, this.filterStatus, registerStatusKey, companyKey).get().toPromise();
    const statisticDoc = await this.ds.environmentRef().get().toPromise();
    const statisticData = pushToObject(statisticDoc);
    const gdata = pushToArray(docs);
    this.data = MappingService.orderByDesc(gdata, 'page_key')
    this.items = this.data;

    if (!docs.empty) {
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
    }

    if (statisticData && statisticData.length > 0) {

    }

    const totalRow = statisticData[`${statisticName}`];
    this.totalRow = totalRow;
    this.totalSize = totalRow;
    const i = parseInt(`${this.totalSize / APPS.size}`);
    const j = this.totalSize % APPS.size > 0 ? 1 : 0;
    this.totalPage = i + j;
    this.pageIndex = 1;
    this.startIndex = 1;
    this.empty = this.data.length === 0;
    this.loading = false;
    this.ds.userprofileRef().doc(uid).collection("histories").doc(routerName).set({
      url: url,
      name: menuName,
      login_date: new Date(),
      page_key: ConvertService.pageKey(),
      key: routerName,
      icon: icon,
    })
  }

  @action
  async fetchRegisterItemNext(collectionName: string, registerStatusKey: string, companyKey: string) {
    if (this.pageIndex === this.totalPage) return;
    this.loading = true;
    const docs = await this.ds.resisterItemListingRef(collectionName, this.lastVisible, this.orderBy, this.filterStatus, registerStatusKey, companyKey).get().toPromise();
    if (!docs.empty) {
      const gdata = pushToArray(docs);
      // this.data = pushToArray(docs);
      this.data = MappingService.orderByDesc(gdata, 'page_key')

      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
      this.items = this.items.concat(this.data);
    }
    this.startIndex = (this.pageIndex * APPS.size) + 1;
    this.pageIndex = this.pageIndex + 1;
    this.loading = false;
  }

  @action
  async fetchBack() {
    this.loading = true;
    const dataSize = this.data.length;
    const list = this.items.slice(0, this.items.length - dataSize)
    this.items = list;
    const docs = list.slice(list.length - APPS.size, list.length);
    this.data = docs;
    if (this.data.length > 0) {
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
    }
    this.pageIndex = this.pageIndex - 1;
    this.startIndex = this.startNo - APPS.size
    this.loading = false;
  }

  @action
  async fetchFilter(collectionName: string, statusKey: any, statisticName: string, orderBy: string) {
    this.loading = true;
    this.lastVisible = null;
    this.orderBy = orderBy;
    this.filterStatus = statusKey;
    const docs = await this.ds.settingListingRef(collectionName, this.lastVisible, this.orderBy, statusKey).get().toPromise();
    const statisticDoc = await this.ds.environmentRef().get().toPromise();
    const statisticData = pushToObject(statisticDoc);
    this.data = pushToArray(docs);
    this.items = this.data;
    if (!docs.empty) {
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
    }
    const totalRow = statisticData[`${statisticName}`];
    this.totalRow = totalRow;
    this.totalSize = totalRow;
    const i = parseInt(`${this.totalSize / APPS.size}`);
    const j = this.totalSize % APPS.size > 0 ? 1 : 0;
    this.totalPage = i + j;
    this.pageIndex = 1;
    this.startIndex = 1;
    this.empty = this.data.length === 0;
    this.loading = false;
  }

  @action
  async fetchRegisterItemFilter(collectionName: string, statusKey: any, statisticName: string, orderBy: string, registerStatusKey: string, companyKey: string) {
    this.loading = true;
    this.lastVisible = null;
    this.orderBy = orderBy;
    this.filterStatus = statusKey;
    const docs = await this.ds.resisterItemListingRef(collectionName, this.lastVisible, this.orderBy, statusKey, registerStatusKey, companyKey).get().toPromise();
    const statisticDoc = await this.ds.environmentRef().get().toPromise();
    const statisticData = pushToObject(statisticDoc);
    this.data = pushToArray(docs);
    this.items = this.data;
    if (!docs.empty) {
      this.lastVisible = this.data[this.data.length - 1];
      this.firstVisible = this.data[0];
    }
    const totalRow = statisticData[`${statisticName}`];
    this.totalRow = totalRow;
    this.totalSize = totalRow;
    const i = parseInt(`${this.totalSize / APPS.size}`);
    const j = this.totalSize % APPS.size > 0 ? 1 : 0;
    this.totalPage = i + j;
    this.pageIndex = 1;
    this.startIndex = 1;
    this.empty = this.data.length === 0;
    this.loading = false;
  }

  @action
  async fetchSearch(collectionName: string, field: string, search: any, callback) {
    if (search) {
      return this.ds.searchListingRef(collectionName, field, search).valueChanges().subscribe(docs => {
        this.data = docs;
        callback(true, null)
      });
    } else {
      callback(false, null)
    }
  }

  @action
  async fetchRegisterSubmitListing(collectionName: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
    this.loading = true;
    this.lastVisible = null;
    this.filterStatus = 0;
    this.registerStatus = registerStatus;
    this.companyKey = companyKey;
    this.fetching = false;
    const ref = this.ds.lazyRegisterSubmitRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey)
    ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        callback(this.data)
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }

      callback(this.data);

      this.loading = false
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchRegisterSubmitMore(collectionName: any, filterForm: any, callback) {

    this.fetching = true;
    this.ds.lazyRegisterSubmitRef(collectionName, this.lastVisible, filterForm, this.registerStatus, this.companyKey).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      callback(this.data)
      this.fetching = false;
    }, error => {
      console.log('error', error);
      this.fetching = false;
    });
  }

}
