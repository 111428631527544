export const APPS = {
  size: 25,
  settingSize:25,
  smallsize:5,
}
export const APPS_SIZE = [
  { key: 10, text: '10' },
  { key: 30, text: '30' },
  { key: 60, text: '60' },
  { key: 100, text: '100' },
  { key: 1000, text: '1000' },
  { key: 5000, text: '5000' },
]

export const TEMPLATE_TYPES = {
  HS_CODE_TEMPLATE: { key: 'HS_CODE_TEMPLATE', name: "HS Code Template" }
}
