<div class="comment-list">
    <div class="chat">
        <div class="file-lists" *ngFor="let file of cm?.files;">
            <div class="user">
                <div class="avatar">
                    <div class="name" [ngClass]="auth?.user?.key === file?.created_by?.key?'msg-owner':''">
                        {{ file?.created_by?.name }}</div>
                </div>
            </div>
            <div class="fl-item">

                <img [src]="file|iconDrive" style="width: 33px;" />



                <a [href]="file?.downloadUrl" target="_blank" style="margin-left: 12px; flex:1 ;    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;">
                    {{file?.originname}}
                </a>

                <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="auth?.user?.key === file?.created_by?.key"
                    aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteFile(file)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>

            <div class="date" style="font-size: 11px; color: #686868;"
                [ngClass]="auth?.user?.key === file.created_by?.key?'msg-owner':''">
                {{ file.created_at?.toDate()|date:'medium' }} ~
                {{ file.created_at?.toDate() | amTimeAgo }}</div>
        </div>

    </div>
</div>