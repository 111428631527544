// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {

    //TESTING
    apiKey: "AIzaSyA1o4d_X79fbFjJPn1VqyYnDvZbVCdsDQE",
    authDomain: "cocamwinco.firebaseapp.com",
    databaseURL: "https://cocamwinco.firebaseio.com",
    projectId: "cocamwinco",
    storageBucket: "cocamwinco.appspot.com",
    messagingSenderId: "137591474676",
    appId: "1:137591474676:web:8b9b6cc22fd6ba510651a1",
    measurementId: "G-EB768RT2WM"

    // PRODUCTION
    // apiKey: "AIzaSyBedFg4vicMPy17JKSgow3_oaIR9rmObPw",
    // authDomain: "bcocamwin.firebaseapp.com",
    // databaseURL: "https://bcocamwin.firebaseio.com",
    // projectId: "bcocamwin",
    // storageBucket: "bcocamwin.appspot.com",
    // messagingSenderId: "263647408782",
    // appId: "1:263647408782:web:adadfbc9a1ee2dc09f4fdf"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
