<mat-sidenav-container style="min-height: calc(100vh - 168px)">
    <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'" class="content-side"
        [fixedInViewport]="true" [fixedTopGap]="118" [style.width]="ismobile?'100%':'50%'" [fixedBottomGap]="0">
        <button mat-icon-button (click)="sidenav.toggle()"
            style="position: absolute; right: 24px; z-index: 999; top: 4px;">
            <mat-icon class="mat-18">clear</mat-icon>
        </button>
        <mat-tab-group style="height: 100%; ">

            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    Comment
                </ng-template>
                <div class="comment-body">
                    <div class="comment-list">
                        <div class="chat">
                            <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                                *ngFor="let msg of cm?.data;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                            {{ msg?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="msg-item">
                                    <div class="c-content"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                                        <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                                            <mat-icon class="mat-18"
                                                style="font-size: 10px; display: flex; align-items: center;">edit
                                            </mat-icon>
                                            <span style="font-size: 10px;">Edited</span>
                                        </div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === msg?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editComment(msg)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="date"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        {{ msg.created_at?.toDate()|date:'medium' }} ~
                                        {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <form class="editor-block" style="position: relative;" [formGroup]="form">
                        <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
                            customToolbarPosition="top">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected value="roboto">Roboto</option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>

                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>

                                <span class="ql-formats">

                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-align" [title]="'Aligment2'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-color" [title]="'Color'">
                                    </select>
                                    <select class="ql-background" [title]="'BackgroundColor'">
                                    </select>
                                </span>
                                <div class="custom-tb">
                                    <span class="ql-formats">
                                        <button class="ql-bold" [title]="'Bold'"></button>
                                        <button class="ql-italic" [title]="'Italic'"></button>
                                        <button class="ql-underline" [title]="'Underline'"></button>
                                        <button class="ql-strike" [title]="'Strike'"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                    </span>
                                    <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                                        (click)="postComment(form.value, data)">Post
                                    </button>
                                </div>

                            </div>


                        </quill-editor>
                        <div style="height: 68px;"></div>
                    </form>
                </div>
            </mat-tab>

            <mat-tab style="height: 100%; ">
                <ng-template mat-tab-label>
                    Files
                </ng-template>
                <div class="comment-body file-manager">
                    <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate"
                        [value]="pct">
                    </mat-progress-bar>
                    <div class="comment-list">
                        <div class="chat">
                            <div class="file-lists" *ngFor="let file of cm?.files;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === file?.created_by?.key?'msg-owner':''">
                                            {{ file?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="fl-item">
                                    <img [src]="file|iconDrive" style="width: 33px;" />



                                    <a [href]="file?.downloadUrl" target="_blank" style="margin-left: 12px; flex:1 ;    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;">
                                        {{file?.originname}}
                                    </a>
                                    <ng-container *ngIf="auth?.userRoles | userPermissionRole:'delete_file_manager'">
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === file?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteFile(file)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Delete</span>
                                            </button>
                                        </mat-menu>
                                    </ng-container>

                                </div>

                                <div class="date" style="font-size: 11px; color: #686868;"
                                    [ngClass]="auth?.user?.key === file.created_by?.key?'msg-owner':''">
                                    {{ file.created_at?.toDate()|date:'medium' }} ~
                                    {{ file.created_at?.toDate() | amTimeAgo }}</div>
                            </div>

                        </div>
                    </div>
                    <div style="padding: 12px; text-align: center;">

                        <form [formGroup]="formUpload">
                            <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                                <a type="button" mat-button class="attach_file upload-button"
                                    (click)="fileInput.click()">
                                    <mat-icon class="mat-18">attach_file</mat-icon>
                                    <span>Add New Attachment</span>
                                    <input #fileInput type="file" multiple
                                        accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file
                                        formControlName="file" (change)="onSelectedFile(file.files)"
                                        style="display:none;" />
                                </a>
                            </ng-container>

                        </form>

                    </div>
                </div>
            </mat-tab>
            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    History
                </ng-template>
                <div class="audit-lists">
                    <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="cs-form-container cs-step-width">
            <div *ngIf="!store?.process" class="form-wrapper" style="padding:33px 0;" id="print">
                <div class="hd-row" style="padding:0 33px 16px 33px">
                    <h4 class="hd-title">{{title}}</h4>
                    <div class="flex-1"></div>
                    <ng-container *ngIf="data">

                        <div>
                            <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;"
                                matTooltip="Print" class="hideOnPrint">
                                <mat-icon class="mat-18">print</mat-icon>
                            </button>
                            <ng-container *ngIf="!isHide">


                                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Delete"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_export_inv') && (collection == 'register_item')">
                                    <mat-icon class="mat-18">delete</mat-icon>
                                </button>

                                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Delete"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_export_inv_usage') && (collection == 'register_item_usage') && !canDeleteUpdate">
                                    <mat-icon class="mat-18">delete</mat-icon>
                                </button>

                                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Delete"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'delete_local_sale_inv') && (collection == 'local_sale_inv')">
                                    <mat-icon class="mat-18">delete</mat-icon>
                                </button>

                                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Delete"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'delete_local_sale_inv_usage') && (collection == 'local_sale_inv_usage')">
                                    <mat-icon class="mat-18">delete</mat-icon>
                                </button>



                                <button mat-icon-button [routerLink]="link" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Edit"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_export_inv') && (collection == 'register_item')">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                </button>

                                <button mat-icon-button [routerLink]="link" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Edit"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_export_inv_usage') && (collection == 'register_item_usage') && !canDeleteUpdate">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                </button>

                                <button mat-icon-button [routerLink]="link" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Edit"
                                    *ngIf="(collection == 'local_sale_inv') && (auth?.userRoles | userPermissionRole:'update_local_sale_inv') ">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                </button>

                                <button mat-icon-button [routerLink]="link" style="margin-right: 8px;"
                                    class="hideOnPrint" matTooltip="Edit"
                                    *ngIf="(auth?.userRoles | userPermissionRole:'update_local_sale_inv_usage') && (collection == 'local_sale_inv_usage')">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                </button>



                            </ng-container>




                            <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                                <mat-icon class="mat-18">insert_comment</mat-icon>
                            </button>
                        </div>

                    </ng-container>
                </div>
                <ng-container *ngIf="collection === 'register_item_usage'">
                    <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    CO Export Invoice
                                </ng-template>

                                <div style="padding: 33px">
                                    <ng-container>
                                        <div class="dl-items">
                                            <div class="dl-title"> CO Form</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.co_formRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Export Invoice Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'invoice_number' | async}}

                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Export Invoice Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{(data?.register_itemRef | docRef:'invoice_date' | async)?.toDate()
                                                    | date}}

                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> CAT</div>
                                            <div class="dl-text">:
                                                <div class="data"
                                                    *ngIf="(data?.register_itemRef | docRef:'cat' | async) > 0">
                                                    {{data?.register_itemRef | docRef:'cat' | async | number:'2.'}}

                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Amount</div>
                                            <div class="dl-text">:
                                                <div class="data"
                                                    *ngIf="(data?.register_itemRef | docRef:'amount_FOB' | async) > 0">
                                                    {{(data?.register_itemRef | docRef:'amount_FOB' | async) |
                                                    currency:' ':'code'}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Destination</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.countryRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">

                                            <div class="dl-title"> Route</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.routeRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Packing</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'qty_packing' | async |
                                                    number:'1.'}}

                                                    <span *ngIf="data?.qty_packing_UOMRef">

                                                        {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Unit</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}

                                                    <span *ngIf="data?.qty_unit_UOMRef">

                                                        {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Last Update</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Status</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.status?.text}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.material?.length > 0" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    CO Material
                                </ng-template>
                                <div style="padding: 33px">
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="data?.material?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material Code</th>
                                                    <th class="table-col center">CO Material Name</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">NN.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <!-- <td class="table-col">
                                                    </td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items" *ngIf="data?.material?.length == 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>


                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.importMaterial?.length > 0" class="mat-18"
                                        style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Customs Material
                                </ng-template>
                                <div style="padding: 33px">
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="data?.importMaterial?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <!-- <td class="table-col">
                                                    </td> -->
                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                                        KG

                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                                        KG

                                                    </td>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="table-col right" colspan="3">
                                                        Total
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'qtyUnit') | number}}
                                                    </td>
                                                    <td class="table-col right">
                                                        <!-- {{ (data?.importMaterial | sumAmount:'unitPrice') | number}} -->
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'totalCost') | number}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items"
                                        *ngIf="!data?.importMaterial || data?.importMaterial?.length === 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </ng-container>

                <ng-container *ngIf="collection === 'register_item'">
                    <div class="" *ngIf="!store?.process" #content id="contentToConvert">

                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Export Invoice
                                </ng-template>

                                <div style="padding: 33px">
                                    <ng-container>
                                        <div class="dl-items">
                                            <div class="dl-title"> CO Form</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.co_formRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Export Invoice Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.invoice_number?data?.invoice_number:null}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Export Invoice Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.invoice_date?.toDate() | date}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Product Name</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.product_name}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> CAT</div>
                                            <div class="dl-text">:
                                                <div class="data" *ngIf="data?.cat > 0">
                                                    {{data?.cat | number:'2.'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Amount</div>
                                            <div class="dl-text">:
                                                <div class="data" *ngIf="data?.amount_FOB > 0">
                                                    {{data?.amount_FOB | currency:' ':'code'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Destination</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.countryRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">

                                            <div class="dl-title"> Route</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.routeRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Packing</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.qty_packing | number:'1.'}}
                                                    <span *ngIf="data?.qty_packing_UOMRef">

                                                        {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Unit</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.qty_unit | number:'1.'}}
                                                    <span *ngIf="data?.qty_unit_UOMRef">

                                                        {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Last Update</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Status</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.status?.text}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="registerItemUsage" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Export Inv. Usage

                                </ng-template>
                                <div style="padding: 33px">

                                    <div *ngIf="data?.registerItemUsageKey"
                                        style="display: flex; justify-content: flex-end;">
                                        <button mat-icon-button
                                            [routerLink]="['/register-export-inv-usage/detail/' + data?.registerItemUsageKey]">
                                            <mat-icon class="mat-18">info</mat-icon>
                                        </button>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="material?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material Code</th>
                                                    <th class="table-col center">CO Material Name</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">NN.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <!-- <td class="table-col">
                                                    </td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items" *ngIf="material?.length == 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.registerCoKey" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Export CO

                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="data?.registerCoRef">
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/register-co/detail/' + data?.registerCoKey]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>


                                        <div class="dl-items">
                                            <div class="dl-title"> CO Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.registerCoRef | docRef:'co_number' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> CO Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.registerCoRef | docRefDate:'co_date' | async | date}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Shipping Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.registerCoRef | docRefDate:'etd' | async | date}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> ADM Fee</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.registerCoRef | docRef:'admFee' | async | number}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> EMF Fee</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.registerCoRef | docRef:'emfFee' | async | number}}
                                                </div>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <div class="dl-items" *ngIf="!data?.registerCoRef"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register CO!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.pickUpBillKey" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Export Pickup Bill

                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="data?.pickUpBillRef">
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/pickup-bill/detail/' + data?.pickUpBillKey]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>


                                        <div class="dl-items">
                                            <div class="dl-title"> Bill Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.pickUpBillRef | docRef:'bill_number' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Bill Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.pickUpBillRef | docRefDate:'bill_date' | async | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>


                                    <div class="dl-items" *ngIf="!data?.pickUpBillRef"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register pickup bill!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>

                                    <mat-icon *ngIf="data?.inspectionKey" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Export Pickup Inspect

                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="data?.inspectionRef">
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/pickup-inspect/detail/' + data?.inspectionKey]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Inspect Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.inspectionRef | docRef:'inspect_number' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Inspect Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.inspectionRef | docRefDate:'inspect_date' | async | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dl-items" *ngIf="!data?.inspectionRef"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register pickup inspect!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>

                                    <mat-icon *ngIf="deliverExport" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Delivery Export Inspect

                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="deliverExport">
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/delivery-export/detail/' + deliverExport?.key]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Reference No.</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliverExport?.refNo}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Application Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliverExport?.application_date?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dl-items" *ngIf="!deliverExport"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register delivery export inspect!
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>

                    </div>
                </ng-container>


                <ng-container *ngIf="collection === 'local_sale_inv'">
                    <div class="" *ngIf="!store?.process" #content id="contentToConvert">

                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Local Sale Invoice
                                </ng-template>

                                <div style="padding: 33px">
                                    <ng-container>
                                        <div class="dl-items">
                                            <div class="dl-title"> CO Form</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.co_formRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Local Sale Invoice Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.invoice_number?data?.invoice_number:null}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Local Sale Invoice Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.invoice_date?.toDate() | date}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Product Name</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.product_name}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> CAT</div>
                                            <div class="dl-text">:
                                                <div class="data" *ngIf="data?.cat > 0">
                                                    {{data?.cat | number:'2.'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Amount</div>
                                            <div class="dl-text">:
                                                <div class="data" *ngIf="data?.amount_FOB > 0">
                                                    {{data?.amount_FOB | currency:' ':'code'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Destination</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.countryRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">

                                            <div class="dl-title"> Route</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.routeRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Packing</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.qty_packing | number:'1.'}}
                                                    <span *ngIf="data?.qty_packing_UOMRef">

                                                        {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Unit</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.qty_unit | number:'1.'}}
                                                    <span *ngIf="data?.qty_unit_UOMRef">

                                                        {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Shipping Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.shipping_date?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>



                                        <div class="dl-items">
                                            <div class="dl-title"> Last Update</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Status</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.status?.text}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="material?.length > 0" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    CO Material
                                </ng-template>
                                <div style="padding: 33px">
                                    <div *ngIf="data?.registerLocalSaleInvUsageKey"
                                        style="display: flex; justify-content: flex-end;">
                                        <button mat-icon-button
                                            [routerLink]="['/register-local-inv-usage/detail/' + data?.registerLocalSaleInvUsageKey]">
                                            <mat-icon class="mat-18">info</mat-icon>
                                        </button>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="material?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material Code</th>
                                                    <th class="table-col center">CO Material Name</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">NN.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <!-- <td class="table-col">
                                                    </td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items" *ngIf="material?.length == 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>

                                    <mat-icon *ngIf="deliverExport" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Delivery Local Sale Invoice
                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="deliverExport">
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/register-local-delivery/detail/' + deliverExport?.key]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Reference No.</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliverExport?.refNo}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Application Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliverExport?.application_date?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dl-items" *ngIf="!deliverExport"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register delivery export inspect!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>

                                    <mat-icon *ngIf="registerLocalCutStock" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Local Sale Stock
                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="registerLocalCutStock">

                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/register-local-cut-stock/detail/' + registerLocalCutStock?.key]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Reference No.</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{registerLocalCutStock?.refNo}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Application Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{registerLocalCutStock?.application_date?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dl-items" *ngIf="!registerLocalCutStock"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register delivery export inspect!
                                    </div>
                                </div>
                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>

                                    <mat-icon *ngIf="deliveryCutStock" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Delivery Local Sale Stock
                                </ng-template>
                                <div style="padding: 33px">
                                    <ng-container *ngIf="deliveryCutStock">
                                        
                                        <div style="display: flex; justify-content: flex-end;">
                                            <button mat-icon-button
                                                [routerLink]="['/local-delivery-cut-stock/detail/' + deliveryCutStock?.key]">
                                                <mat-icon class="mat-18">info</mat-icon>
                                            </button>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Reference No.</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliveryCutStock?.refNo}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Application Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{deliveryCutStock?.application_date?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dl-items" *ngIf="!deliveryCutStock"
                                        style="padding: 64px; font-size: 16px;  justify-content: center;">
                                        Not yet register delivery export inspect!
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>

                    </div>
                </ng-container>


                <ng-container *ngIf="collection === 'local_sale_inv_usage'">
                    <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Local Sale Invoice
                                </ng-template>

                                <div style="padding: 33px">
                                    <ng-container>
                                        <div class="dl-items">
                                            <div class="dl-title"> CO Form</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.co_formRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Local Sale Invoice Number</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'invoice_number' | async}}

                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Local Sale Invoice Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{(data?.register_itemRef | docRef:'invoice_date' | async)?.toDate()
                                                    | date}}

                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> CAT</div>
                                            <div class="dl-text">:
                                                <div class="data"
                                                    *ngIf="(data?.register_itemRef | docRef:'cat' | async) > 0">
                                                    {{data?.register_itemRef | docRef:'cat' | async | number:'2.'}}

                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Amount</div>
                                            <div class="dl-text">:
                                                <div class="data"
                                                    *ngIf="(data?.register_itemRef | docRef:'amount_FOB' | async) > 0">
                                                    {{(data?.register_itemRef | docRef:'amount_FOB' | async) |
                                                    currency:' ':'code'}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Destination</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.countryRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">

                                            <div class="dl-title"> Route</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.routeRef | docRef:'name' | async}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Packing</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'qty_packing' | async |
                                                    number:'1.'}}

                                                    <span *ngIf="data?.qty_packing_UOMRef">

                                                        {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title"> Qty Unit</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}

                                                    <span *ngIf="data?.qty_unit_UOMRef">

                                                        {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title">Shipping Date</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{(data?.register_itemRef | docRef:'shipping_date' |
                                                    async)?.toDate() | date}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dl-items">
                                            <div class="dl-title"> Last Update</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dl-items">
                                            <div class="dl-title">Status</div>
                                            <div class="dl-text">:
                                                <div class="data">
                                                    {{data?.status?.text}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.material?.length > 0" class="mat-18" style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    CO Material
                                </ng-template>
                                <div style="padding: 33px">
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="data?.material?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material Code</th>
                                                    <th class="table-col center">CO Material Name</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">NN.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <!-- <td class="table-col">
                                                    </td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items" *ngIf="data?.material?.length == 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon *ngIf="data?.importMaterial?.length > 0" class="mat-18"
                                        style="color:#1a73e8">
                                        check
                                    </mat-icon>
                                    Customs Material
                                </ng-template>
                                <div style="padding: 33px">
                                    <div class="scrollable-table" style="margin: 0;" id="print"
                                        *ngIf="data?.importMaterial?.length > 0">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col ">Customs Material Code</th>
                                                    <th class="table-col ">Customs Material Name</th>

                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>

                                                    <!-- <th class="table-col ">UOM</th> -->
                                                    <th class="table-col ">N.W</th>
                                                    <th class="table-col ">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice}}</td>
                                                    <td class="table-col right">{{item?.totalCost}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="table-col right" colspan="3">
                                                        Total
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'unitPrice') | number}}
                                                    </td>
                                                    <td class="table-col">

                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'totalCost')| number}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dl-items"
                                        *ngIf="!data?.importMaterial || data?.importMaterial?.length === 0"
                                        style="padding: 64px; font-size: 16px; justify-content: center;">
                                        No material!
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </ng-container>
                <!-- </ng-container> -->

            </div>
            <ng-container *ngIf="store?.process">
                <div style="padding: 33px;">
                    <app-line-loading></app-line-loading>
                    <app-line-loading></app-line-loading>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>