import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { DATA_TYPE } from 'src/app/dummy/columns';
import { ConvertService } from 'src/app/services/convert.service';
import { MappingService } from 'src/app/services/mapping.service';
import { Auth } from 'src/app/stores/auth.store';
import { AutoCompleteStore } from 'src/app/stores/autocomplete.store';
import { FilterStore } from 'src/app/stores/filter.store';

@Component({
  selector: 'app-filter-report',
  templateUrl: './filter-report.component.html',
  styleUrls: ['./filter-report.component.scss']
})
export class FilterReportComponent implements OnInit {

  @Input() filterOptions: any[];
  @Input() companyKey: any;
  @Input() itemStatusKey: any;
  @Output() onFilter = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Input() filterRef: any;
  @Input() showBreakdownMaterial: any;

  form: FormGroup
  option: AbstractControl
  search: AbstractControl
  fromDate: AbstractControl
  toDate: AbstractControl
  includeDate: AbstractControl
  isShowBreakdownMaterial: AbstractControl

  public controlType: any;
  public DATA_TYPE = DATA_TYPE
  constructor(private fb: FormBuilder,
    public store: FilterStore) { }

  ngOnInit() {
    this.form = this.fb.group({
      fromDate: [new Date(), Validators.required],
      toDate: [new Date(), Validators.required,  this.checkValidDate.bind(this)],
      isShowBreakdownMaterial:[false]
    })
    this.fromDate = this.form.controls["fromDate"]
    this.toDate = this.form.controls["toDate"]
    this.isShowBreakdownMaterial = this.form.controls["isShowBreakdownMaterial"]

    // this.form.valueChanges.subscribe(val=>{
    //   if(val){
    //     this.onFilter.emit({
    //       ...val,
    //       filterOption: this.controlType
    //     })
    //   }
    // })
  }

  clear() {
    this.form.patchValue({
      fromDate: new Date(),
      toDate: new Date(),
      isShowBreakdownMaterial:false
    })
    this.onClear.emit()
  }

  displayItem(item: any): string {
    return item ? item[this.controlType ? this.controlType.displayField : 'name'] : item;
  }
  async checkValidDate(control: AbstractControl) {
    if (control) {
      const { value } = control
      if (!value) return null;
      const {fromDate } = this.form.value
      const fromDateKey = ConvertService.toDateKey(fromDate);
      const toDateKey = ConvertService.toDateKey(value);
      if (toDateKey < fromDateKey) {
       return { nameAvailable: true }
      }
      else {
        return null
      }
    }
  }

  _onFilter(form) {
    this.onFilter.emit({
      ...form,
      filterOption: this.controlType
    })
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }
  onChanged(event) {
  }

}



@Component({
  selector: 'app-filter-stock-report',
  templateUrl: './filter-stock-report.component.html',
})
export class FilterStockReportComponent implements OnInit {

  @Input() filterOptions: any[];
  @Input() companyKey: any;
  @Input() itemStatusKey: any;
  @Output() onFilter = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Input() filterRef: any;

  form: FormGroup
  option: AbstractControl
  search: AbstractControl
  fromDate: AbstractControl
  toDate: AbstractControl
  includeDate: AbstractControl
  material: AbstractControl;
  MATERIAL_LIST: any[] = [];

  public controlType: any;
  public DATA_TYPE = DATA_TYPE
  constructor(private fb: FormBuilder,
    public autoStore: AutoCompleteStore,
    public auth: Auth,

    public store: FilterStore) { }

  ngOnInit() {
    this.form = this.fb.group({
      material: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      fromDate: [new Date(), Validators.required],
      toDate: [new Date(), Validators.required,  this.checkValidDate.bind(this)]
    })
    this.fromDate = this.form.controls["fromDate"]
    this.toDate = this.form.controls["toDate"]
    this.material = this.form.controls['material'];

    this.autoStore.fecthitemsCallback('register_material', this.auth.selectedCompany.key, data => {
      if (data) {
        console.log('data',data);
        const all = {key:'all', name:'All Material', code:''}
        this.MATERIAL_LIST =  data.concat(all);
      }
    });

    this.material.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (false)),
        switchMap(value => this.autoStore.fetchSearchCompanyMaterial("register_material", this.auth.selectedCompany.key, "keyword", value, data => {
          if (data) {
            const all = {key:'all', name:'All Material', code:''}
            this.MATERIAL_LIST =  data.concat(all);
          }
        }))
      )
      .subscribe(res => { });
  }

  async checkValidDate(control: AbstractControl) {
    if (control) {
      const { value } = control
      if (!value) return null;
      const {fromDate } = this.form.value
      const fromDateKey = ConvertService.toDateKey(fromDate);
      const toDateKey = ConvertService.toDateKey(value);
      if (toDateKey < fromDateKey) {
       return { nameAvailable: true }
      }
      else {
        return null
      }
    }
  }

  clear(form) {
    this.form.patchValue({
      fromDate: new Date(),
      toDate: new Date(),
    })
    this.onClear.emit(
      {
        ...form,
        filterOption: this.controlType
      }
    )
  }

  displayItem(item: any): string {
    return item ? item[this.controlType ? this.controlType.displayField : 'name'] : item;
  }
  onOptionSelected(event) {
    const { value } = event.option
  }
  _onFilter(form) {
    this.onFilter.emit({
      ...form,
      filterOption: this.controlType
    })
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }
  onChanged(event) {
  }

}
