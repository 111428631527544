import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from '../services/mapping.service';

@Pipe({
  name: 'calStockBalance'
})
export class CalStockBalancePipe implements PipeTransform {

  transform(item: any, qty: number, allocated_qty): any {
    let value = null;
    value = toNumber(qty) - toNumber(allocated_qty);
    return value;
  }

}
