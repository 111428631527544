import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { APPS } from '../dummy/config';
import { Auth } from './auth.store';
import { Subscription } from 'rxjs';

@Injectable({providedIn:'root'})
export class CommentStore {
    @observable data: any = [];
    @observable files: any = [];
    @observable process: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    subscription: Subscription;

    constructor(
        private ds: DataService,
        private auth: Auth

    ) { }

    @action
    addComment(collectionName, data, callback) {
        this.process = true;
        const batch = this.ds.batch();
        const settingRef = this.ds.settingBatchRef(collectionName).doc(data.refKey).collection('comments');
        // batch.set(settingRef, f);
        settingRef.doc(data.key).set(data).then(() => {
            callback(true, null);
        }).catch((error) => {
            alert(error);
            callback(false, error)
        }).finally(() => {
            this.process = false;
        })
        // batch.commit().then(() => {
        //     callback(true, null);
        // })
    }

    @action
    fetchComment(collectionName, key) {
        this.process = true;
        this.subscription =  this.ds.commentRef(collectionName).doc(key).collection('comments', ref => ref.orderBy('created_at')).valueChanges().subscribe(data => {
            this.data = data;
            this.process = false;
        });
    }

    @action
    fetchFile(collectionName, key, callback) {
        this.process = true;
        this.subscription = this.ds.commentRef(collectionName).doc(key).collection('files', ref => ref.orderBy('created_at')).valueChanges().subscribe(data => {
            this.files = data;
            callback(data);
            this.process = false;
        });
    }


    @action
    addCommentByCompany(collectionName, companyKey, data, callback) {
        this.process = true;
        const batch = this.ds.batch();
        const settingRef = this.ds.settingBatchRefByCompany(collectionName, companyKey).doc(data.refKey).collection('comments');
        // batch.set(settingRef, f);
        settingRef.doc(data.key).set(data).then(() => {
            callback(true, null);
        }).catch((error) => {
            alert(error);
            callback(false, error)
        }).finally(() => {
            this.process = false;
        })
        // batch.commit().then(() => {
        //     callback(true, null);
        // })
    }

    subscriptionComment: Subscription;
    @action
    fetchCommentByCompany(collectionName, companyKey, key) {
        this.subscriptionComment && this.subscriptionComment.unsubscribe()
        this.process = true;
        this.subscription = this.ds.commentByCompanyRef(collectionName, companyKey).doc(key).collection('comments', ref => ref.orderBy('created_at')).valueChanges().subscribe(data => {
            this.data = data;
            this.process = false;
        });
    }

    subscriptionFile: Subscription;
    @action
    fetchFileByCompany(collectionName, companyKey, key, callback) {
        this.subscriptionFile && this.subscriptionFile.unsubscribe()
        this.process = true;
        if(collectionName && companyKey && key){
            this.subscriptionFile =  this.ds.commentByCompanyRef(collectionName, companyKey).doc(key).collection('files', ref => ref.orderBy('created_at')).valueChanges().subscribe(data => {
                this.files = data;
                callback(data);
                this.process = false;
            });
        }
        this.process = false;

     
    }
}
