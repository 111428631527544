import { Component, Inject, PLATFORM_ID, APP_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Auth } from './stores/auth.store';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public auth: Auth,
    private fun: AngularFireFunctions,
    private afMessaging: AngularFireMessaging,
    public swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    
  ) {
    this.swUpdate.available.subscribe(evt => {
      const snack = this.snackbar.open('Update Available', 'Reload');
      snack
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });
      snack.afterDismissed().subscribe(() => {
        window.location.reload();

      });

    });
  }

  listen() {
    this.afMessaging.messages.subscribe((message) => { console.log('a', message); });
  }
  ngOnInit(): void {
  }

  async subscribeTopic(topic, token): Promise<void> {
    const success = await this.fun.httpsCallable('subscribeToTopic')({ topic, token }).toPromise();
  }

  requestPermission(): void {
    this.afMessaging.requestToken.subscribe(
      (token) => {
        this.listen()
      },
      (error) => {
        console.error(error);
      }
    );
  }
  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

}
