<mat-sidenav-container style="min-height: calc(100vh - 168px)">
  <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'" class="content-side"
    [fixedInViewport]="true" [fixedTopGap]="118" [style.width]="ismobile?'100%':'50%'" [fixedBottomGap]="0">
    <button mat-icon-button (click)="sidenav.toggle()" style="position: absolute; right: 24px; z-index: 999; top: 4px;">
      <mat-icon class="mat-18">clear</mat-icon>
    </button>
    <mat-tab-group style="height: 100%;">

      <mat-tab style="height: 100%;">
        <ng-template mat-tab-label>
          Comment
        </ng-template>
        <div class="comment-body">
          <div class="comment-list">
            <div class="chat">
              <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                *ngFor="let msg of cm?.data;">
                <div class="user">
                  <div class="avatar">
                    <div class="name" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                      {{ msg?.created_by?.name }}</div>
                  </div>
                </div>
                <div class="msg-item">
                  <div class="c-content" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                    <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                    <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                      <mat-icon class="mat-18" style="font-size: 10px; display: flex; align-items: center;">edit
                      </mat-icon>
                      <span style="font-size: 10px;">Edited</span>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="auth?.user?.key === msg?.created_by?.key"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="editComment(msg)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div class="date" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                    {{ msg.created_at?.toDate()|date:'medium' }} ~
                    {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                </div>
              </div>

            </div>
          </div>
          <form class="editor-block" style="position: relative;" [formGroup]="form">
            <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
              customToolbarPosition="top">
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected value="roboto">Roboto</option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                  </select>

                  <select class="ql-size">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                </span>

                <span class="ql-formats">

                  <select class="ql-align" [title]="'Aligment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                  <select class="ql-align" [title]="'Aligment2'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                  <select class="ql-color" [title]="'Color'">
                  </select>
                  <select class="ql-background" [title]="'BackgroundColor'">
                  </select>
                </span>
                <div class="custom-tb">
                  <span class="ql-formats">
                    <button class="ql-bold" [title]="'Bold'"></button>
                    <button class="ql-italic" [title]="'Italic'"></button>
                    <button class="ql-underline" [title]="'Underline'"></button>
                    <button class="ql-strike" [title]="'Strike'"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
                  <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                    (click)="postComment(form.value, data)">Post
                  </button>
                </div>

              </div>


            </quill-editor>
            <div style="height: 68px;"></div>
          </form>
        </div>
      </mat-tab>

      <mat-tab style="height: 100%; ">
        <ng-template mat-tab-label>
          Files
        </ng-template>
        <div class="comment-body file-manager">
          <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate" [value]="pct">
          </mat-progress-bar>


          <app-file-view-lists *ngIf="data" [collection]="collection" [key]="id" [data]="data"
            [companyKey]="auth?.selectedCompany?.key"></app-file-view-lists>

          <div style="padding: 12px; text-align: center;">

            <form [formGroup]="formUpload">
              <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                <a type="button" mat-button class="attach_file upload-button" (click)="fileInput.click()">
                  <mat-icon class="mat-18">attach_file</mat-icon>
                  <span>Add New Attachment</span>
                  <input #fileInput type="file" multiple
                    accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file formControlName="file"
                    (change)="onSelectedFile(file.files)" style="display:none;" />
                </a>
              </ng-container>

            </form>

          </div>
        </div>
      </mat-tab>
      <mat-tab style="height: 100%;">
        <ng-template mat-tab-label>
          History
        </ng-template>
        <div class="audit-lists">
          <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="cs-form-container">
      <div class="form-wrapper" style="padding:33px 0;" id="print">
        <div class="hd-row" style="padding:0 33px 16px 33px">
          <h4 class="hd-title">{{title}}</h4>
          <div class="flex-1"></div>
          <div *ngIf="data" style="display: flex;">
            <ng-container *ngIf="!isHide">

              <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;" class="hideOnPrint"
              matTooltip="Print">
                <mat-icon class="mat-18">print</mat-icon>
              </button>

              <div *ngIf="!isDeleted" style="display: flex;">

            
                <!-- <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Delete" *ngIf="collection == 'print_templates'">
                    <mat-icon class="mat-18">delete</mat-icon>
                  </button> -->

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_master_list') && (collection == 'register_master_list')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>



                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_material') && (collection == 'register_material')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>


                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_import_material') && (collection == 'register_import_material')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_import_inv') && (collection == 'register_invoice')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>


                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_import_inv') && (collection == 'register_import_invoice')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_local_invoice') && (collection == 'register_local_invoice')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_export_co') && (collection == 'register_co')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_export_bill') && (collection == 'pickup_bill')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>
                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_export_inspect') && (collection == 'pickup_inspect')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_soo') && (collection == 'register_soo')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_soo_statement') && (collection == 'register_soo_statement')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Delete"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_cancel_letter') && (collection == 'register_cancel_co_letter')">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_other_letter') && (collection == 'register_other_letter')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(collection == 'register_letter_form')" class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_reapply_inv') && (collection == 'register_reapply_item')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_duplicate_inv') && (collection == 'register_duplicate_item')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_reapply_letter') && (collection == 'register_reapply_letter')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_duplicate_letter') && (collection == 'register_duplicate_letter')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>
                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="canDelete && (auth?.userRoles | userPermissionRole:'delete_register_co_ob_stock') && (collection == 'register_ob_stock')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="canDelete && (auth?.userRoles | userPermissionRole:'delete_register_co_adjusted_under') && (collection == 'register_adjustment_stock_under')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="canDelete && (auth?.userRoles | userPermissionRole:'delete_register_co_adjusted_over') && (collection == 'register_adjustment_stock')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'delete_delivery_export_inspect') && (collection == 'register_delivery_export_document')"
                  class="hideOnPrint" matTooltip="Delete">
                  <mat-icon class="mat-18">delete</mat-icon>
                </button>


                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="collection == 'print_templates'" class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_master_list') && (collection == 'register_master_list')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_material') && (collection == 'register_material')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_import_material') && (collection == 'register_import_material')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_import_inv') && (collection == 'register_invoice')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_import_inv') && (collection == 'register_import_invoice')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_local_invoice') && (collection == 'register_local_invoice')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_export_co') && (collection == 'register_co')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_export_bill') && (collection == 'pickup_bill')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_export_inspect') && (collection == 'pickup_inspect')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_soo') && (collection == 'register_soo')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_soo_statement') && (collection == 'register_soo_statement')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_cancel_letter') && (collection == 'register_cancel_co_letter')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>


                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_other_letter') && (collection == 'register_other_letter')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>
                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(collection == 'register_letter_form')" class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_reapply_inv') && (collection == 'register_reapply_item')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_duplicate_inv') && (collection == 'register_duplicate_item')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_reapply_letter') && (collection == 'register_reapply_letter')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_duplicate_letter') && (collection == 'register_duplicate_letter')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>



                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_ob_stock') && (collection == 'register_ob_stock')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_adjusted_under') && (collection == 'register_adjustment_stock_under')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Release"
                  *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_register_co_adjusted_under') && (collection == 'register_adjustment_stock_under')">
                  <mat-icon class="mat-18">edit_off</mat-icon>
                </button>


                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_adjusted_over') && (collection == 'register_adjustment_stock')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>

                <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                  matTooltip="Release"
                  *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_register_co_adjusted_over') && (collection == 'register_adjustment_stock')">
                  <mat-icon class="mat-18">edit_off</mat-icon>
                </button>

                <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                  *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_export_inspect') && (collection == 'register_delivery_export_document')"
                  class="hideOnPrint" matTooltip="Edit">
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>




              </div>
            </ng-container>
            <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
              <mat-icon class="mat-18">insert_comment</mat-icon>
            </button>
          </div>
        </div>

        <div class="" *ngIf="!store?.process" #content id="contentToConvert">

          <ng-container *ngIf="collection == 'register_product'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title">Code</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.code?data?.code:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Bar Code</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.barCode?data?.barCode:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Description (EN)</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.name_en?data?.name_en:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Description (KH)</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.name_kh?data?.name_kh:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">UOM</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.uomRef | docRef : 'description_en' | async}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Price</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.price?data?.price:"" | currency}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Sub Item Of</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.subItem?data?.subItem.code:""}}
                  </div>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Last Update</div>
              <div class="dl-text" *ngIf="data?.updated_at">:
                {{data?.updated_at?.toDate() | date:'medium'}}
              </div>
              <div class="dl-text" *ngIf="!data?.updated_at">: </div>
            </div>
            <div class="dl-items" *ngIf="data?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.status?.text}}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection == 'register_material'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Code</div>
                <div class="dl-text">: {{data?.code}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Name</div>
                <div class="dl-text">: {{data?.name}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">UOM</div>
                <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Sub Of CO Material</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.subItem?.code}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection == 'register_import_material'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Code</div>
                <div class="dl-text">: {{data?.code}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Name</div>
                <div class="dl-text">: {{data?.name}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">UOM</div>
                <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Sub Of Customs Material</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.subItem?.code}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="collection == 'supplier_group'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title">Name (EN)</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.name_en?data?.name_en:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Name (KH)</div>
                <div class="dl-text">:
                  <div class="data">{{data?.name_kh?data?.name_kh:""}}</div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="collection == 'customer_group'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title">Name (EN)</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.name_en?data?.name_en:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Name (KH)</div>
                <div class="dl-text">:
                  <div class="data">{{data?.name_kh?data?.name_kh:""}}</div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>
          <ng-container *ngIf="collection == 'customer'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title">Customer ID</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.code?data?.code:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Customer Name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.name?data?.name:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Customer Group</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.customerGroupRef | docRef:'name_en' | async}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Customer Type</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.customer_type?data?.customer_type?.text:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Email</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.email?data?.email:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Country</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.countryRef | docRef:'name' | async }}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Address</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.address?data?.address:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Phone Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.telephone_number?data?.telephone_number:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Postal Code</div>
                <div class="dl-text">:
                  <div class="data">{{data?.postal_code?data?.postal_code:""}}</div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Fax Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.fax_number?data?.fax_number:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">TIN No.</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.tin_no?data?.tin_no:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Account name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.account_name?data?.account_name:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="collection == 'supplier'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title">Supplier ID</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.code?data?.code:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Supplier Name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.name?data?.name:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Supplier Group</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.supplierGroupRef | docRef:'name_en' | async}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Supplier Type</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.supplier_type?data?.supplier_type?.text:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Email</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.email?data?.email:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Country</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.countryRef | docRef:'name' | async }}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Address</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.address?data?.address:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Phone Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.telephone_number?data?.telephone_number:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Postal Code</div>
                <div class="dl-text">:
                  <div class="data">{{data?.postal_code?data?.postal_code:""}}</div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Fax Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.fax_number?data?.fax_number:""}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title">TIN No.</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.tin_no?data?.tin_no:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Account name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.account_name?data?.account_name:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="collection == 'register_invoice'">

            <mat-tab-group>
              <mat-tab>
                <ng-template mat-tab-label>
                  CO Import Invoice
                </ng-template>

                <div style="padding: 0 33px">
                  <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">: {{data?.invoice_number}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title">Export To</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.export_to}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">CAT</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.cat?(data?.cat | number:'1.') : ''}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Import Country</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.countryRef | docRef: 'name' | async}}
                      </div>
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title">CO Material</div>
                    <div class="dl-text">:</div>
                  </div>

                  <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                      <thead class="table-header">
                        <tr class="table-row">
                          <th class="table-col center">No.</th>
                          <th class="table-col center">Material Code</th>
                          <th class="table-col center">Material Name</th>
                          <th class="table-col center">UOM</th>
                          <th class="table-col center">Quantity</th>
                          <th class="table-col center">Currency</th>
                          <th class="table-col center">Price</th>
                          <th class="table-col center">Total</th>
                          <th class="table-col center">N.W</th>
                          <th class="table-col center">G.W</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                          <td class="table-col">{{i+1}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                          <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                          </td>
                          <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                          <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td>
                          <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                          <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                          </td>
                          <td class="table-col right">{{item?.qty_NW}}
                            {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                          <td class="table-col right">{{item?.qty_GW}}
                            {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">:
                      {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.status">
                    <div class="dl-title">Status</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.status?.text}}
                      </div>
                    </div>
                  </div>
                </div>

              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerImportInvoiceInfo" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  CO Import Invoice Information

                </ng-template>
                <div style="padding: 0 33px">
                  <div *ngIf="registerImportInvoiceInfo">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-invoice-info/detail/' + registerImportInvoiceInfo?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Invoice Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.invoice_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Invoice Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.invoice_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Permit Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.permit_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Permit Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.permit_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Custom Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.custom_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Custom Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.custom_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Cam Control Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.camControl_number}}
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Cam Control Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.camControl_date?.toDate() | date}}
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title">Bill Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.bill_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title">Application Form</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.co_formRef | docRef: 'name' | async}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="!registerImportInvoiceInfo"
                    style="padding: 64px; font-size: 16px; justify-content: center;">
                    No CO Import Invoice Information!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerImportStock" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  CO Import Stock

                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="registerImportStock">
                    
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-import-stock/detail/' + registerImportStock?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerImportStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerImportStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!registerImportStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not Import Stock!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryImportStock" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  Delivery CO Import Stock

                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryImportStock">

                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/delivery-import-stock/detail/' + deliveryImportStock?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryImportStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryImportStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryImportStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet do delivery CO import stock!
                  </div>
                </div>
              </mat-tab>



            </mat-tab-group>
          </ng-container>

          <ng-container *ngIf="collection == 'register_import_invoice'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">: {{data?.invoice_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
              </div>

              <!-- <div class="dl-items">
                <div class="dl-title">Export To</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.export_to}}
                  </div>
                </div>
              </div> -->
              <!-- <div class="dl-items">
                <div class="dl-title">CAT</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.cat?(data?.cat | number:'1.') : ''}}
                  </div>
                </div>
              </div> -->
              <div class="dl-items">
                <div class="dl-title"> Import Country</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.countryRef | docRef: 'name' | async}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Permit Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.permit_number}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Permit Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.permit_date?.toDate() | date}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Custom Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.custom_number}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Custom Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.custom_date?.toDate() | date}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Bill Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.bill_number}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Customs Materials</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Customs Material Code</th>
                      <th class="table-col center">Customs Material Name</th>
                      <th class="table-col center">Master List No.</th>
                      <th class="table-col center">Master List Date</th>

                      <th class="table-col center">UOM</th>
                      <th class="table-col center">Quantity</th>
                      <!-- <th class="table-col center">Currency</th> -->
                      <th class="table-col center">Unit Price</th>
                      <th class="table-col center">Amount</th>
                      <th class="table-col center">N.W</th>
                      <th class="table-col center">G.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                      <td class="table-col">{{item?.masterList?.masterId}}</td>
                      <td class="table-col">{{item?.masterList?.approvedDate?.toDate() | date}}</td>

                      <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col right">{{item?.qty | number : '1.4-4'}}</td>
                      <!-- <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td> -->
                      <td class="table-col right">{{item?.price | number : '1.4-4'}}</td>
                      <td class="table-col right">{{item?.totalCost | number : '1.4-4'}}
                      </td>
                      <td class="table-col right">
                        {{item?.qty_NW | number: '1.4-4'}}
                        {{item?.qty_NW_UOMRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col right">
                        {{item?.qty_GW | number: '1.4-4'}}
                        {{item?.qty_GW_UOMRef|docRef:'code'|async}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col right" colspan="6">
                        Total
                      </td>
                      <td class="table-col right">
                        {{ (data?.material | sumAmount:'qty') | number: '1.4-4'}}
                      </td>
                      <td class="table-col">

                      </td>
                      <td class="table-col right">
                        {{ (data?.material | sumAmount:'totalCost') | number : '1.4-4'}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.material | sumAmount:'qty_NW') | number: '1.4-4'}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.material | sumAmount:'qty_GW') | number: '.2-2'}}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>

          </ng-container>


          <ng-container *ngIf="collection == 'register_local_invoice'">

            <mat-tab-group>
              <mat-tab>
                <ng-template mat-tab-label>
                  Local Purchase Invoice
                </ng-template>

                <div style="padding: 0 33px">
                  <div class="dl-items">
                    <div class="dl-title">Local Purchase Invoice Number</div>
                    <div class="dl-text">: {{data?.invoice_number}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">Local Purchase Invoice Date</div>
                    <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title">Export To</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.export_to}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">CAT</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.cat?(data?.cat | number:'1.') : ''}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Import Country</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.countryRef | docRef: 'name' | async}}
                      </div>
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title">CO Material</div>
                    <div class="dl-text">:</div>
                  </div>

                  <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                      <thead class="table-header">
                        <tr class="table-row">
                          <th class="table-col center">No.</th>
                          <th class="table-col center">CO Material Code</th>
                          <th class="table-col center">CO Material Name</th>
                          <th class="table-col center">UOM</th>
                          <th class="table-col center">Quantity</th>
                          <th class="table-col center">Currency</th>
                          <th class="table-col center">Price</th>
                          <th class="table-col center">Total</th>
                          <th class="table-col center">N.W</th>
                          <th class="table-col center">G.W</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                          <td class="table-col">{{i+1}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                          <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                          </td>
                          <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                          <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td>
                          <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                          <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                          </td>
                          <td class="table-col right">{{item?.qty_NW}}
                            {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                          <td class="table-col right">{{item?.qty_GW}}
                            {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">Customs Material</div>
                    <div class="dl-text">:</div>
                  </div>
                  <div class="scrollable-table" style="margin: 0;" id="print"
                  *ngIf="data?.importMaterial?.length > 0">
                      <table class="table-wrapper tb-master">
                          <thead class="table-header">
                              <tr class="table-row">
                                  <th class="table-col center">No.</th>
                                  <th class="table-col ">Customs Material Code</th>
                                  <th class="table-col ">Customs Material Name</th>

                                  <th class="table-col ">Quantity</th>
                                  <th class="table-col ">Unit Price</th>
                                  <th class="table-col ">Amount</th>

                                  <!-- <th class="table-col ">UOM</th> -->
                                  <th class="table-col ">N.W</th>
                                  <th class="table-col ">G.W</th>
                              </tr>
                          </thead>
                          <tbody class="table-body">
                              <tr class="table-row"
                                  *ngFor="let item of data?.importMaterial;let i=index">
                                  <td class="table-col">{{i+1}}</td>
                                  <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                  <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                  <td class="table-col right">
                                      {{item?.qtyUnit | number}}
                                      {{item?.qty_UOMRef|docRef:'code'|async}}
                                  </td>
                                  <td class="table-col right">{{item?.unitPrice}}</td>
                                  <td class="table-col right">{{item?.totalCost}}</td>
                                  <td class="table-col right">
                                      {{item?.qtyNw | number}}
                                      {{item?.uomRef|docRef:'code'|async}}
                                  </td>
                                  <td class="table-col right">
                                      {{item?.qtyGw | number}}
                                      {{item?.uomRef|docRef:'code'|async}}
                                  </td>
                              </tr>
                              <tr class="table-row">
                                  <td class="table-col right" colspan="3">
                                      Total
                                  </td>
                                  <td class="table-col right">
                                      {{ (data?.importMaterial | sumAmount:'unitPrice') | number: '.2-2'}}
                                  </td>
                                  <td class="table-col">
          
                                  </td>
                                  <td class="table-col right">
                                      {{ (data?.importMaterial | sumAmount:'totalCost') | number : '.2-2'}}
                                  </td>
                                  <td class="table-col right">
                                      {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                                  </td>
                                  <td class="table-col right">
                                      {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                                  </td>

                              </tr>
                          </tbody>
                      </table>
                  </div>


                  <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">:
                      {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.status">
                    <div class="dl-title">Status</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.status?.text}}
                      </div>
                    </div>
                  </div>
                </div>

              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerImportInvoiceInfo" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  Local Purchase Invoice Information

                </ng-template>
                <div style="padding: 0 33px">
                  <div *ngIf="registerImportInvoiceInfo">
                    <div class="dl-items">
                      <div class="dl-title"> Invoice Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.invoice_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Invoice Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.invoice_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Permit Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.permit_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Permit Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.permit_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Custom Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.custom_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Custom Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.custom_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Cam Control Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.camControl_number}}
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Cam Control Date</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.camControl_date?.toDate() | date}}
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title">Bill Number</div>
                      <div class="dl-text">: {{registerImportInvoiceInfo?.bill_number}}</div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title">Application Form</div>
                      <div class="dl-text">:
                        {{registerImportInvoiceInfo?.co_formRef | docRef: 'name' | async}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="!registerImportInvoiceInfo"
                    style="padding: 64px; font-size: 16px; justify-content: center;">
                    No CO Import Invoice Information!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerImportStock" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  Local Purchase Stock

                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="registerImportStock">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerImportStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerImportStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!registerImportStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not Import Stock!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryImportStock" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  Delivery Local Purchase Stock

                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryImportStock">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryImportStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryImportStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryImportStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet do delivery CO import stock!
                  </div>
                </div>
              </mat-tab>



            </mat-tab-group>
          </ng-container>

          <ng-container *ngIf="collection == 'register_local_invoice_info'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">: {{data?.invoice_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Permit Number</div>
                <div class="dl-text">: {{data?.permit_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Permit Date</div>
                <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Custom Number</div>
                <div class="dl-text">: {{data?.custom_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Custom Date</div>
                <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Cam Control Number</div>
                <div class="dl-text">: {{data?.camControl_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Cam Control Date</div>
                <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Bill Number</div>
                <div class="dl-text">: {{data?.bill_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Application Form</div>
                <div class="dl-text">: {{data?.co_formRef | docRef: 'name' | async}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>



          </ng-container>

          <ng-container *ngIf="collection == 'register_invoice_info'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">: {{data?.invoice_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Permit Number</div>
                <div class="dl-text">: {{data?.permit_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Permit Date</div>
                <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Custom Number</div>
                <div class="dl-text">: {{data?.custom_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Custom Date</div>
                <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Cam Control Number</div>
                <div class="dl-text">: {{data?.camControl_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Cam Control Date</div>
                <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Bill Number</div>
                <div class="dl-text">: {{data?.bill_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Application Form</div>
                <div class="dl-text">: {{data?.co_formRef | docRef: 'name' | async}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>



          </ng-container>

          <ng-container *ngIf="collection == 'register_co'">



            <mat-tab-group>
              <mat-tab>
                <ng-template mat-tab-label>
                  <!-- <mat-icon class="example-tab-icon">check</mat-icon> -->
                  Export CO
                </ng-template>

                <div style="padding: 0 33px">
                  <div class="dl-items">
                    <div class="dl-title">Export CO Number</div>
                    <div class="dl-text">: {{data?.co_number}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">Export CO Date</div>
                    <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Shipping Date</div>
                    <div class="dl-text">:
                      {{data?.etd?.toDate() | date}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">ADM Fee (KHR)</div>
                    <div class="dl-text">:
                      <div class="data" *ngIf="data?.admFee">
                        {{data?.admFee | number:'1.'}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">EMF Fee (KHR)</div>
                    <div class="dl-text">:
                      <div class="data" *ngIf="data?.emfFee">
                        {{data?.emfFee | number:'1.'}}
                      </div>
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docDocRef: 'co_formRef' : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">Export Invoice Number</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docRef:'invoice_number' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">Export Invoice Date</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docRefDate:'invoice_date' | async | date}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docRef:'cat' | async | number:'1.'}}
                    </div>
                  </div>


                  <div class="dl-items">
                    <div class="dl-title"> Amount </div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docDocRef: 'countryRef' : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docDocRef: 'routeRef' : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                      {{data?.register_itemRef | docRef:'qty_packing' | async | number:'1.'}}
                      <span style="padding-left: 10px;"
                        *ngIf="(data?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'description_en'  | async)">

                        {{data?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                      </span>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">
                      :
                      {{data?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}
                      <span style="padding-left: 10px;"
                        *ngIf="(data?.register_itemRef | docRef: 'qty_unit_UOMRef' : 'description_en'  | async)">

                        {{data?.register_itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                      </span>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">:
                      {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.status">
                    <div class="dl-title">Status</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.status?.text}}
                      </div>
                    </div>
                  </div>
                </div>

              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="soo" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  SOO
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="soo">

                    
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-soo/detail/' + soo?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> SOO Number</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{soo?.soo_number?soo?.soo_number:soo?.co_number}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> SOO Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{(soo?.soo_date?soo?.soo_date:soo?.co_date)?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="dl-items" *ngIf="!soo" style="padding: 64px; font-size: 16px; justify-content: center;">
                    No SOO
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="soo_statement" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  SOO Statement
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="soo_statement">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-soo-statement/detail/' + soo_statement?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{soo_statement?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> SOO Statement Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{soo_statement?.soo_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="dl-items" *ngIf="!soo_statement"
                    style="padding: 64px; font-size: 16px; justify-content: center;">
                    No SOO Statement
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="submitForm" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Submit Form
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="submitForm">

                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-submit-form/detail/' + submitForm?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{submitForm?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{submitForm?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>


                  <div class="dl-items" *ngIf="!submitForm"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register submit form!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>

                  <mat-icon *ngIf="submitCrf" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Submit CRF
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="submitCrf">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-submit-crf/detail/' + submitCrf?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{submitCrf?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{submitCrf?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!submitCrf"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register submit crf!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryCo" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Delivery Export CO
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryCo">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-delivery-co-application/detail/' + deliveryCo?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCo?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCo?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryCo"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register delivery CO!
                  </div>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="returnApp" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Register Return
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="returnApp">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-return-application/detail/' + returnApp?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{returnApp?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{returnApp?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!returnApp"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register return!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryReturnDoc" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Delivery Return
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryReturnDoc">
                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/delivery-return-application/detail/' + deliveryReturnDoc?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryReturnDoc?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryReturnDoc?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryReturnDoc"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register delivery return!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerCutStock" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Register CO Cut Stock
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="registerCutStock">

                    <div style="display: flex; justify-content: flex-end;">
                      <button mat-icon-button
                          [routerLink]="['/register-cut-stock/detail/' + registerCutStock?.key]">
                          <mat-icon class="mat-18">info</mat-icon>
                      </button>
                    </div>

                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerCutStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerCutStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!registerCutStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register cut stock!
                  </div>
                </div>
              </mat-tab>
              <!-- <mat-tab>
                <ng-template mat-tab-label>
                  Delivery CO Cut Stock
                </ng-template>
                <div style="padding: 0 33px">

                </div>
              </mat-tab> -->

            </mat-tab-group>


          </ng-container>

          <ng-container *ngIf="collection == 'register_bom'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Product</div>
                <div class="dl-text">:
                  {{data?.productRef | docRef: 'name_en' | async}}
                </div>
              </div>

              <div class="form-contain" *ngIf="data?.material?.length > 0" style="margin-top: 20px;">
                <h4>CO Material</h4>
                <div class="scrollable-table" style="margin: 0;" id="print">
                  <table class="table-wrapper tb-master">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center">No.</th>
                        <th class="table-col center">CO Material Code</th>
                        <th class="table-col center">CO Material Name</th>
                        <!-- <th class="table-col center">UOM</th> -->
                        <th class="table-col center">Quantity</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <!-- <td class="table-col"></td> -->
                        <td class="table-col right">
                          {{item?.qty | number}}
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>
                      </tr>
                      <tr class="table-row" *ngIf="data?.material?.length>0">
                        <td class="table-col" colspan="4">
                          <strong>Total:</strong>
                        </td>
                        <td class="table-col">
                          {{data?.material| sumItem: 'qty'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_master_list'">
            <div style="padding: 0 33px">

              <div class="dl-items">
                <div class="dl-title"> Master list ID</div>
                <div class="dl-text">: {{data?.masterId}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Approved Date</div>
                <div class="dl-text">: {{data?.approvedDate?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> From Date</div>
                <div class="dl-text">: {{data?.fromDate?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> To Date</div>
                <div class="dl-text">: {{data?.toDate?.toDate() | date}}</div>
              </div>
              <!-- <div class="dl-items">
                <div class="dl-title"> Duration</div>
                <div class="dl-text">: <span *ngIf="data?.duration">{{data?.duration}} days</span></div>
              </div> -->

              <div class="form-contain" *ngIf="data?.material?.length > 0" style="margin-top: 20px;">
                <h4>Master List Material</h4>
                <div class="scrollable-table" style="margin: 0;" id="print">
                  <table class="table-wrapper tb-master">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center">No.</th>
                        <th class="table-col center">Material Code</th>
                        <th class="table-col center">Material Name</th>
                        <th class="table-col center">New/Used</th>
                        <th class="table-col center">Unit</th>
                        <th class="table-col center">Quantity</th>
                        <th class="table-col center">Unit Price</th>
                        <th class="table-col center">Total Price</th>
                        <th class="table-col center">Remarks</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <tr *ngFor="let item of data?.material;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <td class="table-col ">{{item?.isNew?'New':'Used'}}</td>
                        <td class="table-col ">
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>

                        <td class="table-col right">{{item?.qty}}</td>
                        <td class="table-col right">{{item?.unitPrice|currency}}</td>
                        <td class="table-col right">{{item?.total|currency}}</td>
                        <td class="table-col ">{{item?.remarks}}</td>
                      </tr>
                      <tr class="table-row" *ngIf="data?.detail?.length>0">
                        <td class="table-col" colspan="9">
                          <strong>Total:</strong>
                        </td>
                        <td class="table-col">
                          {{data?.detail| sumItem: 'total' | currency}}
                        </td>
                        <td class="table-col "></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <div style="padding:0 33px">
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_purchase_order'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Purchase Number</div>
                <div class="dl-text">: {{data?.purchase_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Purchase Date</div>
                <div class="dl-text">: {{data?.purchase_date?.toDate() | date}}</div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Supplier</div>
                <div class="dl-text">:
                  {{data?.supplierRef | docRef: 'name' | async}}
                </div>
              </div>

              <div class="form-contain" *ngIf="data?.material?.length > 0" style="margin-top: 20px;">
                <h4>CO Material</h4>
                <div class="scrollable-table" style="margin: 0;" id="print">
                  <table class="table-wrapper tb-master">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center">No.</th>
                        <th class="table-col center">CO Material Code</th>
                        <th class="table-col center">CO Material Name</th>
                        <!-- <th class="table-col center">UOM</th> -->
                        <th class="table-col center">Quantity</th>
                        <th class="table-col center">Unit Price</th>
                        <th class="table-col center">Total</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <!-- <td class="table-col"></td> -->
                        <td class="table-col right">
                          {{item?.qty | number}}
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>
                        <td class="table-col right">{{item?.price | currency}}</td>
                        <td class="table-col right">{{item?.total | currency}}</td>
                      </tr>
                      <tr class="table-row" *ngIf="data?.material?.length>0">
                        <td class="table-col" colspan="6">
                          <strong>Total:</strong>
                        </td>
                        <td class="table-col">
                          {{data?.material| sumItem: 'total' | currency}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <div style="padding:0 33px">
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_reapply_item'">

            <mat-tab-group>
              <mat-tab>
                <ng-template mat-tab-label>
                  Reapply Invoice
                </ng-template>
                <div style="padding: 0 33px">
                  <div class="dl-items">
                    <div class="dl-title"> CO Number</div>
                    <div class="dl-text">: {{data?.co_number}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> CO Date</div>
                    <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Shipping Date</div>
                    <div class="dl-text">:
                      {{data?.etd?.toDate() | date}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">ADM Fee (KHR)</div>
                    <div class="dl-text">:
                      <div class="data" *ngIf="data?.admFee">
                        {{data?.admFee | number:'1.'}}
                      </div>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title">EMF Fee (KHR)</div>
                    <div class="dl-text">:
                      <div class="data" *ngIf="data?.emfFee">
                        {{data?.emfFee | number:'1.'}}
                      </div>
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                      {{data?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                      {{data?.invoice_number}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                      {{data?.invoice_date?.toDate() | date}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                      {{data?.cat | number:'1.'}}
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                      {{data?.amount_FOB | currency:' ':'code'}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                      {{data?.countryRef | docRef : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                      {{data?.routeRef | docRef : 'name' | async}}
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                      {{data?.qty_packing | number:'1.'}}
                      <span *ngIf="(data?.qty_packing_UOMRef | docRef : 'code'  | async)" style="padding-left: 10px;">
                        {{data?.qty_packing_UOMRef | docRef : 'code' | async}}</span>
                    </div>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">
                      :
                      {{data?.qty_unit | number:'1.'}}
                      <span *ngIf="(data?.qty_unit_UOMRef | docRef : 'code'  | async)" style="padding-left: 10px;">
                        {{data?.qty_unit_UOMRef | docRef : 'code' | async}}</span>
                    </div>
                  </div>

                  <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                  </div>

                  <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                    <table class="table-wrapper tb-master">
                      <thead class="table-header">
                        <tr class="table-row">
                          <th class="table-col center">No.</th>
                          <th class="table-col center">Code</th>
                          <th class="table-col center">Description</th>
                          <!-- <th class="table-col center">UOM</th> -->
                          <th class="table-col center">Quantity</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                          <td class="table-col">{{i+1}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                          <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                          <!-- <td class="table-col"></td> -->
                          <td class="table-col right">
                            {{item?.qty | number}}
                            {{item?.uomRef|docRef:'code'|async}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">:
                      {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.status">
                    <div class="dl-title">Status</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.status?.text}}
                      </div>
                    </div>
                  </div>
                </div>

              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>

                  <mat-icon *ngIf="reApplyForm" class="mat-18" style="color:#1a73e8">
                    check
                  </mat-icon>
                  Reapply Application
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="reApplyForm">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No.</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{reApplyForm?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{reApplyForm?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!reApplyForm"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register reapply application form!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="registerCutStock" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Register CO Cut Stock
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="registerCutStock">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerCutStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{registerCutStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!registerCutStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register cut stock!
                  </div>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryCo" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Delivery Export CO
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryCo">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCo?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCo?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryCo"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register delivery CO!
                  </div>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="deliveryCutStock" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Delivery CO Cut Stock
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="deliveryCutStock">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCutStock?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{deliveryCutStock?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!deliveryCutStock"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register delivery CO Cut Stock!
                  </div>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon *ngIf="returnApp" class="mat-18" style="color:#1a73e8">check
                  </mat-icon>
                  Register Return
                </ng-template>
                <div style="padding: 0 33px">
                  <ng-container *ngIf="returnApp">
                    <div class="dl-items">
                      <div class="dl-title"> Reference No</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{returnApp?.refNo}}
                        </div>
                      </div>
                    </div>
                    <div class="dl-items">
                      <div class="dl-title"> Application Date</div>
                      <div class="dl-text">:
                        <div class="data">
                          {{returnApp?.application_date?.toDate() | date}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="dl-items" *ngIf="!returnApp"
                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                    Not yet register return!
                  </div>
                </div>
              </mat-tab>

            </mat-tab-group>
          </ng-container>
          <ng-container *ngIf="collection == 'register_duplicate_item'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> CO Number</div>
                <div class="dl-text">: {{data?.co_number}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> CO Date</div>
                <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Shipping Date</div>
                <div class="dl-text">:
                  {{data?.etd?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">ADM Fee (KHR)</div>
                <div class="dl-text">:
                  <div class="data" *ngIf="data?.admFee">
                    {{data?.admFee | number:'1.'}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">EMF Fee (KHR)</div>
                <div class="dl-text">:
                  <div class="data" *ngIf="data?.emfFee">
                    {{data?.emfFee | number:'1.'}}
                  </div>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Form</div>
                <div class="dl-text">:
                  {{data?.co_formRef | docRef: 'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">:
                  {{data?.invoice_number}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">:
                  {{data?.invoice_date?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> CAT</div>
                <div class="dl-text">:
                  {{data?.cat | number:'1.'}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  {{data?.amount_FOB | currency:' ':'code'}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Destination</div>
                <div class="dl-text">:
                  {{data?.countryRef | docRef : 'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  {{data?.routeRef | docRef : 'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Qty Packing</div>
                <div class="dl-text">:
                  {{data?.qty_packing | number:'1.'}}
                  <span *ngIf="(data?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                    {{data?.qty_packing_UOMRef | docRef : 'code' | async}}
                  </span>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Qty Unit</div>
                <div class="dl-text">
                  :
                  {{data?.qty_unit | number:'1.'}}
                  <span *ngIf="(data?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                    {{data?.qty_unit_UOMRef | docRef : 'code' | async}}
                  </span>
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Code</th>
                      <th class="table-col center">Description</th>
                      <!-- <th class="table-col center">UOM</th> -->
                      <th class="table-col center">Quantity</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                      <!-- <td class="table-col"></td> -->
                      <td class="table-col right">
                        {{item?.qty | number}}
                        {{item?.uomRef|docRef:'code'|async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>

          <ng-container *ngIf="collection == 'pickup_bill'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Export Bill Number</div>
                <div class="dl-text">: {{data?.bill_number?data?.bill_number:null}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Export Bill Date</div>
                <div class="dl-text" *ngIf="data?.bill_date">: {{data?.bill_date?.toDate() | date}}
                </div>
                <div class="dl-text" *ngIf="!data?.bill_date">: </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> CO Export Invoice</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Export Invoice Number</th>
                      <th class="table-col center">Export Invoice Date</th>
                      <th class="table-col center">Route</th>
                      <th class="table-col center">Destination</th>
                      <th class="table-col center">CO Form</th>
                      <th class="table-col center">Amount</th>
                      <th class="table-col center">Qty Packing</th>
                      <th class="table-col center">Qty Unit</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                      <td class="table-col"><span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'invoice_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.routeRef | docRef : 'name' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.countryRef | docRef : 'name' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.co_formRef | docRef : 'name' | async}}
                      </td>

                      <td class="table-col right">
                        {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                        <span *ngIf="(item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async)">
                          {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                        </span>
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                        <span *ngIf="(item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async)">
                          {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>
          <ng-container *ngIf="collection == 'pickup_inspect'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Inspect Number</div>
                <div class="dl-text">: {{data?.inspect_number?data?.inspect_number:null}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Inspect Date</div>
                <div class="dl-text">: {{data?.inspect_date?.toDate() | date}}
                </div>
              </div>


              <div class="dl-items">
                <div class="dl-title"> CO Export Invoice</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Invoice Number</th>
                      <th class="table-col center">Invoice Date</th>
                      <th class="table-col center">Route</th>
                      <th class="table-col center">Destination</th>
                      <th class="table-col center">CO Form</th>
                      <th class="table-col center">Amount</th>
                      <th class="table-col center">Qty Packing</th>
                      <th class="table-col center">Qty Unit</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                      <td class="table-col"><span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'invoice_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.routeRef | docRef : 'name' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.countryRef | docRef : 'name' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.co_formRef | docRef : 'name' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                        <span *ngIf="(item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async)">
                          {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                        </span>
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                        <span *ngIf="(item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async)">
                          {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <div class="dl-items">
                <div class="dl-title">Customs Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print"
              *ngIf="materials?.length > 0">
                  <table class="table-wrapper tb-master">
                      <thead class="table-header">
                          <tr class="table-row">
                              <th class="table-col center">No.</th>
                              <th class="table-col ">Customs Material Code</th>
                              <th class="table-col ">Customs Material Name</th>

                              <th class="table-col ">Quantity</th>
                              <th class="table-col ">Unit Price</th>
                              <th class="table-col ">Amount</th>

                              <!-- <th class="table-col ">UOM</th> -->
                              <th class="table-col ">N.W</th>
                              <th class="table-col ">G.W</th>
                          </tr>
                      </thead>
                      <tbody class="table-body">
                          <tr class="table-row"
                              *ngFor="let item of materials;let i=index">
                              <td class="table-col">{{i+1}}</td>
                              <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                              <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                              <td class="table-col right">
                                  {{item?.qtyUnit | number}}
                                  {{item?.qty_UOMRef|docRef:'code'|async}}
                              </td>
                              <td class="table-col right">{{item?.unitPrice}}</td>
                              <td class="table-col right">{{item?.totalCost}}</td>
                              <td class="table-col right">
                                  {{item?.qtyNw | number}}
                                  <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                  KG
                              </td>
                              <td class="table-col right">
                                  {{item?.qtyGw | number}}
                                  <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                  KG
                              </td>
                          </tr>
                          <tr class="table-row">
                              <td class="table-col right" colspan="3">
                                  Total
                              </td>
                              <td class="table-col right">
                                {{ (materials | sumAmount:'qtyUnit') | number: '.2-2'}}
                              </td>
                              <td class="table-col">
      
                              </td>
                              <td class="table-col right">
                                  {{ (materials | sumAmount:'totalCost') | number : '.2-2'}}
                              </td>
                              <td class="table-col right">
                                  {{ (materials | sumAmount:'qtyNw') | number}}
                              </td>
                              <td class="table-col right">
                                  {{ (materials | sumAmount:'qtyGw') | number}}
                              </td>

                          </tr>
                      </tbody>
                  </table>
              </div>
              
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>
          <ng-container *ngIf="collection == 'register_soo'">

            <div style="padding: 0 33px">
              <ng-container *ngIf="data?.type?.key === 1;">
                <div class="dl-items">
                  <div class="dl-title"> SOO Number</div>
                  <div class="dl-text">: {{data?.soo_number?data?.soo_number:null}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> SOO Date</div>
                  <div class="dl-text">: {{data?.soo_date?.toDate() | date}}</div>
                </div>

                <div class="dl-items">
                  <div class="dl-title"> Amount</div>
                  <div class="dl-text">: {{data?.amount | currency:' ':'code'}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> Quality Packing</div>
                  <div class="dl-text">: {{data?.quality_cnt | number:'1.'}}
                    <span style="padding-left: 10px;" *ngIf="data?.qty_packing_UOMRef | docRef:'code' | async">
                      {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                    </span>
                  </div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> Quality Unit</div>
                  <div class="dl-text">: {{data?.quality_pcs | number:'1.'}}
                    <span style="padding-left: 10px;" *ngIf="data?.qty_unit_UOMRef | docRef:'code' | async">
                      {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                    </span>
                  </div>
                </div>

                <div class="dl-items">
                  <div class="dl-title"> Export CO</div>
                  <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                  <table class="table-wrapper tb-master">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center">No.</th>
                        <th class="table-col center">Export CO Number</th>
                        <th class="table-col center">Export CO Date</th>
                        <th class="table-col center">Export Invoice Number</th>
                        <th class="table-col center">Export Invoice Date</th>

                        <th class="table-col center">Shipping Date</th>
                        <th class="table-col center">Route</th>
                        <th class="table-col center">Destination</th>

                        <th class="table-col center">Amount</th>
                        <th class="table-col center">Qty Packing</th>
                        <th class="table-col center">Qty Unit</th>
                        <th class="table-col center">Export Inspect No</th>
                        <th class="table-col center">Export Inspect Date</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                        <td class="table-col">
                          <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                        </td>
                        <td class="table-col">
                          {{item?.co_number}}
                        </td>
                        <td class="table-col">
                          {{item?.co_date?.toDate() | date}}
                        </td>
                        <td class="table-col">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col">
                          {{item?.invoice_date?.toDate() | date}}
                        </td>
                        <td class="table-col">
                          {{item?.etd?.toDate() | date}}
                        </td>

                        <td class="table-col">
                          {{item?.routeRef | docRef:'name' | async}}
                        </td>
                        <td class="table-col">
                          {{item?.countryRef | docRef:'name' | async}}
                        </td>

                        <td class="table-col right">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{item?.qty_packing | number:'1.'}}
                          <span style="padding-left: 10px;" *ngIf="item?.uomPackingKey">
                            {{item?.uomPackingKey | docRef : 'code' | async}}
                          </span>
                        </td>
                        <td class="table-col right">
                          {{item?.qty_unit | number:'1.'}}
                          <span style="padding-left: 10px;" *ngIf="item?.uomUnitRef">
                            {{item?.uomUnitRef | docRef : 'code' | async}}
                          </span>
                        </td>
                        <td class="table-col">
                          {{item?.inspect_number}}
                        </td>
                        <td class="table-col">
                          {{item?.inspect_date?.toDate() | date}}
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col" colspan="2">
                          Total:
                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col right">
                          {{totalAmountFOB | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{totalQtyPacking | number:'1.'}}
                          {{data?.qty_packing_UOMRef | docRef : 'code' | async}}

                        </td>
                        <td class="table-col right">
                          {{totalQtyUnit | number:'1.'}}
                          {{data?.qty_unit_UOMRef | docRef : 'code' | async}}

                        </td>
                        <td class="table-col">

                        </td>
                        <td class="table-col">

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
              <ng-container *ngIf="data?.type?.key === 2;">
                <div class="dl-items">
                  <div class="dl-title"> CO Number</div>
                  <div class="dl-text">: {{data?.co_number}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> CO Date</div>
                  <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> Amount</div>
                  <div class="dl-text">: {{data?.amount_FOB | currency:' ':'code'}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> Qty Packing</div>
                  <div class="dl-text">: {{data?.qty_packing | number:'1.'}}
                    {{data?.register_itemRef | docDocRef:'qty_packing_UOMRef':'code' | async}}

                  </div>
                </div>
                <div class="dl-items">
                  <div class="dl-title">Qty Unit</div>
                  <div class="dl-text">: {{data?.qty_unit | number:'1.'}}
                    {{data?.register_itemRef | docDocRef:'qty_unit_UOMRef':'code' | async}}

                  </div>
                </div>

                <div class="dl-items">
                  <div class="dl-title">Inspect Number</div>
                  <div class="dl-text">: {{data?.inspect_number}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title">Inspect Date</div>
                  <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                  <div class="dl-title"> Items</div>
                  <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                  <table class="table-wrapper tb-master">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center">No.</th>
                        <th class="table-col center">SOO Number</th>
                        <th class="table-col center">SOO Date</th>
                        <th class="table-col center">Amount</th>
                        <th class="table-col center">Quality Packing</th>
                        <th class="table-col center">Quality Unit</th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                        <td class="table-col">
                          <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                        </td>
                        <td class="table-col">{{item?.soo_number}}</td>
                        <td class="table-col">
                          {{item?.soo_date?.toDate() | date}}
                        </td>
                        <td class="table-col right">
                          {{item?.amount | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{item?.quality_cnt | number:'1.'}}
                          {{item?.qty_packing_UOMRef | docRef:'code' | async}}
                        </td>
                        <td class="table-col right">
                          {{item?.quality_pcs | number:'1.'}}
                          {{item?.qty_unit_UOMRef | docRef:'code' | async}}
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col" colspan="3">
                          Total:
                        </td>

                        <td class="table-col right">
                          {{totalAmountFOBSOO | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{totalQtyCNT | number:'1.'}}
                          {{data?.register_itemRef | docDocRef:'qty_packing_UOMRef':'code' | async}}

                        </td>
                        <td class="table-col right">
                          {{totalQtyPCS | number:'1.'}}
                          {{data?.register_itemRef | docDocRef:'qty_unit_UOMRef':'code' | async}}

                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>

              </ng-container>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>



          </ng-container>


          <ng-container *ngIf="collection == 'register_soo_statement'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo?data?.refNo:null}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> SOO Statement Date</div>
                <div class="dl-text" *ngIf="data?.soo_date">: {{data?.soo_date?.toDate() | date}}
                </div>
                <div class="dl-text" *ngIf="!data?.soo_date">: </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Export CO</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" colspan="5">SOO Information</th>

                      <th class="table-col center" colspan="12">Export CO And Export Invoice
                        Information</th>
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">SOO NO</th>
                      <th class="table-col center">SOO DATE</th>
                      <th class="table-col center">Amount</th>
                      <th class="table-col center">Quality Packing</th>
                      <th class="table-col center">Quality Unit</th>
                      <th class="table-col center">EXPORT CO NO</th>
                      <th class="table-col center">EXPORT CO Date</th>
                      <th class="table-col center">EXPORT INVOICE NO</th>
                      <th class="table-col center">EXPORT INVOICE DATE</th>
                      <th class="table-col center">Shipping Date</th>
                      <th class="table-col center">Amount</th>
                      <th class="table-col center">Quality Packing</th>
                      <th class="table-col center">Quality Unit</th>
                      <th class="table-col center">DESTINATION</th>
                      <th class="table-col center">EXPORT INSPECT NO</th>
                      <th class="table-col center">EXPORT INSPECT DATE</th>

                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <ng-container *ngFor="let array of data?.items;let i=index">
                      <ng-container *ngIf="array.type.key === 1">
                        <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">{{i+1}}</td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef|docRef:'soo_number'|async}}
                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docRefDate:'soo_date' | async | date}}
                          </td>

                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef|docRef:'amount' | async | currency:' ':'code'}}

                          </td>
                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef|docRef:'quality_cnt' | async | number:'1.' }}
                            {{array?.itemRef|docDocRef:'qty_packing_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef|docRef:'quality_pcs' | async | number:'1.' }}
                            {{array?.itemRef|docDocRef:'qty_unit_UOMRef':'code' | async}}

                          </td>


                          <td class="table-col">
                            {{item?.itemRef|docRef:'co_number'|async}}
                          </td>
                          <td class="table-col">
                            {{item?.itemRef | docRefDate:'co_date' | async | date}}
                          </td>
                          <td class="table-col">
                            {{item?.register_itemRef | docRef: 'invoice_number' | async}}
                          </td>

                          <td class="table-col">
                            {{item?.register_itemRef | docRefDate: 'invoice_date' | async | date}}
                          </td>

                          <td class="table-col">
                            {{item?.itemRef | docRefDate:'etd' | async | date}}
                          </td>
                          <td class="table-col right">
                            {{item?.register_itemRef | docRef: 'amount_FOB' | async | currency:' ':'code'}}

                          </td>
                          <td class="table-col right">
                            {{item?.register_itemRef | docRef: 'qty_packing' | async | number:'1.'}}
                            {{item?.uomPackingKey|docRef:'code' | async}}
                          </td>
                          <td class="table-col right">
                            {{item?.register_itemRef | docRef: 'qty_unit' | async | number:'1.'}}
                            {{item?.uomUnitRef|docRef:'code' | async}}
                          </td>

                          <td class="table-col">
                            {{item?.register_itemRef | docDocRef: 'countryRef' : 'name' | async}}
                          </td>

                          <td class="table-col">
                            {{item?.inspect_number}}
                          </td>

                          <td class="table-col">
                            {{item?.inspect_date?.toDate() | date}}
                          </td>


                        </tr>
                        <tr class="table-row">
                          <td class="table-col right" colspan="3">
                            Total:
                          </td>
                          <td class="table-col right">
                            {{array?.itemRef|docRef:'amount' | async | currency:' ':'code'}}
                          </td>
                          <td class="table-col right">
                            {{array?.itemRef|docRef:'quality_cnt' | async | number:'1.' }}
                            {{array?.itemRef | docDocRef:'qty_packing_UOMRef':'code' | async}}
                          </td>
                          <td class="table-col right">
                            {{array?.itemRef|docRef:'quality_pcs' | async | number:'1.' }}
                            {{array?.itemRef | docDocRef:'qty_unit_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col right" colspan="5">
                            Total:
                          </td>

                          <td class="table-col right">
                            {{array?.amount_FOB | currency:' ':'code'}}

                          </td>
                          <td class="table-col right">
                            {{array?.qty_packing | number:'1.'}}
                            {{array?.itemRef | docDocRef:'qty_packing_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col right">
                            {{array?.qty_unit | number:'1.'}}
                            {{array?.itemRef | docDocRef:'qty_unit_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col">

                          </td>
                          <td class="table-col">

                          </td>
                          <td class="table-col">

                          </td>



                        </tr>
                      </ng-container>
                      <ng-container *ngIf="array.type.key === 2">
                        <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">{{i+1}}</td>
                          <td class="table-col">
                            {{item?.soo_number}}
                          </td>
                          <td class="table-col">
                            {{item?.soo_date?.toDate() | date}}
                          </td>
                          <td class="table-col right">
                            {{item?.amount | currency:' ':'code'}}
                          </td>
                          <td class="table-col right">
                            {{item?.quality_cnt | number:'1.'}}
                            {{item?.qty_packing_UOMRef|docRef:'code' | async}}
                          </td>
                          <td class="table-col right">
                            {{item?.quality_pcs | number:'1.'}}
                            {{item?.qty_unit_UOMRef|docRef:'code' | async}}
                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docRef: 'co_number' | async}}
                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'co_date' | async)?.toDate() | date}}

                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'invoice_number' | async)}}

                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'invoice_date' | async)?.toDate() | date}}

                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'etd' | async)?.toDate() | date}}

                          </td>
                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'amount_FOB' | async | currency:' ':'code')}}

                          </td>
                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docRef: 'qty_packing' | async | number:'1.'}}
                            {{array?.itemRef | docDocDocRef:'register_itemRef':'qty_packing_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docRef: 'qty_unit' | async | number:'1.'}}
                            {{array?.itemRef|docDocDocRef:'register_itemRef':'qty_unit_UOMRef':'code' | async}}

                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{array?.itemRef | docRef: 'inspect_number' | async}}
                          </td>
                          <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                            {{(array?.itemRef | docRef: 'inspect_date' | async)?.toDate() | date}}

                          </td>

                        </tr>
                        <tr class="table-row">
                          <td class="table-col right" colspan="3">
                            Total:
                          </td>
                          <td class="table-col right">
                            {{array?.amount | currency:' ':'code'}}
                          </td>
                          <td class="table-col right">
                            {{array?.quality_cnt | number:'1.'}}
                            {{array?.soo_items[0]?.qty_packing_UOMRef|docRef:'code' | async}}
                          </td>
                          <td class="table-col right">
                            {{array?.quality_pcs | number:'1.'}}
                            {{array?.soo_items[0]?.qty_unit_UOMRef|docRef:'code' | async}}
                          </td>
                          <td class="table-col right" colspan="5">
                            Total:
                          </td>
                          <td class="table-col right">
                            {{(array?.itemRef | docRef: 'amount_FOB' | async | currency:' ':'code')}}
                          </td>
                          <td class="table-col right">
                            {{array?.itemRef | docRef: 'qty_packing' | async | number:'1.'}}
                            {{array?.itemRef | docDocDocRef:'register_itemRef':'qty_packing_UOMRef':'code' | async}}
                          </td>
                          <td class="table-col right">
                            {{array?.itemRef | docRef: 'qty_unit' | async | number:'1.'}}
                            {{array?.itemRef | docDocDocRef:'register_itemRef':'qty_unit_UOMRef':'code' | async}}
                          </td>
                          <td class="table-col">
                          </td>
                          <td class="table-col">
                          </td>
                          <td class="table-col">
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>

                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>

          <ng-container *ngIf="collection == 'register_submit_form'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Product Type</div>
                <div class="dl-text">:
                  {{data?.productTypeRef | docRef: 'name' | async}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  {{data?.routeRef | docRef: 'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master ">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" colspan="2"> CO Reference</th>
                      <th class="table-col center" rowspan="2"> Quantity Unit</th>
                      <th class="table-col center" rowspan="2"> Amount</th>
                      <th class="table-col center" rowspan="2"> CAT</th>
                      <th class="table-col center" colspan="2"> Invoice</th>
                      <th class="table-col center" colspan="2"> Join Inspection</th>
                      <th class="table-col center" rowspan="2"> Bill No.</th>
                      <th class="table-col center" rowspan="2"> Destination</th>
                      <th class="table-col center" colspan="2"> Service Fee</th>
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">No</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">No</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">No</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">ADM Fee </th>
                      <th class="table-col center">EMF Fee</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRef :'co_number' | async}}
                      </td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                      <td class="table-col right">
                        {{item?.registerItemRef | docRef:'amount_FOB' | async}}

                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef :'cat' | async}}

                      </td>
                      <td class="table-col ">
                        {{item?.registerItemRef | docRef:'invoice_number' | async }}

                      </td>
                      <td class="table-col ">
                        {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                      </td>
                      <td class="table-col ">
                        {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                      </td>
                      <td class="table-col ">
                        {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                      </td>
                      <td class="table-col ">
                        {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                      <td class="table-col ">

                        {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                      </td>
                      <td class="table-col right">
                        {{item?.registerCoRef | docRef:'admFee' | async }}
                      </td>
                      <td class="table-col right">
                        {{item?.registerCoRef | docRef:'emfFee' | async}}
                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>


          <ng-container *ngIf="collection == 'register_return_application'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Title</div>
                <div class="dl-text">:
                  {{data?.titleRef | docRef:'name' | async}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Form</div>
                <div class="dl-text">:
                  {{data?.co_formRef | docRef:'name' | async}}

                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Product</div>
                <div class="dl-text">:
                  {{data?.productTypeRef | docRef:'name' | async}}

                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  {{data?.routeRef | docRef:'name' | async}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">:
                  {{data?.khmerDate}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">:
                  {{data?.lunarDate}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master ">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" colspan="2"> CO Reference</th>
                      <th class="table-col center" rowspan="2"> Quantity Unit</th>
                      <th class="table-col center" rowspan="2"> Amount</th>
                      <th class="table-col center" rowspan="2"> CAT</th>
                      <th class="table-col center" colspan="2"> Invoice</th>
                      <th class="table-col center" colspan="2"> Joint Inspection</th>
                      <th class="table-col center" rowspan="2"> Bill No.</th>
                      <th class="table-col center" rowspan="2"> Destination</th>
                      <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">No</th>
                      <th class="table-col center" style="width: 88px;">Date</th>
                      <th class="table-col center">No</th>
                      <th class="table-col center" style="width: 88px;">Date</th>
                      <th class="table-col center">No</th>
                      <th class="table-col center" style="width: 88px;">Date</th>
                      <!-- <th class="table-col ">ADM Fee </th>
                                            <th class="table-col ">EMF Fee</th> -->
                    </tr>
                  </thead>
                  <tbody class="table-body" *ngIf="data">
                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRef :'co_number' | async}}
                      </td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                      <td class="table-col right">
                        {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                      </td>
                      <td class="table-col ">
                        {{item?.registerItemRef | docRef:'invoice_number' | async }}

                      </td>
                      <td class="table-col ">
                        {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                      </td>
                      <td class="table-col ">
                        {{item?.pickupInspectionRef| docRef:'inspect_number' | async }}
                      </td>
                      <td class="table-col ">
                        {{item?.pickupInspectionRef | docRefDate:'inspect_date' | async | date}}
                      </td>
                      <td class="table-col ">
                        {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                      <td class="table-col ">

                        {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                      </td>
                      <!-- <td class="table-col ">
                                                {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                            </td>
                                            <td class="table-col ">
                                                {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                            </td> -->
                    </tr>
                    <tr class="table-row">
                      <td class="table-col center" colspan="3">
                        <strong>Total:</strong>
                      </td>
                      <td class="table-col right">
                        {{detail?.totalQuantity| number:'1.'}}
                      </td>
                      <td class="table-col right">
                        {{detail?.totalAmount| currency:' ':'code'}}
                      </td>
                      <td class="table-col ">
                      </td>
                      <td class="table-col "></td>
                      <td class="table-col "></td>
                      <td class="table-col "></td>
                      <td class="table-col "></td>
                      <td class="table-col "></td>
                      <td class="table-col "></td>

                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>



          </ng-container>

          <ng-container *ngIf="collection == 'register_import_stock'">

            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>

              <div class="dl-items">
                <div class="dl-title">Title</div>
                <div class="dl-text">:
                  {{data?.titleRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Form</div>
                <div class="dl-text">:
                  {{data?.co_formRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" rowspan="2">Import Country</th>
                      <th class="table-col center" colspan="2">Invoice</th>
                      <th class="table-col center" colspan="2">Permit</th>
                      <th class="table-col center" colspan="2">Custom</th>
                      <th class="table-col center" colspan="2">Cam Control</th>

                      <th class="table-col center" rowspan="2">Purchase Number</th>
                      <th class="table-col center" rowspan="2">CAT</th>
                      <th class="table-col center" rowspan="2">Qty(Order)</th>
                      <th class="table-col center" rowspan="2">UOM</th>
                      <th class="table-col center" rowspan="2">Export to</th>
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'invoice_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'permit_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'custom_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'camControl_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'purchase_number'|async}}
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef|docRef:'cat'|async}}
                      </td>
                      <td class="table-col right">
                        {{item?.itemRef|docRef:'qty_order'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'description_en' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'export_to'|async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <app-group-stock-material *ngIf="data" [key]="data?.key"></app-group-stock-material>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>


          </ng-container>

          <ng-container *ngIf="collection == 'register_cut_stock'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Title</div>
                <div class="dl-text">:
                  {{data?.titleRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Form</div>
                <div class="dl-text">:
                  {{data?.co_formRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Document Type</div>
                <div class="dl-text">:
                  {{data?.description}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" colspan="2">CO</th>
                      <th class="table-col center" colspan="2">Invoice</th>
                      <th class="table-col center" rowspan="2">Shipping Date</th>
                      <th class="table-col center" rowspan="2">Description</th>
                      <th class="table-col center" rowspan="2">Route</th>
                      <th class="table-col center" rowspan="2">Destination</th>
                      <th class="table-col center" rowspan="2">CAT</th>
                      <th class="table-col center" rowspan="2">Amount</th>
                      <th class="table-col center" rowspan="2">Qty Packing</th>
                      <th class="table-col center" rowspan="2">UOM</th>
                      <th class="table-col center" rowspan="2">Qty Unit</th>
                      <th class="table-col center" rowspan="2">UOM</th>
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                      <td class="table-col">
                        {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'etd' | async | date}}</td>
                      <td class="table-col">
                        {{item?.itemName}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}</td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'cat' | async}}
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'amount_FOB' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'qty_packing' | async | number:'1.'}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'qty_unit' | async | number:'1.'}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <app-group-cut-stock-material *ngIf="data" [key]="data?.key"></app-group-cut-stock-material>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'local_cut_stock'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Title</div>
                <div class="dl-text">:
                  {{data?.titleRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Form</div>
                <div class="dl-text">:
                  {{data?.co_formRef | docRef:'name' | async}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Document Type</div>
                <div class="dl-text">:
                  {{data?.description}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center" rowspan="2">No.</th>
                      <th class="table-col center" colspan="2">CO</th>
                      <th class="table-col center" colspan="2">Invoice</th>
                      <th class="table-col center" rowspan="2">Shipping Date</th>
                      <th class="table-col center" rowspan="2">Description</th>
                      <th class="table-col center" rowspan="2">Route</th>
                      <th class="table-col center" rowspan="2">Destination</th>
                      <th class="table-col center" rowspan="2">CAT</th>
                      <th class="table-col center" rowspan="2">Amount</th>
                      <th class="table-col center" rowspan="2">Qty Packing</th>
                      <th class="table-col center" rowspan="2">UOM</th>
                      <th class="table-col center" rowspan="2">Qty Unit</th>
                      <th class="table-col center" rowspan="2">UOM</th>
                    </tr>
                    <tr class="table-row">
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                      <th class="table-col center">Number</th>
                      <th class="table-col center">Date</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                      <td class="table-col">
                        {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'etd' | async | date}}</td>
                      <td class="table-col">
                        {{item?.itemName}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}</td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'cat' | async}}
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'amount_FOB' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'qty_packing' | async | number:'1.'}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                      </td>
                      <td class="table-col right">
                        {{item?.registerItemRef | docRef : 'qty_unit' | async | number:'1.'}}
                      </td>
                      <td class="table-col">
                        {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="dl-items">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <app-local-group-cut-stock-material *ngIf="data" [key]="data?.key">
              </app-local-group-cut-stock-material>
              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="collection == 'register_cancel_co_letter' || collection == 'register_reapply_letter' || collection == 'register_duplicate_letter'">
            <div style="padding: 0 33px">
              <ng-container>
                <table style="margin: 0 auto;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="page">
                          <div class="report-header" style="max-width:190mm">
                            <div class="d-flex">
                              <div class="flex-1"></div>
                              <div class="title-info bavacana">
                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                <div class="star">************</div>
                              </div>
                            </div>
                            <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                              <div class="info" style="text-align: left;">
                                <div class="d-flex">
                                  <div>
                                    <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                      <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                                      </app-company-field>
                                    </div>
                                    <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                      <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                                      </app-company-field>
                                    </div>
                                  </div>
                                </div>
                                <div class="">
                                  <span class="khf">
                                    <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                                    </app-company-field>
                                  </span>
                                </div>
                                <div class="enf">
                                  Reference No:
                                  <span class="enf">{{data?.refNo}}</span>
                                </div>

                              </div>
                            </div>
                            <div class="d-flex" style="align-items: center;
                                                            justify-content: flex-end;
                                                            position: relative;
                                                            top: -60px;">
                              <div class="khf" style="text-align: right;">
                                <p>
                                  {{data?.lunarDate}}
                                </p>
                                <p style="margin-bottom: 6px;">
                                  {{data?.khmerDate}}
                                </p>
                              </div>
                            </div>
                            <!-- <div class="d-flex"
                                                            style="align-items: center; justify-content: center; padding: 20px 0;">
                                                            <div style="text-align: center; margin-bottom: 6px;"
                                                                class="bold khf">
                                                                <h3 style="text-decoration: underline;">សូមគោរពជូន
                                                                </h3>
                                                                <h3 style="text-decoration: underline;">
                                                                    {{data?.item?.titleRef | docRef:'name_kh' | async}}
                                                                    ក្រសួងពាណិជ្ជកម្ម</h3>
                                                            </div>
                                                        </div> -->

                            <div class="d-flex" style="align-items: flex-end;">
                              <div class="info">
                                <div class="c-comment" [innerHtml]="data?.text | safe:'html'">
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div style="margin-top: 12px;">
                                                        <div class="d-flex"
                                                            style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                            <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                        </div>
                                                    </div> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div class="page-footer-space"></div>
                      </td>
                    </tr>
                  </tfoot>

                </table>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_other_letter'">
            <div style="padding: 0 33px">
              <ng-container>
                <table style="margin: 0 auto;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="page">
                          <div class="report-header" style="max-width:190mm">


                            <div class="d-flex" style="align-items: flex-end;">
                              <div class="info">
                                <div class="c-comment" [innerHtml]="data?.text | safe:'html'">
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div style="margin-top: 12px;">
                                                    <div class="d-flex"
                                                        style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                        <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                    </div>
                                                </div> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div class="page-footer-space"></div>
                      </td>
                    </tr>
                  </tfoot>

                </table>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_letter_form'">
            <div style="padding: 0 33px">
              <ng-container>
                <table style="margin: 0 auto;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="page">
                          <div class="report-header" style="max-width:190mm">


                            <div class="d-flex" style="align-items: flex-end;">
                              <div class="info">
                                <div class="c-comment" [innerHtml]="data?.text | safe:'html'">
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div style="margin-top: 12px;">
                                                  <div class="d-flex"
                                                      style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                      <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                  </div>
                                              </div> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div class="page-footer-space"></div>
                      </td>
                    </tr>
                  </tfoot>

                </table>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_ob_stock'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>
              <!-- <div class="dl-items">
                <div class="dl-title"> Released Date</div>
                <div class="dl-text">:
                  {{data?.release_date?.toDate() | date}}
                </div>
              </div> -->
              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Memo</div>
                <div class="dl-text">: {{data?.memo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">CO Material Code</th>
                      <th class="table-col center">CO Material Name</th>
                      <!-- <th class="table-col center">UOM</th> -->
                      <th class="table-col center">Quantity</th>
                      <th class="table-col center">Cost</th>
                      <th class="table-col center">Total</th>
                      <th class="table-col center">NN.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <ng-container>
                      <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <!-- <td class="table-col"></td> -->
                        <td class="table-col right">
                          {{item?.import_qty | number : '.2-2'}}
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>
                        <td class="table-col right">${{item?.cost | number : '.2-2'}}</td>
                        <td class="table-col right">${{item?.totalCost | number : '.2-2'}}</td>
                        <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Customs Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.importMaterial?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Customs Material Code</th>
                      <th class="table-col center">Customs Material Name</th>
                      <th class="table-col ">Quantity</th>
                      <th class="table-col ">Unit Price</th>
                      <th class="table-col ">Amount</th>
                      <th class="table-col center">N.W</th>
                      <th class="table-col center">G.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                      <!-- <td class="table-col">
                                    </td> -->
                      <td class="table-col right">
                        {{item?.qtyUnit | number}}
                        {{item?.qty_UOMRef|docRef:'code'|async}}

                      </td>
                      <td class="table-col right">{{item?.unitPrice | number}}</td>
                      <td class="table-col right">{{item?.totalCost | number}}</td>

                      <td class="table-col right">
                        {{item?.qtyNw | number}}
                        {{item?.uomRef|docRef:'code'|async}}

                      </td>

                      <td class="table-col right">
                        {{item?.qtyGw | number}}
                        {{item?.uomRef|docRef:'code'|async}}

                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col right" colspan="3">
                        Total
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyUnit') | number}}
                      </td>
                      <td class="table-col right">
                        <!-- {{ (data?.importMaterial | sumAmount:'unitPrice') | number}} -->
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'totalCost') | number}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>


              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="collection == 'register_adjustment_stock'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Released Date</div>
                <div class="dl-text">:
                  {{data?.release_date?.toDate() | date}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Memo</div>
                <div class="dl-text">: {{data?.memo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">CO Material Code</th>
                      <th class="table-col center">CO Material Name</th>
                      <!-- <th class="table-col center">UOM</th> -->
                      <th class="table-col center">Quantity</th>
                      <th class="table-col center">Cost</th>
                      <th class="table-col center">Total</th>
                      <th class="table-col center">NN.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <ng-container>
                      <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <!-- <td class="table-col"></td> -->
                        <td class="table-col right">
                          {{item?.import_qty | number : '.2-2'}}
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>
                        <td class="table-col right">${{item?.cost | number : '.2-2'}}</td>
                        <td class="table-col right">${{item?.totalCost | number : '.2-2'}}</td>
                        <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Customs Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.importMaterial?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col ">No.</th>
                      <th class="table-col ">Customs Material Code</th>
                      <th class="table-col ">Customs Material Name</th>

                      <th class="table-col ">Quantity</th>
                      <th class="table-col ">Unit Price</th>
                      <th class="table-col ">Amount</th>

                      <!-- <th class="table-col ">UOM</th> -->
                      <th class="table-col ">N.W</th>
                      <th class="table-col ">G.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>

                      <td class="table-col right">
                          {{item?.qtyUnit | number}}
                          {{item?.qty_UOMRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col right">{{item?.unitPrice | number}}</td>
                      <td class="table-col right">{{item?.totalCost | number}}</td>

                      <!-- <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td> -->
                      <td class="table-col right">
                          {{item?.qtyNw | number}}
                          {{item?.uomRef|docRef:'code'|async}}

                      </td>
                      <td class="table-col right">
                          {{item?.qtyGw | number}}
                          {{item?.uomRef|docRef:'code'|async}}
                      </td>

                    </tr>
                    <tr class="table-row">
                      <td class="table-col right" colspan="3">
                        Total
                      </td>

                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyUnit') | number}}
                    </td>
                    <td class="table-col right">
                        <!-- {{ (ITEM?.importMaterial | sumAmount:'unitPrice') | number}} -->
                    </td>
                    <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'totalCost') | number}}
                    </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>


              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_adjustment_stock_under'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">: {{data?.refNo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  {{data?.application_date?.toDate() | date}}
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Released Date</div>
                <div class="dl-text">:
                  {{data?.release_date?.toDate() | date}}
                </div>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">: {{data?.khmerDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">: {{data?.lunarDate}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Memo</div>
                <div class="dl-text">: {{data?.memo}}</div>
              </div>
              <div class="dl-items">
                <div class="dl-title">CO Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">CO Material Code</th>
                      <th class="table-col center">CO Material Name</th>
                      <!-- <th class="table-col ">UOM</th> -->
                      <th class="table-col center">NN.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <ng-container>
                      <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                        <!-- <td class="table-col"></td> -->
                        <td class="table-col right">
                          {{item?.qty | number : '.2-2'}}
                          {{item?.uomRef|docRef:'code'|async}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Customs Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.importMaterial?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col ">No.</th>
                      <th class="table-col ">Customs Material Code</th>
                      <th class="table-col ">Customs Material Name</th>

                      <th class="table-col ">Quantity</th>
                      <th class="table-col ">Unit Price</th>
                      <th class="table-col ">Amount</th>

                      <!-- <th class="table-col ">UOM</th> -->
                      <th class="table-col ">N.W</th>
                      <th class="table-col ">G.W</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row" *ngFor="let item of data?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>

                      <td class="table-col right">
                          {{item?.qtyUnit | number}}
                          {{item?.qty_UOMRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col right">{{item?.unitPrice | number}}</td>
                      <td class="table-col right">{{item?.totalCost | number}}</td>

                      <!-- <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td> -->
                      <td class="table-col right">
                          {{item?.qtyNw | number}}
                          {{item?.uomRef|docRef:'code'|async}}

                      </td>
                      <td class="table-col right">
                          {{item?.qtyGw | number}}
                          {{item?.uomRef|docRef:'code'|async}}
                      </td>
                      
                    </tr>

                    <tr class="table-row">
                      <td class="table-col right" colspan="3">
                        Total
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyUnit') | number}}
                      </td>
                      <td class="table-col right">
                          <!-- {{ (ITEM?.importMaterial | sumAmount:'unitPrice') | number}} -->
                      </td>
                      <td class="table-col right">
                          {{ (data?.importMaterial | sumAmount:'totalCost') | number}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                      </td>
                      <td class="table-col right">
                        {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">:
                  {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
              </div>
              <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.status?.text}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="collection == 'print_templates'">
            <div style="padding: 0 33px">
              <div class="dl-items">
                <div class="dl-title"> Company Name (EN)</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.company_en?data?.company_en:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Company Name (KH)</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.company_kh?data?.company_kh:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">English Address</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.address_en?data?.address_en:""}}
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title">Khmer Address</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.address_kh?data?.address_kh:""}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="store?.process">
          <div style="padding: 0 33px;">
            <app-line-loading></app-line-loading>
            <app-line-loading></app-line-loading>
          </div>
        </ng-container>

      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>