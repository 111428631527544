<div class="scrollable-table" style="margin: 0;" id="print" *ngIf="materials?.length>0">
    <table class="table-wrapper tb-master">
        <thead class="table-header">
            <tr class="table-row">
                <th class="table-col center">No.</th>
                <th class="table-col center">Code</th>
                <th class="table-col center">Description</th>
                <!-- <th class="table-col center">UOM</th> -->
                <th class="table-col center">Quantity</th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr class="table-row" *ngFor="let item of materials;let i=index">
                <td class="table-col">{{i+1}}</td>
                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                <!-- <td class="table-col"></td> -->
                <td class="table-col">
                    {{item?.qty | number}}
                    {{item?.uomRef|docRef:'code'|async}}
                </td>
            </tr>
        </tbody>
    </table>
</div>