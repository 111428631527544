export const fileFolder = [

    {
        key: 'import-application', name: 'Import Application', route: '/file-manager/lists/import-application',
        item: [
            // { key: 'supplier_group', name: 'Register Supplier Group', route: '/file-manager/lists/import-application/register/supplier_group' },
            // { key: 'supplier', name: 'Register Supplier', route: '/file-manager/lists/import-application/register/supplier' },
            // { key: 'register_master_list', name: 'Register Master List', route: '/file-manager/lists/import-application/register/register_master_list' },
            { key: 'register_material', appkey: 'read_register_co_material', name: 'Register CO Material', route: '/file-manager/lists/import-application/register/register_material' },
            { key: 'register_invoice', appkey: 'read_register_co_import_inv', name: 'Register CO Import Invoice', route: '/file-manager/lists/import-application/register/register_invoice' },
            { key: 'register_invoice_info', appkey: 'read_register_co_import_inv_information', name: 'Register CO Import Invoice Information', route: '/file-manager/lists/import-application/register/register_invoice_info' },
            { key: 'register_import_material', appkey: 'read_register_import_material', name: 'Register Customs Material', route: '/file-manager/lists/import-application/register/register_import_material' },
            { key: 'register_import_invoice', appkey: 'read_register_co_import_inv', name: 'Register Customs Import Invoice', route: '/file-manager/lists/import-application/register/register_import_invoice' },

            { key: 'register_master_list', appkey: 'read_register_master_list', name: 'Register Master List', route: '/file-manager/lists/import-application/register/register_master_list' },

        ]
    },
    {
        key: 'export-application', name: 'Export Application', route: '/file-manager/lists/export-application',
        item: [
            // { key: 'customer_group', name: 'Register Customer Group', route: '/file-manager/lists/export-application/register/customer_group' },
            // { key: 'customer', name: 'Register Customer', route: '/file-manager/lists/export-application/register/customer' },
            // { key: 'register_product', name: 'Register Product', route: '/file-manager/lists/export-application/register/register_product' },
            // { key: 'register_bom', name: 'Register BOM', route: '/file-manager/lists/export-application/register/register_bom' },
            { key: 'register_item', appkey: 'read_register_co_export_inv', name: 'Register CO Export Invoice', route: '/file-manager/lists/export-application/register/register_item' },
            { key: 'register_item_usage', appkey: 'read_register_co_export_inv_usage', name: 'Register CO Export Invoice Usage', route: '/file-manager/lists/export-application/register/register_item_usage' },
            { key: 'register_co', appkey: 'read_register_export_co', name: 'Register Export CO', route: '/file-manager/lists/export-application/register/register_co' },
            { key: 'pickup_bill', appkey: 'read_register_export_bill', name: 'Register Export Bill', route: '/file-manager/lists/export-application/register/pickup_bill' },
            { key: 'pickup_inspect', appkey: 'read_register_export_inspect', name: 'Register Export Inspect', route: '/file-manager/lists/export-application/register/pickup_inspect' },


        ],

    },
    {
        key: 'submit-application', name: 'Submit Application', route: '/file-manager/lists/submit-application',
        item: [
            { key: 'register_soo', appkey: 'read_register_soo', name: 'Register SOO', route: '/file-manager/lists/submit-application/register/register_soo' },
            { key: 'register_soo_statement', appkey: 'read_register_soo_statement', name: 'Register SOO Statement', route: '/file-manager/lists/submit-application/register/register_soo_statement' },
            { key: 'register_submit_form', appkey: 'read_register_submit_form', name: 'Register Submit Form', route: '/file-manager/lists/submit-application/register/register_submit_form' },
            { key: 'register_submit_crf', appkey: 'read_register_submit_crf', name: 'Register Submit CRF', route: '/file-manager/lists/submit-application/register/register_submit_crf' },
            { key: 'register_cancel_co', appkey: 'read_register_cancel_co', name: 'Register Cancel CO', route: '/file-manager/lists/submit-application/register/register_cancel_co' },
            { key: 'register_cancel_co_letter', appkey: 'read_register_cancel_co_letter', name: 'Register Cancel Letter', route: '/file-manager/lists/submit-application/register/register_cancel_co_letter' },
            { key: 'register_letter_form', appkey: 'read_register_letter_form', name: 'Register Other Letter', route: '/file-manager/lists/submit-application/register/register_letter_form' },
            { key: 'register_other_letter', appkey: 'read_register_other_letter', name: 'Register Letter Form', route: '/file-manager/lists/submit-application/register/register_other_letter' },
        ]
    },
    {
        key: 'register-application', name: 'Return Application', route: '/file-manager/lists/register-application',
        item: [
            { key: 'register_return_application', appkey: 'read_register_return', name: 'Register Return', route: '/file-manager/lists/register-application/register/register_return_application' },
            { key: 'register_duplicate', appkey: 'read_register_duplicate_form', name: 'Register Duplicate Form', route: '/file-manager/lists/register-application/register/register_duplicate' },
            { key: 'register_duplicate_item', appkey: 'read_register_duplicate_inv', name: 'Register Duplicate Invoice', route: '/file-manager/lists/register-application/register/register_duplicate_item' },
            { key: 'register_reapply_letter', appkey: 'read_register_reapply_letter', name: 'Register Re-apply Letter', route: '/file-manager/lists/register-application/register/register_reapply_letter' },
            { key: 'register_duplicate_letter', appkey: 'read_register_duplicate_letter', name: 'Register Duplicate Letter', route: '/file-manager/lists/register-application/register/register_duplicate_letter' },


            { key: 'register_reapply_item', appkey: 'read_register_reapply_item', name: 'Register Re-apply Invoice', route: '/file-manager/lists/register-application/register/register_reapply_item' },
            { key: 'register_re_apply', appkey: 'read_register_re_apply', name: 'Register Re-apply Form', route: '/file-manager/lists/register-application/register/register_re_apply' },
           
            
        ]
    },
    {
        key: 'stock-application', name: 'Stock Application', route: '/file-manager/lists/stock-application',
        item: [
            { key: 'register_ob_stock', appkey: 'read_register_co_ob_stock', name: 'Register OB Stock', route: '/file-manager/lists/stock-application/register/register_ob_stock' },
            { key: 'register_cut_stock', appkey: 'read_register_co_cut_stock', name: 'Register CO Cut Stock', route: '/file-manager/lists/stock-application/register/register_cut_stock' },
            { key: 'register_import_stock', appkey: 'read_register_co_import_stock', name: 'Register Import Stock', route: '/file-manager/lists/stock-application/register/register_import_stock' },
            { key: 'register_adjustment_stock', appkey: 'read_register_co_adjusted_over', name: 'Register Adjustment Stock Over', route: '/file-manager/lists/stock-application/register/register_adjustment_stock' },
            { key: 'register_adjustment_stock_under', appkey: 'read_register_co_adjusted_under', name: 'Register Adjustment Stock Under', route: '/file-manager/lists/stock-application/register/register_adjustment_stock_under' },
        ]
    },

    {
        key: 'delivery-application', name: 'Delivery Application', route: '/file-manager/lists/delivery-application',

        item: [
            { key: 'register_delivery_export_document', appkey: 'read_delivery_export_inspect', name: 'Delivery Export Inspect', route: '/file-manager/lists/delivery-application/register/register_delivery_export_document' },
            { key: 'delivery_return_application', appkey: 'read_delivery_return', name: 'Delivery Return', route: '/file-manager/lists/delivery-application/register/delivery_return_application' },
            { key: 'register_delivery_co_application', appkey: 'read_delivery_export_co', name: 'Delivery Export CO', route: '/file-manager/lists/delivery-application/register/register_delivery_co_application' },
            { key: 'register_delivery_import_stock', appkey: 'read_delivery_co_import_stock', name: 'Delivery CO Import Stock', route: '/file-manager/lists/delivery-application/register/register_delivery_import_stock' },
            { key: 'register_delivery_cut_stock', appkey: 'read_delivery_co_cut_stock', name: 'Delivery CO Cut Stock', route: '/file-manager/lists/delivery-application/register/register_delivery_cut_stock' },
            { key: 'register_delivery_local_import_stock', appkey: 'read_register_delivery_local_import_stock', name: 'Delivery Local Purchase Stock', route: '/file-manager/lists/delivery-application/register/register_delivery_local_import_stock' },
            { key: 'local_delivery_cut_stock', appkey: 'read_local_delivery_cut_stock', name: 'Delivery Local Sale Stock', route: '/file-manager/lists/delivery-application/register/local_delivery_cut_stock' },
            { key: 'local_delivery', appkey: 'read_local_delivery', name: 'Delivery Local Sale Invoice', route: '/file-manager/lists/delivery-application/register/local_delivery' },
        ]
    },


    {
        key: 'local-inv-application', name: 'Local Sale Application', route: '/file-manager/lists/local-inv-application',

        item: [
            { key: 'local_sale_inv', appkey: 'read_local_sale_inv', name: 'Local Sale Invoice', route: '/file-manager/lists/local-inv-application/register/local_sale_inv' },
            { key: 'local_sale_inv_usage', appkey: 'read_local_sale_inv_usage', name: 'Local Sale Invoice Usage', route: '/file-manager/lists/local-inv-application/register/local_sale_inv_usage' },
            { key: 'local_cut_stock', appkey: 'read_local_cut_stock', name: 'Local Sale Stock', route: '/file-manager/lists/local-inv-application/register/local_cut_stock' },
        ]
    },



    {
        key: 'local-purchase-inv-application', name: 'Local Purchase Application', route: '/file-manager/lists/local-purchase-inv-application',

        item: [
            { key: 'register_local_invoice', appkey: 'read_register_local_invoice', name: 'Local Purchase Invoice', route: '/file-manager/lists/local-purchase-inv-application/register/register_local_invoice' },
            { key: 'register_local_invoice_info', appkey: 'read_lregister_local_invoice_info', name: 'Local Purchase Invoice Information', route: '/file-manager/lists/local-purchase-inv-application/register/register_local_invoice_info' },
            { key: 'register_local_import_stock', appkey: 'read_register_local_import_stock', name: 'Local Purchase Stock', route: '/file-manager/lists/local-purchase-inv-application/register/register_local_import_stock' },
        ]
    },

    
    {
        key: 'administrator', name: 'Administrator', route: '/file-manager/lists/administrator',
        item: [
            { key: 'settings_department', appkey: 'read_departments', name: 'Departments', route: '/file-manager/lists/administrator/settings/settings_department' },
            { key: 'settings_coform', appkey: 'read_co_form', name: 'CO Form', route: '/file-manager/lists/administrator/settings/settings_coform' },
            { key: 'settings_uom', appkey: 'read_uom', name: 'UOM', route: '/file-manager/lists/administrator/settings/settings_uom' },
            { key: 'settings_country', appkey: 'read_country', name: 'Country', route: '/file-manager/lists/administrator/settings/settings_country' },
            { key: 'settings_country_port', appkey: 'read_country_port', name: 'Country Port', route: '/file-manager/lists/administrator/settings/settings_country_port' },
            { key: 'settings_forwarder', appkey: 'read_forwarder', name: 'Forwarder', route: '/file-manager/lists/administrator/settings/settings_forwarder' },
            { key: 'settings_route', appkey: 'read_application_route', name: 'Application Route', route: '/file-manager/lists/administrator/settings/settings_route' },
            { key: 'settings_title', appkey: 'read_application_title', name: 'Application Title', route: '/file-manager/lists/administrator/settings/settings_title' },
            { key: 'settings_currency', appkey: 'read_currency', name: 'Currency', route: '/file-manager/lists/administrator/settings/settings_currency' },
            { key: 'settings_product_type', appkey: 'read_application_product_type', name: 'Application Product Type', route: '/file-manager/lists/administrator/settings/settings_product_type' },
            { key: 'hs_codes', appkey: 'read_hs_code', name: 'HS Codes', route: '/file-manager/lists/administrator/settings/hs_codes' },
            { key: 'company', appkey: 'read_company', name: 'Company', route: '/file-manager/lists/administrator/register/company' },
            { key: 'users', appkey: 'read_users', name: 'Users', route: '/file-manager/lists/administrator/register/users' },
        ]
    },


]