<header id="header" *mobxAutorun StickyPolyFill>
    <nav class="top-nav">
        <div class="nav-content">
            <div class="left-nav">
                <div class="nav-item-list">
                    <div class="nav-item" style="margin-left: -12px">
                        <button mat-button (click)="togglesidebar()">
                            <mat-icon class="mat-18">menu</mat-icon>
                        </button>
                    </div>
                    <div class="isSmallScreen" *ngIf="ismobile">


                    </div>
                    <div class="isBigScreen" *ngIf="!ismobile">

                    </div>
                </div>
            </div>
            <h3>{{store?.selectedCompany?.name_en}}</h3>
            <div class="right-nav">
                <div class="nav-item-list">
                    <div class="nav-item">
                        <div class="nav-item-link" [matMenuTriggerFor]="userMenu">
                            <div class="avatar">
                                <avatar [userKey]="store?.user?.key"></avatar>
                                    <!-- <img [src]="store?.user?.key | userAvatarRef | async" alt="" alt="user"> -->
                            </div>
                        </div>

                        <mat-menu #userMenu="matMenu">
                            <mat-progress-bar *ngIf="st?.uploadPercent" mode="determinate" [value]="st?.uploadPercent | async ">
                            </mat-progress-bar>
                            <div class="" style="min-width: auto; width: 360px;    padding: 40px 0;"
                                (click)="$event.stopPropagation();">
            
                                <div class="wellcome-user" style="text-align: center;">
                                    <div class="empty-avatar" style="height: 86px; width: 86px; margin: 0 auto;">
                                        <!-- <img [src]="store?.user?.key | userAvatarRef | async" alt="" alt="user"> -->
                                        <avatar [userKey]="store?.user?.key"></avatar>
                                        <div (click)="fileInput.click()" class="change-profile">
                                            <mat-icon class="mat-18">photo_camera</mat-icon>
                                        </div>
                                    </div>
            
                                    <input #fileInput type="file" multiple accept='image/*' (change)="onSelectedFile($event)"
                                        style="display:none;" />
            
                                    <div class="wellcome-text k-wellcome-text" style="margin-top: 10px;">
                                        <!-- <strong>{{greeting}}</strong>, -->
                                        <strong>{{store?.user?.displayName}}</strong>
                                    </div>
            
                                    <div class="devsite-cpwd" (click)="changePassword()">
                                        <mat-icon>verified_user</mat-icon>
                                        <div class="devsite-list-text" style="padding-left: 10px;">
                                            Change password
                                        </div>
                                    </div>
                                </div>
                                <div class="devsite-logout">
                                    <!-- <mat-icon class="mat-18">
                                        lock_open
                                    </mat-icon> -->
                                    <div class="devsite-list-text" (click)="onLogOut()">
                                        <span>
                                            Sign Out
                                        </span>
                                    </div>
                                </div>
            
                            </div>
                        </mat-menu>

                    </div>
                    <!-- <div class="nav-item">
                        <button mat-icon-button class="app-button" [matMenuTriggerFor]="notificationsMenu">
                            <mat-icon class="mat-18">notifications</mat-icon>
                        </button>
                        <mat-menu #notificationsMenu="matMenu">

                            <div class="notify-lists">
                                <div class="notify-lists-header">
                                    <h4>Notifications</h4>
                                </div>
                                <div class="notify-list-item" *ngFor="let item of notificationsData">
                                    <div class="img-box">
                                        <img [src]="item?.updated_by?.key | userAvatarRef | async" alt="">
                                    </div>
                                    <div class="list-text">
                                        <strong>{{item?.updated_by?.name}}</strong>
                                        <p>{{item?.notify_detail?.description}}</p>
                                        <span>{{item?.create_date?.toDate() | date}}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-menu>
                    </div> -->

           
                </div>
            </div>
        </div>
    </nav>
</header>