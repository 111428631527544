import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'sumFee'
})
export class sumFeePipe implements PipeTransform {

  transform(array: any): any {
    const totalADMFee = sum(array, "admFee")
    const totalEMFFee = sum(array, "emfFee")
    return (totalADMFee + totalEMFFee)
  }

}


@Pipe({
  name: 'sumAdmFee'
})
export class sumAdmFeePipe implements PipeTransform {

  transform(array: any): any {
    const totalADMFee = sum(array, "admFee")
    return totalADMFee
  }

}

@Pipe({
  name: 'sumEmfFee'
})
export class sumEmfFeePipe implements PipeTransform {

  transform(array: any): any {
    const totalEMFFee = sum(array, "emfFee")
    return totalEMFFee
  }

}



@Pipe({
  name: 'sumAmount'
})
export class sumAmountPipe implements PipeTransform {

  transform(array: any, field: string): any {
    // const display = array.map(a => a[field])
    const amount = sum(array, field)
    return amount
  }

}

