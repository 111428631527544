<div class="page-section " *mobxAutorun>
    <div class="sticky-block dialog">
        <div class="action-bar">
            <div class="nav-content">
                <a class="nav-back" (click)="goBack()">
                    <mat-icon class="mat-18" style="margin-right: 0px;">
                        arrow_back_ios
                    </mat-icon>
                    <h3 class="head-title">
                        Outstanding Detail
                    </h3>
                </a>
                <div class="flex1"></div>
                <div class="action-list-item">
                    <div class="action-item">
                        <button mat-icon-button style="background-color: #edeff1;" (click)="updateOutstanding(store?.item)">
                            <mat-icon class="mat-18">edit</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="!store?.process">
        <app-register-item-detail [data]="store?.item" [collection]="'register_item'"
            [title]="'Outstanding information'" [backlink]="'/register-item/'" [isHide]="true" [isHideComment]="false">
        </app-register-item-detail>
    </div>
    <app-placeholder *ngIf="store?.process"></app-placeholder>
</div>