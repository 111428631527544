import { APPS } from './../dummy/config';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { statusObj, REGISTER_ITEM_STATUS_OBJ } from '../dummy/status';
import { ConvertService } from './convert.service';
import { DATA_TYPE } from '../dummy/columns';
import { Auth } from '../stores/auth.store';
import * as moment from 'moment'
import { lastWeek } from './mapping.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  baseUri: any = "http://muypiapp-001-site1.gtempurl.com/public/api"

  constructor(
    private http: Http,
    private db: AngularFirestore
  ) { }

  dataRef() {
    return this.db;
  }
  userDocRef(key) {
    return this.db.collection("users").doc(key);
  }
  audit_trailByuserRef(companyKey, appKey) {
    return this.db.collection("audit_trail", ref => ref.where('companyKey', '==', companyKey).where('key', '==', appKey));
  }

  AuditTrailByuserRef(lastVisible, companyKey, appKey) {
    return this.db.collection("audit_trail", ref => {
      let condition = ref
        // .where('companyKey', '==', companyKey)
        .where('appKey', '==', appKey)
        .orderBy('audit_date', 'desc')
        .limit(15)

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition.limit(5)
    })
  }

  audit_trailRef(appKey) {
    return this.db.collection("audit_trail", ref => ref.where('appKey', '==', appKey));
  }

  materialFireRef() {
    return this.firestore().collection("register_material");
  }

  register_item_usageRef(companyKey, itemkey) {
    return this.db.collection('company').doc(companyKey).collection("register_item_usage", ref => ref.where('register_itemKey', '==', itemkey))
  }

  flileRef() {
    return this.db.collection("storage")
  }
  flileSettingRef(folder) {
    return this.db.collection("storage", ref => ref.where('folder', '==', folder))
  }
  flileByCompany(companyKey, folder) {
    return this.db.collection('company').doc(companyKey).collection("storage", ref => ref.where('folder', '==', folder))
  }
  flileRefByCompany(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("storage")
  }

  flileFireRefByCompany(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection("storage")
  }
  flileFireRef() {
    return this.firestore().collection("storage")
  }




  fecthMaterialRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_material", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_material_status.key", "==", registerStatusKey);
      }
      if (filterStatusKey !== 0) {

        condition = condition.where("status.key", "==", filterStatusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      return condition.limit(APPS.size)
    })
  }

  fetchMaterialDetailRef(lastVisible: any, materialKey: string, companyKey: string, dataCol: string) {
    return this.db.collection("company").doc(companyKey).collection("register_material").doc(materialKey).collection(dataCol, ref => ref.orderBy("page_key"));
    // return this.db.collection("register_material").doc(materialKey).collection("transaction_movement", ref=>ref.orderBy("created_at"));
    // return this.db.collection("register_material", ref => {
    //   let condition = ref.where("companyKey", "==", companyKey)
    //   if (registerStatusKey === 'deleted') {
    //     condition = condition.where("isDelete", "==", true)
    //   }
    //   else {
    //     condition = condition.where("isDelete", "==", false).where("register_material_status.key", "==", registerStatusKey);
    //   }
    //   if (filterStatusKey !== 0) {

    //     condition = condition.where("status.key", "==", filterStatusKey)
    //   }
    //   if (lastVisible) {
    //     const startAfter = lastVisible["updated_at"];
    //     condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
    //   }
    //   else {
    //     condition = condition.orderBy("updated_at", "desc")
    //   }
    //   return condition.limit(APPS.size)
    // })
  }


  resisterCoRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {

    return this.db.collection("register_co", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  registerSOORef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_soo")
  }

  checkExistRegisterRef(companyKey, refNo, collection, feild) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where("companyKey", "==", companyKey)
      .where("status.key", "==", 1)
      .where(feild, "==", refNo.toString().trim())
      .where('isDelete', '==', false)
      .limit(1)
    )
  }

  checkExistRegisterItemRef(companyKey, refNo, collection, feild) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(feild, "==", refNo.toString().trim())
      .where('status.key', '==', 1)
      .where('isDelete', '==', false)
      .limit(1)
    )
  }

  registerPickupRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("pickup_bill")
  }

  registerItemCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_item").doc(key)
  }
  registerLocalSaleCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("local_sale_inv").doc(key)
  }
  registerSooCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_soo").doc(key)
  }
  registerCoCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_co").doc(key)
  }
  registerImportStockCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_import_stock").doc(key)
  }

  registerLocalImportStockCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_local_import_stock").doc(key)
  }

  registerCutStockCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_cut_stock").doc(key)
  }

  registerInvoiceCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_invoice").doc(key)
  }

  registerLocalInvoiceCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_local_invoice").doc(key)
  }

  registerDeliveryReturnCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_return_application").doc(key)
  }

  registerInspectCollectionRefDoc(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("pickup_inspect").doc(key)
  }

  selectedCoItemRef(companyKey, key) {
    return this.db.collection('company').doc(companyKey).collection("register_co_selected_item").doc(key)
  }


  registerPickupInspectRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("pickup_inspect")
  }

  registerSooStatementRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_soo_statement")
  }

  resisterPickUpBillRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection("pickup_bill", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterSOORef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection("register_soo", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterOBRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_ob_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterImportStockRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_import_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterAdjustmentRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_adjustment_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterReApplyFormRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_re_apply", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDuplicateFormRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_duplicate", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterSubmitFormRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string, typeKey: any) {
    return this.db.collection("register_submit_form", ref => {
      let condition = ref.where("companyKey", "==", companyKey).where('submitType.key', '==', typeKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDeliverReturnApplicationRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("delivery_return_application", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterReturnApplicationRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_return_application", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDeliveryCOApplicationRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_delivery_co_application", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDeliveryExportDocumentRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_delivery_export_document", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDeliveryImportStockDocumentRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_delivery_import_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDeliveryCutStockDocumentRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_delivery_cut_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterPickUpInspectRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("pickup_inspect", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }
  resisterSooStatementRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_soo_statement", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }


  getitemRef() {
    return this.db.collection("register_item", ref => ref.where('companyKey', '==', 'cB5jIbcMxjRJIzBiNjgo'))
  }

  resisterItemCompanyRef(lastVisible: any, companyKey: string, itemStatusKey, formFilter?: any) {
    let fromDateKey = 0;
    let toDateKey = 1000000000;
    return this.db.collection("register_item", ref => {
      let keyword: any = "~N/A~"
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = ConvertService.toDateKey(fromDate)
          toDateKey = ConvertService.toDateKey(toDate)
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where('register_status.key', "==", itemStatusKey)
        .where('companyKey', "==", companyKey)
        .where("tag", "array-contains", keyword)
        .orderBy('date_key')
        .where('date_key', ">=", fromDateKey)
        .where('date_key', "<=", toDateKey)
      //lazy load condition or pagination
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      return condition.limit(APPS.size)
    })
  }

  resisterItemRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_item", ref => {

      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  // resisterItemRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string, formFilter?: any) {
  //   return this.db.collection("register_item", ref => {
  //     let condition = ref.where("companyKey", "==", companyKey)
  //       .where("isDelete", "==", registerStatusKey === 'deleted' ? true : false)
  //     if (formFilter) {
  //       const { option, search, includeDate, fromDate, toDate } = formFilter;
  //       const keyword = search.toUpperCase();
  //       const end = keyword.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
  //     }
  //     else {
  //       // condition = condition.where('co_form', "==",null)
  //       // condition = condition.where('invoice_number', ">",null)
  //       // condition = condition.where('date_key', ">=",null)
  //       // condition = condition.where('date_key', "<=",null)

  //       // condition = condition.where('amount_FOB', ">",null)
  //       // condition = condition.where('country', ">","")

  //     }
  //     //lazy load condition
  //     if (lastVisible) {
  //       const startAfter = lastVisible["updated_at"];
  //       condition = condition.orderBy("updated_at", "desc")
  //         .startAfter(startAfter)
  //     }
  //     else {
  //       condition = condition.orderBy("updated_at", "desc")
  //     }
  //     //====
  //     return condition.limit(APPS.size)
  //   })
  // }

  resisterInvoiceRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {

    return this.db.collection("register_invoice", ref => {

      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterReApplyItemRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {

    return this.db.collection("register_reapply_item", ref => {

      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterDuplicateItemRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {

    return this.db.collection("register_duplicate_item", ref => {

      let condition = ref.where("companyKey", "==", companyKey)
      //status load condition
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  searchResisterItemListingRef(collectionName: string, field: string, text: any, filterStatusKey, registerStatusKey: string, companyKey: string, typeKey?: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //Submit Type
      if (typeKey) {
        condition = condition.where("submitType.key", "==", typeKey)
      }

      if (text) {
        condition = condition
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy("updated_at", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("updated_at", "desc").limit(APPS.size)
      }
      return condition;
    })
  }


  searchResisterSubmitListingRef(collectionName: string, field: string, text: any, filterStatusKey, registerStatusKey: string, companyKey: string, typeKey?: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //Submit Type
      if (typeKey) {
        condition = condition.where("submitType.key", "==", typeKey)
      }

      if (text) {
        condition = condition
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy("updated_at", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("updated_at", "desc").limit(APPS.size)
      }
      return condition;
    })
  }

  searchResisterListingRef(collectionName: string, field: string, text: any, filterStatusKey: any, registerStatusKey: string, companyKey: string, typeKey?: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //Submit Type
      if (typeKey) {
        condition = condition.where("submitType.key", "==", typeKey)
      }

      if (text) {
        condition = condition
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy("updated_at", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("updated_at", "desc").limit(APPS.size)
      }
      return condition;
    })
  }

  searchResisterMaterialListingRef(collectionName: string, field: string, text: any, filterStatusKey, registerStatusKey: string, companyKey: string, typeKey?: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_material_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //Submit Type
      if (typeKey) {
        condition = condition.where("submitType.key", "==", typeKey)
      }

      if (text) {
        condition = condition
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy("updated_at", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("updated_at", "desc").limit(APPS.size)
      }
      return condition;
    })
  }

  autosearchListingRef(collectionName: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection(collectionName, ref =>
        ref
          .where("status.key", "==", statusObj.active.key)
          .where(field, "array-contains", search)
          // .orderBy('updated_at', "desc")
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("status.key", "==", statusObj.active.key)
        .where(field, "array-contains", '~N/A~')
        // .orderBy('updated_at', "desc")
        .limit(APPS.smallsize)
    );
  }

  autoSearchByCompanyListingRef(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("company").doc(companyKey).collection(collectionName, ref =>
        ref
          .where("status.key", "==", statusObj.active.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("status.key", "==", statusObj.active.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  autoSearchRegisterCoRef(companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("register_co", ref => ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("registerCutStock", "==", false)
        .where("registerCutStockKey", "==", null)
        .where("outstandingStock", "==", true)
        .where(field, ">=", search)
        .where(field, '<', end)
        .orderBy(field)
        .orderBy("updated_at", "desc"))
    }
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("registerCutStock", "==", false)
      .where("registerCutStockKey", "==", null)
      .where("outstandingStock", "==", true)
      .orderBy("updated_at", "desc"))
  }


  autoSearchListingItemRef(companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("register_item", ref =>
        ref
          .where("isDelete", "==", false)
          .where("status.key", "==", statusObj.active.key)
          .where("companyKey", "==", companyKey)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection("register_item", ref =>
      ref
        .where("isDelete", "==", false)
        .where("status.key", "==", statusObj.active.key)
        .where("companyKey", "==", companyKey)
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }


  autosearchListingMaterialRef(collectionName: string, companyKey: string, field: string, text: any) {

    if (text && !text.key) {
      const keyword = text ? text.toUpperCase() : '~N/A~';

      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("keyword", "array-contains", keyword)
          .where("status.key", "==", 1)
          .where("companyKey", "==", companyKey)
          .orderBy('node')
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        // .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("keyword", "array-contains", '~N/A~')
        .where("status.key", "==", 1)
        .where("companyKey", "==", companyKey)
        .orderBy('node')
        .limit(APPS.smallsize)
    );
  }

  masterListItemRef(companyKey: string) {
    return this.db.collection("items", ref =>
      ref
        .where('status.key', '==', 1)
        .where('isDelete', '==', false)
        .where("company.key", "==", companyKey)
        .orderBy("code", "desc").limit(15)
    );
  }

  masterListItemTypeRef(companyKey: string, typeKey: any) {
    return this.db.collection("master_list", ref =>
      ref
        .where('status.key', '==', typeKey)
        .where('isDelete', '==', false)
        .where("company.key", "==", companyKey)
        .orderBy("code", "desc")
    );
  }

  countryAutoRef(lastVisible) {
    if (lastVisible) {
      return this.db.collection("settings_country", ref =>
        ref
          .where('status.key', '==', 1).where('isDelete', '==', false)
          .orderBy("code", "desc")
          .startAfter(lastVisible.code)
      );
    }
    return this.db.collection("settings_country", ref =>
      ref
        .where('status.key', '==', 1).where('isDelete', '==', false)
        .orderBy("code", "desc")
    );
  }
  masterListRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_master_list")
  }

  deleveryExportRef() {
    return this.db.collection("delivery_export_document")
  }

  deleveryExportTypeRef(companyKey: string, typeKey: any) {
    return this.db.collection("delivery_export_document", ref =>
      ref
        .where('status.key', '==', typeKey)
        .where('isDelete', '==', false)
        .where("company.key", "==", companyKey)
      // .orderBy("code", "desc")
    );
  }

  submitFormRefDoc(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_submit_form")
  }

  cancelCoRefDoc(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_cancel_co")
  }
  submitFormRef(companyKey, ref: any) {
    return this.db.collection('company').doc(companyKey).collection("register_submit_form", ref)
  }
  sooFormRef(ref?: any) {
    return this.db.collection("register_soo_statement", ref)
  }

  returnCancelCOLetterRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("register_cancel_co_letter", ref)
  }

  reapplyLetterRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("register_reapply_letter", ref)
  }

  duplicateLetterRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("register_duplicate_letter", ref)
  }

  returnApplicationRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("register_return_application", ref)
  }

  deliveryReturnApplicationRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("delivery_return_application", ref)
  }

  returnApplicationDocRef(companyKey, key: string) {
    return this.db.collection('company').doc(companyKey).collection("register_return_application").doc(key)
  }

  deliveryCoApplicationRef(companyKey, ref?: any) {
    return this.db.collection('company').doc(companyKey).collection("register_delivery_co_application", ref)
  }

  deliveryCoApplicationDocRef(key: string) {
    return this.db.collection("register_delivery_co_application").doc(key)
  }

  deliveryExportDocumentRef(ref?: any) {
    return this.db.collection("register_delivery_export_document", ref)
  }

  deliveryExportDocumentDocRef(key: string) {
    return this.db.collection("register_delivery_export_document").doc(key)
  }

  deliveryImportStockDocumentRef(ref?: any) {
    return this.db.collection("register_delivery_import_stock", ref)
  }

  deliveryImportStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_delivery_import_stock').doc(key);
  }


  deliveryLocalImportStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_delivery_local_import_stock').doc(key);
  }


  deliveryImportStockDocumentDocRef(key: string) {
    return this.db.collection("register_delivery_import_stock").doc(key)
  }

  deliveryCutStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_delivery_cut_stock').doc(key);
  }
  localDeliveryCutStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('local_delivery_cut_stock').doc(key);
  }

  deliveryCutStockDocumentRef(ref?: any) {
    return this.db.collection("register_delivery_cut_stock", ref)
  }

  deliveryCutStockDocumentDocRef(key: string) {
    return this.db.collection("register_delivery_cut_stock").doc(key)
  }

  uomAutoRef(lastVisible) {
    if (lastVisible) {
      return this.db.collection("settings_uom", ref =>
        ref
          .where('status.key', '==', 1).where('isDelete', '==', false)
          .orderBy("code", "desc")
          .startAfter(lastVisible.code)
      );
    }
    return this.db.collection("settings_uom", ref =>
      ref
        .where('status.key', '==', 1).where('isDelete', '==', false)
        .orderBy("code", "desc")
    );
  }
  fecthitems(collectionName: string, companyKey: string, typeKey: string, field: string) {
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where('type.key', '==', typeKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }
  fecthitemsMaterial(collectionName: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('companyKey', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .limit(APPS.smallsize)
    );
  }


  fecthitemsf(collectionName: string, companyKey: string, field: string) {
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .where("register_item_inspect_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }
  searchfilterListingByCompanyTypeReff(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("isDelete", "==", false)
          .where('company.key', '==', companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_item_inspect_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .where("register_item_inspect_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );

  }

  fecthRegisterItems(collectionName: string, companyKey: string, field: string) {
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  fecthRegisterCO(collectionName: string, companyKey: string, field: string) {
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  searchfilterRegisterItemByCompanyTypeRef(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where('company.key', '==', companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );

  }

  searchfilterListingByCompanyTypeRef(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy('page_key', 'desc')
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .orderBy('page_key', 'desc')
        .limit(APPS.smallsize)
    );

  }


  searchFilterMaterListRef(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy('page_key', 'desc')
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .orderBy(field)
        .orderBy('page_key', 'desc')
        .limit(APPS.smallsize)
    );

  }

  searchfilterListingOutstandingByCompanyTypeRef(collectionName: string, outstandingfiled, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)

    );

  }

  searchfilterLocalListingOutstandingByCompanyTypeRef(collectionName: string, outstandingfiled, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where("registerLocalSaleInvUsage", "==", true)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where("registerLocalSaleInvUsage", "==", true)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)

    );

  }


  searchRef(collectionName: string, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)

      );
    }else if(text && text.key){
      const search = text[field].toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }else{
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .orderBy(field)
          .limit(APPS.smallsize)
      );
  
    }

  }

  searchSettingRef(collectionName: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text ? text.toUpperCase() : '~N/A~';
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("status.key", "==", statusObj.active.key)
          .where("keyword", "array-contains", search)
          // .where(field, ">=", search)
          // .where(field, '<', end)
          .orderBy(field, (collectionName === 'hs_codes' ? "asc" : "desc"))
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("status.key", "==", statusObj.active.key)
        .where("keyword", "array-contains", '~N/A~')
        .orderBy(field, (collectionName === 'hs_codes' ? "asc" : "desc"))
        .limit(APPS.smallsize)
    );

  }
  getPort(countryKey) {
    return this.db.collection('settings_country_port', ref =>
      ref
        .where("status.key", "==", statusObj.active.key)
        .where("tag", "array-contains", countryKey)
    );
  }
  searchfilterListingOutstandingByCompanyTypeRefSOO(collectionName: string, outstandingfiled, otherOutstandingField, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where('type.key', "==", otherOutstandingField)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where('type.key', "==", otherOutstandingField)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)

    );

  }

  searchfilterListingOutstandingByCompanyTypeRefT(collectionName: string, outstandingfiled, otherOutstandingField, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where(otherOutstandingField, "==", true)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where(otherOutstandingField, "==", true)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)

    );

  }

  searchfilterListingOutstandingByCompanyTypeRefSF(collectionName: string, outstandingfiled, otherOutstandingField, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where(otherOutstandingField, "==", false)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where(otherOutstandingField, "==", false)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  searchfilterListingOutstandingByCompanyRef(collectionName: string, outstandingfiled, otherOutstandingField, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where(otherOutstandingField, "==", null)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where(otherOutstandingField, "==", null)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  searchFilterListingOutstandingStockRef(collectionName: string, outstandingfiled, outstandingFieldKey, otherOutstandingField, companyKey: string, field: string, text: any) {

    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where(outstandingFieldKey, "==", null)
          .where(otherOutstandingField, "==", true)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where(outstandingFieldKey, "==", null)
        .where(otherOutstandingField, "==", true)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  searchFilterListingOutstandingStockDeliveryRef(collectionName: string, outstandingfiled, outstandingFieldKey, otherOutstandingField, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", false)
          .where(outstandingFieldKey, "==", null)
          .where(otherOutstandingField, "==", true)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", false)
        .where(outstandingFieldKey, "==", null)
        .where(otherOutstandingField, "==", true)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  searchFilterReapplyStockRef(collectionName: string, outstandingfiled, companyKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("companyKey", "==", companyKey)
          .where(outstandingfiled, "==", true)
          .where("status.key", "==", statusObj.active.key)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("tag", "array-contains", '~N/A~')
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("companyKey", "==", companyKey)
        .where(outstandingfiled, "==", true)
        .where("status.key", "==", statusObj.active.key)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("tag", "array-contains", '~N/A~')
        .orderBy(field)
        .limit(APPS.smallsize)
    );
  }

  hsCodeSubItemRef(parentKey: string) {
    return this.db.collection("hs_codes", ref => ref.where("subItem.key", "==", parentKey).where('status.key', '==', 1));
  }
  SubItemRef(collection, parentKey: string) {
    return this.db.collection(collection, ref => ref.where("subItem.key", "==", parentKey).where('status.key', '==', 1));
  }

  hsCodeFireRef() {
    return this.firestore().collection("hs_codes")
  }
  hscodeSearchRef() {
    return this.db.collection("hs_codes", ref => ref.where("keyword", "array-contains", '~N/A~').where('status.key', '==', 1).orderBy('code').limit(APPS.smallsize));
  }

  getSearchRef(collection) {
    return this.db.collection(collection, ref => ref.where("keyword", "array-contains", '~N/A~').where('status.key', '==', 1).orderBy('code').limit(APPS.smallsize));
  }

  autoListingRef(collectionName: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("keyword", "array-contains", search)
          .where("status.key", "==", statusObj.active.key)
          .orderBy(field)
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("keyword", "array-contains", '~N/A~')
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.smallsize)
    );

  }
  getSearchByCompanyRef(collection, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("keyword", "array-contains", '~N/A~')
      .where("status.key", "==", 1)
      .where("companyKey", "==", companyKey)
      .orderBy('node')
      .limit(APPS.smallsize));
  }

  autoListingByCompanyRef(collectionName: string, companyKey: any, field: string, text: any) {
    if (text && !text.key) {
      const keyword = text ? text.toUpperCase() : '~N/A~';

      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
        ref
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("keyword", "array-contains", keyword)
          .where("status.key", "==", 1)
          .where("companyKey", "==", companyKey)
          .orderBy('node')
          .limit(APPS.smallsize)
      );
    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref =>
      ref
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("keyword", "array-contains", '~N/A~')
        .where("status.key", "==", 1)
        .where("companyKey", "==", companyKey)
        .orderBy('node')
        .limit(APPS.smallsize)
    );

  }

  hsCodeSearchRef(field: string, search: string) {
    if (!search) {
      return this.db.collection("hs_codes", ref =>
        ref.orderBy(field)
          .limit(24)
      );
    }
    return this.db.collection("hs_codes", ref =>
      ref
        .where(field, ">=", search)
        .orderBy(field)
        .limit(24)
    );
  }
  autoColelctionRef(collection) {
    return this.db.collection(collection, ref => ref.where("status.key", "==", statusObj.active.key)
      .where("keyword", "array-contains", '~N/A~')
      // .orderBy('updated_at', "desc")
      .limit(APPS.smallsize));
  }

  autoAllColelctionRef(collection) {
    return this.db.collection(collection, ref => ref.where("tag", "array-contains", '~N/A~').where('status.key', '==', 1));
  }
  autoColelctionByCompanyRef(collection, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref =>
      ref.where("tag", "array-contains", '~N/A~')
        .where('status.key', '==', 1)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .orderBy('page_key', 'desc')
        .limit(APPS.smallsize));
  }

  autoColelctionOutstandingByCompanyRef(collection, outstandingfield, companyKey, field) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref.where(outstandingfield, "==", false)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1)
      .orderBy(field)
      .limit(APPS.smallsize));
  }

  autoLocalColelctionOutstandingByCompanyRef(collection, outstandingfield, companyKey, field) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref.where(outstandingfield, "==", false)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("registerLocalSaleInvUsage", "==", true)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1)
      .orderBy(field)
      .limit(APPS.smallsize));
  }

  autoColelctionOutstandingByCompanyRefT(collection, outstandingfield, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref.where(outstandingfield, "==", false)
      .where(otherOutstandingfield, "==", true)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoColelctionOutstandingByCompanyRefSF(collection, outstandingfield, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref.where(outstandingfield, "==", false)
      .where(otherOutstandingfield, "==", false)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoCollectionOutstandingRef(collection, outstandingfield, otherOutstandingField, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", false)
      .where(otherOutstandingField, "==", null)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~')
      .where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoOutstandingStock(collection, outstandingfield, outstandingFieldKey, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", false)
      .where(outstandingFieldKey, "==", null)
      .where(otherOutstandingfield, "==", true)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoOutstandingStockDelivery(collection, outstandingfield, outstandingFieldKey, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", false)
      .where(outstandingFieldKey, "==", null)
      .where(otherOutstandingfield, "==", true)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoOutstandingCoStock(collection, outstandingfield, outstandingFieldKey, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", false)
      .where(outstandingFieldKey, "==", null)
      .where(otherOutstandingfield, "==", true)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoOutstandingLocalStock(collection, outstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", false)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoReapplyItemStock(collection, outstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref
      .where(outstandingfield, "==", true)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }

  autoColelctionOutstandingByCompanyRefSOO(collection, outstandingfield, otherOutstandingfield, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection, ref => ref.where(outstandingfield, "==", false)
      .where('type.key', "==", otherOutstandingfield)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("tag", "array-contains", '~N/A~').where('status.key', '==', 1).limit(APPS.smallsize));
  }


  autoCollectionByCompanyRef(collection, companyKey, fieldOrder) {
    return this.db.collection(collection, ref => ref
      .where("companyKey", "==", companyKey)
      .where('status.key', '==', 1)
      .where('isDelete', '==', false)
      .orderBy(fieldOrder)
      .limit(APPS.smallsize));
  }

  registerAutoCompleteRef(collectionName, companyKey, fieldOrder, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  registerAutoCompleteSearchRef(collectionName, companyKey, fieldOrder: string, text: any, itemStatusField, itemStatusFieldOther, outstandingField) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref => {
        let condition = ref
          .where('status.key', '==', 1)
          .where("isDelete", "==", false)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("companyKey", "==", companyKey)
          .where(itemStatusField, '==', false)
          .where(itemStatusFieldOther, '==', null)
          .where(fieldOrder, ">=", search)
          .where(fieldOrder, '<', end)

        if (outstandingField) {
          condition = condition.where(outstandingField, "==", true)
        }
        return condition.orderBy(fieldOrder)
          .limit(APPS.smallsize)
      })

    }
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  registerCOAutoCompleteRef(collectionName, companyKey, fieldOrder, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  registerCOAutoCompleteSearchRef(collectionName, companyKey, fieldOrder: string, text: any, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref => {
        let condition = ref
          .where('status.key', '==', 1)
          .where("isDelete", "==", false)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("companyKey", "==", companyKey)
          .where("tag", "array-contains", '~N/A~')
          .where(itemStatusField, '==', false)
          .where(itemStatusFieldOther, '==', null)
          .where(fieldOrder, ">=", search)
          .where(fieldOrder, '<', end)

        if (outstandingField) {
          condition = condition.where(outstandingField, "==", true)
        }
        return condition.orderBy(fieldOrder)
          .limit(APPS.smallsize)
      })

    }
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  registerItemAutoCompleteRef(collectionName, companyKey, fieldOrder, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .orderBy('page_key', 'desc')
        .limit(APPS.smallsize)
    })
  }

  registerItemAutoCompleteSearchRef(collectionName, companyKey, fieldOrder: string, text: any, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection('company').doc(companyKey).collection(collectionName, ref => {
        let condition = ref
          .where('status.key', '==', 1)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("companyKey", "==", companyKey)
          .where("tag", "array-contains", '~N/A~')
          .where(itemStatusField, '==', false)
          .where(itemStatusFieldOther, '==', null)
          .where(fieldOrder, ">=", search)
          .where(fieldOrder, '<', end)

        if (outstandingField) {
          condition = condition.where(outstandingField, "==", true)
        }
        return condition.orderBy(fieldOrder)
          .orderBy('page_key', 'desc')
          .limit(APPS.smallsize)
      })

    }
    return this.db.collection('company').doc(companyKey).collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        // .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .orderBy('page_key', 'desc')
        .limit(APPS.smallsize)
    })
  }

  registerSubmitAutoCompleteRef(collectionName, companyKey, fieldOrder, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
      // .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  registerSubmitAutoCompleteSearchRef(collectionName, companyKey, fieldOrder: string, text: any, itemStatusField, itemStatusFieldOther, outstandingField?: string) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref => {
        let condition = ref
          .where('status.key', '==', 1)
          .where("isDelete", "==", false)
          .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
          .where("companyKey", "==", companyKey)
          .where("tag", "array-contains", '~N/A~')

          .where(itemStatusField, '==', false)
          .where(itemStatusFieldOther, '==', null)
          .where(fieldOrder, ">=", search)
          .where(fieldOrder, '<', end)

        if (outstandingField) {
          condition = condition.where(outstandingField, "==", true)
        }
        return condition.orderBy(fieldOrder)
          .limit(APPS.smallsize)
      })

    }
    return this.db.collection(collectionName, ref => {
      let condition = ref
        .where('status.key', '==', 1)
        .where("isDelete", "==", false)
        .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
        .where("companyKey", "==", companyKey)
        .where("tag", "array-contains", '~N/A~')
        .where(itemStatusField, '==', false)
        .where(itemStatusFieldOther, '==', null)

      if (outstandingField) {
        condition = condition.where(outstandingField, "==", true)
      }
      return condition.orderBy(fieldOrder)
        .limit(APPS.smallsize)
    })
  }

  dbRef() {
    return this.db;
  }

  firestore() {
    return this.db.firestore;
  }

  batch() {
    return this.db.firestore.batch()
  }

  createId() {
    return this.db.createId();
  }

  listOfItemsRef(typekey, companyKey) {
    return this.db.collection("items", ref => ref
      .where('type.key', '==', typekey)
      .where('company.key', '==', companyKey)
      .where('isDelete', '==', false)
      .orderBy("node"));
  }

  userRef() {
    return this.db.collection("users");
  }

  routerRef() {
    return this.db.collection("settings_route");
  }

  productTypeRef() {
    return this.db.collection("settings_product_type");
  }

  userFireRef() {
    return this.firestore().collection("users");
  }

  itemRef() {
    return this.firestore().collection("items");
  }
  itemIndexRef() {
    return this.db.collection("items", ref => ref.orderBy("index", "desc").limit(1));
  }

  hscodeIndexRef() {
    return this.db.collection("hs_codes", ref => ref.orderBy("index", "desc").limit(1));
  }
  itemHsCodeRef() {
    return this.firestore().collection("hs_codes");
  }


  sysConfigSettingRef() {
    return this.firestore().collection("sys_configs").doc("settings");
  }

  sysConfigSettingDBRef() {
    return this.firestore().collection("sys_configs").doc("settings");
  }

  sysConfigRef() {
    return this.db.collection("sys_configs").doc("accounts");
  }

  sysConfigCompanyRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("sys_configs").doc("accounts");
  }

  sysPrintConfigCompanyRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("sys_configs").doc("print_header_footer");
  }

  itemsRef(collectionName: string, companyKey: string, itemTypeKey) {
    return this.db.collection("items", ref => ref
      .where('isDelete', '==', false)
      .where('type.key', '==', itemTypeKey)
      .where("company.key", "==", companyKey)
      .orderBy("code")
    )
  }

  settingRef(collectionName: string) {
    return this.db.collection(collectionName, ref => ref.where("status.key", "==", statusObj.active.key));
  }

  commentRef(collectionName: string) {
    return this.db.collection(collectionName);
  }
  collectionRef(collectionName: string) {
    return this.db.collection(collectionName);
  }

  collectionFireRef(collectionName: string) {
    return this.db.firestore.collection(collectionName);
  }

  print_templatesRef(companyKey, appKey) {
    return this.db.collection('company').doc(companyKey).collection('print_templates', ref => ref.where('application_type.key', '==', appKey).where('status.key', '==', 1))
  }

  commentByCompanyRef(collectionName: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collectionName);
  }

  registerCheckExistRef(collectionName: string, field: string, oldValue: string, companyKey: string) {
    return this.db.collection(collectionName, ref => ref
      .where(field, "==", oldValue)
      .where("register_register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("company.key", "==", companyKey)
      .where("isDelete", "==", false)
      .where("status.key", "==", statusObj.active.key)
    );
  }

  settingFireRef(collectionName: string) {
    return this.firestore().collection(collectionName)
  }
  settingFireByCompanyRef(collectionName: string, companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection(collectionName)
  }

  settingDBRef(collectionName: string) {
    return this.db.collection(collectionName)
  }

  settingDBTagRef(collectionName: string, node: string) {
    return this.db.collection(collectionName, ref => ref.where("tag", "array-contains", node))
  }

  settingByCompanyRef(collectionName: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collectionName)
  }

  settingDBByCompanyRef(collectionName: string, id, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collectionName).doc(id)
  }

  registerItemRef(companyKey: string) {
    return this.db.collection('register_item', ref => ref.where("company.key", "==", companyKey));
  }

  registerCORef(companyKey: string) {
    return this.db.collection('register_co', ref => ref.where("company.key", "==", companyKey));
  }

  registerOpeningBalanceRef(key: string) {
    return this.db.collection('register_ob_stock').doc(key);
  }

  registerOpeningBalanceByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_ob_stock').doc(key);
  }

  registerImportStockRef(key: string) {
    return this.db.collection('register_import_stock').doc(key);
  }

  registerImportStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_import_stock').doc(key);
  }

  registerImportStockLocalByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_local_import_stock').doc(key);
  }

  SHCodekRef(key: string) {
    return this.db.collection('hs_codes').doc(key);
  }
  registerMaterialDoc(key: string) {
    return this.db.collection('register_material').doc(key);
  }
  UOMRef(key: string) {
    return this.db.collection('settings_uom').doc(key);
  }

  registerCutStockRef(key: string) {
    return this.db.collection('register_cut_stock').doc(key);
  }

  registerCutStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_cut_stock').doc(key);
  }

  registerCutStockByCompanyFireRef(key: string, companyKey: string) {
    return this.db.firestore.collection("company").doc(companyKey).collection('register_cut_stock').doc(key)
  }
  registerLocalCutStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('local_cut_stock').doc(key)
  }

  registerVoidStockRef(key: string) {
    return this.db.collection('register_void_stock').doc(key);
  }

  registerAdjustmentStockRef(key: string) {
    return this.db.collection('register_adjustment_stock').doc(key);
  }

  registerAdjustmentStockByCompanyRef(key: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection('register_adjustment_stock').doc(key);
  }

  registerItemDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_item').doc(key);
  }
  registerLocalItemDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('local_sale_inv').doc(key);
  }
  outstandingDocRef(collection, key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection).doc(key);
  }

  registerItemUsageDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_item_usage').doc(key);
  }

  registerLocalSaleInvUsageDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('local_sale_inv_usage').doc(key);
  }

  registerItemByCompanyDocRef(collection, key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection).doc(key);
  }
  registerISooRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_soo').doc(key);
  }

  registerInvoiceDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_invoice').doc(key);
  }

  registerImportInvoiceDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_import_invoice').doc(key);
  }

  registerMasterListDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_master_list').doc(key);
  }

  registerLocalInvoiceDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_local_invoice').doc(key);
  }

  registerReapplyItemDocRef(key: string) {
    return this.db.collection('register_reapply_item').doc(key);
  }

  registerReapplyItemDocByCompanyRef(key: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection('register_reapply_item').doc(key);
  }

  registerDuplicateItemDocRef(key: string) {
    return this.db.collection('register_duplicate_item').doc(key);
  }

  registerDuplicateItemDocByCompanyRef(key: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection('register_duplicate_item').doc(key);
  }

  registerReapplyDocRef(key: string) {
    return this.db.collection('register_re_apply').doc(key);
  }

  registerReapplyDocByCompanyRef(key: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection('register_re_apply').doc(key);
  }

  registerDuplicateDocRef(key: string) {
    return this.db.collection('register_duplicate').doc(key);
  }

  registerDuplicateByCompanyRef(key: string, companyKey: string) {
    return this.db.collection("company").doc(companyKey).collection('register_duplicate').doc(key);
  }

  registerSubmitDocRef(key: string) {
    return this.db.collection('register_submit_form').doc(key);
  }

  registerCoDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_co').doc(key);
  }
  registerSooDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_soo').doc(key);
  }


  registerCODocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_co').doc(key);
  }

  pickupInspectDocRef(key: string, companyKey) {
    return this.db.collection('company').doc(companyKey).collection('pickup_inspect').doc(key);
  }

  registerSubmitFormDocRef(key: string) {
    return this.db.collection('register_submit_form').doc(key);
  }

  registerItemDBRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_item');
  }

  settingsCountryDBRef() {
    return this.db.collection('settings_country');
  }

  settingsCountryFireRef() {
    return this.firestore().collection('settings_country');
  }

  registerMaterialDocRef(key: string) {
    return this.db.collection('register_material').doc(key);
  }

  registerItemFireRef(collection, companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection(collection);
  }
  registerCollectionItemRef(collection, companyKey) {
    return this.db.collection('company').doc(companyKey).collection(collection);
  }
  registerSOOFireRef() {
    return this.firestore().collection('register_soo');
  }

  registerInvoiceFireRef(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection('register_invoice');
  }
  registerImportInvoiceFireRef(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection('register_import_invoice');
  }


  registerMasterListFireRef(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection('register_master_list');
  }


  registerLocalInvoiceFireRef(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection('register_local_invoice');
  }

  registerReapplyItemFireRef() {
    return this.firestore().collection('register_reapply_item');
  }

  registerDuplicateItemFireRef() {
    return this.firestore().collection('register_duplicate_item');
  }

  registerCOFireRef(companyKey) {
    return this.firestore().collection('company').doc(companyKey).collection('register_co');
  }

  registerMaterialRef() {
    return this.db.collection('register_material');
  }

  registerMaterialByCompanyRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_material');
  }



  currencyRef() {
    return this.db.collection('settings_currency', ref => ref.where('status.key', "==", 1).where('isDelete', '==', false));
  }

  registerImportMaterialRef() {
    return this.db.collection('register_import_material');
  }

  registerImportMaterialByCompanyRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection('register_import_material');
  }

  statisticRef() {
    return this.db.collection('sys_environment').doc('general');
  }

  stockMovementDBRef(key: string) {
    return this.db.firestore.collectionGroup("stock_movement").where("transactionKey", "==", key).where("isAllocated", "==", true).limit(1);
  }

  stockMovementByINVRef(key: string) {
    return this.db.collectionGroup("stock_movement", ref => ref.where("allocated_itemKey", "==", key).where("isAllocated", "==", true));
  }

  cutStockMovementDBRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_cut_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("submitted_date", "desc").limit(1);
  }

  adjustStockMovementDBRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_adjustment_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("submitted_date", "desc").limit(1);
  }


  checkReleaseImportStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_import_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("release_date", "desc").limit(1);
  }

  checkReleaseLocalImportStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_local_import_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("release_date", "desc").limit(1);
  }


  checkReleaseAdjustStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_adjustment_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("release_date", "desc").limit(1);
  }


  checkReleaseCutStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_cut_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("release_date", "desc").limit(1);
  }

  checkReleaseLocalCutStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("local_cut_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("release_date", "desc").limit(1);
  }

  localCutStockMovementDBRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("local_cut_stock").where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.released.key)
      .orderBy("submitted_date", "desc").limit(1);
  }



  checkReleaseStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_stock_material_movement").orderBy("release_date", "desc").limit(1);
  }


  checkRollBackStockRef(companyKey: string) {
    return this.db.firestore.collection('company').doc(companyKey).collection("register_stock_material_movement").orderBy("release_date", "desc").orderBy('updated_at', 'desc').limit(1);
  }




  settingBatchRef(collectionName: string) {
    return this.firestore().collection(collectionName);
  }
  settingBatchRefByCompany(collectionName: string, companyKey: string) {
    return this.firestore().collection('company').doc(companyKey).collection(collectionName);
  }
  settingRefByCompany(collectionName: string, companyKey: string) {
    return this.db.collection('company').doc(companyKey).collection(collectionName);
  }

  statisticBatchRef() {
    return this.firestore().collection('sys_environment').doc('general');
  }


  ListingByCompanyRef(collectionName: string, companyKey: string, lastVisible: any, orderBy: string, statusKey) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition
          .where('company.key', '==', companyKey)
          .orderBy(orderBy)
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition
          .where('company.key', '==', companyKey)
          .orderBy(orderBy).limit(APPS.size);
      })
    }

  }
  resisterMaterialRef(collectionName: string, lastVisible: any, orderBy: string, statusKey: any, registerStatusKey: string, companyKey: string) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_material_status.key", "==", registerStatusKey)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_material_status.key", "==", registerStatusKey)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy(orderBy, "desc").limit(APPS.size);
      })
    }
  }

  submitOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, outstandingOtherField: string, search?: string) {
    return this.db.collection("register_co", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", true)
        .where(outstandingOtherField, "==", false)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("co_number", ">=", search)
          .where("co_number", '<', end)
          .orderBy("co_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  returnNDeliveryCoOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_return_application", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("refNo", ">=", search)
          .where("refNo", '<', end)
          .orderBy("refNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  reApplyItemOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_reapply_item", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("co_number", ">=", search)
          .where("co_number", '<', end)
          .orderBy("co_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  duplicateItemOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_duplicate_item", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("co_number", ">=", search)
          .where("co_number", '<', end)
          .orderBy("co_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  deliveryImportStockCoOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_import_stock", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where("deliveryImportStock", "==", false)
        .where("outstandingImportStock", "==", true)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("transactionNo", ">=", search)
          .where("transactionNo", '<', end)
          .orderBy("transactionNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  deliveryImportStockOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_import_stock", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where("deliveryImportStock", "==", false)
        .where("outstandingImportStock", "==", true)
        .orderBy(orderBy, "desc")
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("transactionNo", ">=", search)
          .where("transactionNo", '<', end)
          .orderBy("transactionNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .limit(APPS.size)
      }
      return condition
    })
  }

  deliveryImportStocksOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, outstandingField: string, companyKey: string, search?: string) {

    return this.db.collection("register_invoice", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where("registerImportStock", "==", false)
        .where("registerImportStockOutstanding", "==", true)
        .orderBy(orderBy, "desc")
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("invoice_number", ">=", search)
          .where("invoice_number", '<', end)
          .orderBy("invoice_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .limit(APPS.size)
      }

      return condition
    })
  }

  deliveryCutStockOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_cut_stock", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where("deliveryCutStock", "==", false)
        .where("outstandingCutStock", "==", true)
        .orderBy(orderBy, "desc")
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("transactionNo", ">=", search)
          .where("transactionNo", '<', end)
          .orderBy("transactionNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .limit(APPS.size)
      }
      return condition
    })
  }

  registerInvoiceOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_invoice", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where("registerImportStock", "==", false)
        .where("registerImportStockOutstanding", "==", true)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("invoice_number", ">=", search)
          .where("invoice_number", '<', end)
          .orderBy("invoice_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  returnDeliveryCoOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_co", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
        .orderBy(orderBy, "desc")
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("refNo", ">=", search)
          .where("refNo", '<', end)
          .orderBy("refNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition
          .startAfter(startAfter)
          .limit(APPS.size)
      } else {
        condition = condition
          .limit(APPS.size)
      }

      return condition
    })
  }

  deliveryExportOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("pickup_inspect", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
        .orderBy(orderBy, "desc")
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("bill_number", ">=", search)
          .where("bill_number", '<', end)
          .orderBy("bill_number")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          // .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  afterSubmitOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, companyKey: string, outstandingField: string, search?: string) {
    return this.db.collection("register_submit_form", ref => {
      let condition = ref
        .where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("register_status.order", "==", 2)
        .where(outstandingField, "==", false)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const end = search.toUpperCase().replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        condition = condition.where("refNo", ">=", search)
          .where("refNo", '<', end)
          .orderBy("refNo")
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition
          .orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  outstandingRef(lastVisible: any, orderBy: string, statusKey: any, itemStatusField: string, companyKey: string,) {
    return this.db.collection("register_item", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition.orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  outstandingSooRef(lastVisible: any, orderBy: string, statusKey: any, itemStatusField: string, companyKey: string,) {
    return this.db.collection("register_soo", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition.orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }



  searchOutstandingRef(text: any, itemStatusField: string, companyKey: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection("register_item", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      if (text) {
        condition = condition.where("keyword", ">=", search)
          .where("keyword", '<', end)
          .orderBy("keyword")
          .orderBy("page_key", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("page_key", "desc")
          .limit(APPS.size)
      }
      return condition;
    })
  }

  outstandingListRef(itemStatusField: string, itemStatusFieldKey: string, companyKey: string) {
    return this.db.collection("register_item", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusFieldKey, "==", null)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingSooListRef(itemStatusField: string, companyKey: string) {
    return this.db.collection("register_soo", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingSubmitListRef(itemStatusField: string, outstandingOtherField: string, outstandingOtherKey: string, companyKey: string) {
    return this.db.collection("register_co", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", true)
        .where(outstandingOtherField, "==", false)
        .where(outstandingOtherKey, "==", null)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingReturnNDeliveryCoListRef(itemStatusField: string, itemStatusFieldKey: string, companyKey: string) {
    return this.db.collection("register_co", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where(itemStatusFieldKey, "==", null)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingReApplyItemListRef(itemStatusField: string, itemStatusFieldKey: string, companyKey: string) {
    return this.db.collection("register_reapply_item", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where(itemStatusFieldKey, "==", null)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingRegisterInvoiceRef(itemStatusField: string, itemStatusFieldKey: string, companyKey: string) {
    return this.db.collection("register_invoice", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("deliveryImportStockKey", "==", null)
        .where("deliveryImportStock", "==", false)
        .where("outstandingImportStock", "==", true)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingDeliveryImportStockListRef(itemStatusField: string, itemStatusFieldKey: string, companyKey: string) {
    return this.db.collection("register_import_stock", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("outstandingImportStock", "==", true)
        .where(itemStatusField, "==", false)
        .where(itemStatusFieldKey, "==", null)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingDeliveryExportListRef(itemStatusField: string, companyKey: string) {
    return this.db.collection("register_co", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", false)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  outstandingAfterSubmitListRef(itemStatusField: string, companyKey: string) {
    return this.db.collection("register_submit_form", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where(itemStatusField, "==", true)
        .where("register_status.order", "==", 2)
      return condition
    })
  }

  registerCoRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_co")
  }
  registerItemUsageRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_item_usage")
  }

  registerSooStatmentRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_soo_statement")
  }
  registerSooRef(companyKey) {
    return this.db.collection('company').doc(companyKey).collection("register_soo")
  }

  registerCollectionRef(companyKey, collection) {
    return this.db.collection('company').doc(companyKey).collection(collection)
  }
  settingCollectionRef(collection) {
    return this.db.collection(collection)
  }
  registerCoFireRef() {
    return this.firestore().collection("register_co")
  }

  resisterItemListingRef(collectionName: string, lastVisible: any, orderBy: string, statusKey: any, registerStatusKey: string, companyKey: string, typeKey?: any) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_status.key", "==", registerStatusKey)

        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }

        if (typeKey) {
          condition = condition.where("submitType.key", "==", typeKey)
        }

        return condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_status.key", "==", registerStatusKey)

        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }

        if (typeKey) {
          condition = condition.where("submitType.key", "==", typeKey)
        }

        return condition.orderBy(orderBy, "desc").limit(APPS.size);
      })
    }
  }

  resisterSubmitListingRef(collectionName: string, lastVisible: any, orderBy: string, statusKey: any, registerStatusKey: string, companyKey: string, typeKey?: any) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_status.key", "==", registerStatusKey)

        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }

        if (typeKey) {
          condition = condition.where("submitType.key", "==", typeKey)
        }

        return condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("companyKey", "==", companyKey)
          .where("register_status.key", "==", registerStatusKey)

        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }

        if (typeKey) {
          condition = condition.where("submitType.key", "==", typeKey)
        }

        return condition.orderBy(orderBy, "desc").limit(APPS.size);
      })
    }
  }

  submitFormCODraft(companyKey: string) {
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("submit_from", "==", false)
      .where("outstandingForm", "==", true)
      .where("submit_fromKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  submitFormCO(companyKey: string) {
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("submit_crf", "==", false)
      .where("outstandingCRF", "==", true)
      .where("submit_crfKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  returnAppCO(companyKey: string) {
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("returnApp", "==", false)
      .where("returnAppKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  cutStockRegisterCO(companyKey: string) {
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("registerCutStock", "==", false)
      .where("registerCutStockKey", "==", null)
      .where("outstandingStock", "==", true)
      .orderBy("updated_at", "desc")
    );
  }

  cutStockRef(companyKey: string) {
    return this.db.collection("register_cut_stock", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .orderBy("updated_at", "desc")
      .limit(APPS.size)
    );
  }



  returnApplicationADD(companyKey: string) {
    return this.db.collection("register_return_application", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("deliveryReturnDoc", "==", false)
      .where("deliveryReturnDocKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  deliveryAppCO(companyKey: string) {
    return this.db.collection("register_co", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("deliveryCo", "==", false)
      .where("deliveryCoKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  deliveryExport(companyKey: string) {
    return this.db.collection("pickup_inspect", ref => ref
      .where("isDelete", "==", false)
      .where("companyKey", "==", companyKey)
      .where("register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
      .where("delivery_export", "==", false)
      .where("delivery_exportKey", "==", null)
      .orderBy("updated_at", "desc")
    );
  }

  lazyFileManagerRef(folder: string, lastVisible: any, formFilter: any, companyKey: any) {
    if (companyKey) {
      return this.db.collection('company').doc(companyKey).collection('storage', ref => {
        let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
        let condition = ref
          .where("folder", "==", folder)
          .where("keyword", "array-contains", keyword)
          .orderBy('created_at', "desc")
        if (lastVisible) {
          const startAfter = lastVisible['created_at'];
          condition = condition.startAfter(startAfter)
        }
        return condition.limit(APPS.settingSize)
      })
    } else {
      return this.db.collection('storage', ref => {
        let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
        let condition = ref
          .where("folder", "==", folder)
          .where("keyword", "array-contains", keyword)
          .orderBy('created_at', "desc")
        if (lastVisible) {
          const startAfter = lastVisible['created_at'];
          condition = condition.startAfter(startAfter)
        }
        return condition.limit(APPS.settingSize)
      })
    }
  }


  resisterSubmitRef(collectionName: string, lastVisible: any, orderBy: string, statusKey: any, registerStatusKey: string, companyKey: string) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
          .where("company.key", "==", companyKey)
          .where("register_register_status.key", "==", registerStatusKey)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      if (registerStatusKey === "pending") {
        switch (collectionName) {
          case "register_submit_form":
            return this.db.collection("register_co", ref => {
              let condition = ref.where("isDelete", "==", false)
                .where("company.key", "==", companyKey)
                .where("register_register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
                .where("pending_submit_from", "==", false)
              if (statusKey !== 0) {
                condition = condition.where("status.key", "==", statusKey)
              }
              return condition.orderBy(orderBy, "desc").limit(APPS.size);
            });

          case "register_submit_crf":

            return this.db.collection("register_co", ref => {
              let condition = ref.where("isDelete", "==", false)
                .where("company.key", "==", companyKey)
                .where("register_register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
                .where("pending_submit_crf", "==", false)
              if (statusKey !== 0) {
                condition = condition.where("status.key", "==", statusKey)
              }
              return condition.orderBy(orderBy, "desc").limit(APPS.size);
            });

          case "register_soo":
            return this.db.collection("register_co", ref => {
              let condition = ref.where("isDelete", "==", false)
                .where("company.key", "==", companyKey)
                .where("register_register_status.key", "==", REGISTER_ITEM_STATUS_OBJ.submitted.key)
                .where("pending_submit_soo", "==", false)
              if (statusKey !== 0) {
                condition = condition.where("status.key", "==", statusKey)
              }
              return condition.orderBy(orderBy, "desc").limit(APPS.size);
            });

          default:
            break;
        }
      } else {
        return this.db.collection(collectionName, ref => {
          let condition = ref.where("isDelete", "==", false)
            .where("company.key", "==", companyKey)
            .where("register_register_status.key", "==", registerStatusKey)
          if (statusKey !== 0) {
            condition = condition.where("status.key", "==", statusKey)
          }
          return condition.orderBy(orderBy, "desc").limit(APPS.size);
        })
      }

    }
  }

  lazyLoadSettingAllRef(collectionName: string, statusKey) {
    return this.db.collection(collectionName, ref => ref.where("status.key", "==", statusKey))
  }

  lazyLoadSettingRef(collectionName: string, lastVisible: any, statusKey, formFilter: any, orderBy) {
    return this.db.collection(collectionName, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("status.key", "==", statusKey)
        .where("keyword", "array-contains", keyword)
        .orderBy(orderBy, (collectionName === 'hs_codes' ? "asc" : "desc"))
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })
  }


  lazyLoadReportHistoryRef(lastVisible: any, formFilter: any, typeKey: any, companyKey, orderBy) {
    return this.db.collection('company').doc(companyKey).collection('report_history', ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("keyword", "array-contains", keyword)
        .where('type.key', '==', typeKey)
        .orderBy(orderBy, 'desc')
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })
  }


  lazySettingsRef(collectionName: string, lastVisible: any, formFilter: any) {
    return this.db.collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = 0;
      let toDateKey = 1000000000;
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = ConvertService.toDateKey(fromDate)
          toDateKey = ConvertService.toDateKey(toDate)
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("tag", "array-contains", keyword)
        // .where("status.key", "==", statusObj.active.key)
        .orderBy('page_key', "desc")
      if (lastVisible) {
        const startAfter = lastVisible['page_key'];
        condition = condition.startAfter(startAfter)
          .where('page_key', ">=", ConvertService.toNumber(`${fromDateKey}000000`))
          .where('page_key', "<=", ConvertService.toNumber(`${toDateKey}235959`))
      }
      else {
        condition = condition
          .where('page_key', ">=", ConvertService.toNumber(`${fromDateKey}000000`))
          .where('page_key', "<=", ConvertService.toNumber(`${toDateKey}235959`))
      }

      return condition.limit(APPS.settingSize)
    })
  }


  lazySHSettingsRef(collectionName: string, lastVisible: any, formFilter: any) {
    return this.db.collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = 0;
      let toDateKey = 1000000000;
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = ConvertService.toDateKey(fromDate)
          toDateKey = ConvertService.toDateKey(toDate)
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.node : keyword;
            break;
        }
      }
      let condition = ref
        .where("tag", "array-contains", keyword)
        .where("status.key", "==", statusObj.active.key)
        .orderBy('node')
      if (lastVisible) {
        const startAfter = lastVisible['node'];
        condition = condition.startAfter(startAfter)
      }
      else {
        condition = condition
      }

      return condition.limit(APPS.settingSize)
    })
  }

  lazyRegisterRefByType(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, typeKey: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("submitType.key", "==", typeKey)
        .where("keyword", "array-contains", keyword)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  fetchReportDataRef(collectionName: string, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      return condition
    })

  }


  fetchReportCutStockDataRef(collectionName: string, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    console.log('orderBy', orderBy);
    
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        // .where("register_status.order", "<", 3)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        // .orderBy('register_status.order', "desc")
      return condition
    })

  }


  fetchReportInvDataRef(collectionName: string, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();
      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("registerCo", "==", false)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      return condition
    })

  }


  fetchReportInventoryDataRef(formFilter: any, materialKey: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection('register_stock_material_movement', ref => {
      let fromDateKey = moment(new Date()).startOf('day').toDate();
      let toDateKey = moment(new Date()).endOf('day').toDate();
      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }
      let condition = ref
        // .where("companyKey", "==", companyKey)
        // .where("status.key", "==", 1)
        // .where("register_status.key", "==", registerStatus)
        // .where("movementType.factor", "==", 1)
        // .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      if (materialKey !== 'all') {
        condition = condition.where("materialKey", "==", materialKey)
      }
      return condition
    })

  }



  fetchReportOpenInventoryDataFirstRef(fromDateKey: any, materialKey: any, orderBy, companyKey: any) {
    return this.db.firestore.collection('company').doc(companyKey).collection('register_stock_material_movement').where("materialKey", "==", materialKey).where(orderBy, "<", fromDateKey).orderBy(orderBy, "desc").limit(1)
  }

  fetchOpenInventoryDataFirstRef(fromDateKey: any, materialKey: any, orderBy, companyKey: any) {
    return this.db.firestore.collection('company').doc(companyKey).collection('register_stock_material_movement').where("materialKey", "==", materialKey).where(orderBy, "<=", fromDateKey).orderBy(orderBy, "desc").limit(1)
  }


  fetchReportOpenInventoryDataRef(fromDateKey: any, toDateKey: any, materialKey: any, orderBy, companyKey: any) {
    const ref = this.db.firestore.collection('company').doc(companyKey).collection('register_stock_material_movement');
    let condition = ref.where(orderBy, ">=", fromDateKey).where(orderBy, "<=", toDateKey).orderBy(orderBy, "desc")
    if (materialKey !== 'all') {
      condition = condition.where("materialKey", "==", materialKey)
    }
    return condition
  }


  fetchOpenInventoryDataRef(fromDateKey: any, toDateKey: any, materialKey: any, orderBy, companyKey: any) {
    const ref = this.db.firestore.collection('company').doc(companyKey).collection('register_stock_material_movement');
    let condition = ref.where(orderBy, ">=", fromDateKey).where(orderBy, "<=", toDateKey).orderBy(orderBy, "desc").orderBy('updated_at', 'desc').limit(1)
    if (materialKey !== 'all') {
      condition = condition.where("materialKey", "==", materialKey)
    }
    return condition
  }



  fetchReportInventoryDataBRef(collectionName: string, formFilter: any, materialKey: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection('register_material').doc(materialKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }
      let condition = ref
        // .where("companyKey", "==", companyKey)
        // .where("status.key", "==", 1)
        // .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      return condition
    })

  }



  fetchReportStockDataRef(collectionName: string, formFilter: any, registerStatus: any, orderBy, companyKey: any, outstandingFiled, outstandingFiledKey, outstandingOtherFiled) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where(outstandingFiledKey, "==", null)
        .where(outstandingOtherFiled, "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      return condition
    })

  }


  fetchReportCOStockDataRef(collectionName: string, formFilter: any, registerStatus: any, orderBy, companyKey: any, outstandingFiledKey, outstandingOtherFiled) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiledKey, "==", null)
        .where(outstandingOtherFiled, "==", false)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      return condition
    })

  }

  lazyRegisterNewRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";

      let condition = ref
        .where("register_status.key", "==", registerStatus)
        .where("keyword", "array-contains", keyword)
        .where("status.key", "==", 1)
        .where("companyKey", "==", companyKey)
        .orderBy(orderBy, "desc")
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition.limit(APPS.settingSize)
    })

  }


  lazyRegisterDataRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }


  lazyRegisterDataUsageRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("keyword", "array-contains", keyword)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }


  lazyRegisterNewDataRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("keyword", "array-contains", keyword)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyRegisterPkRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {

    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      // let fromDateKey = new Date('100-01-01');
      // let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          // fromDateKey = ConvertService.toDateKey(fromDate)
          // toDateKey = ConvertService.toDateKey(toDate)
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .orderBy(orderBy, "desc")
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
          .where(orderBy, ">=", fromDateKey)
          .where(orderBy, "<=", toDateKey)
      }
      else {
        condition = condition
          .where(orderBy, ">=", fromDateKey)
          .where(orderBy, "<=", toDateKey)
      }

      return condition.limit(APPS.settingSize)
    })

  }

  lazyRegisterRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, orderBy, companyKey: any) {
    let keyword: any = "~N/A~"
    let fromDateKey = new Date('100-01-01');
    let toDateKey = moment(new Date).add('years', 5).toDate()
    if (formFilter) {
      const { search, filterOption, fromDate, toDate } = formFilter;
      const { type } = filterOption
      if (fromDate && toDate) {
        fromDateKey = moment(fromDate).startOf('day').toDate();
        toDateKey = moment(toDate).endOf('day').toDate();
      }
      switch (type) {
        case DATA_TYPE.date:
          keyword = ConvertService.toDateKey(search);
          break;
        case DATA_TYPE.number:
          keyword = ConvertService.toNumber(search);
          break;
        default:
          keyword = search ? search.key : keyword;
          break;
      }
    }
    let ref = this.db.firestore.collection('company').doc(companyKey).collection(`${collectionName}`).where("companyKey", "==", companyKey)
      .where("status.key", "==", 1)
      .where("register_status.key", "==", registerStatus)
      .where("tag", "array-contains", keyword)
      .orderBy(orderBy, "desc")
      .where(orderBy, ">=", fromDateKey)
      .where(orderBy, "<=", toDateKey)
    let condition = ref
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      condition = condition.startAfter(startAfter)
    }
    return condition.limit(APPS.settingSize)

  }

  lazyMaterialDetailRef(materialKey: string, collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection('register_material').doc(materialKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      // let fromDateKey = 0;
      // let toDateKey = 10000000000000000000;
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          // fromDateKey = ConvertService.toPageKey(fromDate)
          // toDateKey = ConvertService.toPageKey(toDate)
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toPageKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }

      let condition = ref
        // .where("companyKey", "==", companyKey)
        // .where("status.key", "==", 1)
        // .where("isHeader", "==", true)
        // .where("register_status.key", "==", registerStatus)
        .where("isAllocated", "==", false)
        .where("movementType.factor", "==", 1)
        .where("tag", "array-contains", keyword)
        .orderBy('application_date', "desc")
      if (lastVisible) {
        const startAfter = lastVisible['application_date'];
        condition = condition.startAfter(startAfter)
          .where('application_date', ">=", fromDateKey)
          .where('application_date', "<=", toDateKey)
      }
      else {
        condition = condition
          .where('application_date', ">=", fromDateKey)
          .where('application_date', "<=", toDateKey)
      }

      return condition.limit(APPS.settingSize)
    })

  }

  lazyMaterialDetailCutStockRef(materialKey: string, collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection('register_material').doc(materialKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      // let fromDateKey = 0;
      // let toDateKey = 10000000000000000000;
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          // fromDateKey = ConvertService.toDateKey(fromDate)
          // toDateKey = ConvertService.toDateKey(toDate)
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("isAllocated", "==", true)
        .where("movementType.factor", "==", 1)
        .where("tag", "array-contains", keyword)
        .orderBy('updated_at', "desc")
      if (lastVisible) {
        const startAfter = lastVisible['updated_at'];
        condition = condition.startAfter(startAfter)
          // .where('release_date', ">=", ConvertService.toNumber(`${fromDateKey}`))
          // .where('created_at', "<=", ConvertService.toNumber(`${toDateKey}`))
          .where('updated_at', ">=", fromDateKey)
          .where('updated_at', "<=", toDateKey)
      }
      else {
        condition = condition
          .where('updated_at', ">=", fromDateKey)
          .where('updated_at', "<=", toDateKey)
      }

      return condition
    })

  }

  lazyRegisterSubmitRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)

        // .where('updated_at', ">=", fromDateKey)
        // .where('updated_at', "<=", toDateKey)
        // .orderBy('updated_at', "desc")
        .where('application_date', ">=", fromDateKey)
        .where('application_date', "<=", toDateKey)
        .orderBy('application_date', "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }


      return condition
    })

  }



  lazyRegisterConverterRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {

    let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";

    // let fromDateKey = new Date('100-01-01');
    // let toDateKey = moment(new Date).add('years', 5).toDate()
    // if (formFilter) {
    //   const { search, filterOption, fromDate, toDate } = formFilter;
    //   const { type } = filterOption
    //   if (fromDate && toDate) {
    //     fromDateKey = moment(fromDate).startOf('day').toDate();
    //     toDateKey = moment(toDate).endOf('day').toDate();
    //   }
    //   switch (type) {
    //     case DATA_TYPE.date:
    //       keyword = ConvertService.toDateKey(search);
    //       break;
    //     case DATA_TYPE.number:
    //       keyword = ConvertService.toNumber(search);
    //       break;
    //     default:
    //       keyword = search ? search.key : keyword;
    //       break;
    //   }
    // }
    let query = this.db.firestore.collection('company').doc(companyKey).collection(`${collectionName}`)
      .where("companyKey", "==", companyKey)
      .where("status.key", "==", 1)
      .where("register_status.key", "==", registerStatus)
      .where("keyword", "array-contains", keyword)
      // .where('application_date', ">=", fromDateKey)
      // .where('application_date', "<=", toDateKey)
      .orderBy('application_date', "desc")
      .limit(APPS.settingSize)

    if (lastVisible) {
      query = query.startAfter(lastVisible)
    }
    return query

  }


  lazyConvertRegisterStockRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    let keyword: any = "~N/A~"
    let fromDateKey = new Date('100-01-01');
    let toDateKey = moment(new Date).add('years', 5).toDate()
    if (formFilter) {
      const { search, filterOption, fromDate, toDate } = formFilter;
      const { type } = filterOption
      if (fromDate && toDate) {
        fromDateKey = moment(fromDate).startOf('day').toDate();
        toDateKey = moment(toDate).endOf('day').toDate();
      }
      switch (type) {
        case DATA_TYPE.date:
          keyword = ConvertService.toDateKey(search);
          break;
        case DATA_TYPE.number:
          keyword = ConvertService.toNumber(search);
          break;
        default:
          keyword = search ? search.key : keyword;
          break;
      }
    }
    let query = this.db.firestore.collection('company').doc(companyKey).collection(collectionName).where("companyKey", "==", companyKey)
      .where("status.key", "==", 1)
      .where("register_status.key", "==", registerStatus)
      .where("tag", "array-contains", keyword)
      .where('updated_at', ">=", fromDateKey)
      .where('updated_at', "<=", toDateKey)
      .orderBy('updated_at', "desc")
      .limit(APPS.settingSize)
    if (lastVisible) {
      query = query.startAfter(lastVisible)
    }

    return query

  }

  lazyRegisterStockRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)

        .where('updated_at', ">=", fromDateKey)
        .where('updated_at', "<=", toDateKey)
        .orderBy('updated_at', "desc")
        // .where('application_date', ">=", fromDateKey)
        // .where('application_date', "<=", toDateKey)
        // .orderBy('application_date', "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyRegisterOutstandingCOSOORef(collectionName: string, outstandingFiled, outstandingOtherFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = 0;
      let toDateKey = 1000000000;
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = ConvertService.toDateKey(fromDate)
          toDateKey = ConvertService.toDateKey(toDate)
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where('departmentKey', "==", outstandingOtherFiled.departmentKey)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .orderBy('page_key', "desc")
      if (lastVisible) {
        const startAfter = lastVisible['page_key'];
        condition = condition.startAfter(startAfter)
          .where('page_key', ">=", ConvertService.toNumber(`${fromDateKey}000000`))
          .where('page_key', "<=", ConvertService.toNumber(`${toDateKey}235959`))
      }
      else {
        condition = condition
          .where('page_key', ">=", ConvertService.toNumber(`${fromDateKey}000000`))
          .where('page_key', "<=", ConvertService.toNumber(`${toDateKey}235959`))
      }

      return condition.limit(APPS.settingSize)
    })

  }
  lazyRegisterOutstandingCORef(collectionName: string, outstandingFiled, outstandingOtherFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where(outstandingOtherFiled, "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition
    })

  }

  lazyRegisterOutstandingStockRef(collectionName: string, outstandingFiled, outstandingFiledKey, outstandingOtherFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where(outstandingFiledKey, "==", null)
        .where(outstandingOtherFiled, "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyRegisterOutstandingRef(collectionName: string, outstandingFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyRegisterOutstandingLocalStockRef(collectionName: string, outstandingFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = new Date('100-01-01');
      let toDateKey = moment(new Date).add('years', 5).toDate()
      if (formFilter) {
        const { search, filterOption, fromDate, toDate } = formFilter;
        const { type } = filterOption
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
        switch (type) {
          case DATA_TYPE.date:
            keyword = ConvertService.toDateKey(search);
            break;
          case DATA_TYPE.number:
            keyword = ConvertService.toNumber(search);
            break;
          default:
            keyword = search ? search.key : keyword;
            break;
        }
      }
      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where('registerLocalSaleInvUsage', "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
        .limit(APPS.settingSize)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyRegisterOutstandingLocalStockReportRef(collectionName: string, outstandingFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where('registerLocalSaleInvUsage', "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }


  lazyRegisterOutstandingLocalImportReportRef(collectionName: string, outstandingFiled, outstandingFiledKey, outstandingOtherFiled, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any, orderBy) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"
      let fromDateKey = moment(new Date()).startOf('day').toDate();;
      let toDateKey = moment(new Date()).endOf('day').toDate();

      if (formFilter) {
        const { fromDate, toDate } = formFilter;
        if (fromDate && toDate) {
          fromDateKey = moment(fromDate).startOf('day').toDate();
          toDateKey = moment(toDate).endOf('day').toDate();
        }
      }

      let condition = ref
        .where("companyKey", "==", companyKey)
        .where(outstandingFiled, "==", false)
        .where(outstandingFiledKey, "==", null)
        .where(outstandingOtherFiled, "==", true)
        .where("status.key", "==", 1)
        .where("register_status.key", "==", registerStatus)
        .where("tag", "array-contains", keyword)
        .where(orderBy, ">=", fromDateKey)
        .where(orderBy, "<=", toDateKey)
        .orderBy(orderBy, "desc")
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }





  lazyRegisterMaterialRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("register_status.key", "==", registerStatus)
        .where("keyword", "array-contains", keyword)
        // .where("status.key", "==", 1)
        .where("companyKey", "==", companyKey)
        .orderBy('node')

      if (lastVisible) {
        const startAfter = lastVisible['node'];
        condition = condition.startAfter(startAfter)
      }
      else {
        condition = condition
      }

      return condition.limit(APPS.settingSize)
    })

  }
  lazyRegisterNSRef(collectionName: string, lastVisible: any, formFilter: any, registerStatus: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword = formFilter ? ConvertService.toCapitalize(formFilter) : "~N/A~";
      let condition = ref
        .where("register_status.key", "==", registerStatus)
        .where("keyword", "array-contains", keyword)
        .where("status.key", "==", 1)
        .where("companyKey", "==", companyKey)
        .orderBy('node')

      if (lastVisible) {
        const startAfter = lastVisible['node'];
        condition = condition.startAfter(startAfter)
      }
      else {
        condition = condition
      }

      return condition.limit(APPS.settingSize)
    })

  }


  lazyRegisterDataPrintRef(collectionName: string, lastVisible: any, formFilter: any, application_type: any, companyKey: any) {
    return this.db.collection('company').doc(companyKey).collection(`${collectionName}`, ref => {
      let keyword: any = "~N/A~"

      let condition = ref
        .where("tag", "array-contains", keyword)
        .where("application_type.key", "==", application_type)

      if (lastVisible) {
        const startAfter = lastVisible['created_at'];
        condition = condition.startAfter(startAfter)
      }
      else {
        condition = condition
      }

      return condition.limit(APPS.settingSize)
    })

  }


  settingListingRef(collectionName: string, lastVisible: any, orderBy: string, statusKey) {
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("status.text", "==", "Active").limit(10)
      return condition
    })
  }

  searchListingRef(collectionName: string, field: string, text: any) {
    if (text) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("isDelete", "==", false)
          .where("status.key", "==", statusObj.active.key)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(5)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.size)
    );

  }



  searchListingByCompanyRef(collectionName: string, companyKey, field: string, text: any) {
    if (text) {
      const search = text.toUpperCase();
      return this.db.collection(collectionName, ref =>
        ref
          .where("isDelete", "==", false)
          .where('company.key', '==', companyKey)
          .where("status.key", "==", statusObj.active.key)
          .where(field, ">=", search)
          .orderBy(field)
          .limit(5)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.size)
    );

  }
  searchListingByCompanyTypeRef(collectionName: string, companyKey: string, typeKey: string, field: string, text: any) {
    if (text) {
      const search = text.toUpperCase();
      return this.db.collection(collectionName, ref =>
        ref
          .where("isDelete", "==", false)
          .where('company.key', '==', companyKey)
          .where('type.key', '==', typeKey)
          .where("status.key", "==", statusObj.active.key)
          .where(field, ">=", search)
          .orderBy(field)
          .limit(5)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("isDelete", "==", false)
        .where('company.key', '==', companyKey)
        .where('type.key', '==', typeKey)
        .where("status.key", "==", statusObj.active.key)
        .orderBy(field)
        .limit(APPS.size)
    );

  }

  itemProductCompanyRef(collectionName: any, typekey: any, companyKey: any, lastVisible: any, orderBy: string, statusKey) {
    if (lastVisible) {
      const startAfter = lastVisible['node'];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.where('company.key', '==', companyKey)
          .where('type.key', '==', typekey)
          .orderBy('node')
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false);

        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition
          .where('company.key', '==', companyKey)
          .where('type.key', '==', typekey)
          .orderBy('node').limit(APPS.size);
      })
    }
  }

  templateRef(collectionName: any, companyKey: any, templateKey: string) {
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("isDelete", "==", false)
      return condition.where('company.key', '==', companyKey)
        .where("type.key", "==", templateKey);
    })
  }

  templateCollectionRef() {
    return this.db.collection('report_templates')
  }

  reportTemplatesFireRef() {
    return this.db.firestore.collection('report_templates')
  }

  reportTemplatesActiveRef(typeKey: string, companyKey: string) {
    return this.db.collection('report_templates', ref => ref.where('default', '==', true)
      .where('isDelete', '==', false).where('company.key', '==', companyKey).where('type.key', '==', typeKey))
  }

  itemCompanyRef(collectionName: any, companyKey: any, lastVisible: any, orderBy: string, statusKey: any = 0) {
    if (lastVisible) {
      const startAfter = lastVisible['node'];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy('node')
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy('node').limit(APPS.size);
      })
    }
  }

  itemsettingListingRef(collectionName: string, company: any, type: any, itemType: any, lastVisible: any, orderBy: string, statusKey: any = 0) {
    if (lastVisible) {
      const startAfter = lastVisible[orderBy];
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false).where('company.key', '==', company.key).where('type.key', '==', itemType.key)
        if (type !== null) {
          condition = condition.where("itemType.key", "==", type.key)
        }
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy(orderBy)
          .startAfter(startAfter)
          .limit(APPS.size)
      })
    }
    else {
      return this.db.collection(collectionName, ref => {
        let condition = ref.where("isDelete", "==", false)
        if (statusKey !== 0) {
          condition = condition.where("status.key", "==", statusKey)
        }
        return condition.orderBy(orderBy).limit(APPS.size);
      })
    }

  }


  companyRef() {
    return this.db.collection("company");
  }

  titleDocRef() {
    return this.db.collection("settings_title");
  }

  coformRef() {
    return this.db.collection("settings_coform", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }
  titleRef() {
    return this.db.collection("settings_title", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }

  hscodeRef() {
    return this.db.collection("hs_codes", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }
  companyCollectRef() {
    return this.db.collection("company", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }
  departmentRef() {
    return this.db.collection("settings_department", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }
  countryRef() {
    return this.db.collection("settings_country", ref => ref.where('status.key', '==', 1).where('isDelete', '==', false));
  }

  hscodeAutoRef(lastVisible) {
    if (lastVisible) {
      return this.db.collection("hs_codes", ref =>
        ref
          .where('status.key', '==', 1).where('isDelete', '==', false)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
      );
    }
    return this.db.collection("hs_codes", ref =>
      ref
        .where('status.key', '==', 1).where('isDelete', '==', false)
        .orderBy("page_key", "desc")
    );
  }


  environmentRef() {
    return this.db.collection("sys_environment").doc('general')
  }

  uomRef() {
    return this.db.collection("settings_uom", ref => ref.where('status.key', "==", 1).where('isDelete', '==', false));
  }

  userprofileRef() {
    return this.db.collection("users");
  }

  accountRef() {
    return this.db.collection("users");
  }

  accountFireRef() {
    return this.db.firestore.collection("users");
  }

  private getOption(data: any = false, method: any = false): RequestOptions {
    let headers = new Headers();
    if (method == 'deleteWithBody') {
      return new RequestOptions({ headers: headers, body: data })
    }
    if (data)
      return new RequestOptions({ headers: headers, search: data })
    return new RequestOptions({ headers: headers });
  }

  private handleSuccess(res) {
    return res.json();
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  login(uri: string = this.baseUri, data: any = {}, options: RequestOptions = this.getOption()): Promise<any> {
    return this.http.post(uri, data, options).toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }


  async getAsync(uri: string = this.baseUri, data: any = false, options: RequestOptions = this.getOption()) {
    if (data)
      options = this.getOption(data, 'get');
    const doc = await this.http.get(uri, options).toPromise();
    return JSON.stringify(doc);
  }


  get(uri: string = this.baseUri, data: any = false, options: RequestOptions = this.getOption()): Promise<any> {
    if (data)
      options = this.getOption(data, 'get');
    return this.http.get(uri, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  post(uri: string = this.baseUri, data: any = {}, options: RequestOptions = this.getOption()): Promise<any> {
    return this.http.post(uri, data, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  put(uri: string = this.baseUri, data: any = {}, options: RequestOptions = this.getOption()): Promise<any> {
    return this.http.put(uri, data, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  delete(uri: string = this.baseUri, data: any = false, options: RequestOptions = this.getOption()): Promise<any> {
    if (data)
      options = this.getOption(data);
    return this.http.delete(uri, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  deleteWithBody(uri: string = this.baseUri, data: any = {}, options: RequestOptions = this.getOption()): Promise<any> {
    if (data)
      options = this.getOption(data, 'deleteWithBody');
    return this.http.delete(uri, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }
  companyInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        name_en: ConvertService.toNull(item.name_en),
        name_kh: ConvertService.toNull(item.name_kh),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }
  departmentInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        name_en: ConvertService.toNull(item.name_en),
        name_kh: ConvertService.toNull(item.name_kh),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  forwarderInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        name_en: ConvertService.toNull(item.name_en),
        name_kh: ConvertService.toNull(item.name_kh),
        email: ConvertService.toNull(item.email),
        telephone_number: ConvertService.toNull(item.telephone_number),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  uomInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        description_kh: ConvertService.toNull(item.description_kh),
        description_en: ConvertService.toNull(item.description_en),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  hscodeInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        description_kh: ConvertService.toNull(item.description_kh),
        description_en: ConvertService.toNull(item.description_en),
        index: ConvertService.toNull(item.index),
        node: ConvertService.toNull(item.node),
        level: ConvertService.toNull(item.level),
        parent: ConvertService.toNull(item.parent),
        total: ConvertService.toNull(item.total),
        st: ConvertService.toNull(item.st),
        et: ConvertService.toNull(item.et),
        vat: ConvertService.toNull(item.vat),
        cd: ConvertService.toNull(item.cd),
        key: ConvertService.toNull(item.key),
        groupCode: ConvertService.toNull(item.groupCode)

      }
    } else return null;
  }

  materialInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        index: ConvertService.toNull(item.index),
        node: ConvertService.toNull(item.node),
        level: ConvertService.toNull(item.level),
        parent: ConvertService.toNull(item.parent),
        total: ConvertService.toNull(item.total),
        key: ConvertService.toNull(item.key),
        groupCode: ConvertService.toNull(item.groupCode)
      }
    } else return null;
  }

  itemInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        name_en: ConvertService.toNull(item.name_en),
        name_kh: ConvertService.toNull(item.name_kh),
        index: ConvertService.toNull(item.index),
        node: ConvertService.toNull(item.node),
        level: ConvertService.toNull(item.level),
        parent: ConvertService.toNull(item.parent),
        total: ConvertService.toNull(item.total),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  countryInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }
  coformInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  countryPortInfo(item: any) {
    const obj = ConvertService.toNull(item)
    if (obj) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key)
      }
    } else return null;
  }

  cutStockOutstandingRef(lastVisible: any, orderBy: string, statusKey: any, itemStatusField: string, companyKey: string,) {
    return this.db.collection("register_co", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("registerCutStock", "==", false)
        .where("outstandingStock", "==", true)
        .where("register_status.order", "==", 2)

      if (statusKey !== 0) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[orderBy];
        condition = condition.orderBy(orderBy, "desc")
          .startAfter(startAfter)
          .limit(APPS.size)
      }
      else {
        condition = condition.orderBy(orderBy, "desc")
          .limit(APPS.size)
      }
      return condition
    })
  }

  searchCutStockOutstandRef(text: any, itemStatusField: string, companyKey: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection("register_co", ref => {
      let condition = ref.where("isDelete", "==", false)
        .where("companyKey", "==", companyKey)
        .where("registerCutStockKey", "==", null)
        .where("outstandingStock", "==", true)
        .where("register_status.order", "==", 2)

      if (text) {
        condition = condition.where("keyword", ">=", search)
          .where("keyword", '<', end)
          .orderBy("keyword")
          .orderBy("updated_at", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
          .limit(APPS.size)
      }
      return condition;
    })
  }

  resisterCutStockRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_cut_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  resisterVoidStockRef(lastVisible: any, filterStatusKey: any, registerStatusKey: any, companyKey: string) {
    return this.db.collection("register_void_stock", ref => {
      let condition = ref.where("companyKey", "==", companyKey)
      if (registerStatusKey === 'deleted') {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false).where("register_status.key", "==", registerStatusKey);
      }
      //register status
      if (filterStatusKey !== 0) {
        condition = condition.where("status.key", "==", filterStatusKey)
      }
      //lazy load condition
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc").startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      //====
      return condition.limit(APPS.size)
    })
  }

  searchResisterCutStockRef(collectionName: string, field: string, text: any, order: any, registerStatusKey: string, companyKey: string) {
    const search = text.toUpperCase();
    const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    return this.db.collection(collectionName, ref => {
      let condition = ref.where("companyKey", "==", companyKey).where("status.key", "==", statusObj.active.key)
      condition = condition.where("isDelete", "==", registerStatusKey === 'deleted' ? true : false)
      if (registerStatusKey !== 'deleted') {
        condition = condition.where("register_status.key", "==", registerStatusKey)
      }
      if (text) {
        condition = condition
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .orderBy("page_key", "desc")
          .limit(APPS.size / 2)
      }
      else {
        condition = condition.orderBy("page_key", "desc").limit(APPS.size)
      }
      return condition;
    })
  }



}
