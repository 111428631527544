<div *ngIf="store?.isLoginByOtherDevice" class="warning-login-session">
    <div class="warning-login-session-content">
        Your account has log in by another device. Please change your password if this is not you!
        <div class="warning-login-info">
            <strong>Device information:</strong>
            <p *ngIf="store?.profile?.session.ip">
                Ip Address:
                {{store?.profile?.session.ip}}
            </p>
            <p *ngIf="store?.profile?.session.browser">
                Browser:
                {{store?.profile?.session.browser}}
            </p>
            <p *ngIf="store?.profile?.session.browser_version">
                Browser Version:
                {{store?.profile?.session.browser_version}}
            </p>
            <p *ngIf="store?.profile?.session.device">
                Device:
                {{store?.profile?.session.device}}
            </p>
            <p *ngIf="store?.profile?.session.os">
                OS:
                {{store?.profile?.session.os}}
            </p>
            <p *ngIf="store?.profile?.session.os_version">
                OS Version:
                {{store?.profile?.session.os_version}}
            </p>
            <!-- <p *ngIf="store?.profile?.session.userAgent">
                User Agent:
                {{store?.profile?.session.userAgent}}
            </p> -->
        </div>
    </div>
</div>
<div class="login-container">
    <div class="branding">
        <img class="logo" src="../../../../assets/images/camwinlogo.jpg" alt="" style="width: 168px;">
        <!-- <h1 class="bname">CAM<span>win</span>-BCO</h1> -->
        <div class="cs-divider"></div>
    </div>
    <div class="lgrhd" style="text-align: center">
        <!-- <h3 class="title">CAMwin Logistic Co.,Ltd.</h3> -->
        <p class="desc">CO Document Management</p>
    </div>
    <form class="form-wrapper" [formGroup]="form">
        <div class="error" *ngIf="error">{{error}}</div>
        <div class="form-group column">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input  matInput required placeholder="email" formControlName="email" autocomplete="username">
                <mat-error *ngIf="form.controls['email'].hasError('required')">
                    Email is required.
                </mat-error>
                <mat-error *ngIf="form.controls['email'].hasError('email')">Email is invalid.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" required placeholder="password" formControlName="password" autocomplete="current-password">
                <mat-error *ngIf="form.controls['password'].hasError('required')">
                    Password is required.
                </mat-error>
            </mat-form-field>
            <app-primary-button class="primary-button-fill" (onPress)="_onSignIn(form.value)" [process]="store?.process"
                label="Log in"></app-primary-button>
            <a class="fpwd" (click)="resetPassword()">Forgot password?</a>
        </div>
    </form>
</div>