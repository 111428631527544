import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'sumGroupMaterial'
})
export class SumGroupMaterialPipe implements PipeTransform {

  transform(array: Array<any>, filed: string, materialKey: string): any {
    let total = 0
    if (array && array.length > 0) {
      const material = array.filter(m => m.materialKey === materialKey);
      total = sum(material, filed)
    }
    return total;
  }

}
