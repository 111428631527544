import { APPS } from '../../dummy/config';
import { DataService } from '../../services/data.service';
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { pushToArray, ConvertService } from 'src/app/services/convert.service';
import { MappingService } from 'src/app/services/mapping.service';

@Injectable({ providedIn: 'root' })
export class Outstanding {
    @observable data: Array<any> = [];
    @observable process: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    lastVisible: any = null;
    @observable orderBy: any = null;
    @observable statusKey: any = 1;
    @observable fetching: boolean = false;
    @observable done: boolean = false;
    @observable item: any = null;
    constructor(private ds: DataService) { }

    @action
    fetchOutstanding(key, companyKey) {
        this.process = true;
        this.ds.registerItemDocRef(key, companyKey).valueChanges().subscribe(doc => {
            this.item = doc;
            this.process = false;
        })
    }

    @action
    updateOutstanding(key, companyKey, callback) {
        this.loading = true;
        this.ds.registerItemDocRef(key, companyKey).update({ hasSelected: false }).then(() => {
            callback(true)
            this.loading = false;
        }).catch(error => {
            callback(false)
            this.loading = false;
        })
    }

    @action
    updateOutstandingLocalSaleUsage(key, companyKey, callback) {
        // this.process = true;
        this.ds.settingFireByCompanyRef('local_sale_inv', companyKey).doc(key).update({ hasSelectedUsageInv: false }).then(() => {
            callback(true)
            // this.process = true;
        }).catch(error => {
            callback(false)
            // this.process = true;
        })
    }

    @action
    updateOutstandingLocalSaleStock(key, companyKey, callback) {
        // this.process = true;
        this.ds.settingFireByCompanyRef('local_sale_inv', companyKey).doc(key).update({ hasSelectedCoCutStockKey: false }).then(() => {
            callback(true)
            // this.process = true;
        }).catch(error => {
            callback(false)
            // this.process = true;
        })
    }


    @action
    updateOutstandingSF(item, callback) {
        this.process = true;
        this.ds.settingFireByCompanyRef('register_co', item?.companyKey).doc(item?.key).update(
            {
                hasSelectedSFCO: false,
                keyword: ConvertService.toCapitalize(`${item?.co_number}`),
            }
        ).then(() => {
            callback(true)
            this.process = true;
        }).catch(error => {
            callback(false)
            this.process = true;
        })
    }

    @action
    updateOutstandingSCRF(item, callback) {
        this.process = true;
        this.ds.settingFireByCompanyRef('register_co', item?.companyKey).doc(item?.key).update(
            {
                hasSelectedSCRFCO: false,
                keyword: ConvertService.toCapitalize(`${item?.co_number}`),

            }).then(() => {
                callback(true)
                this.process = true;
            }).catch(error => {
                callback(false)
                this.process = true;
            })
    }


    @action
    updateOutstandingSoo(item, callback) {
        this.process = true;
        this.ds.settingFireByCompanyRef('register_co', item?.companyKey).doc(item?.key).update(
            {
                hasSelectedSooCO: false,
                keyword: ConvertService.toCapitalize(`${item?.co_number}`),

            }
        ).then(() => {
            callback(true)
            this.process = true;
        }).catch(error => {
            callback(false)
            this.process = true;
        })
    }


    @action
    fetchOutstandingRef(collection, key, companyKey) {
        this.process = true;
        this.ds.outstandingDocRef(collection, key, companyKey).valueChanges().subscribe(doc => {
            this.item = doc;
            this.process = false;
        })
    }




    @action
    updateOutstandingDeliveryLocalSaleInvoice(key, companyKey, callback) {
        // this.process = true;
        this.ds.settingFireByCompanyRef('local_sale_inv', companyKey).doc(key).update({ hasSelectedDeliveryLocalSaleInv: false }).then(() => {
            callback(true)
            // this.process = true;
        }).catch(error => {
            callback(false)
            // this.process = true;
        })
    }

    @action
    fetchOutstandingCo(key, companyKey) {
        this.process = true;
        this.ds.registerCoDocRef(key, companyKey).valueChanges().subscribe(doc => {
            this.item = doc;
            this.process = false;
        })
    }


    //OUTSTANDING
    @action
    async fetchData(routerName: string, url: any, icon: any, menuName: string, uid: string, statusKey: any, itemStatusField: string, companyKey: string, isHistory: boolean = true) {
        this.lastVisible = null;
        this.statusKey = statusKey;
        this.done = false;
        this.loading = true;

        this.orderBy = "updated_at";
        const docs = await this.ds.outstandingRef(this.lastVisible, this.orderBy, this.statusKey, itemStatusField, companyKey).get().toPromise();
        const gdata = pushToArray(docs);
        this.data = MappingService.orderByDesc(gdata, 'updated_at')
        if (!docs.empty) {
            this.lastVisible = this.data[this.data.length - 1];
        }
        this.empty = this.data.length === 0;
        this.loading = false;
        if (isHistory) {
            this.ds.userprofileRef().doc(uid).collection("histories").doc(routerName).set({
                url: url,
                name: menuName,
                login_date: new Date(),
                page_key: ConvertService.pageKey(),
                key: routerName,
                icon: icon,
            })
        }
    }



    @action
    async fetchMore(itemStatusField: any, companyKey: string) {
        if (this.fetching || this.done || this.lastVisible === null || this.loading) { return; }
        this.fetching = true;
        const docsData = await this.ds.outstandingRef(this.lastVisible, this.orderBy, this.statusKey, itemStatusField, companyKey).get().toPromise();
        const docs = pushToArray(docsData);
        if (docs.length > 0 && docs.length >= APPS.size) {
            this.lastVisible = docs[docs.length - 1];
        } else {
            this.done = true;
            this.lastVisible = null;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
    }

    @action
    async fetchSearch(search: any, itemStatusField: string, companyKey: string) {
        return this.ds.searchOutstandingRef(search, itemStatusField, companyKey).valueChanges().subscribe(docs => {
            this.data = docs;
            if (docs.length > 0 && docs.length >= APPS.size) {
                this.lastVisible = docs[docs.length - 1];
            } else {
                this.done = true;
                this.lastVisible = null;
            }
        });
    }



    @action
    fetchList(registerField: string, itemStatusFieldKey: string, companyKey: string, callback) {
        this.loading = true;
        this.ds.outstandingListRef(registerField, itemStatusFieldKey, companyKey).valueChanges().subscribe(docs => {
            callback(docs)
            this.loading = false;
        })
    }

}
