import { Component, OnInit, Input } from '@angular/core';
import { pushToObject } from 'src/app/services/convert.service';

@Component({
  selector: 'app-doc-ref',
  templateUrl: './doc-ref.component.html',
  styleUrls: ['./doc-ref.component.scss']
})
export class DocRefComponent implements OnInit {
  @Input() ref:any;
  @Input() field:any;
  @Input() isDate:boolean;
  displayfield;
  constructor() { }

  async ngOnInit() {
    if(this.ref){
      const data = pushToObject(await this.ref.get())
      this.displayfield = data[this.field]
    }
  
  }

}
