
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { REGISTER_ITEM_STATUS_OBJ } from 'src/app/dummy/status';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Injectable({providedIn:'root'})
export class AutoCompleteStore {
  @observable data: Array<any> = [];
  @observable filterdata: Array<any> = [];
  @observable filter: Array<any> = [];
  @observable filterdatas: Array<any> = [];
  @observable collectionData: Array<any> = [];
  @observable singleData: any;
  @observable process: boolean = false;
  @observable deleteProcess: boolean = false;
  @observable loading: boolean = true;
  @observable empty: boolean = false;
  subscription: Subscription;

  constructor(private ds: DataService) { }

  @action
  fetchCollectionByCompany(collection: any, companyKey: any, fieldOrder, callback) {
    this.ds.autoCollectionByCompanyRef(collection, companyKey, fieldOrder).valueChanges().subscribe(docs => {
      callback(docs);
    })
  }

  @action
  fetchAutoCompleted(ref: AngularFirestoreCollection, callback) {
    ref.valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs);
    })
  }

  @action
  fetchAutoData(collection) {
    this.ds.autoColelctionRef(collection).valueChanges().subscribe(data => {
      this.filterdata = data;
    })
  }



  @action
  fetchAllAutoData(collection, callback) {
    this.ds.autoAllColelctionRef(collection).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoDataOutstandingByCompanyCallBack(collection, outstandingfield, companyKey, field, callback) {
    this.ds.autoColelctionOutstandingByCompanyRef(collection, outstandingfield, companyKey, field).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }


  @action
  fetchAutoLocalDataOutstandingByCompanyCallBack(collection, outstandingfield, companyKey, field, callback) {
    this.ds.autoLocalColelctionOutstandingByCompanyRef(collection, outstandingfield, companyKey, field).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoLocalStockDataOutstandingByCompanyCallBack(collection, outstandingfield, companyKey, callback) {
    this.ds.autoOutstandingLocalStock(collection, outstandingfield, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }



  @action
  async fetchSearchOutstandingByCompany(collectionName: string, outstandingfield, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingOutstandingByCompanyTypeRef(collectionName, outstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearchLocalOutstandingByCompany(collectionName: string, outstandingfield, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterLocalListingOutstandingByCompanyTypeRef(collectionName, outstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  async fetchSearchLocalStockOutstandingByCompany(collectionName: string, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchFilterListingOutstandingStockRef(collectionName, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  fetchAutoDataOutstandingByCompanyCallBackSF(collection, outstandingfield, otherOutstandingfield, companyKey, callback) {
    this.ds.autoColelctionOutstandingByCompanyRefSF(collection, outstandingfield, otherOutstandingfield, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoDataOutstandingCallBack(collection, outstandingfield, otherOutstandingField, companyKey, callback) {
    this.ds.autoCollectionOutstandingRef(collection, outstandingfield, otherOutstandingField, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoDataOutstandingStockCallBack(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, callback) {
    this.subscription = this.ds.autoOutstandingStock(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }
  @action
  fetchAutoDataOutstandingStockDeliveryCallBack(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, callback) {
    this.subscription = this.ds.autoOutstandingStockDelivery(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoDataOutstandingCOStockCallBack(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, callback) {
    this.subscription = this.ds.autoOutstandingCoStock(collection, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  

  @action
  fetchAutoReapplyStockCallBack(collection, outstandingfield, companyKey, callback) {
    this.ds.autoReapplyItemStock(collection, outstandingfield, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  async fetchSearchOutstandingStock(collectionName: string, outstandingfield: string, outstandingFieldKey, otherOutstandingField: string, companyKey: string, field: string, search: any, callback) {
    this.subscription = this.ds.searchFilterListingOutstandingStockRef(collectionName, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  async fetchSearchOutstandingStockDelivery(collectionName: string, outstandingfield: string, outstandingFieldKey, otherOutstandingField: string, companyKey: string, field: string, search: any, callback) {
    this.subscription = this.ds.searchFilterListingOutstandingStockDeliveryRef(collectionName, outstandingfield, outstandingFieldKey, otherOutstandingField, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  async fetchSearchReapplyStock(collectionName: string, outstandingfield: string, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchFilterReapplyStockRef(collectionName, outstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearchOutstandingByCompanySOO(collectionName: string, outstandingfield, otherOutstandingfield, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingOutstandingByCompanyTypeRefSOO(collectionName, outstandingfield, otherOutstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  fetchAutoDataOutstandingByCompanyCallBackSOO(collection, outstandingfield, otherOutstandingfield, companyKey, callback) {
    this.ds.autoColelctionOutstandingByCompanyRefSOO(collection, outstandingfield, otherOutstandingfield, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  async fetchSearchOutstandingByCompanyT(collectionName: string, outstandingfield, otherOutstandingfield, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingOutstandingByCompanyTypeRefT(collectionName, outstandingfield, otherOutstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearchOutstandingByCompanySF(collectionName: string, outstandingfield, otherOutstandingfield, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingOutstandingByCompanyTypeRefSF(collectionName, outstandingfield, otherOutstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearchDataOutstandingByCompany(collectionName: string, outstandingfield, otherOutstandingField, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingOutstandingByCompanyRef(collectionName, outstandingfield, otherOutstandingField, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  fetchAutoDataOutstandingByCompanyCallBackT(collection, outstandingfield, otherOutstandingfield, companyKey, callback) {
    this.ds.autoColelctionOutstandingByCompanyRefT(collection, outstandingfield, otherOutstandingfield, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }



  @action
  fetchAutoDataByCompanyCallBack(collection, companyKey, callback) {
    this.ds.autoColelctionByCompanyRef(collection, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  fetchAutoDataCallBack(collection, callback) {
    this.ds.autoColelctionRef(collection).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data);
    })
  }

  @action
  fecthitemsCallback(collectionName: string, companyKey: string, callback) {
    this.ds.fecthitemsMaterial(collectionName, companyKey).valueChanges().subscribe(data => {
      this.filterdata = data;
      callback(data)
    })
  }

  @action
  async fetchSearchCompanyMaterial(collectionName: string, companyKey, field: string, search: any, callback) {
    return this.ds.autosearchListingMaterialRef(collectionName, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  async fetchSearchRegisterCo(companyKey, field, search, callback) {
    return this.ds.autoSearchRegisterCoRef(companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearchImportStockItem(companyKey, field, search, callback) {
    return this.ds.autoSearchListingItemRef(companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchByCompanySearch(collectionName: string, companyKey: string, field: string, search: any, callback) {
    return this.ds.autoSearchByCompanyListingRef(collectionName, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async fetchSearch(collectionName: string, field: string, search: any, callback) {
    return this.ds.autosearchListingRef(collectionName, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }

  @action
  async sfetchSearch(collectionName: string, field: string, search: any, callback) {
    return this.ds.autosearchListingRef(collectionName, field, search).valueChanges().subscribe(docs => {
      this.filterdatas = docs;
      callback(docs)
    });
  }


  @action
  fecthitems(collectionName: string, companyKey: string, typeKey: any, field: string) {
    this.ds.fecthitems(collectionName, companyKey, typeKey, field).valueChanges().subscribe(data => {
      this.filterdata = data;
    })
  }

  @action
  async fetchSearchByCompany(collectionName: string, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchfilterListingByCompanyTypeRef(collectionName, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  async fetchSearchMasterList(collectionName: string, companyKey: string, field: string, search: any, callback) {
    return this.ds.searchFilterMaterListRef(collectionName, companyKey, field, search).valueChanges().subscribe(docs => {
      this.filterdata = docs;
      callback(docs)
    });
  }


  @action
  fecthitemsF(collectionName: string, companyKey: string, field: string) {
    this.ds.fecthitemsf(collectionName, companyKey, field).valueChanges().subscribe(data => {
      this.filterdata = data;
    })
  }

  @action
  async fetchSearchByCompanyF(collectionName: string, companyKey: string, field: string, search: any, callback) {
    if (search) {
      return this.ds.searchfilterListingByCompanyTypeReff(collectionName, companyKey, field, search).valueChanges().subscribe(docs => {
        this.filterdata = docs;
        callback(docs)
      });
    } else {
      callback([])
    }
  }



  @action
  fecthRegisteritems(collectionName: string, companyKey: string, field: string, condistion: string) {
    this.ds.fecthRegisterItems(collectionName, companyKey, field).valueChanges().subscribe(data => {
      const gdata: any = data;

      switch (condistion) {
        case "register_co":
          return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_co_form);
          break;
        case "pickup_bill":
          return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_bill);
          break;
        case "pickup_inspect":
          return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_inspection);
          break;
        case "register_soo":
          return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_inspection);
          break;
        default:
          // return this.filterdata = gdata
          break;
      }

    })
  }

  @action
  fecthRegisterCO(collectionName: string, companyKey: string, field: string, condistion: string) {
    this.ds.fecthRegisterCO(collectionName, companyKey, field).valueChanges().subscribe(data => {
      const gdata: any = data;
      switch (condistion) {
        case "register_co":
          return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_co_form);
        default:
          // return this.filterdata = gdata
          break;
      }

    })
  }

  @action
  async fetchRegisterItemSearchByCompany(collectionName: string, companyKey: string, field: string, search: any, condistion: string, callback) {
    if (search) {
      return this.ds.searchfilterRegisterItemByCompanyTypeRef(collectionName, companyKey, field, search).valueChanges().subscribe(data => {
        const gdata: any = data;
        switch (condistion) {
          case "register_co":
            return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_co_form);
            break;
          case "pickup_bill":
            return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_bill);
            break;
          case "pickup_inspect":
            return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_inspection);
            break;
          case "register_soo":
            return this.filterdata = gdata.filter(m => m.register_item_status.key === REGISTER_ITEM_STATUS_OBJ.submitted.key && !m.pending_pickup_inspection);
            break;
          default:
            // return this.filterdata = gdata
            break;
        }
        callback(data)
      });
    } else {
      callback([])
    }
  }

}


