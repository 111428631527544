<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">
        <div class="title">
            Filter options
        </div>
    </div>
    <div class="dialog-content">
        <div class="form-wrapper" [formGroup]="form">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>Option</mat-label>
                    <mat-select (selectionChange)="onSelectionChange($event)" [compareWith]="compareObjects"
                        formControlName="option">
                        <mat-option *ngFor="let item of filterOptions;" [value]="item">
                            {{item?.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls['option'].hasError('required')">
                        Option is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group" *ngIf="controlType?.type===DATA_TYPE.number">
                <mat-form-field appearance="outline">
                    <mat-label>Enter number</mat-label>
                    <input placeholder="" type="number" aria-label="Enter keyword" matInput formControlName="search">
                </mat-form-field>
            </div>

            <div class="form-group" *ngIf="controlType?.type===DATA_TYPE.option">
                <mat-form-field appearance="outline">
                    <mat-label>Select one</mat-label>
                    <input placeholder="" [matAutocomplete]="autoRoute" type="text" aria-label="Enter keyword" matInput
                        formControlName="search">
                    <mat-autocomplete #autoRoute="matAutocomplete" [displayWith]="displayItem.bind(this)">
                        <mat-option *ngFor="let option of store?.data;" [value]="option">
                            <span class="flex1 mat-space">{{ option[controlType?.displayField] }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="form-group" *ngIf="controlType?.type===DATA_TYPE.date">
                <mat-form-field appearance="outline">
                    <mat-label>Select date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerOptions" placeholder=""
                        formControlName="search">
                    <mat-datepicker-toggle matSuffix [for]="pickerOptions"></mat-datepicker-toggle>
                    <mat-datepicker #pickerOptions></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-checkbox (change)="onChanged($event)" formControlName="includeDate">Include filter by period
                </mat-checkbox>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>From date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerFromDate" placeholder="From date"
                        formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>To date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerToDate" placeholder="To date"
                        formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerToDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <app-primary-button (onPress)="_onFilter(form.value)" [disable]="!form.valid" label="Search">
        </app-primary-button>
        <button mat-button class="mat-puc cancel" (click)="clear()">
            Clear
        </button>
    </div>
</form>