<form class="dialog-wrapper form-dialog-wrapper">
    <div class="dialog-header">

     
        <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
            aria-label="Print">
            <mat-icon>view_module</mat-icon>
        </button>
        <mat-menu #menuColumn="matMenu">
            <app-column-visible collection="reapply_Column_Print" [data]="COLUMNS">
            </app-column-visible>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
            {{onSelectedItem?.text}}
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let item of reApplyOptionLists;let i=index">
                <button mat-menu-item [class.active]="onSelectedItem.key === item.key" (click)="selectedOption(item)">
                    <mat-icon>dialpad</mat-icon>
                    <span>{{item?.text}}</span>
                </button>
            </ng-container>

        </mat-menu>
        <div class="flex1"></div>

        <a mat-dialog-close class="close-dialog" (click)="close()">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content report-wrapper" id="printms" >
        <div style="flex:1; display: flex; flex-direction: column;">
            <ng-container *ngIf="data?.collection == 'register_re_apply'">
                <table style="width: 100%;">

                    <tbody>

                        <tr>
                            <td>
                                <div class="page">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="title-info" style="margin-top: 18px;">
                                                <div>ក្រសួងពាណិជ្ជកម្ម</div>
                                                <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                                                <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                                                <div class="star">************</div>
                                            </div>
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; margin-top: -24px;">
                                            <div class="khf" style="text-align: center;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold khf">
                                                <h3>កំណត់បង្ហាញ</h3>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">


                                            <div class="khf" style="text-align: center;margin-bottom: 6px; ">

                                                ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ
                                                <strong class="enf">ONLINE</strong>

                                            </div>

                                        </div>
                                        <div class="d-flex" style="align-items: flex-end;">
                                            <div class="info">

                                                <div class="" *ngIf="auth?.selectedCompany?.name_en">
                                                    <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                                                    <span class="enf">
                                                        <!-- {{auth?.selectedCompany?.name_en}} -->
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'name_en'"></app-company-field>

                                                    </span>
                                                </div>
                                                <div class="khf" *ngIf="auth?.selectedCompany?.address">
                                                    <strong class="bold">កម្មវត្ថុ:</strong>
                                                    ស្នើសុំប្ដូរ
                                                    <span class="enf">CO FORM
                                                        "{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                                                    <span class="enf">
                                                        BY ({{data?.item?.routeRef | docRef:'name' | async}})
                                                    </span>
                                                    ចំនួន
                                                    <span class="enf">
                                                        {{data?.item?.registerCoItems?.length}}
                                                    </span>
                                                    ច្បាប់
                                                    នាំចេញ{{data?.item?.productTypeRef | docRef:'name_kh' | async}}

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master tb-master-print">
                                        <thead class="table-header" style="margin-bottom: 0;">
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                                                <th class="table-col center" colspan="2" *ngIf="COLUMNS?.co_reference?.value"> CO Reference</th>
                                                <th class="table-col center" rowspan="2"  *ngIf="COLUMNS?.quantity?.value">
                                                    <div style="text-align: center;" >
                                                        Qty Unit
                                                    </div>
                                                </th>
                                                <th class="table-col " rowspan="2" *ngIf="COLUMNS?.amount?.value">
                                                    <div style="text-align: center;">
                                                        Amount
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.cat?.value">
                                                    CAT</th>
                                                <th class="table-col center" colspan="2" *ngIf="COLUMNS?.invoice?.value"> Invoice</th>
                                                <th class="table-col center" colspan="2" *ngIf="COLUMNS?.joint_inspection?.value "> Joint Inspection</th>
                                                <th class="table-col center" rowspan="2"  *ngIf="COLUMNS?.bill_no?.value"> Bill No.</th>
                                                <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.destination?.value"> Destination</th>
                                                <th class="table-col center"  [colSpan]="COLUMNS?.adm_fee?.value && COLUMNS?.emf_fee?.value?2:1"
                                                *ngIf="COLUMNS?.adm_fee?.value || COLUMNS?.emf_fee?.value"> Service
                                                    Fee</th>
                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" *ngIf="COLUMNS?.co_reference?.value">No
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.co_reference?.value">Date
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.invoice?.value">No</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.invoice?.value">Date</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.joint_inspection?.value">No
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.joint_inspection?.value">
                                                    Date</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.adm_fee?.value">ADM Fee
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.emf_fee?.value">EMF Fee
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body" *ngIf="submitItem">

                                            <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                                                <td class="table-col center">{{i+1}}</td>
                                                <td class="table-col " *ngIf="COLUMNS?.co_reference?.value">
                                                    {{item?.registerCoRef | docRef :'co_number' | async}}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.co_reference?.value">
                                                    {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                                </td>
                                                <td class="table-col right" *ngIf="COLUMNS?.quantity?.value">
                                                    {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}
                                                    {{item?.registerItemRef | docDocRef :'qty_unit_UOMRef':'code' | async}}

                                                </td>
                                                <td class="table-col right" *ngIf="COLUMNS?.amount?.value">
                                                    {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                                </td>
                                                <td class="table-col right" *ngIf="COLUMNS?.cat?.value">
                                                    {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.invoice?.value">
                                                    {{item?.registerItemRef | docRef:'invoice_number' | async }}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.invoice?.value">
                                                    {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value">
                                                    {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value">
                                                    {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.bill_no?.value">
                                                    {{item?.pickupBillRef| docRef:'bill_number' | async }}
                                                </td>
                                                <td class="table-col " *ngIf="COLUMNS?.destination?.value">
                                                    {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}
                                                </td>
                                                <td class="table-col right" *ngIf="COLUMNS?.adm_fee?.value">
                                                    <!-- {{item?.admFee | number:'1.'}} -->
                                                    {{item?.registerCoRef | docRef :'admFee' | async | number:'1.'}}

                                                    <span *ngIf="(item?.registerCoRef | docRef :'admFee' | async)">
                                                        <span class="reil">៛</span></span>
                                                </td>
                                                <td class="table-col right" *ngIf="COLUMNS?.emf_fee?.value">
                                                    <!-- {{item?.emfFee | number:'1.'}} -->
                                                    {{item?.registerCoRef | docRef :'emfFee' | async | number:'1.'}}
                                                    <span *ngIf="(item?.registerCoRef | docRef :'emfFee' | async)">
                                                        <span class="reil">៛</span></span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div class="owe">ច្រកចេញចូលតែមួយ</div>


                                    <table class="table-wrapper tb-master fx-tb-master" *ngIf="onSelectedItem.key === 1">
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center; flex-direction: column;">
                                                    <p class="khf">សូមគោរពជូន ឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</p>
                                                    <p class="khf">គួរអនុញ្ញាតប្ដូរ <span class="enf">CO</span> ជូនតាមសំណើ
                                                    </p>
                                                    <p class="khf">សូមឯកឧត្តមមេត្តាពិនិត្យនិងសម្រេច។</p>
                                                    <p class="khf bold" style="margin-bottom: 100px;">ឯកឧត្តមប្រធាននាយកដ្ឋាន</p>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">ឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</strong>
                                                </div>
                                            </td>
                                        </tr>

                                        <td class="table-col" colspan="2" style="height: 100px;">

                                        </td>
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">ឯកឧត្តម/ លោកជំទាវ
                                                        រដ្ឋលេខាធិការ/ អនុរដ្ឋលេខាធិការ</strong>
                                                </div>
                                            </td>
                                        </tr>

                                        <td class="table-col" colspan="2" style="height: 100px;">

                                        </td>

                                    </table>

                                    <table class="table-wrapper tb-master fx-tb-master"
                                        *ngIf="onSelectedItem.key === 2 || onSelectedItem.key === 4">
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center; flex-direction: column;">
                                                    <p class="khf">សូមគោរពជូន ឯកឧត្តម/ លោកជំទាវ</p>
                                                    <p class="khf">គួរអនុញ្ញាតប្ដូរ <span class="enf">CO</span> ជូនតាមសំណើ
                                                    </p>
                                                    <p class="khf">សូម ឯកឧត្តម/ លោកជំទាវ មេត្តាពិនិត្យនិងសម្រេច។</p>
                                                    <p class="khf bold" style="margin-bottom: 168px;">ឯកឧត្តមប្រធាននាយកដ្ឋាន</p>
                                                </div>

                                            </td>
                                        </tr>

                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">ឯកឧត្តម/ លោកជំទាវ
                                                        រដ្ឋលេខាធិការ/ អនុរដ្ឋលេខាធិការ</strong>
                                                </div>
                                            </td>
                                        </tr>

                                        <td class="table-col" colspan="2" style="height: 168px;">

                                        </td>

                                    </table>

                                    <table class="table-wrapper tb-master fx-tb-master" *ngIf="onSelectedItem.key === 3">
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <strong class="khf bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: center; flex-direction: column;">
                                                    <p class="khf">សូមគោរពជូន ឯកឧត្តម/ លោកជំទាវ</p>
                                                    <p class="khf">គួរអនុញ្ញាតប្ដូរ <span class="enf">CO</span> ជូនតាមសំណើ
                                                    </p>
                                                    <p class="khf">សូម ឯកឧត្តម/ លោកជំទាវ មេត្តាពិនិត្យនិងសម្រេច។</p>
                                                    <p class="khf bold" style="margin-bottom: 168px;">ឯកឧត្តមប្រធាននាយកដ្ឋាន</p>
                                                </div>

                                            </td>
                                        </tr>

                                        <tr class="table-row">
                                            <td class="table-col center" colspan="2">
                                                <p class="khf">ឯកឧត្តម/លោកជំទាវ រដ្ឋលេខាធិការ/អនុរដ្ឋលេខាធិការ</p>
                                                <!-- <div class="d-flex"
                                                    style="align-items: center; justify-content: center;">
                                                    <div class="checkbox-custom"></div>
                                                    <strong class="khf bold">ឯកឧត្តមរដ្ឋលេខាធិការ</strong>
                                                    <div class="checkbox-custom"></div>
                                                    <strong class="khf bold">ឯកឧត្តមអនុរដ្ឋលេខាធិការ</strong>
                                                    <div class="checkbox-custom"></div>
                                                    <strong
                                                        class="khf bold">ឯកឧត្តមអគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</strong>
                                                </div> -->
                                            </td>
                                        </tr>

                                        <td class="table-col" colspan="2" style="height: 168px;">

                                        </td>

                                    </table>


                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <div class="page-footer-space small"></div>
                            </td>
                        </tr>
                    </tfoot>

                </table>
            </ng-container>
            <div class="page-footer" style="border: none;">
                <div class="d-flex print-date">
                    <div class="flex-1"></div>
                    <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                    </div>
                    <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                </div>
            </div>
        </div>


    </div>
    <div class="dialog-footer" style="padding: 24px;">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="close()"><span>Cancel</span></a>
        <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
            style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
            <mat-icon class="mat-18">print</mat-icon>
            Print
        </button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>
</form>