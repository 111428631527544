import { Pipe, PipeTransform } from '@angular/core';
import { ReportingStore } from '../stores/reports/reporting.store';

@Pipe({
    name: 'stateDocDocRef'
})
export class StateDocRefPipe implements PipeTransform {
    constructor(private store: ReportingStore) { }
    transform(ref: any, field1: string, field2: string, isMulti: boolean): any {
        if (!ref) return null;
        if (isMulti) {
            return ref.get().then(doc => {
                if (doc.data() !== undefined) {
                    const data = doc.data()[field1];
                    this.store.loading = true
                    if (data) {
                        return data.get().then(ref => {
                            this.store.loading = false
                            return field2 ? ref.data()[field2] : ref.data()
                        })
                    } else {
                        this.store.loading = false
                        return null
                    }
                }
            });
        }
        else {
            return ref.get().then(doc => {
                this.store.loading = true
                if (doc.data() !== undefined) {
                    this.store.loading = false
                    return field1 ? doc.data()[field1] : doc.data()
                } else {
                    return null
                }
            });
        }
    }

    // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}
