import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Auth } from 'src/app/stores/auth.store';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { pushToObject } from 'src/app/services/convert.service';
@Component({
  selector: 'app-register-detail-item',
  templateUrl: './register-detail-item.component.html',
  styleUrls: ['./register-detail-item.component.scss']
})
export class RegisterDetailItemComponent implements OnInit {
  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Output() onDelete = new EventEmitter();
  @ViewChild('content') content: ElementRef;
  detail: any;
  ismobile: boolean = false;
  registerImportInvoiceInfo;
  registerImportStock;
  deliveryImportStock;
  registerLocalSaleInvUsage;
  material;
  constructor(
    public auth: Auth,
    public store: SettingStore,
    public ps: PrintService,
    public breakpointObserver: BreakpointObserver,

  ) { }

  async ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });

    if (this.collection == 'local_sale_inv') {
      const { registerLocalSaleInvUsageRef } = this.data
      if (registerLocalSaleInvUsageRef) {
        this.registerLocalSaleInvUsage = pushToObject(await registerLocalSaleInvUsageRef.get())
        this.material = this.registerLocalSaleInvUsage.material;
      }

    }

    if (this.collection == 'register_invoice') {
      const { registerImportInvoiceInfoRef, registerImportStockRef } = this.data
      if (registerImportInvoiceInfoRef) {
        this.registerImportInvoiceInfo = pushToObject(await registerImportInvoiceInfoRef.get())
      }

      if (registerImportStockRef) {
        this.registerImportStock = pushToObject(await registerImportStockRef.get())
        const { deliveryImportStockRef } = this.registerImportStock;
        if (deliveryImportStockRef) {
          this.deliveryImportStock = pushToObject(await deliveryImportStockRef.get())
        }

      }
    }
    

  }
  print() {
    this.ps.print('#print', 'a4');
  }
  makePdf() {

  }
  delete(item: any) {
    this.onDelete.emit(item);
  }

}
