import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';
import { from } from 'rxjs';
import * as _ from 'lodash';
import { groupBy, mergeMap, toArray, map } from 'rxjs/operators';

@Component({
  selector: 'app-group-stock-material',
  templateUrl: './group-stock-material.component.html',
  styleUrls: ['./group-stock-material.component.scss']
})
export class GroupStockMaterialComponent implements OnInit {

  @Input() key: any;
  @Input() data: any;
  materials: any = [];
  group: any = [];
  groupedData: any;
  constructor() { }
  async ngOnInit() {
    if (this.data?.materials) {
      this.materials = MappingService.orderBy(this.data.materials, "itemKey") || [];
      if (this.materials && this.materials.length > 0) {
        from(this.materials)
          .pipe(
            groupBy((x: any) => x.materialKey)
            , mergeMap(group => group.pipe(toArray()))
            , map(g => {
              return {
                materialKey: g[0].materialKey,
                materialRef: g[0].materialRef,
                itemRef: g[0].itemRef,
                import_qty: _.sumBy(g, 'import_qty'),
                import_cost: _.sumBy(g, 'import_cost'),
                import_totalCost: _.sumBy(g, 'import_totalCost'),
                import_qty_NW: _.sumBy(g, 'import_qty_NW'),
                import_qty_GW: _.sumBy(g, 'import_qty_GW'),
                uomRef: g[0].uomRef,
                import_qty_NW_UOMRef: g[0].import_qty_NW_UOMRef,
                import_currencyRef: g[0].import_currencyRef,
                import_qty_GW_UOMRef: g[0].import_qty_GW_UOMRef,
              }
            }),
            toArray()
          )
          .subscribe(d => this.groupedData = d);
      }

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue) {
      this.materials = MappingService.orderBy(changes.data.currentValue.materials, "itemKey") || [];
      if (this.materials && this.materials.length > 0) {
        from(this.materials)
          .pipe(
            groupBy((x: any) => x.materialKey)
            , mergeMap(group => group.pipe(toArray()))
            , map(g => {
              return {
                materialKey: g[0].materialKey,
                materialRef: g[0].materialRef,
                itemRef: g[0].itemRef,
                import_qty: _.sumBy(g, 'import_qty'),
                import_cost: _.sumBy(g, 'import_cost'),
                import_totalCost: _.sumBy(g, 'import_totalCost'),
                import_qty_NW: _.sumBy(g, 'import_qty_NW'),
                import_qty_GW: _.sumBy(g, 'import_qty_GW'),
                uomRef: g[0].uomRef,
                import_qty_NW_UOMRef: g[0].import_qty_NW_UOMRef,
                import_currencyRef: g[0].import_currencyRef,
                import_qty_GW_UOMRef: g[0].import_qty_GW_UOMRef,
              }
            }),
            toArray()
          )
          .subscribe(d => this.groupedData = d);
      }
    }
  }

}
