import { Pipe, PipeTransform } from '@angular/core';
import { driveObj, } from '../dummy/status';
import { FILE_TYPE } from '../dummy/fileType'
@Pipe({
  name: 'iconDrive'
})
export class IconDrivePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      const { type, fileType, downloadUrl, key } = value;
      const icon = FILE_TYPE[type]
      if (icon) return icon ? icon : 'd'
      return downloadUrl

    }
    return null;
  }

}
