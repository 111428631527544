<div class="page-section" *mobxAutorun>
    <div class="sticky-block">
        <div class="action-bar">
            <div class="nav-content">
                <h3 class="head-title">
                    File Manager
                </h3>
                <!-- <div class="flex1"></div> -->
             
            </div>

        </div>
        <nav mat-tab-nav-bar class="cs-mat-tab-link">
            <a mat-tab-link [routerLink]="['/file-manager/lists']" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive">Folder</a>
            <div class="flex-1"></div>
        </nav>
    </div>
    <div class="cs-container">
        <div class="folder-lists">
            <button *ngFor="let item of fileFolders" mat-button [routerLink]="[item?.route]"
                routerLinkActive="router-link-active" class="mat-elevation-z1">
                <mat-icon class="mat-18" style="color: #1a73e8;">folder</mat-icon>
                {{item?.name}}
            </button>
        </div>
    </div>
</div>