import { Router } from '@angular/router';
import { Auth } from './../../../stores/auth.store';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordComponent } from '../../user/forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;

  error: any = null;
  constructor(
    private fb: FormBuilder,
    public store: Auth,
    private router: Router,
    private dialog:MatDialog
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    })
    this.email = this.form.controls['userName'];
    this.password = this.form.controls['password'];
  }
  resetPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      data:null ,
      panelClass: "resetpassword-overlay-panel",
      width: "",
      height: "100vh",
      disableClose: true,
      role: "dialog"
    });
    dialogRef.updatePosition({ top: "0", right: "0", bottom: "0" });
    dialogRef.afterClosed().subscribe(result => {});
  }
  _onSignIn(f: any) {
    if (this.form.valid) {
      this.error = null;
      this.form.disable();
      this.store.signIn(f, (success) => {
        if (success) {
          this.router.navigate(['/login/choose-company'])
        }
        else {
          this.error = "Invalid your email and password. Please try again!";
        }
        this.form.enable();
      })
    }
    else {
      this.form.enable();
      this.error = null;
    }
  }


}
