<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="title">
            Confirm release stock
        </div>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="onClose()">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content">
        <form class="form-contain" [formGroup]="form">
            <!-- <h4>CO Material</h4> -->
            <div class="form-group">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Release Date</mat-label>
                    <input autocomplete="off" required matInput [matDatepicker]="pickera" placeholder="Release Date"
                        formControlName="application_date">
                    <mat-datepicker-toggle matSuffix [for]="pickera"></mat-datepicker-toggle>
                    <mat-datepicker #pickera></mat-datepicker>
                    <mat-error *ngIf="form.controls['application_date'].hasError('required')">
                        <div>This field is required.</div>
                    </mat-error>
                    <mat-error *ngIf="form.controls['application_date'].hasError('nameAvailable')"
                    class="error-message">
                    This release date must be in order.</mat-error>
                </mat-form-field>
            </div>

        </form>
    </div>
    <div class="dialog-footer">
        <div class="flex1"></div>
        <a mat-dialog-close class="enbutton cancel m-r16" mat-button (click)="onClose()">Cancel</a>
        <button type="submit" class="enbutton primary m-r16" [disabled]="!form.valid"
            mat-raised-button (click)="onSubmit(form.value)" color="warn">Confirm</button>
    </div>
</div>