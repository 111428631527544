import { Auth } from './../../stores/auth.store';
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { AppInfo } from 'src/app/dummy/appinfo';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { fileFolder } from 'src/app/dummy/fileFolder';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { appPrintTypes } from 'src/app/dummy/status';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  appInfo = AppInfo
  expansions = {
    'one': true,
    'two': true,
    'three': true,
    'four': true,
    'five': true,
    'six': true,
    'seven': true,
    'eight': true,
    'nine': true,
    'ten': true,
    'eleven': true,
    'twelve': true,
  };
  toggleSidebar: boolean = true;
  disabled: boolean = true;
  profile: any;
  appPrintTypes = appPrintTypes;
  fileMgs = fileFolder;
  isMobile = false;

  constructor(
    public auth: Auth,
    public ds: DataService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {

    const toggleSidebar = localStorage.getItem("toggleSidebar");
    this.toggleSidebar = JSON.parse(toggleSidebar);
    if (!this.toggleSidebar) {
      let body = document.getElementsByClassName("page-wrapper")[0];
      body.classList.toggle("toggled-sidebar");
    }
    
    
    this.breakpointObserver
      .observe(['(max-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          let body = document.getElementsByClassName("page-wrapper")[0];
          body.classList.toggle("toggled-sidebar");
        }
      });

      this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = state.matches;
        } else {
          this.isMobile = state.matches;
        }
      });

    const expansions = JSON.parse(localStorage.getItem("expansions"));
    if (expansions) {
      this.expansions = expansions
    }
  }

  onLogOut() {
    const uRef = this.ds.userFireRef().doc(this.auth.user.uid);
    uRef.update({ selected_company: null }).then(() => {
      this.auth.signOut(() => {
        this.router.navigate(['/login'])
      });
    }).catch(error => {
      console.log('error', error);
    }).finally(() => { })
  }

  togglesidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    localStorage.setItem('toggleSidebar', JSON.stringify(this.toggleSidebar));

  }

  onOpened(i) {
    const isOpen = true;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
      case 11:
        this.expansions.eleven = isOpen;
        break;
      case 12:
        this.expansions.twelve = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }
  onClosed(i) {
    const isOpen = false;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
      case 11:
        this.expansions.eleven = isOpen;
        break;
      case 12:
        this.expansions.twelve = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }


}
