<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">


        <ng-container
            *ngIf="data?.collection === 'register_ob_stock' || data?.collection === 'register_local_invoice'  || data?.collection === 'register_adjustment_stock'  || data?.collection === 'register_adjustment_stock_under'">
            <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="">
                <mat-icon>more_vert</mat-icon>
                {{selectedItem?.text}}
            </button>
            <mat-menu #menuCountry="matMenu">
                <ng-container *ngFor="let item of CoMaterialPrintLists;let i=index">
                    <button mat-menu-item [class.active]="selectedItem?.key === item?.key"
                        (click)="_onSelectedItem(item)">
                        <mat-icon>dialpad</mat-icon>
                        <span>{{item?.text}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.collection === 'register_soo_statement'">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="SooStatement_Column_Print" [selectedTemplate]="selectedTemplate"
                    [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>
        <ng-container *ngIf="data?.collection === 'register_soo'">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="Soo_Column_Print" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>
        <ng-container *ngIf="data?.printTemplateData?.length > 0">
            <button mat-button [matMenuTriggerFor]="menu">Print Templates</button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let item of data?.printTemplateData">
                    <button mat-menu-item [class.active]="selectedTemplate?.key === item?.key"
                        (click)="onSelectTemplate(item)">{{item?.title}}</button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content report-wrapper">
        <div style="flex:1; display: flex; flex-direction: column;">
            <table id="printms" style="width: 100%;">
                <tbody>
                    <tr>
                        <td>
                            <div class="page" style="width: 100%;">
                                <ng-container
                                    *ngIf="data?.collection == 'register_cancel_co_letter' || data?.collection == 'register_reapply_letter' || data?.collection == 'register_duplicate_letter' || data?.collection == 'register_letter_form'">
                                    <table style="margin: 0 auto;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="page">
                                                        <div class="report-header" style="max-width:190mm">
                                                            <div class="d-flex">
                                                                <div class="flex-1"></div>
                                                                <div class="title-info bavacana">
                                                                    <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                                    <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                                    <div class="star">************</div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: flex-end; margin-top: -28px;">
                                                                <div class="info" style="text-align: left;">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <div class="khf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_kh">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_kh'">
                                                                                </app-company-field>
                                                                            </div>
                                                                            <div class="enf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_en">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_en'">
                                                                                </app-company-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="">
                                                                        <span class="enf">
                                                                            <app-company-field
                                                                                [key]="auth?.selectedCompany?.key"
                                                                                [feild]="'address_kh'">
                                                                            </app-company-field>
                                                                        </span>
                                                                    </div>
                                                                    <div class="enf">
                                                                        Reference No:
                                                                        <span class="enf">{{data?.item?.refNo}}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                                                                <div class="khf" style="text-align: right;">
                                                                    <p>
                                                                        {{data?.item?.lunarDate}}
                                                                    </p>
                                                                    <p style="margin-bottom: 6px;">
                                                                        {{data?.item?.khmerDate}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="d-flex"
                                                                style="align-items: center; justify-content: center; padding: 20px 0;">
                                                                <div style="text-align: center; margin-bottom: 6px;"
                                                                    class="bold khf">
                                                                    <h3 style="text-decoration: underline;">សូមគោរពជូន
                                                                    </h3>
                                                                    <h3 style="text-decoration: underline;">
                                                                        {{data?.item?.titleRef | docRef:'name_kh' | async}}
                                                                        ក្រសួងពាណិជ្ជកម្ម</h3>
                                                                </div>
                                                            </div> -->

                                                            <div class="d-flex" style="align-items: flex-end;">
                                                                <div class="info">
                                                                    <div class="c-comment"
                                                                        [innerHtml]="data?.item?.text | safe:'html'">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="margin-top: 12px;">
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                                <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div class="page-footer-space"></div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_other_letter'">
                                    <table style="margin: 0 auto;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="page">
                                                        <div class="report-header" style="max-width:190mm">

                                                            <div class="d-flex" style="align-items: flex-end;">
                                                                <div class="info">
                                                                    <div class="c-comment"
                                                                        [innerHtml]="data?.item?.text | safe:'html'">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="margin-top: 12px;">
                                                        <div class="d-flex"
                                                            style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                            <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                        </div>
                                                    </div> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div class="page-footer-space"></div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'preview_letter'">
                                    <table style="margin: 0 auto;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="page">
                                                        <div class="report-header" style="max-width:190mm">
                                                            <div class="d-flex">
                                                                <div class="flex-1"></div>
                                                                <div class="title-info bavacana">
                                                                    <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                                    <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                                    <div class="star">************</div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: flex-end; margin-top: -28px;">
                                                                <div class="info" style="text-align: left;">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <div class="khf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_kh">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_kh'">
                                                                                </app-company-field>
                                                                            </div>
                                                                            <div class="enf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_en">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_en'">
                                                                                </app-company-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="">
                                                                        <span class="enf">
                                                                            <app-company-field
                                                                                [key]="auth?.selectedCompany?.key"
                                                                                [feild]="'address_kh'">
                                                                            </app-company-field>
                                                                        </span>
                                                                    </div>
                                                                    <div class="enf">
                                                                        Reference No:
                                                                        <span class="enf">{{data?.item?.refNo}}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                                                                <div class="khf" style="text-align: right;">
                                                                    <p>
                                                                        {{data?.item?.lunarDate}}
                                                                    </p>
                                                                    <p style="margin-bottom: 6px;">
                                                                        {{data?.item?.khmerDate}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="d-flex"
                                                                style="align-items: center; justify-content: center; padding: 20px 0;">
                                                                <div style="text-align: center; margin-bottom: 6px;"
                                                                    class="bold khf">
                                                                    <h3 style="text-decoration: underline;">សូមគោរពជូន
                                                                    </h3>
                                                                    <h3 style="text-decoration: underline;">
                                                                        {{data?.item?.titleRef | docRef:'name_kh' | async}}
                                                                        ក្រសួងពាណិជ្ជកម្ម</h3>
                                                                </div>
                                                            </div> -->

                                                            <div class="d-flex" style="align-items: flex-end;">
                                                                <div class="info">
                                                                    <div class="c-comment"
                                                                        [innerHtml]="data?.text | safe:'html'">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="margin-top: 12px;">
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                                <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div class="page-footer-space"></div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_material'">
                                    <div class="report-header" style="padding: 0;">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'CO Material'}}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Code</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.code}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Name</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.name}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">UOM</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.uomRef | docRef:'code' | async}}
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">Sub Of CO Material</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.subItem?.code}}
                                            </td>

                                        </tr>
                                        <!-- <tr>
                                            <td>
                                                <div class="pml-name">Last Update</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.updated_at?.toDate() | date:'medium'}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Status</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.status?.text}}
                                            </td>

                                        </tr> -->

                                    </table>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_import_material'">
                                    <div class="report-header" style="padding: 0;">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;"> Customs Material</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Code</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.code}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Name</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.name}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">UOM</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.uomRef | docRef:'code' | async}}
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">Sub Of CO Material</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.subItem?.code}}
                                            </td>

                                        </tr>
                                        <!-- <tr>
                                            <td>
                                                <div class="pml-name">Last Update</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.updated_at?.toDate() | date:'medium'}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Status</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.status?.text}}
                                            </td>

                                        </tr> -->

                                    </table>
                                </ng-container>



                                <ng-container *ngIf="data?.collection == 'register_co'">
                                    <div class="report-header" style="padding: 0;">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Export CO
                                                </h3>
                                            </div>
                                        </div>
                                    </div>


                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export CO Number</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.co_number}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export CO Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.co_date?.toDate() | date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Shipping Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.etd?.toDate() | date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">ADM Fee (KHR)</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.admFee | number:'1.'}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">EMF Fee (KHR)</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.emfFee | number:'1.'}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name"> CO Form</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docDocRef: 'co_formRef' : 'name' |
                                                async}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Invoice Number</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.register_itemRef | docRef:'invoice_number' | async}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Invoice Date</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docRefDate:'invoice_date' | async |
                                                date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name"> CAT</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.register_itemRef | docRef:'cat' | async | number:'1.'}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name"> Amount</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docRef:'amount_FOB' | async |
                                                currency:' ':'code'}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name"> Destination</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docDocRef: 'countryRef' : 'name' |
                                                async}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name"> Route</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docDocRef: 'routeRef' : 'name' |
                                                async}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name"> Qty Packing</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docRef:'qty_packing' | async |
                                                number:'1.'}}
                                                <span
                                                    *ngIf="(data?.item?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async)">

                                                    {{data?.item?.register_itemRef | docDocRef: 'qty_packing_UOMRef' :
                                                    'code' | async}}

                                                </span>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>
                                                <div class="pml-name"> Qty Unit</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.register_itemRef | docRef:'qty_unit' | async |
                                                number:'1.'}}
                                                <span
                                                    *ngIf="(data?.item?.register_itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async)">

                                                    {{data?.item?.register_itemRef | docDocRef: 'qty_unit_UOMRef' :
                                                    'code' | async}}
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td>
                                                <div class="pml-name"> Last Update</div>
                                            </td>
                                            <td>
                                                :
                                                {{data?.item?.updated_at?.toDate() | date:'medium'}}
                                            </td>
                                        </tr> -->

                                    </table>

                                </ng-container>
                                <ng-container *ngIf="data?.collection == 'pickup_bill'">
                                    <div class="report-header" style="padding: 0;">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Export Bill
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Bill Number</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.bill_number}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Bill Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.bill_date?.toDate() | date}}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">CO Export Invoice</div>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                        </tr>


                                    </table>


                                    <div class="scrollable-table" style="margin: 0;" id="print">
                                        <table class="table-wrapper tb-master tb-master-print">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Export Invoice Number</th>
                                                    <th class="table-col center">Export Invoice Date</th>
                                                    <th class="table-col center">Route</th>
                                                    <th class="table-col center">Destination</th>
                                                    <th class="table-col center">CO Form</th>
                                                    <th class="table-col center">Amount</th>
                                                    <th class="table-col center">Qty Packing</th>
                                                    <th class="table-col center">Qty Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.items;let i=index">
                                                    <td class="table-col center">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.itemRef|docRef:'invoice_number'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.routeRef | docRef : 'name' | async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.countryRef | docRef : 'name' | async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.co_formRef | docRef : 'name' | async}}
                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'amount_FOB' | async | currency:'
                                                        ':'code'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                                        <span
                                                            *ngIf="item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async">

                                                            {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' |
                                                            async}}
                                                        </span>
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                                        <span
                                                            *ngIf="item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async">

                                                            {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' |
                                                            async}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="data?.collection == 'pickup_inspect'">
                                    <div class="report-header" style="padding: 0;">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>


                                            </div>

                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Export Inspect</h3>
                                            </div>
                                        </div>

                                    </div>


                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Inspect Number</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.inspect_number}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Export Inspect Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.inspect_date?.toDate() | date}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">CO Export Invoice</div>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                        </tr>
                                    </table>

                                    <!-- <h4 style="
                                    border-bottom: 1px solid #333;
                                    display: inline-flex;
                                    font-size: 11px;
                                    padding: 4px 0; margin: 0;">Items</h4> -->
                                    <div class="scrollable-table" style="margin: 0;" id="print">
                                        <table class="table-wrapper tb-master tb-master-print">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Export Invoice Number</th>
                                                    <th class="table-col center">Export Invoice Date</th>
                                                    <th class="table-col center">Route</th>
                                                    <th class="table-col center">Destination</th>
                                                    <th class="table-col center">CO Form</th>
                                                    <th class="table-col center">Amount</th>
                                                    <th class="table-col center">Qty Packing</th>
                                                    <th class="table-col center">Qty Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.items;let i=index">
                                                    <td class="table-col center">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.itemRef|docRef:'invoice_number'|async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.routeRef | docRef : 'name' | async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.countryRef | docRef : 'name' | async}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.co_formRef | docRef : 'name' | async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'amount_FOB' | async | currency:'
                                                        ':'code'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                                        <span
                                                            *ngIf="item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async">

                                                            {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' |
                                                            async}}
                                                        </span>
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                                        <span
                                                            *ngIf="item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async">

                                                            {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' |
                                                            async}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="data?.collection == 'register_soo'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <!-- <div class="enf">
                                                    SOO Number:
                                                    <span class="enf">{{data?.item?.soo_number}}</span>
                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.soo_date?.toDate() | date}}</span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Statement Of Origin</h3>
                                            </div>
                                        </div>
                                    </div>


                                    <table class="table-wrapper tb-master tb-master-print">
                                        <thead class="table-header">
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2">No.</th>

                                                <th class="table-col center" *ngIf="soo_info?.length > 0"
                                                    [colSpan]="soo_info?.length">SOO Information</th>
                                                <th class="table-col center" *ngIf="export_info?.length > 0"
                                                    [colSpan]="export_info?.length">
                                                    Export CO And Export Invoice Information
                                                </th>
                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" *ngIf="COLUMNS?.soo_no?.value">SOO NO</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.soo_date?.value">SOO DATE
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                    Amount</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                    Quality Packing</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                    Quality Unit</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.co_no?.value">CO NO</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.co_date?.value">CO DATE
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.invoice_no?.value">INVOICE
                                                    NO</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.invoice_date?.value">
                                                    INVOICE DATE</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.shipping_date?.value">
                                                    Shipping Date</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.amount_fob?.value">Amount
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.quality_ctn?.value">Quality
                                                    Packing</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.quality_pcs?.value">Quality
                                                    Unit</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.destination?.value">
                                                    DESTINATION</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.inspect_no?.value">INSPECT
                                                    NO</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.inspect_date?.value">
                                                    INSPECT DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <ng-container *ngIf="data?.item?.type?.key === 1">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.items;let s=index">
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0">{{s+1}}</td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0 && COLUMNS?.soo_no?.value">
                                                        {{data?.item?.soo_number}}
                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0 && COLUMNS?.soo_date?.value">
                                                        {{data?.item?.soo_date?.toDate() | date}}
                                                    </td>

                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0 && COLUMNS?.soo_amount_fob?.value">
                                                        {{data?.item?.amount | currency:' ':'code'}}
                                                    </td>

                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0 && COLUMNS?.soo_quality_ctn?.value">
                                                        {{data?.item?.quality_cnt | number:'1.' }}
                                                        {{data?.item?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </td>

                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="s == 0 && COLUMNS?.soo_quality_pcs?.value">
                                                        {{data?.item?.quality_pcs | number:'1.' }}
                                                        {{data?.item?.qty_unit_UOMRef | docRef:'code' | async}}

                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.co_no?.value">
                                                        {{item?.itemRef|docRef:'co_number'|async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.co_date?.value">
                                                        {{item?.itemRef | docRefDate:'co_date' | async | date}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.invoice_no?.value">
                                                        {{item?.register_itemRef | docRef: 'invoice_number' | async}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.invoice_date?.value">
                                                        {{item?.register_itemRef | docRefDate: 'invoice_date' | async |
                                                        date}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.shipping_date?.value">
                                                        {{item?.itemRef | docRefDate:'etd' | async | date}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.amount_fob?.value">
                                                        {{item?.register_itemRef | docRef: 'amount_FOB' | async |
                                                        currency:' ':'code'}}

                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_ctn?.value">
                                                        {{item?.register_itemRef | docRef: 'qty_packing' | async |
                                                        number:'1.'}}
                                                        {{item?.uomPackingKey | docRef:'code' | async}}

                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_pcs?.value">
                                                        {{item?.register_itemRef | docRef: 'qty_unit' | async |
                                                        number:'1.'}}

                                                        {{item?.uomUnitRef | docRef:'code' | async}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.destination?.value">
                                                        {{item?.register_itemRef | docDocRef: 'countryRef' : 'name' |
                                                        async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">
                                                        {{item?.inspect_number}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">
                                                        {{item?.inspect_date?.toDate() | date}}
                                                    </td>

                                                </tr>
                                                <tr class="table-row">
                                                    <td class="table-col"
                                                        [colSpan]="(COLUMNS?.soo_no?.value && COLUMNS?.soo_date?.value)?3:(COLUMNS?.soo_no?.value || COLUMNS?.soo_date?.value)?2:1">
                                                        Total:
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                        {{data?.item?.amount | currency:' ':'code'}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                        {{data?.item?.quality_cnt | number:'1.' }}
                                                        {{data?.item?.qty_packing_UOMRef | docRef : 'code' | async}}

                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                        {{data?.item?.quality_pcs | number:'1.' }}
                                                        {{data?.item?.qty_unit_UOMRef | docRef : 'code' | async}}

                                                    </td>
                                                    <td class="table-col right" [colSpan]="total" *ngIf="total">
                                                        Total:
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.amount_fob?.value">
                                                        {{totalAmountFOB | currency:' ':'code'}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_ctn?.value">
                                                        {{totalQtyPacking | number:'1.'}}
                                                        {{data?.item?.qty_packing_UOMRef | docRef : 'code' | async}}

                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_pcs?.value">
                                                        {{totalQtyUnit | number:'1.'}}
                                                        {{data?.item?.qty_unit_UOMRef | docRef : 'code' | async}}

                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.destination?.value">

                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">

                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">

                                                    </td>



                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="data?.item?.type?.key === 2">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.items;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col" *ngIf="COLUMNS?.soo_no?.value">
                                                        {{item?.soo_number}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.soo_date?.value">
                                                        {{item?.soo_date?.toDate() | date}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                        {{item?.amount | currency:' ':'code'}}
                                                    </td>

                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                        {{item?.quality_cnt | number:'1.'}}
                                                        {{item?.qty_packing_UOMRef | docRef:'code' | async}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                        {{item?.quality_pcs | number:'1.'}}
                                                        {{item?.qty_unit_UOMRef | docRef:'code' | async}}
                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 && COLUMNS?.co_no?.value">
                                                        {{data?.item?.co_number}}
                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.co_date?.value">
                                                        {{data?.item?.co_date?.toDate() | date}}

                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.invoice_no?.value">
                                                        {{(data?.item?.invoice_number)}}

                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.invoice_date?.value">
                                                        {{data?.item?.invoice_date?.toDate() | date}}

                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.shipping_date?.value">
                                                        {{data?.item?.etd?.toDate() | date}}

                                                    </td>
                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.amount_fob?.value">
                                                        {{(data?.item?.amount_FOB | currency:' ':'code')}}

                                                    </td>
                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.quality_ctn?.value">
                                                        {{data?.item?.qty_packing | number:'1.'}}
                                                        {{data?.item?.register_itemRef |
                                                        docDocRef:'qty_packing_UOMRef':'code' | async}}

                                                    </td>
                                                    <td class="table-col right" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.quality_pcs?.value">
                                                        {{data?.item?.qty_unit | number:'1.'}}
                                                        {{data?.item?.register_itemRef |
                                                        docDocRef:'qty_unit_UOMRef':'code' | async}}

                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.destination?.value">
                                                        {{data?.item?.countryRef | docRef:'name' | async}}
                                                    </td>

                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.inspect_no?.value">
                                                        {{(data?.item?.inspect_number)}}

                                                    </td>
                                                    <td class="table-col" [rowSpan]="data?.item?.items?.length"
                                                        *ngIf="i == 0 &&  COLUMNS?.inspect_date?.value">
                                                        {{data?.item?.inspect_date?.toDate() | date}}

                                                    </td>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="table-col"
                                                        [colSpan]="(COLUMNS?.soo_no?.value && COLUMNS?.soo_date?.value)?3:(COLUMNS?.soo_no?.value || COLUMNS?.soo_date?.value)?2:1">
                                                        Total:
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                        {{totalAmountFOBSOO | currency:' ':'code'}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                        {{totalQtyCNT | number:'1.'}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                        {{totalQtyPCS | number:'1.'}}
                                                    </td>
                                                    <td class="table-col right" [colSpan]="total" *ngIf="total">
                                                        Total:
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.amount_fob?.value">
                                                        {{(data?.item?.amount_FOB | currency:' ':'code')}}
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_ctn?.value">
                                                        {{data?.item?.qty_packing | number:'1.'}}
                                                        {{data?.item?.register_itemRef |
                                                        docDocRef:'qty_packing_UOMRef':'code' | async}}

                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.quality_pcs?.value">
                                                        {{data?.item?.qty_unit | number:'1.'}}
                                                        {{data?.item?.register_itemRef |
                                                        docDocRef:'qty_unit_UOMRef':'code' | async}}

                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.destination?.value">

                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">

                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">

                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>


                                </ng-container>
                                <ng-container *ngIf="data?.collection == 'register_soo_statement'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <!-- <ng-container *ngIf="selectedTemplate?.top_text; else noText">
                                                    <div [innerHtml]="selectedTemplate?.top_text | safe:'html'"></div>
                                                </ng-container>
                                                <ng-template #noText>
                                                </ng-template> -->
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>

                                                </div>
                                                <div class="enf">

                                                    Reference No.:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.soo_date?.toDate() | date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 12px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?.title ||
                                                    'របាយការណ៍នាំចេញផលិតផលតាមប្រកាសដើមកំណើតទំនិញ'}}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" id="print">
                                        <table class="table-wrapper tb-master tb-master-print">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center" rowspan="2">No.
                                                        <div class="prin-br"></div>
                                                    </th>

                                                    <th class="table-col center" *ngIf="soo_info?.length > 0"
                                                        [colSpan]="soo_info?.length">
                                                        {{selectedTemplate?.soo_info || 'SOO Information'}}
                                                        <div class="prin-br"></div>
                                                    </th>
                                                    <th class="table-col center" *ngIf="export_info?.length > 0"
                                                        [colSpan]="export_info?.length">
                                                        {{selectedTemplate?.export_info || 'Export CO And Export Invoice
                                                        Information'}}
                                                        <div class="prin-br"></div>
                                                    </th>
                                                </tr>
                                                <tr class="table-row">
                                                    <th class="table-col center" *ngIf="COLUMNS?.soo_no?.value">
                                                        {{selectedTemplate?.soo_no || 'SOO NO'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.soo_date?.value">
                                                        {{selectedTemplate?.soo_date || 'SOO DATE'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                        {{selectedTemplate?.soo_amount_fob || 'Amount'}}
                                                    </th>
                                                    <th class="table-col center"
                                                        *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                        {{selectedTemplate?.soo_quality_ctn|| 'Quality Packing'}}
                                                    </th>
                                                    <th class="table-col center"
                                                        *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                        {{selectedTemplate?.soo_quality_pcs|| 'Quality Unit'}}

                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.co_no?.value">
                                                        {{selectedTemplate?.co_no|| 'EXPORT CO NO'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.co_date?.value">
                                                        {{selectedTemplate?.co_date|| 'EXPORT CO DATE'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.invoice_no?.value">
                                                        {{selectedTemplate?.invoice_no|| 'EXPORT INVOICE NO'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.invoice_date?.value">
                                                        {{selectedTemplate?.invoice_date|| 'EXPORT INVOICE DATE'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.shipping_date?.value">
                                                        {{selectedTemplate?.shipping_date|| 'Shipping Date'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.amount_fob?.value">
                                                        {{selectedTemplate?.amount_fob|| 'Amount'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.quality_ctn?.value">
                                                        {{selectedTemplate?.quality_ctn|| 'Quality Packing'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.quality_pcs?.value">
                                                        {{selectedTemplate?.quality_pcs|| 'Quality Unit'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.destination?.value">
                                                        {{selectedTemplate?.destination|| 'DESTINATION'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.inspect_no?.value">
                                                        {{selectedTemplate?.inspect_no|| 'EXPORT INSPECT NO'}}
                                                    </th>
                                                    <th class="table-col center" *ngIf="COLUMNS?.inspect_date?.value">
                                                        {{selectedTemplate?.inspect_date|| 'EXPORT INSPECT DATE'}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <ng-container *ngFor="let array of data?.item?.items;let i=index">
                                                    <ng-container *ngIf="array.type.key === 1">
                                                        <tr class="table-row"
                                                            *ngFor="let item of array?.soo_items;let s=index">
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0">{{i+1}}</td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.soo_no?.value">
                                                                {{array?.itemRef|docRef:'soo_number'|async}}
                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.soo_date?.value">
                                                                {{array?.itemRef | docRefDate:'soo_date' | async |
                                                                date}}
                                                            </td>

                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.soo_amount_fob?.value">
                                                                {{array?.itemRef|docRef:'amount' | async | currency:'
                                                                ':'code'}}

                                                            </td>
                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.soo_quality_ctn?.value">
                                                                {{array?.itemRef|docRef:'quality_cnt' | async |
                                                                number:'1.' }}
                                                                {{array?.itemRef|docDocRef:'qty_packing_UOMRef':'code' |
                                                                async}}
                                                            </td>
                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.soo_quality_pcs?.value">
                                                                {{array?.itemRef|docRef:'quality_pcs' | async |
                                                                number:'1.' }}
                                                                {{array?.itemRef|docDocRef:'qty_unit_UOMRef':'code' |
                                                                async}}

                                                            </td>


                                                            <td class="table-col" *ngIf="COLUMNS?.co_no?.value">
                                                                {{item?.itemRef|docRef:'co_number'|async}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.co_date?.value">
                                                                {{item?.itemRef | docRefDate:'co_date' | async | date}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.invoice_no?.value">
                                                                {{item?.register_itemRef | docRef: 'invoice_number' |
                                                                async}}
                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.invoice_date?.value">
                                                                {{item?.register_itemRef | docRefDate: 'invoice_date' |
                                                                async | date}}
                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.shipping_date?.value">
                                                                {{item?.itemRef | docRefDate:'etd' | async | date}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.amount_fob?.value">
                                                                {{item?.register_itemRef | docRef: 'amount_FOB' | async
                                                                | currency:' ':'code'}}

                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_ctn?.value">
                                                                {{item?.register_itemRef | docRef: 'qty_packing' | async
                                                                | number:'1.'}}
                                                                {{item?.uomPackingKey|docRef:'code' | async}}

                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_pcs?.value">
                                                                {{item?.register_itemRef | docRef: 'qty_unit' | async |
                                                                number:'1.'}}
                                                                {{item?.uomUnitRef|docRef:'code' | async}}


                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.destination?.value">
                                                                {{item?.register_itemRef | docDocRef: 'countryRef' :
                                                                'name' | async}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">
                                                                {{item?.inspect_number}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">
                                                                {{item?.inspect_date?.toDate() | date}}
                                                            </td>

                                                        </tr>
                                                        <tr class="table-row">
                                                            <td class="table-col right"
                                                                [colSpan]="(COLUMNS?.soo_no?.value && COLUMNS?.soo_date?.value)?3:(COLUMNS?.soo_no?.value || COLUMNS?.soo_date?.value)?2:1">
                                                                Total:
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                                {{array?.itemRef|docRef:'amount' | async | currency:'
                                                                ':'code'}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                                {{array?.itemRef|docRef:'quality_cnt' | async |
                                                                number:'1.' }}
                                                                {{array?.itemRef | docDocRef:'qty_packing_UOMRef':'code'
                                                                | async}}

                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                                {{array?.itemRef|docRef:'quality_pcs' | async |
                                                                number:'1.' }}
                                                                {{array?.itemRef | docDocRef:'qty_unit_UOMRef':'code' |
                                                                async}}

                                                            </td>
                                                            <td class="table-col right" [colSpan]="total" *ngIf="total">
                                                                Total:
                                                            </td>

                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.amount_fob?.value">
                                                                {{array?.amount_FOB | currency:' ':'code'}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_ctn?.value">
                                                                {{array?.qty_packing | number:'1.'}}
                                                                {{array?.itemRef | docDocRef:'qty_packing_UOMRef':'code'
                                                                | async}}

                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_pcs?.value">
                                                                {{array?.qty_unit | number:'1.'}}
                                                                {{array?.itemRef | docDocRef:'qty_unit_UOMRef':'code' |
                                                                async}}

                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.destination?.value">

                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">

                                                            </td>

                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">

                                                            </td>

                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="array.type.key === 2">
                                                        <tr class="table-row"
                                                            *ngFor="let item of array?.soo_items;let s=index">
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0">{{i+1}}</td>
                                                            <td class="table-col" *ngIf="COLUMNS?.soo_no?.value">
                                                                {{item?.soo_number}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.soo_date?.value">
                                                                {{item?.soo_date?.toDate() | date}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                                {{item?.amount | currency:' ':'code'}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                                {{item?.quality_cnt | number:'1.'}}
                                                                {{item?.qty_packing_UOMRef|docRef:'code' | async}}

                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                                {{item?.quality_pcs | number:'1.'}}
                                                                {{item?.qty_unit_UOMRef|docRef:'code' | async}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.co_no?.value">
                                                                {{array?.itemRef | docRef: 'co_number' | async}}
                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.co_date?.value">
                                                                {{(array?.itemRef | docRef: 'co_date' | async)?.toDate()
                                                                | date}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.invoice_no?.value">
                                                                {{(array?.itemRef | docRef: 'invoice_number' | async)}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.invoice_date?.value">
                                                                {{(array?.itemRef | docRef: 'invoice_date' |
                                                                async)?.toDate() | date}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.shipping_date?.value">
                                                                {{(array?.itemRef | docRef: 'etd' | async)?.toDate() |
                                                                date}}

                                                            </td>
                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.amount_fob?.value">
                                                                {{(array?.itemRef | docRef: 'amount_FOB' | async |
                                                                currency:' ':'code')}}

                                                            </td>
                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.quality_ctn?.value">
                                                                {{array?.itemRef | docRef: 'qty_packing' | async |
                                                                number:'1.'}}
                                                                {{array?.itemRef |
                                                                docDocDocRef:'register_itemRef':'qty_packing_UOMRef':'code'
                                                                | async}}

                                                            </td>
                                                            <td class="table-col right"
                                                                [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.quality_pcs?.value">
                                                                {{array?.itemRef | docRef: 'qty_unit' | async |
                                                                number:'1.'}}
                                                                {{array?.itemRef|docDocDocRef:'register_itemRef':'qty_unit_UOMRef':'code'
                                                                | async}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.destination?.value">
                                                                {{array?.itemRef | docDocRef: 'countryRef' : 'name' |
                                                                async}}
                                                            </td>

                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.inspect_no?.value">
                                                                {{(array?.itemRef | docRef: 'inspect_number' | async)}}

                                                            </td>
                                                            <td class="table-col" [rowSpan]="array?.soo_items?.length"
                                                                *ngIf="s == 0 && COLUMNS?.inspect_date?.value">
                                                                {{(array?.itemRef | docRef: 'inspect_date' |
                                                                async)?.toDate() | date}}

                                                            </td>
                                                        </tr>
                                                        <tr class="table-row">
                                                            <td class="table-col right"
                                                                [colSpan]="(COLUMNS?.soo_no?.value && COLUMNS?.soo_date?.value)?3:(COLUMNS?.soo_no?.value || COLUMNS?.soo_date?.value)?2:1">
                                                                Total:
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_amount_fob?.value">
                                                                {{array?.amount | currency:' ':'code'}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_ctn?.value">
                                                                {{array?.quality_cnt | number:'1.'}}
                                                                {{array?.soo_items[0]?.qty_packing_UOMRef|docRef:'code'
                                                                | async}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.soo_quality_pcs?.value">
                                                                {{array?.quality_pcs | number:'1.'}}
                                                                {{array?.soo_items[0]?.qty_unit_UOMRef|docRef:'code' |
                                                                async}}
                                                            </td>
                                                            <td class="table-col right" [colSpan]="total" *ngIf="total">
                                                                Total:
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.amount_fob?.value">
                                                                {{(array?.itemRef | docRef: 'amount_FOB' | async |
                                                                currency:' ':'code')}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_ctn?.value">
                                                                {{array?.itemRef | docRef: 'qty_packing' | async |
                                                                number:'1.'}}
                                                                {{array?.itemRef |
                                                                docDocDocRef:'register_itemRef':'qty_packing_UOMRef':'code'
                                                                | async}}
                                                            </td>
                                                            <td class="table-col right"
                                                                *ngIf="COLUMNS?.quality_pcs?.value">
                                                                {{array?.itemRef | docRef: 'qty_unit' | async |
                                                                number:'1.'}}
                                                                {{array?.itemRef |
                                                                docDocDocRef:'register_itemRef':'qty_unit_UOMRef':'code'
                                                                | async}}
                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.destination?.value">

                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_no?.value">

                                                            </td>
                                                            <td class="table-col" *ngIf="COLUMNS?.inspect_date?.value">

                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="text-align: right; margin-top: 18px; margin-right: 33px;">
                                        <p class="enf bold">
                                            {{selectedTemplate?.footerSignature || 'Authorizer Signature and Stamp'}}
                                        </p>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_invoice'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'CO Import Invoice'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export To</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.export_to}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CAT</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.cat?data?.item?.cat:'' | number:'1.'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Country</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Material</div>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">Quantity</th>
                                                    <th class="table-col center">Cost</th>
                                                    <th class="table-col center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                    <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>



                                <ng-container *ngIf="data?.collection == 'register_import_invoice'">

                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'Customs Import
                                                    Invoice'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Country</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export To</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.export_to}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CAT</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.cat?data?.item?.cat:'' | number:'1.'}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Customs Material</div>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col center">Master List No.</th>
                                                    <th class="table-col center">Master List Date</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">Quantity</th>
                                                    <!-- <th class="table-col center">Currency</th> -->
                                                    <th class="table-col center">Unit Price</th>
                                                    <th class="table-col center">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>

                                                    <td class="table-col">{{item?.masterList?.masterId}}</td>
                                                    <td class="table-col">{{item?.masterList?.approvedDate?.toDate() |
                                                        date}}</td>
                                                    <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qty | number : '1.4-4'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.price | number : '1.4-4'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.totalCost | number : '1.4-4'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qty_NW | number:'1.4-4'}}
                                                        {{item?.qty_NW_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qty_GW | number:'1.4-4'}}
                                                        {{item?.qty_GW_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                                <tr class="table-row">
                                                    <td class="table-col right" colspan="6">
                                                        Total
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.item?.material | sumAmount:'qty') | number: '1.4-4'}}
                                                    </td>
                                                    <td class="table-col">

                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.item?.material | sumAmount:'totalCost') | number :
                                                        '1.4-4'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.item?.material | sumAmount:'qty_NW') | number:
                                                        '1.4-4'}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{ (data?.item?.material | sumAmount:'qty_GW') | number:
                                                        '1.4-4'}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </ng-container>


                                <ng-container *ngIf="data?.collection == 'register_local_invoice'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'Local Purchase
                                                    Invoice'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Purchase Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Purchase Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export To</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.export_to}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CAT</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.cat?data?.item?.cat:'' | number:'1.'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Country</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Material</div>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">Quantity</th>
                                                    <th class="table-col center">Cost</th>
                                                    <th class="table-col center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                    <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_reapply_item'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Re-apply Invoice
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_date?.toDate() | date}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Shipping Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.etd?.toDate() | date}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> ADM Fee (KHR)</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.admFee | number:'1.'}}
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name"> EMF Fee (KHR)</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.emfFee | number:'1.'}}
                                                </td>

                                            </tr>


                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Form</div>
                                                </td>
                                                <td>
                                                    :
                                                    {{data?.item?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">CAT</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.cat | number:'1.'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Amount</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.amount_FOB | currency:' ':'code'}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Destination</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>

                                            </tr>


                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Packing</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_packing | number:'1.'}}
                                                    <span
                                                        *ngIf="(data?.item?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                                                        {{data?.item?.qty_packing_UOMRef | docRef : 'code' | async}}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Unit</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_unit | number:'1.'}}
                                                    <span
                                                        *ngIf="(data?.item?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                                                        {{data?.item?.qty_unit_UOMRef | docRef : 'code' | async}}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Material</div>

                                                </td>
                                                <td>
                                                    :
                                                </td>
                                            </tr>
                                        </table>

                                        <!-- <h4 style="
                                        border-bottom: 1px solid #333;
                                        display: inline-flex;
                                        font-size: 11px;
                                        padding: 4px 0; margin: 0;">Material</h4> -->
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <!-- <th class="table-col center">UOM</th> -->
                                                    <th class="table-col center">Quantity</th>

                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <!-- <td class="table-col"></td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number:'1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_duplicate_item'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;"> Duplication Invoice
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_date?.toDate() | date}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Shipping Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.etd?.toDate() | date}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> ADM Fee (KHR)</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.admFee | number:'1.'}}
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name"> EMF Fee (KHR)</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.emfFee | number:'1.'}}
                                                </td>

                                            </tr>


                                            <tr>
                                                <td>
                                                    <div class="pml-name"> CO Form</div>
                                                </td>
                                                <td>
                                                    :
                                                    {{data?.item?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">CAT</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.cat | number:'1.'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Amount</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.amount_FOB | currency:' ':'code'}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Destination</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>

                                            </tr>


                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Packing</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_packing | number:'1.'}}
                                                    <span
                                                        *ngIf="(data?.item?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                                                        {{data?.item?.qty_packing_UOMRef | docRef : 'code' | async}}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Unit</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_unit | number:'1.'}}
                                                    <span
                                                        *ngIf="(data?.item?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                                                        {{data?.item?.qty_unit_UOMRef | docRef : 'code' | async}}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Material</div>

                                                </td>
                                                <td>
                                                    :
                                                </td>
                                            </tr>
                                        </table>

                                        <!-- <h4 style="
                                        border-bottom: 1px solid #333;
                                        display: inline-flex;
                                        font-size: 11px;
                                        padding: 4px 0; margin: 0;">Material</h4> -->
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col ">No.</th>
                                                    <th class="table-col ">Material Code</th>
                                                    <th class="table-col ">Material Name</th>
                                                    <th class="table-col ">UOM</th>
                                                    <th class="table-col ">Quantity</th>

                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="data?.collection == 'register_ob_stock'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">
                                                    Refer No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>
                                                </div>
                                                <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span class="enf">{{data?.item?.application_date?.toDate() |
                                                            date}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">OB Stock</h3>
                                            </div>
                                        </div>
                                        <!-- <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>



                                        </table> -->

                                        <h4 style="
                                        display: inline-flex;
                                        font-size: 11px;
                                        margin: 0;">{{selectedItem?.text}}</h4>


                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <!-- <th class="table-col ">UOM</th> -->
                                                    <th class="table-col center">Quantity</th>

                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.materials;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <!-- <td class="table-col"></td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number:'1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_adjustment_stock'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">
                                                    Refer No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>
                                                </div>
                                                <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span class="enf">{{data?.item?.application_date?.toDate() |
                                                            date}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Adjustment Over</h3>
                                            </div>
                                        </div>
                                        <!-- <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>



                                        </table> -->

                                        <h4 style="
                                        display: inline-flex;
                                        font-size: 11px;
                                       margin: 0;">{{selectedItem?.text}}</h4>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <!-- <th class="table-col ">UOM</th> -->
                                                    <th class="table-col center">Quantity</th>

                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.materials;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <!-- <td class="table-col"></td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number:'1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <!-- <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td> -->
                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_adjustment_stock_under'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">
                                                    Refer No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>
                                                </div>
                                                <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span class="enf">{{data?.item?.application_date?.toDate() |
                                                            date}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Adjustment Under
                                                </h3>
                                            </div>
                                        </div>
                                        <!-- <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>



                                        </table> -->

                                        <h4 style="
                                        display: inline-flex;
                                        font-size: 11px; margin: 0;">{{selectedItem?.text}}</h4>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Material Code</th>
                                                    <th class="table-col center">Material Name</th>
                                                    <!-- <th class="table-col ">UOM</th> -->
                                                    <th class="table-col center">Quantity</th>

                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.materials;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <!-- <td class="table-col"></td> -->
                                                    <td class="table-col right">
                                                        {{item?.qty | number:'1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <!-- <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td> -->
                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>



                                <ng-container *ngIf="data?.collection == 'register_master_list'">

                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Register Master List
                                                </h3>
                                            </div>
                                        </div>

                                        <h4 style="
                                        display: inline-flex;
                                        font-size: 11px; margin: 0;">Master List Item</h4>
                                    </div>
                                    <table class="pitem-table">
                                        <tr>
                                            <td>
                                                <div class="pml-name">Master list ID:</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.masterId}}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="pml-name">Approved Date:</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.approvedDate?.toDate() | date}}
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">From Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.fromDate?.toDate() | date}}
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="pml-name">To Date</div>
                                            </td>
                                            <td>
                                                : {{data?.item?.toDate?.toDate() | date}}
                                            </td>

                                        </tr>


                                    </table>
                                    <!-- <div class="form-contain" *ngIf="data?.item?.material?.length > 0" style="margin-top: 20px;">
                                        <div class="scrollable-table" style="margin: 0;" id="print">
                                            <table class="table-wrapper tb-master">
                                                <thead class="table-header">
                                                    <tr class="table-row">
                                                        <th class="table-col center">No.</th>
                                                        <th class="table-col center">Material Code</th>
                                                        <th class="table-col center">Material Name</th>
                                                        <th class="table-col center">Model / Country</th>
                                                        <th class="table-col center">Serial No.</th>
                                                        <th class="table-col center">New/Used</th>
                                                        <th class="table-col center">Unit</th>
                                                        <th class="table-col center">Quantity</th>
                                                        <th class="table-col center">Unit Price</th>
                                                        <th class="table-col center">Total Price</th>
                                                        <th class="table-col center">Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-body">
                                                    <tr *ngFor="let item of data?.item?.material;let i=index">
                                                        <td class="table-col">{{i+1}}</td>
                                                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                        <td class="table-col ">{{item?.countryRef|docRef:'name'|async}}</td>
                                                        <td class="table-col ">{{item?.serialNo}}</td>
                                                        <td class="table-col ">{{item?.isNew?'New':'Used'}}</td>
                                                        <td class="table-col ">{{item?.uom?.code}}</td>
                                                        <td class="table-col right">{{item?.qty}}</td>
                                                        <td class="table-col right">{{item?.unitPrice|currency}}</td>
                                                        <td class="table-col right">{{item?.total|currency}}</td>
                                                        <td class="table-col ">{{item?.Remarks}}</td>
                                                    </tr>
                                                    <tr class="table-row" *ngIf="data?.item?.material?.length>0">
                                                        <td class="table-col" colspan="9">
                                                            <strong>Total:</strong>
                                                        </td>
                                                        <td class="table-col right">
                                                            {{data?.item?.material| sumItem: 'total' | currency}}
                                                        </td>
                                                        <td class="table-col "></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> -->

                                </ng-container>

                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space"></div>

                            <div class="page-footer">
                                <div class="d-flex print-date">
                                    <div class="enf"
                                        style="margin-right: 12px; color:#555; font-size: 8px !important; text-align: left;">
                                        <ng-container *ngIf="!selectedTemplate">
                                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address'">
                                            </app-company-field>
                                        </ng-container>
                                        <ng-container *ngIf="selectedTemplate">
                                            {{selectedTemplate?.address_en}}
                                        </ng-container>
                                    </div>

                                    <div class="flex-1"></div>
                                    <div class="enf" style="margin-right: 12px; font-size: 8px !important;">
                                        {{data?.item?.refNo}}
                                    </div>
                                    <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}
                                    </div>

                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>

            </table>




        </div>

    </div>
    <div class="dialog-footer" style="padding: 24px;">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"><span>Cancel</span></a>
        <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
            style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
            <mat-icon class="mat-18">print</mat-icon>
            Print
        </button>
    </div>
    <!-- <app-spinner *ngIf="loading"></app-spinner> -->
</form>