import { Router } from '@angular/router';
import { DataService } from './../services/data.service';
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { pushToObject } from '../services/convert.service';
import { MappingService, dateRefNo, objectToArrayData } from '../services/mapping.service';
import { IpServiceService } from '../services/ip.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class Auth {
  @observable user: any = null;
  @observable userRoles: any = null;
  @observable remember: boolean = false;
  @observable profile: any = null;
  @observable uid: string = null;
  @observable token: string = null;
  @observable data: any = [];
  @observable isLogged: boolean = false;
  @observable process: boolean = false;
  @observable loading: boolean = true;
  @observable company: Array<any> = [];
  @observable filterCompany: Array<any> = [];
  @observable selectedCompany: any = null;
  @observable statistic: any = null;
  @observable histories: Array<any> = [];
  @observable COLUMNS_ARRAY: Array<any> = [];
  @observable public error = null;
  @observable public displayName = null;
  @observable public expansions = null;
  isLoginByOtherDevice: boolean = false;
  subscription: Subscription;
  subscriptionUser: Subscription;
  subscriptionUserRole: Subscription;
  getIPAddress;
  constructor(
    private ds: DataService,
    private router: Router,
    public auth: AuthService,
    private deviceService: DeviceDetectorService,
    private dialogRef: MatDialog
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  @action
  async fetchRefNo(companyKey, fieldRefNo) {
    const doc = await this.ds.companyRef().doc(companyKey).get().toPromise();
    const config = pushToObject(doc)[fieldRefNo];
    const { index, prefix } = config;
    const format = '000';
    const no = index + 1;
    const suffix = format.substring(0, format.length - `${no}`.length) + `${no}`
    return `${prefix}${dateRefNo()}${suffix}`
  }

  @action
  getIdToken(callback) {
    firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
      callback(idToken)
    }).catch(function (error) {
      callback(error)
    });
  }


  @action
  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;
    this.auth.authRef().signInWithEmailAndPassword(user.email, oldPassword).then((account) => {
      user.updatePassword(newPassword).then(() => {
        callback(true, null)
      }).catch(error => {
        alert(error)
        callback(false, error)
      })
    }).catch(error => {
      alert(error)
      callback(false, error)
    })
  }
  @action
  async fetchCanActive() {
    this.loading = true;
    this.auth.afsAuthRef().authState.subscribe(async user => {
      this.loading = true;
      this.user = user;
      if (this.user) {
        this.displayName = user.displayName;
        this.user = {
          key: user.uid,
          name: user.displayName,
          full_name: user.displayName,
          email: user.email,
          uid: user.uid,
          displayName: user.displayName,
        }
        this.subscriptionUser = this.ds.userprofileRef().doc(user.uid).valueChanges().subscribe((list: any) => {
          if (list) {
            const { company, selected_company } = list;
            this.profile = list;
            if (company) {
              let mm = [];
              company.forEach(data => {
                data.get().then(doc => {
                  mm = mm.concat(doc.data())
                  const cm = mm.filter(f => f.status.key === 1)
                  this.company = MappingService.orderBy(cm, 'name_en');
                  this.filterCompany = MappingService.orderBy(cm, 'name_en');
                })
              });
            }
            this.selectedCompany = selected_company;
          }
        })
        this.subscriptionUserRole = this.ds.userprofileRef().doc(user.uid).collection('roles').doc(user.uid).valueChanges().subscribe(data => {
          if (data) {
            this.userRoles = data;
          }
        })
        this.loading = false;

      }
      else {
        this.loading = false
      }
    })
  }

  @action
  async fetchUserCallback(callback) {
    this.loading = true;
    await this.auth.afsAuthRef().onAuthStateChanged(async user => {
      this.loading = true;
      if (user) {
        this.ds.userprofileRef().doc(user.uid).valueChanges().subscribe((list: any) => {
          if (list) {
            const { selected_company } = list
            this.profile = list;
            this.selectedCompany = selected_company;
          }
        })
        this.ds.userprofileRef().doc(user.uid).collection('roles').doc(user.uid).valueChanges().subscribe(data => {
          if (data) {
            this.userRoles = data;
            callback(this.userRoles)
          }
        })
        this.loading = false;
      }
      else {
        this.loading = false
      }
    })
  }

  @action
  fetchColumns(fieldName: string, callback) {
    const { uid } = firebase.auth().currentUser;
    this.COLUMNS_ARRAY = [];
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.ds.accountRef().doc(uid).collection(`columns`).doc(fieldName).valueChanges().subscribe(doc => {
      const column = MappingService.orderBy(objectToArrayData(doc), 'key')
      this.COLUMNS_ARRAY = column
      callback(doc);
    })
  }

  @action
  async fetchSearch(text: any, callback) {
    if (text && typeof text == 'string') {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      this.company = this.company.filter(f => f.keyword >= search || f.keyword == end)
    } else {
      this.company = this.filterCompany;
    }
  }

  @action
  async fetchProfile() {
    const { uid } = firebase.auth().currentUser;;
    if (uid) {
      const docs = await this.ds.userprofileRef().doc(uid).get().toPromise();
      const doc = pushToObject(docs);
      const { selected_company } = doc;
      this.selectedCompany = selected_company;
      return doc;
    }
    return null;
  }

  @action
  deleteHistory(menuKey) {
    this.ds.userprofileRef().doc(this.user.uid).collection("histories").doc(menuKey).delete().then(() => { })
  }

  @action
  async fetchStatistic() {
    const doc = await this.ds.environmentRef().get().toPromise();
    this.statistic = pushToObject(doc);
    return this.statistic;
  }

  @action
  async fetchEnvironment() {
    const doc = await this.ds.environmentRef().get().toPromise()
    return pushToObject(doc);
  }

  @action
  async checkExists(collection: any, companyKey, key: any) {
    const doc = await this.ds.dbRef().collection('company').doc(companyKey).collection(collection).doc(key).get().toPromise()
    return pushToObject(doc);
  }

  @action
  updateColumn(item, collection?) {
    const { type, value } = item;
    this.ds.accountRef().doc(this.user.uid).collection('columns').doc(collection).update({
      [type]: {
        ...item,
        value: !value
      },
    })
  }

  @action
  fetchUserCompany(callback) {
    this.loading = true;
    this.subscription = this.ds.companyRef().valueChanges().subscribe(data => {
      this.data = data;
      this.loading = false;
      callback(this.data)
    })
  }

  @action
  saveSelectedCompany(company: any, callback) {
    this.process = true;
    localStorage.removeItem("selected_company");
    const data = {
      selected_company: company,
    }
    this.ds.userprofileRef().doc(this.user.uid).update(data).then(data => {
      localStorage.setItem("selected_company", JSON.stringify(company, getCircularReplacer()))
      this.process = false;
      callback(true, null);
    }).catch((error) => {
      callback(false, error)
      this.process = false;
    })
  }
  @action
  async fetchSelectedCompany() {
    const company = localStorage.getItem("selected_company");
    if (company) this.selectedCompany = JSON.parse(company);
  }

  @action
  async signIn(form: any, callback) {
    const { email, password, remember } = form;
    this.process = true;
    // this.getIPAddress = await this.ip.getIPAddress().toPromise();

    this.auth.authRef().signInWithEmailAndPassword(email, password).then(async user => {
      if (user) {
        this.user = user.user;
        const deviceInfo = this.deviceService.getDeviceInfo();
        this.ds.userprofileRef().doc(user.user.uid).update(
          {
            session: {
              refreshToken: user.user.refreshToken,
              ip: this.getIPAddress?.ip || null,
              ...deviceInfo,
            }
          }
        )

        this.router.navigate(['/login/choose-company'])
        this.process = false;
      }
      else {
        this.error = "Invalid email and password.";
        this.process = false;
      }
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  @action
  signOut(callback) {
    this.auth.signOut().then(() => {
      this.company = null;
      this.selectedCompany = null;
      localStorage.removeItem("selected_company");
      this.subscriptionUser && this.subscriptionUser.unsubscribe();
      this.subscriptionUserRole && this.subscriptionUserRole.unsubscribe();
      callback(true, null);
      this.dialogRef.closeAll();
      this.process = false;
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }


  @action
  fetchCurrentUser() {
    return this.auth.authRef().currentUser;
  }

  @action
  getUser() {
    const { uid, displayName, email, phoneNumber, photoURL } = firebase.auth().currentUser;
    return {
      key: uid,
      displayName,
      email,
      phoneNumber,
      photoURL,
      uid,
    }
  }

  @action
  resetPassword(email) {
    return this.auth.authRef().sendPasswordResetEmail(email);
  }

}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
