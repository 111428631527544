export const FILE_TYPE = {
    'application/vnd.ms-excel': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fexcel.svg?alt=media&token=145e6a2f-3451-4e86-bf5d-681c01e38d8d',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fexcel.svg?alt=media&token=145e6a2f-3451-4e86-bf5d-681c01e38d8d',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fword.svg?alt=media&token=eb23daee-d238-4ada-b2fe-b3cd11f80c74',
    'application/msword': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fword.svg?alt=media&token=eb23daee-d238-4ada-b2fe-b3cd11f80c74',
    'application/pdf': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fpdf.svg?alt=media&token=d7a5750b-410d-43e1-8bdc-17a698bfa3b8',
    'application/vnd.ms-powerpoint': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fpowerpoint.svg?alt=media&token=4f013852-a5a3-4335-9e83-c82c3cfae044',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fpowerpoint.svg?alt=media&token=4f013852-a5a3-4335-9e83-c82c3cfae044',
    'application/zip': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fzip.svg?alt=media&token=64eb3098-fe78-4e2c-b8c3-552fbfa1f30a',
    'application/x-compress': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fzip.svg?alt=media&token=64eb3098-fe78-4e2c-b8c3-552fbfa1f30a',
    'application/x-zip-compressed': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fzip.svg?alt=media&token=64eb3098-fe78-4e2c-b8c3-552fbfa1f30a',
    'application/x-7z-compressed': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fzip.svg?alt=media&token=64eb3098-fe78-4e2c-b8c3-552fbfa1f30a',
    'application/vnd.rar': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fzip.svg?alt=media&token=64eb3098-fe78-4e2c-b8c3-552fbfa1f30a',
    'application/rtf': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Ftxt.svg?alt=media&token=cd09de93-39d6-4f6c-af2f-f044908a3f78',
    'video/x-ms-wmv': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fvideo.svg?alt=media&token=0208165e-1c19-4632-bddb-4f67977c86ed',
    'video/mp4': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fvideo.svg?alt=media&token=0208165e-1c19-4632-bddb-4f67977c86ed',
    'video/x-msvideo': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fvideo.svg?alt=media&token=0208165e-1c19-4632-bddb-4f67977c86ed',
    'video/webm': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fvideo.svg?alt=media&token=0208165e-1c19-4632-bddb-4f67977c86ed',
    'video/ogg': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fvideo.svg?alt=media&token=0208165e-1c19-4632-bddb-4f67977c86ed',
    'audio/ogg': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Faudio-file.svg?alt=media&token=7bad472e-801b-490b-aa59-03e9abe708c7',
    'audio/aac': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Faudio-file.svg?alt=media&token=7bad472e-801b-490b-aa59-03e9abe708c7',
    'audio/mp3': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Faudio-file.svg?alt=media&token=7bad472e-801b-490b-aa59-03e9abe708c7',
    'audio/webm': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Faudio-file.svg?alt=media&token=7bad472e-801b-490b-aa59-03e9abe708c7',
    'audio/wav': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Faudio-file.svg?alt=media&token=7bad472e-801b-490b-aa59-03e9abe708c7',
    'application/x-msdownload': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fexe-file.svg?alt=media&token=dbbd3f4d-c192-4362-9f20-da7404d10f9b',
    'application/x-javascript': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fjs.svg?alt=media&token=705e76b9-d8e9-4f9d-b270-5e0e3a9bfba1',
    'application/javascript': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fjs.svg?alt=media&token=705e76b9-d8e9-4f9d-b270-5e0e3a9bfba1',
    'application/ecmascript': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fjs.svg?alt=media&token=705e76b9-d8e9-4f9d-b270-5e0e3a9bfba1',
    'text/javascript': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fjs.svg?alt=media&token=705e76b9-d8e9-4f9d-b270-5e0e3a9bfba1',
    'text/ecmascript': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fjs.svg?alt=media&token=705e76b9-d8e9-4f9d-b270-5e0e3a9bfba1',
    'application/epub+zip': 'https://firebasestorage.googleapis.com/v0/b/doccamwin.appspot.com/o/file_icons%2Fepub%20(1).svg?alt=media&token=4e792e16-f6b2-4772-a62d-0980a133e061'
}