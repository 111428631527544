import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Outstanding } from 'src/app/stores/register/outstanding.store';
import { Auth } from 'src/app/stores/auth.store';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-outstanding-detail',
  templateUrl: './outstanding-detail.component.html',
  styleUrls: ['./outstanding-detail.component.scss']
})
export class OutstandingDetailComponent implements OnInit {
  subscriptionRoute: Subscription;

  constructor(
    public store: Outstanding,
    private route: ActivatedRoute,
    private location: Location,
    private auth: Auth,
    private snackBar: MatSnackBar,

    
  ) { }

  ngOnInit() {
    this.subscriptionRoute = this.route.params.subscribe(param => {
      if (param) {
        const { id } = param;
        this.store.fetchOutstanding(id, this.auth.selectedCompany.key);
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptionRoute && this.subscriptionRoute.unsubscribe();
  }

  goBack() {
    this.location.back();
  }

  updateOutstanding(item){
    this.store.updateOutstanding(item?.key,item?.companyKey, success=>{
      this.snackBar.open("Register CO outstanding has been update successful.", "Done", { duration: 100 });
    })
  }

}
