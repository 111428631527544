<div class="company-container">
    <div class="cbar-top" style="justify-content: flex-start;">
        <img class="logo" src="../../../../assets/images/camwinlogo.jpg" alt="" style="width: 126px;">

        <!-- <h1 class="bname">CAM<span>win</span>-BCO</h1> -->
    </div>
    <div class="company-selection" *ngIf="!auth?.loading">
        <div class="lgrhd">
            <h3 class="title">CO Document Management</h3>
            <p class="desc">Please choose your company to start your transaction!</p>
        </div>

        <div class="form-wrapper" [formGroup]="form">
            <div class="form-group column" style="margin-bottom: 24px;">

                <mat-form-field appearance="outline">
                    <mat-label>Select company</mat-label>
                    <input type="text" matInput [formControl]="company" [matAutocomplete]="auto" autocomplete="off">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayItem">
                        <mat-option *ngFor="let item of auth?.company | findCompany:company?.value" [value]="item"
                            class="cs-mat">
                            <div class="cs-select-list">
                                <div class="logo">
                                    <span class="icon x-hidden-focus">
                                        <svg style="display:none;visibility:hidden;">
                                            <defs>
                                                <symbol id="mc-exceed-fs-o" viewBox="0 0 36 36">
                                                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0"
                                                        y="0" width="36" height="36">
                                                        <rect width="36" height="36" fill="#C4C4C4"></rect>
                                                    </mask>
                                                    <g mask="url(#clip0)">
                                                        <path
                                                            d="M17.88 7.08014L19.2923 9.93128L22.3923 10.4122L20.1189 12.645L20.67 15.8053L17.88 14.2939L15.1245 15.8053L15.6411 12.645L13.4023 10.4122L16.5023 9.93128L17.88 7.08014Z"
                                                            fill="#007869"></path>
                                                        <path
                                                            d="M25.4233 20.6145C27.7311 18.5534 29.1778 15.5649 29.1778 12.2672C29.1778 6.04962 24.1144 1 17.88 1C11.6456 1 6.61667 6.04962 6.61667 12.2328C6.61667 15.5649 8.06333 18.5534 10.3711 20.5802L3 30.4046H9.61333L12.92 37L17.88 23.5L22.84 37L26.1467 30.4046H32.76L25.4233 20.6145ZM23.6667 17.1794C22.2544 18.7595 20.1878 19.7557 17.9144 19.7557C15.6411 19.7557 13.5744 18.7595 12.1622 17.1794C12.0244 17.0076 11.8522 16.8359 11.7144 16.6641C11.5767 16.4924 11.4389 16.2863 11.3356 16.1145C10.8533 15.3244 10.5089 14.4656 10.3367 13.5382C10.2333 13.0573 10.1989 12.6107 10.1989 12.0954C10.1989 11.0992 10.4056 10.1718 10.75 9.27863C11.8867 6.42748 14.6767 4.40076 17.9144 4.40076C19.7744 4.40076 21.4967 5.05343 22.8056 6.15267C24.5278 7.56107 25.63 9.69084 25.63 12.0954C25.63 13.126 25.4233 14.1221 25.0444 15.0153C24.9411 15.2901 24.8033 15.5649 24.6311 15.8397C24.3556 16.3206 24.0111 16.7672 23.6667 17.1794Z"
                                                            fill="#3C3C41"></path>
                                                    </g>
                                                </symbol>
                                            </defs>
                                        </svg>
                                        <svg>
                                            <use xlink:href="#mc-exceed-fs-o"></use>
                                        </svg>
                                    </span>
                                </div>
                                <div class="info">
                                    <p class="info-item name">{{item?.name_en}} &nbsp;</p>
                                    <p class="info-item desc">{{item?.address}} &nbsp;</p>
                                    <p class="info-item desc">{{item?.telephone_number}}</p>
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="d-flex">
                <button mat-button class="mat-puc cancel" (click)="goBack()" style="margin-right: 12px">
                    <mat-icon class="mat-18" style="margin-right: 0px;">
                        arrow_back_ios
                    </mat-icon>
                    <span>
                        Back
                    </span>
                </button>
                <app-primary-button [disable]="!form.valid" (onPress)="onSelectedCompany()" [process]="auth?.process"
                    label="Continue">
                </app-primary-button>


<!-- 
                <a mat-button class="mat-puc cancel" (click)="resoleItem(form.value)">
                    <span>
                        resoleItem
                    </span>
                </a> -->
            </div>
        </div>
    </div>
    <app-placeholder *ngIf="auth?.loading"></app-placeholder>
</div>