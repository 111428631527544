<table class="add-item-table">
    <tr class="add-item-table-row" >
        <td class="add-item-table-col"  style="width: 256px;">
            <input #focusInput1 matInput autofocus="off">
        </td>
        <td class="add-item-table-col" >
            <input #focusInput2 matInput autofocus="off">
        </td>
        <td class="add-item-table-col"  style="width: 168px;">
            <input #focusInput3 matInput autofocus="off">
        </td>
    </tr>
</table>