import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-field',
  templateUrl: './company-field.component.html',
  styleUrls: ['./company-field.component.scss']
})
export class CompanyFieldComponent implements OnInit {
  @Input() key;
  @Input() feild;
  data;
  SubscriptionRef: Subscription = null;

  
  constructor(private afs: AngularFirestore) { }
  ngOnInit() {
    this.SubscriptionRef = this.afs.collection('company').doc(this.key).valueChanges().subscribe(doc => {
      this.data = doc;
    })
  }
  ngOnDestroy(): void {
    this.SubscriptionRef && this.SubscriptionRef.unsubscribe();
  }

}
