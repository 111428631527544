<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="title">
            Reset password
        </div>
        <div class="flex1"></div>
        <button mat-button mat-icon-button>
            <mat-icon aria-label="close" (click)="dialogRef.close('no')">close</mat-icon>
        </button>
    </div>
    <div class="dialog-content">

        <form [formGroup]="form" class="form-wrapper">
            <div class="form-group column">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput required placeholder="email" cdkFocusInitial type="email" formControlName="email">
                    <mat-error *ngIf="form.controls['email'].hasError('required')">
                        Email is required.
                    </mat-error>
                    <mat-error *ngIf="form.controls['email'].hasError('email')">Email is invalid.</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="flex1"></div>
        
        <button mat-dialog-close class="enbutton cancel m-r16">Cancel</button>
        <!-- <button type="submit" class="primary-button-fill"  
            (click)="create(form.value)">OK</button> -->
            <app-primary-button class="primary-button-fill" cdkFocusInitial (onPress)="create(form.value)" [process]="auth?.process"
            label="Reset"></app-primary-button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>

</div>