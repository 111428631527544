import { Component, OnInit } from '@angular/core';
import { fileFolder } from 'src/app/dummy/fileFolder';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  fileFolders;
  subscriptionRoute: Subscription;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.subscriptionRoute = this.route.params.subscribe(param => {
      const f = fileFolder.filter(f => f.key === param.app)
      this.fileFolders = f[0].item
    })
  }
  ngOnDestroy(): void {
    this.subscriptionRoute&&this.subscriptionRoute.unsubscribe();
  }

}
