import { Pipe, PipeTransform } from '@angular/core';
import { ConvertService } from '../services/convert.service';

@Pipe({
  name: 'sumCutStockItemQty'
})
export class SumCutStockItemQtyPipe implements PipeTransform {
  async transform(data: Array<any>): Promise<any> {

    let total = 0;
    if (data && data.length > 0) {
      for (const item of data) {
        const d = await item.registerItemRef.get()
        const { qty_unit } = d.data();
        total += ConvertService.toNumber(qty_unit);
      }
      return total;
    }
  }

}
