<form class="dialog-wrapper form-dialog-wrapper">
  <div class="dialog-header">
    <!-- <div class="title">
                    Print Preview
                </div> -->

    <ng-container *ngIf="data?.collection === 'register_submit_form'">
      <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
        aria-label="Print">
        <mat-icon>view_module</mat-icon>
      </button>

      <mat-menu #menuColumn="matMenu">

        <app-column-visible collection="Submit_Column_Print" [data]="COLUMNS">
        </app-column-visible>
      </mat-menu>
    </ng-container>


    <ng-container *ngIf="data?.collection == 'register_cancel_co'">
      <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="">
        <mat-icon>more_vert</mat-icon>
        {{selectedItem?.text}}
      </button>
      <mat-menu #menuCountry="matMenu">
        <ng-container *ngFor="let item of cancelCoPrintLists;let i=index">
          <button mat-menu-item [class.active]="selectedItem?.key === item?.key" (click)="_onSelectedItem(item)">
            <mat-icon>dialpad</mat-icon>
            <span>{{item?.text}}</span>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>


    <ng-container *ngIf="data?.collection == 'register_submit_form'">
      <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="">
        <mat-icon>more_vert</mat-icon>
        {{selectedItem?.text}}
      </button>
      <mat-menu #menuCountry="matMenu">
        <ng-container *ngFor="let item of submitFormPrintLists;let i=index">
          <button mat-menu-item [class.active]="selectedItem?.typeKey === item?.typeKey" (click)="_onSelectedItem(item)">
            <mat-icon>dialpad</mat-icon>
            <span>{{item?.text}}</span>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>

    <div class="flex1"></div>

    <a mat-dialog-close class="close-dialog" (click)="close()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content report-wrapper" id="printms">
    <div style="flex:1; display: flex; flex-direction: column;">

      <ng-container *ngIf="data?.collection === 'register_submit_form'">
        <table style="width: 100%;">

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="title-info" style="margin-top: 18px;">

                        <div>ក្រសួងពាណិជ្ជកម្ម</div>
                        <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                        <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                        <div class="star">************</div>
                      </div>
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">

                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; top:58px; right: 14px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold khf">
                        <h3>
                          កំណត់បង្ហាញ
                        </h3>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">


                      <ng-container *ngIf="data?.item?.submitType?.key == 1">
                        <div class="khf" style="text-align: center;">

                          <p style="margin-bottom: 6px;">

                            {{selectedItem?.typeKey === 1 ? 'ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ' : 'ការនាំចេញវិញ្ញាបនបត្របញ្ជាក់ដើមកំណើតទំនិញតាមប្រព័ន្ធ'}}
                            <strong class="enf">ONLINE
                              <span *ngIf="selectedItem.key === 2">(UK)</span>
                            </strong>
                          </p>

                        </div>
                      </ng-container>
                      <ng-container *ngIf="data?.item?.submitType?.key == 2">
                        <div class="khf" style="text-align: center;">
                          <p class="khf">
                            ស្ដីពីក្រុមហ៊ុនប្រកាសការនាំចេញ
                          </p>
                          <p class="khf" style="margin-bottom: 6px;">

                            {{selectedItem?.typeKey === 3 ? 'ដោយមិនស្នើសុំវិញ្ញាបនបត្របញ្ជាក់ដើមកំណើតទំនិញតាមប្រព័ន្ធ' : 'ដោយមិនស្នើសុំវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ'}}

                            <strong class="enf">ONLINE</strong>
                          </p>
                        </div>
                      </ng-container>
                    </div>
                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">

                        <div class="">
                          <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                          <span class="enf bold">
                            <app-company-field [key]="auth?.selectedCompany?.key"
                              [feild]="'name_en'"></app-company-field>
                          </span>

                        </div>



                        <ng-container *ngIf="data?.item?.submitType?.key == 1">
                          <div class="khf">
                            <strong class="bold">កម្មវត្ថុ:</strong>
                            ស្នើសុំ
                            <span class="enf">C/O Form
                              "{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                            ចំនួន
                            <span class="enf">
                              {{data?.item?.registerCoItems?.length}}
                            </span>
                            ច្បាប់នាំចេញ
                            {{data?.item?.productTypeRef | docRef:'name_kh' | async}}
                            <span class="enf">
                              BY ({{data?.item?.routeRef | docRef:'name' | async}})
                            </span>
                          </div>

                        </ng-container>
                        <ng-container *ngIf="data?.item?.submitType?.key == 2">
                          <div class="khf">
                            <strong class="bold khf">កម្មវត្ថុ:</strong>
                            ស្នើសុំ
                            <span class="enf">CRF</span>
                            <span class="enf">
                              BY {{data?.item?.routeRef | docRef:'name' | async}}
                            </span>
                            ចំនួន
                            <span class="enf">
                              {{data?.item?.registerCoItems?.length}}
                            </span>
                            ច្បាប់ នូវមុខទំនិញ
                            {{data?.item?.productTypeRef | docRef:'name_kh' | async}}
                            <!-- <span class="enf">
                                                            BY ({{data?.item?.routeRef | docRef:'name' | async}})
                                                        </span> -->
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header" style="margin-bottom: 0;">
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2" *ngIf="COLUMNS?.co_reference?.value"> CO Reference</th>
                        <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.quantity?.value">
                          <div style="text-align: center;">
                            Quality Unit
                          </div>
                        </th>
                        <th class="table-col " rowspan="2" *ngIf="COLUMNS?.amount?.value">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>
                        <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.cat?.value">
                          CAT</th>
                        <th class="table-col center" colspan="2" *ngIf="COLUMNS?.invoice?.value"> Invoice</th>
                        <th class="table-col center" colspan="2" *ngIf="COLUMNS?.joint_inspection?.value "> Joint
                          Inspection</th>
                        <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.bill_no?.value"> Bill No.</th>
                        <th class="table-col center" rowspan="2" *ngIf="COLUMNS?.destination?.value"> Destination</th>
                        <th class="table-col center" [colSpan]="COLUMNS?.adm_fee?.value && COLUMNS?.emf_fee?.value?2:1"
                          *ngIf="COLUMNS?.adm_fee?.value || COLUMNS?.emf_fee?.value"> Service
                          Fee</th>
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center" *ngIf="COLUMNS?.co_reference?.value">No
                        </th>
                        <th class="table-col center" *ngIf="COLUMNS?.co_reference?.value">Date
                        </th>
                        <th class="table-col center" *ngIf="COLUMNS?.invoice?.value">No</th>
                        <th class="table-col center" *ngIf="COLUMNS?.invoice?.value">Date</th>
                        <th class="table-col center" *ngIf="COLUMNS?.joint_inspection?.value">No
                        </th>
                        <th class="table-col center" *ngIf="COLUMNS?.joint_inspection?.value">
                          Date</th>
                        <th class="table-col center" *ngIf="COLUMNS?.adm_fee?.value">ADM Fee
                        </th>
                        <th class="table-col center" *ngIf="COLUMNS?.emf_fee?.value">EMF Fee
                        </th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col " *ngIf="COLUMNS?.co_reference?.value">
                          {{item?.co_number}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.co_reference?.value">
                          {{item?.co_date?.toDate() | date}}
                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.quantity?.value">
                          {{item?.qty_unit | number:'1.'}}
                          {{item?.uomUnitRef | docRef:'code' | async}}

                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.amount?.value">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td>
                        <td class="table-col center" *ngIf="COLUMNS?.cat?.value">
                          {{item?.cat | number: '1.'}}

                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.invoice?.value">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.invoice?.value">
                          {{item?.invoice_date?.toDate() | date}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value">
                          {{item?.inspect_number}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value">
                          {{item?.inspect_date?.toDate() | date}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.bill_no?.value">
                          {{item?.bill_number}}</td>
                        <td class="table-col " *ngIf="COLUMNS?.destination?.value">
                          {{item?.countryRef | docRef:'name' | async }}
                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.adm_fee?.value">
                          {{item?.admFee | number:'1.'}}
                          <span *ngIf="(item?.admFee)"> <span class="reil">៛</span></span>
                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.emf_fee?.value">
                          {{item?.emfFee | number:'1.'}}
                          <span *ngIf="(item?.emfFee)"> <span class="reil">៛</span></span>
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center" [colSpan]="COLUMNS?.co_reference?.value?3:1">
                          <strong class="bold">Total:</strong>
                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.quantity?.value">
                          {{detail?.totalQuantity| number:'1.'}}
                          {{dataUOM?.uomUnitRef | docRef:'code' | async}}

                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.amount?.value">
                          {{detail?.totalAmount| currency:' ':'code'}}
                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.cat?.value">

                        </td>
                        <td class="table-col " *ngIf="COLUMNS?.invoice?.value"></td>
                        <td class="table-col " *ngIf="COLUMNS?.invoice?.value"></td>
                        <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value"></td>
                        <td class="table-col " *ngIf="COLUMNS?.joint_inspection?.value"></td>
                        <td class="table-col " *ngIf="COLUMNS?.bill_no?.value"></td>
                        <td class="table-col " *ngIf="COLUMNS?.destination?.value"></td>
                        <td class="table-col right" *ngIf="COLUMNS?.adm_fee?.value">
                          {{detail?.totalADMFee | number:'1.'}} <span class="reil">៛</span>
                        </td>
                        <td class="table-col right" *ngIf="COLUMNS?.emf_fee?.value">
                          {{detail?.totalEMFFee | number:'1.'}} <span class="reil">៛</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="owe">
                    ច្រកចេញចូលតែមួយ
                  </div>

                  <table class="table-wrapper tb-master fx-tb-master" *ngIf="data?.item?.submitType?.key == 1">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <div class="checkbox-custom"></div>
                          <strong class="bold">ឯកឧត្តម/ លោកជំទាវរដ្ឋលេខាធិការ/
                            អនុរដ្ឋលេខាធិការ</strong>
                          <div class="checkbox-custom"></div>
                          <strong class="bold">អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</strong>
                          <div class="checkbox-custom"></div>
                          <strong class="bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                        </div>

                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" style="height:20px; vertical-align: bottom;">
                        <div class="d-flex">
                          <div>កាលបរិច្ឆេទ</div>
                          <div class="dot"></div>
                          <div>-ម៉ោងចូល</div>
                          <div class="dot"></div>

                        </div>
                      </td>
                      <td class="table-col" rowspan="2" style="height: 60px;"
                        [style.height]="selectedItem.key !== 2 ? '60px' : '168px'">
                        <div class="d-flex" style="padding-top: 18px;">
                          <div>ហត្ថលេខា/ ហត្ថលេខាខ្លី</div>
                          <div class="dot"></div>
                          <div class="flex-1"></div>
                        </div>
                      </td>
                    </tr>

                    <tr class="table-row">
                      <td class="table-col" style="height:22px; vertical-align: bottom;">
                        <div class="d-flex">
                          <div>កាលបរិច្ឆេទ</div>
                          <div class="dot"></div>
                          <div>-ម៉ោងចេញ</div>
                          <div class="dot"></div>

                        </div>
                      </td>
                    </tr>
                    <ng-container *ngIf="selectedItem.key === 1">
                      <tr class="table-row">
                        <td class="table-col center" colspan="2" style="padding: 0 !important;">
                          <strong class="bold">បោះត្រា</strong>
                        </td>
                      </tr>

                      <tr class="table-row">
                        <td class="table-col" style="height: 60px;">
                          <div class="d-flex">
                            <div>កាលបរិច្ឆេទ</div>
                            <div class="dot"></div>
                            <div>-ម៉ោងចូល</div>
                            <div class="dot"></div>
                          </div>
                        </td>
                        <td class="table-col" style="height: 60px;">
                          <div class="d-flex" style="padding-top: 18px;">
                            <div>ហត្ថលេខា/ ហត្ថលេខាខ្លី</div>
                            <div class="dot"></div>
                            <div class="flex-1"></div>

                          </div>
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col" style="height: 60px;">
                          <div class="d-flex">
                            <div>កាលបរិច្ឆេទ</div>
                            <div class="dot"></div>
                            <div>-ម៉ោងចេញ</div>
                            <div class="dot"></div>
                          </div>
                        </td>
                        <td class="table-col" style="height: 60px;">
                          <div class="d-flex" style="padding-top: 18px;">
                            <div>ហត្ថលេខា/ ហត្ថលេខាខ្លី</div>
                            <div class="dot"></div>
                            <div class="flex-1"></div>

                          </div>
                        </td>
                      </tr>
                    </ng-container>




                    <tr class="table-row">
                      <td class="table-col center" colspan="2" style="padding: 0 !important;">
                        <strong class="bold">អ្នកទទួល <span class="enf">C/O</span></strong>
                      </td>
                    </tr>

                    <tr class="table-row">
                      <td class="table-col" style="height: 60px;"
                        [style.height]="selectedItem.key !== 2 ? '60px' : '168px'">
                        <div class="d-flex">
                          <div class="d-flex" style="align-items: center;">
                            <span>កាលបរិច្ឆេទ</span>
                            <span>-ម៉ោងអ្នកទទួល</span>
                            <span class="enf" style="margin-left: 8px;">C/O</span>
                          </div>
                          <div class="dot"></div>
                          <div class="flex-1"></div>

                        </div>
                      </td>
                      <td class="table-col" style="height: 60px;"
                        [style.height]="selectedItem.key !== 2 ? '60px' : '168px'">
                        <div class="d-flex" style="padding-top: 18px;">
                          <div>ហត្ថលេខា និង ឈ្មោះ</div>
                          <div class="dot"></div>
                          <div class="flex-1"></div>

                        </div>
                      </td>
                    </tr>

                  </table>
                  <table class="table-wrapper tb-master fx-tb-master" *ngIf="data?.item?.submitType?.key == 2">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <!-- <strong class="bold">យោបល់នាយកដ្ឋាន</strong> -->
                          <strong class="bold">
                            <span *ngIf="selectedItem.key === 2">
                              យោបល់នាយកដ្ឋាន
                            </span>
                            <span *ngIf="selectedItem.key !== 2">
                              <span [matMenuTriggerFor]="comment">{{slComment}}</span>
                              <mat-menu #comment="matMenu">
                                <button mat-menu-item *ngFor="let c of slCommentLists"
                                  (click)="slCommentChange(c)">{{c}}</button>
                              </mat-menu>
                            </span>
                          </strong>

                        </div>
                      </td>
                    </tr>
                    <td class="table-col" colspan="2"
                      style="height: 288px;text-align: center; vertical-align: baseline;">
                      <!-- <strong class="bold">បានឃើញ</strong> -->
                    </td>

                  </table>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

        <div class="page-footer" style="border-top: none;">
          <div class="d-flex">
            <div class="flex-1"></div>
            <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
            </div>
            <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_cancel_co'">
        <table>

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="title-info" style="margin-top: 18px;">
                        <div>ក្រសួងពាណិជ្ជកម្ម</div>
                        <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                        <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                        <div class="star">************</div>
                      </div>
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; top:58px; right: 14px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold khf">
                        <h3>កំណត់បង្ហាញ</h3>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div class="khf" style="text-align: center;">
                        <p style="margin-bottom: 6px;">
                          ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ
                          <strong class="enf">ONLINE</strong>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">

                        <div class="">
                          <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                          <span class="enf">
                            <!-- {{auth?.selectedCompany?.name_en}} -->
                            <app-company-field [key]="auth?.selectedCompany?.key"
                              [feild]="'name_en'"></app-company-field>

                          </span>
                        </div>
                        <div class="khf">
                          <strong class="bold">កម្មវត្ថុ:</strong>
                          ស្នើសុំ
                          <span class="enf">Cancel C/O Form
                            "{{data?.item?.co_formRef | docRef:'name' | async}}"
                          </span>
                          <span class="enf">
                            BY ({{data?.item?.routeRef | docRef:'name' | async}})
                          </span>
                          ចំនួន
                          <span class="enf">
                            {{data?.item?.registerCoItems?.length}}
                          </span>
                          ច្បាប់ ។


                        </div>



                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header" style="margin-bottom: 0;">
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2"> CO Reference</th>
                        <th class="table-col center" rowspan="2">
                          <div style="text-align: center;">
                            Quantity
                            <div>(UOM)</div>
                          </div>
                        </th>
                        <th class="table-col " rowspan="2">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>

                        <th class="table-col center" colspan="2"> Invoice
                        </th>

                        <th class="table-col center" rowspan="2"> Destination
                        </th>

                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">Date
                        </th>
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.co_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.co_date?.toDate() | date}}
                        </td>
                        <td class="table-col right">
                          {{item?.qty_unit | number:'1.'}}

                          {{item?.uomUnitRef | docRef:'code' | async}}

                        </td>
                        <td class="table-col right">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td>

                        <td class="table-col ">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.invoice_date?.toDate() | date}}
                        </td>


                        <td class="table-col ">
                          {{item?.countryRef | docRef:'name' | async }}
                        </td>

                      </tr>
                      <tr class="table-row">
                        <td class="table-col center" [colSpan]="COLUMNS?.co_reference?.value?3:1">
                          <strong class="bold">Total:</strong>
                        </td>
                        <td class="table-col right">
                          {{detail?.totalQuantity| number:'1.'}}
                          {{dataUOM?.uomUnitRef | docRef:'code' | async}}

                        </td>
                        <td class="table-col right">
                          {{detail?.totalAmount| currency:' ':'code'}}
                        </td>

                        <td class="table-col "></td>
                        <td class="table-col "></td>

                        <td class="table-col "></td>

                      </tr>
                    </tbody>
                  </table>
                  <div class="owe">ច្រកចេញចូលតែមួយ</div>

                  <table class="table-wrapper tb-master fx-tb-master">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">នាយកដ្ឋាន នាំចេញ-នាំចូល</strong>
                        </div>
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="2" style="text-align: center;
                                            vertical-align: baseline;"
                        [style.height]="selectedItem.key !== 2 ? '128px' : '256px'">
                        <span>គួរអនុញ្ញាតលុប
                          CO តាមសំណើរក្រុមហ៊ុន
                          ។</span>
                      </td>
                    </tr>

                    <tr class="table-row" *ngIf="selectedItem.key !== 2">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <ng-container *ngIf="selectedItem.key !== 3">
                            <div class="checkbox-custom"></div>
                            <strong class="bold">ឯកឧត្តមរដ្ឋលេខាធិការ</strong>
                            <div class="checkbox-custom"></div>
                            <strong class="bold">ឯកឧត្តមអនុរដ្ឋលេខាធិការ</strong>
                          </ng-container>

                          <ng-container *ngIf="selectedItem.key == 2">
                            <div class="checkbox-custom">
                            </div>
                            <strong class="bold">ឯកឧត្តមអគ្គនាយកដ្ឋានសេវាកម្មពាណិជ្ជកម្ម</strong>
                          </ng-container>
                          <ng-container *ngIf="selectedItem.key == 3">

                            <strong class="bold">អគ្គនាយកដ្ឋានសេវាកម្មពាណិជ្ជកម្ម</strong>
                          </ng-container>

                        </div>

                      </td>
                    </tr>

                    <td class="table-col" colspan="2" style="height: 128px;" *ngIf="selectedItem.key !== 2">

                    </td>

                  </table>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

        <div class="page-footer" style="border-top: none;">
          <div class="d-flex">
            <div class="flex-1"></div>
            <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
            </div>
            <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
          </div>
        </div>

      </ng-container>

    </div>
  </div>
  <div class="dialog-footer" style="padding: 24px;">
    <div class="flex-1"></div>
    <a mat-button class="mat-puc cancel" (click)="close()"><span>Cancel</span></a>
    <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
      style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
      <mat-icon class="mat-18">print</mat-icon>
      Print
    </button>
  </div>
  <app-spinner *ngIf="loading"></app-spinner>
</form>
