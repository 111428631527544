import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth } from 'src/app/stores/auth.store';
import { CommentStore } from 'src/app/stores/comment.store';
import { FileManagerStore } from 'src/app/stores/filemanager.store';

@Component({
  selector: 'app-file-view-lists',
  templateUrl: './file-view-lists.component.html',
  styleUrls: ['./file-view-lists.component.scss']
})
export class FileViewListsComponent implements OnInit {
  @Input() collection:string;
  @Input() key:string;
  @Input() companyKey:any;
  @Input() data: any;

  constructor(
    public cm: CommentStore,
    public auth: Auth,
    public fm: FileManagerStore,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    if(this.companyKey){
      this.cm.fetchFileByCompany(this.collection, this.companyKey, this.key, data => { });
    }else{
      this.cm.fetchFile(this.collection, this.key, data => {});
    }
    
  }


  deleteFile(file) {
    if(this.companyKey){
      this.fm.deleteFileDataByCompany(this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, file, (success, res) => {
        if (success)
          this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
      })
    }else{
      if (this.collection == 'company' || this.collection == 'users') {
        this.fm.deleteFileDataC(this.collection == 'company' ? this.data.name_en : this.auth.selectedCompany.name_en, this.collection, this.collection == 'company' ? this.data.key : this.auth.selectedCompany.key, file, (success, res) => {
          if (success)
            this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
        })
      } else {
        this.fm.deleteFileData(this.collection, file, (success, res) => {
          if (success)
            this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
        })
      }
    }

  }

}
