

import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  processClose: boolean;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    // public auth: AuthStore,
    public auth: Auth,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirm: [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    })
  }

  onSave(f: any) {
    if (this.form.valid) {
      const { password, confirm, oldPassword } = f;
      if (password !== confirm) {
        alert('New Password and Confirm Password do not match');
        return;
      }
      this.form.disable();
      this.auth.changePassword(oldPassword, password, (success, error) => {
        if (success) {
          this.snackBar.open("Password has been changed successfully.", "Done", { duration: 3000 })
          this.dialogRef.close('no')
        }
        this.form.enable()
      })
    }
  }
}


@Component({
  selector: 'avatar',
  template: `
  <img [src]="user?.fileurl || defultImg" alt="" alt="user">
  `
})
export class AvatarComponent implements OnInit {
  @Input() userKey: any;
  defultImg = 'https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c'
  subscription: Subscription;
  user: any;
  constructor(
    private db: DataService,
  ) { }
  ngOnInit() {

  }
  ngAfterViewInit(): void {

    this.subscription = this.db.userDocRef(this.userKey).valueChanges().subscribe(user => {
      this.user = user;
    })
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe()
  }
}
