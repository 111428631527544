import { Component, OnInit } from '@angular/core';
import { OptionStore } from 'src/app/stores/options.store';
import { tabs } from 'src/app/dummy/tabs';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MappingService } from 'src/app/services/mapping.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {
  tabs = tabs.options;
  form: FormGroup;
  crfCode: AbstractControl;
  department: AbstractControl;

  filteredStates: any;
  filteredDepartmentStates: any;
  constructor(public store: OptionStore,
    private db: DataService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private fun: AngularFireFunctions,
    // private afMessaging: AngularFireMessaging

  ) { }

  async ngOnInit() {

    this.form = this.fb.group({
      crfCode: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
      department: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],

    })
    this.crfCode = this.form.controls["crfCode"]
    this.department = this.form.controls["department"]
    this.filteredStates = await this.store.fetchItem(this.db.coformRef());
    this.filteredDepartmentStates = await this.store.fetchItem(this.db.departmentRef());

    this.store.fetchData(doc => {
      const { crfCodeKey, departmentKey } = doc;
      if (crfCodeKey) {
        const crf = this.filteredStates.filter(m => m.key === crfCodeKey)[0];
        this.form.patchValue({
          crfCode: crf
        })
      }
      if (departmentKey) {
        const department = this.filteredDepartmentStates.filter(m => m.key === departmentKey)[0];
        this.form.patchValue({
          department: department
        })
      }
    });

  }

  displayItem(item: any) {
    return item ? item.name_en : item;
  }

  displayItemName(item: any) {
    return item ? `${item.code} - ${item.name}` : item;
  }

  create(f: any) {
    const { crfCode, department } = f;
    const item = {
      crfCodeKey: crfCode ? crfCode.key : null,
      crfCodeRef: crfCode ? this.db.coformRef().doc(crfCode.key).ref : null,
      departmentKey: department ? department.key : null,
      departmentRef: department ? this.db.departmentRef().doc(department.key).ref : null,
    }
    this.store.saveOptions(item, (success, res) => {
      this.snackBar.open("Options has been saved successful.", "Done", { duration: 100 });
    })
  }

  subscribelocalInv() {

  }
  // listen() {
  //   this.afMessaging.messages.subscribe((message) => { console.log('a', message); });
  // }

  // async subscribeTopic(topic, token): Promise<void> {
  //   const success = await this.fun.httpsCallable('subscribeToTopic')({ topic, token }).toPromise();
  //   console.log('success', success);
  // }

  // requestPermission(): void {
  //   this.afMessaging.requestToken.subscribe(
  //     (token) => {
  //       console.log('Permission granted!', token);
  //       this.subscribeTopic("TESTING_NOTIFICATION", token);
  //       this.listen()
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }
}
