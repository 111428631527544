
export const coColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'CO Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Export CO Number',
    collection: '',
    field: 'co_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Export CO Date',
    collection: '',
    field: 'co_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },




  {
    key: 7,
    name: 'Export Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 8,
    name: 'Export Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Destination',
    collection: '',
    field: 'countryName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 6,
    name: 'Route',
    collection: '',
    field: 'routeName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const coReplyColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'CO Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 3,
    name: 'Set',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 6,
    name: 'Route',
    collection: '',
    field: 'routeName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Joint Inspection (Set)',
    collection: '',
    field: 'inspectSet',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 7,
    name: 'Bill Leading (Set)',
    collection: '',
    field: 'billSet',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },



  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const returnAppColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'CO Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 3,
    name: 'Set',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 6,
    name: 'Route',
    collection: '',
    field: 'routeName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 5,
    name: 'Joint Inspection (Set)',
    collection: '',
    field: 'inspectSet',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 7,
    name: 'Bill Leading (Set)',
    collection: '',
    field: 'billSet',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  



  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const localSaleAppColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Invoice (Set)',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const localDeliverCoAppColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 3,
    name: 'Set',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]
export const localDeliverReturnAppColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 3,
    name: 'Invoice (Set)',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const localDeliverExportAppColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Reference No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  
  {
    key: 3,
    name: 'Joint Inspect (Set)',
    collection: '',
    field: 'inspectSet',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 3,
    name: 'Invoice (Set)',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]


export const submitColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Set',
    collection: '',
    field: 'set',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isLengthField: true,
    isDateField: false,
    isFilter: false
  },
  {
    key: 6,
    name: 'Route',
    collection: '',
    field: 'routeName',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key:7,
    name: 'Total Service Fee',
    collection: '',
    field: 'totalFee',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isSumField: true,
    isFilter: false
  },
  {
    key: 8,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 9,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const sooColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'SOO Number',
    collection: '',
    field: 'soo_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'SOO Date',
    collection: '',
    field: 'soo_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Export CO Number',
    collection: '',
    field: 'co_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Export CO Date',
    collection: '',
    field: 'co_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const sooStatementColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'soo_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const cutStockColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false,
    width: 50,
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false,
    isShortDateField: true,
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]
export const cutStocklocalDeliveryColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 4,
    name: 'Set',
    collection: '',
    field: 'registerCoItems',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isLengthField: true,
    isFilter: false
  },


  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const userColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Name',
    collection: '',
    field: 'full_name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Email',
    collection: '',
    field: 'email',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Phone Number',
    collection: '',
    field: 'phone',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 5,
    name: 'Position',
    collection: '',
    field: 'position',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 6,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 7,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]

export const userRoleColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Title',
    collection: '',
    field: 'title',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Description',
    collection: '',
    field: 'description',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]


export const stockColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 3,
    name: 'Released Date',
    collection: '',
    field: 'release_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]


export const letterColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 3,
    name: 'Memo',
    collection: '',
    field: 'memo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]



export const stockDeliveryColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Reference No.',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'application_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]

export const departmentColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Department Name (EN)',
    collection: '',
    field: 'name_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Department Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]
export const coformColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'CO Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'CO Name',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const uomColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'UOM Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'UOM (EN)',
    collection: '',
    field: 'description_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'UOM (KH)',
    collection: '',
    field: 'description_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]
export const inventoryColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Name',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'UOM',
    collection: '',
    field: 'uomName',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {

    key: 4,
    name: 'Stock Qty',
    collection: '',
    field: 'stockQty',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const companyColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Name (EN)',
    collection: '',
    field: 'name_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Phone Number',
    collection: '',
    field: 'telephone_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 5,
    name: 'Email',
    collection: '',
    field: 'email',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 6,
    name: 'Address',
    collection: '',
    field: 'address',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 7,
    name: 'TIN No.',
    collection: '',
    field: 'tin_no',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  
  {
    key: 8,
    name: 'Country',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 9,
    name: 'Postal Code',
    collection: '',
    field: 'postal_code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 10,
    name: 'Fax Number',
    collection: '',
    field: 'fax_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 11,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 12,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]

export const countryColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Country Code',
    collection: '',
    field: 'code',
    orderBy: 'name',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Country Name',
    collection: '',
    field: 'name',
    orderBy: 'name',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Department',
    collection: '',
    field: 'department',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const countryPortColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Country Port Code',
    collection: 'settings_country_port',
    field: 'code',
    orderBy: 'name',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Country Port Name',
    collection: 'settings_country_port',
    field: 'name',
    orderBy: 'name',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Country',
    collection: 'settings_country',
    field: 'country',
    orderBy: 'name',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const forwarderColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Name (EN)',
    collection: '',
    field: 'name_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Short Name (EN)',
    collection: '',
    field: 'short_name_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 5,
    name: 'Short Name (KH)',
    collection: '',
    field: 'short_name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 6,
    name: 'Email',
    collection: '',
    field: 'email',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 7,
    name: 'Address',
    collection: '',
    field: 'address',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 8,
    name: 'Country',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 9,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 10,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]
export const routeColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Route Name (EN)',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Route Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]
export const productTypeColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Product Name (EN)',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Product Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const titleColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Title Name (EN)',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Title Name (KH)',
    collection: '',
    field: 'name_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]
export const currencyColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Currency Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Currency Name',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Currency Symbol',
    collection: '',
    field: 'symbol',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isLengthField: false,
    isFilter: false
  },


  {
    key: 5,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 6,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const hsCodeColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Description (EN)',
    collection: '',
    field: 'description_en',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Description (KH)',
    collection: '',
    field: 'description_kh',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'CD',
    collection: '',
    field: 'cd',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 6,
    name: 'ST',
    collection: '',
    field: 'st',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 7,
    name: 'VAT',
    collection: '',
    field: 'vat',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 8,
    name: 'ET',
    collection: '',
    field: 'et',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },



  {
    key: 10,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 11,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },





]

export const materialColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Code',
    collection: '',
    field: 'code',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Name',
    collection: '',
    field: 'name',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Status',
    collection: '',
    field: 'status',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: true,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]
export const ImportInvColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Import Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Import Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Cat',
    collection: '',
    field: 'cat',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Import Country',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]



export const ImportStockMovementColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Application No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'refDate',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 3,
    name: 'Release Date',
    collection: '',
    field: 'release_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Application Type',
    collection: '',
    field: 'transactionType',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },


  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Import Qty (N.W)',
    collection: '',
    field: 'qty',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isUpdateByField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Balance',
    collection: '',
    field: 'cutStock_balance',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isUpdateByField: false,
    isDateField: false,
    isFilter: false
  },

]



export const CutStockMovementColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Application No',
    collection: '',
    field: 'refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'refDate',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  
  {
    key: 2,
    name: 'Application Type',
    collection: '',
    field: 'transactionType',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 5,
    name: 'Import Qty (N.W)',
    collection: '',
    field: 'qty',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isUpdateByField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Application No',
    collection: '',
    field: 'cutStock_refNo',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Application Date',
    collection: '',
    field: 'cutStock_refDate',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  
  

  {
    key: 3,
    name: 'Release Date',
    collection: '',
    field: 'release_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 2,
    name: 'Application Type',
    collection: '',
    field: 'allocated_transactionType',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 5,
    name: 'Cut Stock Qty (N.W)',
    collection: '',
    field: 'allocated_qty',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isUpdateByField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 5,
    name: 'Balance',
    collection: '',
    field: 'cutStock_balance',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isUpdateByField: false,
    isDateField: false,
    isFilter: false
  },

]


export const LocalImportInvColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Local Purchase Invoice',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Local Purchase Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Cat',
    collection: '',
    field: 'cat',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Local Purchase Country',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]


export const ImportInvInfoColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Import Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Import Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Permit Number',
    collection: '',
    field: 'permit_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Permit Date',
    collection: '',
    field: 'permit_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 2,
    name: 'Custom Number',
    collection: '',
    field: 'custom_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Custom Date',
    collection: '',
    field: 'custom_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'CamControl Number',
    collection: '',
    field: 'camControl_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'CamControl Date',
    collection: '',
    field: 'camControl_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Bill Number',
    collection: '',
    field: 'bill_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Application Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]

export const outStandingImportInvInfoColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Import Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Import Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Cat',
    collection: '',
    field: 'cat',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Permit Number',
    collection: '',
    field: 'permit_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Permit Date',
    collection: '',
    field: 'permit_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 2,
    name: 'Custom Number',
    collection: '',
    field: 'custom_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Custom Date',
    collection: '',
    field: 'custom_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'CamControl Number',
    collection: '',
    field: 'camControl_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'CamControl Date',
    collection: '',
    field: 'camControl_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Bill Number',
    collection: '',
    field: 'bill_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Application Form',
    collection: '',
    field: 'coFormName',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Destination',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]


export const LocalImportInvInfoColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Local Purchase Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Local Purchase Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Permit Number',
    collection: '',
    field: 'permit_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Permit Date',
    collection: '',
    field: 'permit_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 2,
    name: 'Custom Number',
    collection: '',
    field: 'custom_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Custom Date',
    collection: '',
    field: 'custom_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'CamControl Number',
    collection: '',
    field: 'camControl_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'CamControl Date',
    collection: '',
    field: 'camControl_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Bill Number',
    collection: '',
    field: 'bill_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 2,
    name: 'Application Form',
    collection: '',
    field: 'coForm',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]



export const ExportInvColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'CO Form',
    collection: '',
    field: 'coForm',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Export Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Export Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Amount',
    collection: '',
    field: 'amount_FOB',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Destination',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Route',
    collection: '',
    field: 'route',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]



export const LocalExportInvColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'CO Form',
    collection: '',
    field: 'coForm',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Local Sale Invoice Number',
    collection: '',
    field: 'invoice_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Local Sale Invoice Date',
    collection: '',
    field: 'invoice_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 2,
    name: 'Amount',
    collection: '',
    field: 'amount_FOB',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  
  {
    key: 2,
    name: 'Destination',
    collection: '',
    field: 'country',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Route',
    collection: '',
    field: 'route',
    orderBy: '',
    isRefField: true,
    isTextField: false,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },

  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },



]


export const billColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Export Bill Number',
    collection: '',
    field: 'bill_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Export Bill Date',
    collection: '',
    field: 'bill_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]


export const inspectColumn = [
  {
    key: 1,
    name: 'No',
    collection: '',
    field: 'rowIndex',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 2,
    name: 'Export Inspect Number',
    collection: '',
    field: 'inspect_number',
    orderBy: '',
    isRefField: false,
    isTextField: true,
    isStatusField: false,
    isDateField: false,
    isFilter: false
  },
  {
    key: 3,
    name: 'Export Inspect Date',
    collection: '',
    field: 'inspect_date',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },
  {
    key: 4,
    name: 'Last Updated',
    collection: '',
    field: 'updated_at',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isDateField: true,
    isFilter: false
  },

  {
    key: 5,
    name: 'Updated By',
    collection: '',
    field: 'updated_by',
    orderBy: '',
    isRefField: false,
    isTextField: false,
    isStatusField: false,
    isUpdateByField: true,
    isDateField: false,
    isFilter: false
  },

]


export const PRINT_IMPORTSTOCK = {

  importcountry: {
    key: 1,
    name: "ប្រទេសនាំចូល",
    value: true,
  },
  rawmaterial: {
    key: 2,
    name: "វត្ថុធាតុដើមចំបង",
    value: true,
  },
  volume: {
    key: 3,
    name: "បរិមាណ",
    value: true,
  },
  invoicenumber: {
    key: 4,
    name: "វិក័យប័ត្រលេខ",
    value: true,
  },
  customspermitno: {
    key: 5,
    name: "លិខិតអនុញ្ញាតគយលេខ",
    value: true,
  },
  customsno: {
    key: 6,
    name: "ប្រតិវេទន៍គយលេខ",
    value: true,
  },
  camcontrolno: {
    key: 7,
    name: "កាំកុងត្រូលលេខ",
    value: true,
  },
  ordernumber: {
    key: 8,
    name: "បញ្ជាទិញលេខ",
    value: true,
  },
  cat: {
    key: 9,
    name: "CAT មុខទំនិញផលិត",
    value: true,
  },
  amount: {
    key: 10,
    name: "បរិមាណផលិត PCS",
    value: true,
  },
  exportto: {
    key: 11,
    name: "ទិសដៅនាំចេញ",
    value: true,
  },
}


export const PRINT_CUTSTOCK = {

  invoicenumber: {
    key: 1,
    name: "វិក័យប័ត្រ លេខ",
    value: true,
  },
  exportitem: {
    key: 2,
    name: "មុខទំនិញនាំចេញ",
    value: true,
  },
  cat: {
    key: 3,
    name: "CAT",
    value: true,
  },
  numberofproducts: {
    key: 4,
    name: "ចំនួនផលិតផល",
    value: true,
  },
  amountmaterial: {
    key: 5,
    name: "បរិមាណវត្ថុធាតុដើមប្រើប្រាស់",
    value: true,
  },
  conumber: {
    key: 6,
    name: "C/O លេខ",
    value: true,
  },
  codate: {
    key: 7,
    name: " CO Date",
    value: true,
  },
  country: {
    key: 8,
    name: "ប្រទេសគោលដៅ",
    value: true,
  },
  route: {
    key: 9,
    name: "SEA/AIR",
    value: true,
  },
}

export const PRINT_CUTSTOCK_INSPECTION = {

  invoicenumber: {
    key: 1,
    name: "វិក័យប័ត្រ លេខ",
    value: true,
  },
  exportitem: {
    key: 2,
    name: "មុខទំនិញនាំចេញ",
    value: true,
  },
  cat: {
    key: 3,
    name: "CAT",
    value: true,
  },
  exportvalume: {
    key: 4,
    name: "បរិមាណនាំចេញ",
    value: true,
  },
  conumber: {
    key: 5,
    name: "C/O លេខ",
    value: true,
  },
  codate: {
    key: 6,
    name: " CO Date",
    value: true,
  },
  country: {
    key: 7,
    name: "ប្រទេសគោលដៅ",
    value: true,
  },
  route: {
    key: 8,
    name: "SEA/AIR",
    value: true,
  },


}

export const SETTING_COUNTRY = {
  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  name: {
    key: 2,
    name: "Name",
    value: true,
  },
  department: {
    key: 3,
    name: "Department",
    value: true,
  },
  coForm: {
    key: 4,
    name: "CO Form",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  }
}



export const PrintSOOStatement = {
  soo: {
    key: 1,
    name: "SOO",
    value: true,
  },
  co: {
    key: 2,
    name: "CO",
    value: true,
  },
  invoice: {
    key: 3,
    name: "Invoice",
    value: true,
  },
  shippingDate: {
    key: 4,
    name: "Shipping Date",
    value: true,
  },
  quality: {
    key: 5,
    name: "Quality",
    value: true,
  },
  amount: {
    key: 6,
    name: "Amount (FOB)",
    value: true,
  },
  distination: {
    key: 7,
    name: "Distination",
    value: true,
  }
}

export const SETTINGBOSTOCK = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  reference: {
    key: 2,
    name: "Ref",
    value: true,
  },
  date: {
    key: 3,
    name: "Date",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 5,
    name: "Last Update",
    value: true,
  },
}

export const settingImportStock = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  reference: {
    key: 2,
    name: "Ref",
    value: true,
  },
  date: {
    key: 3,
    name: "Date",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 5,
    name: "Last Update",
    value: true,
  },
}

export const settingCutStock = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  reference: {
    key: 2,
    name: "Ref",
    value: true,
  },
  date: {
    key: 3,
    name: "Date",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 5,
    name: "Last Update",
    value: true,
  },
}

export const settingVoidStock = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  reference: {
    key: 2,
    name: "Ref",
    value: true,
  },
  date: {
    key: 3,
    name: "Date",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 5,
    name: "Last Update",
    value: true,
  },
}

export const stockMovement = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  ref: {
    key: 2,
    name: "ref",
    value: true,
  },
  type: {
    key: 3,
    name: "type",
    value: true,
  },
  cost: {
    key: 4,
    name: "cost",
    value: true,
  },
  qty: {
    key: 5,
    name: "qty",
    value: true,
  },
  total: {
    key: 6,
    name: "total",
    value: true,
  },
  date: {
    key: 7,
    name: "Date",
    value: true,
  },
  // status: {
  //   key: 5,
  //   name: "Status",
  //   value: true,
  // },
  // updated_at: {
  //   key: 6,
  //   name: "Last Update",
  //   value: true,
  // },
}

export const settingDeliveryReturnDocument = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  reference: {
    key: 2,
    name: "Ref",
    value: true,
  },
  date: {
    key: 3,
    name: "date",
    value: true,
  },
  invoice: {
    key: 4,
    name: "Invoice",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const Submit_Column_Print = {
  co_reference: {
    key: 1,
    name: "CO Reference",
    value: true,
  },
  // co_reference_date: {
  //   key: 2,
  //   name: "CO Reference Date",
  //   value: true,
  // },
  quantity: {
    key: 2,
    name: "Quantity",
    value: true,
  },
  amount: {
    key: 3,
    name: "Amount",
    value: true,
  },
  cat: {
    key: 4,
    name: "CAT",
    value: true,
  },
  invoice: {
    key: 5,
    name: "Invoice",
    value: true,
  },
  // invoice_date: {
  //   key: 6,
  //   name: "Invoice Date",
  //   value: true,
  // },
  joint_inspection: {
    key: 6,
    name: "Joint Inspection",
    value: true,
  },
  // joint_inspection_date: {
  //   key: 9,
  //   name: "Joint Inspection Date",
  //   value: true,
  // },
  bill_no: {
    key: 7,
    name: "Bill No",
    value: true,
  },
  destination: {
    key: 8,
    name: "Destination",
    value: true,
  },
  adm_fee: {
    key: 9,
    name: " Service ADM Fee",
    value: true,
  },
  emf_fee: {
    key: 10,
    name: " Service EMF Fee",
    value: true,
  },
}

export const MasterList = {
  code: {
    name: "Master List Code",
    value: true,
  },
  id: {
    name: "Master list ID",
    value: true,
  },
  approvedDate: {
    name: "Approved Date",
    value: true,
  },
  fromDate: {
    name: "From Date",
    value: true,
  },
  toDate: {
    name: "To Date",
    value: true,
  },
  duration: {
    name: "Duration",
    value: true,
  },
  status: {
    name: "Status",
    value: true,
  }
}
export const Setting_Product = {

  name: {
    key: 1,
    name: "Description",
    value: true,
  },
  uom: {
    key: 2,
    name: "UOM",
    value: true,
  },
  type: {
    key: 3,
    name: "Item Type",
    value: true,
  },
  price: {
    key: 4,
    name: "Price",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },

}

export const Setting_Item = {

  name: {
    key: 1,
    name: "Description",
    value: true,
  },
  uom: {
    key: 2,
    name: "UOM",
    value: true,
  },
  type: {
    key: 3,
    name: "Item Type",
    value: true,
  },
  price: {
    key: 4,
    name: "Price",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },

}



export const SETTING_COFORM = {

  code: {
    key: 1,
    name: "CO Code",
    value: true,
  },
  name: {
    key: 2,
    name: "CO Form",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  }
}

export const SETTING_USER = {
  name: {
    key: 1,
    name: "Name",
    value: true,
  },
  email: {
    key: 2,
    name: "Email",
    value: true,
  },
  phone: {
    key: 4,
    name: "Phone Number",
    value: true,
  },
  position: {
    key: 3,
    name: "Position",
    value: true,
  },

  status: {
    key: 5,
    name: "Status",
    value: true,
  }
}

export const Setting_Department = {
  name_en: {
    key: 1,
    name: "Name (EN)",
    value: true,
  },
  name_kh: {
    key: 2,
    name: "Name (KH)",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  }
}

export const pickupBill = {
  bill_number: {
    key: 1,
    name: "Bill number",
    value: true,
  },
  bill_date: {
    key: 2,
    name: "Bill date",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 4,
    name: "Last Update",
    value: true,
  },
}


export const pickupInspect = {
  inspect_number: {
    key: 1,
    name: "Inspect number",
    value: true,
  },
  inspect_date: {
    key: 2,
    name: "Inspect date",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 4,
    name: "Last Update",
    value: true,
  },
}

export const SettingSubmitForm = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  form: {
    key: 3,
    name: "Form",
    value: true,
  },
  reference: {
    key: 4,
    name: "Reference No.",
    value: true,
  },
  // product_type: {
  //   key: 3,
  //   name: "Product",
  //   value: true,
  // },
  set: {
    key: 5,
    name: "Set",
    value: true,
  },

  route: {
    key: 6,
    name: "Route",
    value: true,
  },

  // shipping_date: {
  //   key: 7,
  //   name: "Shipping Date",
  //   value: true,
  // },
  total_service_fee: {
    key: 7,
    name: "Total Service Fee",
    value: true,
  },

  status: {
    key: 8,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 9,
    name: "Last Update",
    value: true,
  },
}


export const SettingSubmitCrf = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  form: {
    key: 3,
    name: "Form",
    value: true,
  },
  reference: {
    key: 4,
    name: "Reference No.",
    value: true,
  },
  // product_type: {
  //   key: 3,
  //   name: "Product",
  //   value: true,
  // },
  set: {
    key: 5,
    name: "Set",
    value: true,
  },

  route: {
    key: 6,
    name: "Route",
    value: true,
  },

  // shipping_date: {
  //   key: 7,
  //   name: "Shipping Date",
  //   value: true,
  // },
  total_service_fee: {
    key: 7,
    name: "Total Service Fee",
    value: true,
  },

  status: {
    key: 8,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 9,
    name: "Last Update",
    value: true,
  },
}

export const settingReApply = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  form: {
    key: 3,
    name: "Form",
    value: true,
  },
  reference: {
    key: 4,
    name: "Reference No.",
    value: true,
  },

  set: {
    key: 5,
    name: "Set",
    value: true,
  },

  route: {
    key: 6,
    name: "Route",
    value: true,
  },

  joint_inspection: {
    key: 7,
    name: "Joint Inspection",
    value: true,
  },
  bill_leading: {
    key: 8,
    name: "Bill Leading",
    value: true,
  },

  status: {
    key: 9,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 10,
    name: "Last Update",
    value: true,
  },
}

export const SettingReturnApplication = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  form: {
    key: 3,
    name: "Form",
    value: true,
  },
  reference: {
    key: 4,
    name: "Reference No.",
    value: true,
  },

  set: {
    key: 5,
    name: "Set",
    value: true,
  },

  route: {
    key: 6,
    name: "Route",
    value: true,
  },

  joint_inspection: {
    key: 7,
    name: "Joint Inspection",
    value: true,
  },
  bill_leading: {
    key: 8,
    name: "Bill Leading",
    value: true,
  },

  status: {
    key: 9,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 10,
    name: "Last Update",
    value: true,
  },
}

export const SettingDeliveryCOApplication = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },

  reference: {
    key: 3,
    name: "Reference No.",
    value: true,
  },

  set: {
    key: 4,
    name: "Set",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const SettingDeliveryImportStock = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  reference: {
    key: 3,
    name: "Reference No.",
    value: true,
  },
  date: {
    key: 4,
    name: "Date",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const SettingDeliveryCutStock = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },
  reference: {
    key: 3,
    name: "Reference No.",
    value: true,
  },
  date: {
    key: 4,
    name: "Date",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const SettingDeliveryExportDocument = {
  no: {
    key: 1,
    name: "No",
    value: true,
  },
  attachment: {
    key: 2,
    name: "Attachment",
    value: true,
  },

  reference: {
    key: 2,
    name: "Reference No",
    value: true,
  },
  joint_inspection: {
    key: 7,
    name: "Joint Inspection (Set)",
    value: true,
  },
  invoice: {
    key: 4,
    name: "Invoice (Set)",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const Setting_Route = {
  name: {
    key: 1,
    name: "English Name",
    value: true,
  },
  name_kh: {
    key: 1,
    name: "Khmer Name",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  }
}

export const SettingProductType = {
  name: {
    key: 1,
    name: "English Name",
    value: true,
  },
  name_kh: {
    key: 1,
    name: "Khmer Name",
    value: true,
  },
  status: {
    key: 3,
    name: "Status",
    value: true,
  }
}

export const RegisterCO = {
  co_number: {
    name: "CO Number",
    value: true,
  },
  co_date: {
    name: "CO Date",
    value: true,
  },
  co_form: {
    key: 2,
    name: "CO Form",
    value: true,
  },
  invoice_number: {
    key: 3,
    name: "Invoice Number",
    value: true,
  },
  invoice_date: {
    key: 4,
    name: "Invoice Date",
    value: true,
  },
  country: {
    key: 5,
    name: "Destination",
    value: true,
  },
  route: {
    key: 6,
    name: "Route",
    value: true,
  },
  status: {
    name: "Status",
    value: true,
  },
  updated_at: {
    name: "Last Update",
    value: true,
  },
}

export const RegisterSOO = {
  soo_number: {
    name: "SOO Number",
    value: true,
  },
  soo_date: {
    name: "SOO Date",
    value: true,
  },
  status: {
    name: "Status",
    value: true,
  },
  updated_at: {
    name: "Last Update",
    value: true,
  },
}

export const RegisterSOOStatement = {
  ref: {
    name: "Ref",
    value: true,
  },
  soo_date: {
    name: "Application Date",
    value: true,
  },
  status: {
    name: "Status",
    value: true,
  },
  updated_at: {
    name: "Last Update",
    value: true,
  },
}

export const DATA_TYPE = {
  'option': 'option',
  'date': 'date',
  'number': 'number'
}

export const FILE_REGISTER_ITEM = {
  'CO Form': {
    key: 'co_formKey',
    type: DATA_TYPE.option,
    collection: 'settings_coform',
    setting: true,
    field: 'keyword',
    displayField: 'name'
  },
  'Invoice Number': {
    key: 'invoice_number',
    type: DATA_TYPE.option,
    collection: 'register_item',
    setting: false,
    field: 'invoice_number',
    displayField: 'invoice_number'
  },
  'Invoice Date': {
    key: 'invoice_date',
    type: DATA_TYPE.date,
    collection: 'register_item',
    setting: false,
    field: 'invoice_date',
    displayField: 'name'
  },
  'Amount (FOB)': {
    key: 'amount_FOB',
    type: DATA_TYPE.number,
    collection: 'register_item',
    setting: false,
    field: 'amount_FOB',
    displayField: 'name'
  },
  'Destination': {
    key: 'countryKey',
    type: DATA_TYPE.option,
    collection: 'settings_country',
    setting: true,
    field: 'keyword',
    displayField: 'name'
  },
  'Route': {
    key: 'routerKey',
    type: DATA_TYPE.option,
    collection: 'settings_route',
    setting: true,
    field: 'keyword',
    displayField: 'name'
  },
  'Register CO': {
    key: 'registerCo',
    type: DATA_TYPE.option,
    collection: 'register_co',
    setting: false,
    field: 'keyword',
    displayField: 'co_number'
  }
}

export const RegisterItem = {
  co_form: {
    key: 1,
    name: "CO Form",
    field: 'co_form',
    value: true,
  },
  invoice_number: {
    key: 2,
    name: "Invoice Number",
    field: 'invoice_number',
    value: true,
  },
  invoice_date: {
    key: 3,
    name: "Invoice Date",
    field: 'invoice_date',
    value: true,
  },
  fob: {
    key: 4,
    name: "Amount (FOB)",
    field: 'fob',
    value: true,
  },
  country: {
    key: 5,
    name: "Destination",
    field: 'country',
    value: true,
  },
  route: {
    key: 6,
    name: "Route",
    field: 'routes',
    value: true,
  },
  status: {
    key: 7,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 8,
    name: "Last Update",
    value: true,
  },
}


export const RegisterInvoice = {
  invoice_number: {
    key: 2,
    name: "Invoice Number",
    value: true,
  },
  invoice_date: {
    key: 3,
    name: "Invoice Date",
    value: true,
  },
  cat: {
    key: 4,
    name: "Cat",
    value: true,
  },
  purchaseNo: {
    key: 5,
    name: "Purchase Number",
    value: true,
  },
  country: {
    key: 6,
    name: "Destination",
    value: true,
  },
  status: {
    key: 7,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 8,
    name: "Last Update",
    value: true,
  },
}

export const Setting_UOM = {
  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  description_en: {
    key: 2,
    name: "Description (EN)",
    value: true,
  },
  description_kh: {
    key: 3,
    name: "Description (KH)",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  }
}
export const Material = {
  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  name: {
    key: 2,
    name: "Name",
    value: true,
  },
  uom: {
    key: 3,
    name: "UOM",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 5,
    name: "Last Update",
    value: true,
  },
}

export const InventoryBalance = {
  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  name: {
    key: 2,
    name: "Name",
    value: true,
  },
  uom: {
    key: 3,
    name: "UOM",
    value: true,
  },
  stockQty: {
    key: 4,
    name: "Stock Qty",
    value: true,
  },
  status: {
    key: 5,
    name: "Status",
    value: true,
  },
  updated_at: {
    key: 6,
    name: "Last Update",
    value: true,
  },
}

export const Setting_Company = {
  name_en: {
    key: 1,
    name: "Name (EN)",
    value: true,
  },
  name_kh: {
    key: 2,
    name: "Name (KH)",
    value: true,
  },
  phone: {
    key: 8,
    name: "Phone Number",
    value: true,
  },
  email: {
    key: 3,
    name: "Email",
    value: true,
  },
  address: {
    key: 5,
    name: "Address",
    value: true,
  },
  tinNo: {
    key: 4,
    name: "TIN No.",
    value: true,
  },
  country: {
    key: 6,
    name: "Country",
    value: true,
  },
  postalCode: {
    key: 7,
    name: "Postal Code",
    value: true,
  },

  fax: {
    key: 9,
    name: "Fax Number",
    value: true,
  },
  status: {
    key: 10,
    name: "Status",
    value: true,
  }
}

export const Setting_Forwarder = {
  name_en: {
    key: 1,
    name: "Name (EN)",
    value: true,
  },
  name_kh: {
    key: 2,
    name: "Name (KH)",
    value: true,
  },
  short_name_en: {
    key: 3,
    name: "Short Name (EN)",
    value: true,
  },
  short_name_kh: {
    key: 4,
    name: "Short Name (KH)",
    value: true,
  },
  email: {
    key: 5,
    name: "Email",
    value: true,
  },
  tinNo: {
    key: 6,
    name: "TIN No.",
    value: true,
  },
  address: {
    key: 7,
    name: "Address",
    value: true,
  },
  country: {
    key: 8,
    name: "Country",
    value: true,
  },
  postalCode: {
    key: 9,
    name: "Postal Code",
    value: true,
  },
  linkAddress: {
    key: 10,
    name: "Link Address",
    value: true,
  },
  phone: {
    key: 11,
    name: "Phone Number",
    value: true,
  },
  fax: {
    key: 12,
    name: "Fax Number",
    value: true,
  },
  status: {
    key: 13,
    name: "Status",
    value: true,
  }
}

export const Setting_Country_port = {
  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  name: {
    key: 2,
    name: "Name",
    value: true,
  },
  country: {
    key: 3,
    name: "Country",
    value: true,
  },
  status: {
    key: 4,
    name: "Status",
    value: true,
  }
}

export const Setting_Customer = {

  name: {
    key: 1,
    name: "Name",
    value: true,
  },
  email: {
    key: 2,
    name: "Email",
    value: true,
  },
  address: {
    key: 3,
    name: "Address",
    value: true,
  },
  // postal_code:{
  //   name: "Postal Code",
  //   value: true,
  // },
  telephone_number: {
    key: 4,
    name: "Phone Number",
    value: true,
  },
  // country: {
  //   name: "Country",
  //   value: true,
  // },
  // discount_percentage: {
  //   name: "Discount Percentage",
  //   value: true,
  // },

  // discount_amount: {
  //   name: "Discount Amount",
  //   value: true,
  // },
  // fax_number: {
  //   name: "Fax Number",
  //   value: true,
  // },
  // credit_Limit: {
  //   name: "Credit Limit",
  //   value: true,
  // },
  // account_name: {
  //   name: "Account name",
  //   value: true,
  // },
  status: {
    key: 5,
    name: "Status",
    value: true,
  }
}
export const Setting_Supplier = {

  name: {
    key: 1,
    name: "Name",
    value: true,
  },
  email: {
    key: 2,
    name: "Email",
    value: true,
  },
  address: {
    key: 3,
    name: "Address",
    value: true,
  },
  // postal_code:{
  //   name: "Postal Code",
  //   value: true,
  // },
  telephone_number: {
    key: 4,
    name: "Phone Number",
    value: true,
  },
  // country: {
  //   name: "Country",
  //   value: true,
  // },
  // discount_percentage: {
  //   name: "Discount Percentage",
  //   value: true,
  // },

  // discount_amount: {
  //   name: "Discount Amount",
  //   value: true,
  // },
  // fax_number: {
  //   name: "Fax Number",
  //   value: true,
  // },
  // credit_Limit: {
  //   name: "Credit Limit",
  //   value: true,
  // },
  // account_name: {
  //   name: "Account name",
  //   value: true,
  // },
  status: {
    key: 5,
    name: "Status",
    value: true,
  }
}

export const Setting_HsCode = {

  code: {
    key: 1,
    name: "Code",
    value: true,
  },
  description_en: {
    key: 2,
    name: "Description (EN)",
    value: true,
  },
  // description_kh: {
  //   name: "Description (KH)",
  //   value: true,
  // },
  uom: {
    key: 3,
    name: "UOM",
    value: true,
  },
  cd: {
    key: 4,
    name: "CD",
    value: true,
  },
  st: {
    key: 5,
    name: "ST",
    value: true,
  },
  vat: {
    key: 6,
    name: "VAT",
    value: true,
  },
  et: {
    key: 7,
    name: "ET",
    value: true,
  },
  status: {
    key: 8,
    name: "Status",
    value: true,
  }
}
