import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { dashBoardFilterBy } from 'src/app/dummy/status';
import { AngularFirestore } from '@angular/fire/firestore';
import { SearchStore } from 'src/app/stores/search.store';
import { Auth } from 'src/app/stores/auth.store';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { PrintService } from 'src/app/services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { pushObjArray } from 'src/app/services/convert.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  formFocus: boolean;
  form: FormGroup;
  search: AbstractControl;
  searchType: AbstractControl;
  filterBy = dashBoardFilterBy;
  loading: boolean = false;
  filterData = [];
  selectedFilter = null;
  selectedItem = null;
  searchSubscription: Subscription
  constructor(
    public db: AngularFirestore,
    private fb: FormBuilder,
    public store: SearchStore,
    public auth: Auth,
    private ps: PrintService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.selectedFilter = this.filterBy[0]
    this.form = this.fb.group({
      searchType: [this.selectedFilter, [Validators.required]],
      search: [null, Validators.required]
    });
    this.search = this.form.controls["search"];
    this.searchType = this.form.controls["searchType"];
    const params = this.route.snapshot.queryParams;
    const { search } = params;

    if (search) {
      this.selectedFilter = this.filterBy.find(f => f.key === Number(search));
    }
    this.searchSubscription = this.store.search(this.selectedFilter, this.auth.selectedCompany.key, this.selectedFilter.filterBy, null).subscribe(results => {
      this.filterData = results;
    })
    this.searchSubscription = this.search.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (this.loading = true)),
        switchMap(value => this.store.search(this.selectedFilter, this.auth.selectedCompany.key, this.selectedFilter.filterBy, value || '~N/A~'))
      )
      .subscribe(results => {
        this.filterData = results;
        this.loading = false;
      });

  }
  ngOnDestroy(): void {
    this.searchSubscription && this.searchSubscription.unsubscribe()
  }

  onSelectFilter(item) {
    this.filterData = [];
    this.selectedItem = null;
    this.selectedFilter = item;
    this.store.search(this.selectedFilter, this.auth.selectedCompany.key, this.selectedFilter.filterBy, null).subscribe(results => {
      this.filterData = results;
    })
    this.router.navigate(['/'], {
      queryParams: {
        search: item.key,
      }
    }).then(() => {
      this.search.setValue(null);
    })

  }
  printIframe() {
    this.ps.print('#printf', 'a4')
  }
  displayItem(item: any): string {
    return item ? item : item;
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) {
      return o1.text === o2.text;
    }
  }
  _onFocus(yes) {
    this.formFocus = yes;
    if (!yes) return;
    const { search } = this.form.value;
    const sValue = search;
    if (sValue) {
      this.store.search(this.selectedFilter, this.auth.selectedCompany.key, this.selectedFilter.filterBy, search).subscribe(results => {
        this.filterData = results;
      })
    }

  }

  _selectionChange(event) {
    const { value } = event;
    this.selectedItem = null
    this.filterData = []
    this.form.patchValue({
      searchType: value,
      search: ''
    })
    this.selectedFilter = value

  }
  clearResult() {
    this.selectedItem = null
    this.filterData = []
    this.form.patchValue({
      search: ''
    })
  }
  _optionSelected(item: any) {
    this.selectedItem = item;
  }
  _onSearch(f) {
    // this.store.search(this.searchType.value, this.auth.selectedCompany.key, 'keyword', value)
  }
}
