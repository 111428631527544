import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getReferenceObject'
})
export class GetReferenceObjectPipe implements PipeTransform {
  transform(data: any, first: any, field: string): any {
    if (typeof data !== 'string' && data !== null && data != undefined) {
      let value = '';
      return data.get().then(doc => {
        const ref = doc.data();
        const { status } = ref;
        if (status.key === 1) {
          let comma = ',';
          if (first) comma = '';
          value = doc ? `${comma}` + ` ${doc.data()[field]}`: '';
        }
        return value;
      });
    }
  }
}
