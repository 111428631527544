import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { firestore } from 'firebase';
import * as _ from 'lodash';
import { toNumber } from './mapping.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor(
  ) { }



  static dateFormat(date: Date) {
    return moment(date).format('D/MM/YYYY');
  }

  static uniqueArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
  }

  static getNumber(value: any, getval: any): number {
    if (Number.isNaN(value) || value === null || value === undefined || value === '') {
      return getval;
    }
    return Number(value);
  }


  static toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number.isNaN(Number(value))) return 0;
    return Number(value.toFixed(2));
  }

  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number.isNaN(Number(value))) return 0;
    return Number(value);
  }

  static numberOnly(sender: any) {
    let numb = sender.match(/\d/g);
    numb = numb.join("");
    return this.toNumber(numb);
  }

  static toYear(date: Date) {
    return moment(date).format('YYYY');
  }

  public toCapitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  public toLoverCase(string) {
    return string.toLoverCase();
  }

  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }
  static orderBy(data: Array<any>, field: string) {
    return _.orderBy(data, [field])
  }
  static orderByDesc(data: Array<any>, field: string) {
    return _.orderBy(data, [field], ['desc'])
  }
  static toNullInputKey(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value.key;
  }


  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static toPageKey(date: Date) {
    return Number(moment(date).format('YYYYMMDDHHmmss'))
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }

  static toDateAppKey(date: any) {
    let value = Number(moment(date).format('YYYYMMDD'));
    const time = Number(moment().format('HHmmss'))
    value = Number(`${value}${time}`);
    return value;
  }

  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = Number(moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString());
    return number;
  }

  static generate_puc_id(config) {
    var number = moment().format('YY').toString() + (config.puc_id + 1).toString();
    return number;
  }

}




export function toTrimString(value) {
  let string = null;
  if (value) string = value.toString().trim();
  return string;
}

export function sum(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function sumArray(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function sumData(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function toFixed5(value: any) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number.isNaN(Number(value))) return 0;
  return Number(value.toFixed(5));
}

export function floatify(number: number) {
  return parseFloat((number).toFixed(10));
}

export function sumStock(data: Array<any>) {
  if (!data) return 0;
  return data.reduce((a, b: any) => {
    const { movementType, qty } = b;
    const j = a + toNumber(qty * movementType.factor)
    return j;
  }, 0)
}

export function pushToArray(snapshot: firestore.QuerySnapshot): Array<any> {
  if (snapshot.empty) return [];
  return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
}


export function pushToObject(snapshot: firestore.DocumentSnapshot<any>): any {
  if (!snapshot.exists) return null;
  return { ...snapshot.data(), id: snapshot.id }
}

export function removeObjArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m.key !== key);
  }
  return value;
}

export function removeObjRoleArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m.appkey !== key);
  }
  return value;
}

export function fieldValueArrayObject(value: any, field, batches: Array<any>) {
  let result: Array<any> = batches;
  if (batches && batches.length > 0) {
    const { key } = value
    const invalid = batches.filter(m => m[field] === key).length === 0;
    if (invalid) result.push(value);
  }
  else {
    result = [];
    result.push(value);
  }
  return result;
}

export function fieldValueArray(key: any, batches: Array<any>) {
  let result: Array<any> = batches;
  if (batches && batches.length > 0) {
    const invalid = batches.filter(m => m === key).length === 0;
    if (invalid) result.push(key);
  }
  else {
    result = [];
    result.push(key);
  }
  return result;
}


export function removeStingArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m !== key);
  }
  return value;
}

export function pushObjArray(data: Array<any>, newItem: any, field: any = "key") {
  let value = [];
  if (data && data.length > 0) {
    value = [...data];
    const exist = value.filter(m => m[field] === newItem.key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj[field] == newItem.key));
      value[index] = newItem;
    } else {
      value.push(newItem);
    }
  } else {
    value.push(newItem);
  }

  return value;
}

export function pushReplaceObjArray(value: Array<any>, newItem: any, field: any = "key") {
  if (value && value.length > 0) {
    const exist = value.filter(m => m[field] === newItem.key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj[field] == newItem.key));
      value[index] = newItem;
    } else {
      value.push(newItem);
    }
  } else {
    value.push(newItem);
  }

  return value;
}

export function pushStringArray(data: Array<any>, key: string) {
  let value = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter(m => m === key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj == key));
      value[index] = key;
    } else {
      value.push(key);
    }
  } else {
    value.push(key);
  }

  return value;
}
export function orderBy(rows: Array<any>, field: string, ascDesc: string = "asc") {
  return _.orderBy(rows, [field], ascDesc)
}


export function orderByData(rows: Array<any>, field: string, ascDesc: string = "asc") {
  return _.orderBy(rows, [field], ascDesc)
}

export function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    const data = _.transform(newObj, function (result, value, key) {
      if (!_.isEqual(value, origObj[key])) {
        // let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key
        result[key] = (_.isObject(value) && _.isObject(origObj[key])) && !_.isDate(value) && !_.isDate(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
    if (_.isArray(data)) {
      return _.filter(data, (m: any) => m)
    }
    if (_.isObject(data) && !_.isPlainObject(data) && !_.isArrayLikeObject(data)) {
      return null
    }

    return data
  }
  return changes(newObj, origObj);
}

export function changedKeys(o1, o2) {
  var keys = _.union(_.keys(o1), _.keys(o2));
  return _.filter(keys, function (key) {
    return o1[key] !== o2[key];
  }
  )
}


export function dateKeyToDate(dateKey: string | number) {
  return (moment(dateKey, 'YYYYMMDD').toDate())
}



export function compareDate(oldDate, newDate) {
  const od = oldDate.toDate();
  const nd = newDate
  return od === nd ? oldDate : newDate
}


export const createKeywords = name => {
  const arrName = [];
  let curName = '';
  let nextName = '';
  name.trim().split(/[ .\-_ ]/).forEach(letter => {
    curName += letter;
    arrName.push(ConvertService.toCapitalize(letter));
    arrName.push(ConvertService.toCapitalize(curName));
  });

  name.split('').forEach(letter => {
    nextName += letter;
    arrName.push(ConvertService.toCapitalize(nextName));
  });

  return arrName;
}

export const generateKeywords = names => {
  const [keyword1, keyword2, keyword3, keyword4, keyword5, keyword6, keyword7, keyword8, keyword9, keyword10] = names;
  const suffix1 = keyword1 && keyword1.length > 0 ? `${keyword1}` : '';
  const suffix2 = keyword2 && keyword2.length > 0 ? `${keyword2}` : '';
  const suffix3 = keyword3 && keyword3.length > 0 ? `${keyword3}` : '';
  const suffix4 = keyword4 && keyword4.length > 0 ? `${keyword4}` : '';
  const suffix5 = keyword5 && keyword5.length > 0 ? `${keyword5}` : '';
  const suffix6 = keyword6 && keyword6.length > 0 ? `${keyword6}` : '';
  const suffix7 = keyword7 && keyword7.length > 0 ? `${keyword7}` : '';
  const suffix8 = keyword8 && keyword8.length > 0 ? `${keyword8}` : '';
  const suffix9 = keyword9 && keyword9.length > 0 ? `${keyword9}` : '';
  const suffix10 = keyword10 && keyword10.length > 0 ? `${keyword10}` : '';
  const keywordT1 = createKeywords(`${suffix1}`);
  const keywordT2 = createKeywords(`${suffix2}`);
  const keywordT3 = createKeywords(`${suffix3}`);
  const keywordT4 = createKeywords(`${suffix4}`);
  const keywordT5 = createKeywords(`${suffix5}`);
  const keywordT6 = createKeywords(`${suffix6}`);
  const keywordT7 = createKeywords(`${suffix7}`);
  const keywordT8 = createKeywords(`${suffix8}`);
  const keywordT9 = createKeywords(`${suffix9}`);
  const keywordT10 = createKeywords(`${suffix10}`);
  return [
    ...new Set([
      '~N/A~',
      ...keywordT1,
      ...keywordT2,
      ...keywordT3,
      ...keywordT4,
      ...keywordT5,
      ...keywordT6,
      ...keywordT7,
      ...keywordT8,
      ...keywordT9,
      ...keywordT10,
    ])
  ];
}



export function toDateKey(date: Date) {
  return Number(moment(date).format('YYYYMMDD'))
}