<ng-container *ngIf="!store.loading && store?.auditData?.length > 0">
  <virtual-scroller [enableUnequalChildrenSizes]="true" #scroll [items]="store?.auditData"
    [enableUnequalChildrenSizes]="randomSize" (vsEnd)="scrollHandler($event, scroll?.cachedItemsLength)">
    <div class="audit-list-item" [randomHeight]="randomSize"
      *ngFor="let data of scroll.viewPortItems ;let i=index; trackBy: trackByIdx">
      <div class="ac-date" style="font-size: 14px;">
        {{data?.audit_date?.toDate() | date:'medium'}}
      </div>
      <div *ngIf="data?.audit_type?.key === 1 " style="display: flex;
                  padding: 10px 18px;">
        <div class="u-icon" style="background-color: #4285F3;">
          {{data?.created_by?.displayName | shorten: 1: ''}}
        </div>
        <div style="font-size: 13px;">
          <strong>{{data?.created_by?.displayName}}</strong>
          <p style="font-size: 13px;">Create this item</p>
        </div>
      </div>

      <div *ngIf="data?.audit_type?.key === 5 " style="display: flex;
      padding: 10px 18px;">
        <div class="u-icon" style="background-color: #00BFA5;">
          {{data?.print_by?.displayName | shorten: 1: ''}}
        </div>
        <div style="font-size: 13px;">
          <strong>{{data?.print_by?.displayName}}</strong>
          <p style="font-size: 13px;">Print this item</p>
        </div>
      </div>

      <div *ngIf="data?.audit_type?.key === 3" style="display: flex;
                  padding: 10px 18px;">
        <div class="u-icon" style="background-color: #018491;">
          {{data?.updated_by?.displayName | shorten: 1: ''}}
        </div>
        <div>
          <strong>{{data?.updated_by?.displayName}}</strong>
          <p style="font-size: 13px;">Update this item</p>
        </div>
      </div>
      <div *ngIf="data?.audit_type?.key === 2" style="display: flex;
                  padding: 10px 18px;">
        <div class="u-icon" style="background-color: #d50000;">
          {{data?.deleted_by?.displayName | shorten: 1: ''}}
        </div>
        <div>
          <strong>{{data?.deleted_by?.displayName}}</strong>
          <p style="font-size: 13px;">Delete this item</p>
        </div>
      </div>
      <mat-accordion *ngIf="data?.audit_type?.key !== 5">
        <mat-expansion-panel style="box-shadow: none;" [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Action
            </mat-panel-title>
          </mat-expansion-panel-header>

          

          <ng-container *ngIf="collection === 'register_import_invoice'">
            <div class="" *ngIf="!store?.process" #content id="contentToConvert">
              <div>
                <ng-container>
                  <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.co_formRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.co_formKey">to
                          <strong class="change">
                            {{data?.newdata?.co_formKey |
                            docKeyRef:'settings_coform':'name' | async}}
                          </strong>
                        </span>

                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
                    <div class="dl-title">Invoice Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.invoice_number}}
                        <span *ngIf="data?.newdata?.invoice_number">to
                          <strong class="change">
                            {{data?.newdata?.invoice_number}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.invoice_date_key">
                    <div class="dl-title">Invoice Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.invoice_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.invoice_date_key">to
                          <strong class="change">
                            {{data?.newdata?.invoice_date_key | toDate | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.product_name">
                    <div class="dl-title">Product Name</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.product_name}}
                        <span *ngIf="data?.newdata?.product_name">to
                          <strong class="change">
                            {{data?.newdata?.product_name}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.cat > 0">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                      <div class="data">
                        <span *ngIf="data?.olddata?.cat > 0">
                          {{data?.olddata?.cat | number:'2.'}}
                        </span>
                        <span *ngIf="data?.newdata?.cat > 0">to
                          <strong class="change">
                            {{data?.newdata?.cat}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.amount_FOB > 0">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                      <div class="data">
                        <span *ngIf="data?.olddata?.amount_FOB > 0">
                          {{data?.olddata?.amount_FOB | currency:' ':'code'}}
                        </span>
                        <span *ngIf="data?.newdata?.amount_FOB">to
                          <strong class="change">
                            {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.countryKey">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.countryRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.countryKey">to
                          <strong class="change">
                            {{data?.newdata?.countryKey |
                            docKeyRef:'settings_country':'name' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.routerKey">

                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.routeRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.routerKey">to
                          <strong class="change">
                            {{data?.newdata?.routerKey |
                            docKeyRef:'settings_route':'name' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.qty_packing_UOMKey || data?.newdata?.qty_packing">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.qty_packing | number:'1.'}}
                        <span *ngIf="data?.newdata?.qty_packing">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_packing | number:'1.'}}
                          </strong>
                        </span>

                        <span *ngIf="data?.olddata?.qty_packing_UOMRef">
                          {{data?.olddata?.qty_packing_UOMRef | docRef:'code' |
                          async}}
                        </span>
                        <span *ngIf="data?.newdata?.qty_packing_UOMKey">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_packing_UOMKey |
                            docKeyRef:'settings_uom':'code' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.qty_unit_UOMKey || data?.newdata?.qty_unit">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.qty_unit | number:'1.'}}
                        <span *ngIf="data?.newdata?.qty_unit">
                          to
                          <strong class="change">

                            {{data?.newdata?.qty_unit | number:'1.'}}
                          </strong>
                        </span>
                        <span *ngIf="data?.olddata.qty_unit_UOMRef">
                          {{data?.olddata?.qty_unit_UOMRef | docRef:'code' |
                          async}}
                        </span>
                        <span *ngIf="data?.newdata?.qty_unit_UOMKey">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_unit_UOMKey |
                            docKeyRef:'settings_uom':'code' | async}}
                          </strong>
                        </span>

                      </div>
                    </div>
                  </div>


                  <div class="dl-items" *ngIf="data?.newdata?.permit_number">
                    <div class="dl-title">Permit Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.permit_number}}
                        <span *ngIf="data?.newdata?.permit_number">to
                          <strong class="change">
                            {{data?.newdata?.permit_number}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                  <div class="dl-items" *ngIf="data?.newdata?.permit_date | isDifDay:data?.olddata?.permit_date">
                    <div class="dl-title">Permit Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.permit_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.permit_date">to
                          <strong class="change">
                            {{data?.newdata?.permit_date?.toDate() | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                  <div class="dl-items" *ngIf="data?.newdata?.custom_number">
                    <div class="dl-title">Custom Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.custom_number}}
                        <span *ngIf="data?.newdata?.custom_number">to
                          <strong class="change">
                            {{data?.newdata?.custom_number}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                  <div class="dl-items" *ngIf="data?.newdata?.custom_date | isDifDay:data?.olddata?.custom_date">
                    <div class="dl-title">Custom Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.custom_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.custom_date">to
                          <strong class="change">
                            {{data?.newdata?.custom_date?.toDate() | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.purchase_number">
                    <div class="dl-title">Purchase Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.purchase_number}}
                        <span *ngIf="data?.newdata?.purchase_number">to
                          <strong class="change">
                            {{data?.newdata?.purchase_number}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                  <div class="dl-items" *ngIf="data?.newdata?.purchase_date | isDifDay:data?.olddata?.purchase_date">
                    <div class="dl-title">Purchase Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.purchase_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.purchase_date">to
                          <strong class="change">
                            {{data?.newdata?.purchase_date?.toDate() | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>


                  <div class="dl-items" *ngIf="data?.newdata?.bill_number">
                    <div class="dl-title">Bill Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.bill_number}}
                        <span *ngIf="data?.newdata?.bill_number">to
                          <strong class="change">
                            {{data?.newdata?.bill_number}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                  <div class="dl-items" *ngIf="data?.newdata?.bill_date | isDifDay:data?.olddata?.bill_date">
                    <div class="dl-title">Bill Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.bill_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.bill_date">to
                          <strong class="change">
                            {{data?.newdata?.bill_date?.toDate() | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>



                  <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
                    <table class="table-wrapper tb-master">
                      <thead class="table-header">
                        <tr class="table-row">
                          <th class="table-col center">No.</th>
                          <th class="table-col center">Code</th>
                          <th class="table-col center">Description</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <tr class="table-row">
                          <td class="table-col" colspan="3">
                            <strong>Old CO Material</strong>
                          </td>
                        </tr>
                        <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                          <td class="table-col">{{i+1}}</td>
                          <td class="table-col">
                            {{item?.materialRef|docRef:'code'|async}}
                          </td>
                          <td class="table-col">
                            {{item?.materialRef|docRef:'name'|async}}
                          </td>
                        </tr>
                        <tr class="table-row">
                          <td class="table-col" colspan="3">
                            <strong>New CO Material</strong>
                          </td>
                        </tr>
                        <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                          <tr class="table-row">
                            <td class="table-col">{{i+1}}</td>
                            <td class="table-col">
                              {{item?.materialRef|docRef:'code'|async}}
                            </td>
                            <td class="table-col">
                              {{item?.materialRef|docRef:'name'|async}}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>

                </ng-container>
              </div>

            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'register_item'">
            <div class="" *ngIf="!store?.process" #content id="contentToConvert">
              <div>
                <ng-container>
                  <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.co_formRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.co_formKey">to
                          <strong class="change">
                            {{data?.newdata?.co_formKey |
                            docKeyRef:'settings_coform':'name' | async}}
                          </strong>
                        </span>

                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
                    <div class="dl-title">Export Invoice Number</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.invoice_number}}
                        <span *ngIf="data?.newdata?.invoice_number">to
                          <strong class="change">
                            {{data?.newdata?.invoice_number}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.invoice_date_key">
                    <div class="dl-title">Export Invoice Date</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.invoice_date?.toDate() | date}}
                        <span *ngIf="data?.newdata?.invoice_date_key">to
                          <strong class="change">
                            {{data?.newdata?.invoice_date_key | toDate | date}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.product_name">
                    <div class="dl-title">Product Name</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.product_name}}
                        <span *ngIf="data?.newdata?.product_name">to
                          <strong class="change">
                            {{data?.newdata?.product_name}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.cat > 0">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                      <div class="data">
                        <span *ngIf="data?.olddata?.cat > 0">
                          {{data?.olddata?.cat | number:'2.'}}
                        </span>
                        <span *ngIf="data?.newdata?.cat > 0">to
                          <strong class="change">
                            {{data?.newdata?.cat}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.amount_FOB > 0">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                      <div class="data">
                        <span *ngIf="data?.olddata?.amount_FOB > 0">
                          {{data?.olddata?.amount_FOB | currency:' ':'code'}}
                        </span>
                        <span *ngIf="data?.newdata?.amount_FOB">to
                          <strong class="change">
                            {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                          </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.countryKey">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.countryRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.countryKey">to
                          <strong class="change">
                            {{data?.newdata?.countryKey |
                            docKeyRef:'settings_country':'name' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.routerKey">

                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.routeRef | docRef:'name' | async}}
                        <span *ngIf="data?.newdata?.routerKey">to
                          <strong class="change">
                            {{data?.newdata?.routerKey |
                            docKeyRef:'settings_route':'name' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.qty_packing_UOMKey || data?.newdata?.qty_packing">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.qty_packing | number:'1.'}}
                        <span *ngIf="data?.newdata?.qty_packing">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_packing | number:'1.'}}
                          </strong>
                        </span>

                        <span *ngIf="data?.olddata?.qty_packing_UOMRef">
                          {{data?.olddata?.qty_packing_UOMRef | docRef:'code' |
                          async}}
                        </span>
                        <span *ngIf="data?.newdata?.qty_packing_UOMKey">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_packing_UOMKey |
                            docKeyRef:'settings_uom':'code' | async}}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dl-items" *ngIf="data?.newdata?.qty_unit_UOMKey || data?.newdata?.qty_unit">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">:
                      <div class="data">
                        {{data?.olddata?.qty_unit | number:'1.'}}
                        <span *ngIf="data?.newdata?.qty_unit">
                          to
                          <strong class="change">

                            {{data?.newdata?.qty_unit | number:'1.'}}
                          </strong>
                        </span>
                        <span *ngIf="data?.olddata.qty_unit_UOMRef">
                          {{data?.olddata?.qty_unit_UOMRef | docRef:'code' |
                          async}}
                        </span>
                        <span *ngIf="data?.newdata?.qty_unit_UOMKey">
                          to
                          <strong class="change">
                            {{data?.newdata?.qty_unit_UOMKey |
                            docKeyRef:'settings_uom':'code' | async}}
                          </strong>
                        </span>

                      </div>
                    </div>
                  </div>


                </ng-container>
              </div>

            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'local_sale_inv'">
            <div class="" *ngIf="!store?.process" #content id="contentToConvert">

              <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
                <div class="dl-title"> CO Form</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.co_formRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.co_formKey">to
                      <strong class="change">
                        {{data?.newdata?.co_formKey |
                        docKeyRef:'settings_coform':'name' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
                <div class="dl-title">Local Sale Invoice Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.invoice_number}}
                    <span *ngIf="data?.newdata?.invoice_number">to
                      <strong class="change">
                        {{data?.newdata?.invoice_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
                <div class="dl-title">Local Sale Invoice Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.invoice_date?.toDate() | date}}
                    <span *ngIf="data?.newdata?.invoice_date">to
                      <strong class="change">
                        {{data?.newdata?.invoice_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>

              </div>

              <div class="dl-items" *ngIf="data?.newdata?.shipping_date | isDifDay:data?.olddata?.shipping_date">
                <div class="dl-title">Local Sale Shipping Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.shipping_date?.toDate() | date}}
                    <span *ngIf="data?.newdata?.shipping_date">to
                      <strong class="change">
                        {{data?.newdata?.shipping_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>

              </div>
              <div class="dl-items" *ngIf="data?.newdata?.product_name">
                <div class="dl-title">Product Name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.product_name}}
                    <span *ngIf="data?.newdata?.product_name">to
                      <strong class="change">
                        {{data?.newdata?.product_name}}
                      </strong>
                    </span>
                  </div>
                </div>

              </div>
              <div class="dl-items" *ngIf="data?.newdata?.cat > 0">
                <div class="dl-title"> CAT</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.cat > 0">
                      {{data?.olddata?.cat | number:'2.'}}
                    </span>
                    <span *ngIf="data?.newdata?.cat">to
                      <strong class="change">
                        {{data?.newdata?.cat}}
                      </strong>
                    </span>
                  </div>
                </div>

              </div>
              <div class="dl-items" *ngIf="data?.newdata?.amount_FOB > 0">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.amount_FOB > 0">
                      {{data?.olddata?.amount_FOB | currency:' ':'code'}}
                    </span>
                    <span *ngIf="data?.newdata?.amount_FOB">to
                      <strong class="change">
                        {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                      </strong>
                    </span>
                  </div>
                </div>

              </div>
              <div class="dl-items" *ngIf="data?.newdata?.countryKey">
                <div class="dl-title"> Destination</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.countryRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.countryKey">to
                      <strong class="change">
                        {{data?.newdata?.countryKey |
                        docKeyRef:'settings_country':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.routerKey">

                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.routeRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.routerKey">to
                      <strong class="change">
                        {{data?.newdata?.routerKey |
                        docKeyRef:'settings_route':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_packing || data?.newdata?.qty_packing_UOMKey">
                <div class="dl-title"> Qty Packing</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_packing | number:'1.'}}

                    <span *ngIf="data?.newdata?.qty_packing">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing | number:'1.'}}
                      </strong>
                    </span>

                    <span *ngIf="data?.olddata?.qty_packing_UOMRef">
                      {{data?.olddata?.qty_packing_UOMRef | docRef:'code' | async}}
                    </span>

                    <span *ngIf="data?.newdata?.qty_packing_UOMKey">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing_UOMKey |
                        docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_unit_UOMRef || data?.newdata?.qty_unit_UOMKey">
                <div class="dl-title"> Qty Unit</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_unit | number:'1.'}}
                    <span *ngIf="data?.newdata?.qty_unit">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_unit | number:'1.'}}
                      </strong>
                    </span>
                    <span *ngIf="data?.olddata?.qty_unit_UOMRef">
                      {{data?.olddata?.qty_unit_UOMRef | docRef:'code' | async}}
                    </span>
                    <span *ngIf="data?.newdata?.qty_unit_UOMKey">
                      to
                      <strong class="change">

                        {{data?.newdata?.qty_unit_UOMKey |
                        docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>

            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'local_sale_inv_usage'">
            <div class="dl-items" *ngIf="data?.newdata?.register_itemRef">
              <div class="dl-title">Local Sale Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.register_itemRef | docRef:'invoice_number' | async}}
                  <span *ngIf="data?.newdata?.register_itemRef">
                    to
                    <strong class="change">
                      {{data?.newdata?.register_itemRef | docRef:'invoice_number' |
                      async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old CO Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New CO Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Customs Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Customs Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>
          <ng-container *ngIf="collection === 'register_item_usage'">
            <div class="dl-items" *ngIf="data?.newdata?.register_itemRef">
              <div class="dl-title">Export Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.register_itemRef | docRef:'invoice_number' | async}}
                  <span *ngIf="data?.newdata?.register_itemRef">
                    to
                    <strong class="change">
                      {{data?.newdata?.register_itemRef | docRef:'invoice_number' |
                      async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old CO Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New CO Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>




            
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Customs Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Customs Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>



          </ng-container>


          <ng-container *ngIf="collection === 'register_invoice_info'">
            <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
              <div class="dl-title">Import Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.invoice_number}}
                  <span *ngIf="data?.newdata?.invoice_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.invoice_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
              <div class="dl-title">Import Invoice Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.invoice_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.invoice_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.invoice_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.permit_number">
              <div class="dl-title"> Permit Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.permit_number}}
                  <span *ngIf="data?.newdata?.permit_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.permit_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.permit_date | isDifDay:data?.olddata?.permit_date">
              <div class="dl-title"> Permit Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.permit_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.permit_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.permit_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.custom_number">
              <div class="dl-title"> Custom Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.custom_number}}
                  <span *ngIf="data?.newdata?.custom_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.custom_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.custom_date | isDifDay:data?.olddata?.custom_date">
              <div class="dl-title"> Custom Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.custom_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.custom_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.custom_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.camControl_number">
              <div class="dl-title"> Cam Control Number</div>
              <div class="dl-text">:

                <div class="data">
                  {{data?.olddata?.camControl_number}}
                  <span *ngIf="data?.newdata?.camControl_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.camControl_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.camControl_date | isDifDay:data?.olddata?.camControl_date">
              <div class="dl-title"> Cam Control Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.camControl_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.camControl_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.camControl_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.bill_number">
              <div class="dl-title">Bill Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.bill_number}}
                  <span *ngIf="data?.newdata?.bill_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.bill_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title">Application Form</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.co_formRef | docRef: 'name' | async}}

                  <span *ngIf="data?.newdata?.co_formKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef: 'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'register_local_invoice_info'">
            <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
              <div class="dl-title">Local Purchase Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.invoice_number}}
                  <span *ngIf="data?.newdata?.invoice_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.invoice_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
              <div class="dl-title">Local Purchase Invoice Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.invoice_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.invoice_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.invoice_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.permit_number">
              <div class="dl-title"> Permit Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.permit_number}}
                  <span *ngIf="data?.newdata?.permit_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.permit_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.permit_date | isDifDay:data?.olddata?.permit_date">
              <div class="dl-title"> Permit Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.permit_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.permit_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.permit_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.custom_number">
              <div class="dl-title"> Custom Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.custom_number}}
                  <span *ngIf="data?.newdata?.custom_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.custom_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.custom_date | isDifDay:data?.olddata?.custom_date">
              <div class="dl-title"> Custom Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.custom_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.custom_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.custom_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.camControl_number">
              <div class="dl-title"> Cam Control Number</div>
              <div class="dl-text">:

                <div class="data">
                  {{data?.olddata?.camControl_number}}
                  <span *ngIf="data?.newdata?.camControl_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.camControl_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.camControl_date | isDifDay:data?.olddata?.camControl_date">
              <div class="dl-title"> Cam Control Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.camControl_date?.toDate() | date}}

                  <span *ngIf="data?.newdata?.camControl_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.camControl_date?.toDate() | date}}

                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.bill_number">
              <div class="dl-title">Bill Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.bill_number}}
                  <span *ngIf="data?.newdata?.bill_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.bill_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title">Application Form</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.co_formRef | docRef: 'name' | async}}

                  <span *ngIf="data?.newdata?.co_formKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef: 'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>


            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Customs Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Customs Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


          </ng-container>

          

          <ng-container *ngIf="collection === 'local_cut_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef:'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.description">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.description">
                    {{data?.olddata?.description}}
                  </span>
                  <span *ngIf="data?.newdata?.description">to
                    <strong class="change">
                      {{data?.newdata?.description}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCo">
              <div class="dl-title"> Local Sale Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCo">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Local Sale Inv Number</th>
                    <th class="table-col center">Local Sale Inv Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.newdata?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>




          </ng-container>
          <ng-container *ngIf="collection === 'register_cut_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef:'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.description">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.description">
                    {{data?.olddata?.description}}
                  </span>
                  <span *ngIf="data?.newdata?.description">to
                    <strong class="change">
                      {{data?.newdata?.description}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCo">
              <div class="dl-title"> Items</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCo">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO Export Invoice Number</th>
                    <th class="table-col center">CO Export Invoice Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.newdata?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


          </ng-container>


          <ng-container *ngIf="collection === 'register_import_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef:'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.description">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.description">
                    {{data?.olddata?.description}}
                  </span>
                  <span *ngIf="data?.newdata?.description">to
                    <strong class="change">
                      {{data?.newdata?.description}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.items">
              <div class="dl-title"> CO Import Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO Import Invoice Number</th>
                    <th class="table-col center">CO Import Invoice Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.newdata?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'register_local_import_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef:'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.description">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.description">
                    {{data?.olddata?.description}}
                  </span>
                  <span *ngIf="data?.newdata?.description">to
                    <strong class="change">
                      {{data?.newdata?.description}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.items">
              <div class="dl-title"> Local Purchase Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Local Sale Inv Number</th>
                    <th class="table-col center">Local Sale Inv Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.newdata?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>

          

          <ng-container *ngIf="collection === 'register_cancel_co_letter' && data?.audit_type?.key !== 2">

            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title">Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>



            <button mat-button class="enbutton primary" (click)="preview(data)">View
              Detail</button>
          </ng-container>

          <ng-container *ngIf="collection === 'register_duplicate_letter' && data?.audit_type?.key !== 2">

            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title">Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <button mat-button class="enbutton primary" (click)="preview(data)">View
              Detail</button>
          </ng-container>

          <ng-container *ngIf="collection === 'register_other_letter' && data?.audit_type?.key !== 2">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title">Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <button mat-button class="enbutton primary" (click)="preview(data)">View
              Detail</button>
          </ng-container>

          <ng-container *ngIf="collection === 'register_reapply_letter' && data?.audit_type?.key !== 2">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title">Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <button mat-button class="enbutton primary" (click)="preview(data)">View
              Detail</button>
          </ng-container>

          <ng-container *ngIf="collection === 'register_letter_form' && data?.audit_type?.key !== 2">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title">Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <button mat-button class="enbutton primary" (click)="preview(data)">View
              Detail</button>
          </ng-container>

          <ng-container *ngIf="collection == 'register_re_apply'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>


            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title"> Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title"> CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.co_formRef">
                    {{data?.olddata?.co_formRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.co_formKey">to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.productTypeKey">
              <div class="dl-title"> Product Type</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.productTypeRef">
                    {{data?.olddata?.productTypeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.productTypeKey">to
                    <strong class="change">
                      {{data?.newdata?.productTypeKey | docKeyRef:'settings_product_type':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.routeKey">
              <div class="dl-title"> Route</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.routeRef">
                    {{data?.olddata?.routeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.routeKey">to
                    <strong class="change">
                      {{data?.newdata?.routeKey | docKeyRef:'settings_route':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Export CO</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO No</th>
                    <th class="table-col center">CO Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">
                      {{i+1}}
                    </td>
                    <td class="table-col ">
                      {{item?.registerCoRef | docRef :'co_number' | async}}
                    </td>
                    <td class="table-col ">
                      {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRef :'co_number' | async}}
                      </td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_duplicate'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>


            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title"> Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title"> CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.co_formRef">
                    {{data?.olddata?.co_formRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.co_formKey">to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.productTypeKey">
              <div class="dl-title"> Product Type</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.productTypeRef">
                    {{data?.olddata?.productTypeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.productTypeKey">to
                    <strong class="change">
                      {{data?.newdata?.productTypeKey | docKeyRef:'settings_product_type':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.routeKey">
              <div class="dl-title"> Route</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.routeRef">
                    {{data?.olddata?.routeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.routeKey">to
                    <strong class="change">
                      {{data?.newdata?.routeKey | docKeyRef:'settings_route':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Items</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO No</th>
                    <th class="table-col center">CO Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">
                      {{i+1}}
                    </td>
                    <td class="table-col ">
                      {{item?.registerCoRef | docRef :'co_number' | async}}
                    </td>
                    <td class="table-col ">
                      {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRef :'co_number' | async}}
                      </td>
                      <td class="table-col ">
                        {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>

          

          <ng-container *ngIf="collection === 'register_master_list'" >
            <div class="dl-items" *ngIf="data?.newdata?.masterId">
              <div class="dl-title"> Master list ID</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.masterId">
                    {{data?.olddata?.masterId}}
                  </span>
                  <span *ngIf="data?.newdata?.masterId">to
                    <strong class="change">
                      {{data?.newdata?.masterId}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.approvedDate | isDifDay:data?.olddata?.approvedDate">
              <div class="dl-title">Approved Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.approvedDate ">
                    {{data?.olddata?.approvedDate?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.approvedDate">to
                    <strong class="change">
                      {{data?.newdata?.approvedDate?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.fromDate | isDifDay:data?.olddata?.fromDate">
              <div class="dl-title">From Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.fromDate">
                    {{data?.olddata?.fromDate?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.fromDate">to
                    <strong class="change">
                      {{data?.newdata?.fromDate?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.toDate | isDifDay:data?.olddata?.toDate">
              <div class="dl-title">To Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.toDate">
                    {{data?.olddata?.toDate?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.toDate">to
                    <strong class="change">
                      {{data?.newdata?.toDate?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Customs Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Customs Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

       
          </ng-container>


          <ng-container *ngIf="collection === 'register_material' || collection === 'register_import_material'" >
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title"> Code</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.code">
                    {{data?.olddata?.code}}
                  </span>
                  <span *ngIf="data?.newdata?.code">to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">Name</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.name">
                    {{data?.olddata?.name}}
                  </span>
                  <span *ngIf="data?.newdata?.name">to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.uomKey">
              <div class="dl-title">UOM</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.uomRef">
                    {{data?.olddata?.uomRef | docRef:'code' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.uomKey">to
                    <strong class="change">
                      {{data?.newdata?.uomKey | docKeyRef:'settings_uom':'code' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.subItem?.code">
              <div class="dl-title">Sub Item Of</div>
              <div class="dl-text">
                :
                <div class="data">
                  {{data?.olddata?.subItem?.code}}
                  <span *ngIf="data?.newdata?.subItem?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.subItem?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'register_co'">
            <div class="dl-items" *ngIf="data?.newdata?.register_itemRef">
              <div class="dl-title">Export Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.register_itemRef | docRef:'invoice_number' | async}}
                  <span *ngIf="data?.newdata?.register_itemRef">
                    to
                    <strong class="change">
                      {{data?.newdata?.register_itemRef | docRef:'invoice_number' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.co_number">
              <div class="dl-title">Export CO Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.co_number}}
                  <span *ngIf="data?.newdata?.co_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.co_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.toDate | isDifDay:data?.olddata?.toDate">
              <div class="dl-title">Export CO Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.co_date?.toDate() | date}}
                  <span *ngIf="data?.newdata?.toDate">
                    to
                    <strong class="change">
                      {{data?.newdata?.co_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.etd | isDifDay:data?.olddata?.etd">
              <div class="dl-title"> Shipping Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.etd?.toDate() | date}}
                  <span *ngIf="data?.newdata?.etd">
                    to
                    <strong class="change">
                      {{data?.newdata?.etd?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.admFee > 0">
              <div class="dl-title">ADM Fee (KHR)</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.admFee">
                    {{data?.olddata?.admFee | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.admFee">
                    to
                    <strong class="change">
                      {{data?.newdata?.admFee | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.emfFee > 0">
              <div class="dl-title">EMF Fee (KHR)</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.emfFee">
                    {{data?.olddata?.emfFee | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.emfFee">
                    to
                    <strong class="change">
                      {{data?.newdata?.emfFee | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'pickup_bill'">
            <div class="dl-items" *ngIf="data?.newdata?.bill_number">
              <div class="dl-title"> Export Bill Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.bill_number}}
                  <span *ngIf="data?.newdata?.bill_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.bill_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.bill_date | isDifDay:data?.olddata?.bill_date">
              <div class="dl-title"> Export Bill Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.bill_date?.toDate() | date}}
                  <span *ngIf="data?.newdata?.bill_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.bill_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>

            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items?.length > 0">
              <table class="table-wrapper tb-master">

                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Invoice Number</th>
                    <th class="table-col center">Invoice Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.items;let i=index">
                    <tr class="table-row" *ngIf="item?.itemRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'invoice_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'pickup_inspect'">
            <div class="dl-items" *ngIf="data?.newdata?.inspect_number">
              <div class="dl-title"> Inspect Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.inspect_number}}
                  <span *ngIf="data?.newdata?.inspect_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.inspect_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.inspect_date | isDifDay:data?.olddata?.inspect_date">
              <div class="dl-title"> Inspect Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.inspect_date?.toDate() | date}}
                  <span *ngIf="data?.newdata?.inspect_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.inspect_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items?.length > 0">
              <table class="table-wrapper tb-master">

                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Invoice Number</th>
                    <th class="table-col center">Invoice Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.itemRef|docRef:'invoice_number'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.items;let i=index">
                    <tr class="table-row" *ngIf="item?.itemRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'invoice_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'register_invoice'">
            <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
              <div class="dl-title"> Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.invoice_number">
                    {{data?.olddata?.invoice_number}}
                  </span>
                  <span *ngIf="data?.newdata?.invoice_number">to
                    <strong class="change">
                      {{data?.newdata?.invoice_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
              <div class="dl-title"> Invoice Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.invoice_date">
                    {{data?.olddata?.invoice_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.invoice_date">to
                    <strong class="change">
                      {{data?.newdata?.invoice_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.export_to">
              <div class="dl-title">Export To</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.export_to">
                    {{data?.olddata?.export_to}}
                  </span>
                  <span *ngIf="data?.newdata?.export_to">to
                    <strong class="change">
                      {{data?.newdata?.export_to}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.cat > 0">
              <div class="dl-title">CAT</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.cat > 0">
                    {{data?.olddata?.cat | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.cat > 0">to
                    <strong class="change">
                      {{data?.newdata?.cat | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.countryKey">
              <div class="dl-title"> Import Country</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.countryRef">
                    {{data?.olddata?.countryRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.countryKey">to
                    <strong class="change">
                      {{data?.newdata?.countryKey | docKeyRef: 'settings_country':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.qty_order">
              <div class="dl-title"> Qty Order</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.qty_order">
                    {{data?.olddata?.qty_order | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.qty_order">to
                    <strong class="change">
                      {{data?.newdata?.qty_order | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.qty_order_uomKey">
              <div class="dl-title"> Oty UOM</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.qty_order_uomRef">
                    {{data?.olddata?.qty_order_uomRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.qty_order_uomKey">to
                    <strong class="change">
                      {{data?.newdata?.qty_order_uomKey | docKeyRef: 'settings_uom':'code' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Material Code</th>
                    <th class="table-col center">Material Name</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                    <tr class="table-row" *ngIf="item?.materialRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'register_local_invoice'">
            <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
              <div class="dl-title">Local Purchase Invoice Number</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.invoice_number">
                    {{data?.olddata?.invoice_number}}
                  </span>
                  <span *ngIf="data?.newdata?.invoice_number">to
                    <strong class="change">
                      {{data?.newdata?.invoice_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
              <div class="dl-title">Local Purchase Invoice Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.invoice_date">
                    {{data?.olddata?.invoice_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.invoice_date">to
                    <strong class="change">
                      {{data?.newdata?.invoice_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.export_to">
              <div class="dl-title">Export To</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.export_to">
                    {{data?.olddata?.export_to}}
                  </span>
                  <span *ngIf="data?.newdata?.export_to">to
                    <strong class="change">
                      {{data?.newdata?.export_to}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.cat > 0">
              <div class="dl-title">CAT</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.cat > 0">
                    {{data?.olddata?.cat | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.cat > 0">to
                    <strong class="change">
                      {{data?.newdata?.cat | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.countryKey">
              <div class="dl-title"> Import Country</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.countryRef">
                    {{data?.olddata?.countryRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.countryKey">to
                    <strong class="change">
                      {{data?.newdata?.countryKey | docKeyRef: 'settings_country':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.qty_order">
              <div class="dl-title"> Qty Order</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.qty_order">
                    {{data?.olddata?.qty_order | number:'1.'}}
                  </span>
                  <span *ngIf="data?.newdata?.qty_order">to
                    <strong class="change">
                      {{data?.newdata?.qty_order | number:'1.'}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.qty_order_uomKey">
              <div class="dl-title"> Oty UOM</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.qty_order_uomRef">
                    {{data?.olddata?.qty_order_uomKey | docKeyRef:  'settings_uom':'code' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.qty_order_uomKey">to
                    <strong class="change">
                      {{data?.newdata?.qty_order_uomKey | docKeyRef: 'settings_uom':'code' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.purchase_number">
              <div class="dl-title">Purchase Number</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.purchase_number">
                    {{data?.olddata?.purchase_number}}
                  </span>
                  <span *ngIf="data?.newdata?.purchase_number">to
                    <strong class="change">
                      {{data?.newdata?.purchase_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.purchase_date | isDifDay:data?.olddata?.purchase_date">
              <div class="dl-title">Purchase Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.purchase_date">
                    {{data?.olddata?.purchase_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.purchase_date">to
                    <strong class="change">
                      {{data?.newdata?.purchase_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Material Code</th>
                    <th class="table-col center">Material Name</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.material;let i=index">
                    <tr class="table-row" *ngIf="item?.materialRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>

            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Customs Material</strong>
                    </td>
                  </tr>
                  <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Customs Material</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            
          </ng-container>

          



          <ng-container *ngIf="collection === 'register_soo'">
            <ng-container *ngIf="data?.type?.key === 1;">
              <div class="dl-items" *ngIf="data?.newdata?.soo_number">
                <div class="dl-title"> SOO Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.soo_number}}
                    <span *ngIf="data?.newdata?.soo_number">
                      to
                      <strong class="change">
                        {{data?.newdata?.soo_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.soo_date | isDifDay:data?.olddata?.soo_date">
                <div class="dl-title"> SOO Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.soo_date?.toDate() | date}}
                    <span *ngIf="data?.newdata?.soo_date">
                      to
                      <strong class="change">
                        {{data?.newdata?.soo_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.amount > 0">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.amount | currency:' ':'code'}}
                    <span *ngIf="data?.newdata?.amount">
                      to
                      <strong class="change">
                        {{data?.newdata?.amount | currency:' ':'code'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
                <div class="dl-title"> khmer Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.khmerDate}}
                    <span *ngIf="data?.newdata?.khmerDate">
                      to
                      <strong class="change">
                        {{data?.newdata?.khmerDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
                <div class="dl-title"> khmer Lunar Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.lunarDate}}
                    <span *ngIf="data?.newdata?.lunarDate">
                      to
                      <strong class="change">
                        {{data?.newdata?.lunarDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              
              <div class="dl-items" *ngIf="data?.newdata?.quality_cnt">
                <div class="dl-title"> Quality Packing</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.quality_cnt | number:'1.'}}
                    <span *ngIf="data?.newdata?.quality_cnt">
                      to
                      <strong class="change">
                        {{data?.newdata?.quality_cnt | number:'1.'}}
                      </strong>
                    </span>
                  </div>

                  <span style="padding-left: 10px;" *ngIf="data?.olddata?.qty_packing_UOMRef">
                    {{data?.olddata?.qty_packing_UOMRef | docRef:'code' | async}}
                  </span>
                  <span style="padding-left: 10px;" *ngIf="data?.newdata?.qty_packing_UOMKey">to
                    {{data?.newdata?.qty_packing_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                  </span>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.quality_pcs">
                <div class="dl-title"> Quality Unit</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.quality_pcs | number:'1.'}}
                    <span *ngIf="data?.newdata?.quality_pcs">
                      to
                      <strong class="change">
                        {{data?.newdata?.quality_pcs | number:'1.'}}
                      </strong>
                    </span>
                  </div>

                  <span style="padding-left: 10px;" *ngIf="data?.olddata?.qty_unit_UOMRef">
                    {{data?.olddata?.qty_unit_UOMRef | docRef:'code' | async}}
                  </span>
                  <span style="padding-left: 10px;" *ngIf="data?.newdata?.qty_unit_UOMKey">to
                    {{data?.newdata?.qty_unit_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                  </span>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.items">
                <div class="dl-title"> SOO</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Export CO Number</th>
                      <th class="table-col center">Export CO Date</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.items;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </ng-container>
            <ng-container *ngIf="data?.type?.key === 2;">
              <div class="dl-items" *ngIf="data?.newdata?.co_number">
                <div class="dl-title"> CO Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.co_number}}
                    <span *ngIf="data?.newdata?.co_number">
                      to
                      <strong class="change">
                        {{data?.newdata?.co_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.co_date | isDifDay:data?.olddata?.co_date">
                <div class="dl-title"> CO Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.co_date?.toDate() | date}}
                    <span *ngIf="data?.newdata?.co_date">
                      to
                      <strong class="change">
                        {{data?.newdata?.co_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.amount_FOB">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.amount_FOB | currency:' ':'code'}}

                    <span *ngIf="data?.newdata?.amount_FOB">
                      to
                      <strong class="change">
                        {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.inspect_number">
                <div class="dl-title">Inspect Number</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.inspect_number}}
                    <span *ngIf="data?.newdata?.inspect_number">
                      to
                      <strong class="change">
                        {{data?.newdata?.inspect_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
                <div class="dl-title">Inspect Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.invoice_date?.toDate() | date}}
                    <span *ngIf="data?.newdata?.invoice_date">
                      to
                      <strong class="change">
                        {{data?.newdata?.invoice_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
                <div class="dl-title"> khmer Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.khmerDate}}
                    <span *ngIf="data?.newdata?.khmerDate">
                      to
                      <strong class="change">
                        {{data?.newdata?.khmerDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
                <div class="dl-title"> khmer Lunar Date</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.lunarDate}}
                    <span *ngIf="data?.newdata?.lunarDate">
                      to
                      <strong class="change">
                        {{data?.newdata?.lunarDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.items">
                <div class="dl-title"> Items</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Soo Number</th>
                      <th class="table-col center">Soo Date</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.items;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.soo_number}}</td>
                      <td class="table-col">
                        {{item?.soo_date?.toDate() | date}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.items;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.soo_number}}</td>
                      <td class="table-col">
                        {{item?.soo_date?.toDate() | date}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="collection === 'register_soo_statement'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">
                    to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.soo_date | isDifDay:data?.olddata?.soo_date">
              <div class="dl-title"> SOO Statement Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.soo_date?.toDate() | date}}
                  <span *ngIf="data?.newdata?.soo_date">
                    to
                    <strong class="change">
                      {{data?.newdata?.soo_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>

            </div>

            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.items">
              <div class="dl-title"> SOO</div>
              <div class="dl-text">:</div>
            </div>

            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.items">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">SOO NO</th>
                    <th class="table-col center">SOO DATE</th>
                  </tr>
                </thead>
                <tbody class="table-body">

                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let array of data?.olddata?.items;let i=index">
                    <ng-container *ngIf="array.type.key === 1">
                      <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{array?.itemRef|docRef:'soo_number'|async}}
                        </td>
                        <td class="table-col">
                          {{array?.itemRef | docRefDate:'soo_date' | async | date}}
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="array.type.key === 2">
                      <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{item?.soo_number}}
                        </td>
                        <td class="table-col">
                          {{item?.soo_date?.toDate() | date}}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>

                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let array of data?.newdata?.items;let i=index">
                    <ng-container *ngIf="array.type.key === 1">
                      <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{array?.itemRef|docRef:'soo_number'|async}}
                        </td>
                        <td class="table-col">
                          {{array?.itemRef | docRefDate:'soo_date' | async | date}}
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="array.type.key === 2">
                      <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{item?.soo_number}}
                        </td>
                        <td class="table-col">
                          {{item?.soo_date?.toDate() | date}}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'register_adjustment_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title"> Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.importMaterial">
              <div class="dl-title"> Customs Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Customs Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Customs Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            
          </ng-container>

          <ng-container *ngIf="collection === 'register_adjustment_stock_under'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.memo">
              <div class="dl-title"> Memo</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.memo">
                    {{data?.olddata?.memo}}
                  </span>
                  <span *ngIf="data?.newdata?.memo">to
                    <strong class="change">
                      {{data?.newdata?.memo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.materials">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


            <div class="dl-items" *ngIf="data?.newdata?.importMaterial">
              <div class="dl-title"> Customs Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Code</th>
                    <th class="table-col center">Description</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Customs Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Customs Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'register_ob_stock'">
            <div style="padding: 0">
              <div class="dl-items" *ngIf="data?.newdata?.refNo">
                <div class="dl-title"> Reference No.</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.refNo">
                      {{data?.olddata?.refNo}}
                    </span>
                    <span *ngIf="data?.newdata?.refNo">to
                      <strong class="change">
                        {{data?.newdata?.refNo}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
                <div class="dl-title"> Application Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.application_date">
                      {{data?.olddata?.application_date?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.application_date">to
                      <strong class="change">
                        {{data?.newdata?.application_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
                <div class="dl-title"> Khmer Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.khmerDate">
                      {{data?.olddata?.khmerDate}}
                    </span>
                    <span *ngIf="data?.newdata?.khmerDate">to
                      <strong class="change">
                        {{data?.newdata?.khmerDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
                <div class="dl-title"> Khmer Lunar Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.lunarDate">
                      {{data?.olddata?.lunarDate}}
                    </span>
                    <span *ngIf="data?.newdata?.lunarDate">to
                      <strong class="change">
                        {{data?.newdata?.lunarDate}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.memo">
                <div class="dl-title"> Memo</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.memo">
                      {{data?.olddata?.memo}}
                    </span>
                    <span *ngIf="data?.newdata?.memo">to
                      <strong class="change">
                        {{data?.newdata?.memo}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Register Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.register_status?.text}}
                  </div>
                </div>
              </div>



              <div class="dl-items" *ngIf="data?.newdata?.materials">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.materials?.length>0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Code</th>
                      <th class="table-col center">Description</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <ng-container>
                      <tr class="table-row">
                        <td class="table-col" colspan="3">
                          <strong>Old CO Materials</strong>
                        </td>
                      </tr>
                      <tr class="table-row" *ngFor="let item of data?.olddata?.materials;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'name'|async}}</td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col" colspan="3">
                          <strong>New CO Materials</strong>
                        </td>
                      </tr>
                      <tr class="table-row" *ngFor="let item of data?.newdata?.materials;let i=index">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'code'|async}}</td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'name'|async}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>



              <div class="dl-items" *ngIf="data?.newdata?.importMaterial">
                <div class="dl-title"> Customs Material</div>
                <div class="dl-text">:</div>
              </div>
              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.importMaterial?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Code</th>
                      <th class="table-col center">Description</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Customs Material</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.importMaterial;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Customs Material</strong>
                      </td>
                    </tr>
                    <ng-container *ngFor="let item of data?.newdata?.importMaterial;let i=index">
                      <tr class="table-row">
                        <td class="table-col">{{i+1}}</td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'code'|async}}
                        </td>
                        <td class="table-col">
                          {{item?.materialRef|docRef:'name'|async}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'register_duplicate_item'">
            <div style="padding: 0">
              <div class="dl-items" *ngIf="data?.newdata?.co_number">
                <div class="dl-title"> CO Number</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.co_number">
                      {{data?.olddata?.co_number}}
                    </span>
                    <span *ngIf="data?.newdata?.co_number">to
                      <strong class="change">
                        {{data?.newdata?.co_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.co_date | isDifDay:data?.olddata?.co_date">
                <div class="dl-title"> CO Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.co_date">
                      {{data?.olddata?.co_date?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.co_date">to
                      <strong class="change">
                        {{data?.newdata?.co_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.etd | isDifDay:data?.olddata?.etd">
                <div class="dl-title"> Shipping Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.etd">
                      {{data?.olddata?.etd?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.etd">to
                      <strong class="change">
                        {{data?.newdata?.etd?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.admFee > 0">
                <div class="dl-title">ADM Fee (KHR)</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.admFee">
                      {{data?.olddata?.admFee | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.admFee">to
                      <strong class="change">
                        {{data?.newdata?.admFee | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.emfFee > 0">
                <div class="dl-title">EMF Fee (KHR)</div>
                <div class="dl-text">:

                  <div class="data">
                    <span *ngIf="data?.olddata?.emfFee">
                      {{data?.olddata?.emfFee | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.emfFee">to
                      <strong class="change">
                        {{data?.newdata?.emfFee | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
                <div class="dl-title"> CO Form</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.co_formRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.co_formKey">to
                      <strong class="change">
                        {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.invoice_number">
                      {{data?.olddata?.invoice_number}}
                    </span>
                    <span *ngIf="data?.newdata?.invoice_number">to
                      <strong class="change">
                        {{data?.newdata?.invoice_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.invoice_date">
                      {{data?.olddata?.invoice_date?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.invoice_date">to
                      <strong class="change">
                        {{data?.newdata?.invoice_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.cat">
                <div class="dl-title"> CAT</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.cat">
                      {{data?.olddata?.cat | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.cat">to
                      <strong class="change">
                        {{data?.newdata?.cat | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.amount_FOB > 0">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.amount_FOB">
                      {{data?.olddata?.amount_FOB | currency:' ':'code'}}
                    </span>
                    <span *ngIf="data?.newdata?.amount_FOB">to
                      <strong class="change">
                        {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.countryKey">
                <div class="dl-title"> Destination</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.countryRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.countryKey">to
                      <strong class="change">
                        {{data?.newdata?.countryKey | docKeyRef:'settings_country':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.routerKey">

                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.routeRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.routerKey">to
                      <strong class="change">
                        {{data?.newdata?.routerKey | docKeyRef:'settings_route':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_packing || data?.newdata?.qty_packing_UOMKey">
                <div class="dl-title"> Qty Packing</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_packing | number:'1.'}}

                    <span *ngIf="data?.newdata?.qty_packing">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing | number:'1.'}}
                      </strong>
                    </span>

                    <span *ngIf="data?.olddata?.qty_packing_UOMRef">
                      {{data?.olddata?.qty_packing_UOMRef | docRef:'code' | async}}
                    </span>

                    <span *ngIf="data?.newdata?.qty_packing_UOMKey">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_unit_UOMRef || data?.newdata?.qty_unit_UOMKey">
                <div class="dl-title"> Qty Unit</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_unit | number:'1.'}}
                    <span *ngIf="data?.newdata?.qty_unit">
                      to
                      <strong class="change">

                        {{data?.newdata?.qty_unit | number:'1.'}}
                      </strong>
                    </span>
                    <span *ngIf="data?.olddata?.qty_unit_UOMRef">
                      {{data?.olddata?.qty_unit_UOMRef | docRef:'code' | async}}
                    </span>
                    <span *ngIf="data?.newdata?.qty_unit_UOMKey">
                      to
                      <strong class="change">

                        {{data?.newdata?.qty_unit_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Register Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.register_status?.text}}
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.material?.length > 0">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Code</th>
                      <th class="table-col center">Description</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>


          </ng-container>

          <ng-container *ngIf="collection === 'register_reapply_item'">
            <div style="padding: 0">
              <div class="dl-items" *ngIf="data?.newdata?.co_number">
                <div class="dl-title"> CO Number</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.co_number">
                      {{data?.olddata?.co_number}}
                    </span>
                    <span *ngIf="data?.newdata?.co_number">to
                      <strong class="change">
                        {{data?.newdata?.co_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.co_date | isDifDay:data?.olddata?.co_date">
                <div class="dl-title"> CO Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.co_date">
                      {{data?.olddata?.co_date?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.co_date">to
                      <strong class="change">
                        {{data?.newdata?.co_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.etd | isDifDay:data?.olddata?.etd">
                <div class="dl-title"> Shipping Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.etd">
                      {{data?.olddata?.etd?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.etd">to
                      <strong class="change">
                        {{data?.newdata?.etd?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.admFee > 0">
                <div class="dl-title">ADM Fee (KHR)</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.admFee">
                      {{data?.olddata?.admFee | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.admFee">to
                      <strong class="change">
                        {{data?.newdata?.admFee | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.emfFee > 0">
                <div class="dl-title">EMF Fee (KHR)</div>
                <div class="dl-text">:

                  <div class="data">
                    <span *ngIf="data?.olddata?.emfFee">
                      {{data?.olddata?.emfFee | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.emfFee">to
                      <strong class="change">
                        {{data?.newdata?.emfFee | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
                <div class="dl-title"> CO Form</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.co_formRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.co_formKey">to
                      <strong class="change">
                        {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.invoice_number">
                <div class="dl-title"> Invoice Number</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.invoice_number">
                      {{data?.olddata?.invoice_number}}
                    </span>
                    <span *ngIf="data?.newdata?.invoice_number">to
                      <strong class="change">
                        {{data?.newdata?.invoice_number}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.invoice_date | isDifDay:data?.olddata?.invoice_date">
                <div class="dl-title"> Invoice Date</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.invoice_date">
                      {{data?.olddata?.invoice_date?.toDate() | date}}
                    </span>
                    <span *ngIf="data?.newdata?.invoice_date">to
                      <strong class="change">
                        {{data?.newdata?.invoice_date?.toDate() | date}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.cat">
                <div class="dl-title"> CAT</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.cat">
                      {{data?.olddata?.cat | number:'1.'}}
                    </span>
                    <span *ngIf="data?.newdata?.cat">to
                      <strong class="change">
                        {{data?.newdata?.cat | number:'1.'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.amount_FOB > 0">
                <div class="dl-title"> Amount</div>
                <div class="dl-text">:
                  <div class="data">
                    <span *ngIf="data?.olddata?.amount_FOB">
                      {{data?.olddata?.amount_FOB | currency:' ':'code'}}
                    </span>
                    <span *ngIf="data?.newdata?.amount_FOB">to
                      <strong class="change">
                        {{data?.newdata?.amount_FOB | currency:' ':'code'}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>


              <div class="dl-items" *ngIf="data?.newdata?.countryKey">
                <div class="dl-title"> Destination</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.countryRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.countryKey">to
                      <strong class="change">
                        {{data?.newdata?.countryKey | docKeyRef:'settings_country':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.routerKey">

                <div class="dl-title"> Route</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.routeRef | docRef:'name' | async}}
                    <span *ngIf="data?.newdata?.routerKey">to
                      <strong class="change">
                        {{data?.newdata?.routerKey | docKeyRef:'settings_route':'name' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_packing || data?.newdata?.qty_packing_UOMKey">
                <div class="dl-title"> Qty Packing</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_packing | number:'1.'}}

                    <span *ngIf="data?.newdata?.qty_packing">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing | number:'1.'}}
                      </strong>
                    </span>

                    <span *ngIf="data?.olddata?.qty_packing_UOMRef">
                      {{data?.olddata?.qty_packing_UOMRef | docRef:'code' | async}}
                    </span>

                    <span *ngIf="data?.newdata?.qty_packing_UOMKey">
                      to
                      <strong class="change">
                        {{data?.newdata?.qty_packing_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.qty_unit_UOMRef || data?.newdata?.qty_unit_UOMKey">
                <div class="dl-title"> Qty Unit</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.qty_unit | number:'1.'}}
                    <span *ngIf="data?.newdata?.qty_unit">
                      to
                      <strong class="change">

                        {{data?.newdata?.qty_unit | number:'1.'}}
                      </strong>
                    </span>
                    <span *ngIf="data?.olddata?.qty_unit_UOMRef">
                      {{data?.olddata?.qty_unit_UOMRef | docRef:'code' | async}}
                    </span>
                    <span *ngIf="data?.newdata?.qty_unit_UOMKey">
                      to
                      <strong class="change">

                        {{data?.newdata?.qty_unit_UOMKey | docKeyRef:'settings_uom':'code' | async}}
                      </strong>
                    </span>

                  </div>
                </div>
              </div>
              <div class="dl-items">
                <div class="dl-title"> Register Status</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.register_status?.text}}
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.material?.length > 0">
                <div class="dl-title"> CO Material</div>
                <div class="dl-text">:</div>
              </div>

              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.material?.length > 0">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col center">No.</th>
                      <th class="table-col center">Code</th>
                      <th class="table-col center">Description</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New CO Materials</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.material;let i=index">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}
                      </td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'local_delivery_cut_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo"> to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Local Sale Stock</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Reference</th>
                    <th class="table-col center">Application Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'local_delivery'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.refNo}}
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.application_date?.toDate() | date}}
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.khmerDate}}
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">

                  {{data?.olddata?.lunarDate}}
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Local Sale Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center"> Invoice No</th>
                    <th class="table-col center">Invoice Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col ">
                      {{item?.invoice_number}}
                    </td>
                    <td class="table-col ">
                      {{item?.invoice_date?.toDate() | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr *ngIf="item?.invoice_number">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.invoice_number}}
                      </td>
                      <td class="table-col">
                        {{item?.invoice_date?.toDate() | date}}
                      </td>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'register_delivery_export_document'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Export Inspect</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Export Inspect Number</th>
                    <th class="table-col center">Export Inspect Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'inspect_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRefDate:'inspect_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row" *ngIf="item?.itemRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'inspect_number'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRefDate:'inspect_date' | async | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'delivery_return_application'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Return</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">ReFerence No</th>
                    <th class="table-col center">Application Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>

                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRefDate:'application_date' | async |
                        date}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row" *ngIf="item?.itemRef">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRefDate:'application_date' | async |
                        date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'register_delivery_co_application'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Export CO</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO Number</th>
                    <th class="table-col center">CO Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col ">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col ">
                        {{item?.co_date?.toDate() | date}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col ">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col ">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'register_delivery_import_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> CO Import Stock</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Reference</th>
                    <th class="table-col center">Application Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>


          <ng-container *ngIf="collection === 'register_delivery_local_import_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Local Purchase Stock</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Reference</th>
                    <th class="table-col center">Application Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>

          

          <ng-container *ngIf="collection === 'register_delivery_cut_stock'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> CO Cut Stock</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Reference</th>
                    <th class="table-col center">Application Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">
                        {{i+1}}
                      </td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.itemRef|docRef:'refNo'|async}}</td>
                      <td class="table-col">
                        {{item?.itemRef| docRefDate:'application_date' | async |
                        dateOnly}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>



          <ng-container *ngIf="collection === 'register_submit_form'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.productTypeKey">
              <div class="dl-title"> Product Type</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.productTypeRef">
                    {{data?.olddata?.productTypeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.productTypeKey">to
                    <strong class="change">
                      {{data?.newdata?.productTypeKey | docKeyRef:'settings_product_type':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.routeKey">
              <div class="dl-title"> Route</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.routeRef">
                    {{data?.olddata?.routeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.routeKey">to
                    <strong class="change">
                      {{data?.newdata?.routeKey | docKeyRef:'settings_route':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title"> CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.co_formRef">
                    {{data?.olddata?.co_formRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.co_formKey">to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Items</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO No</th>
                    <th class="table-col center">CO Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">
                      {{i+1}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_number}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_date?.toDate() | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row" *ngIf="item?.co_number">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>



          <ng-container *ngIf="collection === 'register_cancel_co'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>


            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title"> Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title"> CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.co_formRef">
                    {{data?.olddata?.co_formRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.co_formKey">to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.productTypeKey">
              <div class="dl-title"> Product Type</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.productTypeRef">
                    {{data?.olddata?.productTypeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.productTypeKey">to
                    <strong class="change">
                      {{data?.newdata?.productTypeKey | docKeyRef:'settings_product_type':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.routeKey">
              <div class="dl-title"> Route</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.routeRef">
                    {{data?.olddata?.routeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.routeKey">to
                    <strong class="change">
                      {{data?.newdata?.routeKey | docKeyRef:'settings_route':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Export CO</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO No</th>
                    <th class="table-col center">CO Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">
                      {{i+1}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_number}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_date?.toDate() | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row" *ngIf="item?.co_number">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>
          <ng-container *ngIf="collection === 'register_return_application'">
            <div class="dl-items" *ngIf="data?.newdata?.refNo">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.refNo">
                    {{data?.olddata?.refNo}}
                  </span>
                  <span *ngIf="data?.newdata?.refNo">to
                    <strong class="change">
                      {{data?.newdata?.refNo}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.application_date | isDifDay:data?.olddata?.application_date">
              <div class="dl-title"> Application Date</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.application_date">
                    {{data?.olddata?.application_date?.toDate() | date}}
                  </span>
                  <span *ngIf="data?.newdata?.application_date">to
                    <strong class="change">
                      {{data?.newdata?.application_date?.toDate() | date}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>


            <div class="dl-items" *ngIf="data?.newdata?.titleKey">
              <div class="dl-title"> Title</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.titleRef">
                    {{data?.olddata?.titleRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.titleKey">to
                    <strong class="change">
                      {{data?.newdata?.titleKey | docKeyRef:'settings_title':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.co_formKey">
              <div class="dl-title"> CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.co_formRef">
                    {{data?.olddata?.co_formRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.co_formKey">to
                    <strong class="change">
                      {{data?.newdata?.co_formKey | docKeyRef:'settings_coform':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.productTypeKey">
              <div class="dl-title"> Product Type</div>
              <div class="dl-text">:

                <div class="data">
                  <span *ngIf="data?.olddata?.productTypeRef">
                    {{data?.olddata?.productTypeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.productTypeKey">to
                    <strong class="change">
                      {{data?.newdata?.productTypeKey | docKeyRef:'settings_product_type':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.routeKey">
              <div class="dl-title"> Route</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.routeRef">
                    {{data?.olddata?.routeRef | docRef: 'name' | async}}
                  </span>
                  <span *ngIf="data?.newdata?.routeKey">to
                    <strong class="change">
                      {{data?.newdata?.routeKey | docKeyRef:'settings_route':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.khmerDate">
              <div class="dl-title"> Khmer Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.khmerDate">
                    {{data?.olddata?.khmerDate}}
                  </span>
                  <span *ngIf="data?.newdata?.khmerDate">to
                    <strong class="change">
                      {{data?.newdata?.khmerDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.lunarDate">
              <div class="dl-title"> Khmer Lunar Date</div>
              <div class="dl-text">:
                <div class="data">
                  <span *ngIf="data?.olddata?.lunarDate">
                    {{data?.olddata?.lunarDate}}
                  </span>
                  <span *ngIf="data?.newdata?.lunarDate">to
                    <strong class="change">
                      {{data?.newdata?.lunarDate}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Register Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.register_status?.text}}
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.registerCoItems">
              <div class="dl-title"> Export CO</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.registerCoItems">
              <table class="table-wrapper tb-master ">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">CO No</th>
                    <th class="table-col center">CO Date</th>
                  </tr>

                </thead>
                <tbody class="table-body">
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>Old Items</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of data?.olddata?.registerCoItems;let i=index">
                    <td class="table-col">
                      {{i+1}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_number}}
                    </td>
                    <td class="table-col ">
                      {{item?.co_date?.toDate() | date}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col" colspan="3">
                      <strong>New Items</strong>
                    </td>
                  </tr>
                  <ng-container *ngFor="let item of data?.newdata?.registerCoItems;let i=index">
                    <tr class="table-row" *ngIf="item?.co_number">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">
                        {{item?.co_number}}
                      </td>
                      <td class="table-col">
                        {{item?.co_date?.toDate() | date}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>


          <ng-container *ngIf="collection === 'users'">
            <div style="padding: 0">
              <div class="dl-items" *ngIf="data?.newdata?.full_name">
                <div class="dl-title">Full name</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata.full_name}}
                    <span *ngIf="data?.newdata?.full_name">
                      to
                      <strong class="change">
                        {{data?.newdata?.full_name}}
                      </strong>
                    </span>
                  </div>
                </div>


              </div>
              <div class="dl-items" *ngIf="data?.newdata?.email">
                <div class="dl-title">Email</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata?.email}}
                    <span *ngIf="data?.newdata?.email">
                      to
                      <strong class="change">
                        {{data?.newdata?.email}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.phone">
                <div class="dl-title">Phone Number</div>
                <div class="dl-text">:

                  <div class="data">
                    {{data?.olddata?.phone}}
                    <span *ngIf="data?.newdata?.phone">
                      to
                      <strong class="change">
                        {{data?.newdata?.phone}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.position">
                <div class="dl-title">Position</div>
                <div class="dl-text">
                  :
                  <div class="data">
                    {{data?.olddata?.position}}
                    <span *ngIf="data?.newdata?.position">
                      to
                      <strong class="change">
                        {{data?.newdata?.position}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.company">
                <div class="dl-title">Company</div>
                <div class="dl-text">
                  :
                </div>
                <div class="data">
                  <ng-container *ngIf="data && data?.olddata?.company && data?.olddata?.company?.length>0">
                    <ng-container *ngFor="let f of data?.olddata?.company; let i=index; let fs=first;">
                      <div class="dl-items">
                        <div class="dl-text">
                          <div class="data ">
                            {{f | docRef:'name_en' | async}}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>


                  <ng-container *ngIf="data && data?.newdata?.company && data?.newdata?.company?.length>0">
                    to
                    <strong *ngFor="let f of data?.newdata?.company; let i=index; let fs=first;">
                      <div class="dl-items">
                        <div class="dl-text">
                          <div class="data change">
                            {{f | docRef:'name_en' | async}}
                          </div>
                        </div>
                      </div>
                    </strong>
                  </ng-container>
                </div>
              </div>

              <div class="dl-items" *ngIf="data?.newdata?.roles">
                <div class="dl-title">Roles</div>
                <div class="dl-text">
                  :
                </div>
                <div class="data">
                  <ng-container *ngIf="data && data?.olddata?.roles && data?.olddata?.roles?.length>0">
                    <ng-container *ngFor="let f of data?.olddata?.roles; let i=index; let fs=first;">
                      <div class="dl-items">
                        <div class="dl-text">
                          <div class="data ">
                            {{f.title}}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="data && data?.newdata?.roles && data?.newdata?.roles?.length>0">
                    to
                    <strong *ngFor="let f of data?.newdata?.roles; let i=index; let fs=first;">
                      <div class="dl-items">
                        <div class="dl-text">
                          <div class="data change">
                            {{f.title}}
                          </div>
                        </div>
                      </div>
                    </strong>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'user_roles'">
            <div style="padding: 0">
              <div class="dl-items" *ngIf="data?.newdata?.title">
                <div class="dl-title">Title</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata.title}}
                    <span *ngIf="data?.newdata?.title">
                      to
                      <strong class="change">
                        {{data?.newdata?.title}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="dl-items" *ngIf="data?.newdata?.description">
                <div class="dl-title">Description</div>
                <div class="dl-text">:
                  <div class="data">
                    {{data?.olddata.description}}
                    <span *ngIf="data?.newdata?.description">
                      to
                      <strong class="change">
                        {{data?.newdata?.description}}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="scrollable-table" style="margin: 0;" *ngIf="data?.newdata?.permissions">
                <table class="table-wrapper tb-master">
                  <thead class="table-header">
                    <tr class="table-row">
                      <th class="table-col ">No.</th>
                      <th class="table-col ">Application</th>
                      <th class="table-col ">Permission</th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>Old Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.olddata?.permissions;let i=index">
                      <td class="table-col">
                        <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                      </td>
                      <td class="table-col ">
                        {{item?.text}}
                      </td>
                      <td class="table-col">
                        {{item?.name}}
                      </td>

                    </tr>
                    <tr class="table-row">
                      <td class="table-col" colspan="3">
                        <strong>New Items</strong>
                      </td>
                    </tr>
                    <tr class="table-row" *ngFor="let item of data?.newdata?.permissions;let i=index">
                      <td class="table-col">
                        <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                      </td>
                      <td class="table-col ">
                        {{item?.text}}
                      </td>
                      <td class="table-col">
                        {{item?.name}}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="collection === 'company'">
            <div class="dl-items" *ngIf="data?.newdata?.name_en">
              <div class="dl-title"> Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata.name_en}}
                  <span *ngIf="data?.newdata?.name_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title">Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.email">
              <div class="dl-title">Email</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.email}}
                  <span *ngIf="data?.newdata?.email">
                    to
                    <strong class="change">
                      {{data?.newdata?.email}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.tin_no">
              <div class="dl-title">TIN No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.tin_no}}
                  <span *ngIf="data?.newdata?.tin_no">
                    to
                    <strong class="change">
                      {{data?.newdata?.tin_no}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items " *ngIf="data?.newdata?.address">
              <div class="dl-title">English Address</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.address}}
                  <span *ngIf="data?.newdata?.address">
                    to
                    <strong class="change">
                      {{data?.newdata?.address}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.address_kh">
              <div class="dl-title">Khmer Address</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.address_kh}}
                  <span *ngIf="data?.newdata?.address_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.address_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.countryKey">
              <div class="dl-title">Country</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.country | getReferenceObject:true : 'name' | async}}
                  <span *ngIf="data?.newdata?.countryKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.countryKey | docKeyRef :'settings_country':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.postal_code">
              <div class="dl-title">Postal Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.postal_code}}
                  <span *ngIf="data?.newdata?.postal_code">
                    to
                    <strong class="change">
                      {{data?.newdata?.postal_code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.telephone_number">
              <div class="dl-title">Phone Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.telephone_number}}
                  <span *ngIf="data?.newdata?.telephone_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.telephone_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.fax_number">
              <div class="dl-title">Fax Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.fax_number}}
                  <span *ngIf="data?.newdata?.fax_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.fax_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="collection === 'settings_uom'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">UOM Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.description_en">
              <div class="dl-title">UOM (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.description_en}}
                  <span *ngIf="data?.newdata?.description_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.description_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.description_kh">
              <div class="dl-title"> UOM (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.description_kh}}
                  <span *ngIf="data?.newdata?.description_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.description_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_department'">
            <div class="dl-items" *ngIf="data?.newdata?.name_en">
              <div class="dl-title">Department Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata.name_en}}
                  <span *ngIf="data?.newdata?.name_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title">Department Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_currency'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">Currency Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">Currency Name</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.symbol">
              <div class="dl-title">Symbol</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.symbol}}
                  <span *ngIf="data?.newdata?.symbol">
                    to
                    <strong class="change">
                      {{data?.newdata?.symbol}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_coform'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">CO Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">CO Name</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'settings_country'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">Country Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">Country Name</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.departmentKey">
              <div class="dl-title">Department</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.department | getReferenceObject: true : 'name_en' | async}}
                  <span *ngIf="data?.newdata?.departmentKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.departmentKey | docKeyRef :'settings_department':'name_en' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.co_form">
              <div class="dl-title">CO Form</div>
              <div class="dl-text">:
                <div class="data">
                  <ng-container *ngIf="data && data?.olddata?.co_form && data?.olddata?.co_form?.length>0">
                    <ng-container *ngFor="let f of data?.olddata?.co_form;let i=index; let fs=first;">
                      {{f | getReferenceObject: fs : 'name' | async}}
                    </ng-container>
                  </ng-container>

                  <span *ngIf="data && data?.newdata?.co_form && data?.newdata?.co_form?.length>0">
                    to
                    <strong class="change" *ngFor="let f of data?.newdata?.co_form;let i=index; let fs=first;">
                      {{f | getReferenceObject: fs : 'name' | async}}
                    </strong>
                  </span>

                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_country_port'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">Country Port Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">Country Port Name</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.countryKey">
              <div class="dl-title">Country Name</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.country | getReferenceObject: true :'name' | async}}
                  <span *ngIf="data?.newdata?.countryKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.countryKey | docKeyRef :'settings_country':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="collection === 'settings_forwarder'">
            <div class="dl-items" *ngIf="data?.newdata?.name_en">
              <div class="dl-title"> Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_en}}
                  <span *ngIf="data?.newdata?.name_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title">Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.short_name_en">
              <div class="dl-title"> Short Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.short_name_en}}
                  <span *ngIf="data?.newdata?.short_name_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.short_name_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.short_name_kh">
              <div class="dl-title">Short Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.short_name_kh}}
                  <span *ngIf="data?.newdata?.short_name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.short_name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.email">
              <div class="dl-title">Email</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.email}}
                  <span *ngIf="data?.newdata?.email">
                    to
                    <strong class="change">
                      {{data?.newdata?.email}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.tin_no">
              <div class="dl-title">TIN No.</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.tin_no}}

                  <span *ngIf="data?.newdata?.tin_no">
                    to
                    <strong class="change">
                      {{data?.newdata?.tin_no}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            <div class="dl-items" *ngIf="data?.newdata?.address">
              <div class="dl-title">Address</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.address}}
                  <span *ngIf="data?.newdata?.address">
                    to
                    <strong class="change">
                      {{data?.newdata?.address}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.countryKey">
              <div class="dl-title">Country</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.country | getReferenceObject:true : 'name' | async}}
                  <span *ngIf="data?.newdata?.countryKey">
                    to
                    <strong class="change">
                      {{data?.newdata?.countryKey | docKeyRef :'settings_country':'name' | async}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.postal_code">
              <div class="dl-title">Postal Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.postal_code}}
                  <span *ngIf="data?.newdata?.postal_code">
                    to
                    <strong class="change">
                      {{data?.newdata?.postal_code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.link_address">
              <div class="dl-title">Link Address</div>
              <div class="dl-text">:
                <div class="data">
                  <a target="_blank" [href]="data?.link_address">
                    {{data?.olddata?.link_address}}
                    <span *ngIf="data?.newdata?.link_address">
                      to
                      <strong class="change">
                        {{data?.newdata?.link_address}}
                      </strong>
                    </span>
                  </a>


                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.telephone_number">
              <div class="dl-title">Phone Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.telephone_number}}
                  <span *ngIf="data?.newdata?.telephone_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.telephone_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.fax_number">
              <div class="dl-title">Fax Number</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.fax_number}}
                  <span *ngIf="data?.newdata?.fax_number">
                    to
                    <strong class="change">
                      {{data?.newdata?.fax_number}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_product_type'">
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title">Product Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title">Product Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_route'">
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title"> Route Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title"> Route Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'settings_title'">
            <div class="dl-items" *ngIf="data?.newdata?.name">
              <div class="dl-title"> Title Name (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name}}
                  <span *ngIf="data?.newdata?.name">
                    to
                    <strong class="change">
                      {{data?.newdata?.name}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.name_kh">
              <div class="dl-title"> Title Name (KH)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.name_kh}}
                  <span *ngIf="data?.newdata?.name_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.name_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="collection === 'hs_codes'">
            <div class="dl-items" *ngIf="data?.newdata?.code">
              <div class="dl-title">Code</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.code}}
                  <span *ngIf="data?.newdata?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.uom?.description_en">
              <div class="dl-title">UOM</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.uom?.description_en}}
                  <span *ngIf="data?.newdata?.uom?.description_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.uom?.description_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.description_en">
              <div class="dl-title">Description (EN)</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.description_en}}
                  <span *ngIf="data?.newdata?.description_en">
                    to
                    <strong class="change">
                      {{data?.newdata?.description_en}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.description_kh">
              <div class="dl-title"> Description (KH)</div>
              <div class="dl-text">:

                <div class="data">
                  {{data?.olddata?.description_kh}}
                  <span *ngIf="data?.newdata?.description_kh">
                    to
                    <strong class="change">
                      {{data?.newdata?.description_kh}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.cd">
              <div class="dl-title"> CD</div>
              <div class="dl-text">:

                <div class="data">
                  {{data?.olddata?.cd}}
                  <span *ngIf="data?.newdata?.cd">
                    to
                    <strong class="change">
                      {{data?.newdata?.cd}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.st">
              <div class="dl-title"> ST</div>
              <div class="dl-text">:

                <div class="data">
                  {{data?.olddata?.st}}
                  <span *ngIf="data?.newdata?.st">
                    to
                    <strong class="change">
                      {{data?.newdata?.st}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.vat">
              <div class="dl-title"> VAT</div>
              <div class="dl-text">
                :
                <div class="data">
                  {{data?.olddata?.vat}}
                  <span *ngIf="data?.newdata?.vat">
                    to
                    <strong class="change">
                      {{data?.newdata?.vat}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.et">
              <div class="dl-title"> ET</div>
              <div class="dl-text">
                :
                <div class="data">
                  {{data?.olddata?.et}}
                  <span *ngIf="data?.newdata?.et">
                    to
                    <strong class="change">
                      {{data?.newdata?.et}}
                    </strong>
                  </span>
                </div>

              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.subItem?.code">
              <div class="dl-title">Sub Item Of</div>
              <div class="dl-text">
                :
                <div class="data">
                  {{data?.olddata?.subItem?.code}}
                  <span *ngIf="data?.newdata?.subItem?.code">
                    to
                    <strong class="change">
                      {{data?.newdata?.subItem?.code}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="dl-items" *ngIf="data?.newdata?.status">
              <div class="dl-title">Status</div>
              <div class="dl-text">:
                <div class="data">
                  {{data?.olddata?.status?.text}}
                  <span *ngIf="data?.newdata?.status">
                    to
                    <strong class="change">
                      {{data?.newdata?.status?.text}}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </virtual-scroller>
</ng-container>