import { Component, OnInit, Inject } from '@angular/core';
import { reApplyOption, duplicateOption } from 'src/app/dummy/status';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { SubmitDetail } from 'src/app/stores/submit-detail.store';
import { totalStore } from 'src/app/stores/getTotal.store';
import { sum, ConvertService } from 'src/app/services/convert.service';
import { pushToObject } from 'src/app/services/mapping.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-duplicate-app-detail-print',
  templateUrl: './duplicate-app-detail-print.component.html',
  styleUrls: ['./duplicate-app-detail-print.component.scss']
})
export class DuplicateAppDetailPrintComponent implements OnInit {

  submitItem = [];
  printDate: Date;
  loading;
  COLUMNS: any = null;
  invoice;
  TotalBill;
  TotalInspect;
  registerInvoice = []
  totalPermit = null;
  totalControl = null;
  totalComControl = null;
  totalInvoice = null;

  reApplyOptionLists = duplicateOption;
  onSelectedItem:any;
  COLUMN_ARRAY: any[] = []

  constructor(
    public dialogRef: MatDialogRef<DuplicateAppDetailPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps: PrintService,
    public auth: Auth,
    public detail: SubmitDetail,
    public total: totalStore,
    private store:SettingStore

  ) {

  }

  async ngOnInit() {
    this.onSelectedItem = this.reApplyOptionLists[0]

    this.auth.fetchColumns('duplicate_Column_Print', doc => {
      if (doc) {
        this.COLUMN_ARRAY = this.auth.COLUMNS_ARRAY.filter(m => m.filter === true);
        this.COLUMNS = doc;
        this.loading = false;
      }
    })
    this.printDate = new Date();
    const data = this.data.item.registerCoItems;
    this.submitItem = pushToObject(data);
    if (this.submitItem) {
      this.totalPermit = JSON.parse(localStorage.getItem('permit_number'));
      this.totalControl = JSON.parse(localStorage.getItem('custom_number'));
      this.totalComControl = JSON.parse(localStorage.getItem('camControl_number'));
      this.totalInvoice = JSON.parse(localStorage.getItem('invoice'));
    }


    if (this.data.collection === 'register_return_application') {
      const b = 15 - this.submitItem.length;
      this.invoice = sum(data, 'itemCount')
      // delivery_return_application
      if (this.submitItem.length <= 15) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = data
      }
    }
    if (this.data.collection !== 'register_return_application') {
      const b = 10 - this.submitItem.length;
      this.invoice = sum(data, 'itemCount')
      // delivery_return_application
      if (this.submitItem.length <= 10) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = data
      }
    }


    const bill = []
    const inspect = []
    if (this.data.collection === 'register_return_application') {
      data.forEach(data => {
        bill.push(data.pickupBillKey)
        inspect.push(data.pickupInspectionKey)
      });

      // await bill
      this.TotalBill = ConvertService.uniqueArray(bill);
      this.TotalInspect = ConvertService.uniqueArray(inspect);
    }
  }


  selectedOption(item){
    this.onSelectedItem = item;
  }


  close() {
    this.dialogRef.close()
  }
  print() {
    this.ps.print('#printms', 'a4');
    this.store.printHistory(this.data?.item, this.auth.user, success=>{})
  }
}
