import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPS } from 'src/app/dummy/config';
import { orderBy } from 'src/app/services/convert.service';
import { Auth } from 'src/app/stores/auth.store';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { LatterPreviewComponent } from '../latter-preview/latter-preview.component';

@Component({
  selector: 'app-history-listing',
  templateUrl: './history-listing.component.html',
  styleUrls: ['./history-listing.component.scss']
})
export class HistoryListingComponent implements OnInit {
  @Input() collection:string;
  @Input() item:any;
  auditData;
  constructor(
    public store: SettingStore,
    public auth: Auth,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    if(this.item && this.item.key){
      this.store.auditTrail(this.auth.selectedCompany.key, this.item.key, data => {
        this.auditData = orderBy(data, 'audit_date', 'desc');
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    const {currentValue} = changes.item;
    if(currentValue && currentValue.key){
      this.store.auditTrail(this.auth.selectedCompany.key, currentValue.key, data => {
        this.auditData = orderBy(data, 'audit_date', 'desc');
      })
    }
  }
  ngOnDestroy(): void {
    this.store.auditData = []
  }

  trackByIdx(i) {
    return i;
  }
  scrollHandler(e, size) {
    if(size < 5) return;
    if (e.endIndex === size - 1) {
      this.store.auditTrailMore(this.auth.selectedCompany.key, this.item.key, data => {
        this.auditData = orderBy(data, 'audit_date', 'desc');
      });
    }
  }

  preview(item: any) {
    let dialogRef = this.dialog.open(LatterPreviewComponent, {
      data: { item: item, collection: this.collection },
      panelClass: 'print-master-panel',
      width: '',
      height: '',
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }

}
