import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { Auth } from 'src/app/stores/auth.store';
import { fileFolder } from 'src/app/dummy/fileFolder';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-data',
  templateUrl: './file-data.component.html',
  styleUrls: ['./file-data.component.scss']
})
export class FileDataComponent implements OnInit {
  fileLists = []
  fileFolders
  title;
  app;
  searchTextBox = new FormControl('', []);
  appKey;
  subscriptionRoute: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public fm: FileManagerStore,
    public auth: Auth,
    private location: Location
  ) { }
  clear(){
    this.searchTextBox.reset();
  }
  ngOnInit() {
    this.subscriptionRoute = this.route.params.subscribe(params => {
      if (params) {
        const { id, type,app } = params
        const f = fileFolder.filter(f => f.key === app)
        this.fileFolders = f[0].item
        this.app = app
        const title = this.fileFolders.filter(f => f.key == id)[0]
        if (title) {
          this.appKey = title.appkey
          this.title = title.name
        }
        if (type == 'settings') {
          this.fm.fetchLazyFile(id, null, null, data => {
            this.fileLists = data;
          })
        } else {
          this.fm.fetchLazyFile(id, null, this.auth.selectedCompany.key, data => {
            this.fileLists = data
          })
        }

        this.searchTextBox.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => (false)),
          switchMap(value => this.fm.fetchLazyFileSearch(value, data => {
            this.fileLists = data
          }))
        )
        .subscribe(res => { });    
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptionRoute&&this.subscriptionRoute.unsubscribe();
  }
  goBack(){
    this.location.back()
  }

  onScroll() {
    this.fm.fetchLazyFileMore(data => {
      this.fileLists = data
    });
  }

}
