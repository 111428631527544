import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { pushToObject, sum} from 'src/app/services/convert.service';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Injectable({providedIn:'root'})
export class SubmitDetail {
    @observable data: Array<any> = [];
    @observable items: Array<any> = [];

    @observable process: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    @observable singleData: any = null;
    @observable header: any = null;
    @observable detail: any[] = [];

    @observable totalADMFee: any = 0;
    @observable totalEMFFee: any = 0;
    @observable totalQuantity: any = 0;
    @observable totalAmount: any = 0;
    @observable totalQtyPacking: any = 0;
    @observable totalCat: any = 0;
    @observable totalItem: any = 0;

    constructor(private ds: DataService, private router: Router) {
    }

    @action
    async fetchCollectionPrintTemplate(companyKey, appKey, callback) {
      this.process = true;
      this.ds.print_templatesRef(companyKey, appKey).valueChanges().subscribe(data => {
        this.process = false;
        callback(data);
      })
  
    }
    
    @action
    async detailCollectionByCompany(collectionName: string, id, companyKey, callback) {
        this.process = true;
        const data = await this.ds.settingFireByCompanyRef(collectionName, companyKey).doc(id).get()
        this.singleData = pushToObject(data)
        const { registerCoItems } = this.singleData || {};
        this.totalADMFee = sum(registerCoItems, "admFee")
        this.totalEMFFee = sum(registerCoItems, "emfFee")
        this.totalQuantity = sum(registerCoItems, "qty_unit")
        this.totalAmount = sum(registerCoItems, "amount_FOB")
        this.totalQtyPacking = sum(registerCoItems, "qty_packing")
        this.totalCat = sum(registerCoItems, "cat")
        this.totalItem = sum(registerCoItems, "itemCount")
        this.process = false;
        callback(this.singleData);

    }


    
}
