import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'sumItem'
})
export class sumItemPipe implements PipeTransform {

  transform(array: any, filed: string): any {
    const total = sum(array, filed)
    return (total)
  }

}

@Pipe({
  name: 'totalMaterialCol'
})
export class totalMaterialColPipe implements PipeTransform {

  transform(num1: number, num2: number): any {
    if (num2 > 1) {
      return num1 + num2 + 2
    } else if (num2 == 1) {
      return num1 + num2 + 1
    }
  }

}

@Pipe({
  name: 'sumAdjItem'
})
export class sumAdjItemPipe implements PipeTransform {

  transform(array: any, filed: string, type: number): any {
    const newArray = array.filter(f => f.method.factor === type)
    const total = sum(newArray, filed)
    return (total)
  }

}
