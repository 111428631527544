import { Pipe, PipeTransform } from '@angular/core';
import { pushToObject } from '../services/convert.service';
import { DataService } from '../services/data.service';

@Pipe({
    name: 'userAvatarRef'
})
export class UserAvatarPipe implements PipeTransform {
    defultImg = 'https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c'
    constructor(
        private db: DataService,
    ) { }

    async transform(userkey: any): Promise<any> {
        if (!userkey) return this.defultImg;
        const user = pushToObject(await this.db.userDocRef(userkey).get().toPromise())
        if (user) {
            const imgData = user.fileurl
            return imgData || this.defultImg
        } else {
            return this.defultImg
        }
    }

}
