export const tabs = {
  items: [
    { path: '/item-management/register-item', label: 'Listing' },
  ],
  videoYoutube: [
    { path: '/video-youtube', label: 'All' },
  ],
  arrested: [
    { path: '/arrested', label: 'All' },
  ],
  newsContent: [
    { path: '/news-content', label: 'All' },
  ],
  options: [
    { path: '/options/general', label: 'General' },
  ],
  company: [
    { path: '/view-and-register/company/active', label: 'Active' },
    { path: '/view-and-register/company/disabled', label: 'Disabled' },
  ],
  HSCode: [
    { path: '/view-and-register/hs-code/active', label: 'Active' },
    { path: '/view-and-register/hs-code/disabled', label: 'Disabled' },

  ],
  Product: [
    { path: '/view-and-register/product-type', label: 'Product' },
  ],
  Title: [
    { path: '/view-and-register/title/active', label: 'Active' },
    { path: '/view-and-register/title/disabled', label: 'Disabled' },

  ],
  Department: [
    { path: '/view-and-register/department/active', label: 'Active' },
    { path: '/view-and-register/department/disabled', label: 'Disabled' },

  ],
  Currency: [
    { path: '/view-and-register/currency/active', label: 'Active' },
    { path: '/view-and-register/currency/disabled', label: 'Disabled' },

  ],
  COForm: [
    { path: '/view-and-register/co-form/active', label: 'Active' },
    { path: '/view-and-register/co-form/disabled', label: 'Disabled' },

  ],
  Country: [
    { path: '/view-and-register/country/active', label: 'Active' },
    { path: '/view-and-register/country/disabled', label: 'Disabled' },

  ],
  UOM: [
    { path: '/view-and-register/uom/active', label: 'Active' },
    { path: '/view-and-register/uom/disabled', label: 'Disabled' },
  ],
  CountryPort: [
    { path: '/view-and-register/country-port/active', label: 'Active' },
    { path: '/view-and-register/country-port/disabled', label: 'Disabled' },
  ],
  Forwarder: [
    { path: '/view-and-register/forwarder/active', label: 'Active' },
    { path: '/view-and-register/forwarder/disabled', label: 'Disabled' },
  ],
  Route: [
    { path: '/view-and-register/route/active', label: 'Active' },
    { path: '/view-and-register/route/disabled', label: 'Disabled' },
  ],
  registerCO: [
    { path: '/register-co/submitted', label: 'Data' },
    { path: '/register-co/deleted', label: 'Deleted' },
    { path: '/register-co/outstanding/listing', label: 'Outstanding' },
  ],

  registerCancelCO: [
    // { path: '/register-cancel-co/list', label: 'Lists' },
    { path: '/register-cancel-co/submitted', label: 'Data' },
    // { path: '/register-cancel-co/submitted', label: 'Submitted' },
    { path: '/register-cancel-co/deleted', label: 'Deleted' },
  ],
  registerPickupBill: [
    { path: '/pickup-bill/submitted', label: 'Data' },
    { path: '/pickup-bill/deleted', label: 'Deleted' },
    { path: '/pickup-bill/outstanding/listing', label: 'Outstanding' },
  ],
  registerPickupInspect: [
    { path: '/pickup-inspect/submitted', label: 'Data' },
    { path: '/pickup-inspect/deleted', label: 'Deleted' },
    { path: '/pickup-inspect/outstanding/listing', label: 'Outstanding' },
  ],

  pickupBill: [
    { path: '/view-and-register/pickup-bill', label: newFunction() },
    { path: '/view-and-register/pickupbill/pedding-up', label: newFunctionpeddingJob() },
  ],
  pickupInspect: [
    { path: '/view-and-register/pickup-inspect', label: newFunctions() },
    { path: '/view-and-register/pickupinspect/pedding-job', label: newFunctionpeddingJob() },
  ],
  customer: [
    { path: '/view-and-register/customer', label: 'Data' },
  ],
  supplier: [
    { path: '/view-and-register/supplier', label: 'Data' },
  ],
  supplierGroup: [
    { path: '/view-and-register/supplier-group', label: 'Data' },
  ],
  customerGroup: [
    { path: '/view-and-register/customer-group', label: 'Data' },
  ],
  producttype: [
    { path: '/view-and-register/product-type/active', label: 'Active' },
    { path: '/view-and-register/product-type/disabled', label: 'Disabled' },

  ],

  hsCodeTemplate: [
    { path: '/reports/template/hs-code', label: 'Template' },
  ],
  item: [
    { path: '/register-item/submitted', label: 'Data' },
    // { path: '/register-item/submitted', label: 'Submitted' },
    { path: '/register-item/deleted', label: 'Deleted' },
  ],

  localInv: [
    { path: '/register-local-inv/submitted', label: 'Data' },
    { path: '/register-local-inv/deleted', label: 'Deleted' },
  ],
  localInvSage: [
    { path: '/register-local-inv-usage/submitted', label: 'Data' },
    { path: '/register-local-inv-usage/deleted', label: 'Deleted' },
    { path: '/register-local-inv-usage/outstanding/listing', label: 'Outstanding' },
  ],
  BOM: [
    { path: '/register-bom/data', label: 'Data' },
    // { path: '/register-item/submitted', label: 'Submitted' },
    // { path: '/register-item/deleted', label: 'Deleted' },
  ],
  purchaseOrder: [
    { path: '/register-purchase-order/data', label: 'Data' },
    // { path: '/register-item/submitted', label: 'Submitted' },
    // { path: '/register-item/deleted', label: 'Deleted' },
  ],
  itemusage: [
    { path: '/register-export-inv-usage/submitted', label: 'Data' },
    { path: '/register-export-inv-usage/deleted', label: 'Deleted' },

    { path: '/register-export-inv-usage/outstanding/listing', label: 'Outstanding' },

    // { path: '/register-item/submitted', label: 'Submitted' },
  ],
  exportdetail: [
    { path: '/register-export-detail/data', label: 'Data' },
    { path: '/register-export-detail/outstanding/listing', label: 'Outstanding' },

    // { path: '/register-item/submitted', label: 'Submitted' },
    // { path: '/register-item/deleted', label: 'Deleted' },
  ],
  registerInvoice: [
    { path: '/register-invoice/submitted', label: 'Data' },
    { path: '/register-invoice/deleted', label: 'Deleted' },
  ],
  registerImportInvoice: [
    { path: '/register-import-invoice/submitted', label: 'Data' },
    { path: '/register-import-invoice/deleted', label: 'Deleted' },
  ],
  
  registerLocalInvoice: [
    { path: '/register-local-invoice/submitted', label: 'Data' },
    { path: '/register-local-invoice/deleted', label: 'Deleted' },
  ],
  registerInvoiceInfo: [
    { path: '/register-invoice-info/submitted', label: 'Data' },
    { path: '/register-invoice-info/deleted', label: 'Deleted' },
    { path: '/register-invoice-info/outstanding/listing', label: 'Outstanding' },
  ],

  registerLocalInvoiceInfo: [
    { path: '/register-local-invoice-info/submitted', label: 'Data' },
    { path: '/register-local-invoice-info/deleted', label: 'Deleted' },
    { path: '/register-local-invoice-info/outstanding/listing', label: 'Outstanding' },
  ],
  cancelCOLetter: [
    // { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/cancel-co-letter/submitted', label: 'Submitted' },
    { path: '/cancel-co-letter/deleted', label: 'Deleted' },
  ],

  otherLetter: [
    // { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/other-letter/submitted', label: 'Submitted' },
    { path: '/other-letter/deleted', label: 'Deleted' },
  ],
  LetterForm: [
    // { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/letter-form/submitted', label: 'Submitted' },
    { path: '/letter-form/deleted', label: 'Deleted' },
  ],
  duplicateLetter: [
    // { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/duplicate-letter/submitted', label: 'Submitted' },
    { path: '/duplicate-letter/deleted', label: 'Deleted' },
  ],
  reapplyLetter: [
    // { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/re-apply-letter/submitted', label: 'Submitted' },
    { path: '/re-apply-letter/deleted', label: 'Deleted' },
  ],
  reApplyRegisterItem: [
    { path: '/re-apply-register-item/draft', label: 'Draft' },
    { path: '/re-apply-register-item/submitted', label: 'Submitted' },
    { path: '/re-apply-register-item/deleted', label: 'Deleted' },
  ],
  duplicateRegisterItem: [
    // { path: '/register-duplicate-item/draft', label: 'Draft' },
    { path: '/register-duplicate-item/submitted', label: 'Submitted' },
    { path: '/register-duplicate-item/deleted', label: 'Deleted' },
  ],
  material: [
    { path: '/item-registration/register-material/submitted', label: 'Data' },
    // { path: '/item-registration/register-material/draft', label: 'Draft' },
    // { path: '/item-registration/register-material/submitted', label: 'Submitted' },
    { path: '/item-registration/register-material/deleted', label: 'Deleted' },
  ],
  importMaterial: [
    { path: '/item-registration/register-import-material/submitted', label: 'Data' },
    // { path: '/item-registration/register-material/draft', label: 'Draft' },
    // { path: '/item-registration/register-material/submitted', label: 'Submitted' },
    { path: '/item-registration/register-import-material/deleted', label: 'Deleted' },
  ],
  product: [
    { path: '/item-registration/register-product/data', label: 'Data' },
    // { path: '/item-registration/register-material/draft', label: 'Draft' },
    // { path: '/item-registration/register-material/submitted', label: 'Submitted' },
    // { path: '/item-registration/register-material/deleted', label: 'Deleted' },
  ],
  print: [
    { path: '/view-and-register/print', label: 'Data' },
  ],
  inventoryBalance: [
    { path: '/inventory-balance/list', label: 'Data' },
  ],
  hsCode: [
    { path: '/item-management/hs-code/list', label: 'List' },
  ],
  registerMasterList: [
    { path: '/register-master-list/submitted', label: 'Data' },
    { path: '/register-master-list/deleted', label: 'Deleted' },
    // { path: '/item-management/register-master-list/released', label: 'Released' },
    // { path: '/item-management/register-master-list/reject', label: 'Reject' },
  ],

  registerSooStatment: [
    { path: '/register-soo-statement/submitted', label: 'Data' },
    // { path: '/register-soo-statement/submitted', label: 'Submitted' },
    { path: '/register-soo-statement/deleted', label: 'Deleted' },
    { path: '/register-soo-statement/outstanding/listing', label: 'Outstanding' },

  ],
  registerSOO: [
    { path: '/register-soo/submitted', label: 'Data' },
    // { path: '/register-soo/submitted', label: 'Submitted' },
    { path: '/register-soo/deleted', label: 'Deleted' },
    { path: '/register-soo/outstanding/listing', label: 'Outstanding' },
  ],

  registerSOOForm: [
    { path: '/register-soo/register-soo-form/draft/co/new', label: 'Register Multi CO' },
    { path: '/register-soo/register-soo-form/draft/soo/new', label: 'Register Multi SOO' },
  ],
  submitForm: [
    { path: '/register-submit-form/submitted', label: 'Data' },
    // { path: '/register-submit-form/submitted', label: 'Submitted' },
    { path: '/register-submit-form/deleted', label: 'Deleted' },
    { path: '/register-submit-form/outstanding/listing', label: 'Outstanding' },
    // { path: '/register-submit-form/canceled', label: 'Canceled' },
  ],
  submitCRF: [
    { path: '/register-submit-crf/submitted', label: 'Data' },
    // { path: '/register-submit-crf/submitted', label: 'Submitted' },
    { path: '/register-submit-crf/deleted', label: 'Deleted' },
    { path: '/register-submit-crf/outstanding/listing', label: 'Outstanding' },
    // { path: '/register-submit-crf/canceled', label: 'Canceled' },
  ],

  reApply: [
    // { path: '/register-re-apply-application/draft', label: 'Draft' },
    { path: '/register-re-apply-application/submitted', label: 'Submitted' },
    { path: '/register-re-apply-application/deleted', label: 'Deleted' },
    { path: '/register-re-apply-application/outstanding/listing', label: 'Outstanding' },
  ],
  duplicateApp: [
    // { path: '/register-duplicate-application/draft', label: 'Draft' },
    { path: '/register-duplicate-application/submitted', label: 'Submitted' },
    { path: '/register-duplicate-application/deleted', label: 'Deleted' },
    { path: '/register-duplicate-application/outstanding/listing', label: 'Outstanding' },
  ],
  returnApp: [
    { path: '/register-return-application/submitted', label: 'Submitted' },
    { path: '/register-return-application/deleted', label: 'Deleted' },
    { path: '/register-return-application/outstanding/listing', label: 'Outstanding' },
  ],
  deliveryreturnApp: [
    { path: '/delivery-return-application/submitted', label: 'Data' },
    { path: '/delivery-return-application/deleted', label: 'Deleted' },
    { path: '/delivery-return-application/outstanding/listing', label: 'Outstanding' },
  ],
  deliveryCOApp: [
    { path: '/register-delivery-co-application/submitted', label: 'Data' },
    { path: '/register-delivery-co-application/deleted', label: 'Deleted' },
    { path: '/register-delivery-co-application/outstanding/listing', label: 'Outstanding' },
  ],

  localDelivery: [
    { path: '/register-local-delivery/submitted', label: 'Data' },
    { path: '/register-local-delivery/deleted', label: 'Deleted' },
    { path: '/register-local-delivery/outstanding/listing', label: 'Outstanding' },
  ],
  deliveryImportStockApp: [
    // { path: '/delivery-import-stock/draft', label: 'Draft' },
    { path: '/delivery-import-stock/data', label: 'Data' },
    { path: '/delivery-import-stock/deleted', label: 'Deleted' },
    { path: '/delivery-import-stock/outstanding/listing', label: 'Outstanding' },
  ],

  deliveryLocalImportStockApp: [
    // { path: '/delivery-import-stock/draft', label: 'Draft' },
    { path: '/delivery-local-import-stock/data', label: 'Data' },
    { path: '/delivery-local-import-stock/deleted', label: 'Deleted' },
    { path: '/delivery-local-import-stock/outstanding/listing', label: 'Outstanding' },
  ],

  deliveryCutStockApp: [
    // { path: '/delivery-cut-stock/draft', label: 'Draft' },
    { path: '/delivery-cut-stock/data', label: 'Data' },
    { path: '/delivery-cut-stock/deleted', label: 'Deleted' },
    { path: '/delivery-cut-stock/outstanding/listing', label: 'Outstanding' },
  ],
  localDeliveryCutStockApp: [
    { path: '/local-delivery-cut-stock/data', label: 'Data' },
    { path: '/local-delivery-cut-stock/deleted', label: 'Deleted' },
    { path: '/local-delivery-cut-stock/outstanding/listing', label: 'Outstanding' },
  ],
  deliveryExportDocument: [
    { path: '/delivery-export/submitted', label: 'Data' },
    // { path: '/delivery-export/submitted', label: 'Submitted' },
    { path: '/delivery-export/deleted', label: 'Deleted' },
    { path: '/delivery-export/outstanding/listing', label: 'Outstanding' },
  ],

  user: [
    { path: '/user-management/users/active', label: 'Active' },
    { path: '/user-management/users/disabled', label: 'Disabled' },

  ],
  userRoles: [
    { path: '/user-management/user-roles/active', label: 'Active' },
    { path: '/user-management/user-roles/disabled', label: 'Disabled' },
  ],
  obStock: [
    { path: '/register-ob-stock/draft', label: 'Draft' },
    { path: '/register-ob-stock/released', label: 'Released' },
    { path: '/register-ob-stock/deleted', label: 'Deleted' },
  ],
  adjustStock: [
    { path: '/register-adjustment-stock/draft', label: 'Draft' },
    { path: '/register-adjustment-stock/submitted', label: 'Submitted' },
    { path: '/register-adjustment-stock/released', label: 'Released' },
    { path: '/register-adjustment-stock/deleted', label: 'Deleted' },
  ],
  adjustStockDecrease: [
    { path: '/register-adjustment-stock-decrease/draft', label: 'Draft' },
    { path: '/register-adjustment-stock-decrease/submitted', label: 'Submitted' },
    { path: '/register-adjustment-stock-decrease/released', label: 'Released' },
    { path: '/register-adjustment-stock-decrease/deleted', label: 'Deleted' },
  ],
  importStock: [
    { path: '/register-import-stock/draft', label: 'Draft' },
    { path: '/register-import-stock/submitted', label: 'Submitted' },
    { path: '/register-import-stock/released', label: 'Released' },
    { path: '/register-import-stock/deleted', label: 'Deleted' },
    { path: '/register-import-stock/outstanding/listing', label: 'Outstanding' },
  ],

  importLocalStock: [
    { path: '/register-local-import-stock/draft', label: 'Draft' },
    { path: '/register-local-import-stock/submitted', label: 'Submitted' },
    { path: '/register-local-import-stock/released', label: 'Released' },
    { path: '/register-local-import-stock/deleted', label: 'Deleted' },
    { path: '/register-local-import-stock/outstanding/listing', label: 'Outstanding' },
  ],
  cutStock: [
    { path: '/register-cut-stock/draft', label: 'Draft' },
    { path: '/register-cut-stock/submitted', label: 'Submitted' },
    { path: '/register-cut-stock/released', label: 'Released' },
    { path: '/register-cut-stock/deleted', label: 'Deleted' },
    { path: '/register-cut-stock/outstanding/listing', label: 'Outstanding' },
  ],
  localCutStock: [
    { path: '/register-local-cut-stock/draft', label: 'Draft' },
    { path: '/register-local-cut-stock/submitted', label: 'Submitted' },
    { path: '/register-local-cut-stock/released', label: 'Released' },
    { path: '/register-local-cut-stock/deleted', label: 'Deleted' },
    { path: '/register-local-cut-stock/outstanding/listing', label: 'Outstanding' },
  ],
  voidStock: [
    { path: '/register-void-stock/draft', label: 'Draft' },
    { path: '/register-void-stock/submitted', label: 'Submitted' },
    // { path: '/register-void-stock/deleted', label: 'Deleted' },
    { path: '/register-void-stock/outstanding/listing', label: 'Outstanding' },
  ],



  reapplyInv: [
    { path: '/reports/preview/reapply-inv/data', label: 'Re-apply Invoice Report' },
    // { path: '/reports/preview/reapply-inv/history/re-apply-inv', label: 'History' },
  ],


  duplicateInv: [
    { path: '/reports/preview/duplicate-inv/data', label: 'Duplicate Invoice Report' },
    // { path: '/reports/preview/duplicate-inv/history/duplicate-invoice', label: 'History' },
  ],

  inventoryReport: [
    { path: '/reports/preview/inventory-report/data', label: 'Inventory CO Balance Report' },
    // { path: '/reports/preview/inventory-report/history/inventory-co-balance', label: 'History' },
  ],
  adjustmentReport: [
    { path: '/reports/preview/adj/adj-report', label: 'Adjustment CO Balance' },
    { path: '/reports/preview/adj/adj-custom-report', label: 'Adjustment Customs Report' },
    // { path: '/reports/preview/adj-custom/history/adjustment-report', label: 'History' },
  ],
  
  
  coReport: [
    { path: '/reports/preview/co/data', label: 'Export CO Report' },
    // { path: '/reports/preview/co/history/export-co', label: 'History' },
  ],

  sooReport: [
    { path: '/reports/preview/soo/data', label: 'SOO Report' },
    // { path: '/reports/preview/soo/history/soo', label: 'History' },
  ],

  inspectReport: [
    { path: '/reports/preview/inspect/export-inspect', label: 'CO Export Inspect Report' },
    { path: '/reports/preview/inspect/custom-export-inspect', label: 'Customs Export Inspect Report' },
    // { path: '/reports/preview/inspect/history/export-inspect', label: 'History' },
  ],

  coCancelReport: [
    { path: '/reports/preview/cancel-co/data', label: 'Export Cancel CO Report' },
    // { path: '/reports/preview/cancel-co/history/export-cancel-co', label: 'History' },
  ],

  cutStockReport: [
    { path: '/reports/preview/cutstock/data', label: 'Cut Stock Report' },
    // { path: '/reports/preview/cutstock/history/cut-stock', label: 'History' },
  ],


  importStockReport: [
    { path: '/reports/preview/importstock/data', label: 'Import Stock Report' },
    // { path: '/reports/preview/importstock/history/import-stock', label: 'History' },
  ],

  localPurchaseStockReport: [
    { path: '/reports/preview/local-purchase-stock/data', label: 'Local Purchase Stock Report' },
    // { path: '/reports/preview/local-purchase-stock/history/local-purchase-stock', label: 'History' },
  ],

  localSaleStockReport: [
    { path: '/reports/preview/cutstock-local-sale-inv/data', label: 'Local Sale Stock Report' },
    // { path: '/reports/preview/cutstock-local-sale-inv/history/local-sale-stock', label: 'History' },
  ],

  
  

  outstandingCutStock: [
    { path: '/reports/preview/c-outstanding/none-cutstock', label: 'Outstanding Cut Stock Report' },
    { path: '/reports/preview/c-outstanding/none-release-cutstock', label: 'Outstanding Release Cut Stock Report' },
    { path: '/reports/preview/c-outstanding/none-cutstock-inv', label: 'Outstanding Cut Stock From INV Report' },
    { path: '/reports/preview/c-outstanding/none-cutstock-co-inv', label: 'Outstanding Cut Stock From CO Report' },
    // { path: '/reports/preview/c-outstanding/history/outstanding-cut-stock', label: 'History' },

  ],

  outstandingImportStock: [
    { path: '/reports/preview/i-outstanding/none-importstock', label: 'Outstanding Import Stock Report' },
    { path: '/reports/preview/i-outstanding/none-release-importstock', label: 'Outstanding Release Import Stock Report' },
    // { path: '/reports/preview/i-outstanding/history/outstanding-import-stock', label: 'History' },
  ],


  outstandingLocalCutStock: [
    { path: '/reports/preview/lc-outstanding/none-local-cutstock', label: 'Outstanding Local Sale Stock Report' },
    { path: '/reports/preview/lc-outstanding/none-local-release-cutstock', label: 'Outstanding Release Local Sale Stock Report' },
    // { path: '/reports/preview/lc-outstanding/history/outstanding-local-sale-stock', label: 'History' },

  ],

  outstandingLocalImportStock: [
    { path: '/reports/preview/li-outstanding/none-local-importstock', label: 'Outstanding Local Purchase Stock Report' },
    { path: '/reports/preview/li-outstanding/none-local-release-importstock', label: 'Outstanding Local Release Purchase Stock Report' },
    // { path: '/reports/preview/li-outstanding/history/outstanding-local-purchase-stock', label: 'History' },
  ],


  exportInvoice: [
    { path: '/reports/preview/export-inv/co-export-inv', label: 'CO Export Invoice Report' },
    { path: '/reports/preview/export-inv/co-custom-export-inv', label: 'Customs Export Invoice Report' },
    // { path: '/reports/preview/export-inv/history/co-export-inv', label: 'History' },
  ],

  localInvoice: [
    { path: '/reports/preview/local-sale/local-sale-inv', label: 'CO Local Sale Invoice Report' },
    { path: '/reports/preview/local-sale/custom-local-sale-inv', label: 'Customs Local Sale Invoice Report' },
    // { path: '/reports/preview/local-sale/history/local-sale-inv', label: 'History' },
  ],

  invoice: [
    { path: '/reports/preview/invoice/import-inv', label: 'CO Import Invoice Report'},
    { path: '/reports/preview/invoice/custom-import-inv', label: 'Customs Import Invoice Report' },
    // { path: '/reports/preview/invoice/history/import-inv', label: 'History' },
  ],

  localPurchase: [
    { path: '/reports/preview/local-purchase/local-purchase-inv', label: 'CO Local Purchase Invoice Report'},
    { path: '/reports/preview/local-purchase/custom-local-purchase-inv', label: 'Customs Local Purchase Invoice Report' },
    // { path: '/reports/preview/local-purchase/history/local-purchase-inv', label: 'History' },

  ],


}


function newFunction() {
  return 'Pickup Bill';
}
function newFunctionpeddingJob() {
  return 'Pedding Job';
}
function newFunctions() {
  return 'Pickup Inspect';
}
