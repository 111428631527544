import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { pushToObject, sum } from 'src/app/services/convert.service';
import { SearchStore } from 'src/app/stores/search.store';
import { groupByData } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Input() isHideComment: boolean;

  @Output() onDelete = new EventEmitter();
 
  ismobile = false;
  material;
  registerImportInvoiceInfo;
  registerImportStock;
  deliveryImportStock;
  portData = [];
  soo;
  submitForm;
  submitCrf;
  returnApp;
  registerCutStock;
  deliveryReturnDoc;
  deliveryCo;
  soo_statement;
  deliverExport;
  registerLocalCutStock;
  deliveryCutStock;

  materials
  constructor(
    public store: SettingStore,
    public ps: PrintService,
    public auth: Auth,
    public breakpointObserver: BreakpointObserver,
    public sc:SearchStore
  ) {

  }
  async ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
   
  

  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const data = changes.data.currentValue;
    if(data){
      const { registerItemUsageRef, registerLocalCutStockRef, inspectionRef, registerLocalSaleInvUsageRef, registerLocalSaleDeliveryRef } = data;

      if(registerItemUsageRef){
        const item = pushToObject(await registerItemUsageRef.get())
        this.material  = item.material;
      }

      if (inspectionRef) {
        const item = pushToObject(await inspectionRef.get())
        const { delivery_exportRef } = item

        if (delivery_exportRef) {
          const item = pushToObject(await delivery_exportRef.get())
          this.deliverExport = item;
        }
      }
      if (registerLocalCutStockRef) {
        const item = pushToObject(await registerLocalCutStockRef.get())
        const { deliveryCutStockRef } = item
        this.registerLocalCutStock = item;
        if (deliveryCutStockRef) {
          const item = pushToObject(await deliveryCutStockRef.get())
          this.deliveryCutStock = item;
        }

      }
     
      if (registerLocalSaleDeliveryRef) {
        const item = pushToObject(await registerLocalSaleDeliveryRef.get())
        this.deliverExport = item;
      }
      if (registerItemUsageRef) {
        const item = pushToObject(await registerItemUsageRef.get())
        this.material = item.material;
      }
      if (registerLocalSaleInvUsageRef) {
        const item = pushToObject(await registerLocalSaleInvUsageRef.get())
        this.material = item.material;
      }
      if (this.collection == 'register_invoice' || this.collection == 'register_local_invoice') {
        const { registerImportInvoiceInfoRef, registerImportStockRef} = this.data
        if(registerImportInvoiceInfoRef){
          this.registerImportInvoiceInfo = pushToObject(await registerImportInvoiceInfoRef.get())
        }

        if(registerImportStockRef){
          this.registerImportStock = pushToObject(await registerImportStockRef.get())
          const { deliveryImportStockRef } = this.registerImportStock;
          if (deliveryImportStockRef) {
            this.deliveryImportStock = pushToObject(await deliveryImportStockRef.get())
          }

        }

      }
      if (this.collection == 'settings_country') {
        this.sc.fetchCountryPort(this.data.key, data=>{
          this.portData = data;
        })
      }

      if (this.collection == 'register_co') {
        const { submit_sooRef, submit_crfRef, submit_fromRef, returnAppRef, registerCutStockRef, deliveryReturnDocRef, deliveryCoRef } =  data

        if (submit_sooRef) {
          this.soo = pushToObject(await submit_sooRef.get())
          const { soo_statementRef } = this.soo;
          if (soo_statementRef) {
            this.soo_statement = pushToObject(await soo_statementRef.get())
          }
        }


        if (submit_crfRef) {
          this.submitCrf = pushToObject(await submit_crfRef.get())
        }
        if (submit_fromRef) {
          this.submitForm = pushToObject(await submit_fromRef.get())
        }

        if (returnAppRef) {
          this.returnApp = pushToObject(await returnAppRef.get())
        }

        if (deliveryReturnDocRef) {
          this.deliveryReturnDoc = pushToObject(await deliveryReturnDocRef.get())
        }

        if (deliveryCoRef) {
          this.deliveryCo = pushToObject(await deliveryCoRef.get())
        }
        if (registerCutStockRef) {
          this.registerCutStock = pushToObject(await registerCutStockRef.get())
        }
      }


      if(this.collection == 'pickup_inspect'){
        let importMaterialArr = [];
          await Promise.all(this.data.items.map(async gg => {
            const { itemRef } = gg;
            let itemData = null
            if (itemRef) {
              itemData = pushToObject(await itemRef?.get());
            }
            const { registerItemUsageRef } = itemData || {}
            const [registerItemUsageRefData] = await Promise.all([
              registerItemUsageRef?.get(),
            ])
            const registerItemUsageData = registerItemUsageRefData ? pushToObject(registerItemUsageRefData) : null;
            if (registerItemUsageData) {
              const { importMaterial } = registerItemUsageData;
              importMaterialArr = importMaterialArr.concat(...importMaterial)
            }
          }));
          const groupMaterial = groupByData(importMaterialArr, 'key', 'key')
          this.materials = groupMaterial.map(m => {
            const { data } = m;
            const material = data[0];
            return {
              ...material,
              qtyNw: sum(data, 'qtyNw') || 0,
              qtyGw: sum(data, 'qtyGw') || 0,
              totalCost: sum(data, 'totalCost') || 0,
              qtyUnit: sum(data, 'qtyUnit') || 0,
            }
          })
      }
    }
  }

}
