import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileDocRef'
})
export class fileDocRefPipe implements PipeTransform {

  transform(ref: any, field: string): any {
    if (!ref) return null;
    return ref.get().then(doc => {
      if (doc.data() !== undefined) {
        return field ? doc.data()[field] : doc.data()
      } else {
        return null
      }
    });
  }

  // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}
