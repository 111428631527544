import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userPermission'
})
export class userPermissionPipe implements PipeTransform {
    transform(array: any, filed: string): any {
        let value: boolean = false;

        if (array) {
            const isHasPermission = array.permissions.filter(f => f.app === filed)
            value = isHasPermission.length > 0 ? true : false
        }
        return value
    }
}

@Pipe({
    name: 'userPermissionByApp'
})
export class userPermissionByAppPipe implements PipeTransform {
    transform(array: any, filed: string): any {
        let value1: boolean = false;
        if (array) {
            const isHasPermission = array.permissions.filter(f => f.mKey === filed)
            value1 = isHasPermission.length > 0 ? true : false
        }
        return value1
    }
}

@Pipe({
    name: 'userPermissionRole'
})
export class userPermissionRolePipe implements PipeTransform {
    transform(array: any, filed: string): any {
        let value1: boolean = false;
        if (array) {
            const isHasPermission = array.permissions.filter(f => f.appkey === filed)
            value1 = isHasPermission.length > 0 ? true : false
        }
        return value1
    }
}
