<mat-sidenav-container style="min-height: calc(100vh - 168px)">
    <mat-sidenav #sidenav mode="side" [position]="'end'" [opened]="false" class="content-side" [fixedInViewport]="true"
        [fixedTopGap]="118" style="width: 460px;" [fixedBottomGap]="0">
        <mat-tab-group style="height: 100%; ">
            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    Comment
                </ng-template>
                <div class="comment-body">
                    <div class="comment-list">
                        <div class="chat">
                            <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                                *ngFor="let msg of cm?.data;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                            {{ msg?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="msg-item">
                                    <div class="c-content"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                                        <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                                            <mat-icon class="mat-18"
                                                style="font-size: 10px; display: flex; align-items: center;">edit
                                            </mat-icon>
                                            <span style="font-size: 10px;">Edited</span>
                                        </div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === msg?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editComment(msg)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="date"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        {{ msg.created_at?.toDate()|date:'medium' }} ~
                                        {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <form class="editor-block" style="position: relative;" [formGroup]="form">
                        <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
                            customToolbarPosition="top">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected value="roboto">Roboto</option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>

                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>

                                <span class="ql-formats">

                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-align" [title]="'Aligment2'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-color" [title]="'Color'">
                                    </select>
                                    <select class="ql-background" [title]="'BackgroundColor'">
                                    </select>
                                </span>
                                <div class="custom-tb">
                                    <span class="ql-formats">
                                        <button class="ql-bold" [title]="'Bold'"></button>
                                        <button class="ql-italic" [title]="'Italic'"></button>
                                        <button class="ql-underline" [title]="'Underline'"></button>
                                        <button class="ql-strike" [title]="'Strike'"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                    </span>
                                    <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                                        (click)="postComment(form.value, data)">Post
                                    </button>
                                </div>

                            </div>


                        </quill-editor>
                        <div style="height: 68px;"></div>
                    </form>
                </div>
            </mat-tab>
            <mat-tab style="height: 100%; " >
                <ng-template mat-tab-label>
                    Files
                </ng-template>
                <div class="comment-body file-manager">
                    <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate"
                        [value]="pct">
                    </mat-progress-bar>
                    <div class="comment-list">
                        <div class="chat">
                            <div class="file-lists" *ngFor="let file of cm?.files;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === file?.created_by?.key?'msg-owner':''">
                                            {{ file?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="fl-item">

                                    <img [src]="file|iconDrive" style="width: 33px;" />



                                    <a [href]="file?.downloadUrl" target="_blank" style="margin-left: 12px; flex:1 ;    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;">
                                        {{file?.originname}}
                                    </a>
                                    <ng-container *ngIf="auth?.userRoles | userPermissionRole:'delete_file_manager'">
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === file?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteFile(file)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Delete</span>
                                            </button>
                                        </mat-menu>
                                    </ng-container>

                                </div>

                                <div class="date" style="font-size: 11px; color: #686868;"
                                    [ngClass]="auth?.user?.key === file.created_by?.key?'msg-owner':''">
                                    {{ file.created_at?.toDate()|date:'medium' }} ~
                                    {{ file.created_at?.toDate() | amTimeAgo }}</div>
                            </div>

                        </div>
                    </div>
                    <div style="padding: 12px; text-align: center;">

                        <form [formGroup]="formUpload">
                            <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                                <a type="button" mat-button class="attach_file upload-button"
                                    (click)="fileInput.click()">
                                    <mat-icon class="mat-18">attach_file</mat-icon>
                                    <span>Add New Attachment</span>
                                    <input #fileInput type="file" multiple
                                        accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file
                                        formControlName="file" (change)="onSelectedFile(file.files)"
                                        style="display:none;" />
                                </a>
                            </ng-container>

                        </form>

                    </div>
                </div>
            </mat-tab>

            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    History
                </ng-template>
                <div class="audit-lists">
                    <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="cs-form-container" *mobxAutorun>
            <div class="form-wrapper" style="padding:33px 0;" id="print">
                <div class="hd-row" style="padding:0 33px 16px 33px">
                    <h4 class="hd-title">{{title}}</h4>
                    <div class="flex-1"></div>
                    <div *ngIf="data">
                        <ng-container *ngIf="!isHide">
                            <div style="display: flex;">
                                <!-- <button mat-icon-button *ngIf="!store?.status && collection == 'register_submit_form'"
                                    (click)="cancel(data)" style="margin-right: 8px;" class="hideOnPrint"
                                    matTooltip="Cancel Submit Form">
                                    <mat-icon class="mat-18">cancel</mat-icon>
                                </button> -->
                                <ng-container *ngIf="getStatus(data)">
                                    <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;"
                                        class="hideOnPrint" matTooltip="Print">
                                        <mat-icon class="mat-18">print</mat-icon>
                                    </button>
                                    <ng-container *ngIf="isUse(data)">
                                        <button mat-icon-button
                                            *ngIf="!isCutStock || (auth?.userRoles | userPermissionRole:'delete_register_reapply_form') && (collection == 'register_re_apply')"
                                            (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                                            matTooltip="Delete">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>
                                    </ng-container>

                                    <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                                        class="hideOnPrint" matTooltip="Edit"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_register_reapply_form') && (collection == 'register_re_apply')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>


                                </ng-container>
                                <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                                    <mat-icon class="mat-18">insert_comment</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                    <ng-container *ngIf="collection == 'register_re_apply'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Product</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef:'name' | async}}

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">  Export CO</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> CO Reference</th>
                                        <th class="table-col center" rowspan="2"> Quantity Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount</th>
                                        <th class="table-col center" rowspan="2"> CAT</th>
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                                        <th class="table-col center" rowspan="2"> Bill No.</th>
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef :'co_number' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}
                                            {{item?.registerItemRef | docDocRef :'qty_unit_UOMRef':'code' | async}}

                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                        <td class="table-col ">

                                            {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                            {{dataUOM?.uomUnitRef | docRef:'code' | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                    <div class="dl-items">
                        <div class="dl-title"> Last Update</div>
                        <div class="dl-text" *ngIf="data?.updated_at">: {{data?.updated_at?.toDate() | date:'medium'}}
                        </div>
                        <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                    </div>
                    <div class="dl-items" *ngIf="data?.status">
                        <div class="dl-title">Status</div>
                        <div class="dl-text">:
                            <div class="data">
                                {{data?.status?.text}}
                            </div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="store?.process">
                    <div style="padding: 33px;">
                        <app-line-loading></app-line-loading>
                        <app-line-loading></app-line-loading>
                    </div>
                </ng-container>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
