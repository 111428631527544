import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { PrintRegisterItemComponent } from 'src/app/pages/items/print-register-item/print-register-item.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CommentStore } from 'src/app/stores/comment.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Auth } from 'src/app/stores/auth.store';
import { statusObj } from 'src/app/dummy/status';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { pushToArray, pushToObject, orderBy } from 'src/app/services/convert.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-register-item-detail',
  templateUrl: './register-item-detail.component.html',
  styleUrls: ['./register-item-detail.component.scss']
})
export class RegisterItemDetailComponent implements OnInit {
  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Input() isHideComment: boolean;
  @Output() onDelete = new EventEmitter();
  @ViewChild('content') content: ElementRef;
  isdelete;
  completed: boolean = true;
  isLinear = false;
  ismobile = false;
  @ViewChild('editor') editor;
  selectedFiles: any;
  formUpload: FormGroup;
  file: AbstractControl;
  modules = {};
  id;
  form: FormGroup;
  comment: AbstractControl;
  material = []
  deliverExport;
  registerLocalCutStock;
  deliveryCutStock;
  auditData;
  newAuditData;
  registerItemUsage;
  canDeleteUpdate: boolean = false;
  subscriptionRoute: Subscription;

  constructor(
    public store: SettingStore,
    private dialog: MatDialog,
    public ps: PrintService,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public cm: CommentStore,
    private afs: AngularFirestore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public fm: FileManagerStore,

  ) {
    this.modules = {
      // imageResize: {},
      syntax: false,
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['formula'],
        ['image', 'code-block']
      ]
    }
  }

  hideBar() {
    if (!this.isHideComment) {
      this.isHideComment = true;
    } else {
      this.isHideComment = false;
    }
  }

  ngOnInit() {
    this.formUpload = this.fb.group({
      file: [null, Validators.required]
    });
    this.form = this.fb.group({
      comment: [null, Validators.required]
    })
    this.comment = this.form.controls['comment']
    this.file = this.form.controls['file']

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });

    this.subscriptionRoute = this.route.params.subscribe(async param => {
      if (param && param.id) {
        this.id = param.id
        this.store.detailCollectionByCompany(this.collection, param.id, this.auth.selectedCompany.key, async data => {
          if (data) {
            this.store.auditTrail(this.auth.selectedCompany.key, param.id, data => {
              this.auditData = orderBy(data, 'audit_date', 'desc');
            })
            const { registerItemUsageRef, register_itemRef, registerLocalCutStockRef, inspectionRef, registerLocalSaleInvUsageRef, registerLocalSaleDeliveryRef } = data;
            // console.log(data, 'data');
            
            if (inspectionRef) {
              const item = pushToObject(await inspectionRef.get())
              const { delivery_exportRef } = item

              if (delivery_exportRef) {
                const item = pushToObject(await delivery_exportRef.get())
                this.deliverExport = item;
              }
            }
            if (registerLocalCutStockRef) {
              const item = pushToObject(await registerLocalCutStockRef.get())
              const { deliveryCutStockRef } = item
              this.registerLocalCutStock = item;
              if (deliveryCutStockRef) {
                const item = pushToObject(await deliveryCutStockRef.get())
                this.deliveryCutStock = item;
              }

            }
            if (register_itemRef) {
              const item = pushToObject(await register_itemRef.get());

              const { registerLocalCutStockKey, registerLocalSaleDeliveryKey, registerCoRef } = item;
              if (registerLocalCutStockKey || registerLocalSaleDeliveryKey) {
                this.canDeleteUpdate = true
              } else {
                this.canDeleteUpdate = false
              }

              if (this.collection == "register_item_usage") {
                if (registerCoRef) {
                  const item = pushToObject(await registerCoRef.get())
                  const { registerCutStock,registerCutStockKey, deliveryReturnDocKey, deliveryCoKey } = item
                  if (registerCutStock || deliveryReturnDocKey || deliveryCoKey) {
                    this.canDeleteUpdate = true
                  } else {
                    this.canDeleteUpdate = false
                  }
                }

              }
            }
            if (registerLocalSaleDeliveryRef) {
              const item = pushToObject(await registerLocalSaleDeliveryRef.get())
              this.deliverExport = item;
            }
            if (registerItemUsageRef) {
              this.registerItemUsage = pushToObject(await registerItemUsageRef.get())
              this.material = this.registerItemUsage.material;
            }
            if (registerLocalSaleInvUsageRef) {
              const item = pushToObject(await registerLocalSaleInvUsageRef.get())
              this.material = item.material;
            }
            this.cm.fetchCommentByCompany(this.collection, data.companyKey, data.key);
            this.cm.fetchFileByCompany(this.collection, data.companyKey, data.key, data => { });
          }
        });
      }
    })

  }

  ngOnDestroy(): void {
    this.subscriptionRoute && this.subscriptionRoute.unsubscribe();
  }
  trackByCreated(i, msg) {
    return msg.created_at;
  }

  editItem = null;
  editComment(item) {
    this.editItem = item
    this.form.patchValue({
      comment: item.comment
    })
  }

  postComment(f, data) {
    if (this.form.valid) {
      const isEdit = this.editItem ? true : false;
      const key = this.editItem ? this.editItem.key : this.afs.createId()
      const item = {
        key: key,
        refKey: data.key,
        comment: f.comment,
        created_at: this.editItem ? this.editItem.created_at : new Date(),
        created_by: this.editItem ? this.editItem.created_by : this.auth.user,
        updated_at: new Date(),
        updated_by: this.auth.user,
        status: statusObj.active,
        isEdit: isEdit,
      }
      this.cm.addCommentByCompany(this.collection, this.auth.selectedCompany.key, item, (success, res) => {
        // this.snackBar.open("Comment has been submit successful.", "Done", { duration: 100 });
        this.editItem = null;
        this.form.reset();
      })
    }

  }

  onSelectedFile(event) {
    this.selectedFiles = event;
    this.uploadFile()
  }

  uploadFile() {
    if (this.formUpload.valid) {
      this.fm.addFileManagerByCompany(this.router.url, this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, this.id, this.auth.user, this.selectedFiles, success => { })
    }
  }
  deleteFile(file) {
    this.fm.deleteFileDataByCompany(this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, file, (success, res) => {
      if (success)
        this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
    })
  }

  print() {
    this.ps.print('#print', 'a4');
  }
  delete(item: any) {
    this.onDelete.emit(item);
  }
  printMasterList() {

    let dialogRef = this.dialog.open(PrintRegisterItemComponent, {
      data: { item: this.data, collection: this.collection },
      panelClass: 'print-master-panel',
      width: '',
      height: '',
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  // getStatusDelete(data) {
  //   if (!data) return true;
  //   let show = true;
  //   const { isDelete } = data;
  //   if (isDelete === true)
  //     show = false
  //   return show;
  // }
  // getStatus(data) {
  //   if (!data) return true;
  //   let show = true;
  //   const { registerItemUsageKey, pickUpBillKey, registerCoKey, inspectionKey, isDelete, registerLocalSaleDeliveryKey, registerLocalCutStockKey, registerLocalSaleInvUsageKey } = data;
  //   if (inspectionKey || pickUpBillKey || registerCoKey || registerItemUsageKey || registerLocalSaleDeliveryKey || registerLocalCutStockKey || registerLocalSaleInvUsageKey)
  //     show = false;
  //   else if (isDelete == true)
  //     show = false
  //   return show;
  // }
}
