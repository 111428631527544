import { Component, OnInit, Inject } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { pushToObject } from 'src/app/services/convert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-print-register-item',
  templateUrl: './print-register-item.component.html',
  styleUrls: ['./print-register-item.component.scss']
})
export class PrintRegisterItemComponent implements OnInit {
  printDate: Date;
  material;
  importMaterial;
  CoMaterialPrintLists = [
    {key:1, text:'CO Material'},
    {key:2, text:'Customs Material'},
  ]
  selectedItem;
  constructor(
    public dialogRef: MatDialogRef<PrintRegisterItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps:PrintService,
    public auth: Auth,
    private store:SettingStore

  ) { }

  async ngOnInit() {
    this.selectedItem = this.CoMaterialPrintLists[0]
    this.printDate = new Date();
    if(this.data.item){
      const {registerItemUsageRef, registerLocalSaleInvUsageRef} = this.data.item
      if(registerItemUsageRef){
        const item = pushToObject(await registerItemUsageRef.get())
        this.material  = item?.material;
        this.importMaterial = item?.importMaterial;
      }
      if(registerLocalSaleInvUsageRef){
        const item = pushToObject(await registerLocalSaleInvUsageRef.get())
        this.material  = item?.material;
        this.importMaterial = item?.importMaterial;
      }
    }
  }
  _onSelectedItem(item){
    this.selectedItem = item;
  }
  print() {
    this.ps.print('#printms', 'a4');
    this.store.printHistory(this.data?.item, this.auth.user, success=>{})

  }
}
