import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'docDocRef'
})
export class DocDocRefPipe implements PipeTransform {

    transform(ref: any, field1: string, field2: string): any {
        if (!ref) return null;
        return ref.get().then(doc => {
            if (doc.data() !== undefined) {
                const data = doc.data()[field1];
                if (!data) return
                return data.get().then(ref => {
                    return field2 ? ref.data()[field2] : ref.data()
                })
            }
        });
    }

    // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}


@Pipe({
    name: 'docDocDocRef'
})
export class DocDocDocRefPipe implements PipeTransform {

    transform(ref: any, field1: string, field2: string, field3: string): any {
        if (!ref) return null;
        return ref.get().then(doc => {
            if (doc.data() !== undefined) {
                const data = doc.data()[field1];
                if (!data) return
                return data.get().then(ref => {
                    const data2 = ref.data()[field2];
                    if (!data2) return
                    return data2.get().then(ref2=>{
                        return field3 ? ref2.data()[field3] : ref.data()
                    })
                })
            }
        });
    }

    // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}
