<mat-sidenav-container style="min-height: calc(100vh - 168px)">
  <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'" class="content-side"
    [fixedInViewport]="true" [fixedTopGap]="118" [style.width]="ismobile?'100%':'50%'" [fixedBottomGap]="0">
    <button  mat-icon-button (click)="sidenav.toggle()"
      style="position: absolute; right: 24px; z-index: 999; top: 4px;">
      <mat-icon class="mat-18">clear</mat-icon>
    </button>
    <mat-tab-group style="height: 100%; ">

      <mat-tab style="height: 100%;">
        <ng-template mat-tab-label>
          Comment
        </ng-template>
        <div class="comment-body">
          <div class="comment-list">
            <div class="chat">
              <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                *ngFor="let msg of cm?.data;">
                <div class="user">
                  <div class="avatar">
                    <div class="name" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                      {{ msg?.created_by?.name }}</div>
                  </div>
                </div>
                <div class="msg-item">
                  <div class="c-content" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                    <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                    <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                      <mat-icon class="mat-18" style="font-size: 10px; display: flex; align-items: center;">edit
                      </mat-icon>
                      <span style="font-size: 10px;">Edited</span>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="auth?.user?.key === msg?.created_by?.key"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="editComment(msg)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div class="date" [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                    {{ msg.created_at?.toDate()|date:'medium' }} ~
                    {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                </div>
              </div>

            </div>
          </div>
          <form class="editor-block" style="position: relative;" [formGroup]="form">
            <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
              customToolbarPosition="top">
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected value="roboto">Roboto</option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                  </select>

                  <select class="ql-size">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                </span>

                <span class="ql-formats">

                  <select class="ql-align" [title]="'Aligment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                  <select class="ql-align" [title]="'Aligment2'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                  <select class="ql-color" [title]="'Color'">
                  </select>
                  <select class="ql-background" [title]="'BackgroundColor'">
                  </select>
                </span>
                <div class="custom-tb">
                  <span class="ql-formats">
                    <button class="ql-bold" [title]="'Bold'"></button>
                    <button class="ql-italic" [title]="'Italic'"></button>
                    <button class="ql-underline" [title]="'Underline'"></button>
                    <button class="ql-strike" [title]="'Strike'"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
                  <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                    (click)="postComment(form.value, data)">Post
                  </button>
                </div>

              </div>


            </quill-editor>
            <div style="height: 68px;"></div>
          </form>
        </div>
      </mat-tab>
      <mat-tab style="height: 100%; ">
        <ng-template mat-tab-label>
          Files
        </ng-template>
        <div class="comment-body file-manager">
          <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate" [value]="pct">
          </mat-progress-bar>

          <app-file-view-lists *ngIf="data" [collection]="collection" [key]="id" [companyKey]="auth?.selectedCompany?.key" [data]="data"></app-file-view-lists>

          <div style="padding: 12px; text-align: center;">

            <form [formGroup]="formUpload">
              <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                <a type="button" mat-button class="attach_file upload-button" (click)="fileInput.click()">
                  <mat-icon class="mat-18">attach_file</mat-icon>
                  <span>Add New Attachment</span>
                  <input #fileInput type="file" multiple
                    accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file formControlName="file"
                    (change)="onSelectedFile(file.files)" style="display:none;" />
                </a>
              </ng-container>

            </form>

          </div>
        </div>
      </mat-tab>
      <mat-tab style="height: 100%;">
        <ng-template mat-tab-label>
          History
        </ng-template>
        <div class="audit-lists">
          <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="cs-form-container" *mobxAutorun>
      <div class="form-wrapper" style="padding:33px 0;" id="print">
        <div class="hd-row" style="padding:0 33px 16px 33px">
          <h4 class="hd-title">{{title}}</h4>
          <div class="flex-1"></div>
          <div *ngIf="data">
            <ng-container *ngIf="!isHide">
              <div style="display: flex;">
                <ng-container *ngIf="!isDeleted">
                  <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Print">
                    <mat-icon class="mat-18">print</mat-icon>
                  </button>
                  <ng-container *ngIf="!isHideDelete">

                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_import_inv_information') && (collection == 'register_invoice_info')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>

                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_local_invoice_info') && (collection == 'register_local_invoice_info')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>
                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_import_stock') && (collection == 'register_import_stock')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>

                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_local_import_stock') && (collection == 'register_local_import_stock')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>

                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_register_co_cut_stock') && (collection == 'register_cut_stock')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>


                    <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint"
                      matTooltip="Delete"
                      *ngIf="(auth?.userRoles | userPermissionRole:'delete_local_cut_stock') &&  (collection == 'local_cut_stock')">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button>


                  </ng-container>

                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_local_cut_stock') &&  (collection == 'local_cut_stock')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>
                  <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Release"
                    *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_local_cut_stock') && (collection == 'local_cut_stock')">
                    <mat-icon class="mat-18">edit_off</mat-icon>
                  </button>

                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_import_inv_information') && (collection == 'register_invoice_info')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_local_invoice_info') && (collection == 'register_local_invoice_info')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_import_stock') && (collection == 'register_import_stock')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Release"
                    *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_register_co_import_stock') && (collection == 'register_import_stock')">
                    <mat-icon class="mat-18">edit_off</mat-icon>
                  </button>


                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_local_import_stock') && (collection == 'register_local_import_stock')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Release"
                    *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_register_local_import_stock') && (collection == 'register_local_import_stock')">
                    <mat-icon class="mat-18">edit_off</mat-icon>
                  </button>



                  <button mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Edit"
                    *ngIf="(auth?.userRoles | userPermissionRole:'update_register_co_cut_stock') && (collection == 'register_cut_stock')">
                    <mat-icon class="mat-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button (click)="_onRelease()" style="margin-right: 8px;" class="hideOnPrint"
                    matTooltip="Release"
                    *ngIf="!isRelease && (auth?.userRoles | userPermissionRole:'update_register_co_cut_stock') && (collection == 'register_cut_stock')">
                    <mat-icon class="mat-18">edit_off</mat-icon>
                  </button>


                  <!-- </ng-container> -->
                </ng-container>
                <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                  <mat-icon class="mat-18">insert_comment</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">


          <ng-container *ngIf="collection == 'register_ob_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNumber}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col ">No.</th>
                    <th class="table-col ">Code</th>
                    <th class="table-col ">Description</th>
                    <th class="table-col ">UOM</th>
                    <th class="table-col ">Quantity</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row" *ngFor="let item of data?.materials;let i=index;">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                      <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.qty | number}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>

          <ng-container *ngIf="collection == 'register_adjustment_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNo}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col ">No.</th>
                    <th class="table-col ">Code</th>
                    <th class="table-col ">Description</th>
                    <th class="table-col ">UOM</th>
                    <th class="table-col ">Quantity</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <ng-container>
                    <tr class="table-row" *ngFor="let item of data?.materials;let i=index;">
                      <td class="table-col">{{i+1}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                      <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                      <td class="table-col">{{item?.qty | number}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


          </ng-container>

          <ng-container *ngIf="collection == 'register_invoice_info'">
            <div class="dl-items">
              <div class="dl-title">Import Invoice Number</div>
              <div class="dl-text">: {{data?.invoice_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Import Invoice Date</div>
              <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Permit Number</div>
              <div class="dl-text">: {{data?.permit_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Permit Date</div>
              <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Custom Number</div>
              <div class="dl-text">: {{data?.custom_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Custom Date</div>
              <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Cam Control Number</div>
              <div class="dl-text">: {{data?.camControl_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Cam Control Date</div>
              <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Bill Number</div>
              <div class="dl-text">: {{data?.bill_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Application Form</div>
              <div class="dl-text">: {{data?.co_formRef | docRef: 'name' | async}}</div>
            </div>

          </ng-container>
          <ng-container *ngIf="collection == 'register_local_invoice_info'">
            <div class="dl-items">
              <div class="dl-title">Local Purchase Invoice Number</div>
              <div class="dl-text">: {{data?.invoice_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Local Purchase Invoice Date</div>
              <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Permit Number</div>
              <div class="dl-text">: {{data?.permit_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Permit Date</div>
              <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Custom Number</div>
              <div class="dl-text">: {{data?.custom_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Custom Date</div>
              <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Cam Control Number</div>
              <div class="dl-text">: {{data?.camControl_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Cam Control Date</div>
              <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Bill Number</div>
              <div class="dl-text">: {{data?.bill_number}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Application Form</div>
              <div class="dl-text">: {{data?.co_formRef | docRef: 'name' | async}}</div>
            </div>
            <!-- <div class="dl-items">
              <div class="dl-title"> Customs Material</div>
              <div class="dl-text">:</div>
            </div>

            <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.importMaterial?.length > 0">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center">No.</th>
                    <th class="table-col center">Customs Material Code</th>
                    <th class="table-col center">Customs Material Name</th>
                    <th class="table-col center">N.W</th>
                    <th class="table-col center">G.W</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row" *ngFor="let item of data?.importMaterial;let i=index">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'code'|async}}
                    </td>
                    <td class="table-col">
                      {{item?.materialRef|docRef:'name'|async}}
                    </td>
                    
                    <td class="table-col right">
                      {{item?.qtyNw | number}}
                    </td>

                    <td class="table-col right">
                      {{item?.qtyGw | number}}
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-col right" colspan="3">
                      Total
                    </td>
                    <td class="table-col right">
                      {{ (data?.importMaterial | sumAmount:'qtyNw') | number}}
                    </td>
                    <td class="table-col right">
                      {{ (data?.importMaterial | sumAmount:'qtyGw') | number}}
                    </td>

                  </tr>
                </tbody>
              </table>
            </div> -->

            

          </ng-container>

          <ng-container *ngIf="collection == 'register_import_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNo}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Released Date</div>
              <div class="dl-text">:
                {{data?.release_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Khmer Date</div>
              <div class="dl-text">:
                {{data?.khmerDate}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Lunar Date</div>
              <div class="dl-text">:
                {{data?.lunarDate}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                {{data?.title}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">CO Form</div>
              <div class="dl-text">:
                {{data?.co_formRef | docRef:'name' | async}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> CO Import Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center" rowspan="2">No.</th>
                    <th class="table-col center" rowspan="2">Import Country</th>
                    <th class="table-col center" colspan="2">Invoice</th>
                    <th class="table-col center" colspan="2">Permit</th>
                    <th class="table-col center" colspan="2">Custom</th>
                    <th class="table-col center" colspan="2">Cam Control</th>

                    <th class="table-col center" rowspan="2">Bill Number</th>
                    <th class="table-col center" rowspan="2">CAT</th>
                    <th class="table-col center" rowspan="2">Qty(Order)</th>
                    <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                    <th class="table-col center" rowspan="2">Export to</th>
                  </tr>
                  <tr class="table-row">
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row" *ngFor="let item of data?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.countryRef | docRef : 'name' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_number}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.permit_number}}
                    </td>
                    <td class="table-col">
                      {{item?.permit_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.custom_number}}
                    </td>
                    <td class="table-col">
                      {{item?.custom_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.camControl_number}}
                    </td>
                    <td class="table-col">
                      {{item?.camControl_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.bill_number}}
                    </td>
                    <td class="table-col right">
                      {{item?.cat}}
                    </td>
                    <td class="table-col right">
                      {{item?.qty}}
                      {{item?.qty_order_UOMRef | docRef : 'code' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.export_to}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>

            <app-group-stock-material *ngIf="data" [data]="data" [key]="data?.key"></app-group-stock-material>
          </ng-container>

          <ng-container *ngIf="collection == 'register_local_import_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNo}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Released Date</div>
              <div class="dl-text">:
                {{data?.release_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Khmer Date</div>
              <div class="dl-text">:
                {{data?.khmerDate}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Lunar Date</div>
              <div class="dl-text">:
                {{data?.lunarDate}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                {{data?.title}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">CO Form</div>
              <div class="dl-text">:
                {{data?.co_formRef | docRef:'name' | async}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title"> Local Purchase Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center" rowspan="2">No.</th>
                    <th class="table-col center" rowspan="2">Import Country</th>
                    <th class="table-col center" colspan="2">Invoice</th>
                    <th class="table-col center" colspan="2">Permit</th>
                    <th class="table-col center" colspan="2">Custom</th>
                    <th class="table-col center" colspan="2">Cam Control</th>

                    <th class="table-col center" rowspan="2">Bill Number</th>
                    <th class="table-col center" rowspan="2">CAT</th>
                    <th class="table-col center" rowspan="2">Qty(Order)</th>
                    <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                    <th class="table-col center" rowspan="2">Export to</th>
                  </tr>
                  <tr class="table-row">
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row" *ngFor="let item of data?.items;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">
                      {{item?.countryRef | docRef : 'name' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_number}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.permit_number}}
                    </td>
                    <td class="table-col">
                      {{item?.permit_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.custom_number}}
                    </td>
                    <td class="table-col">
                      {{item?.custom_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.camControl_number}}
                    </td>
                    <td class="table-col">
                      {{item?.camControl_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.bill_number}}
                    </td>
                    <td class="table-col right">
                      {{item?.cat}}
                    </td>
                    <td class="table-col right">
                      {{item?.qty}}
                      {{item?.qty_order_UOMRef | docRef : 'code' | async}}
                    </td>
                    <td class="table-col">
                      {{item?.export_to}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>

            <app-group-stock-material *ngIf="data" [data]="data" [key]="data?.key"></app-group-stock-material>
          </ng-container>

          <ng-container *ngIf="collection == 'register_cut_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNo}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Khmer Date</div>
              <div class="dl-text">:
                {{data?.khmerDate}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Lunar Date</div>
              <div class="dl-text">:
                {{data?.lunarDate}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                {{data?.title}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                {{data?.description}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Released Date</div>
              <div class="dl-text">:
                {{data?.release_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Export CO</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center" rowspan="2">No.</th>
                    <th class="table-col center" colspan="2">CO</th>
                    <th class="table-col center" colspan="2">Invoice</th>
                    <th class="table-col center" rowspan="2">Shipping Date</th>
                    <th class="table-col center" rowspan="2">Description</th>
                    <th class="table-col center" rowspan="2">Route</th>
                    <th class="table-col center" rowspan="2">Destination</th>
                    <th class="table-col center" rowspan="2">CAT</th>
                    <th class="table-col center" rowspan="2">Amount</th>
                    <th class="table-col center" rowspan="2">Qty Packing</th>
                    <th class="table-col center" rowspan="2">Qty Unit</th>
                  </tr>
                  <tr class="table-row">
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>
                    <td class="table-col">{{item?.co_number}}</td>
                    <td class="table-col">
                      {{item?.co_date?.toDate() | date}}</td>
                    <td class="table-col">
                      {{item?.invoice_number}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.etd?.toDate() | date}}</td>
                    <td class="table-col">
                      {{item?.itemName}}
                    </td>
                    <td class="table-col">
                      {{item?.routeName}}</td>
                    <td class="table-col">
                      {{item?.countryName}}
                    </td>
                    <td class="table-col right">
                      {{item?.cat}}
                    <td class="table-col right">
                      {{item?.amount_FOB}}
                    </td>
                    <td class="table-col right">
                      {{item?.qty_packing | number:'1.'}}
                      {{item?.qty_packing_UOMRef | docRef : 'code' | async}}
                    </td>

                    <td class="table-col right">
                      {{item?.qty_unit | number:'1.'}}
                      {{item?.qty_unit_UOMRef | docRef : 'code' | async}}
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>
            <app-group-cut-stock-material *ngIf="data" [data]="data" [key]="data?.key"></app-group-cut-stock-material>
          </ng-container>

          <ng-container *ngIf="collection == 'local_cut_stock'">
            <div class="dl-items">
              <div class="dl-title"> Reference No.</div>
              <div class="dl-text">: {{data?.refNo}}</div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Khmer Date</div>
              <div class="dl-text">:
                {{data?.khmerDate}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title">Lunar Date</div>
              <div class="dl-text">:
                {{data?.lunarDate}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Title</div>
              <div class="dl-text">:
                {{data?.title}}
              </div>
            </div>
            <!--
                        <div class="dl-items">
                            <div class="dl-title">CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}
                            </div>
                        </div> -->

            <div class="dl-items">
              <div class="dl-title">Document Type</div>
              <div class="dl-text">:
                {{data?.description}}
              </div>
            </div>

            <div class="dl-items">
              <div class="dl-title">Application Date</div>
              <div class="dl-text">:
                {{data?.application_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Released Date</div>
              <div class="dl-text">:
                {{data?.release_date?.toDate() | date}}
              </div>
            </div>
            <div class="dl-items">
              <div class="dl-title"> Local Sale Invoice</div>
              <div class="dl-text">:</div>
            </div>
            <div class="scrollable-table" style="margin: 0;" id="print">
              <table class="table-wrapper tb-master">
                <thead class="table-header">
                  <tr class="table-row">
                    <th class="table-col center" rowspan="2">No.</th>
                    <th class="table-col center" colspan="2">Invoice</th>
                    <th class="table-col center" rowspan="2">Shipping Date</th>
                    <th class="table-col center" rowspan="2">Description</th>
                    <th class="table-col center" rowspan="2">Route</th>
                    <th class="table-col center" rowspan="2">Destination</th>
                    <th class="table-col center" rowspan="2">CAT</th>
                    <th class="table-col center" rowspan="2">Amount</th>
                    <th class="table-col center" rowspan="2">Qty Packing</th>
                    <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                    <th class="table-col center" rowspan="2">Qty Unit</th>
                    <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                  </tr>
                  <tr class="table-row">
                    <th class="table-col center">Number</th>
                    <th class="table-col center">Date</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index;">
                    <td class="table-col">{{i+1}}</td>

                    <td class="table-col">
                      {{item?.invoice_number}}
                    </td>
                    <td class="table-col">
                      {{item?.invoice_date?.toDate() | date}}
                    </td>
                    <td class="table-col">
                      {{item?.shipping_date?.toDate() | date}}
                    </td>

                    <td class="table-col">
                      {{item?.itemName}}
                    </td>
                    <td class="table-col">
                      {{item?.routeRef | docRef : 'name' | async}}</td>
                    <td class="table-col">
                      {{item?.countryRef | docRef : 'name' | async}}
                    </td>
                    <td class="table-col right">
                      {{item?.cat}}
                    <td class="table-col right">
                      {{item?.amount_FOB}}
                    </td>
                    <td class="table-col right">
                      {{item?.qty_packing | number:'1.'}}
                      {{item?.qty_packing_UOMRef | docRef : 'code' | async}}
                    </td>

                    <td class="table-col right">
                      {{item?.qty_unit | number:'1.'}}
                      {{item?.qty_unit_UOMRef | docRef : 'code' | async}}
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

            <div class="dl-items">
              <div class="dl-title"> CO Material</div>
              <div class="dl-text">:</div>
            </div>

            <app-local-group-cut-stock-material *ngIf="data" [data]="data" [key]="data?.key">
            </app-local-group-cut-stock-material>
          </ng-container>


          <div class="dl-items" *ngIf="data?.status">
            <div class="dl-title">Status</div>
            <div class="dl-text">:
              <div class="data">
                {{data?.status?.text}}
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="store?.process">
          <div style="padding: 33px;">
            <app-line-loading></app-line-loading>
            <app-line-loading></app-line-loading>
          </div>
        </ng-container>

      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>