
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { statusLists, REGISTER_ITEM_STATUS_OBJ } from 'src/app/dummy/status';
import { AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class SearchStore {
    @observable data: Array<any> = [];
    @observable filterdata: Array<any> = [];
    @observable filter: Array<any> = [];
    @observable filterdatas: Array<any> = [];
    @observable collectionData: Array<any> = [];
    @observable singleData: any;
    @observable process: boolean = false;
    @observable deleteProcess: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    constructor(private ds: DataService) { }

    @action
    fetchCollectionByCompany(collection: any, companyKey: any, fieldOrder, callback) {
        this.ds.autoCollectionByCompanyRef(collection, companyKey, fieldOrder).valueChanges().subscribe(docs => {
            callback(docs);
        })
    }

    @action
    fetchAutoCompleted(ref: AngularFirestoreCollection, callback) {
        ref.valueChanges().subscribe(docs => {
            this.filterdata = docs;
            callback(docs);
        })
    }

    @action
    fetchAutoData(collection) {
        this.ds.autoColelctionRef(collection).valueChanges().subscribe(data => {
            this.filterdata = data;
        })
    }
    @action
    fetchAutoDataOutstandingByCompanyCallBack(collection, outstandingfield, companyKey, field, callback) {
        this.ds.autoColelctionOutstandingByCompanyRef(collection, outstandingfield, companyKey, field).valueChanges().subscribe(data => {
            this.filterdata = data;
            callback(data)
        })
    }

    @action
    async fetchSearchOutstandingByCompany(collectionName: string, outstandingfield, companyKey: string, field: string, search: any, callback) {
        return this.ds.searchfilterListingOutstandingByCompanyTypeRef(collectionName, outstandingfield, companyKey, field, search).valueChanges().subscribe(docs => {
            this.filterdata = docs;
            callback(docs)
        });
    }

    @action
    search(option: any, companyKey, field, search: any) {
        if (option.setting == true) {
            return this.ds.searchSettingRef(option.collection, field, search).valueChanges()
        } else {
            return this.ds.searchRef(option.collection, companyKey, field, search).valueChanges()
        }
    }

    @action
    fetchCountryPort(countryKey, callback) {
        this.loading = true;
        return this.ds.getPort(countryKey).valueChanges().subscribe(data=>{
            this.loading = false;
            callback(data)
        })
    }



}


