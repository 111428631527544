<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">

        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content report-wrapper">
        <div style="flex:1; display: flex; flex-direction: column;">
            <table id="printms" style="width: 100%;">
                <tbody>
                    <tr>
                        <td>
                            <div class="page" style="width: 100%;">
                                <ng-container
                                    *ngIf="data?.collection == 'register_letter_form' || data?.collection == 'register_cancel_co_letter' || data?.collection == 'register_reapply_letter' || data?.collection == 'register_duplicate_letter'">
                                    <table style="margin: 0 auto;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="page">
                                                        <div class="report-header" style="max-width:190mm">
                                                            <div class="d-flex">
                                                                <div class="flex-1"></div>
                                                                <div class="title-info bavacana">
                                                                    <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                                    <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                                    <div class="star">************</div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: flex-end; margin-top: -28px;">
                                                                <div class="info" style="text-align: left;">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <div class="khf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_kh">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_kh'">
                                                                                </app-company-field>
                                                                            </div>
                                                                            <div class="enf bold"
                                                                                *ngIf="auth?.selectedCompany?.name_en">
                                                                                <app-company-field
                                                                                    [key]="auth?.selectedCompany?.key"
                                                                                    [feild]="'name_en'">
                                                                                </app-company-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="">
                                                                        <span class="enf">
                                                                            <app-company-field
                                                                                [key]="auth?.selectedCompany?.key"
                                                                                [feild]="'address_kh'">
                                                                            </app-company-field>
                                                                        </span>
                                                                    </div>
                                                                    <div class="enf">
                                                                        Reference No:
                                                                        <span class="enf">{{data?.item?.olddata?.refNo}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                                                                <div class="khf" style="text-align: right;">
                                                                    <p>
                                                                        {{data?.item?.olddata?.lunarDate}}
                                                                    </p>
                                                                    <p style="margin-bottom: 6px;">
                                                                        {{data?.item?.olddata?.khmerDate}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="d-flex"
                                                                style="align-items: center; justify-content: center; padding: 20px 0;">
                                                                <div style="text-align: center; margin-bottom: 6px;"
                                                                    class="bold khf">
                                                                    <h3 style="text-decoration: underline;">សូមគោរពជូន
                                                                    </h3>
                                                                    <h3 style="text-decoration: underline;">
                                                                        {{data?.item?.olddata?.titleRef | docRef:'name_kh' | async}}
                                                                        ក្រសួងពាណិជ្ជកម្ម</h3>
                                                                </div>
                                                            </div> -->

                                                            <div class="d-flex" style="align-items: flex-end;">
                                                                <div class="info">
                                                                    <div class="c-comment"
                                                                        [innerHtml]="data?.item?.olddata?.text | safe:'html'">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="margin-top: 12px;">
                                                            <div class="d-flex"
                                                                style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                                <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div class="page-footer-space"></div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_other_letter'">
                                    <table style="margin: 0 auto;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="page">
                                                        <div class="report-header" style="max-width:190mm">

                                                            <div class="d-flex" style="align-items: flex-end;">
                                                                <div class="info">
                                                                    <div class="c-comment"
                                                                        [innerHtml]="data?.item?.olddata?.text | safe:'html'">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="margin-top: 12px;">
                                                        <div class="d-flex"
                                                            style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                                            <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                                                        </div>
                                                    </div> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div class="page-footer-space"></div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space"></div>

                            <div class="page-footer">
                                <div class="d-flex print-date">
                                    <div class="enf"
                                        style="margin-right: 12px; color:#555; font-size: 8px !important; text-align: left;">
                                        <ng-container *ngIf="!selectedTemplate">
                                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address'">
                                            </app-company-field>
                                        </ng-container>
                                        <ng-container *ngIf="selectedTemplate">
                                            {{selectedTemplate?.address_en}}
                                        </ng-container>
                                    </div>

                                    <div class="flex-1"></div>
                                    <div class="enf" style="margin-right: 12px; font-size: 8px !important;">
                                        {{data?.olddata?.refNo}}
                                    </div>
                                    <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}
                                    </div>

                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>
    <div class="dialog-footer" style="padding: 24px;">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"><span>Cancel</span></a>
        <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
            style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
            <mat-icon class="mat-18">print</mat-icon>
            Print
        </button>
    </div>
</form>