import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentStore } from 'src/app/stores/comment.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Auth } from 'src/app/stores/auth.store';
import { statusObj } from 'src/app/dummy/status';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PERMISSION } from 'src/app/dummy/permission';
import { orderBy } from 'src/app/services/convert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-settings-detail',
  templateUrl: './settings-detail.component.html',
  styleUrls: ['./settings-detail.component.scss']
})
export class SettingsDetailComponent implements OnInit {
  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;

  @Output() onDelete = new EventEmitter();
  @ViewChild('content') content: ElementRef;
  @ViewChild('editor') editor;
  @ViewChild('one', { static: false }) d1: ElementRef;
  modules = {};
  form: FormGroup;
  comment: AbstractControl;
  selectedFiles: any;
  formUpload: FormGroup;
  file: AbstractControl;
  id;
  encodedUrl;
  ismobile = false;
  SYS_PERMISSION = PERMISSION

  auditData;

  userRoles:[];
  subscriptionRoute: Subscription;

  constructor(
    public store: SettingStore,
    public ps: PrintService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public cm: CommentStore,
    private afs: AngularFirestore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public fm: FileManagerStore,
    public breakpointObserver: BreakpointObserver,
    private router: Router,

  ) {
    this.modules = {
      // imageResize: {},
      syntax: false,
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['formula'],
        ['image', 'code-block']
      ]
    }
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
    this.formUpload = this.fb.group({
      file: [null, Validators.required]
    });
    this.form = this.fb.group({
      comment: [null, Validators.required]
    })
    this.comment = this.form.controls['comment']
    this.file = this.form.controls['file']

    this.subscriptionRoute = this.route.params.subscribe(async param => {
      if (param && param.id) {
        this.id = param.id

        this.cm.fetchComment(this.collection, param.id);
        // this.store.detailCollection(this.collection, param.id, data => {
       
        // });

        if (this.collection === 'users') {
          this.store.fetchUserRolePermission(param.id, udata => {
            if (udata) {
              const {permissions,roles} = udata;
              
              if(roles && roles.length > 0){
                this.userRoles = roles.map(m=>{
                  return{
                    ...m,
                    permissions:permissions.filter(f=>f.roleKey === m.key),
                  }
                })
              }
            }
          })
        }
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptionRoute && this.subscriptionRoute.unsubscribe();
  }
 
  editItem = null;
  editComment(item) {
    this.editItem = item
    this.form.patchValue({
      comment: item.comment
    })
  }

  postComment(f, data) {
    if (this.form.valid) {
      const isEdit = this.editItem ? true : false;
      const key = this.editItem ? this.editItem.key : this.afs.createId()
      const item = {
        key: key,
        refKey: data.key,
        comment: f.comment,
        created_at: this.editItem ? this.editItem.created_at : new Date() ,
        created_by: this.editItem ? this.editItem.created_by : this.auth.user,
        updated_at: new Date() ,
        updated_by: this.auth.user,
        status: statusObj.active,
        isEdit: isEdit,
      }
      this.cm.addComment(this.collection, item, (success, res) => {
        // this.snackBar.open("Comment has been submit successful.", "Done", { duration: 100 });
        this.editItem = null;
        this.form.reset();
      })
    }

  }

  onSelectedFile(event) {
    this.selectedFiles = event;
    this.uploadFile()
  }

  uploadFile() {
    if (this.formUpload.valid) {
      if (this.collection == 'company' || this.collection == 'users') {
        this.fm.addFileManagerC(this.router.url, this.collection, this.collection == 'company' ? this.data.name_en : this.auth.selectedCompany.name_en, this.collection == 'company' ? this.data.key : this.auth.selectedCompany.key, this.id, this.auth.user, this.selectedFiles, success => { })
      } else {
        this.fm.addFileManager(this.router.url, this.collection, this.id, this.auth.user, this.selectedFiles, success => { })
      }
    }
  }
  deleteFile(file) {

    if (this.collection == 'company' || this.collection == 'users') {
      this.fm.deleteFileDataC(this.collection == 'company' ? this.data.name_en : this.auth.selectedCompany.name_en, this.collection, this.collection == 'company' ? this.data.key : this.auth.selectedCompany.key, file, (success, res) => {
        if (success)
          this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
      })
    } else {
      this.fm.deleteFileData(this.collection, file, (success, res) => {
        if (success)
          this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
      })
    }
  }


  print() {
    this.ps.print('#print', 'a4');
    this.store.printHistory(this.data, this.auth.user, success=>{})

  }
  makePdf() {
   
  }

  delete(item: any) {
    this.onDelete.emit(item);
  }

}
