import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})
export class TabLayoutComponent implements OnInit {
  constructor() { }
  ngOnInit() {}

}
