import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'calCutStockBalance'
})
export class CalCutStockBalancePipe implements PipeTransform {

  transform(items: Array<any>, field: string): any {
    const total = sum(items, field);
    return total;
  }

}
