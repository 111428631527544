import { Component, OnInit } from '@angular/core';
import { Auth } from 'src/app/stores/auth.store';
@Component({
  selector: 'app-authlayout',
  templateUrl: './authlayout.component.html',
  styleUrls: ['./authlayout.component.scss']
})
export class AuthlayoutComponent implements OnInit {

  constructor(
    private auth: Auth
  ) {
  }

  ngOnInit() { 
  }
  ngOnDestroy(): void {
 
  }
}
