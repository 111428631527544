import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-group-void-stock',
  templateUrl: './group-void-stock.component.html',
  styleUrls: ['./group-void-stock.component.scss']
})
export class GroupVoidStockComponent implements OnInit {

  @Input() key: any;

  data: any = [];
  materials: any = [];
  group: any = [];
  SubscriptionRef: Subscription = null;

  constructor(private ds: DataService) { }

  ngOnInit() {
    this.data = [];
    this.SubscriptionRef = this.ds.registerVoidStockRef(this.key).valueChanges().subscribe((doc: any) => {
      if (doc.materials) {
        this.materials = doc.materials || [];
      }
    })
  }
  ngOnDestroy(): void {
    this.SubscriptionRef && this.SubscriptionRef.unsubscribe()
  }

}
