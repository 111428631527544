import { Pipe, PipeTransform } from '@angular/core';
import { SetStatusStore } from '../stores/set-status.store';

@Pipe({
    name: 'coRefDoc'
})
export class CoRefDocPipe implements PipeTransform {
    constructor(
        private store: SetStatusStore
    ) { }
    transform(ref: any): any {
        if (ref) {
            ref.get().then(doc => {
            });
        }
    }

    // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}


@Pipe({
    name: 'colCount'
})
export class colCountPipe implements PipeTransform {

    transform(col1: any, col2: any): any {
        if (col1 && col2) {
            return 2
        } else if (col1 || col2) {
            return 1
        } else {
            return 0
        }
    }

    // <strong>{{item?.countryRef|docRef:'name' | async}}</strong>
}
