import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class groupByPipe implements PipeTransform {
  transform(array: any, key: any): any {
    const newArray = array.filter(i => i.pickupInspectionKey === key)
    return newArray
  }
}


@Pipe({
  name: 'stockGroupBy'
})
export class stockGroupByPipe implements PipeTransform {
  transform(array: any, key: any): any {
    const newArray = array.filter(i => i.transactionKey === key)
    return newArray
  }
}

@Pipe({
  name: 'findCompany'
})
export class findCompanyPipe implements PipeTransform {

  transform(items: any[], text: any): unknown {
    if (items && text) {
      return items.filter(m => `${m.name_en}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1);
    }
    return items;
  }

}

@Pipe({
  name: 'filterData'
})
export class filterDataPipe implements PipeTransform {

  transform(items: any[], text: any): unknown {
    if (text) {
      const search = text.toUpperCase();
      return items.filter(m => m.keyword.filter(k => k === search).length > 0);
    }
    return items;
  }

}