import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Auth } from 'src/app/stores/auth.store';

@Component({
  selector: 'app-register-item-materials',
  templateUrl: './register-item-materials.component.html',
  styleUrls: ['./register-item-materials.component.scss']
})
export class RegisterItemMaterialsComponent implements OnInit {

  @Input() key: any;
  data: any = [];
  SubscriptionRef: Subscription = null;
  SubscriptionRef2: Subscription = null;

  constructor(
    private ds: DataService,
    private auth: Auth
  ) { }

  ngOnInit() {
    this.SubscriptionRef = this.ds.registerItemDocRef(this.key, this.auth.selectedCompany.key).valueChanges().subscribe((doc: any) => {
      this.data = doc;
      const { registerItemUsageKey } = doc;
      if (registerItemUsageKey) {
        this.SubscriptionRef2 = this.ds.registerItemUsageDocRef(registerItemUsageKey, this.auth.selectedCompany.key).valueChanges().subscribe((doc: any) => {
          this.data.material = doc.material || [];
        });
      }
    })
  }
  ngOnDestroy(): void {
    this.SubscriptionRef && this.SubscriptionRef.unsubscribe();
    this.SubscriptionRef2 && this.SubscriptionRef2.unsubscribe();

  }

}
