import { Pipe, PipeTransform } from '@angular/core';
import { sumStock } from '../services/convert.service';

@Pipe({
  name: 'sumStock'
})
export class SumStockPipe implements PipeTransform {

  transform(array: any, filed: string): any {
    const total = sumStock(array)
    return total;
  }

}
