import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
declare var $: any;
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
// import 'jspdf-autotable';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({ providedIn: 'root' })
export class PrintService {

  constructor() { }


  public exportAsExcelFile(rows: any[], excelFileName: string): void {
    if (rows.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
      const workbook: XLSX.WorkBook = { Sheets: { 'Compte-rendu': worksheet }, SheetNames: ['Compte-rendu'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } else {
      // this.notifService.message('Aucune ligne à exporter...');
    }
  }
  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, baseFileName + '_' + this.getDateFormat(new Date()) + EXCEL_EXTENSION);
  }

  private getDateFormat(date: Date): string {
    return formatDate(date, 'yyyyMMdd_HHmmss', 'en-US');
  }
  savepdf(title: any) {
    // let data = document.getElementById('contentToConvert');
    // var doc = new jsPDF('l', 'mm', 'a4')
    // html2canvas(data).then(canvas => {
    //   var imgWidth = 208;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas
    //   var position = 25;
    //   doc.setFontSize(12)
    //   doc.text(5, 12, title)
    //   doc.setDrawColor(140)
    //   doc.line(300, 20, 0, 20)
    //   doc.setLineWidth(1)
    //   doc.addImage(contentDataURL, '*', 0, position, imgWidth, imgHeight)
    //   doc.save(title + '.pdf');
    // });
    // doc.setFontSize(12)
    // doc.text(15, 10, title)
    // doc.setDrawColor(140)
    // doc.line(300, 12, 0, 12)
    // doc.setLineWidth(1)
    // doc.autoTable({ html: '#contentToConvert' });
    // doc.save(title + new  Date().getTime() + '.pdf');

  }
  
  exportExcel(tableName: string, table: any, isCustom?: boolean) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table.nativeElement, { raw: true, cellDates: false, cellStyles:true });
    const range = XLSX.utils.decode_range(ws['!ref']);
    const regex = /^[-\d.,]+$/;
    let numberFormat = '#,##0.00';
    if (isCustom) {
      numberFormat = '#,##0.0000';
    }
    // Loop through the cells in the range, including merged cells
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        // Get the current value of the cell
        const cellValue = ws[cellAddress] ? ws[cellAddress].v : null;
        // console.log('cellValue', cellValue);
        // Modify the value as needed
        if (typeof cellValue === 'string' && regex.test(cellValue)) {
          // If the cell is a string with a number, convert it to a string
          // const numericValue = Number(cellValue) || 0;
          const number = parseFloat(cellValue.replace(/,/g, ""));
          // console.log('cellValue', cellValue, numericValue, number);
          ws[cellAddress].v = number;
          ws[cellAddress].t = 'n';
          ws[cellAddress].z = numberFormat;
        }
        // If the cell is anything else, leave it as is (e.g. null for merged cells)
      }
    }


    // for (let row = range.s.r; row <= range.e.r; row++) {
    //   for (let col = range.s.c; col <= range.e.c; col++) {
    //     const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
    //     const cellValue = ws[cellAddress]?.v;

    //     // Check if the cell value is a string with only digits
    //     if (typeof cellValue === 'string' && /^\d+$/.test(cellValue)) {
    //       // Convert the cell value to a number
    //       const numericValue = parseInt(cellValue);
    //       // Set the cell value to the numeric value
    //       if(ws[cellAddress].v && ws[cellAddress].t){
    //         ws[cellAddress].v = numericValue;
    //         ws[cellAddress].t = 'n';
    //       }
    //     } else {
    //       // Set the cell value to the string value
    //       if(ws[cellAddress].v){
    //         ws[cellAddress].v = cellValue;
    //       }
    //     }
    //   }
    // }

    XLSX.utils.book_append_sheet(wb, ws, tableName);
    XLSX.writeFile(wb, tableName + new Date().getTime() + '.xlsx');

    // const tableData = table.nativeElement;
    // const workbook = XLSX.utils.book_new();
    // const cellData = [];
    // tableData.querySelectorAll('tr').forEach((row) => {
    //   const rowData = [];
    //   row.querySelectorAll('td').forEach((cell) => {
    //     rowData.push(cell.innerText);
    //   });
    //   cellData.push(rowData);
    // });
    // const regex = /^[\d.,]+$/;

    // cellData.forEach((row: any, rowIndex) => {
    //   row.forEach((cell, cellIndex) => {
    //     if (typeof cell === 'string' && regex.test(cell.trim())) {
    //       cellData[rowIndex][cellIndex] = Number(cell) || 0;
    //     } else {
    //       cellData[rowIndex][cellIndex] = cell.toString() || '';
    //     }
    //   });
    // });
    // console.log('cellData', cellData);

    // const worksheet = XLSX.utils.aoa_to_sheet(cellData);

    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // XLSX.writeFile(workbook, tablename + new Date().getTime() + '.xlsx');


  }

  print(section: any, size: any) {
    switch (size) {
      case 'a5':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a5.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        });
        break;
      case 'a4':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a4.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'a4l':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a4l.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'a5l':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a5l.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'rp':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/rollpaper.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;

      default:
        break;
    }
  }
}
