import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';
import { totalStore } from '../stores/getTotal.store';

@Pipe({
    name: 'sumRgControl'
})
export class sumRgControlPipe implements PipeTransform {
    items: any;
    itemsArr = []
    constructor(
        public total:totalStore
    ){
        
    }
    async transform(array: any, field: string): Promise<any> {
        if (!array) return
        let data = [];
        for (const item of array) {
            let d = await item.itemRef.get()
            data.push(d.data())
        }
        if (data) {
            let mm = data.map(f => ({ [field]: f[field] }))
            const ss = mm.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t[field] === item[field]
                ))
            )
            this.total.totalControlArray.push({value:ss.length})
            const total = sum(this.total.totalControlArray, 'value')
            this.total.settotalControl(total)
            return ss.length
        }

    }

}
