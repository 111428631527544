import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SubmitApplicationPrintComponent } from '../submit-application-print/submit-application-print.component';
import { PrintService } from 'src/app/services/print.service';
import { SubmitDetail } from 'src/app/stores/submit-detail.store';
import { ActivatedRoute, Router } from '@angular/router';
import { ConvertService, orderBy } from 'src/app/services/convert.service';
import { ApplicationDetailPrintComponent } from '../application-detail-print/application-detail-print.component';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CommentStore } from 'src/app/stores/comment.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Auth } from 'src/app/stores/auth.store';
import { statusObj } from 'src/app/dummy/status';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-application-co-register-detail',
  templateUrl: './application-co-register-detail.component.html',
})
export class ApplicationCoRegisterDetailComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHidePrint: boolean = false;
  @Input() isHideDelete: boolean = false;
  @Input() isHideEdit: boolean = false;

  @Output() onDelete = new EventEmitter();
  @Output() cancelSubmit = new EventEmitter();
  @ViewChild('content') content: ElementRef;
  detailData: any;
  isReturn: any;
  isCutStock: any;
  isDeliveryCO: any;

  @ViewChild('editor') editor;
  modules = {};
  selectedFiles: any;

  form: FormGroup;
  comment: AbstractControl;

  formUpload: FormGroup;
  file: AbstractControl;
  id;
  ismobile = false;
  dataUOM;
  auditData;
  printTemplateData;
  @Input() printTemplate: number;

  constructor(
    public ps: PrintService,
    private dialog: MatDialog,
    public detail: SubmitDetail,
    public store: SettingStore,
    private routes: ActivatedRoute,
    private route: Router,
    private fb: FormBuilder,
    public cm: CommentStore,
    private afs: AngularFirestore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public fm: FileManagerStore,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.modules = {
      syntax: false,
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['formula'],
        ['image', 'code-block']
      ]
    }
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
    this.form = this.fb.group({
      comment: [null, Validators.required]
    })
    
    this.formUpload = this.fb.group({
      file: [null, Validators.required]
    });
    this.comment = this.form.controls['comment']
    this.file = this.form.controls['file']
    this.routes.params.subscribe(async param => {
      if (param && param.id) {
        this.id = param.id
        this.detail.detailCollectionByCompany(this.collection, param.id, this.auth.selectedCompany.key, async data => {
          if (data) {
            this.detailData = ConvertService.orderBy(data.registerCoItems, 'created_at');
            this.dataUOM = this.detailData[0]
            this.cm.fetchCommentByCompany(this.collection, data.companyKey, data.key);
            this.cm.fetchFileByCompany(this.collection, data.companyKey, data.key, data => { });
          }
        });
      }
    })

    if (this.printTemplate) {
      this.detail.fetchCollectionPrintTemplate(this.auth.selectedCompany.key, Number(this.printTemplate), data => {
        this.printTemplateData = ConvertService.orderByDesc(data, 'updated_at')
      })
    }
  }
  editItem = null;
  editComment(item) {
    this.editItem = item
    this.form.patchValue({
      comment: item.comment
    })
  }

  postComment(f, data) {
    if (this.form.valid) {
      const isEdit = this.editItem ? true : false;
      const key = this.editItem ? this.editItem.key : this.afs.createId()
      const item = {
        key: key,
        refKey: data.key,
        comment: f.comment,
        created_at: this.editItem ? this.editItem.created_at : new Date() ,
        created_by: this.editItem ? this.editItem.created_by : this.auth.user,
        updated_at: new Date() ,
        updated_by: this.auth.user,
        status: statusObj.active,
        isEdit: isEdit,
      }
      this.cm.addCommentByCompany(this.collection, this.auth.selectedCompany.key, item, (success, res) => {
        // this.snackBar.open("Comment has been submit successful.", "Done", { duration: 100 });
        this.editItem = null;
        this.form.reset();
      })
    }

  }


  onSelectedFile(event) {
    this.selectedFiles = event;
    this.uploadFile()
  }

  uploadFile() {
    if (this.formUpload.valid) {
      let collection = this.collection
      if (this.collection == 'register_submit_form' && this.data && this.data.submitType.key === 2 ) {
        collection = 'register_submit_crf'
      }
      this.fm.addFileManagerByCompany(this.route.url, this.auth.selectedCompany.name_en, collection, this.auth.selectedCompany.key, this.id, this.auth.user, this.selectedFiles, success => { })
    }
  }
  deleteFile(file) {
    let collection = this.collection
    if (this.collection == 'register_submit_form' && this.data && this.data.submitType.key === 2) {
      collection = 'register_submit_crf'
    }
    this.fm.deleteFileDataByCompany(this.auth.selectedCompany.name_en, collection, this.auth.selectedCompany.key, file, (success, res) => {
      if (success)
        this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
    })
  }
  print() {
    this.ps.print('#print', 'a4');
  }

  printMasterList() {
    const item = this.data;
    const collection = this.collection;

    if (collection === "register_return_application") {
      const dialogRef = this.dialog.open(ApplicationDetailPrintComponent, {
        data: { item,registerCoItems:item?.registerCoItems, collection ,printTemplateData: this.printTemplateData },
        panelClass: 'print-master-panel',
        width: '',
        height: '',
        role: 'dialog',
      });
      dialogRef.afterClosed().subscribe(result => {
      });

    } else {

      const dialogRef = this.dialog.open(SubmitApplicationPrintComponent, {
        data: { item, collection,printTemplateData: this.printTemplateData },
        panelClass: 'print-master-panel',
        width: '',
        height: '',
        role: 'dialog',
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

  }

  delete(item: any) {
    this.onDelete.emit(item);
  }
  cancel(item: any) {
    this.cancelSubmit.emit(item);
  }

  getStatus(data) {
    if (!data) return true;
    let show = true;
    const { isDelete, register_status } = data;
    if (isDelete == true)
      show = false
    else if (register_status?.key == 'canceled')
      show = false

    return show;
  }

  async isSubmit(data) {
    if (!data) return true;
    let show = true;
    const { deliveryCoKey, returnAppKey, registerCutStockKey } = data;
    if (deliveryCoKey || returnAppKey || registerCutStockKey)
      show = false
    return show;
  }

}
