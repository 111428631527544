<ng-container *ngIf="feild == 'name_kh'">
    <span class="khf">
        {{data?.name_kh}}
    </span>
</ng-container>

<ng-container *ngIf="feild == 'name_en'">
    <span class="enf">
        {{data?.name_en}}
    </span>
</ng-container>

<ng-container *ngIf="feild == 'address_kh'">
   <span class="khf">
    {{data?.address_kh}}
   </span>
</ng-container>
<ng-container *ngIf="feild == 'address'">
   <span class="enf">
    {{data?.address}}
   </span>
</ng-container>

