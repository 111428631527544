import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';
import { toNumber } from '../services/mapping.service';

@Pipe({
  name: 'calStockTotal'
})
export class CalStockTotalPipe implements PipeTransform {

  transform(items: Array<any>): any {
    const total1 = sum(items, "qty");
    const total2 = sum(items, "allocated_qty");
    const value = toNumber(total1) - toNumber(total2);
    return value;
  }

}
