import { Auth } from './../../stores/auth.store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { validateAllFormFields, MappingService } from 'src/app/services/mapping.service';
import { startWith, map, debounceTime, tap, switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { generateKeywords, pushToArray, pushToObject } from 'src/app/services/convert.service';

@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styleUrls: ['./choose-company.component.scss']
})

export class ChooseCompanyComponent implements OnInit {
  form: FormGroup;
  company: AbstractControl;
  error: any = "";
  searchTerms
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public auth: Auth,
    public ds: DataService,
  ) { 
    this.auth.fetchCanActive();
  }

  async ngOnInit() {

    this.form = this.fb.group({
      company: [null, Validators.compose([Validators.required, MappingService.validSelected.bind(this)])],
    })
    this.company = this.form.controls['company'];
  }
  ngOnDestroy(): void {}


  displayItem(item: any): string {
    return item ? item.name_en : item;
  }

  goBack() {
    this.auth.signOut((success, res) => {
      this.router.navigate(['/login'])
    })
  }

  compareWithObj(a: any, b: any): boolean {
    if (b) return a.key === b.key;
  }


  async resoleItem(f) {
    const { company } = f
    const ref = this.ds.dataRef().collection('company').doc(company.key).collection('register_duplicate_item')
    const data = pushToArray(await ref.get().toPromise())
    if (data) {
      data.forEach(async item => {
        // const tag = [
        //   item.key,
        //   this.auth.user.key,
        //   ConvertService.toCapitalize(`${item.invoice_number}`.trim()),
        //   item.countryKey ? item.countryKey : null,
        //   ConvertService.toDateKey(new Date()),
        //   `STATUS_${statusObj.active.text}`,
        //   `~N/A~`,
        // ]
        const { co_formRef, countryRef, routeRef,invoice_number,co_number } = item;
        let coFormData;
        let countryData;
        let routeData;
        if (co_formRef) {
          coFormData = pushToObject(await co_formRef.get()) ?? null;
        }

        if (countryRef) {
          countryData = pushToObject(await countryRef.get()) ?? null;
        }
        if (routeRef) {
          routeData = pushToObject(await routeRef.get()) ?? null;
        }
        ref.doc(item.key).update({
          keyword: generateKeywords([
            invoice_number,
            coFormData?.code,
            coFormData?.name,
            co_number,
            countryData?.code,
            countryData?.name,
            routeData?.name
          ]).filter(a => a),

        }).then(() => console.log('success'));

      });
    }

  }


  onSelectedCompany() {
    if (this.form.valid) {
      this.form.disable();
      const { company } = this.form.value;
      this.auth.saveSelectedCompany(company, (success, res) => {
        this.form.enable();
        if (success) {
          this.router.navigate(['/'])
        }
        else {
          this.error = "Invalid company profile. Please try again!";
        }
      })
    }
    else {
      validateAllFormFields(this.form);
      this.form.enable();
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.name === o2.name;
  }

}
