<mat-sidenav-container style="min-height: calc(100vh - 168px)">
    <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'"
        class="content-side" [fixedInViewport]="true" [fixedTopGap]="118" [style.width]="ismobile?'100%':'50%'"
        [fixedBottomGap]="0">
        <button  mat-icon-button (click)="sidenav.toggle()"
            style="position: absolute; right: 24px; z-index: 999; top: 4px;">
            <mat-icon class="mat-18">clear</mat-icon>
        </button>
        <mat-tab-group style="height: 100%; ">

            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    Comment
                </ng-template>
                <div class="comment-body">
                    <div class="comment-list">
                        <div class="chat">
                            <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                                *ngFor="let msg of cm?.data;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                            {{ msg?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="msg-item">
                                    <div class="c-content"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                                        <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                                            <mat-icon class="mat-18"
                                                style="font-size: 10px; display: flex; align-items: center;">edit
                                            </mat-icon>
                                            <span style="font-size: 10px;">Edited</span>
                                        </div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === msg?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editComment(msg)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="date"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        {{ msg.created_at?.toDate()|date:'medium' }} ~
                                        {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <form class="editor-block" style="position: relative;" [formGroup]="form">
                        <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
                            customToolbarPosition="top">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected value="roboto">Roboto</option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>

                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>

                                <span class="ql-formats">

                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-align" [title]="'Aligment2'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-color" [title]="'Color'">
                                    </select>
                                    <select class="ql-background" [title]="'BackgroundColor'">
                                    </select>
                                </span>
                                <div class="custom-tb">
                                    <span class="ql-formats">
                                        <button class="ql-bold" [title]="'Bold'"></button>
                                        <button class="ql-italic" [title]="'Italic'"></button>
                                        <button class="ql-underline" [title]="'Underline'"></button>
                                        <button class="ql-strike" [title]="'Strike'"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                    </span>
                                    <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                                        (click)="postComment(form.value, data)">Post
                                    </button>
                                </div>

                            </div>


                        </quill-editor>
                        <div style="height: 68px;"></div>
                    </form>
                </div>
            </mat-tab>

            <mat-tab style="height: 100%; ">
                <ng-template mat-tab-label>
                    Files
                </ng-template>
                <div class="comment-body file-manager">
                    <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate"
                        [value]="pct">
                    </mat-progress-bar>
                    <app-file-view-lists  *ngIf="data" [collection]="collection" [key]="id" [data]="data" [companyKey]="auth?.selectedCompany?.key"></app-file-view-lists>
                    <div style="padding: 12px; text-align: center;">

                        <form [formGroup]="formUpload">
                            <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                                <a type="button" mat-button class="attach_file upload-button"
                                    (click)="fileInput.click()">
                                    <mat-icon class="mat-18">attach_file</mat-icon>
                                    <span>Add New Attachment</span>
                                    <input #fileInput type="file" multiple
                                        accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file
                                        formControlName="file" (change)="onSelectedFile(file.files)"
                                        style="display:none;" />
                                </a>
                            </ng-container>
                        </form>

                    </div>
                </div>
            </mat-tab>
            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    History
                </ng-template>
                <div class="audit-lists">
                    <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
                </div>
            </mat-tab>

        </mat-tab-group>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="cs-form-container">
            <div class="form-wrapper" style="padding:33px 0;" id="print">
                <div class="hd-row" style="padding:0 33px 16px 33px">
                    <h4 class="hd-title">{{title}}</h4>
                    <div class="flex-1"></div>
                    <div *ngIf="data">
                        <ng-container *ngIf="!isHide">
                            <div style="display: flex;">
                                {{data?.registerCoItems | coStatus | async}}

                                <ng-container *ngIf="getStatus(data)">
                                    <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;"
                                        class="hideOnPrint" matTooltip="Print">
                                        <mat-icon class="mat-18">print</mat-icon>
                                    </button>
                                    <ng-container *ngIf="isUse(data)">

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_delivery_export_inspect') && (collection == 'register_delivery_export_document')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_delivery_return') && (collection == 'delivery_return_application')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_delivery_export_co') && (collection == 'register_delivery_co_application')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>


                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_delivery_co_import_stock') && (collection == 'register_delivery_import_stock')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_register_delivery_local_import_stock') && (collection == 'register_delivery_local_import_stock')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="!isCutStock && (auth?.userRoles | userPermissionRole:'delete_delivery_co_cut_stock') && (collection == 'register_delivery_cut_stock')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>


                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="(auth?.userRoles | userPermissionRole:'delete_local_delivery_cut_stock') && (collection == 'local_delivery_cut_stock' )">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete"
                                            *ngIf="(auth?.userRoles | userPermissionRole:'delete_local_delivery') && (collection == 'local_delivery')">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>

                                    </ng-container>


                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_local_delivery') && (collection == 'local_delivery')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_local_delivery_cut_stock') && (collection == 'local_delivery_cut_stock')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>


                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_export_inspect') && (collection == 'register_delivery_export_document')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_return') && (collection == 'delivery_return_application')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_export_co') && (collection == 'register_delivery_co_application')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>


                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_co_import_stock') && (collection == 'register_delivery_import_stock')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_register_delivery_local_import_stock') && (collection == 'register_delivery_local_import_stock')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button [routerLink]="link " class="hideOnPrint" matTooltip="Edit"
                                        style="margin-right: 8px;"
                                        *ngIf="(auth?.userRoles | userPermissionRole:'update_delivery_co_cut_stock') && (collection == 'register_delivery_cut_stock')">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>


                                </ng-container>
                                <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                                    <mat-icon class="mat-18">insert_comment</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                    <ng-container *ngIf="collection == 'register_reapply_item'">
                        <div class="dl-items">
                            <div class="dl-title"> CO Number</div>
                            <div class="dl-text">: {{data?.co_number}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CO Date</div>
                            <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Shipping Date</div>
                            <div class="dl-text">:
                                {{data?.etd?.toDate() | date}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">ADM Fee (KHR)</div>
                            <div class="dl-text">:
                                <div class="data" *ngIf="data?.admFee">
                                    {{data?.admFee | number:'1.'}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">EMF Fee (KHR)</div>
                            <div class="dl-text">:
                                <div class="data" *ngIf="data?.emfFee">
                                    {{data?.emfFee | number:'1.'}}
                                </div>
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Number</div>
                            <div class="dl-text">:
                                {{data?.invoice_number}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Date</div>
                            <div class="dl-text">:
                                {{data?.invoice_date?.toDate() | date}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CAT</div>
                            <div class="dl-text">:
                                {{data?.cat | number:'1.'}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Amount</div>
                            <div class="dl-text">:
                                {{data?.amount_FOB | currency:' ':'code'}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Destination</div>
                            <div class="dl-text">:
                                {{data?.countryRef | docRef : 'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef : 'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Packing</div>
                            <div class="dl-text">:
                                {{data?.qty_packing | number:'1.'}}
                                <span *ngIf="(data?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                                    {{data?.qty_packing_UOMRef | docRef : 'code' | async}}
                                </span>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Unit</div>
                            <div class="dl-text">
                                :
                                {{data?.qty_unit | number:'1.'}}
                                <span *ngIf="(data?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                                    {{data?.qty_unit_UOMRef | docRef : 'code' | async}}
                                </span>
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">CO Material</div>
                            <div class="dl-text">:</div>
                        </div>

                        <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center">No.</th>
                                        <th class="table-col center">CO Material Code</th>
                                        <th class="table-col center">CO Material Name</th>
                                        <!-- <th class="table-col center">UOM</th> -->
                                        <th class="table-col center">NN.W</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                        <!-- <td class="table-col"></td> -->
                                        <td class="table-col right">
                                            {{item?.qty | number}}
                                            {{item?.uomRef|docRef:'code'|async}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="collection == 'register_re_apply'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Product</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef:'name' | async}}

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> CO Reference</th>
                                        <th class="table-col center" rowspan="2"> Quantity Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount</th>
                                        <th class="table-col center" rowspan="2"> CAT</th>
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                                        <th class="table-col center" rowspan="2"> Bill No.</th>
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef :'co_number' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:'
                                            ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                        <td class="table-col ">

                                            {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_duplicate'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Product</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef:'name' | async}}

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> CO Reference</th>
                                        <th class="table-col center" rowspan="2"> Quantity Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount</th>
                                        <th class="table-col center" rowspan="2"> CAT</th>
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                                        <th class="table-col center" rowspan="2"> Bill No.</th>
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef :'co_number' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:'
                                            ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                        <td class="table-col ">

                                            {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_return_application'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Product</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef:'name' | async}}

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> CO Reference</th>
                                        <th class="table-col center" rowspan="2"> Quantity Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount</th>
                                        <th class="table-col center" rowspan="2"> CAT</th>
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                                        <th class="table-col center" rowspan="2"> Bill No.</th>
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <th class="table-col center">No</th>
                                        <th class="table-col center" style="width: 88px;">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">
                                            <span class="dot-status" [ngClass]="data?.isSubmit?'s6':'s1'"></span>
                                            {{i+1}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.co_number}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.co_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.qty_unit | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.amount_FOB | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.cat | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_number }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_date?.toDate() | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.inspect_number }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.inspect_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.bill_number }}</td>
                                        <td class="table-col ">

                                            {{item?.countryRef | docRef:'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_co_application'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Export CO</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> CO Reference</th>
                                        <!-- <th class="table-col center" rowspan="2">Qty Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount
                                            <div>

                                            </div>
                                        </th> -->
                                        <!-- <th class="table-col center" rowspan="2"> CAT</th> -->
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <!-- <th class="table-col " colspan="2"> Joint Inspection</th>
                                        <th class="table-col " rowspan="2"> Bill No.</th> -->
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <th class="table-col center" rowspan="2"> Route</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center">Date</th>
                                        <!-- <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th> -->
                                        <th class="table-col center">No</th>
                                        <th class="table-col center">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.co_number}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.co_date?.toDate() | date}}
                                        </td>
                                        <!-- <td class="table-col right">
                                            {{item?.qty_unit | number:'1.'}}
                                            {{item?.uomUnitRef | docRef:'code' | async}}
                                        <td class="table-col right">
                                            {{item?.amount_FOB | currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.cat | number:'1.'}}

                                        </td> -->
                                        <td class="table-col ">
                                            {{item?.invoice_number}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_date?.toDate() | date}}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td> -->
                                        <td class="table-col ">

                                            {{item?.countryRef | docRef:'name' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.routeRef | docRef : 'name' | async}}
                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>


                                    <tr class="table-row">
                                        <td class="table-col center" colspan="2" rowspan="2">
                                            <strong class="bold">Total:</strong>
                                        </td>
                                        <td class="table-col "> Qty CO</td>
                                        <td class="table-col "> Qty Invoice</td>
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col ">
                                            {{data?.registerCoItems?.length}}
                                        </td>
                                        <td class="table-col ">
                                            {{data?.registerCoItems?.length}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'local_delivery'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Local Sale Invoice</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2"> Invoice</th>
                                        <th class="table-col center" rowspan="2">Qty Unit</th>
                                        <th class="table-col center" rowspan="2"> Amount </th>
                                        <!-- <th class="table-col center" rowspan="2"> CAT</th> -->
                                        <th class="table-col center" rowspan="2"> Route</th>
                                        <th class="table-col center" rowspan="2"> Destination</th>
                                        <!-- <th class="table-col center" rowspan="2"> Shipping Date</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">No</th>
                                        <th class="table-col center">Date</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.invoice_number}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.qty_unit | number:'1.'}}
                                            {{item?.uomUnitRef | docRef:'code' | async}}
                                        <td class="table-col right">
                                            {{item?.amount_FOB | currency:' ':'code'}}
                                        </td>
                                        <!-- <td class="table-col right">
                                            {{item?.cat | number:'1.'}}
                                        </td> -->
                                        <td class="table-col ">
                                            {{item?.routeRef | docRef : 'name' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.countryRef | docRef:'name' | async }}
                                        </td>
                                     
                                        <!-- <td class="table-col ">
                                            {{item?.shipping_date?.toDate() | date}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="2" rowspan="2">
                                            <strong class="bold">Total:</strong>
                                        </td>
                                        <!-- <td class="table-col "> Qty CO</td> -->
                                        <td class="table-col "> Qty Invoice</td>
                                    </tr>
                                    <tr class="table-row">
                                        <!-- <td class="table-col ">
                                            {{data?.registerCoItems?.length}}
                                        </td> -->
                                        <td class="table-col ">
                                            {{data?.registerCoItems?.length}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_cut_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Cut Stock</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center">No.</th>
                                        <th class="table-col center">Reference No.</th>
                                        <th class="table-col center">Application Date</th>
                                        <!-- <th class="table-col center" colspan="2">CO</th>
                                        <th class="table-col center" colspan="2">Invoice</th>

                                        <th class="table-col center" rowspan="2">Shipping Date</th>
                                        <th class="table-col center" rowspan="2">Description</th>
                                        <th class="table-col center" rowspan="2">Route</th>
                                        <th class="table-col center" rowspan="2">Destination</th>
                                        <th class="table-col center" rowspan="2">CAT</th>
                                        <th class="table-col center" rowspan="2">Amount</th>
                                        <th class="table-col center" rowspan="2">Qty Packing</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                                        <!-- <th class="table-col center" rowspan="2">Qty Unit</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->

                                        <th class="table-col center">Form</th>
                                        <th class="table-col center">Invoice
                                            <div>(Set)</div>
                                        </th>

                                    </tr>
                                    <!-- <tr class="table-row">
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                    </tr> -->
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let data of data?.registerCoItems;let i=index">
                                        <tr class="table-row">
                                            <td class="table-col"><span class="dot-status"
                                                    [ngClass]="data?.isSubmit?'s6':'s1'"></span>{{i+1}}
                                            </td>
                                            <td class="table-col">
                                                {{data?.itemRef|docRef:'refNo'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{data?.itemRef| docRefDate:'application_date' | async | date}}
                                            </td>
                                            <!-- <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                                    <td class="table-col">
                                        {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                                    <td class="table-col">
                                        {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.itemRef| docRefDate:'etd' | async | date}}</td>
                                    <td class="table-col">
                                        {{item?.itemName}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.registerItemRef | docRef : 'cat' | async}}
                                    <td class="table-col right">
                                        {{item?.registerItemRef | docRef : 'amount_FOB' | async}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.registerItemRef | docRef : 'qty_packing' | async}}
                                        {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code' |
                                        async}}
                                    </td> -->
                                            <!-- <td class="table-col">
                                    </td> -->
                                            <!-- <td class="table-col right">
                                        {{item?.registerItemRef | docRef : 'qty_unit' | async | number:'1.'}}
                                        {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code' |
                                        async}}

                                    </td> -->
                                            <!-- <td class="table-col">
                                    </td> -->

                                            <td class="table-col">
                                                {{data?.itemRef | docRef:'description' | async}}
                                            </td>


                                            <td class="table-col center">
                                                {{data.itemCount}}
                                            </td>

                                        </tr>

                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'local_delivery_cut_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Local Sale Stock</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" >No.</th>
                                        <th class="table-col center" >Reference No.</th>
                                        <th class="table-col center" >Application Date</th>
                                        <th class="table-col center">Form</th>
                                        <th class="table-col center">Invoice
                                          <div>(Set)</div>
                                        </th>
                                        <!-- <th class="table-col center" rowspan="2">Shipping Date</th>
                                        <th class="table-col center" rowspan="2">Description</th>
                                        <th class="table-col center" rowspan="2">Route</th>
                                        <th class="table-col center" rowspan="2">Destination</th>
                                        <th class="table-col center" rowspan="2">CAT</th>
                                        <th class="table-col center" rowspan="2">Amount</th>
                                        <th class="table-col center" rowspan="2">Qty Packing</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                                        <!-- <th class="table-col center" rowspan="2">Qty Unit</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                                    </tr>
                                    <!-- <tr class="table-row">
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                    </tr> -->
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let data of data?.registerCoItems;let i=index">
                                        <tr class="table-row" *ngFor="let item of data?.registerItems;let s=index">
                                            <td class="table-col" [rowSpan]="data?.registerItems?.length"
                                                *ngIf="s == 0"><span class="dot-status"
                                                    [ngClass]="data?.isSubmit?'s6':'s1'"></span>{{i+1}}</td>
                                            <td class="table-col" [rowSpan]="data?.registerItems?.length"
                                                *ngIf="s == 0">
                                                {{data?.itemRef|docRef:'refNo'|async}}
                                            </td>
                                            <td class="table-col" [rowSpan]="data?.registerItems?.length"
                                                *ngIf="s == 0">
                                                {{data?.itemRef| docRefDate:'application_date' | async | date}}
                                            </td>
                                           
                                            <td class="table-col">
                                                {{data?.itemRef | docRef:'description' | async}}
                                            </td>
                                            <td class="table-col center">
                                                {{data?.itemCount}}
                                            </td>
                                            <!-- <td class="table-col">
                                                {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef| docRefDate:'shipping_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemName}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.registerItemRef | docRef : 'cat' | async}}
                                            <td class="table-col right">
                                                {{item?.registerItemRef | docRef : 'amount_FOB' | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.registerItemRef | docRef : 'qty_packing' | async}}
                                                {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code' |
                                                async}}
                                            </td> -->
                                            <!-- <td class="table-col">
                                            </td> -->
                                            <!-- <td class="table-col right">
                                                {{item?.registerItemRef | docRef : 'qty_unit' | async | number:'1.'}}
                                                {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code' |
                                                async}}
                                            </td> -->
                                            <!-- <td class="table-col">
                                            </td> -->
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_import_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Import Stock </div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" rowspan="2">No.</th>
                                        <th class="table-col center" colspan="2">Application</th>
                                        <th class="table-col center" rowspan="2">Import Invoice (Set)</th>
                                        <th class="table-col center" rowspan="2">Custom Permit (Set)</th>
                                        <th class="table-col center"  rowspan="2">Custom Declaration
                                            <div>(Set)</div>
                                          </th>
                                        <!-- <th class="table-col center" colspan="2">Custom</th> -->
                                        <!-- <th class="table-col center" colspan="2">Cam Control</th> -->

                                        <!-- <th class="table-col center" rowspan="2">Bill Number</th>
                                        <th class="table-col center" rowspan="2">Export to</th>
                                        <th class="table-col center" rowspan="2">CAT</th>
                                        <th class="table-col center" rowspan="2">Destination</th>
                                        <th class="table-col center" rowspan="2">Qty(Order)</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <!-- <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let d of data?.registerCoItems;let i=index">
                                        <!-- *ngFor="let item of data?.registerItems;let s=index" -->
                                        <tr class="table-row" >
                                            <td class="table-col"><span class="dot-status"
                                                    [ngClass]="d?.isSubmit?'s6':'s1'"></span>{{i+1}}
                                            </td>
                                            <td class="table-col" >
                                                {{d?.itemRef|docRef:'refNo'|async}}</td>
                                            <td class="table-col" >
                                                {{d?.itemRef| docRefDate:'application_date' | async | date}}
                                            </td>

                                            <td class="table-col">
                                                {{d?.itemCount}}
                                            </td>
                                            <td class="table-col">
                                                {{d?.totalPermitNumber}}
                                            </td>
                                            <td class="table-col center">
                                                {{d?.totalCustomNumber}}
                                            </td>
                                            <!-- <td class="table-col">
                                                {{item?.itemRef|docRef:'permit_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'custom_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'camControl_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'bill_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'export_to'|async}}
                                            </td>
                                            <td class="table-col right">
                                                <ng-container *ngIf="item?.itemRef|docRef:'cat'|async">
                                                    {{item?.itemRef|docRef:'cat'|async}}

                                                </ng-container>

                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef|docRef:'qty_order'|async}}
                                                {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'description_en' |
                                                async}}

                                            </td> -->

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_local_import_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div> 
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Local Purchase Stock </div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center" >No.</th>
                                        <th class="table-col center" > Reference No.</th>
                                        <th class="table-col center" > Application Date</th>
                                       
                                        <th class="table-col center" >Local Purchase Inv (Set)
                                        </th>
                                        <th class="table-col center" > Custom Permit (Set)</th>
                                        <th class="table-col center" > Custom Declaration (Set)</th>
                                        <!-- <th class="table-col center" colspan="2">Custom</th>
                                        <th class="table-col center" colspan="2">Cam Control</th>

                                        <th class="table-col center" rowspan="2">Bill Number</th>
                                        <th class="table-col center" rowspan="2">Export to</th>
                                        <th class="table-col center" rowspan="2">CAT</th>
                                        <th class="table-col center" rowspan="2">Destination</th>
                                        <th class="table-col center" rowspan="2">Qty(Order)</th> -->
                                        <!-- <th class="table-col center" rowspan="2">UOM</th> -->
                                    </tr>
                                    <!-- <tr class="table-row">
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                        <th class="table-col center">Number</th>
                                        <th class="table-col center">Date</th>
                                    </tr> -->
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let d of registerCoItems;let i=index">
                                        <!-- *ngFor="let item of data?.registerItems;let s=index" -->
                                        <tr class="table-row">
                                            <td class="table-col"><span class="dot-status"
                                                    [ngClass]="d?.isSubmit?'s6':'s1'"></span>{{i+1}}</td>
                                            <td class="table-col" >
                                                {{d?.itemRef|docRef:'refNo'|async}}</td>
                                            <td class="table-col">
                                                {{d?.itemRef| docRefDate:'application_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                <!-- {{item?.itemRef|docRef:'invoice_number'|async}} -->
                                                {{d?.itemCount}}
                                            </td>
                                            <td class="table-col">
                                                {{d?.totalCustomNumber}}
                                            </td>

                                            <td class="table-col">
                                                {{d?.totalPermitNumber}}
                                            </td>

                                        
                                            <!-- <td class="table-col">
                                                {{item?.itemRef|docRef:'permit_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'custom_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'camControl_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'bill_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'export_to'|async}}
                                            </td>
                                            <td class="table-col right">
                                                <ng-container *ngIf="item?.itemRef|docRef:'cat'|async">
                                                    {{item?.itemRef|docRef:'cat'|async}}

                                                </ng-container>

                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef|docRef:'qty_order'|async}}
                                                {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'description_en' |
                                                async}}

                                            </td> -->

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_export_document'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Export Inspect</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center">No.</th>
                                        <th class="table-col center">Export Inspect Number</th>
                                        <th class="table-col center">Export Inspect Date</th>
                                        <th class="table-col center">Export Invoice Number</th>
                                        <th class="table-col center">Export Invoice Date</th>
                                        <!-- <th class="table-col center">Route</th> -->
                                        <th class="table-col center">Destination</th>
                                        <!-- <th class="table-col center">CO Form</th>
                                        <th class="table-col center">Amount</th>
                                        <th class="table-col center">Qty Packing</th>
                                        <th class="table-col center">Qty Unit</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let data of data?.registerCoItems;let i=index">
                                        <tr class="table-row" *ngFor="let item of data?.inspectItem;let s=index">
                                            <td class="table-col" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                                                <span class="dot-status" [ngClass]="data?.isSubmit?'s6':'s1'"></span>
                                                {{i+1}}
                                            </td>
                                            <td class="table-col" *ngIf="s == 0" [rowSpan]="data?.inspectItem?.length">
                                                {{data?.itemRef|docRef:'inspect_number'|async}}
                                            </td>
                                            <td class="table-col" *ngIf="s == 0" [rowSpan]="data?.inspectItem?.length">
                                                {{data?.itemRef|docRefDate:'inspect_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'invoice_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                            </td>
                                            <!-- <td class="table-col">
                                                {{item?.routeRef | docRef : 'name' | async}}
                                            </td> -->
                                            <td class="table-col">
                                                {{item?.countryRef | docRef : 'name' | async}}
                                            </td>
                                            <!-- <td class="table-col">
                                                {{item?.co_formRef | docRef : 'name' | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                                <span
                                                    *ngIf="item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async">
                                                    {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                                                </span>
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                                <span
                                                    *ngIf="item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async">
                                                    {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                                                </span>
                                            </td> -->
                                        </tr>
                                    </ng-container>
                                    <!-- <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>

                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQtyPacking | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'delivery_return_application'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Application Date</div>
                            <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Return </div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col center">No.</th>
                                        <th class="table-col center">ReFerence No</th>
                                        <th class="table-col center">Application Date</th>
                                        <!-- <th class="table-col center">Invoice Number</th>
                                        <th class="table-col center">Invoice Date</th> -->
                                        <th class="table-col center">CO Form</th>

                                        <th class="table-col center">Invoice (Set)</th>
                                        <th class="table-col center">Route</th>
                                        <!-- <th class="table-col center">Destination</th> -->
                                        <!-- <th class="table-col center">Amount</th>
                                        <th class="table-col center">Qty Packing</th>
                                        <th class="table-col center">Qty Unit</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <ng-container *ngFor="let data of data?.registerCoItems;let i=index">
                                        <tr class="table-row" *ngFor="let item of data?.returnItems;let s=index">
                                            <td class="table-col" [rowSpan]="data?.returnItems?.length" *ngIf="s == 0">
                                                <span class="dot-status" [ngClass]="data?.isSubmit?'s6':'s1'"></span>
                                                {{i+1}}
                                            </td>

                                            <td class="table-col" [rowSpan]="data?.returnItems?.length" *ngIf="s == 0">
                                                {{data?.itemRef|docRef:'refNo'|async}}
                                            </td>
                                            <td class="table-col" [rowSpan]="data?.returnItems?.length" *ngIf="s == 0">
                                                {{data?.itemRef|docRefDate:'application_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef | docDocRef: 'co_formRef' : 'name' | async}}
                                            </td>
                                            <td class="table-col" [rowSpan]="data?.returnItems?.length" *ngIf="s == 0">
                                                {{data?.itemCount}}
                                            </td>


                                            <!-- <td class="table-col">
                                                {{item?.registerItemRef|docRef:'invoice_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}
                                            </td> -->
                                            <td class="table-col">
                                                {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}
                                            </td>
                                            <!-- <td class="table-col">
                                                {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                                            </td> -->

                                            <!-- <td class="table-col right">
                                                {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:'
                                                ':'code'}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.registerItemRef | docRef:'qty_packing' | async | number:'1.'}}
                                                <span
                                                    *ngIf="item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async">
                                                    {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'code' |
                                                    async}}
                                                </span>
                                            </td>
                                            <td class="table-col right">
                                                {{item?.registerItemRef | docRef:'qty_unit' | async | number:'1.'}}
                                                <span
                                                    *ngIf="item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async">
                                                    {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'code' |
                                                    async}}
                                                </span>
                                            </td> -->

                                        </tr>

                                    </ng-container>
                                    <!-- <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>

                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>

                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQtyPacking | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <div class="dl-items">
                        <div class="dl-title"> Last Update</div>
                        <div class="dl-text" *ngIf="data?.updated_at">: {{data?.updated_at?.toDate() | date:'medium'}}
                        </div>
                        <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                    </div>
                    <div class="dl-items" *ngIf="data?.status">
                        <div class="dl-title">Status</div>
                        <div class="dl-text">:
                            <div class="data">
                                {{data?.status?.text}}
                            </div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="store?.process">
                    <div style="padding: 33px;">
                        <app-line-loading></app-line-loading>
                        <app-line-loading></app-line-loading>
                    </div>
                </ng-container>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>