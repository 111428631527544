import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { toDateKey } from '../services/convert.service';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value) return;
    const date = new Date(value); 
    return new DatePipe('en-US').transform(date, 'MMM dd, yyyy hh:MM:ss a');
  }

}



@Pipe({
  name: 'isDifDay'
})
export class isDifDayPipe implements PipeTransform {
  transform(newDate:any,oldDate:any, ): any {
    if(!newDate && !oldDate) {
      return false;
    }
    if(newDate && oldDate){
      const oldDateKey  = toDateKey(oldDate?.toDate()); 
      const newDateKey  = toDateKey(newDate?.toDate());
      if(oldDateKey === newDateKey){
        return false;
      }else{
        return true;
      }
    }

  }

}
