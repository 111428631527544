import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment'
import { Injectable } from '@angular/core';
import { ConvertService, pushToArray } from './convert.service';
@Injectable({ providedIn: 'root' })

export class MappingService {

  static autoComplete(stateCtrl: AbstractControl, data: any, type) {
    return stateCtrl.valueChanges.pipe(startWith(''),
      map(state => state ? this.filterStates(data, stateCtrl.value, type) : data.slice())
    );
  }
  public static validSelected(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      if (!value.key && !value.id) {
        return { validKey: true }
      }
    }
  }



  static filterStates(data: any, value: any, type: any): any[] {
    if (value.key || value.id || !data) return;
    switch (type) {
      case "code":
        return data.filter(state => state.code.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "name_en":
        return data.filter(state => state.name_en.toString().toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "name":
        return data.filter(state => state.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "co_number":
        return data.filter(state => state.co_number.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "description_en":
        return data.filter(state => state.description_en.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "invoice_number":
        return data.filter(state => state.invoice_number.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "full_name":
        return data.filter(state => state.full_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "bill_number":
        return data.filter(state => state.bill_number.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "refNo":
        return data.filter(state => state.refNo.toLowerCase().indexOf(value.toLowerCase()) > -1);
      default:
        return data.filter(state => state && state[type].toLowerCase().indexOf(value.toLowerCase()) > -1);
    }
  }
  static orderBy(rows: Array<any>, field: string) {
    return _.orderBy(rows, [field])
  }

  static orderByDesc(rows: Array<any>, field: string) {
    return _.orderBy(rows, [field], "desc")
  }

  static groupBy(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy])
  }

  static groupByOrderDesc(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy], ['desc'])
  }
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number.isNaN(Number(value))) return 0;
  return Number(value);
}
export function toNull(value) {
  if (value === null || value === "" || value === undefined) {
    return null;
  }
  return value;
}

export function objectToArray(obj: any) {
  const mapped = Object.keys(obj).map(key => {
    return ({ type: key, value: obj[key] })
  });
  return mapped
}
export function chunkArray(myArray, chunk_size) {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

export function objectToArrayData(obj: any) {
  if (!obj) return;
  const mapped = Object.keys(obj).map(key => {
    return ({
      type: key,
      key: key,
      ...obj[key]
    })
  });
  return mapped
}


export function mapUser(obj: any) {
  if (!obj) return null;
  return ({
    key: obj.key,
    displayName: obj.displayName || null,
  })
}

export function pushToObject(obj: any) {
  const mapped = obj.map(data => {
    return data
  });
  return mapped
}
export function groupByData(data: Array<any>, field: string, fields?: string[] | string) {
  const rows = _.chain(data).groupBy(fields ? fields : field).map((value, key) => ({ [field]: value[0][field], data: value })).value()
  return rows
}
export function dateRefNo() {
  return moment().format('YYMM')
}

export function lastWeek() {
  return moment().subtract('week', 1).toDate()
}

export function waitForOneSecond() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve("I promise to return after two second!");
    }, 2200);
  });
}

export class UserRole {
  constructor(
    readonly key: string,
    readonly permissions: string,

  ) { }

  toString(): string {
    return null
  }
}
export const UserRoleConverter = {
  toFirestore(stock: UserRole): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      permissions: stock.permissions,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): UserRole {
    const data = snapshot.data(options)!;
    return new UserRole(
      data.key,
      mappingRole(data.permissions)
    );
  }

};

function mappingRole(item) {
  if (item && item.length > 0) {
    return item.map(m => ({
      app: m.app,
      appkey: m.appkey,
      mKey: m.mKey,
    }))
  }
  return []
}



export class StockAdjust {
  constructor(
    readonly key: string,
    readonly refNo: string,
    readonly application_date: any,
    readonly updated_at: any,
    readonly updated_by: any,
    readonly method: any,
    readonly release_date: any,

  ) { }

  toString(): string {
    return null
  }
}
export const StockAdjustConverter = {
  toFirestore(stock: StockAdjust): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      refNo: stock.refNo,
      application_date: stock.application_date,
      updated_at: stock.updated_at,
      updated_by: stock.updated_by,
      method: stock.method,
      release_date: stock.release_date,

    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StockAdjust {
    const data = snapshot.data(options)!;
    return new StockAdjust(
      data.key,
      data.refNo,
      data.application_date,
      data.updated_at,
      data.updated_by,
      data.method,
      data.release_date

    );
  }

};

export class Stock {
  constructor(
    readonly key: string,
    readonly refNo: string,
    readonly application_date: any,
    readonly updated_at: any,
    readonly updated_by: any,
    readonly release_date: any,

  ) { }

  toString(): string {
    return null
  }
}
export const stockConverter = {
  toFirestore(stock: Stock): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      refNo: stock.refNo,
      application_date: stock.application_date,
      updated_at: stock.updated_at,
      updated_by: stock.updated_by,
      release_date: stock.release_date,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Stock {
    const data = snapshot.data(options)!;
    return new Stock(
      data.key,
      data.refNo,
      data.application_date,
      data.updated_at,
      data.updated_by,
      data.release_date
    );
  }

};

export class StockRoleBack {
  constructor(
    readonly key: string,
    readonly refNo: string,
    readonly transactionNo: string,
    readonly release_date: any,
    readonly transactionKey: any,
    readonly movementType: any,
    
  ) { }

  toString(): string {
    return null
  }
}
export const StockRoleBackConverter = {
  toFirestore(stock: StockRoleBack): firebase.firestore.DocumentData {
    return {
      key: stock?.key,
      refNo: stock?.refNo || null,
      transactionNo:stock?.transactionNo || null,
      release_date:stock?.release_date || null,
      transactionKey:stock?.transactionKey || null,
      movementType:stock?.movementType || null,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StockRoleBack {
    const data = snapshot.data(options)!;
    return new StockRoleBack(
      data.key,
      data.refNo,
      data.transactionNo,
      data.release_date,
      data.transactionKey,
      data.movementType,
    );
  }

};

export class StockOpenBalance {
  constructor(
    readonly key: string,
    readonly stockBalance: number,
    readonly release_date:any,
    readonly updated_at:any,
  ) { }

  toString(): string {
    return null
  }
}
export const StockOpenBalanceConverter = {
  toFirestore(stock: StockOpenBalance): firebase.firestore.DocumentData {
    return {
      key: stock?.key,
      stockBalance:stock?.stockBalance || 0,
      release_date:stock?.release_date,
      updated_at:stock?.updated_at,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StockOpenBalance {
    const data = snapshot.data(options)!;
    return new StockOpenBalance(
      data.key,
      data.stockBalance,
      data.release_date,
      data.updated_at,
    );
  }

};


export class StockMaterial {
  constructor(
    readonly key: string,
    readonly stockQty: string,
  ) { }

  toString(): string {
    return null
  }
}
export const StockMaterialConverter = {
  toFirestore(stock: StockMaterial): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      stockQty: stock.stockQty
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StockMaterial {
    const data = snapshot.data(options)!;
    return new StockMaterial(
      data.key,
      data.stockQty,
    );
  }

};

export class StockDetail {
  constructor(
    readonly key: string,
    readonly refNo: string,
    readonly application_date: any,
    readonly updated_at: any,
    readonly updated_by: any,
    readonly khmerDate: any,
    readonly lunarDate: any,
    readonly title: any,
    readonly description: any,
    readonly registerCo: any,
    readonly materials: any,
    readonly register_status: any,
    readonly companyKey: any,
    readonly deliveryCutStockKey: any,

  ) { }

  toString(): string {
    return null
  }
}
export const stockConverterDetail = {
  toFirestore(stock: StockDetail): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      refNo: stock.refNo,
      application_date: stock.application_date,
      updated_at: stock.updated_at,
      updated_by: stock.updated_by,
      khmerDate: stock.khmerDate,
      lunarDate: stock.lunarDate,
      title: stock.title,
      description: stock.description,
      registerCo: stock.registerCo,
      materials: stock.materials,
      register_status: stock.register_status,
      companyKey: stock.companyKey,
      deliveryCutStockKey: stock.deliveryCutStockKey,

    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StockDetail {
    const data = snapshot.data(options)!;
    return new StockDetail(
      data.key,
      data.refNo,
      data.application_date,
      data.updated_at,
      data.updated_by,
      data.khmerDate,
      data.lunarDate,
      data.title,
      data.description,
      data.registerCo,
      data.materials,
      data.register_status,
      data.companyKey,
      data.deliveryCutStockKey,
    );
  }

};

export class ImportStockDetail {
  constructor(
    readonly key: string,
    readonly refNo: string,
    readonly application_date: any,
    readonly updated_at: any,
    readonly updated_by: any,
    readonly khmerDate: any,
    readonly lunarDate: any,
    readonly title: any,
    readonly description: any,
    readonly registerCo: any,
    readonly materials: any,
    readonly register_status: any,
    readonly companyKey: any,
    readonly items: any,
    readonly deliveryImportStockKey: any,
    readonly co_formRef: any,
    readonly created_by: any,
    readonly method: any,


  ) { }

  toString(): string {
    return null
  }
}

function mappingItem(item) {
  if (item && item.length > 0) {
    return item.map(m => ({
      key: m.key,
      invoice_number: m.invoice_number || null,
      invoice_date: m.invoice_date || null,
      permit_number: m.permit_number || null,
      permit_date: m.permit_date || null,
      custom_number: m.custom_number || null,
      custom_date: m.custom_date || null,
      camControl_number: m.camControl_number || null,
      camControl_date: m.camControl_date || null,
      bill_number: m.bill_number || null,
      cat: m.cat || null,
      qty_order: m.qty_order || null,
      qty_order_UOMRef: m.qty_order_UOMRef || null,
      export_to: m.export_to || null,
      countryKey: m.countryKey || null,
      itemKey: m.itemKey || null,
      countryRef: m.countryRef || null,
      // itemRef: m.itemRef,
      material: m.material || null,

    }))
  }
  return []
}


function mappingMaterial(item) {
  if (item && item.length > 0) {
    return item.map(m => ({
      materialKey: m.materialKey || null,
      materialRef: m.materialRef || null,
      itemRef: m.itemRef || null,
      import_qty: m.import_qty || null,
      import_cost: m.import_cost || null,
      import_totalCost: m.import_totalCost || null,
      import_qty_NW: m.import_qty_NW || null,
      import_qty_GW: m.import_qty_GW || null,
      uomRef: m.uomRef || null,
      import_qty_NW_UOMRef: m.import_qty_NW_UOMRef || null,
      import_currencyRef: m.import_currencyRef || null,
      import_qty_GW_UOMRef: m.import_qty_GW_UOMRef || null,
      qty: m.qty || null,
      key: m.key || null,
      itemKey: m.itemKey || null,
      transactionKey: m.transactionKey || null,
    }))
  }
  return []
}




export function mappingMasterList(item) {
  if (item) {
    return {
      key:item?.key,
      masterId:item?.masterId,
      approvedDate:item?.approvedDate,
    }
  }
  return null
}


export const importStockConverterDetail = {
  toFirestore(stock: ImportStockDetail): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      refNo: stock.refNo,
      application_date: stock.application_date,
      updated_at: stock.updated_at,
      updated_by: stock.updated_by,
      khmerDate: stock.khmerDate,
      lunarDate: stock.lunarDate,
      title: stock.title,
      co_formRef: stock.co_formRef,
      description: stock.description,
      registerCo: stock.registerCo,
      materials: stock.materials,
      register_status: stock.register_status,
      companyKey: stock.companyKey,
      items: stock.items,
      deliveryImportStockKey: stock.deliveryImportStockKey,
      created_by: stock.created_by,
      method: stock.method,


    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ImportStockDetail {
    const data = snapshot.data(options)!;
    return new ImportStockDetail(
      data.key,
      data.refNo,
      data.application_date,
      data.updated_at,
      data.updated_by,
      data.khmerDate,
      data.lunarDate,
      data.title,
      data.description || null,
      data.registerCo || null,
      mappingMaterial(data.materials),
      data.register_status,
      data.companyKey,
      mappingItem(data.items),
      data.deliveryImportStockKey,
      data.co_formRef,
      data.created_by,
      data.method,
    );
  }

};


export function toFlatten(rows: Array<any>) {
  return _.flatten(rows)
}