import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DuplicateAppDetailPrintComponent } from '../duplicate-app-detail-print/duplicate-app-detail-print.component';
import { PrintService } from 'src/app/services/print.service';
import { SubmitDetail } from 'src/app/stores/submit-detail.store';
import { DataService } from 'src/app/services/data.service';
import { pushToObject, pushToArray, orderBy } from 'src/app/services/convert.service';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentStore } from 'src/app/stores/comment.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Auth } from 'src/app/stores/auth.store';
import { statusObj } from 'src/app/dummy/status';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { chunkArray } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-duplicate-app-detail',
  templateUrl: './duplicate-app-detail.component.html',
  styleUrls: ['./duplicate-app-detail.component.scss']
})
export class DuplicateAppDetailComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Output() onDelete = new EventEmitter();
  @Output() cancelSubmit = new EventEmitter();
  @ViewChild('content') content: ElementRef;

  @ViewChild('editor') editor;
  modules = {};
  form: FormGroup;
  comment: AbstractControl;

  selectedFiles: any;
  formUpload: FormGroup;
  file: AbstractControl;
  id;
  ismobile = false;
  dataUOM;
  auditData;

  constructor(
    public ps: PrintService,
    private dialog: MatDialog,
    public detail: SubmitDetail,
    private ds: DataService,
    private routes: ActivatedRoute,
    private fb: FormBuilder,
    public cm: CommentStore,
    private afs: AngularFirestore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    public fm: FileManagerStore,
    public store: SettingStore,

  ) {
    this.modules = {
      // imageResize: {},
      syntax: false,
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['formula'],
        ['image', 'code-block']
      ]
    }
  }

  isCutStock: any = null;
  async ngOnInit() {


    if (this.data && this.collection == "register_return_application") {
      const returnDoc = await this.ds.dbRef().collection("register_return_application").doc(this.data.key).get().toPromise();
      const returnData = pushToObject(returnDoc)
      const { registerCoItems } = returnData;
      if (returnData && registerCoItems && registerCoItems.length > 0) {
        const registerCo = registerCoItems.map(m => (m.registerCoKey));
        if (registerCo) {
          const chunkData = chunkArray(registerCo, 10); 
          let COData = [];
          for await (const f of chunkData) {
            const CODoc = pushToArray(await this.ds.dbRef().collection('company').doc(this.auth.selectedCompany.key).collection('register_co', ref => ref.where("key", "in", f)).get().toPromise());
            COData.push(...CODoc);
          }
       
          this.isCutStock = COData.filter(f => f.registerCutStockRef)[0];
        }
      }
    }

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
    this.form = this.fb.group({
      comment: [null, Validators.required]
    })
    this.formUpload = this.fb.group({
      file: [null, Validators.required]
    });

    this.comment = this.form.controls['comment']
    this.file = this.form.controls['file']
    this.routes.params.subscribe(param => {
      if (param && param.id) {
        this.id = param.id

        this.detail.detailCollectionByCompany(this.collection, param.id, this.auth.selectedCompany.key, data => {
          if (data) {

            this.store.auditTrail(this.auth.selectedCompany.key, param.id, data => {
              this.auditData = orderBy(data, 'audit_date','desc');
            })
            this.dataUOM = data.registerCoItems?data.registerCoItems[0]:null
            this.cm.fetchCommentByCompany(this.collection, data.companyKey, data.key);
            this.cm.fetchFileByCompany(this.collection, data.companyKey, data.key, data => { });
          }
        });
      }
    })
  }
  editItem = null;
  editComment(item) {
    this.editItem = item
    this.form.patchValue({
      comment: item.comment
    })
  }

  postComment(f, data) {
    if (this.form.valid) {
      const isEdit = this.editItem ? true : false;
      const key = this.editItem ? this.editItem.key : this.afs.createId()
      const item = {
        key: key,
        refKey: data.key,
        comment: f.comment,
        created_at: this.editItem ? this.editItem.created_at : new Date() ,
        created_by: this.editItem ? this.editItem.created_by : this.auth.user,
        updated_at: new Date() ,
        updated_by: this.auth.user,
        status: statusObj.active,
        isEdit: isEdit,
      }
      this.cm.addCommentByCompany(this.collection, this.auth.selectedCompany.key, item, (success, res) => {
        // this.snackBar.open("Comment has been submit successful.", "Done", { duration: 100 });
        this.editItem = null;
        this.form.reset();
      })
    }

  }

  onSelectedFile(event) {
    this.selectedFiles = event;
    this.uploadFile()
  }

  uploadFile() {
    if (this.formUpload.valid) {
      this.fm.addFileManagerByCompany(this.router.url, this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, this.id, this.auth.user, this.selectedFiles, success => { })
    }
  }
  deleteFile(file) {
    this.fm.deleteFileDataByCompany(this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, file, (success, res) => {
      if (success)
        this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
    })
  }

  print() {
    this.ps.print('#print', 'a4');
  }
  printMasterList() {
    const item = this.data
    const collection = this.collection
    const dialogRef = this.dialog.open(DuplicateAppDetailPrintComponent, {
      data: { item, collection },
      panelClass: 'print-master-panel',
      width: '',
      height: '',
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  delete(item: any) {
    this.onDelete.emit(item);
  }
  cancel(item: any) {
    this.cancelSubmit.emit(item);
  }

  getStatus(data) {
    if (!data) return true;
    let show = true;
    const { isDelete, register_status, deliveryReturnDocKey } = data;

    if (isDelete == true)
      show = false
    else if (register_status && register_status.key == 'canceled')
      show = false

    return show;
  }

  isUse(data) {
    if (!data) return true;
    let show = true;
    const { isDelete, register_status, deliveryReturnDocKey } = data;
    if (deliveryReturnDocKey)
      show = false
    if (isDelete == true)
      show = false

    return show;
  }

}
