<div class="dashboard-contain" *mobxAutorun style="height: 100%;">
    <div class="task-wrapper chapternav">
        <mat-progress-bar *ngIf="env?.loading" class="top-nav-progress" mode="indeterminate"></mat-progress-bar>
        <div class="search-engine">
            <h2>Dashboard</h2>
            <form [formGroup]="form" class="search-nav" [ngClass]="{ active: formFocus }">
                <div class="enroll-filter">
                    <mat-select (selectionChange)="_selectionChange($event)" formControlName="searchType"
                        [compareWith]="compareObjects">
                        <mat-option *ngFor="let item of filterBy" [value]="item">
                            {{ item.text }}
                        </mat-option>
                    </mat-select>
                </div>
                <input type="text" formControlName="search" [matAutocomplete]="auto" (focus)="_onFocus(true)"
                    (blur)="_onFocus(false)" appFocusSearch placeholder="Search" />
                <mat-autocomplete [displayWith]="displayItem.bind(this)" #auto="matAutocomplete"
                    class="mat-result-items">
                    <div class="mat-auto-header"><strong></strong></div>
                    <mat-option class="options-card" (click)="_optionSelected(state)" *ngFor="let state of filterData"
                        [value]="state">
                        <svg viewBox="0 0 24 24" role="presentation" aria-hidden="true" focusable="false"
                            style="height: 32px; width: 32px; display: block; fill: rgb(255, 180, 0);">
                            <path
                                d="m17.61 13.4-2.49-.68c-2.76-.75-.51-2.87-2.88-3.51l-2.88-.78a.9.9 0 0 0 -1.1.62l-1.27 4.54 3.81 1.04c2.37.64.13 2.76 2.88 3.51l2.72.74 3.26.89z">
                            </path>
                            <path
                                d="m1.5 22a .52.52 0 0 1 -.13-.02.5.5 0 0 1 -.35-.61l5.21-19.02a.5.5 0 0 1 .61-.35l6.92 1.88a2.02 2.02 0 0 1 1.65 2.06c.08.69.13 1.15 1.23 1.45l5.98 1.63a.5.5 0 0 1 .2.86l-4.55 4.04 1.86 5.78a.5.5 0 0 1 -.61.64l-5.98-1.63a2.29 2.29 0 0 1 -1.96-2.3c-.07-.66-.11-.99-.92-1.21l-3.82-1.04a.5.5 0 1 1 .25-.97h.01l3.82 1.04a2.03 2.03 0 0 1 1.65 2.07c.08.69.13 1.15 1.23 1.45l5.1 1.39-1.67-5.22a.5.5 0 0 1 .14-.53l4.11-3.64-5.09-1.39a2.29 2.29 0 0 1 -1.96-2.3c-.07-.66-.11-.99-.92-1.21l-6.44-1.75-5.08 18.54a.5.5 0 0 1 -.48.37z"
                                fill="#484848">
                            </path>
                        </svg>
                        <span class="flex1 mat-space">{{ state[selectedFilter?.field] }}</span>
                        <small>{{ state?.puc_id }}</small>
                    </mat-option>
                    <mat-option [disabled]="true" class="mat-empty-result" *ngIf="!loading && filterData.length === 0">
                        <img
                            src="https://mobilecenter.azureedge.net/generated/img/no-filtered-apps-5e1a9c8b30813a99f20a80479de65977.svg" />
                        <h3>
                            Sorry, we couldn't find any results for "{{ search?.value }}"
                        </h3>
                    </mat-option>
                </mat-autocomplete>
                <button mat-icon-button (click)="_onSearch(form.value)">
                    <mat-icon aria-label="search">search</mat-icon>
                </button>
            </form>
        </div>
        <div class="" *ngIf="selectedItem" style="width: 100%; background-color: #fff;">
            <div class="container noShadow" style="width:100%; max-width: 1200px; margin: 0 auto; position: relative;">
                <button mat-icon-button (click)="clearResult()" class="cl-btn">
                    <mat-icon class="mat-18">clear</mat-icon>
                </button>
                <app-search-result [data]="selectedItem" [collection]="selectedFilter?.collection"
                    [title]="selectedFilter?.text + ' ' + 'Information'" [isHide]="true" [isHideComment]="true">
                </app-search-result>
            </div>
        </div>
        <div class="task-content" *ngIf="!selectedItem">
            <div class="task-item ani-col" [routerLink]="['/register-item']">
                <div class="task-icon task-icon1 orang">
                    <i class="material-icons">person_add</i>
                </div>
                <h2 class="task-title">Export Invoice</h2>
            </div>

            <div class="task-item ani-col" [routerLink]="['/register-invoice/']">
                <div class="task-icon task-icon1 purple">
                    <i class="material-icons">assignment</i>
                </div>
                <h2 class="task-title">Import Invoice</h2>
            </div>
            <div class="task-item ani-col" [routerLink]="['/register-cut-stock']">
                <div class="task-icon task-icon1 sky">
                    <i class="material-icons">spa</i>
                </div>
                <h2 class="task-title">Register Cut Stock</h2>
            </div>
            <div class="task-item ani-col" [routerLink]="['/inventory-balance/list']">
                <div class="task-icon task-icon1 red">
                    <i class="material-icons">recent_actors</i>
                </div>
                <h2 class="task-title">Inventory Balance</h2>
            </div>
            <div class="task-item ani-col" [routerLink]="['/reports/preview/soo']">
                <div class="task-icon task-icon1">
                    <i class="material-icons">home</i>
                </div>
                <h2 class="task-title">Reporting</h2>
            </div>
        </div>
    </div>
</div>