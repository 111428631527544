import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { ConvertService } from '../services/convert.service';
import { DATA_TYPE } from '../dummy/columns';
import { APPS } from '../dummy/config';
import { statusObj } from '../dummy/status';

@Injectable({providedIn:'root'})
export class FilterStore {
    @observable data: Array<any> = [];
    @observable loading: boolean = true;
    constructor(private ds: DataService) { }

    @action
    async fetchData(companyKey, collectionName, itemStatusKey, setting, orderBy, callback) {
        if (setting) {
            return this.ds.dataRef().collection(collectionName, ref => ref.limit(APPS.smallsize))
                .valueChanges().subscribe(doc => {
                    this.data = doc;
                    callback(doc)
                })
        }
        else {
            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition = ref
                    .where('register_status.key', "==", itemStatusKey)
                    .where('companyKey', "==", companyKey)
                    .where("status.key", "==", statusObj.active.key)
                    .where("tag", "array-contains", '~N/A~')
                    .orderBy(orderBy, 'desc')
                return condition.limit(APPS.smallsize)
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

    @action
    async fetchFilterOptions(val: any, collectionName, field, companyKey: string, type: any, itemStatusKey, orderBy, callback) {
        // console.log(val, collectionName, field, companyKey, type, itemStatusKey, orderBy,);
        
        if (companyKey) {
            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition: any = ref
                    .where('register_status.key', "==", itemStatusKey)
                    .where('companyKey', "==", companyKey)
                    .where("status.key", "==", statusObj.active.key)
                    .where("tag", "array-contains", '~N/A~')
                    .limit(APPS.smallsize)
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = condition.where(field, ">=", word).orderBy(field)
                }else{
                    condition = condition.orderBy(orderBy, 'desc')
                }
                return condition;
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }
        else {
            return this.ds.dataRef().collection(collectionName, ref => {
                let condition: any = ref.limit(APPS.smallsize);
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = ref.where(field, ">=", word).orderBy(field).limit(APPS.smallsize);
                }
                else {
                    condition = condition.orderBy(field).limit(APPS.smallsize)
                }
                return condition
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

    @action
    async fetchDataByType(companyKey, collectionName, itemStatusKey, setting, typeKey, callback) {
        if (setting) {
            return this.ds.dataRef().collection(collectionName, ref => ref.limit(APPS.smallsize))
                .valueChanges().subscribe(doc => {
                    this.data = doc;
                    callback(doc)
                })
        }
        else {
            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition = ref
                    .where('register_status.key', "==", itemStatusKey)
                    .where('companyKey', "==", companyKey)
                    .where("status.key", "==", statusObj.active.key)
                    .where("submitType.key", "==", typeKey)
                    .where("tag", "array-contains", '~N/A~')
                    .orderBy('page_key', 'desc')
                return condition.limit(APPS.smallsize)
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

    @action
    async fetchFilterOptionsByType(val: any, collectionName, field, companyKey: string, type: any, itemStatusKey, typeKey, callback) {
        if (companyKey) {
            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition: any = ref
                    .where('register_status.key', "==", itemStatusKey)
                    .where('companyKey', "==", companyKey)
                    .where("status.key", "==", statusObj.active.key)
                    .where("submitType.key", "==", typeKey)
                    .where("tag", "array-contains", '~N/A~')
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = condition.where(field, ">=", word)
                        .limit(APPS.smallsize);
                }
                else {
                    condition = condition
                        .orderBy('page_key', 'desc')
                        .limit(APPS.smallsize)
                }

                return condition;
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }
        else {
            return this.ds.dataRef().collection(collectionName, ref => {
                let condition: any = ref.limit(APPS.smallsize);
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = ref.where(field, ">=", word).orderBy(field).limit(APPS.smallsize);
                }
                else {
                    condition = condition.orderBy(field).limit(APPS.smallsize)
                }
                return condition
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }





    @action
    async fetchDataSub(companyKey, collectionName, itemStatusKey, setting, callback) {
        if (setting) {
            return this.ds.dataRef().collection(collectionName, ref => ref.limit(APPS.smallsize))
                .valueChanges().subscribe(doc => {
                    this.data = doc;
                    callback(doc)
                })
        }
        else {

            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition = ref
                    .where('register_status.key', "==", itemStatusKey)
                    .where('companyKey', "==", companyKey)
                    .where("tag", "array-contains", '~N/A~')
                    .where("status.key", "==", 1)
                return condition.limit(APPS.smallsize)
            }).valueChanges().subscribe(doc => {
                this.data = doc;

                callback(doc)
            })
        }

    }
    @action
    async fetchFilterSubOptions(val: any, collectionName, field, companyKey: string, type: any, itemStatusKey, callback) {
        if (companyKey) {
            return this.ds.dataRef().collection('company').doc(companyKey).collection(collectionName, ref => {
                let condition: any = ref
                    .where("register_status.key", "==", itemStatusKey)
                    .where("tag", "array-contains", '~N/A~')
                    .where("status.key", "==", 1)
                    .where('companyKey', "==", companyKey)
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = ref.where(field, ">=", word).orderBy(field).limit(APPS.smallsize);
                }
                else {
                    condition = condition.orderBy(field).limit(APPS.smallsize)
                }

                return condition;
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }
        else {
            return this.ds.dataRef().collection(collectionName, ref => {
                let condition: any = ref.limit(APPS.smallsize);
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = ref.where(field, ">=", word).orderBy(field).limit(APPS.smallsize);
                }
                else {
                    condition = condition.orderBy(field).limit(APPS.smallsize)
                }
                return condition
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

    @action
    async fetchInventoryDetailData(materialKey: string, companyKey, collectionName, itemStatusKey, setting, callback) {
        if (setting) {
            return this.ds.dataRef().collection(collectionName, ref => ref.limit(APPS.smallsize))
                .valueChanges().subscribe(doc => {
                    this.data = doc;
                    callback(doc)
                })
        }
        else {
            const fromDateKey = 0;
            const toDateKey = 1000000000;
            return this.ds.dataRef().collection('company').doc(companyKey).collection('register_material').doc(materialKey).collection(collectionName, ref => {
                let condition = ref
                    .where("movementType.factor", "==", 1)
                    .where("tag", "array-contains", '~N/A~')
                    .orderBy('appDateKey', 'desc')
                // .where('page_key', ">=", fromDateKey)
                // .where('page_key', "<=", toDateKey)
                return condition.limit(APPS.smallsize)
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

    @action
    async fetchFilterInventoryDetailOptions(materialKey: string, val: any, collectionName, field, companyKey: string, type: any, itemStatusKey, callback) {
        if (companyKey) {
            return this.ds.dataRef().collection('company').doc(companyKey).collection('register_material').doc(materialKey).collection(collectionName, ref => {
                let condition: any = ref
                    // .where('register_status.key', "==", itemStatusKey)
                    // .where('companyKey', "==", companyKey)
                    // .where("status.key", "==", statusObj.active.key)
                    .where("movementType.factor", "==", 1)
                    .where("tag", "array-contains", '~N/A~')
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = condition.where(field, ">=", word)
                        .limit(APPS.smallsize);
                }
                else {
                    condition = condition
                        .orderBy('appDateKey', 'desc')
                        .limit(APPS.smallsize)
                }
                return condition;
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }
        else {
            return this.ds.dataRef().collection(collectionName, ref => {
                let condition: any = ref.limit(APPS.smallsize);
                if (val && !val.key) {
                    const word: any = type === DATA_TYPE.number ? val : ConvertService.toCapitalize(`${val}`)
                    condition = ref.where(field, ">=", word).orderBy(field).limit(APPS.smallsize);
                }
                else {
                    condition = condition.orderBy(field).limit(APPS.smallsize)
                }
                return condition
            }).valueChanges().subscribe(doc => {
                this.data = doc;
                callback(doc)
            })
        }

    }

}
