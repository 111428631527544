import { Pipe, PipeTransform } from '@angular/core';
import { sum } from '../services/convert.service';
import { totalStore } from '../stores/getTotal.store';

@Pipe({
    name: 'sumRgInv'
})
export class sumRgInvPipe implements PipeTransform {
    items: any;
    itemsArr = []
    constructor(
        public total: totalStore
    ) {
    }
    async transform(array: any): Promise<any> {
        if (!array) return
        const total = array.length;
        for (const item of array) {
            const { itemRef, registerItemRef } = item;
            const d = itemRef ? await itemRef.get() : await registerItemRef.get();
            if (d?.data()) {
                this.total.totalInvoiceArray.push(d.data())
            }
        }
        this.total.settotalInvoice(this.total.totalInvoiceArray.length)
        return (total)
    }

}


@Pipe({
    name: 'sumTotal'
})
export class sumTotalPipe implements PipeTransform {
    transform(array: any, field): Promise<any> {
        const total = sum(array, field);
        return (total)
    }

}
