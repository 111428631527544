export const REGISTER_ITEM_STATUS = [
  { key: "draft", text: 'Draft', order: 1 },
  { key: "submitted", text: 'Submitted', order: 2 },
  { key: "deleted", text: 'Deleted', order: 3 },
]
export const driveObj = {
  folder: { key: 'folder', name: 'Folder' },
  file: { key: 'file', text: 'File' },
}
export const AuditType = {
  create: { key: 1, text: 'create' },
  delete: { key: 2, text: 'delete' },
  update: { key: 3, text: 'update' },
  view: { key: 4, text: 'view' },
  print: { key: 5, text: 'print' },
  exportExcel: { key: 6, text: 'export excel' },
}

export const REGISTER_ITEM_STATUS_OBJ = {
  draft: { key: "draft", text: 'Draft', order: 1 },
  submitted: { key: "submitted", text: 'Submitted', order: 2 },
  released: { key: "released", text: 'Released', order: 0 },
  deleted: { key: "deleted", text: 'Deleted', order: 3 },
  cancelDraft: { key: "cancel_draft", text: 'Cancel Draft', order: 4 },
  cancelSubmit: { key: "canceled", text: 'Cancel Submit', order: 4 },
  void: { key: "void", text: 'Void', order: 5 },
}


export const transactionStatusObj = {
  hold: { key: 1, text: 'Hold' },
  release: { key: 2, text: 'Released' },
  reject: { key: 3, text: 'Rejected' },
}

export const roleBackType = {
  cutStock: { key: 1, text: 'Cut Stock' },
  importStock: { key: 2, text: 'Import Stock' },
}

export const transactionStatus = [
  { key: 1, text: 'Hold', route: 'hold' },
  { key: 2, text: 'Released', route: 'released' },
  { key: 3, text: 'Rejected', route: 'rejected' },
]



export const SOOTypes = [
  { key: 1, text: 'Multi CO' },
  { key: 2, text: 'Multi SOO' },
]


export const importDateFilter = [
  { key: 1, text: 'Invoice Date', field: 'invoice_date' },
  { key: 2, text: 'Custom Date', field: 'custom_date' },
]

export const adjFilter = [
  { key: 1, text: 'Adjustment CO Balance', field: 'Adjustment CO Balance', cl: 'Adjustment_CO_Report' },
  { key: 2, text: 'Adjustment Customs Report', field: 'Adjustment Customs Report ', cl: 'Adjustment_Customs_Report' },
]


export const appPrintTypes = [
  { key: 1, text: 'SOO Statement' },
  { key: 2, text: 'Submit Form' },
  { key: 3, text: 'Submit CRF' },
  { key: 4, text: 'Cancel CO Form' },
  { key: 5, text: 'Return' },
  { key: 6, text: 'Re-apply Form' },
  { key: 7, text: 'Duplicate Form' },
  { key: 8, text: 'Register Import Stock' },
  { key: 9, text: 'Register Cut Stock' },
  { key: 10, text: 'Local Sale Stock' },
  { key: 11, text: 'Local Purchase Stock' },
]

export const SOOTypesOBJ = {
  multiCO: { key: 1, text: 'Multi CO' },
  multiSOO: { key: 2, text: 'Multi SOO' },

}


export const importStockOption = [
  { key: 1, text: 'Detail Items' },
  { key: 2, text: 'Detail Materials' },
]

export const cutStockOption = [
  { key: 1, text: 'Inspection' },
  { key: 2, text: 'Cut Stock' },
]
export const dashBoardFilterBy = [
  { key: 1, text: 'CO Export Invoice', collection: 'register_item', field: 'invoice_number', filterBy: 'keyword', orderBy: 'invoice_date', setting: false },
  { key: 2, text: 'Export CO', collection: 'register_co', field: 'co_number', filterBy: 'keyword', setting: false },
  { key: 3, text: 'CO Import Invoice', collection: 'register_invoice', field: 'invoice_number', filterBy: 'keyword', orderBy: 'invoice_date', setting: false },
  { key: 9, text: 'Custom Import Invoice', collection: 'register_import_invoice', field: 'invoice_number', filterBy: 'keyword', orderBy: 'invoice_date', setting: false },
  // { key: 4, text: 'Country', collection: 'settings_country', field: 'name', filterBy: 'updated_at', setting: true },
  // { key: 5, text: 'HS Code', collection: 'hs_codes', field: 'code', filterBy: 'code', setting: true },
  { key: 6, text: 'Local Sale Invoice', collection: 'local_sale_inv', field: 'invoice_number', filterBy: 'keyword', orderBy: 'invoice_date', setting: false },
  { key: 7, text: 'Local Purchase Invoice', collection: 'register_local_invoice', field: 'invoice_number', filterBy: 'keyword', orderBy: 'invoice_date', setting: false },
  { key: 8, text: 'Export Inspect', collection: 'pickup_inspect', field: 'inspect_number', filterBy: 'keyword', orderBy: 'inspect_date', setting: false },
]

export const cancelCoOption = [
  { key: 1, text: 'Cancel Table Over' },
  { key: 2, text: 'Cancel Table Over +' },
  { key: 3, text: 'Cancel Table Under' },
]


export const reportHistoryType = [
  { key: 1, text: 'Export CO', type: 'export-co' },
  { key: 2, text: 'Export Cancel CO', type: 'export-cancel-co' },
  { key: 3, text: 'SOO', type: 'soo' },
  { key: 4, text: 'Export Inspect', type: 'export-inspect' },
  { key: 5, text: 'Import Invoice', type: 'import-invoice' },
  { key: 6, text: 'Export Invoice', type: 'export-invoice' },
  { key: 7, text: 'Outstanding Cut Stock', type: 'outstanding-cut-stock' },
  { key: 8, text: 'Outstanding Local Sale Stock', type: 'outstanding-local-sale-stock' },
  { key: 9, text: 'Outstanding Import Stock', type: 'outstanding-import-stock' },
  { key: 10, text: 'Outstanding Local Purchase Stock', type: 'outstanding-local-purchase-stock' },
  { key: 11, text: 'Cut Stock', type: 'cut-stock' },
  { key: 12, text: 'Import Stock', type: 'import-stock' },
  { key: 13, text: 'Local Purchase INV', type: 'local-purchase-inv' },
  { key: 14, text: 'Local Purchase Stock', type: 'local-purchase-stock' },
  { key: 15, text: 'Local Sale INV', type: 'local-sale-inv' },
  { key: 16, text: 'Local Sale Stock', type: 'local-sale-stock' },
  { key: 17, text: 'Duplicate Invoice', type: 'duplicate-invoice' },
  { key: 18, text: 'Re-apply Invoice', type: 're-apply-invoice' },
  { key: 19, text: 'Inventory CO Balance', type: 'inventory-co-balance' },
  { key: 20, text: 'Adjustment Report', type: 'adjustment-report' },
]



export const reportHistoryTypeObject = {
  exportCo: { key: 1, text: 'Export CO', type: 'export-co' },
  exportCancelCo: { key: 2, text: 'Export Cancel CO', type: 'export-cancel-co' },
  soo: { key: 3, text: 'SOO', type: 'soo' },
  exportInspect: { key: 4, text: 'Export Inspect', type: 'export-inspect' },
  importInvoice: { key: 5, text: 'Import Invoice', type: 'import-invoice' },
  exportInvoice: { key: 6, text: 'Export Invoice', type: 'export-invoice' },
  outstandingCutStock: { key: 7, text: 'Outstanding Cut Stock', type: 'outstanding-cut-stock' },
  outstandingLocalSaleStock: { key: 8, text: 'Outstanding Local Sale Stock', type: 'outstanding-local-sale-stock' },
  outstandingImportStock: { key: 9, text: 'Outstanding Import Stock', type: 'outstanding-import-stock' },
  outstandingLocalPurchaseStock: { key: 10, text: 'Outstanding Local Purchase Stock', type: 'outstanding-local-purchase-stock' },
  cutStock: { key: 11, text: 'Cut Stock', type: 'cut-stock' },
  importStock: { key: 12, text: 'Import Stock', type: 'import-stock' },
  localPurchaseInv: { key: 13, text: 'Local Purchase INV', type: 'local-purchase-inv' },
  localPurchaseStock: { key: 14, text: 'Local Purchase Stock', type: 'local-purchase-stock' },
  localSaleInv: { key: 15, text: 'Local Sale INV', type: 'local-sale-inv' },
  localSaleStock: { key: 16, text: 'Local Sale Stock', type: 'local-sale-stock' },
  duplicateInvoice: { key: 17, text: 'Duplicate Invoice', type: 'duplicate-invoice' },
  reApplyInvoice: { key: 18, text: 'Re-apply Invoice', type: 're-apply-invoice' },
  inventoryCoBalance: { key: 19, text: 'Inventory CO Balance', type: 'inventory-co-balance' },
  adjustmentReport: { key: 20, text: 'Adjustment Report', type: 'adjustment-report' },
}


export const submitFormOption = [
  { key: 1, typeKey: 1, text: 'Submit Form Table One' },
  { key: 2, typeKey: 2, text: 'Submit Form Table Two' },
  { key: 1, typeKey: 3, text: 'Submit Form EU' },
]

export const deliveryInspectOption = [
  { key: 1, text: 'Delivery Export Inspect' },
  { key: 2, text: 'Received Export Inspect' },
]

export const reApplyOption = [
  { key: 1, text: 'Re-Apply GSP N' },
  { key: 2, text: 'Re-Apply GSP AB' },
  { key: 3, text: 'Re-Apply Asean A' },
  { key: 4, text: 'Re-Apply EU' },
]

export const duplicateOption = [
  { key: 1, text: 'Duplicate EU Under' },
  { key: 2, text: 'Duplicate EU Over' },
  { key: 3, text: 'Duplicate GSP A' },
  { key: 4, text: 'Duplicate GSP N' },
  { key: 5, text: 'Duplicate Asean A' },
  { key: 6, text: 'Duplicate Asean N' },
]

export const statusLists = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]


export const supplierType = [
  { key: 1, text: 'Domestic' },
  { key: 2, text: 'Import' },
]
export const customerType = [
  { key: 1, text: 'Domestic' },
  { key: 2, text: 'Export' },
]

export const statusObj = {
  active: { key: 1, text: 'Active' },
  disabled: { key: 2, text: 'Disabled' },
}

export const transactionTypeObj = {
  OB: { key: 1, text: 'Opening Balance' },
  ADJUSTMENT: { key: 2, text: 'Adjustment Stock' },
  IMPORT_STOCK: { key: 3, text: 'Register Import Stock' },
  CUT_STOCK: { key: 5, text: 'Register Cut Stock' },
  VOID_STOCK: { key: 6, text: 'Register Void Stock' },
}

export const submitType = [
  { key: 1, text: 'Submit Form' },
  { key: 2, text: 'Submit CRF' },
]

export const submitTypeObj = {
  submitForm: { key: 1, text: 'Submit Form' },
  submitCRF: { key: 2, text: 'Submit CRF' },
}

export const CO_STATUS = [
  { key: 1, text: 'Draft' },
  { key: 2, text: 'Register' },
]

export const CO_STATUS_OBJ = {
  draft: { key: 1, text: 'Draft' },
  register: { key: 2, text: 'Register' },
}

export const allStatusLists = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]

export const PRODUCT_TYPES_ARRAY = [
  { key: 1, text: 'Inventory' },
  { key: 2, text: 'Accounting' },
  { key: 3, text: 'Fixed Asset' },
]


export const SysItemType = {
  item: { key: 1, text: 'item', router: "item" },
  product: { key: 2, text: 'product', router: "product" },
}

export const PRODUCT_TYPES_ARRAY_OBJ = {
  inventory: { key: 1, text: 'Inventory', router: "inventory" },
  accounting: { key: 2, text: 'Accounting', router: "accounting" },
  fixed_asset: { key: 3, text: 'Fixed Asset', router: "fixed_asset" },
}
//STOCK MOVEMENT TYPE
export const ADJUSTMENT_OPTIONS = [
  { key: 1, text: 'ADJ+', factor: 1 },
  { key: 2, text: 'ADJ-', factor: -1 },
]

export const movementTypeObj = {
  ADJ_INCREASE: { key: 1, text: 'ADJ+', factor: 1 },
  ADJ_DECREASE: { key: 2, text: 'ADJ-', factor: -1 },
  OB: { key: 3, text: 'OB', factor: 1 },
  IMPORT_STOCK: { key: 4, text: 'IMPORT STOCK', factor: 1 },
  CUT_STOCK: { key: 5, text: 'CUT STOCK', factor: -1 },
  VOID_STOCK: { key: 6, text: 'VOID', factor: 1 },
  LOCAL_PURCHASE_STOCK: { key: 7, text: 'LOCAL PURCHASE STOCK', factor: 1 },
  LOCAL_SALE_STOCK: { key: 8, text: 'LOCAL SALE STOCK', factor: -1 },
}


export const appTypeObj = {
  ADJ_INCREASE: { key: 1, text: 'ADJ+', factor: 1 },
  ADJ_DECREASE: { key: 2, text: 'ADJ-', factor: -1 },
  OB: { key: 3, text: 'OB', factor: 1 },
  IMPORT_STOCK: { key: 4, text: 'Import Stock', factor: 1 },
  CUT_STOCK: { key: 5, text: 'Cut Stock', factor: -1 },
  VOID_STOCK: { key: 6, text: 'Void', factor: 1 },
  LOCAL_PURCHASE_STOCK: { key: 7, text: 'Local Purchase Stock', factor: 1 },
  LOCAL_SALE_STOCK: { key: 8, text: 'Local Sale Stock', factor: -1 },
}
