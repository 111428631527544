import { Injectable } from '@angular/core';
import { computed, action } from 'mobx';

@Injectable({ providedIn: 'root' })

export class totalStore {
  public totalComControl: number = 0;
  public totalControl: number = 0;
  public totalPermit: number = 0;
  public totalInvoice: number = 0;
  public totalComControlArray = [];
  public totalControlArray = [];
  public totalPermitArray = [];
  public totalInvoiceArray = [];
  constructor() { }

  @action settotalComControl(val: any) {
    this.totalComControl = val;
  }
  @action settotalControl(val: any) {
    this.totalControl = val;
  }
  @action settotalPermit(val: any) {
    this.totalPermit = val;
  }
  @action settotalInvoice(val: any) {
    this.totalInvoice = val;
  }
  @action
  depose() {
    this.totalComControlArray = [];
    this.totalControlArray = [];
    this.totalPermitArray = [];
    this.totalInvoiceArray = [];
  }

}
