<div class="page-section" *mobxAutorun>
    <div class="sticky-block">
        <div class="action-bar">
            <div class="nav-content">
                <h3 class="head-title">
                    File Manager
                </h3>
                <!-- <div class="flex1"></div> -->

            </div>

        </div>
        <nav mat-tab-nav-bar class="cs-mat-tab-link">
            <a mat-tab-link [routerLink]="['/file-manager/lists/' + app]" routerLinkActive #rla="routerLinkActive"
                [active]="rla.isActive">{{title}}</a>
            <div class="flex-1"></div>
            <div class="pg-wrap">
                <div class="search-filter">
                    <mat-icon class="mat-18">search</mat-icon>
                    <input type="text" #search name="" placeholder="Search" [formControl]="searchTextBox">
                    <a class="clearbtn" *ngIf="searchTextBox.value" matSuffix aria-label="Clear" (click)="clear()">
                        <mat-icon>close</mat-icon>
                    </a>
                </div>
            </div>
        </nav>
    </div>
    <div class="cs-container">
        <ng-container *ngIf="!fm?.loading">
            <div class="scrollable-table" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
                (scrolled)="onScroll()">
                <table class="table-wrapper" #printtable id="printtable" *ngIf="!fm?.empty">
                    <thead class="table-header">
                        <tr class="table-row">
                            <th class="table-col ">No</th>
                            <th class="table-col ">Name</th>
                            <th class="table-col ">Owner</th>
                            <th class="table-col ">Created At</th>
                            <th class="table-col ">Size</th>
                            <th class="table-col "></th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let item of fileLists; let i=index" >
                            <td class="table-col ">
                                {{i+1}}
                            </td>
                            <td class="table-col ">
                                <a class="file-display" target="_blank" [href]="item?.downloadUrl">
                                    <img [src]="item|iconDrive" />
                                    <span>{{item?.originname}}</span>
                                </a>
                            </td>
                         
                            <td class="table-col ">
                                {{item?.created_by?.name}}
                            </td>
                            <td class="table-col ">
                                {{item?.created_at?.toDate() | date:'medium'}}
                            </td>
                            <td class="table-col ">
                                {{item?.fileSize | fileSize}}
                            </td>
                            <td class="table-col " *ngIf="auth?.userRoles | userPermissionRole:appKey" [routerLink]="[item?.route]" routerLinkActive="router-link-active" >
                                <mat-icon class="mat-18"  matTooltip="Click to go to file location"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z" fill="#0E03CD"/></svg></mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <app-empty *ngIf="fm?.empty" [name]="'Welcome to CAMwin'"></app-empty>
        </ng-container>
        <app-placeholder *ngIf="fm?.loading"></app-placeholder>
        <app-spinner *ngIf="fm?.fetching"></app-spinner>
    </div>


</div>