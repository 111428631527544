<form class="dialog-wrapper form-dialog-wrapper">
  <div class="dialog-header">

    <!-- <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
            aria-label="Print">
            <mat-icon>view_module</mat-icon>
        </button> -->
    <!-- <mat-menu #menuColumn="matMenu">
            <app-column-visible field="Submit_Column_Print">
            </app-column-visible>
        </mat-menu> -->
    <ng-container *ngIf="data?.collection == 'register_delivery_export_document'">
      <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="">
        <mat-icon>more_vert</mat-icon>
        {{selectedItem?.text}}
      </button>
      <mat-menu #menuCountry="matMenu">
        <ng-container *ngFor="let item of deliveryInspectList;let i=index">
          <button mat-menu-item [class.active]="selectedItem?.key === item?.key" (click)="_onSelectedItem(item)">
            <mat-icon>dialpad</mat-icon>
            <span>{{item?.text}}</span>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
    <div class="flex1"></div>

    <a mat-dialog-close class="close-dialog" (click)="close()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content report-wrapper" id="printms">
    <div style="flex:1; display: flex; flex-direction: column;">
      <ng-container *ngIf="data?.collection == 'register_return_application'">

        <table style="width: 100%;">


          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">
                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>
                        </div>
                        <!-- <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span
                                                            class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                                                    </p>
                                                </div> -->
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center; padding: 20px 0;">
                      <div style="text-align: center; margin-bottom: 6px;" class="bold khf">
                        <h3 style="text-decoration: underline;">សូមគោរពជូន</h3>
                        <h3 style="text-decoration: underline;">
                          {{data?.item?.titleRef | docRef:'name_kh' | async}}
                          ក្រសួងពាណិជ្ជកម្ម</h3>
                      </div>
                    </div>

                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">
                        <table>
                          <tr>
                            <td style="white-space: nowrap;">
                              <strong class="bold khf" style="white-space: nowrap;">កម្មវត្ថុ: </strong>
                            </td>
                            <td>
                              <div class="khf" *ngIf="auth?.selectedCompany?.address">
                                សំណើសុំសងឯកសារនាំចេញលើ <span class="enf">C/O (Form
                                  {{data?.item?.co_formRef | docRef:'name' | async}})</span>
                                ចំនួន <span class="enf">{{data?.item?.registerCoItems?.length}}
                                </span>ច្បាប់។
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style="white-space: nowrap;">
                              <strong class="bold khf" style="white-space: nowrap;">យោងៈ
                              </strong>
                            </td>
                            <td>
                              <div class="khf" *ngIf="auth?.selectedCompany?.address">
                                ប្រកាសលេខ ២៩៨ ព.ណ នច.ប្រក ចុះថ្ងៃទី២៩ ខែធ្នូ ឆ្នាំ២០១៥
                                ស្តីពីនិតិវិធីនៃការចេញវិញ្ញាបនបត្រ
                                បញ្ជាក់ប្រភពដើម ទំនិញទំរង់
                                <span class="enf">"{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                                តាមប្រព័ន្ធស្វ័យប្រវត្តិកម្ម។
                                <div>
                                  តបតាមកម្មវត្ថុ និង យោងខាងលើនេះ
                                  ខ្ញុំបាទ/ នាងខ្ញុំសូមជម្រាបជូន
                                  <span class="bold">{{data?.item?.titleRef | docRef:'name_kh' | async}}</span>
                                  មេត្តាជ្រាបថាៈ
                                  ក្រុមហ៊ុនស្នើសុំសងមកក្រសួងពាណិជ្ជកម្មនូវឯកសារនាំចេញនៃ
                                  <span class="enf">C/O BY
                                    {{data?.item?.routeRef | docRef:'name' | async}}
                                    Form
                                    {{data?.item?.co_formRef | docRef:'name' | async}}</span>
                                  ចំនួន <span class="enf">{{data?.item?.registerCoItems?.length}}</span>
                                  រួមមាន៖
                                </div>

                                <div>
                                  <table>
                                    <tr>
                                      <td>- វិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញ
                                        (ថតចម្លង)</td>
                                      <td style="padding-left: 124px;">ចំនួន <span
                                          class="enf">{{data?.item?.registerCoItems?.length}}</span>
                                        ច្បាប់</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        - វិក័យបត្រ <span class="enf">(Invoice)</span>
                                        និងតារាងវេចខ្ចប់
                                        <span class="enf">(Packing List)</span>
                                      </td>
                                      <td style="padding-left: 124px;">ចំនួន <span
                                          class="enf">{{data?.item?.registerCoItems?.length}}</span>
                                        ច្បាប់</td>
                                    </tr>
                                    <tr>
                                      <td> - កំណត់ហេតុរួមគយ - កាំកុងត្រូល</td>
                                      <td style="padding-left: 124px;">ចំនួន <span
                                          class="enf">{{TotalInspect?.length}}</span>
                                        ច្បាប់</td>
                                    </tr>
                                    <tr>
                                      <td> - អញ្ញាត្តិប័ណ្ណដឹកជញ្ជូន <span class="enf">(BL)</span>
                                      </td>
                                      <td style="padding-left: 124px;">ចំនួន <span
                                          class="enf">{{TotalBill?.length}}</span>
                                        ច្បាប់</td>
                                    </tr>
                                  </table>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </table>
                        <div class="khf">
                          ដូចមានក្នុងតារាងខាងក្រោម៖
                        </div>

                      </div>


                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 16px;" rowspan="2">No.</th>
                        <th class="table-col center" colspan="2"> C/O</th>
                        <th class="table-col center" style="width: 36px;" rowspan="2">
                          <div style="text-align: center;">
                            Qty Unit
                          </div>
                        </th>
                        <th class="table-col center" rowspan="2">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>
                        <th class="table-col center" colspan="2"> Invoice</th>
                        <th class="table-col center" rowspan="2"> Bill No.</th>

                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No</th>
                        <th class="table-col center" style="width: 60px;">Date</th>
                        <th class="table-col center">No</th>
                        <th class="table-col center" style="width: 60px;">Date</th>
                        <th class="table-col center">No</th>
                        <th class="table-col center" style="width: 60px;">Date</th>
                        <!-- <th class="table-col ">ADM Fee </th>
                                                                                <th class="table-col ">EMF Fee</th> -->
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <ng-container *ngFor="let data of result;let g=index">
                        <tr *ngFor="let item of data?.values;let i=index">
                          <td class="table-col " [rowSpan]="data?.values?.length" *ngIf="i == 0">
                            {{g+1}}
                          </td>
                          <td class="table-col ">
                            {{item?.co_number}}
                          </td>
                          <td class="table-col ">
                            {{item?.co_date?.toDate() | date}}
                          </td>
                          <td class="table-col right">
                            {{item?.qty_unit | number:'1.'}}
                            <span>
                              {{item?.uomUnitRef | docRef:'code' | async}}
                            </span>
                          </td>
                          <td class="table-col right">
                            {{item?.amount_FOB | currency:' ':'code'}}
                          </td>
                          <td class="table-col ">
                            {{item?.invoice_number }}
                          </td>
                          <td class="table-col ">
                            {{item?.invoice_date?.toDate() | date}}
                          </td>
                          <td class="table-col ">
                            {{item?.bill_number}}
                          </td>
                          <!-- <td class="table-col ">
                            {{item?.inspect_number}}
                          </td>
                          <td class="table-col ">
                            {{item?.inspect_date?.toDate() | date}}
                          </td> -->
                          <td class="table-col " [rowSpan]="data?.values?.length" *ngIf="i == 0">
                            {{data?.inspect_number}}
                          </td>
                          <td class="table-col " [rowSpan]="data?.values?.length" *ngIf="i == 0">
                            {{data?.inspect_date?.toDate() | date}}
                          </td>
                        </tr>
                      </ng-container>



                    </tbody>
                  </table>
                  <div style="margin-top: 12px;">
                    <div class="khf">
                      សេចក្តីដូចបានជម្រាបជូនខាងលើសូម
                      <span class="bold">{{data?.item?.titleRef | docRef:'name_kh' | async}}</span>
                      មេត្តាពិនិត្យ
                      និងអនុញ្ញាតដល់ក្រុមហ៊ុនខ្ញុំបាទ/ នាងខ្ញុំបានសងឯកសារនាំចេញដូចខាងលើ
                      ដោយក្តីអនុគ្រោះ។
                    </div>
                    <div class="khf">
                      សូម <span class="bold">{{data?.item?.titleRef | docRef:'name_kh' | async}}</span>
                      មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីខ្ញុំបាទ/ នាងខ្ញុំ។
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                      <div class="khf" style="text-align: center;">
                        <div>រាជធានីភ្នំពេញ. ថ្ងៃទី ........... ខែ .................. ឆ្នាំ
                          .........
                        </div>
                        <div class="khf bold">អ្នកទទួល</div>
                      </div>
                      <div class="khf bold">ជ.ប្រធានក្រុមហ៊ុន</div>
                    </div>

                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_delivery_co_application'">
        <table>

          <!-- <thead>
                        <tr>
                            <td>
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">
                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>
                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery Export CO</h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">

                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2"> CO Reference</th>
                        <!-- <th class="table-col center" rowspan="2"> Qty Unit
                        </th>
                        <th class="table-col center" rowspan="2"> Amount
                        </th> -->
                        <th class="table-col center" colspan="2"> Invoice</th>

                        <th class="table-col center" rowspan="2"> Route</th>

                        <th class="table-col center" rowspan="2"> Destination</th>

                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">
                      <tr *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.co_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.co_date?.toDate() | date}}
                        </td>
                        <!-- <td class="table-col right">
                          {{item?.qty_unit | number:'1.'}}
                          {{item?.uomUnitRef | docRef:'code' | async}}
                        </td>
                        <td class="table-col right">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td> -->
                        <td class="table-col ">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.invoice_date?.toDate() | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.routeRef | docRef:'name' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.countryRef | docRef:'name' | async }}
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center" colspan="2" rowspan="2">
                          <strong class="bold">Total:</strong>
                        </td>
                        <td class="table-col "> Qty CO</td>
                        <td class="table-col "> Qty Invoice</td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col ">
                          {{data?.item?.registerCoItems?.length}}
                        </td>
                        <td class="table-col ">
                          {{data?.item?.registerCoItems?.length}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Delivery by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <ng-container *ngIf="data?.collection == 'local_delivery'">
        <table>

          <!-- <thead>
                        <tr>
                            <td>
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">
                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>
                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute;top: 58px; right: 14px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery Local Sale Invoice</h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">

                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <!-- <th class="table-col center" colspan="2"> CO Reference</th> -->
                        <th class="table-col center" colspan="2"> Invoice</th>

                        <th class="table-col center" rowspan="2"> Qty Unit
                        </th>
                        <th class="table-col center" rowspan="2"> Amount
                        </th>

                        <th class="table-col center" rowspan="2"> Route</th>

                        <th class="table-col center" rowspan="2"> Destination</th>

                      </tr>
                      <tr class="table-row">
                        <!-- <th class="table-col center">No</th>
                                                <th class="table-col center">Date</th> -->

                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>

                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">
                      <tr *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.invoice_date?.toDate() | date}}

                        </td>
                        <!-- <td class="table-col ">
                                                    {{item?.co_number}}
                                                </td>
                                                <td class="table-col ">
                                                    {{item?.co_date?.toDate() | date}}
                                                </td> -->
                        <td class="table-col right">
                          {{item?.qty_unit | number:'1.'}}
                          {{item?.uomUnitRef | docRef:'code' | async}}

                        <td class="table-col right">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td>

                        <td class="table-col ">
                          {{item?.routeRef | docRef:'name' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.countryRef | docRef:'name' | async }}
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center" colspan="2" rowspan="2">
                          <strong class="bold">Total:</strong>
                        </td>
                        <!-- <td class="table-col "> Qty CO</td> -->
                        <td class="table-col "> Qty Invoice</td>
                      </tr>
                      <tr class="table-row">
                        <!-- <td class="table-col ">
                                                    {{data?.item?.registerCoItems?.length}}
                                                </td> -->
                        <td class="table-col ">
                          {{data?.item?.registerCoItems?.length}}
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Sender / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Received by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_delivery_export_document'">
        <table>

          <!-- <thead>
                            <tr>
                                <td>
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                        </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">{{selectedItem?.text}}</h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master-print tb-master">
                    <thead class="table-header">
                      <!-- <tr class="table-row">
                                                <td class="table-col" colspan="10">
                                                </td>
                                            </tr> -->
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">
                          <div class="prin-br">
                            No.
                          </div>
                        </th>

                        <th class="table-col center" colspan="2">Invoice</th>
                        <!-- <th class="table-col center" colspan="2">Quantity</th> -->

                        <th class="table-col center" rowspan="2">Destination</th>
                        <!-- <th class="table-col center" rowspan="2">Route</th>
                        <th class="table-col center" rowspan="2">Amount</th> -->
                        <th class="table-col center" rowspan="2">
                          Inspect No
                          <div class="prin-br"></div>
                        </th>
                        <th class="table-col center" rowspan="2">
                          Inspect Date
                          <div class="prin-br"> </div>
                        </th>
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                        <!-- <th class="table-col center">Packing</th>
                        <th class="table-col center">Unit</th> -->
                      </tr>
                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <ng-container *ngFor="let item of data?.inspectItem;let s=index">
                          <tr class="table-row">
                            <td class="table-col center" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                              {{i+1}}
                            </td>

                            <td class="table-col" style="width: 110px;">
                              {{item?.itemRef|docRef:'invoice_number'|async}}
                            </td>
                            <td class="table-col">
                              {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                            </td>
                            <!-- <td class="table-col right">
                              {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                              {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}

                            </td>
                            <td class="table-col right">
                              {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                              {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}

                            </td> -->

                            <td class="table-col">
                              {{item?.countryRef | docRef : 'name' | async}}
                            </td>
                            <!-- <td class="table-col">
                              {{item?.routeRef | docRef : 'name' | async}}
                            </td>
                            <td class="table-col right">
                              {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                            </td> -->

                            <td class="table-col" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                              {{data?.itemRef|docRef:'inspect_number'|async}}
                            </td>
                            <td class="table-col" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                              {{data?.itemRef|docRefDate:'inspect_date' | async | date}}
                            </td>

                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" rowspan="3" colspan="2">Total:</td>
                        <td class="table-col center">Inspection</td>
                        <td class="table-col center">Invoice</td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center">
                          {{data?.item?.registerCoItems?.length}}
                        </td>
                        <td class="table-col center">
                          {{invoice}}
                        </td>
                      </tr>

                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>


      <ng-container *ngIf="data?.collection == 'delivery_return_application'">
        <table>

          <!-- <thead>
                            <tr>
                                <td>
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                        </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery Return</h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 26px;">No.</th>
                        <th class="table-col center">Reference No.</th>
                        <th class="table-col center">Application Date</th>
                        <th class="table-col center">Form</th>
                        <th class="table-col center">Invoice (Set)</th>
                      <th class="table-col center">Route</th>
                      </tr>

                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <tr class="table-row">
                          <td class="table-col center">
                            {{i+1}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'refNo' | async}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef|docRefDate:'application_date' | async | date}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docDocRef: 'co_formRef' : 'name' | async}}
                          </td>
                          <td class="table-col center">
                            {{data?.itemCount | number:'1.'}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docDocRef: 'routeRef' : 'name' | async}}
                          </td>
                        </tr>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" colspan="4" rowspan="2">Total:</td>
                        <td class="table-col center">
                          Invoice (Set)
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center"> {{invoice | number:'1.'}}</td>
                      </tr>

                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_re_apply'">
        <table>

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="title-info" style="margin-top: 18px;">
                        <div>ក្រសួងពាណិជ្ជកម្ម</div>
                        <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                        <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                        <div class="star">************</div>
                      </div>
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: flex-end; margin-top: -24px;">
                      <div class="khf" style="text-align: center;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold khf">
                        <h3>កំណត់បង្ហាញ</h3>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">


                      <div class="khf" style="text-align: center;">
                        <p style="margin-bottom: 6px;">
                          ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ
                          <strong class="enf">ONLINE</strong>
                        </p>
                      </div>

                    </div>
                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">

                        <div class="" *ngIf="auth?.selectedCompany?.name_en">
                          <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                          <span class="enf">
                            <!-- {{auth?.selectedCompany?.name_en}} -->
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                            </app-company-field>

                          </span>
                        </div>
                        <div class="khf" *ngIf="auth?.selectedCompany?.address">
                          <strong class="bold">កម្មវត្ថុ:</strong>
                          ស្នើសុំ
                          <span class="enf">C/O Form
                            "{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                          ចំនួន
                          <span class="enf">
                            {{data?.item?.registerCoItems?.length}}
                          </span>
                          ច្បាប់នាំចេញ
                          {{data?.item?.productTypeRef | docRef:'name_kh' | async}}
                          <span class="enf">
                            BY ({{data?.item?.routeRef | docRef:'name' | async}})
                          </span>
                        </div>



                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header" style="margin-bottom: 0;">
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2"> CO Reference</th>
                        <th class="table-col center" rowspan="2">
                          <div style="text-align: center;">
                            Qty Unit
                          </div>
                        </th>
                        <th class="table-col " rowspan="2">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>
                        <th class="table-col center" rowspan="2">
                          CAT</th>
                        <th class="table-col center" colspan="2"> Invoice</th>
                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                        <th class="table-col center" rowspan="2"> Bill No.</th>
                        <th class="table-col center" rowspan="2"> Destination</th>
                        <th class="table-col center" colspan="2"> Service
                          Fee</th>
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">Date
                        </th>
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">
                          Date</th>
                        <th class="table-col center">ADM Fee
                        </th>
                        <th class="table-col center">EMF Fee
                        </th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.registerCoRef | docRef :'co_number' | async}}
                        </td>
                        <td class="table-col ">
                          {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docRef:'invoice_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupBillRef| docRef:'bill_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}
                        </td>
                        <td class="table-col right">
                          <!-- {{item?.admFee | number:'1.'}} -->
                          {{item?.registerCoRef | docRef :'admFee' | async | number:'1.'}}

                          <span *ngIf="(item?.registerCoRef | docRef :'admFee' | async)">
                            <span class="reil">៛</span></span>
                        </td>
                        <td class="table-col right">
                          <!-- {{item?.emfFee | number:'1.'}} -->
                          {{item?.registerCoRef | docRef :'emfFee' | async | number:'1.'}}
                          <span *ngIf="(item?.registerCoRef | docRef :'emfFee' | async)">
                            <span class="reil">៛</span></span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  <div class="owe">ច្រកចេញចូលតែមួយ</div>


                  <table class="table-wrapper tb-master fx-tb-master">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                        </div>
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex"
                          style="align-items: center; justify-content: center; flex-direction: column;">
                          <p class="bold">គោរពជូនឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</p>
                          <p class="bold">គួរអនុញ្ញាតប្ដូរ CO តាមសំណើរ</p>
                          <p class="bold">សូមឯកឧត្តមមេត្តាពិនិត្យនិងសម្រេច។</p>
                          <p class="bold">ប្រធាននាយកដ្ឋាន</p>
                        </div>

                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">ឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</strong>
                        </div>
                      </td>
                    </tr>

                    <td class="table-col" colspan="2" style="height: 130px;">

                    </td>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">ឯកឧត្តម/លោកជំទាវ
                            រូបលេខាធិការ/អនុរដ្ឋលេខាធិការ</strong>
                        </div>
                      </td>
                    </tr>

                    <td class="table-col" colspan="2" style="height: 130px;">

                    </td>

                  </table>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

        <!-- <div class="page-footer" style="border-top: none;">
                    <div class="d-flex" style="margin-top: 6px">
                        <div class="flex-1"></div>
                        <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                        </div>
                        <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                    </div>
                </div> -->

      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_duplicate'">
        <table>

          <!-- <thead>
                        <tr>
                            <td>
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="title-info" style="margin-top: 18px;">
                        <div>ក្រសួងពាណិជ្ជកម្ម</div>
                        <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                        <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                        <div class="star">************</div>
                      </div>
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: flex-end; margin-top: -24px;">
                      <div class="khf" style="text-align: center;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold khf">
                        <h3>កំណត់បង្ហាញ</h3>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">


                      <div class="khf" style="text-align: center;">
                        <p style="margin-bottom: 6px;">
                          ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ
                          <strong class="enf">ONLINE</strong>
                        </p>
                      </div>

                    </div>
                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">

                        <div class="" *ngIf="auth?.selectedCompany?.name_en">
                          <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                          <span class="enf">
                            <!-- {{auth?.selectedCompany?.name_en}} -->
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                            </app-company-field>

                          </span>
                        </div>
                        <div class="khf" *ngIf="auth?.selectedCompany?.address">
                          <strong class="bold">កម្មវត្ថុ:</strong>
                          ស្នើសុំ
                          <span class="enf">C/O Form
                            "{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                          ចំនួន
                          <span class="enf">
                            {{data?.item?.registerCoItems?.length}}
                          </span>
                          ច្បាប់នាំចេញ
                          {{data?.item?.productTypeRef | docRef:'name_kh' | async}}
                          <span class="enf">
                            BY ({{data?.item?.routeRef | docRef:'name' | async}})
                          </span>
                        </div>



                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header" style="margin-bottom: 0;">
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2"> CO Reference</th>
                        <th class="table-col center" rowspan="2">
                          <div style="text-align: center;">
                            Qty Unit
                          </div>
                        </th>
                        <th class="table-col " rowspan="2">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>
                        <th class="table-col center" rowspan="2">
                          CAT</th>
                        <th class="table-col center" colspan="2"> Invoice</th>
                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                        <th class="table-col center" rowspan="2"> Bill No.</th>
                        <th class="table-col center" rowspan="2"> Destination</th>
                        <th class="table-col center" colspan="2"> Service
                          Fee</th>
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">Date
                        </th>
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">
                          Date</th>
                        <th class="table-col center">ADM Fee
                        </th>
                        <th class="table-col center">EMF Fee
                        </th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.registerCoRef | docRef :'co_number' | async}}
                        </td>
                        <td class="table-col ">
                          {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                        </td>
                        <td class="table-col right">
                          {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docRef:'invoice_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.pickupBillRef| docRef:'bill_number' | async }}
                        </td>
                        <td class="table-col ">
                          {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}
                        </td>
                        <td class="table-col right">
                          <!-- {{item?.admFee | number:'1.'}} -->
                          {{item?.registerCoRef | docRef :'admFee' | async | number:'1.'}}

                          <span *ngIf="(item?.registerCoRef | docRef :'admFee' | async)">
                            <span class="reil">៛</span></span>
                        </td>
                        <td class="table-col right">
                          <!-- {{item?.emfFee | number:'1.'}} -->
                          {{item?.registerCoRef | docRef :'emfFee' | async | number:'1.'}}
                          <span *ngIf="(item?.registerCoRef | docRef :'emfFee' | async)">
                            <span class="reil">៛</span></span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  <div class="owe">ច្រកចេញចូលតែមួយ</div>


                  <table class="table-wrapper tb-master fx-tb-master">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">នាយកដ្ឋាននាំចេញ-នាំចូល</strong>
                        </div>
                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex"
                          style="align-items: center; justify-content: center; flex-direction: column;">
                          <p class="bold">គោរពជូនឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</p>
                          <p class="bold">គួរអនុញ្ញាតប្ដូរ CO តាមសំណើរ</p>
                          <p class="bold">សូមឯកឧត្តមមេត្តាពិនិត្យនិងសម្រេច។</p>
                          <p class="bold">ប្រធាននាយកដ្ឋាន</p>
                        </div>

                      </td>
                    </tr>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">ឯកឧត្តមអគ្គនាយកសេវាពាណិជ្ជកម្ម</strong>
                        </div>
                      </td>
                    </tr>

                    <td class="table-col" colspan="2" style="height: 130px;">

                    </td>
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">ឯកឧត្តម/លោកជំទាវ
                            រូបលេខាធិការ/អនុរដ្ឋលេខាធិការ</strong>
                        </div>
                      </td>
                    </tr>

                    <td class="table-col" colspan="2" style="height: 130px;">

                    </td>

                  </table>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

        <!-- <div class="page-footer" style="border-top: none;">
                    <div class="d-flex" style="margin-top: 6px">
                        <div class="flex-1"></div>
                        <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                        </div>
                        <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                    </div>
                </div> -->

      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_reapply_item'">
        <table>

          <!-- <thead>
                        <tr>
                            <td>
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="title-info" style="margin-top: 18px;">
                        <div>ក្រសួងពាណិជ្ជកម្ម</div>
                        <div>អគ្គនាយកដ្ឋានសេវាពាណិជ្ជកម្ម</div>
                        <div>នាយកដ្ឋាននាំចេញ-នាំចូល</div>
                        <div class="star">************</div>
                      </div>
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: flex-end; margin-top: -24px;">
                      <div class="khf" style="text-align: center;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold khf">
                        <h3>កំណត់បង្ហាញ</h3>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">


                      <div class="khf" style="text-align: center;">
                        <p style="margin-bottom: 6px;">
                          ការចេញវិញ្ញាបនបត្របញ្ជាក់ប្រភពដើមទំនិញតាមប្រព័ន្ធ
                          <strong class="enf">ONLINE</strong>
                        </p>
                      </div>

                    </div>
                    <div class="d-flex" style="align-items: flex-end;">
                      <div class="info">

                        <div class="" *ngIf="auth?.selectedCompany?.name_en">
                          <strong class="khf bold">ក្រុមហ៊ុន:</strong>
                          <span class="enf">
                            <!-- {{auth?.selectedCompany?.name_en}} -->
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                            </app-company-field>

                          </span>
                        </div>
                        <div class="khf" *ngIf="auth?.selectedCompany?.address">
                          <strong class="bold">កម្មវត្ថុ:</strong>
                          ស្នើសុំ
                          <span class="enf">C/O Form
                            "{{data?.item?.co_formRef | docRef:'name' | async}}"</span>
                          ចំនួន
                          <span class="enf">
                            {{data?.item?.registerCoItems?.length}}
                          </span>
                          ច្បាប់នាំចេញ
                          {{data?.item?.productTypeRef | docRef:'name_kh' | async}}
                          <span class="enf">
                            BY ({{data?.item?.routeRef | docRef:'name' | async}})
                          </span>
                        </div>



                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header" style="margin-bottom: 0;">
                      <tr class="table-row">
                        <th class="table-col center" rowspan="2" style="width: 26px;">No.</th>
                        <th class="table-col center" colspan="2"> CO Reference</th>
                        <th class="table-col center" rowspan="2">
                          <div style="text-align: center;">
                            Qty Unit
                          </div>
                        </th>
                        <th class="table-col " rowspan="2">
                          <div style="text-align: center;">
                            Amount
                          </div>
                        </th>
                        <th class="table-col center" rowspan="2">
                          CAT</th>
                        <th class="table-col center" colspan="2"> Invoice</th>
                        <th class="table-col center" colspan="2"> Joint Inspection</th>
                        <th class="table-col center" rowspan="2"> Bill No.</th>
                        <th class="table-col center" rowspan="2"> Destination</th>
                        <th class="table-col center" colspan="2"> Service
                          Fee</th>
                      </tr>
                      <tr class="table-row">
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">Date
                        </th>
                        <th class="table-col center">No</th>
                        <th class="table-col center">Date</th>
                        <th class="table-col center">No
                        </th>
                        <th class="table-col center">
                          Date</th>
                        <th class="table-col center">ADM Fee
                        </th>
                        <th class="table-col center">EMF Fee
                        </th>
                      </tr>
                    </thead>
                    <tbody class="table-body" *ngIf="submitItem">

                      <tr class="table-row" *ngFor="let item of submitItem;let i=index">
                        <td class="table-col center">{{i+1}}</td>
                        <td class="table-col ">
                          {{item?.co_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.co_date?.toDate() | date}}
                        </td>
                        <td class="table-col right">
                          {{item?.qty_unit | number:'1.'}}
                          <!-- {{item?.registerItemRef | docDocRef :'qty_unit_UOMRef':'code' | async}} -->
                        </td>
                        <td class="table-col right">
                          {{item?.amount_FOB | currency:' ':'code'}}
                        </td>
                        <td class="table-col center">
                          {{item?.cat | number: '1.'}}

                        </td>
                        <td class="table-col ">
                          {{item?.invoice_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.invoice_date?.toDate() | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.inspect_number}}
                        </td>
                        <td class="table-col ">
                          {{item?.inspect_date?.toDate() | date}}
                        </td>
                        <td class="table-col ">
                          {{item?.bill_number}}</td>
                        <td class="table-col ">
                          {{item?.countryRef | docRef:'name' | async }}
                        </td>
                        <td class="table-col right">
                          {{item?.admFee | number:'1.'}}
                          <span *ngIf="(item?.admFee)"> <span class="reil">៛</span></span>
                        </td>
                        <td class="table-col right">
                          {{item?.emfFee | number:'1.'}}
                          <span *ngIf="(item?.emfFee)"> <span class="reil">៛</span></span>
                        </td>
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center" colspan="3">
                          <strong class="bold">Total:</strong>
                        </td>
                        <td class="table-col right">
                          {{detail?.totalQuantity| number:'1.'}}
                        </td>
                        <td class="table-col right">
                          {{detail?.totalAmount| currency:' ':'code'}}
                        </td>
                        <td class="table-col ">

                        </td>
                        <td class="table-col "></td>
                        <td class="table-col "></td>
                        <td class="table-col "></td>
                        <td class="table-col "></td>
                        <td class="table-col "></td>
                        <td class="table-col "></td>
                        <td class="table-col right">
                          {{detail?.totalADMFee | number:'1.'}} <span class="reil">៛</span>
                        </td>
                        <td class="table-col right">
                          {{detail?.totalEMFFee | number:'1.'}} <span class="reil">៛</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="owe">ច្រកចេញចូលតែមួយ</div>


                  <table class="table-wrapper tb-master fx-tb-master">
                    <tr class="table-row">
                      <td class="table-col center" colspan="2">
                        <div class="d-flex" style="align-items: center; justify-content: center;">
                          <strong class="bold">យោបល់របស់លោកប្រធាននាយកដ្ឋាន</strong>
                        </div>
                      </td>
                    </tr>
                    <td class="table-col" colspan="2" style="height: 288px;">

                    </td>

                  </table>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>

        <!-- <div class="page-footer" style="border-top: none;">
                    <div class="d-flex" style="margin-top: 6px">
                        <div class="flex-1"></div>
                        <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                        </div>
                        <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                    </div>
                </div> -->

      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_delivery_import_stock'">
        <table>

          <!-- <thead>
                            <tr>
                                <td>
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                        </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery CO Import Stock
                        </h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 26px;">No.</th>
                        <th class="table-col center">Reference No.</th>
                        <th class="table-col center">Application Date</th>
                        <th class="table-col center">Form</th>
                        <th class="table-col center">Import Invoice
                          <div>(Set)</div>
                        </th>
                        <th class="table-col center">Custom Permit
                          <div>(Set)</div>
                        </th>
                        <th class="table-col center">Custom Declaration
                          <div>(Set)</div>
                        </th>
                        <!-- <th class="table-col center">Camcontrol
                          <div>(Set)</div>
                        </th> -->
                        <!-- <th class="table-col center">Country</th> -->
                      </tr>

                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <tr class="table-row">
                          <td class="table-col">
                            {{i+1}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'refNo' | async}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef| docRefDate:'application_date' | async | date}}
                          </td>

                          <td class="table-col">
                            {{data?.itemRef | docDocRef: 'co_formRef' : 'name'  | async}}
                          </td>

                          <td class="table-col">
                            {{data?.itemCount}}
                          </td>
                          <td class="table-col">
                            {{data?.totalPermitNumber}}
                          </td>
                          <td class="table-col center">
                            {{data?.totalCustomNumber}}
                          </td>

                      
                          <!-- <td class="table-col center">
                            <ng-container *ngIf="data?.registerItems">
                              {{data?.registerItems | sumRgComControl:'camControl_number' | async}}
                            </ng-container>
                          </td> -->
                          <!-- <td class="table-col">
                                                        {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                                    </td> -->
                        </tr>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" colspan="4" rowspan="2">Total:</td>
                        <td class="table-col center">Import Invoice
                          <div>(Set)</div>
                        </td>
                        <td class="table-col center">Custom Permit
                          <div>(Set)</div>
                        </td>
                        <td class="table-col center">Custom Declaration
                          <div>(Set)</div>
                        </td>
                        <!-- <td class="table-col center">Camcontrol
                          <div>(Set)</div>
                        </td> -->

                      </tr>
                      <tr class="table-row" *ngIf="submitItem">
                        <td class="table-col center">
                            <span>{{submitItem | sumTotal:'itemCount'}}</span>
                        </td>
                        <td class="table-col center">
                            <span>{{submitItem | sumTotal:'totalPermitNumber'}}</span>
                        </td>
                        <td class="table-col center">
                          <span>{{submitItem | sumTotal:'totalCustomNumber'}}</span>
                        </td>
                        <!-- <td class="table-col center"> <span>{{total?.totalComControl}}</span>
                        </td> -->
                      </tr>

                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_delivery_local_import_stock'">
        <table>

          <!-- <thead>
                            <tr>
                                <td>
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                        </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery  Local Purchase Stock
                        </h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 26px;">No.</th>
                        <th class="table-col center">Reference No.</th>
                        <th class="table-col center">Application Date</th>
                        <th class="table-col center">Form</th>
                        <th class="table-col center">Local Purchase Inv
                          <div>(Set)</div>
                        </th>
                        <th class="table-col center">Custom Permit
                          <div>(Set)</div>
                        </th>
                        <th class="table-col center">Custom Declaration
                          <div>(Set)</div>
                        </th>
                        <!-- <th class="table-col center">Camcontrol
                          <div>(Set)</div>
                        </th> -->
                        <!-- <th class="table-col center">Country</th> -->
                      </tr>

                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <tr class="table-row">
                          <td class="table-col">
                            {{i+1}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'refNo' | async}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef| docRefDate:'application_date' | async | date}}
                          </td>

                          <td class="table-col">
                            {{data?.itemRef | docDocRef: 'co_formRef' : 'name'  | async}}
                          </td>
                          
                          <td class="table-col">
                            {{data?.itemCount}}
                          </td>
                          <td class="table-col">
                            {{data?.totalPermitNumber}}
                          </td>
                          <td class="table-col center">
                            {{data?.totalCustomNumber}}
                          </td>
                          
                          <!-- <td class="table-col center">
                            <ng-container *ngIf="data?.registerItems">
                              {{data?.registerItems | sumRgComControl:'camControl_number' | async}}
                            </ng-container>
                          </td> -->
                          <!-- <td class="table-col">
                                                        {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                                    </td> -->
                        </tr>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" colspan="4" rowspan="2">Total:</td>
                        <td class="table-col center">Import Invoice
                          <div>(Set)</div>
                        </td>
                        <td class="table-col center">Custom Permit
                          <div>(Set)</div>
                        </td>
                        <td class="table-col center">Custom Declaration
                          <div>(Set)</div>
                        </td>
                        <!-- <td class="table-col center">Camcontrol
                          <div>(Set)</div>
                        </td> -->

                      </tr>
                      <tr class="table-row" *ngIf="submitItem">
                        <td class="table-col center">
                          <span>{{submitItem | sumTotal:'itemCount'}}</span>
                      </td>
                      <td class="table-col center">
                          <span>{{submitItem | sumTotal:'totalPermitNumber'}}</span>
                      </td>
                      <td class="table-col center">
                        <span>{{submitItem | sumTotal:'totalCustomNumber'}}</span>
                      </td>
                        <!-- <td class="table-col center"> <span>{{total?.totalComControl}}</span>
                        </td> -->
                      </tr>

                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <ng-container *ngIf="data?.collection == 'register_delivery_cut_stock'">
        <table>

          <!-- <thead>
                            <tr>
                                <td>
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                        </thead> -->

          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery Cut Stock
                        </h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 26px;">No.</th>
                        <th class="table-col center">Reference No.</th>
                        <th class="table-col center">Application Date</th>
                        <th class="table-col center">Form</th>
                        <th class="table-col center">Invoice
                          <div>(Set)</div>
                        </th>

                        <!-- <th class="table-col center">Country</th> -->
                      </tr>

                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <tr class="table-row">
                          <td class="table-col">
                            {{i+1}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'refNo' | async}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef| docRefDate:'application_date' | async | date}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'description' | async}}
                          </td>

                          <td class="table-col center">
                            {{data?.itemCount}}
                          </td>

                          <!-- <td class="table-col">
                                                        {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                                    </td> -->
                        </tr>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" colspan="4" rowspan="2">Total:</td>
                        <td class="table-col center"> Invoice
                          (Set)
                        </td>
                        <!-- <td class="table-col center"> {{totalPermit}}</td>
                                                <td class="table-col center"> {{totalControl}}</td>
                                                <td class="table-col center"> {{totalComControl}}</td> -->
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center">
                          <span>{{submitItem | sumTotal:'itemCount'}}</span>
                        </td>
                      </tr>


                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>
      <ng-container *ngIf="data?.collection == 'local_delivery_cut_stock'">
        <table>
          <tbody>

            <tr>
              <td>
                <div class="page">
                  <div class="report-header">
                    <div class="d-flex">
                      <div class="flex-1"></div>
                      <div class="title-info bavacana">
                        <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                        <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                        <div class="star">************</div>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                      <div class="info" style="text-align: left;">
                        <div class="d-flex">
                          <div>
                            <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_kh'">
                              </app-company-field>
                            </div>
                            <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                              <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'name_en'">
                              </app-company-field>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <span class="khf">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address_kh'">
                            </app-company-field>
                          </span>
                        </div>
                        <div class="enf">

                          Reference No:
                          <span class="enf">{{data?.item?.refNo}}</span>

                        </div>
                        <div class="enf">
                          Date:
                          <span class="enf">{{data?.item?.application_date?.toDate() | date}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex"
                      style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                      <div class="khf" style="text-align: right;">
                        <p>
                          {{data?.item?.lunarDate}}
                        </p>
                        <p style="margin-bottom: 6px;">
                          {{data?.item?.khmerDate}}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex" style="align-items: center; justify-content: center;">
                      <div style="text-align: center;" class="bold enf">
                        <h3 style="text-decoration: underline;">Delivery Local Sale Stock
                        </h3>
                      </div>
                    </div>
                  </div>
                  <table class="table-wrapper tb-master tb-master-print">
                    <thead class="table-header">
                      <tr class="table-row">
                        <th class="table-col center" style="width: 26px;">No.</th>
                        <th class="table-col center">Reference No.</th>
                        <th class="table-col center">Application Date</th>
                        <th class="table-col center">Form</th>
                        <th class="table-col center">Invoice
                          <div>(Set)</div>
                        </th>

                        <!-- <th class="table-col center">Country</th> -->
                      </tr>

                    </thead>
                    <tbody class="table-body">

                      <ng-container *ngFor="let data of submitItem;let i=index">
                        <tr class="table-row">
                          <td class="table-col">
                            {{i+1}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'refNo' | async}}
                          </td>
                           <td class="table-col">
                            {{data?.itemRef| docRefDate:'application_date' | async | date}}
                          </td>
                          <td class="table-col">
                            {{data?.itemRef | docRef:'description' | async}}
                          </td>
                          <td class="table-col center">
                              {{data?.itemCount}}
                          </td>


                          <!-- <td class="table-col">
                                                        {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                                    </td> -->
                        </tr>
                      </ng-container>
                      <tr class="table-row">
                        <td class="table-col center" colspan="4" rowspan="2">Total:</td>
                        <td class="table-col center"> Invoice
                          (Set)
                        </td>
                        <!-- <td class="table-col center"> {{totalPermit}}</td>
                                                <td class="table-col center"> {{totalControl}}</td>
                                                <td class="table-col center"> {{totalComControl}}</td> -->
                      </tr>
                      <tr class="table-row">
                        <td class="table-col center">
                          <span>{{submitItem | sumTotal:'itemCount'}}</span>

                        </td>
                      </tr>


                    </tbody>

                  </table>
                  <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                    <div class="enf" style="text-align: center;">
                      Received.by / Date
                    </div>
                    <div class="enf" style="text-align: center;">
                      Deliver.by / Date
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>

        </table>
      </ng-container>

      <div class="page-footer">
        <div class="d-flex print-date">
          <div class="enf" style="margin-right: 12px; color:#555; font-size: 8px !important; text-align: left;">
            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address'"></app-company-field>

          </div>
          <div class="flex-1"></div>
          <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
          </div>
          <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
          <!-- <div class="enf" style="font-size: 8px !important; margin-left: 12px;" id="pageFooter">Page </div> -->

        </div>
      </div>
    </div>


  </div>
  <div class="dialog-footer" style="padding: 24px;">
    <div class="flex-1"></div>
    <a mat-button class="mat-puc cancel" (click)="close()"><span>Cancel</span></a>
    <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
      style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
      <mat-icon class="mat-18">print</mat-icon>
      Print
    </button>
  </div>
  <app-spinner *ngIf="loading"></app-spinner>
</form>
