export const PERMISSION_MY_FILE = {
    read: { key: 'read_my_files', name: 'View' },
    create: { key: 'create', name: 'Upload/Create' },
    get_link: { key: 'get_link', name: 'Share link' },
    // Invite_user:{ key: 'Invite_user', name: 'Invite people' },
    // remove_Invite_user:{ key: 'remove_Invite_user', name: 'Remove people' },
    // move:{ key: 'move', name: 'Move' },
    // download:{ key: 'download', name: 'Download' },
    update: { key: 'update', name: 'Update' },
    delete: { key: 'delete', name: 'Delete' },
}

export const PERMISSION_USER = {
    read: { key: 'read_user', name: 'View' },
    create_user: { key: 'create_user', name: 'Create' },
    update_user: { key: 'update_user', name: 'Update' },
    delete_user: { key: 'delete_user', name: 'Delete' },
    reset_user_password: { key: 'reset_user_password', name: 'Reset Password' },
    // view_permission:{ key: 'view_permission', name: 'View Permission' },
    // set_permission:{ key: 'set_permission', name: 'Set Permission' },
    // user_info:{ key: 'user_info', name: 'User Info' },
    user_permission: { key: 'user_permission', name: 'User Permission' },
}

export const PERMISSION_GROUP_USER = {
    read: { key: 'read_group_user', name: 'View' },
    add_member: { key: 'add_member', name: 'Add Member' },
    remove_member: { key: 'remove_member', name: 'Remove Member' },
    view_member: { key: 'view_member', name: 'View Member' },
    create_group: { key: 'create_group', name: 'Create' },
    update_group: { key: 'update_group', name: 'Update' },
    delete_group: { key: 'delete_group', name: 'Delete' },
    // view_group_permission:{ key: 'view_group_permission', name: 'View Group Permission' },
    // set_group_permission:{ key: 'set_group_permission', name: 'Set Group Permission' },
    group_info: { key: 'group_info', name: 'Group Info' },
    group_permission: { key: 'group_permission', name: 'Group Permission' },
}

export const PERMISSION_COMPANY = {
    read: { key: 'read_company', name: 'View' },
    create_company: { key: 'create_company', name: 'Create' },
    update_company: { key: 'update_company', name: 'Update' },
    delete_company: { key: 'delete_company', name: 'Delete' },
}
export const PERMISSION_TAG = {
    read: { key: 'read_tag', name: 'View' },
    create_tag: { key: 'create_tag', name: 'Create' },
    update_tag: { key: 'update_tag', name: 'Update' },
    delete_tag: { key: 'delete_tag', name: 'Delete' },
}

export const PERMISSION: any[] = [


    // import app

    // { initial: 'Import Application', isGroupBy: true },
    { app: 'Import Application', mKey: 'register_co_material', appkey: 'read_register_co_material', key: 'read', name: 'View', text: 'Register CO Material' },
    { app: 'Import Application', mKey: 'register_co_material', appkey: 'create_register_co_material', key: 'create', name: 'Create', text: 'Register CO Material' },
    { app: 'Import Application', mKey: 'register_co_material', appkey: 'update_register_co_material', key: 'update', name: 'Update', text: 'Register CO Material' },
    { app: 'Import Application', mKey: 'register_co_material', appkey: 'delete_register_co_material', key: 'delete', name: 'Delete', text: 'Register CO Material' },

    { app: 'Import Application', mKey: 'register_co_import_inv', appkey: 'read_register_co_import_inv', key: 'read', name: 'View', text: 'Register CO Import INV' },
    { app: 'Import Application', mKey: 'register_co_import_inv', appkey: 'create_register_co_import_inv', key: 'create', name: 'Create', text: 'Register CO Import INV' },
    { app: 'Import Application', mKey: 'register_co_import_inv', appkey: 'update_register_co_import_inv', key: 'update', name: 'Update', text: 'Register CO Import INV' },
    { app: 'Import Application', mKey: 'register_co_import_inv', appkey: 'delete_register_co_import_inv', key: 'delete', name: 'Delete', text: 'Register CO Import INV' },

    { app: 'Import Application', mKey: 'register_co_import_inv_information', appkey: 'read_register_co_import_inv_information', key: 'read', name: 'View', text: 'Register CO Import INV Information' },
    { app: 'Import Application', mKey: 'register_co_import_inv_information', appkey: 'create_register_co_import_inv_information', key: 'create', name: 'Create', text: 'Register CO Import INV Information' },
    { app: 'Import Application', mKey: 'register_co_import_inv_information', appkey: 'update_register_co_import_inv_information', key: 'update', name: 'Update', text: 'Register CO Import INV Information' },
    { app: 'Import Application', mKey: 'register_co_import_inv_information', appkey: 'delete_register_co_import_inv_information', key: 'delete', name: 'Delete', text: 'Register CO Import INV Information' },


    { app: 'Import Application', mKey: 'register_import_material', appkey: 'read_register_import_material', key: 'read', name: 'View', text: 'Register Customs Material' },
    { app: 'Import Application', mKey: 'register_import_material', appkey: 'create_register_import_material', key: 'create', name: 'Create', text: 'Register Customs Material' },
    { app: 'Import Application', mKey: 'register_import_material', appkey: 'update_register_import_material', key: 'update', name: 'Update', text: 'Register Customs Material' },
    { app: 'Import Application', mKey: 'register_import_material', appkey: 'delete_register_import_material', key: 'delete', name: 'Delete', text: 'Register Customs Material' },

    { app: 'Import Application', mKey: 'register_import_inv', appkey: 'read_register_import_inv', key: 'read', name: 'View', text: 'Register Customs Import Invoice' },
    { app: 'Import Application', mKey: 'register_import_inv', appkey: 'create_register_import_inv', key: 'create', name: 'Create', text: 'Register Customs Import Invoice' },
    { app: 'Import Application', mKey: 'register_import_inv', appkey: 'update_register_import_inv', key: 'update', name: 'Update', text: 'Register Customs Import Invoice' },
    { app: 'Import Application', mKey: 'register_import_inv', appkey: 'delete_register_import_inv', key: 'delete', name: 'Delete', text: 'Register Customs Import Invoice' },

    //export app
    // { initial: 'Export Application', isGroupBy: true },


    

    { app: 'Export Application', mKey: 'register_master_list', appkey: 'read_register_master_list', key: 'read', name: 'View', text: 'Register Master List' },
    { app: 'Export Application', mKey: 'register_master_list', appkey: 'create_register_master_list', key: 'create', name: 'Create', text: 'Register Master List' },
    { app: 'Export Application', mKey: 'register_master_list', appkey: 'update_register_master_list', key: 'update', name: 'Update', text: 'Register Master List' },
    { app: 'Export Application', mKey: 'register_master_list', appkey: 'delete_register_master_list', key: 'delete', name: 'Delete', text: 'Register Master List' },

    { app: 'Export Application', mKey: 'register_co_export_inv', appkey: 'read_register_co_export_inv', key: 'read', name: 'View', text: 'Register CO Export INV' },
    { app: 'Export Application', mKey: 'register_co_export_inv', appkey: 'create_register_co_export_inv', key: 'create', name: 'Create', text: 'Register CO Export INV' },
    { app: 'Export Application', mKey: 'register_co_export_inv', appkey: 'update_register_co_export_inv', key: 'update', name: 'Update', text: 'Register CO Export INV' },
    { app: 'Export Application', mKey: 'register_co_export_inv', appkey: 'delete_register_co_export_inv', key: 'delete', name: 'Delete', text: 'Register CO Export INV' },


    { app: 'Export Application', mKey: 'register_co_export_inv_usage', appkey: 'read_register_co_export_inv_usage', key: 'read', name: 'View', text: 'Register CO Export INV Usage' },
    { app: 'Export Application', mKey: 'register_co_export_inv_usage', appkey: 'create_register_co_export_inv_usage', key: 'create', name: 'Create', text: 'Register CO Export INV Usage' },
    { app: 'Export Application', mKey: 'register_co_export_inv_usage', appkey: 'update_register_co_export_inv_usage', key: 'update', name: 'Update', text: 'Register CO Export INV Usage' },
    { app: 'Export Application', mKey: 'register_co_export_inv_usage', appkey: 'delete_register_co_export_inv_usage', key: 'delete', name: 'Delete', text: 'Register CO Export INV Usage' },


    { app: 'Export Application', mKey: 'register_export_co', appkey: 'read_register_export_co', key: 'read', name: 'View', text: 'Register Export CO' },
    { app: 'Export Application', mKey: 'register_export_co', appkey: 'create_register_export_co', key: 'create', name: 'Create', text: 'Register Export CO' },
    { app: 'Export Application', mKey: 'register_export_co', appkey: 'update_register_export_co', key: 'update', name: 'Update', text: 'Register Export CO' },
    { app: 'Export Application', mKey: 'register_export_co', appkey: 'delete_register_export_co', key: 'delete', name: 'Delete', text: 'Register Export CO' },

    { app: 'Export Application', mKey: 'register_export_bill', appkey: 'read_register_export_bill', key: 'read', name: 'View', text: 'Register Export Bill' },
    { app: 'Export Application', mKey: 'register_export_bill', appkey: 'create_register_export_bill', key: 'create', name: 'Create', text: 'Register Export Bill' },
    { app: 'Export Application', mKey: 'register_export_bill', appkey: 'update_register_export_bill', key: 'update', name: 'Update', text: 'Register Export Bill' },
    { app: 'Export Application', mKey: 'register_export_bill', appkey: 'delete_register_export_bill', key: 'delete', name: 'Delete', text: 'Register Export Bill' },


    { app: 'Export Application', mKey: 'register_export_inspect', appkey: 'read_register_export_inspect', key: 'read', name: 'View', text: 'Register Export Inspect' },
    { app: 'Export Application', mKey: 'register_export_inspect', appkey: 'create_register_export_inspect', key: 'create', name: 'Create', text: 'Register Export Inspect' },
    { app: 'Export Application', mKey: 'register_export_inspect', appkey: 'update_register_export_inspect', key: 'update', name: 'Update', text: 'Register Export Inspect' },
    { app: 'Export Application', mKey: 'register_export_inspect', appkey: 'delete_register_export_inspect', key: 'delete', name: 'Delete', text: 'Register Export Inspect' },

    // stock app
    { app: 'Stock Application', mKey: 'inventory_co_balance', appkey: 'read_inventory_co_balance', key: 'read', name: 'View', text: 'Inventory CO Balance' },
    { app: 'Stock Application', mKey: 'inventory_co_balance', appkey: 'create_inventory_co_balance', key: 'create', name: 'Create', text: 'Inventory CO Balance' },
    { app: 'Stock Application', mKey: 'inventory_co_balance', appkey: 'update_inventory_co_balance', key: 'update', name: 'Update', text: 'Inventory CO Balance' },
    { app: 'Stock Application', mKey: 'inventory_co_balance', appkey: 'delete_inventory_co_balance', key: 'delete', name: 'Delete', text: 'Inventory CO Balance' },

    { app: 'Stock Application', mKey: 'register_co_ob_stock', appkey: 'read_register_co_ob_stock', key: 'read', name: 'View', text: 'Register CO OB Stock ' },
    { app: 'Stock Application', mKey: 'register_co_ob_stock', appkey: 'create_register_co_ob_stock', key: 'create', name: 'Create', text: 'Register CO OB Stock ' },
    { app: 'Stock Application', mKey: 'register_co_ob_stock', appkey: 'update_register_co_ob_stock', key: 'update', name: 'Update', text: 'Register CO OB Stock ' },
    { app: 'Stock Application', mKey: 'register_co_ob_stock', appkey: 'delete_register_co_ob_stock', key: 'delete', name: 'Delete', text: 'Register CO OB Stock ' },

    { app: 'Stock Application', mKey: 'register_co_adjusted_over', appkey: 'read_register_co_adjusted_over', key: 'read', name: 'View', text: 'Register CO Adjusted Over' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_over', appkey: 'create_register_co_adjusted_over', key: 'create', name: 'Create', text: 'Register CO Adjusted Over' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_over', appkey: 'update_register_co_adjusted_over', key: 'update', name: 'Update', text: 'Register CO Adjusted Over' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_over', appkey: 'delete_register_co_adjusted_over', key: 'delete', name: 'Delete', text: 'Register CO Adjusted Over' },

    { app: 'Stock Application', mKey: 'register_co_adjusted_under', appkey: 'read_register_co_adjusted_under', key: 'read', name: 'View', text: 'Register CO Adjusted Under' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_under', appkey: 'create_register_co_adjusted_under', key: 'create', name: 'Create', text: 'Register CO Adjusted Under' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_under', appkey: 'update_register_co_adjusted_under', key: 'update', name: 'Update', text: 'Register CO Adjusted Under' },
    { app: 'Stock Application', mKey: 'register_co_adjusted_under', appkey: 'delete_register_co_adjusted_under', key: 'delete', name: 'Delete', text: 'Register CO Adjusted Under' },


    { app: 'Stock Application', mKey: 'register_co_import_stock', appkey: 'read_register_co_import_stock', key: 'read', name: 'View', text: 'Register CO Import Stock' },
    { app: 'Stock Application', mKey: 'register_co_import_stock', appkey: 'create_register_co_import_stock', key: 'create', name: 'Create', text: 'Register CO Import Stock' },
    { app: 'Stock Application', mKey: 'register_co_import_stock', appkey: 'update_register_co_import_stock', key: 'update', name: 'Update', text: 'Register CO Import Stock' },
    { app: 'Stock Application', mKey: 'register_co_import_stock', appkey: 'delete_register_co_import_stock', key: 'delete', name: 'Delete', text: 'Register CO Import Stock' },


    { app: 'Stock Application', mKey: 'register_co_cut_stock', appkey: 'read_register_co_cut_stock', key: 'read', name: 'View', text: 'Register CO Cut Stock' },
    { app: 'Stock Application', mKey: 'register_co_cut_stock', appkey: 'create_register_co_cut_stock', key: 'create', name: 'Create', text: 'Register CO Cut Stock' },
    { app: 'Stock Application', mKey: 'register_co_cut_stock', appkey: 'update_register_co_cut_stock', key: 'update', name: 'Update', text: 'Register CO Cut Stock' },
    { app: 'Stock Application', mKey: 'register_co_cut_stock', appkey: 'delete_register_co_cut_stock', key: 'delete', name: 'Delete', text: 'Register CO Cut Stock' },

    // submit app


    { app: 'Submit Applications', mKey: 'register_soo', appkey: 'read_register_soo', key: 'read', name: 'View', text: 'Register SOO' },
    { app: 'Submit Applications', mKey: 'register_soo', appkey: 'create_register_soo', key: 'create', name: 'Create', text: 'Register SOO' },
    { app: 'Submit Applications', mKey: 'register_soo', appkey: 'update_register_soo', key: 'update', name: 'Update', text: 'Register SOO' },
    { app: 'Submit Applications', mKey: 'register_soo', appkey: 'delete_register_soo', key: 'delete', name: 'Delete', text: 'Register SOO' },

    { app: 'Submit Applications', mKey: 'register_soo_statement', appkey: 'read_register_soo_statement', key: 'read', name: 'View', text: 'Register SOO Statement' },
    { app: 'Submit Applications', mKey: 'register_soo_statement', appkey: 'create_register_soo_statement', key: 'create', name: 'Create', text: 'Register SOO Statement' },
    { app: 'Submit Applications', mKey: 'register_soo_statement', appkey: 'update_register_soo_statement', key: 'update', name: 'Update', text: 'Register SOO Statement' },
    { app: 'Submit Applications', mKey: 'register_soo_statement', appkey: 'delete_register_soo_statement', key: 'delete', name: 'Delete', text: 'Register SOO Statement' },

    { app: 'Submit Applications', mKey: 'register_submit_form', appkey: 'read_register_submit_form', key: 'read', name: 'View', text: 'Register Submit Form' },
    { app: 'Submit Applications', mKey: 'register_submit_form', appkey: 'create_register_submit_form', key: 'create', name: 'Create', text: 'Register Submit Form' },
    { app: 'Submit Applications', mKey: 'register_submit_form', appkey: 'update_register_submit_form', key: 'update', name: 'Update', text: 'Register Submit Form' },
    { app: 'Submit Applications', mKey: 'register_submit_form', appkey: 'delete_register_submit_form', key: 'delete', name: 'Delete', text: 'Register Submit Form' },

    { app: 'Submit Applications', mKey: 'register_submit_crf', appkey: 'read_register_submit_crf', key: 'read', name: 'View', text: 'Register Submit CRF' },
    { app: 'Submit Applications', mKey: 'register_submit_crf', appkey: 'create_register_submit_crf', key: 'create', name: 'Create', text: 'Register Submit CRF' },
    { app: 'Submit Applications', mKey: 'register_submit_crf', appkey: 'update_register_submit_crf', key: 'update', name: 'Update', text: 'Register Submit CRF' },
    { app: 'Submit Applications', mKey: 'register_submit_crf', appkey: 'delete_register_submit_crf', key: 'delete', name: 'Delete', text: 'Register Submit CRF' },

    { app: 'Submit Applications', mKey: 'register_cancel_co', appkey: 'read_register_cancel_co', key: 'read', name: 'View', text: 'Register Cancel CO' },
    { app: 'Submit Applications', mKey: 'register_cancel_co', appkey: 'create_register_cancel_co', key: 'create', name: 'Create', text: 'Register Cancel CO' },
    { app: 'Submit Applications', mKey: 'register_cancel_co', appkey: 'update_register_cancel_co', key: 'update', name: 'Update', text: 'Register Cancel CO' },
    { app: 'Submit Applications', mKey: 'register_cancel_co', appkey: 'delete_register_cancel_co', key: 'delete', name: 'Delete', text: 'Register Cancel CO' },


    { app: 'Submit Applications', mKey: 'register_cancel_letter', appkey: 'read_register_cancel_letter', key: 'read', name: 'View', text: 'Register Cancel Letter' },
    { app: 'Submit Applications', mKey: 'register_cancel_letter', appkey: 'create_register_cancel_letter', key: 'create', name: 'Create', text: 'Register Cancel Letter' },
    { app: 'Submit Applications', mKey: 'register_cancel_letter', appkey: 'update_register_cancel_letter', key: 'update', name: 'Update', text: 'Register Cancel Letter' },
    { app: 'Submit Applications', mKey: 'register_cancel_letter', appkey: 'delete_register_cancel_letter', key: 'delete', name: 'Delete', text: 'Register Cancel Letter' },


    { app: 'Submit Applications', mKey: 'register_other_letter', appkey: 'read_register_other_letter', key: 'read', name: 'View', text: 'Register Other Letter' },
    { app: 'Submit Applications', mKey: 'register_other_letter', appkey: 'create_register_other_letter', key: 'create', name: 'Create', text: 'Register Other Letter' },
    { app: 'Submit Applications', mKey: 'register_other_letter', appkey: 'update_register_other_letter', key: 'update', name: 'Update', text: 'Register Other Letter' },
    { app: 'Submit Applications', mKey: 'register_other_letter', appkey: 'delete_register_other_letter', key: 'delete', name: 'Delete', text: 'Register Other Letter' },


    { app: 'Submit Applications', mKey: 'register_letter_form', appkey: 'read_register_letter_form', key: 'read', name: 'View', text: 'Register Letter Form' },
    { app: 'Submit Applications', mKey: 'register_letter_form', appkey: 'create_register_letter_form', key: 'create', name: 'Create', text: 'Register Letter Form' },
    { app: 'Submit Applications', mKey: 'register_letter_form', appkey: 'update_register_letter_form', key: 'update', name: 'Update', text: 'Register Letter Form' },
    { app: 'Submit Applications', mKey: 'register_letter_form', appkey: 'delete_register_letter_form', key: 'delete', name: 'Delete', text: 'Register Letter Form' },

    // return app

    { app: 'Return Application', mKey: 'register_return', appkey: 'read_register_return', key: 'read', name: 'View', text: 'Register Return' },
    { app: 'Return Application', mKey: 'register_return', appkey: 'create_register_return', key: 'create', name: 'Create', text: 'Register Return' },
    { app: 'Return Application', mKey: 'register_return', appkey: 'update_register_return', key: 'update', name: 'Update', text: 'Register Return' },
    { app: 'Return Application', mKey: 'register_return', appkey: 'delete_register_return', key: 'delete', name: 'Delete', text: 'Register Return' },

    { app: 'Return Application', mKey: 'register_reapply_inv', appkey: 'read_register_reapply_inv', key: 'read', name: 'View', text: 'Register Re-apply INV' },
    { app: 'Return Application', mKey: 'register_reapply_inv', appkey: 'create_register_reapply_inv', key: 'create', name: 'Create', text: 'Register Re-apply INV' },
    { app: 'Return Application', mKey: 'register_reapply_inv', appkey: 'update_register_reapply_inv', key: 'update', name: 'Update', text: 'Register Re-apply INV' },
    { app: 'Return Application', mKey: 'register_reapply_inv', appkey: 'delete_register_reapply_inv', key: 'delete', name: 'Delete', text: 'Register Re-apply INV' },


    { app: 'Return Application', mKey: 'register_reapply_form', appkey: 'read_register_reapply_form', key: 'read', name: 'View', text: 'Register Re-apply Form' },
    { app: 'Return Application', mKey: 'register_reapply_form', appkey: 'create_register_reapply_form', key: 'create', name: 'Create', text: 'Register Re-apply Form' },
    { app: 'Return Application', mKey: 'register_reapply_form', appkey: 'update_register_reapply_form', key: 'update', name: 'Update', text: 'Register Re-apply Form' },
    { app: 'Return Application', mKey: 'register_reapply_form', appkey: 'delete_register_reapply_form', key: 'delete', name: 'Delete', text: 'Register Re-apply Form' },

    { app: 'Return Application', mKey: 'register_duplicate_inv', appkey: 'read_register_duplicate_inv', key: 'read', name: 'View', text: 'Register Duplicate INV' },
    { app: 'Return Application', mKey: 'register_duplicate_inv', appkey: 'create_register_duplicate_inv', key: 'create', name: 'Create', text: 'Register Duplicate INV' },
    { app: 'Return Application', mKey: 'register_duplicate_inv', appkey: 'update_register_duplicate_inv', key: 'update', name: 'Update', text: 'Register Duplicate INV' },
    { app: 'Return Application', mKey: 'register_duplicate_inv', appkey: 'delete_register_duplicate_inv', key: 'delete', name: 'Delete', text: 'Register Duplicate INV' },


    { app: 'Return Application', mKey: 'register_duplicate_form', appkey: 'read_register_duplicate_form', key: 'read', name: 'View', text: 'Register Duplicate Form' },
    { app: 'Return Application', mKey: 'register_duplicate_form', appkey: 'create_register_duplicate_form', key: 'create', name: 'Create', text: 'Register Duplicate Form' },
    { app: 'Return Application', mKey: 'register_duplicate_form', appkey: 'update_register_duplicate_form', key: 'update', name: 'Update', text: 'Register Duplicate Form' },
    { app: 'Return Application', mKey: 'register_duplicate_form', appkey: 'delete_register_duplicate_form', key: 'delete', name: 'Delete', text: 'Register Duplicate Form' },

    { app: 'Return Application', mKey: 'register_reapply_letter', appkey: 'read_register_reapply_letter', key: 'read', name: 'View', text: 'Register Re-apply Letter' },
    { app: 'Return Application', mKey: 'register_reapply_letter', appkey: 'create_register_reapply_letter', key: 'create', name: 'Create', text: 'Register Re-apply Letter' },
    { app: 'Return Application', mKey: 'register_reapply_letter', appkey: 'update_register_reapply_letter', key: 'update', name: 'Update', text: 'Register Re-apply Letter' },
    { app: 'Return Application', mKey: 'register_reapply_letter', appkey: 'delete_register_reapply_letter', key: 'delete', name: 'Delete', text: 'Register Re-apply Letter' },


    { app: 'Return Application', mKey: 'register_duplicate_letter', appkey: 'read_register_duplicate_letter', key: 'read', name: 'View', text: 'Register Duplicate Letter' },
    { app: 'Return Application', mKey: 'register_duplicate_letter', appkey: 'create_register_duplicate_letter', key: 'create', name: 'Create', text: 'Register Duplicate Letter' },
    { app: 'Return Application', mKey: 'register_duplicate_letter', appkey: 'update_register_duplicate_letter', key: 'update', name: 'Update', text: 'Register Duplicate Letter' },
    { app: 'Return Application', mKey: 'register_duplicate_letter', appkey: 'delete_register_duplicate_letter', key: 'delete', name: 'Delete', text: 'Register Duplicate Letter' },

    //  delivery app


    { app: 'Delivery Application', mKey: 'delivery_export_inspect', appkey: 'read_delivery_export_inspect', key: 'read', name: 'View', text: 'Delivery Export Inspect' },
    { app: 'Delivery Application', mKey: 'delivery_export_inspect', appkey: 'create_delivery_export_inspect', key: 'create', name: 'Create', text: 'Delivery Export Inspect' },
    { app: 'Delivery Application', mKey: 'delivery_export_inspect', appkey: 'update_delivery_export_inspect', key: 'update', name: 'Update', text: 'Delivery Export Inspect' },
    { app: 'Delivery Application', mKey: 'delivery_export_inspect', appkey: 'delete_delivery_export_inspect', key: 'delete', name: 'Delete', text: 'Delivery Export Inspect' },


    { app: 'Delivery Application', mKey: 'delivery_return', appkey: 'read_delivery_return', key: 'read', name: 'View', text: 'Delivery Return' },
    { app: 'Delivery Application', mKey: 'delivery_return', appkey: 'create_delivery_return', key: 'create', name: 'Create', text: 'Delivery Return' },
    { app: 'Delivery Application', mKey: 'delivery_return', appkey: 'update_delivery_return', key: 'update', name: 'Update', text: 'Delivery Return' },
    { app: 'Delivery Application', mKey: 'delivery_return', appkey: 'delete_delivery_return', key: 'delete', name: 'Delete', text: 'Delivery Return' },

    { app: 'Delivery Application', mKey: 'delivery_export_co', appkey: 'read_delivery_export_co', key: 'read', name: 'View', text: 'Delivery Export CO' },
    { app: 'Delivery Application', mKey: 'delivery_export_co', appkey: 'create_delivery_export_co', key: 'create', name: 'Create', text: 'Delivery Export CO' },
    { app: 'Delivery Application', mKey: 'delivery_export_co', appkey: 'update_delivery_export_co', key: 'update', name: 'Update', text: 'Delivery Export CO' },
    { app: 'Delivery Application', mKey: 'delivery_export_co', appkey: 'delete_delivery_export_co', key: 'delete', name: 'Delete', text: 'Delivery Export CO' },



    { app: 'Delivery Application', mKey: 'delivery_co_import_stock', appkey: 'read_delivery_co_import_stock', key: 'read', name: 'View', text: 'Delivery CO Import Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_import_stock', appkey: 'create_delivery_co_import_stock', key: 'create', name: 'Create', text: 'Delivery CO Import Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_import_stock', appkey: 'update_delivery_co_import_stock', key: 'update', name: 'Update', text: 'Delivery CO Import Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_import_stock', appkey: 'delete_delivery_co_import_stock', key: 'delete', name: 'Delete', text: 'Delivery CO Import Stock' },

    { app: 'Delivery Application', mKey: 'delivery_co_cut_stock', appkey: 'read_delivery_co_cut_stock', key: 'read', name: 'View', text: 'Delivery CO Cut Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_cut_stock', appkey: 'create_delivery_co_cut_stock', key: 'create', name: 'Create', text: 'Delivery CO Cut Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_cut_stock', appkey: 'update_delivery_co_cut_stock', key: 'update', name: 'Update', text: 'Delivery CO Cut Stock' },
    { app: 'Delivery Application', mKey: 'delivery_co_cut_stock', appkey: 'delete_delivery_co_cut_stock', key: 'delete', name: 'Delete', text: 'Delivery CO Cut Stock' },



    // admin app
    { app: 'Administrator', mKey: 'users', appkey: 'read_users', key: 'read', name: 'View', text: 'Users' },
    { app: 'Administrator', mKey: 'users', appkey: 'create_users', key: 'create', name: 'Create', text: 'Users' },
    { app: 'Administrator', mKey: 'users', appkey: 'update_users', key: 'update', name: 'Update', text: 'Users' },
    { app: 'Administrator', mKey: 'users', appkey: 'delete_users', key: 'delete', name: 'Delete', text: 'Users' },


    { app: 'Administrator', mKey: 'user_roles', appkey: 'read_user_roles', key: 'read', name: 'View', text: 'User Roles ' },
    { app: 'Administrator', mKey: 'user_roles', appkey: 'create_user_roles', key: 'create', name: 'Create', text: 'User Roles ' },
    { app: 'Administrator', mKey: 'user_roles', appkey: 'update_user_roles', key: 'update', name: 'Update', text: 'User Roles ' },
    { app: 'Administrator', mKey: 'user_roles', appkey: 'delete_user_roles', key: 'delete', name: 'Delete', text: 'User Roles ' },

    { app: 'Administrator', mKey: 'company', appkey: 'read_company', key: 'read', name: 'View', text: 'Company' },
    { app: 'Administrator', mKey: 'company', appkey: 'create_company', key: 'create', name: 'Create', text: 'Company' },
    { app: 'Administrator', mKey: 'company', appkey: 'update_company', key: 'update', name: 'Update', text: 'Company' },
    { app: 'Administrator', mKey: 'company', appkey: 'delete_company', key: 'delete', name: 'Delete', text: 'Company' },


    { app: 'Administrator', mKey: 'departments', appkey: 'read_departments', key: 'read', name: 'View', text: 'Departments' },
    { app: 'Administrator', mKey: 'departments', appkey: 'create_departments', key: 'create', name: 'Create', text: 'Departments' },
    { app: 'Administrator', mKey: 'departments', appkey: 'update_departments', key: 'update', name: 'Update', text: 'Departments' },
    { app: 'Administrator', mKey: 'departments', appkey: 'delete_departments', key: 'delete', name: 'Delete', text: 'Departments' },



    { app: 'Administrator', mKey: 'co_form', appkey: 'read_co_form', key: 'read', name: 'View', text: 'CO Form' },
    { app: 'Administrator', mKey: 'co_form', appkey: 'create_co_form', key: 'create', name: 'Create', text: 'CO Form' },
    { app: 'Administrator', mKey: 'co_form', appkey: 'update_co_form', key: 'update', name: 'Update', text: 'CO Form' },
    { app: 'Administrator', mKey: 'co_form', appkey: 'delete_co_form', key: 'delete', name: 'Delete', text: 'CO Form' },


    { app: 'Administrator', mKey: 'uom', appkey: 'read_uom', key: 'read', name: 'View', text: 'UOM' },
    { app: 'Administrator', mKey: 'uom', appkey: 'create_uom', key: 'create', name: 'Create', text: 'UOM' },
    { app: 'Administrator', mKey: 'uom', appkey: 'update_uom', key: 'update', name: 'Update', text: 'UOM' },
    { app: 'Administrator', mKey: 'uom', appkey: 'delete_uom', key: 'delete', name: 'Delete', text: 'UOM' },

    { app: 'Administrator', mKey: 'country', appkey: 'read_country', key: 'read', name: 'View', text: 'Country' },
    { app: 'Administrator', mKey: 'country', appkey: 'create_country', key: 'create', name: 'Create', text: 'Country' },
    { app: 'Administrator', mKey: 'country', appkey: 'update_country', key: 'update', name: 'Update', text: 'Country' },
    { app: 'Administrator', mKey: 'country', appkey: 'delete_country', key: 'delete', name: 'Delete', text: 'Country' },

    { app: 'Administrator', mKey: 'country_port', appkey: 'read_country_port', key: 'read', name: 'View', text: 'Country Port' },
    { app: 'Administrator', mKey: 'country_port', appkey: 'create_country_port', key: 'create', name: 'Create', text: 'Country Port' },
    { app: 'Administrator', mKey: 'country_port', appkey: 'update_country_port', key: 'update', name: 'Update', text: 'Country Port' },
    { app: 'Administrator', mKey: 'country_port', appkey: 'delete_country_port', key: 'delete', name: 'Delete', text: 'Country Port' },


    { app: 'Administrator', mKey: 'forwarder', appkey: 'read_forwarder', key: 'read', name: 'View', text: 'Forwarder' },
    { app: 'Administrator', mKey: 'forwarder', appkey: 'create_forwarder', key: 'create', name: 'Create', text: 'Forwarder' },
    { app: 'Administrator', mKey: 'forwarder', appkey: 'update_forwarder', key: 'update', name: 'Update', text: 'Forwarder' },
    { app: 'Administrator', mKey: 'forwarder', appkey: 'delete_forwarder', key: 'delete', name: 'Delete', text: 'Forwarder' },


    { app: 'Administrator', mKey: 'application_route', appkey: 'read_application_route', key: 'read', name: 'View', text: 'Application Route' },
    { app: 'Administrator', mKey: 'application_route', appkey: 'create_application_route', key: 'create', name: 'Create', text: 'Application Route' },
    { app: 'Administrator', mKey: 'application_route', appkey: 'update_application_route', key: 'update', name: 'Update', text: 'Application Route' },
    { app: 'Administrator', mKey: 'application_route', appkey: 'delete_application_route', key: 'delete', name: 'Delete', text: 'Application Route' },

    { app: 'Administrator', mKey: 'application_product_type', appkey: 'read_application_product_type', key: 'read', name: 'View', text: 'Application Product Type' },
    { app: 'Administrator', mKey: 'application_product_type', appkey: 'create_application_product_type', key: 'create', name: 'Create', text: 'Application Product Type' },
    { app: 'Administrator', mKey: 'application_product_type', appkey: 'update_application_product_type', key: 'update', name: 'Update', text: 'Application Product Type' },
    { app: 'Administrator', mKey: 'application_product_type', appkey: 'delete_application_product_type', key: 'delete', name: 'Delete', text: 'Application Product Type' },

    { app: 'Administrator', mKey: 'application_title', appkey: 'read_application_title', key: 'read', name: 'View', text: 'Application Title' },
    { app: 'Administrator', mKey: 'application_title', appkey: 'create_application_title', key: 'create', name: 'Create', text: 'Application Title' },
    { app: 'Administrator', mKey: 'application_title', appkey: 'update_application_title', key: 'update', name: 'Update', text: 'Application Title' },
    { app: 'Administrator', mKey: 'application_title', appkey: 'delete_application_title', key: 'delete', name: 'Delete', text: 'Application Title' },


    { app: 'Administrator', mKey: 'currency', appkey: 'read_currency', key: 'read', name: 'View', text: 'Currency' },
    { app: 'Administrator', mKey: 'currency', appkey: 'create_currency', key: 'create', name: 'Create', text: 'Currency' },
    { app: 'Administrator', mKey: 'currency', appkey: 'update_currency', key: 'update', name: 'Update', text: 'Currency' },
    { app: 'Administrator', mKey: 'currency', appkey: 'delete_currency', key: 'delete', name: 'Delete', text: 'Currency' },

    { app: 'Administrator', mKey: 'hs_code', appkey: 'read_hs_code', key: 'read', name: 'View', text: 'HS Code' },
    { app: 'Administrator', mKey: 'hs_code', appkey: 'create_hs_code', key: 'create', name: 'Create', text: 'HS Code' },
    { app: 'Administrator', mKey: 'hs_code', appkey: 'update_hs_code', key: 'update', name: 'Update', text: 'HS Code' },
    { app: 'Administrator', mKey: 'hs_code', appkey: 'delete_hs_code', key: 'delete', name: 'Delete', text: 'HS Code' },



    // report app



    { app: 'Reports', mKey: 'register_export_co_report', appkey: 'read_register_export_co_report', key: 'read', name: 'View', text: 'Register Export CO Report' },
    { app: 'Reports', mKey: 'register_export_cancel_co_report', appkey: 'read_register_export_cancel_co_report', key: 'read', name: 'View', text: 'Register Export Cancel CO Report' },
    // { app: 'Reports', mKey: 'register_export_co_report', appkey: 'create_register_export_co_report', key: 'create', name: 'Create', text: 'Register Export CO Report' },
    // { app: 'Reports', mKey: 'register_export_co_report', appkey: 'update_register_export_co_report', key: 'update', name: 'Update', text: 'Register Export CO Report' },
    // { app: 'Reports', mKey: 'register_export_co_report', appkey: 'delete_register_export_co_report', key: 'delete', name: 'Delete', text: 'Register Export CO Report' },

    { app: 'Reports', mKey: 'register_soo_report', appkey: 'read_register_soo_report', key: 'read', name: 'View', text: 'Register SOO Report' },
    { app: 'Reports', mKey: 'register_export_inspect_report', appkey: 'read_register_export_inspect_report', key: 'read', name: 'View', text: 'Register Export Inspect Report' },
    { app: 'Reports', mKey: 'register_export_invoice_report', appkey: 'read_register_export_invoice_report', key: 'read', name: 'View', text: 'Register Export Invoice Report' },

    { app: 'Reports', mKey: 'none_cut_stock_report', appkey: 'read_none_cut_stock_report', key: 'read', name: 'View', text: 'Outstanding Cut Stock Report' },
    { app: 'Reports', mKey: 'none_import_stock_report', appkey: 'read_none_import_stock_report', key: 'read', name: 'View', text: 'Outstanding Import Stock Report' }, 

    { app: 'Reports', mKey: 'none_local_cut_stock_report', appkey: 'read_none_local_cut_stock_report', key: 'read', name: 'View', text: 'Outstanding Local Sale Stock Report' },
    { app: 'Reports', mKey: 'none_local_import_stock_report', appkey: 'read_none_local_import_stock_report', key: 'read', name: 'View', text: 'Outstanding Local Purchase Stock Report' }, 


    { app: 'Reports', mKey: 'cut_stock_report', appkey: 'read_cut_stock_report', key: 'read', name: 'View', text: 'Cut Stock Report' },
    { app: 'Reports', mKey: 'import_stock_report', appkey: 'read_import_stock_report', key: 'read', name: 'View', text: 'Import Stock Report' },

    { app: 'Reports', mKey: 'local_sale_inv_report', appkey: 'read_local_sale_inv_report', key: 'read', name: 'View', text: 'Local Sale Inv Report' },
    { app: 'Reports', mKey: 'local_cut_stock_report', appkey: 'read_local_cut_stock_report', key: 'read', name: 'View', text: 'Cut Stock Local Sale Inv Report' },

    { app: 'Reports', mKey: 'register_reapply_item_report', appkey: 'read_register_reapply_item_report', key: 'read', name: 'View', text: 'Register Re-apply INV Report' },
    { app: 'Reports', mKey: 'register_duplicate_item', appkey: 'read_register_duplicate_item_report', key: 'read', name: 'View', text: 'Register Duplicate INV Report' },
    { app: 'Reports', mKey: 'register_local_invoice', appkey: 'read_register_local_invoice_report', key: 'read', name: 'View', text: 'Local Purchase Inv Report' },
    { app: 'Reports', mKey: 'register_local_import_stock', appkey: 'read_register_local_import_stock_report', key: 'read', name: 'View', text: 'Local Purchase Stock Report' },
    { app: 'Reports', mKey: 'register_import_invoice_report', appkey: 'read_register_import_invoice_report', key: 'read', name: 'View', text: 'Register Import Invoice Report' },
    { app: 'Reports', mKey: 'register_adjust_stock_report', appkey: 'read_register_adjust_stock_report', key: 'read', name: 'View', text: 'Register Adjust Stock Report' },
    { app: 'Reports', mKey: 'inventory_balance_report', appkey: 'read_inventory_balance_report', key: 'read', name: 'View', text: 'Inventory Balance Report' },



    // filemanager

    { app: 'File Manager', mKey: 'file_manager', appkey: 'read_file_manager', key: 'read', name: 'View', text: 'File Manager' },
    { app: 'File Manager', mKey: 'file_manager', appkey: 'create_file_manager', key: 'create', name: 'Create', text: 'File Manager' },
    // { app: 'File Manager', mKey: 'file_manager', appkey: 'update_file_manager', key: 'update', name: 'Update', text: 'File Manager' },
    { app: 'File Manager', mKey: 'file_manager', appkey: 'delete_file_manager', key: 'delete', name: 'Delete', text: 'File Manager' },





    // local

    { app: 'Local Inv Application', mKey: 'local_sale_inv', appkey: 'read_local_sale_inv', key: 'read', name: 'View', text: 'Local Sale Invoice' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv', appkey: 'create_local_sale_inv', key: 'create', name: 'Create', text: 'Local Sale Invoice' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv', appkey: 'update_local_sale_inv', key: 'update', name: 'Update', text: 'Local Sale Invoice' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv', appkey: 'delete_local_sale_inv', key: 'delete', name: 'Delete', text: 'Local Sale Invoice' },


    { app: 'Local Inv Application', mKey: 'local_sale_inv_usage', appkey: 'read_local_sale_inv_usage', key: 'read', name: 'View', text: 'Local Sale Inv Usage' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv_usage', appkey: 'create_local_sale_inv_usage', key: 'create', name: 'Create', text: 'Local Sale Inv Usage' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv_usage', appkey: 'update_local_sale_inv_usage', key: 'update', name: 'Update', text: 'Local Sale Inv Usage' },
    { app: 'Local Inv Application', mKey: 'local_sale_inv_usage', appkey: 'delete_local_sale_inv_usage', key: 'delete', name: 'Delete', text: 'Local Sale Inv Usage' },


    { app: 'Local Inv Application', mKey: 'local_delivery_cut_stock', appkey: 'read_local_delivery_cut_stock', key: 'read', name: 'View', text: 'Delivery Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_delivery_cut_stock', appkey: 'create_local_delivery_cut_stock', key: 'create', name: 'Create', text: 'Delivery Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_delivery_cut_stock', appkey: 'update_local_delivery_cut_stock', key: 'update', name: 'Update', text: 'Delivery Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_delivery_cut_stock', appkey: 'delete_local_delivery_cut_stock', key: 'delete', name: 'Delete', text: 'Delivery Local Sale Stock' },


    { app: 'Local Inv Application', mKey: 'local_cut_stock', appkey: 'read_local_cut_stock', key: 'read', name: 'View', text: 'Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_cut_stock', appkey: 'create_local_cut_stock', key: 'create', name: 'Create', text: 'Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_cut_stock', appkey: 'update_local_cut_stock', key: 'update', name: 'Update', text: 'Local Sale Stock' },
    { app: 'Local Inv Application', mKey: 'local_cut_stock', appkey: 'delete_local_cut_stock', key: 'delete', name: 'Delete', text: 'Local Sale Stock' },


    { app: 'Local Inv Application', mKey: 'local_delivery', appkey: 'read_local_delivery', key: 'read', name: 'View', text: 'Delivery Local Sale Inv' },
    { app: 'Local Inv Application', mKey: 'local_delivery', appkey: 'create_local_delivery', key: 'create', name: 'Create', text: 'Delivery Local Sale Inv' },
    { app: 'Local Inv Application', mKey: 'local_delivery', appkey: 'update_local_delivery', key: 'update', name: 'Update', text: 'Delivery Local Sale Inv' },
    { app: 'Local Inv Application', mKey: 'local_delivery', appkey: 'delete_local_delivery', key: 'delete', name: 'Delete', text: 'Delivery Local Sale Inv' },




    { app: 'Local Purchase Inv App', mKey: 'register_local_import_stock', appkey: 'read_register_local_import_stock', key: 'read', name: 'View', text: 'Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_import_stock', appkey: 'create_register_local_import_stock', key: 'create', name: 'Create', text: 'Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_import_stock', appkey: 'update_register_local_import_stock', key: 'update', name: 'Update', text: 'Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_import_stock', appkey: 'delete_register_local_import_stock', key: 'delete', name: 'Delete', text: 'Local Purchase Stock' },


    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice', appkey: 'read_register_local_invoice', key: 'read', name: 'View', text: 'Local Purchase Inv' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice', appkey: 'create_register_local_invoice', key: 'create', name: 'Create', text: 'Local Purchase Inv' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice', appkey: 'update_register_local_invoice', key: 'update', name: 'Update', text: 'Local Purchase Inv' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice', appkey: 'delete_register_local_invoice', key: 'delete', name: 'Delete', text: 'Local Purchase Inv' },


    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice_info', appkey: 'read_register_local_invoice_info', key: 'read', name: 'View', text: 'Local Purchase Inv Information' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice_info', appkey: 'create_register_local_invoice_info', key: 'create', name: 'Create', text: 'Local Purchase Inv Information' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice_info', appkey: 'update_register_local_invoice_info', key: 'update', name: 'Update', text: 'Local Purchase Inv Information' },
    { app: 'Local Purchase Inv App', mKey: 'register_local_invoice_info', appkey: 'delete_register_local_invoice_info', key: 'delete', name: 'Delete', text: 'Local Purchase Inv Information' },


    { app: 'Local Purchase Inv App', mKey: 'register_delivery_local_import_stock', appkey: 'read_register_delivery_local_import_stock', key: 'read', name: 'View', text: 'Delivery Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_delivery_local_import_stock', appkey: 'create_register_delivery_local_import_stock', key: 'create', name: 'Create', text: 'Delivery Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_delivery_local_import_stock', appkey: 'update_register_delivery_local_import_stock', key: 'update', name: 'Update', text: 'Delivery Local Purchase Stock' },
    { app: 'Local Purchase Inv App', mKey: 'register_delivery_local_import_stock', appkey: 'delete_register_delivery_local_import_stock', key: 'delete', name: 'Delete', text: 'Delivery Local Purchase Stock' },



]



export const NOTIFYPERMISSION: any[] = [
    // import app
    'register_co_material',
    'register_co_import_inv',
    'register_co_import_inv_information',
    //export app
    'register_co_export_inv',
    'register_co_export_inv_usage',
    'register_export_co',
    'register_export_bill',
    'register_export_inspect',
    // stock app
    'register_co_ob_stock',
    'register_co_adjusted_over',
    'register_co_adjusted_under',
    'register_co_import_stock',
    'register_co_cut_stock',
    // submit app
    'register_soo',
    'register_soo_statement',
    'register_submit_form',
    'register_submit_crf',
    'register_cancel_co',
    // return app
    'register_return',
    'register_reapply_inv',
    'register_reapply_form',
    'register_duplicate_inv',
    'register_duplicate_form',
    //  delivery app
    'delivery_export_inspect',
    'read_delivery_return',
    'delivery_export_co',
    'delivery_co_import_stock',
    'delivery_co_cut_stock',
    // local
    'local_sale_inv',
    'local_sale_inv_usage',
    'local_delivery_cut_stock',
    'local_cut_stock',
    'local_delivery',
]


export const NOTIFYPERMISSIONOBJECT = {
    // import app
    register_co_material:{
        key: 'register_co_material',
        text: 'Register CO Material'
    },
    register_co_import_inv:{
        key: 'register_co_import_inv',
        text: 'Register CO Import INV'
    },
    register_co_import_inv_information:{
        key: 'register_co_import_inv_information',
        text: 'Register CO Import IV Information'
    },
    register_co_export_inv:{
        key: 'register_co_export_inv',
        text: 'Register CO Export INV'
    },
    register_co_export_inv_usage:{
        key: 'register_co_export_inv_usage',
        text: 'Register CO Export INV Usage'
    },
    register_export_co:{
        key: 'register_export_co',
        text: 'Register Export CO'
    },
    register_export_bill:{
        key: 'register_export_bill',
        text: 'Register Export Bill'
    },
    register_export_inspect:{
        key: 'register_export_inspect',
        text: 'Register Export Inspect'
    },
    register_co_ob_stock:{
        key: 'register_co_ob_stock',
        text: 'Register CO OB Stock'
    },
    register_co_adjusted_over:{
        key: 'register_co_adjusted_over',
        text: 'Register CO Adjusted Over'
    },
    register_co_adjusted_under:{
        key: 'register_co_adjusted_under',
        text: 'Register CO Adjusted Under'
    },
    register_co_import_stock:{
        key: 'register_co_import_stock',
        text: 'Register CO Import Stock'
    },

    register_co_cut_stock:{
        key: 'register_co_cut_stock',
        text: 'Register CO Cut Stock'
    },

    register_soo:{
        key: 'register_soo',
        text: 'Register SOO'
    },

    register_soo_statement:{
        key: 'register_soo_statement',
        text: 'Register SOO Statement'
    },

    register_submit_form:{
        key: 'register_submit_form',
        text: 'Register Submit Form'
    },

    register_submit_crf:{
        key: 'register_submit_crf',
        text: 'Register Submit CRF'
    },
    register_cancel_co:{
        key: 'register_cancel_co',
        text: 'Register Cancel CO'
    },
    register_return:{
        key: 'register_return',
        text: 'Register Return'
    },
    register_reapply_inv:{
        key: 'register_reapply_inv',
        text: 'Register Reapply INV'
    },

    register_reapply_form:{
        key: 'register_reapply_form',
        text: 'Register Reapply Form'
    },
    register_duplicate_inv:{
        key: 'register_duplicate_inv',
        text: 'Register Duplicate INV'
    },
    register_duplicate_form:{
        key: 'register_duplicate_form',
        text: 'Register Duplicate Form'
    },
    delivery_export_inspect:{
        key: 'delivery_export_inspect',
        text: 'Delivery Export Inspect'
    },
    read_delivery_return:{
        key: 'delivery_return',
        text: 'Delivery Return'
    },
    delivery_export_co:{
        key: 'delivery_export_co',
        text: 'Delivery Export CO'
    },
    delivery_co_import_stock:{
        key: 'delivery_co_import_stock',
        text: 'Delivery CO Import Stock'
    },
    delivery_co_cut_stock:{
        key: 'delivery_co_cut_stock',
        text: 'Delivery CO Cut Stock'
    },
    local_sale_inv:{
        key: 'local_sale_inv',
        text: 'Local Sale INV'
    },

    local_sale_inv_usage:{
        key: 'local_sale_inv_usage',
        text: 'Local Sale INV Usage'
    },
    local_delivery_cut_stock:{
        key: 'local_delivery_cut_stock',
        text: 'Local Delivery Cut Stock'
    },
    local_cut_stock:{
        key: 'local_cut_stock',
        text: 'Local Cut Stock'
    },
    local_delivery:{
        key: 'local_delivery',
        text: 'Local Delivery'
    },
}












// export const PERMISSION: any[] = [

//     {
//         key: 'import_application',
//         name: 'Import Application',
//         task: [
//             {
//                 key: 'register_co_material',
//                 name: 'Register CO Material',
//                 task: [
//                     { appkey: 'register_co_material', key: 'read', name: 'View', text: 'Register CO Material' },
//                     { appkey: 'register_co_material', key: 'create', name: 'Create', text: 'Register CO Material' },
//                     { appkey: 'register_co_material', key: 'update', name: 'Update', text: 'Register CO Material' },
//                     { appkey: 'register_co_material', key: 'delete', name: 'Delete', text: 'Register CO Material' },
//                 ]
//             },
//             {
//                 key: 'register_co_import_inv',
//                 name: 'Register CO Import INV',
//                 task: [
//                     { appkey: 'register_co_import_inv', key: 'read', name: 'View', text: 'Register CO Import INV' },
//                     { appkey: 'register_co_import_inv', key: 'create', name: 'Create', text: 'Register CO Import INV' },
//                     { appkey: 'register_co_import_inv', key: 'update', name: 'Update', text: 'Register CO Import INV' },
//                     { appkey: 'register_co_import_inv', key: 'delete', name: 'Delete', text: 'Register CO Import INV' },
//                 ]
//             },
//             {
//                 key: 'register_co_import_inv_information',
//                 name: 'Register CO Import INV Information',
//                 task: [
//                     { appkey: 'register_co_import_inv_information', key: 'read', name: 'View', text: 'Register CO Import INV Information' },
//                     { appkey: 'register_co_import_inv_information', key: 'create', name: 'Create', text: 'Register CO Import INV Information' },
//                     { appkey: 'register_co_import_inv_information', key: 'update', name: 'Update', text: 'Register CO Import INV Information' },
//                     { appkey: 'register_co_import_inv_information', key: 'delete', name: 'Delete', text: 'Register CO Import INV Information' },
//                 ]
//             },
//         ]
//     },

//     {
//         key: 'export_application',
//         name: 'Export Application',
//         task: [
//             {
//                 key: 'register_co_export_inv',
//                 name: 'Register CO Export INV',
//                 task: [
//                     { appkey: 'register_co_export_inv', key: 'read', name: 'View', text: 'Register CO Export INV' },
//                     { appkey: 'register_co_export_inv', key: 'create', name: 'Create', text: 'Register CO Export INV' },
//                     { appkey: 'register_co_export_inv', key: 'update', name: 'Update', text: 'Register CO Export INV' },
//                     { appkey: 'register_co_export_inv', key: 'delete', name: 'Delete', text: 'Register CO Export INV' },
//                 ]
//             },
//             {
//                 key: 'register_co_export_inv_usage',
//                 name: 'Register CO Export INV Usage',
//                 task: [
//                     { appkey: 'register_co_export_inv_usage', key: 'read', name: 'View', text: 'Register CO Export INV Usage' },
//                     { appkey: 'register_co_export_inv_usage', key: 'create', name: 'Create', text: 'Register CO Export INV Usage' },
//                     { appkey: 'register_co_export_inv_usage', key: 'update', name: 'Update', text: 'Register CO Export INV Usage' },
//                     { appkey: 'register_co_export_inv_usage', key: 'delete', name: 'Delete', text: 'Register CO Export INV Usage' },
//                 ]
//             },
//             {
//                 key: 'register_export_co',
//                 name: 'Register Export CO',
//                 task: [
//                     { appkey: 'register_export_co', key: 'read', name: 'View', text: 'Register Export CO' },
//                     { appkey: 'register_export_co', key: 'create', name: 'Create', text: 'Register Export CO' },
//                     { appkey: 'register_export_co', key: 'update', name: 'Update', text: 'Register Export CO' },
//                     { appkey: 'register_export_co', key: 'delete', name: 'Delete', text: 'Register Export CO' },
//                 ]
//             },
//             {
//                 key: 'register_export_bill',
//                 name: 'Register Export Bill',
//                 task: [
//                     { appkey: 'register_export_bill', key: 'read', name: 'View', text: 'Register Export Bill' },
//                     { appkey: 'register_export_bill', key: 'create', name: 'Create', text: 'Register Export Bill' },
//                     { appkey: 'register_export_bill', key: 'update', name: 'Update', text: 'Register Export Bill' },
//                     { appkey: 'register_export_bill', key: 'delete', name: 'Delete', text: 'Register Export Bill' },
//                 ]
//             },
//             {
//                 key: 'register_export_inspect',
//                 name: 'Register Export Inspect',
//                 task: [
//                     { appkey: 'register_export_inspect', key: 'read', name: 'View', text: 'Register Export Inspect' },
//                     { appkey: 'register_export_inspect', key: 'create', name: 'Create', text: 'Register Export Inspect' },
//                     { appkey: 'register_export_inspect', key: 'update', name: 'Update', text: 'Register Export Inspect' },
//                     { appkey: 'register_export_inspect', key: 'delete', name: 'Delete', text: 'Register Export Inspect' },
//                 ]
//             },

//         ]
//     },







// ]
