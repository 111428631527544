import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthlayoutComponent } from './shared/authlayout/authlayout.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { TabLayoutComponent } from './shared/tab-layout/tab-layout.component';
import { OptionsComponent } from './pages/settings/options/options.component';
import { FileManagerComponent } from './pages/file-manager/file-manager.component';
import { FileDataComponent } from './pages/file-manager/file-data/file-data.component';
import { HomeComponent } from './pages/home/home.component';
import { ChooseCompanyComponent } from './pages/choose-company/choose-company.component';
import { CanDeactivateGuard } from './guard/can-component-deactivate.guard';
const routes: Routes = [
  {
    path: "login",
    component: AuthlayoutComponent,
    children: [
      { path: "", component: LoginComponent },
    ]
  },
  {
    path: "login",
    component: AuthlayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "choose-company", component: ChooseCompanyComponent }
    ]
  },

  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeComponent },
      {
        path: 'reports', component: TabLayoutComponent,
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
      },

      {
        path: 'view-and-register', component: TabLayoutComponent,
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'register-local-inv', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-sale-inv/local-sale-inv.module').then(m => m.LocalSaleInvModule)
      },

      {
        path: 'register-local-inv-usage', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-sale-inv-usage/local-sale-inv-usage.module').then(m => m.LocalSaleInvUsageModule)
      },

      {
        path: 'register-item', component: TabLayoutComponent,
        loadChildren: () => import('./pages/items/items.module').then(m => m.ItemsModule)
      },

      {
        path: 'register-export-inv-usage', component: TabLayoutComponent,
        loadChildren: () => import('./pages/export-inv-usage/export-inv-usage.module').then(m => m.ExportInvUsageModule)
      },

      {
        path: 're-apply-letter', component: TabLayoutComponent,
        loadChildren: () => import('./pages/re-apply-letter/re-apply-letter.module').then(m => m.ReApplyLetterModule)
      },
      {
        path: 'duplicate-letter', component: TabLayoutComponent,
        loadChildren: () => import('./pages/duplicate-letter/duplicate-letter.module').then(m => m.DuplicateLetterModule)
      },

      {
        path: 'cancel-co-letter', component: TabLayoutComponent,
        loadChildren: () => import('./pages/cancel-co-letter/cancel-co-letter.module').then(m => m.CancelCoLetterModule)
      },

      {
        path: 'other-letter', component: TabLayoutComponent,
        loadChildren: () => import('./pages/other-latter/other-latter.module').then(m => m.OtherLatterModule)
      },

      {
        path: 'letter-form', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-latter-form/register-latter-form.module').then(m => m.RegisterLatterFormModule)
      },

      {
        path: 'user-management', component: TabLayoutComponent,
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
      },

      {
        path: 'item-registration', component: TabLayoutComponent,
        loadChildren: () => import('./pages/material/material.module').then(m => m.MaterialModule)
      },

      {
        path: "file-manager", component: TabLayoutComponent, children: [
          { path: "", redirectTo: "lists/:app", pathMatch: "full" },
          { path: "lists/:app", component: FileManagerComponent },
          { path: "lists/:app/:type/:id", component: FileDataComponent },
        ]
      },

      {
        path: 'register-co', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-co/register-co.module').then(m => m.RegisterCoModule)
      },

      {
        path: 'register-master-list', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-master-list/register-master-list.module').then(m => m.RegisterMasterListModule)
      },
      
      {
        path: 'pickup-bill', component: TabLayoutComponent,
        loadChildren: () => import('./pages/pickup-bill/pickup-bill.module').then(m => m.PickupBillModule)
      },

      {
        path: 'pickup-inspect', component: TabLayoutComponent,
        loadChildren: () => import('./pages/pick-up-inspect/pick-up-inspect.module').then(m => m.PickUpInspectModule)
      },
      {
        path: 'register-invoice', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-invoice/register-invoice.module').then(m => m.RegisterInvoiceModule)
      },

      {
        path: 'register-import-invoice', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-import-invoice/register-import-invoice.module').then(m => m.RegisterImportInvoiceModule)
      },

      {
        path: 'register-invoice-info', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-invoice-info/register-invoice-info.module').then(m => m.RegisterInvoiceInfoModule)
      },

      {
        path: 'register-local-invoice', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-import/local-import-invoice/local-import.module').then(m => m.LocalImportModule)
      },

      {
        path: 'register-local-invoice-info', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-import/local-invoice-info/local-import-info.module').then(m => m.LocalImportInfoModule)
      },

      {
        path: 'delivery-export', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-export-document/delivery-export-document.module').then(m => m.DeliveryExportDocumentModule)
      },

      {
        path: 're-apply-register-item', component: TabLayoutComponent,
        loadChildren: () => import('./pages/re-apply-register-item/re-apply-register-item.module').then(m => m.ReApplyRegisterItemModule)
      },
      {
        path: 'register-re-apply-application', component: TabLayoutComponent,
        loadChildren: () => import('./pages/re-apply-application/re-apply-application.module').then(m => m.ReApplyApplicationModule)
      },

      {
        path: 'register-duplicate-application', component: TabLayoutComponent,
        loadChildren: () => import('./pages/duplicate-application/duplicate-application.module').then(m => m.DuplicateApplicationModule)
      },

      {
        path: 'register-duplicate-item', component: TabLayoutComponent,
        loadChildren: () => import('./pages/duplicate-application-item/duplicate-application-item.module').then(m => m.DuplicateApplicationItemModule)
      },
      {
        path: 'register-return-application', component: TabLayoutComponent,
        loadChildren: () => import('./pages/return-application/return-application.module').then(m => m.ReturnApplicationModule)
      },

      {
        path: 'delivery-return-application', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-return-document/delivery-return-document.module').then(m => m.DeliveryReturnDocumentModule)
      },

      {
        path: 'register-delivery-co-application', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-co-application/delivery-co-application.module').then(m => m.DeliveryCoApplicationModule)
      },

      {
        path: 'register-local-delivery', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-delivery/local-delivery.module').then(m => m.LocalDeliveryModule)
      },

      {
        path: 'register-cut-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-cut-stock/register-cut-stock.module').then(m => m.RegisterCutStockModule)
      },

      {
        path: 'register-local-cut-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-cut-stock/local-cut-stock.module').then(m => m.LocalCutStockModule)
      },


      {
        path: 'register-soo', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-soo/register-soo.module').then(m => m.RegisterSooModule)
      },

      {
        path: 'register-soo-statement', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-soo-statement/register-soo-statement.module').then(m => m.RegisterSooStatementModule)
      },

      {
        path: 'register-submit-form', component: TabLayoutComponent,
        loadChildren: () => import('./pages/submit-form/submit-form.module').then(m => m.SubmitFormModule)
      },
      {
        path: 'register-submit-crf', component: TabLayoutComponent,
        loadChildren: () => import('./pages/submit-crf/submit-crf.module').then(m => m.SubmitCrfModule)
      },

      {
        path: 'delivery-import-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-import-stock-application/delivery-import-stock-application.module').then(m => m.DeliveryImportStockApplicationModule)
      },

      {
        path: 'delivery-local-import-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-local-purchase-stock/delivery-local-purchase-stock.module').then(m => m.DeliveryLocalPurchaseStockModule)
      },
      {
        path: 'delivery-cut-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/delivery-cut-stock/delivery-cut-stock.module').then(m => m.DeliveryCutStockModule)
      },

      {
        path: 'local-delivery-cut-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/local-delivery-cut-stock/local-delivery-cut-stock.module').then(m => m.LocalDeliveryCutStockModule)
      },
      {
        path: "options", component: TabLayoutComponent,
        children: [
          { path: "", redirectTo: "general", pathMatch: "full" },
          { path: "general", component: OptionsComponent },
        ]
      },

      {
        path: 'register-ob-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-ob-stock/register-ob-stock.module').then(m => m.RegisterObStockModule)
      },
      {
        path: 'inventory-balance', component: TabLayoutComponent,
        loadChildren: () => import('./pages/inventory-balance/inventory-balance.module').then(m => m.InventoryBalanceModule)
      },


      {
        path: 'register-cancel-co', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-cancel-co/register-cancel-co.module').then(m => m.RegisterCancelCoModule)
      },

      {
        path: 'register-adjustment-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-adjustment-stock/register-adjustment-stock.module').then(m => m.RegisterAdjustmentStockModule)
      },
      {
        path: 'register-adjustment-stock-decrease', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-adjustment-stock-decrease/register-adjustment-stock-decrease.module').then(m => m.RegisterAdjustmentStockDecreaseModule)
      },
      {
        path: 'register-import-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-import-stock/register-import-stock.module').then(m => m.RegisterImportStockModule)
      },

      {
        path: 'register-local-import-stock', component: TabLayoutComponent,
        loadChildren: () => import('./pages/register-local-purchase/register-local-purchase.module').then(m => m.RegisterLocalPurchaseModule)
      },

    ]

  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    CanDeactivateGuard
  ],
})
export class AppRoutingModule { }
