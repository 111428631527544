import { Auth } from './../../stores/auth.store';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SideNavService } from 'src/app/services/sidenav.servce';
import { ChangePasswordComponent } from 'src/app/pages/user/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  toggleSidebar;
  ismobile = false;
  notificationsData;
  uploadPercent: any;
  subscription: Subscription;

  constructor(
    public store: Auth,
    public st: SettingStore,
    public ds: DataService,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog,
    private sideNavService: SideNavService
  ) { }

  ngOnInit() {

    const toggleSidebar = localStorage.getItem("toggleSidebar");
    this.toggleSidebar = JSON.parse(toggleSidebar);
    this.subscription = this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
  }

  toggleActive: boolean = false;

  toggleChartbar() {
    this.sideNavService.toggle();
  }
  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      panelClass: 'item-list-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  togglesidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    localStorage.setItem('toggleSidebar', JSON.stringify(this.toggleSidebar));
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  onLogOut() {
    const uRef = this.ds.userFireRef().doc(this.store.user.uid);
    uRef.update({ selected_company: null }).then(() => {
      this.store.signOut(() => {
        this.router.navigate(['/login'])
      });
    }).catch(error => {
      console.log('error', error);
    }).finally(() => { })
  }

  async onSelectedFile(event) {
    if (event) {
      this.st.changeAvatar(event.target.files[0], this.store.user, (success, msg) => {})
    }
  }

}
