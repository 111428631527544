<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">
        <div class="title">
            Print Header Footer
        </div>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content">
        <div class="form-wrapper" [formGroup]="form">
          
            <mat-vertical-stepper [linear]="isLinear" #stepper>

                <mat-step [stepControl]="form">
                    <form [formGroup]="form">
                        <ng-template matStepLabel>Print Header Information</ng-template>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Company (Khmer)</mat-label>
                                <input matInput required placeholder="Company (KH)"
                                    formControlName="company_kh">
                                <mat-error *ngIf="form.controls['company_kh'].hasError('required')">
                                    This field is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">

                            <mat-form-field appearance="outline">
                                <mat-label>Company (English)</mat-label>
                                <input matInput required placeholder="Company (EN)"
                                    formControlName="company_en">
                                <mat-error *ngIf="form.controls['company_en'].hasError('required')">
                                    This field is required.
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Address (KH)</mat-label>
                                <textarea matInput placeholder="Company address (KH)"
                                    formControlName="address_kh"></textarea>
                                <mat-error *ngIf="form.controls['address_kh'].hasError('required')">
                                    This field is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="form">
                    <form [formGroup]="form">
                        <ng-template matStepLabel>Print Footer Information</ng-template>
                     

                        <div class="form-group">

                            <mat-form-field appearance="outline">
                                <mat-label>Address (EN)</mat-label>
                                <textarea matInput placeholder="Company address (EN)"
                                    formControlName="address_en"></textarea>
                                <mat-error *ngIf="form.controls['address_en'].hasError('required')">
                                    This field is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"><span>Cancel</span></a>
        <app-primary-button (onPress)="onSave(form.value)" [disable]="!form.valid" [process]="processNew"
            label="Save">
        </app-primary-button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>
</form>