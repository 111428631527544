<mat-sidenav-container style="min-height: calc(100vh - 168px)">
    <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'"
        class="content-side" [fixedInViewport]="true" [fixedTopGap]="118" [style.width]="ismobile?'100%':'50%'"
        [fixedBottomGap]="0">
        <button mat-icon-button (click)="sidenav.toggle()"
            style="position: absolute; right: 24px; z-index: 999; top: 4px;">
            <mat-icon class="mat-18">clear</mat-icon>
        </button>
        <mat-tab-group style="height: 100%; ">
            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    Comment
                </ng-template>
                <div class="comment-body">
                    <div class="comment-list">
                        <div class="chat">
                            <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                                *ngFor="let msg of cm?.data;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                            {{ msg?.created_by?.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="msg-item">
                                    <div class="c-content"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        <div class="c-comment" [innerHtml]="msg?.comment | safe:'html'"></div>
                                        <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                                            <mat-icon class="mat-18"
                                                style="font-size: 10px; display: flex; align-items: center;">edit
                                            </mat-icon>
                                            <span style="font-size: 10px;">Edited</span>
                                        </div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === msg?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editComment(msg)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="date"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        {{ msg.created_at?.toDate()|date:'medium' }} ~
                                        {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <form class="editor-block" style="position: relative;" [formGroup]="form">
                        <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
                            customToolbarPosition="top">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected value="roboto">Roboto</option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>

                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>

                                <span class="ql-formats">

                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-align" [title]="'Aligment2'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-color" [title]="'Color'">
                                    </select>
                                    <select class="ql-background" [title]="'BackgroundColor'">
                                    </select>
                                </span>
                                <div class="custom-tb">
                                    <span class="ql-formats">
                                        <button class="ql-bold" [title]="'Bold'"></button>
                                        <button class="ql-italic" [title]="'Italic'"></button>
                                        <button class="ql-underline" [title]="'Underline'"></button>
                                        <button class="ql-strike" [title]="'Strike'"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                    </span>
                                    <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                                        (click)="postComment(form.value, data)">Post
                                    </button>
                                </div>

                            </div>
                        </quill-editor>
                        <div style="height: 68px;"></div>
                    </form>
                </div>
            </mat-tab>

            <mat-tab style="height: 100%; ">
                <ng-template mat-tab-label>
                    Files
                </ng-template>
                <div class="comment-body file-manager">
                    <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate"
                        [value]="pct">
                    </mat-progress-bar>

                    <app-file-view-lists *ngIf="data" [collection]="collection" [key]="id" [data]="data"></app-file-view-lists>
                    
                    <div style="padding: 12px; text-align: center;">

                        <form [formGroup]="formUpload">
                            <a type="button" mat-button class="attach_file upload-button" (click)="fileInput.click()">
                                <mat-icon class="mat-18">attach_file</mat-icon>
                                <span>Add New Attachment</span>
                                <input #fileInput type="file" multiple
                                    accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file
                                    formControlName="file" (change)="onSelectedFile(file.files)"
                                    style="display:none;" />
                            </a>
                        </form>

                    </div>
                </div>
            </mat-tab>


            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    History
                </ng-template>
                <div class="audit-lists">
                    <app-history-listing *ngIf="data" [item]="data" [collection]="collection"></app-history-listing>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="cs-form-container">
            <div class="form-wrapper" style="padding:33px 0;" id="print">
                <div class="hd-row" style="padding:0 33px 16px 33px">
                    <h4 class="hd-title">{{title}}</h4>
                    <div class="flex-1"></div>
                    <div>
                        <ng-container *ngIf="data?.register_item_status && data?.register_item_status.order < 3 && !isHide">

                            <button mat-icon-button (click)="print()" style="margin-right: 8px;" matTooltip="Print" class="hideOnPrint">
                                <mat-icon class="mat-18">print</mat-icon>
                            </button>
    
                            <button mat-icon-button
                                *ngIf="data?.register_item_status && data?.register_item_status.order < 3"
                                (click)="delete(data)" style="margin-right: 8px;" class="hideOnPrint" matTooltip="Delete">
                                <mat-icon class="mat-18">delete</mat-icon>
                            </button>
                            <button *ngIf="data?.register_item_status && data?.register_item_status.order < 3"
                                mat-icon-button [routerLink]="link " style="margin-right: 8px;" class="hideOnPrint"
                                matTooltip="Edit">
                                <mat-icon class="mat-18">edit</mat-icon>
                            </button>
                        </ng-container>
    
    
                        <button mat-icon-button class="hideOnPrint" *ngIf="!isHide" (click)="print()"
                            style="margin-right: 8px;" matTooltip="Print">
                            <mat-icon class="mat-18">print</mat-icon>
                        </button>
    
                        <button mat-icon-button class="hideOnPrint"
                            *ngIf="!isHide && (auth?.userRoles | userPermissionRole:'delete_users') && (collection == 'users')"
                            (click)="delete(data)" style="margin-right: 8px;" matTooltip="Delete">
                            <mat-icon class="mat-18">delete</mat-icon>
                        </button>
                        <!-- <button mat-icon-button class="hideOnPrint"
                            *ngIf="!isHide && (auth?.userRoles | userPermissionRole:'delete_user_roles') && (collection == 'user_roles')"
                            (click)="delete(data)" style="margin-right: 8px;" matTooltip="Delete">
                            <mat-icon class="mat-18">delete</mat-icon>
                        </button> -->
                        <button mat-icon-button class="hideOnPrint" style="margin-right: 8px;" *ngIf="!isHide"
                            [routerLink]="link " matTooltip="Edit">
                            <mat-icon class="mat-18">edit</mat-icon>
                        </button>
                        <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                            <mat-icon class="mat-18">insert_comment</mat-icon>
                        </button>
                    </div>
                    
                </div>

                <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                    <ng-container *ngIf="collection == 'print_templates'">
                        <div class="dl-items">
                            <div class="dl-title"> Company Name (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.company_en?data?.company_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Company Name (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.company_kh?data?.company_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">English Address</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.address_en?data?.address_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Khmer Address</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.address_kh?data?.address_kh:""}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'register_material'">
                        <div class="dl-items">
                            <div class="dl-title"> Code</div>
                            <div class="dl-text">: {{data?.code}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Name</div>
                            <div class="dl-text">: {{data?.name}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">UOM</div>
                            <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'settings_product_type'">
                        <div class="dl-items">
                            <div class="dl-title">Product Name (EN)</div>
                            <div class="dl-text">: {{data?.name?data?.name:"N/A"}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Product Name (KH)</div>
                            <div class="dl-text">: {{data?.name_kh?data?.name_kh:"N/A"}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_route'">
                        <div class="dl-items">
                            <div class="dl-title"> Route Name (EN)</div>
                            <div class="dl-text">: {{data?.name?data?.name:"N/A"}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route Name (KH)</div>
                            <div class="dl-text">: {{data?.name_kh?data?.name_kh:"N/A"}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_title'">
                        <div class="dl-items">
                            <div class="dl-title"> Title Name (EN)</div>
                            <div class="dl-text">: {{data?.name?data?.name:"N/A"}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Title Name (KH)</div>
                            <div class="dl-text">: {{data?.name_kh?data?.name_kh:"N/A"}}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'settings_raw_material'">
                        <div class="dl-items">
                            <div class="dl-title"> English Name</div>
                            <div class="dl-text">: {{data?.name?data?.name:"N/A"}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Name</div>
                            <div class="dl-text">: {{data?.name_kh?data?.name_kh:"N/A"}}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_co'">
                        <div class="dl-items">
                            <div class="dl-title"> CO Number</div>
                            <div class="dl-text">: {{data?.co_number?data?.co_number:''}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CO Date</div>
                            <div class="dl-text" *ngIf="data?.co_date">: {{data?.co_date?.toDate() | date}}</div>
                            <div class="dl-text" *ngIf="!data?.co_date">: </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.register_item?.co_form?data?.register_item?.co_form?.name:''}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Number</div>
                            <div class="dl-text">:
                                {{data?.register_item?.invoice_number?data?.register_item?.invoice_number:''}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Date</div>
                            <div class="dl-text" *ngIf="data?.register_item?.invoice_date">:
                                {{data?.register_item?.invoice_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.register_item?.invoice_date">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Amount</div>
                            <div class="dl-text" *ngIf="data?.register_item?.amount_FOB">:
                                {{data?.register_item?.amount_FOB | number:'1.'}}</div>
                            <div class="dl-text" *ngIf="!data?.register_item?.amount_FOB">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Country</div>
                            <div class="dl-text">:
                                {{data?.register_item?.country?data?.register_item?.country?.name:''}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">: {{data?.register_item?.route?data?.register_item?.route?.name:''}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Packing</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_packing && data?.register_item?.qty_packing_UOM">
                                : {{data?.register_item?.qty_packing | number:'1.'}}
                                {{data?.register_item?.qty_packing_UOM?.description_en}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_packing || !data?.register_item?.qty_packing_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Unit</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_unit && data?.register_item?.qty_unit_UOM">:
                                {{data?.register_item?.qty_unit | number:'1.'}}
                                {{data?.register_item?.qty_unit_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_unit || !data?.register_item?.qty_unit_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (N.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_nw && data?.register_item?.qty_nw_UOM">
                                :
                                {{data?.register_item?.qty_nw | number:'1.'}}
                                {{data?.register_item?.qty_nw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_nw || !data?.register_item?.qty_nw_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (G.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_gw && data?.register_item?.qty_gw_UOM">
                                :
                                {{data?.register_item?.qty_gw | number:'1.'}}
                                {{data?.register_item?.qty_gw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_gw || !data?.register_item?.qty_gw_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_item'">
                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">: {{data?.co_formRef | docRef:'code' | async}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Number</div>
                            <div class="dl-text">: {{data?.invoice_number?data?.invoice_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Date</div>
                            <div class="dl-text" *ngIf="data?.invoice_date">:
                                {{data?.invoice_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.invoice_date">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Amount</div>
                            <div class="dl-text" *ngIf="data?.amount_FOB">:
                                {{data?.amount_FOB | number:'1.'}}</div>
                            <div class="dl-text" *ngIf="!data?.amount_FOB">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Country</div>
                            <div class="dl-text">: {{data?.countryRef | docRef:'name' | async}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">: {{data?.routeRef | docRef:'name' | async}}</div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Material </div>:
                        </div>
                        <div class="scrollable-table" style="margin: 0;">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Code</th>
                                        <th class="table-col ">Description</th>
                                        <th class="table-col ">UOM</th>
                                        <th class="table-col ">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.material;let i=index;">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                        <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                        <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                        <td class="table-col right">{{item?.qty | number}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'pickup_bill'">
                        <div class="dl-items">
                            <div class="dl-title"> Bill Number</div>
                            <div class="dl-text">: {{data?.bill_number?data?.bill_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Bill Date</div>
                            <div class="dl-text" *ngIf="data?.bill_date">: {{data?.bill_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.bill_date">: </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.register_item?.co_form?data?.register_item?.co_form?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Number</div>
                            <div class="dl-text">:
                                {{data?.register_item?.invoice_number?data?.register_item?.invoice_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Date</div>
                            <div class="dl-text" *ngIf="data?.register_item?.invoice_date">:
                                {{data?.register_item?.invoice_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.register_item?.invoice_date">: null</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Amount</div>
                            <div class="dl-text" *ngIf="data?.register_item?.amount_FOB">:
                                {{data?.register_item?.amount_FOB | number:'1.'}}</div>
                            <div class="dl-text" *ngIf="!data?.register_item?.amount_FOB">: null</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Country</div>
                            <div class="dl-text">:
                                {{data?.register_item?.country?data?.register_item?.country?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">: {{data?.register_item?.route?data?.register_item?.route?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Packing</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_packing && data?.register_item?.qty_packing_UOM">
                                : {{data?.register_item?.qty_packing | number:'1.'}}
                                {{data?.register_item?.qty_packing_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_packing || !data?.register_item?.qty_packing_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Unit</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_unit && data?.register_item?.qty_unit_UOM">:
                                {{data?.register_item?.qty_unit | number:'1.'}}
                                {{data?.register_item?.qty_unit_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_unit || !data?.register_item?.qty_unit_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (N.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_nw && data?.register_item?.qty_nw_UOM">
                                :
                                {{data?.register_item?.qty_nw | number:'1.'}}
                                {{data?.register_item?.qty_nw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_nw || !data?.register_item?.qty_nw_UOM">:

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (G.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_gw && data?.register_item?.qty_gw_UOM">
                                :
                                {{data?.register_item?.qty_gw | number:'1.'}}
                                {{data?.register_item?.qty_gw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_gw || !data?.register_item?.qty_gw_UOM">:

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'pickup_inspect'">
                        <div class="dl-items">
                            <div class="dl-title"> Inspect Number</div>
                            <div class="dl-text">: {{data?.inspect_number?data?.inspect_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Inspect Date</div>
                            <div class="dl-text" *ngIf="data?.inspect_date">:
                                {{data?.inspect_date?.toDate() | date}}</div>
                            <div class="dl-text" *ngIf="!data?.inspect_date">: </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.register_item?.co_form?data?.register_item?.co_form?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Number</div>
                            <div class="dl-text">:
                                {{data?.register_item?.invoice_number?data?.register_item?.invoice_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Invoice Date</div>
                            <div class="dl-text" *ngIf="data?.register_item?.invoice_date">:
                                {{data?.register_item?.invoice_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.register_item?.invoice_date">: null</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Amount</div>
                            <div class="dl-text" *ngIf="data?.register_item?.amount_FOB">:
                                {{data?.register_item?.amount_FOB | number:'1.'}}</div>
                            <div class="dl-text" *ngIf="!data?.register_item?.amount_FOB">: null</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Country</div>
                            <div class="dl-text">:
                                {{data?.register_item?.country?data?.register_item?.country?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">: {{data?.register_item?.route?data?.register_item?.route?.name:null}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Packing</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_packing && data?.register_item?.qty_packing_UOM">
                                : {{data?.register_item?.qty_packing | number:'1.'}}
                                {{data?.register_item?.qty_packing_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_packing || !data?.register_item?.qty_packing_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty Unit</div>
                            <div class="dl-text"
                                *ngIf="data?.register_item?.qty_unit && data?.register_item?.qty_unit_UOM">:
                                {{data?.register_item?.qty_unit | number:'1.'}}
                                {{data?.register_item?.qty_unit_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_unit || !data?.register_item?.qty_unit_UOM">:
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (N.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_nw && data?.register_item?.qty_nw_UOM">
                                :
                                {{data?.register_item?.qty_nw | number:'1.'}}
                                {{data?.register_item?.qty_nw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_nw || !data?.register_item?.qty_nw_UOM">:

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Qty (G.W)</div>
                            <div class="dl-text" *ngIf="data?.register_item?.qty_gw && data?.register_item?.qty_gw_UOM">
                                :
                                {{data?.register_item?.qty_gw | number:'1.'}}
                                {{data?.register_item?.qty_gw_UOM?.code}}</div>
                            <div class="dl-text"
                                *ngIf="!data?.register_item?.qty_gw || !data?.register_item?.qty_gw_UOM">:

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'report_templates'">
                        <div class="dl-items">
                            <div class="dl-title"> Name (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name_en?data?.name_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Name (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name_kh?data?.name_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Email</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.email?data?.email:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Phone Number</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.telephone_number?data?.telephone_number:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Address</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.address?data?.address:""}}
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container *ngIf="collection == 'settings_uom'">
                        <div class="dl-items">
                            <div class="dl-title">UOM Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.code?data?.code:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">UOM (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.description_en?data?.description_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> UOM (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.description_kh?data?.description_kh:""}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_department'">
                        <div class="dl-items">
                            <div class="dl-title">Department Name (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name_en?data?.name_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Department Name (KH)</div>
                            <div class="dl-text">:
                                <div class="data">{{data?.name_kh?data?.name_kh:""}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_currency'">
                        <div class="dl-items">
                            <div class="dl-title">Currency Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.code?data?.code:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Currency Name</div>
                            <div class="dl-text">:
                                <div class="data">{{data?.name?data?.name:""}}</div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Symbol</div>
                            <div class="dl-text">:
                                <div class="data">{{data?.symbol?data?.symbol:""}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_coform'">
                        <div class="dl-items">
                            <div class="dl-title">CO Code</div>
                            <div class="dl-text">:
                                <div class="data">{{data?.code?data?.code:""}}</div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">CO Name</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name?data?.name:""}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'settings_country'">
                        <div class="dl-items">
                            <div class="dl-title">Country Code</div>
                            <div class="dl-text">:
                                <div class="data">{{data?.code?data?.code:""}}</div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Country Name</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name?data?.name:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Department</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.department | getReferenceObject: true : 'name_en' | async}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">CO Form</div>
                            <div class="dl-text">:
                                <div class="data">
                                    <ng-container *ngIf="data && data?.co_form && data?.co_form?.length>0">
                                        <ng-container *ngFor="let f of data?.co_form;let i=index; let fs=first;">
                                            {{f | getReferenceObject: fs : 'name'  | async}}
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'settings_country_port'">
                        <div class="dl-items">
                            <div class="dl-title">Country Port Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.code?data?.code:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Country Port Name</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name?data?.name:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Country Name</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.country | getReferenceObject: true :'name' | async}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'settings_forwarder'">
                        <div class="dl-items">
                            <div class="dl-title"> Name (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name_en?data?.name_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Name (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.name_kh?data?.name_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Short Name (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.short_name_en?data?.short_name_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Short Name (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.short_name_kh?data?.short_name_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Email</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.email?data?.email:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">TIN No.</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.tin_no?data?.tin_no:""}}
                                </div>
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">Address</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.address?data?.address:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Country</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.country | getReferenceObject:true : 'name' | async}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Postal Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.postal_code?data?.postal_code:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Link Address</div>
                            <div class="dl-text">:
                                <div class="data">
                                    <a target="_blank" [href]="data?.link_address">
                                        {{data?.link_address?data?.link_address:""}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Phone Number</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.telephone_number?data?.telephone_number:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Fax Number</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.fax_number?data?.fax_number:""}}
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="collection == 'company'">

                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Company Information
                                </ng-template>
                                <div style="padding: 0">
                                    <div class="dl-items">
                                        <div class="dl-title"> Name (EN)</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.name_en?data?.name_en:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Name (KH)</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.name_kh?data?.name_kh:""}}
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="dl-items">
                                        <div class="dl-title">Email</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.email?data?.email:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">TIN No.</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.tin_no?data?.tin_no:""}}
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="dl-items">
                                        <div class="dl-title">English Address</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.address?data?.address:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Khmer Address</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.address_kh?data?.address_kh:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Country</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.country | getReferenceObject:true : 'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Postal Code</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.postal_code?data?.postal_code:""}}
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="dl-items">
                                        <div class="dl-title">Phone Number</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.telephone_number?data?.telephone_number:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Fax Number</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.fax_number?data?.fax_number:""}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Company Users
                                </ng-template>
                                <div style="padding: 0">
                                 

                                    <div class="scrollable-table" style="margin: 0;">
                                        <table class="table-wrapper tb-master">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col " style="width:60px">No.</th>
                                                    <th class="table-col ">User Name</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of data?.companyUsers;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">
                                                        {{item?.full_name}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>

                    </ng-container>

                    <ng-container *ngIf="collection == 'hs_codes'">
                        <div class="dl-items">
                            <div class="dl-title">Code</div>
                            <div class="dl-text">:
                                <div class="data"> {{data?.code?data?.code:""}}</div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">UOM</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.uom?.description_en?data?.uom?.description_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Description (EN)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.description_en?data?.description_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Description (KH)</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.description_kh?data?.description_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CD</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.cd?data?.cd:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> ST</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.st?data?.st:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> VAT</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.vat?data?.vat:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> ET</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.et?data?.et:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Sub Item Of</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.subItem?data?.subItem.code:""}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'items'">
                        <div class="dl-items">
                            <div class="dl-title">Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.code?data?.code:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Bar Code</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.barCode?data?.barCode:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Item Type</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.itemType?data?.itemType?.text:""}}
                                </div>
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">Description (EN)</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.name_en?data?.name_en:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Description (KH)</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.name_kh?data?.name_kh:""}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">UOM</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.uom | getReferenceObject:true : 'description_en' | async}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Price</div>
                            <div class="dl-text">:
                                <div class="data">
                                    {{data?.price?data?.price:"" | currency}}
                                </div>
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Sub Item Of</div>
                            <div class="dl-text">
                                :
                                <div class="data">
                                    {{data?.subItem?data?.subItem.code:""}}
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="collection == 'user_roles'">
                        <div style="padding: 0">
                            <div class="dl-items">
                                <div class="dl-title">Title</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.title}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Description</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.description}}
                                    </div>
                                </div>
                            </div>

                            <div class="scrollable-table" style="margin: 0;">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col ">No.</th>
                                            <th class="table-col ">Application</th>
                                            <th class="table-col ">Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of data?.permissions;let i=index">
                                            <td class="table-col">
                                                <span class="dot-status"
                                                    [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}</td>
                                            <td class="table-col ">
                                                {{item?.text}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.name}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="collection == 'users'">
                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    User Information
                                </ng-template>

                                <div style="padding: 0">
                                    <div class="dl-items">
                                        <div class="dl-title">Full name</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.full_name?data?.full_name:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Email</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.email?data?.email:""}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Phone Number</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.phone?data?.phone:""}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-items">
                                        <div class="dl-title">Position</div>
                                        <div class="dl-text">
                                            :
                                            <div class="data">
                                                {{data?.position?data?.position:""}}
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Select Companies
                                </ng-template>

                                <div style="padding: 0;">
                                    <ng-container *ngIf="data && data?.company && data?.company?.length>0">
                                        <div class="scrollable-table" >
                                            <table class="table-wrapper  tb-master">
                                                <tr class="table-row">
                                                    <th class="table-col " style="width:50px">
                                                        No.
                                                    </th>
                                                    <th class="table-col ">
                                                        Company name
                                                    </th>
                                             
                                                </tr>
                                                <ng-container *ngFor="let f of data?.company; let i=index">
          
                                                    <tr class="table-row">
                                                        <td class="table-col">
                                                            {{i+1}}
                                                        </td>
                                                        <td class="table-col">
                                                            <span>                                                         
                                                               {{f | docRef:'name_en'  | async}}
                                                           </span>
                                                        </td>
                                                    </tr>
                                              
                                                </ng-container>
                                          
                                            </table>
                                        </div>
                                    </ng-container>
                                 </div>
                            </mat-tab>


                            <mat-tab>
                                <ng-template mat-tab-label>
                                    User Roles
                                </ng-template>
                                <div style="padding: 0;">
                                   <div class="scrollable-table" >
                                    <table class="table-wrapper  tb-master">
                                        <tr class="table-row">
                                            <th class="table-col " style="width:50px">
                                                No.
                                            </th>
                                            <th class="table-col ">
                                                Roles
                                            </th>
                                            <th class="table-col ">
                                                Permissions
                                            </th>
                                        </tr>
                                        <ng-container *ngFor="let item of userRoles; let i=index">
  
                                            <tr class="table-row">
                                                <td class="table-col" [rowSpan]="item?.permissions?.length + 1">
                                                    {{i+1}}
                                                </td>
                                                <td class="table-col" [rowSpan]="item?.permissions?.length + 1">
                                                    <span> {{item?.title}}</span>
                                           
                                                </td>
                                            </tr>
                                            <tr class="table-row" *ngFor="let p of item?.permissions">
                                                <td class="table-col ">
                                                    <span>{{p?.name}} {{p?.text}}</span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                  
                                    </table>
                                </div>
                              
                                  
                                    


                                </div>
                            </mat-tab>


                        </mat-tab-group>
                    </ng-container>

                    <div class="dl-items">
                        <div class="dl-title">Status</div>
                        <div class="dl-text">:
                            <div class="data">{{data?.status?data?.status?.text:""}}</div>
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">Last updated</div>
                        <div class="dl-text">:
                            <div class="data">{{data?.updated_at?.toDate()| date:'medium'}}</div>
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">Updated by</div>
                        <div class="dl-text">:
                            <div class="data">{{data?.updated_by?.displayName}}</div>
                        </div>
                    </div>

                </div>
                <ng-container *ngIf="store?.process">
                    <div style="padding: 33px;">
                        <app-line-loading></app-line-loading>
                        <app-line-loading></app-line-loading>
                    </div>
                </ng-container>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- <div class="one" #one></div> -->
<!-- <iframe src="https://drive.google.com/viewerng/viewer?url=https://library.osu.edu/assets/Documents/SEL/QuickConvertWordPDF.pdf?pid=explorer&efh=false&a=v&chrome=false&embedded=true" width="400px" height="300px"/> -->