import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { sum } from 'src/app/services/convert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-registration-detail-print',
  templateUrl: './registration-detail-print.component.html',
  styleUrls: ['./registration-detail-print.component.scss'],
})
export class RegistrationDetailPrintComponent implements OnInit {
  printDate: Date;
  COLUMNS: any = null;
  soo_info;
  export_info;
  totalAmountFOBSOO: number = 0;
  totalQtyCNT: number = 0;
  totalQtyPCS: number = 0;
  totalAmountFOB: number = 0;
  totalQtyUnit: number = 0;
  totalQtyPacking: number = 0;
  total: number = 0;
  selectedTemplate = null;
  CoMaterialPrintLists = [
    {key:1, text:'CO Material'},
    {key:2, text:'Customs Material'},
  ]
  selectedItem;
  constructor(
    public dialogRef: MatDialogRef<RegistrationDetailPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps: PrintService,
    public auth: Auth,
    private store:SettingStore

  ) { }

  ngOnInit() {
    this.selectedItem = this.CoMaterialPrintLists[0]
    
    if(this.data?.printTemplateData){
      this.selectedTemplate =  this.data?.printTemplateData[0];
    }
    if (this.data.collection === 'register_soo_statement') {

      this.auth.fetchColumns('SooStatement_Column_Print', doc => {
        if (doc) {
          this.COLUMNS = doc;
          let arr = [],
            keys = Object.keys(this.COLUMNS);

          for (let i = 0, n = keys.length; i < n; i++) {
            let key = keys[i];
            arr.push(this.COLUMNS[key]);
          }
          if (this.COLUMNS) {
            this.soo_info = arr.filter(f => f.is_soo == true && f.value == true)
            this.export_info = arr.filter(f => f.is_soo == false && f.value == true)
            const gettotal = this.export_info.filter(f => f.name == 'INSPECT DATE' || f.name == 'INSPECT NO' || f.name == 'Amount(FOB)' || f.name == 'DESTINATION' || f.name == "Quality(PCS)" || f.name == "Quality(CTN)")
            this.total = this.export_info.length - gettotal.length
          }
        }
      })
    }

    if (this.data.collection === 'register_soo') {
      this.totalAmountFOB = sum(this.data.item.items, "amount_FOB");
      this.totalQtyUnit = sum(this.data.item.items, "qty_unit");
      this.totalQtyPacking = sum(this.data.item.items, "qty_packing");
      this.totalAmountFOBSOO = sum(this.data.item.items, "amount");
      this.totalQtyCNT = sum(this.data.item.items, "quality_cnt");
      this.totalQtyPCS = sum(this.data.item.items, "quality_pcs");
      this.auth.fetchColumns('Soo_Column_Print', doc => {
        if (doc) {
          
          this.COLUMNS = doc;
          let arr = [],
            keys = Object.keys(this.COLUMNS);

          for (let i = 0, n = keys.length; i < n; i++) {
            let key = keys[i];
            arr.push(this.COLUMNS[key]);
          }
          if (this.COLUMNS) {
            this.soo_info = arr.filter(f => f.is_soo == true && f.value == true)
            this.export_info = arr.filter(f => f.is_soo == false && f.value == true)
            const gettotal = this.export_info.filter(f => f.name == 'INSPECT DATE' || f.name == 'INSPECT NO' || f.name == 'Amount(FOB)' || f.name == 'DESTINATION' || f.name == "Quality(PCS)" || f.name == "Quality(CTN)")
            this.total = this.export_info.length - gettotal.length
          }

          // const {soo_no, soo_date} = this.COLUMNS
        }
      })
    }



    this.printDate = new Date();

  }

  _onSelectedItem(item){
    this.selectedItem = item;
  }

  onSelectTemplate(item){
    this.selectedTemplate = item;
  }
  print() {
    if (this.data.collection === 'register_soo_statement' || this.data.collection === 'register_soo') {
      this.ps.print('#printms', 'a4l');
    } else {
      this.ps.print('#printms', 'a4');
    }
    this.store.printHistory(this.data?.item, this.auth.user, success=>{})
  }

}
