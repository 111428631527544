import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { DATA_TYPE } from 'src/app/dummy/columns';
import { FilterStore } from 'src/app/stores/filter.store';
import { MappingService, lastWeek } from 'src/app/services/mapping.service';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { statusLists } from 'src/app/dummy/status';

@Component({
  selector: 'app-filter-submit-listing',
  templateUrl: './filter-submit-listing.component.html',
  styleUrls: ['./filter-submit-listing.component.scss']
})
export class FilterSubmitListingComponent implements OnInit {

  @Input() filterOptions: any[];
  @Input() companyKey: any;
  @Input() itemStatusKey: any;
  @Output() onFilter = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Input() filterRef: any;
  @Input() typeKey: any;

  form: FormGroup
  option: AbstractControl
  search: AbstractControl
  fromDate: AbstractControl
  toDate: AbstractControl
  includeDate: AbstractControl

  public controlType: any;
  public DATA_TYPE = DATA_TYPE
  constructor(private fb: FormBuilder,
    public store: FilterStore) { }

  ngOnInit() {
    this.form = this.fb.group({
      option: [null, Validators.required],
      search: [null, Validators.compose([MappingService.validSelected.bind(this)])],
      includeDate: [false, Validators.required],
      fromDate: [{ value: lastWeek(), disabled: true }],
      toDate: [{ value: new Date(), disabled: true }]
    })
    this.search = this.form.controls["search"]
    this.includeDate = this.form.controls["includeDate"]
    this.option = this.form.controls["option"]
    this.fromDate = this.form.controls["fromDate"]
    this.toDate = this.form.controls["toDate"]

    if (this.filterOptions.length > 0) {
      this.controlType = this.filterOptions[0];
      this.option.patchValue(this.filterOptions[0])
      this.findControlOption(this.filterOptions[0])

    }
  }

  clear() {
    this.search.patchValue(null)
    this.includeDate.patchValue(false)
    this.onClear.emit()
  }

  displayItem(item: any): string {
    return item ? item[this.controlType ? this.controlType.displayField : 'name'] : item;
  }

  _onFilter(form) {
    this.onFilter.emit({
      ...form,
      filterOption: this.controlType
    })
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  onChanged(event) {
    const { checked } = event;
    if (checked) {
      this.fromDate.enable()
      this.toDate.enable()
    }
    else {
      this.fromDate.disable()
      this.toDate.disable()
    }
  }

  onSelectionChange(event) {
    const { value } = event;
    if (value && this.filterRef) {
      this.findControlOption(value)
      this.search.patchValue(null)
    }
  }

  findControlOption(value: any) {
    const { name } = value;
    const filterOption = this.filterRef[name];
    this.controlType = filterOption;
    const { collection, setting, key, field, type } = filterOption;
    const comKey = !setting ? this.companyKey || null : null;
    switch (filterOption.type) {
      case DATA_TYPE.option:
        this.store.fetchDataByType(comKey, collection, this.itemStatusKey, setting, this.typeKey, docs => { })
        this.search.valueChanges.pipe(
          debounceTime(300),
          tap(() => (false)),
          switchMap(value => this.store.fetchFilterOptionsByType(value, collection, field, comKey, type, this.itemStatusKey, this.typeKey, docs => {
            if (!value) {
              const val = !value ? { required: true } : null;
              return val;
            }
            const val = docs.length > 0 ? { nameAvailable: true } : null;
            return val;
          }))).subscribe(res => { });
        break;
      case 'status':
        this.store.data = statusLists
        break;
      default:
        break;
    }
  }

}
