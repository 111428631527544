
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MappingService } from 'src/app/services/mapping.service';
@Component({
  selector: 'app-group-cut-stock-material',
  templateUrl: './group-cut-stock-material.component.html',
  styleUrls: ['./group-cut-stock-material.component.scss']
})
export class GroupCutStockMaterialComponent implements OnInit {

  @Input() key: any;
  @Input() data: any;
  materials: any = [];
  group: any = [];
  constructor() { }

  async ngOnInit() {
    if (this.data?.materials) {
      this.materials = this.data.materials || [];
      this.group = MappingService.groupBy(this.materials, "materialKey", "materialKey");
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue) {
      this.materials = changes.data.currentValue.materials || [];
      this.group = MappingService.groupBy(this.materials, "materialKey", "materialKey");
    }
  }


}

@Component({
  selector: 'app-local-group-cut-stock-material',
  templateUrl: './group-cut-stock-material.component.html',
  styleUrls: ['./group-cut-stock-material.component.scss']
})
export class LocalGroupCutStockMaterialComponent implements OnInit {

  @Input() key: any;
  @Input() data: any;
  materials: any = [];
  group: any = [];
  constructor() { }

  async ngOnInit() {
    if (this.data?.materials) {
      this.materials = this.data.materials || [];
      this.group = MappingService.groupBy(this.materials, "materialKey", "materialKey");
    }
  }
  ngOnChanges(changes: SimpleChanges) {

    if (changes?.data?.currentValue) {
      this.materials = changes.data.currentValue.materials || [];
      this.group = MappingService.groupBy(this.materials, "materialKey", "materialKey");
    }

  }

}
