import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConvertService, pushToArray } from '../services/convert.service';
import { APPS } from '../dummy/config';

@Injectable({ providedIn: 'root' })
export class FileManagerStore {
    @observable data: any = [];
    @observable process: boolean;
    @observable loading: boolean;
    selectedFiles: any;
    uploadFolder;
    uploads: any[];

    allPercentage: Observable<any>;
    files: Observable<any>;

    @observable lastVisible: any = null;
    @observable filterStatus: any = 0;
    @observable fetching: boolean = false;
    @observable orderBy: any = "code";
    @observable done: boolean = false;
    @observable empty: boolean = false;

    @observable folder: any = null;
    @observable companyKey: any = null;
    @observable filterForm: any = null;


    constructor(
        private afs: AngularFirestore,
        private storage: AngularFireStorage,
        private ds: DataService
    ) {

    }

    @action
    addFolder(item: any, callback) {
        this.process = true;
    }
    @action
    fetchFileDataByCompany(companyKey, folder, callback) {
        this.loading = true;
        this.ds.flileByCompany(companyKey, folder).valueChanges().subscribe(data => {
            if (data) {
                this.data = data;
                callback(data)
            }
            this.loading = false
        })
    }
    @action
    fetchFileData(folder, callback) {
        this.loading = true;
        this.ds.flileSettingRef(folder).valueChanges().subscribe(data => {
            if (data) {
                this.data = data;
                callback(data)
            }
            this.loading = false
        })
    }


    @action
    async fetchLazyFile(folder, filterForm: any, companyKey, callback) {
        //   this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.fetching = false;
        this.companyKey = companyKey;
        this.folder = folder;
        const docsData = await this.ds.lazyFileManagerRef(folder, this.lastVisible, filterForm, companyKey).get().toPromise();
        const docs = pushToArray(docsData)

        if (!docsData.empty) {
            this.lastVisible = docs[docs.length - 1];
        } else {
            this.lastVisible = null;
            this.done = true;
        }
        this.data = docs;
        this.empty = docsData.empty;
        this.loading = false;
        callback(this.data);
    }



    @action
    async fetchLazyFileSearch(filterForm, callback) {
        //   this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.fetching = false;
        const docsData = await this.ds.lazyFileManagerRef(this.folder, this.lastVisible, filterForm, this.companyKey).get().toPromise();
        const docs = pushToArray(docsData)
        if (!docsData.empty) {
            this.lastVisible = docs[docs.length - 1];
        } else {
            this.lastVisible = null;
            this.done = true;
        }
        this.data = docs;
        this.empty = docsData.empty;
        this.loading = false;
        callback(this.data);
    }


    @action
    async fetchLazyFileMore(callback) {
        if (this.fetching || this.lastVisible === null || this.loading) { return; }
        this.fetching = true;
        const docsData = await this.ds.lazyFileManagerRef(this.folder, this.lastVisible, this.filterForm, this.companyKey).get().toPromise();
        const docs = pushToArray(docsData);
        if (docs.length > 0 && docs.length === APPS.settingSize) {
            this.lastVisible = docs[docs.length - 1];
        } else {
            this.done = true;
            this.lastVisible = null;
        }
        this.data = this.data.slice().concat(docs);
        callback(this.data);

        this.fetching = false;
    }

    @action
    deleteFile(collection, filename) {
        const fileRef = this.storage.ref('settings' + '/' + collection + '/' + filename);
        fileRef.delete()
    }


    @action
    deleteFileData(collection, data, callback) {
        const batch = this.ds.batch()
        const fileRef = this.ds.settingFireRef(collection).doc(data.refKey).collection('files').doc(data.key)
        const gFileRef = this.ds.flileFireRef().doc(data.key)
        batch.delete(fileRef)
        batch.delete(gFileRef)
        batch.commit().then(() => {
            this.deleteFile(collection, data.filename)
            this.process = false;
            callback(true, null);
        }).catch((error) => {
            this.process = false;
            callback(false, error);
        })
    }



    @action
    deleteFileDataC(company, collection, companyKey, data, callback) {
        const batch = this.ds.batch()
        const fileRef = this.ds.settingFireRef(collection).doc(data.refKey).collection('files').doc(data.key)
        const gFileRef = this.ds.flileFireRefByCompany(companyKey).doc(data.key)
        batch.delete(fileRef)
        batch.delete(gFileRef)
        batch.commit().then(() => {
            this.deleteFileByCompany(company, collection, data.filename)
            this.process = false;
            callback(true, null);
        }).catch((error) => {
            this.process = false;
            callback(false, error);
        })
    }
    @action
    addFileManager(route: string, collection: any, refKey: any, user: any, filelist: any, callback) {
        if (filelist) {
            this.process = true;
            this.uploads = [];

            const allPercentage: Observable<number>[] = [];
            for (const file of filelist) {
                // const fkey = this.afs.createId();
                const batch = this.ds.batch();

                const filename = Math.random().toString(36).substring(7) + new Date().getTime() + '-' + file.name;
                const path = `${'settings'}/${collection}/${filename}`;
                // const path = `filemanager/${type.key}/${filename}`;
                const ref = this.storage.ref(path);
                const task = this.storage.upload(path, file);
                const _percentage$ = task.percentageChanges();
                allPercentage.push(_percentage$);

                const uploadTrack = {
                    fileName: filename,
                    percentage: _percentage$
                }
                this.uploads.push(uploadTrack);
                const key = this.afs.createId();
                // const fileRef = this.ds.settingFireRef(collection).doc(refKey).collection('files').doc(key)
                // const filemanagerRef = this.ds.flileFireRef().doc(key)
                const _t = task.then((f) => {
                    f.ref.getDownloadURL().then((url) => {
                        let fileType = file.type.split('/').slice(0, -1).join('/')
                        const name = ConvertService.toCapitalize(`${file.name}`)
                        const keyword = generateKeywords([name]);

                        const data = {
                            key: key,
                            keyword: keyword,
                            filename: filename,
                            originname: file.name,
                            downloadUrl: url,
                            type: file.type,
                            fileType: fileType,
                            fileSize: file.size,
                            created_at: new Date(),
                            created_by: user,
                            folder: collection,
                            route: route,
                            refKey: refKey,
                            refDoc: this.ds.settingCollectionRef(collection).doc(refKey).ref
                        }
                        batch.set(this.ds.settingRef(collection).doc(refKey).collection('files').doc(key).ref, data, {merge:true})
                        batch.set(this.ds.flileRef().doc(key).ref, data, {merge:true})
                        batch.commit().then(() => {
                            this.process = false;
                            callback(true, null);
                        }).catch(error => {
                            this.process = false;
                            callback(false, error);
                        });

                    })
                })
            }
            this.allPercentage = combineLatest(allPercentage)
                .pipe(
                    map((percentages) => {
                        let result = 0;
                        for (const percentage of percentages) {
                            result = result + percentage;
                        }
                        return result / percentages.length;
                    }),
                    tap()
                );
        }
        callback(true, null);
    }


    @action
    addFileManagerC(route: string, collection: any, company, companyKey, refKey: any, user: any, filelist: any, callback) {
        if (filelist) {
            this.process = true;
            this.uploads = [];

            const allPercentage: Observable<number>[] = [];
            for (const file of filelist) {
                const batch = this.ds.batch();

                // const fkey = this.afs.createId();
                const filename = Math.random().toString(36).substring(7) + new Date().getTime() + '-' + file.name;
                const path = `${company}/${collection}/${filename}`;
                // const path = `filemanager/${type.key}/${filename}`;
                const ref = this.storage.ref(path);
                const task = this.storage.upload(path, file);
                const _percentage$ = task.percentageChanges();
                allPercentage.push(_percentage$);

                const uploadTrack = {
                    fileName: filename,
                    percentage: _percentage$
                }
                this.uploads.push(uploadTrack);
                const key = this.afs.createId();
                // const fileRef = this.ds.settingFireRef(collection).doc(refKey).collection('files').doc(key)
                // const filemanagerRef = this.ds.flileFireRef().doc(key)
                const _t = task.then((f) => {
                    f.ref.getDownloadURL().then((url) => {
                        let fileType = file.type.split('/').slice(0, -1).join('/')
                        const name = ConvertService.toCapitalize(`${file.name}`)
                        const keyword = generateKeywords([name]);

                        const data = {
                            key: key,
                            keyword: keyword,
                            filename: filename,
                            originname: file.name,
                            downloadUrl: url,
                            type: file.type,
                            fileType: fileType,
                            fileSize: file.size,
                            created_at: new Date(),
                            created_by: user,
                            folder: collection,
                            route: route,
                            refKey: refKey,
                            refDoc: this.ds.settingCollectionRef(collection).doc(refKey).ref
                        }

                        batch.set(this.ds.settingRef(collection).doc(refKey).collection('files').doc(key).ref, data ,{merge:true})
                        batch.set(this.ds.flileRefByCompany(companyKey).doc(key).ref, data, {merge:true})
                        batch.commit().then(() => {
                            this.process = false;
                            callback(true, null);
                        }).catch(error => {
                            this.process = false;
                            callback(false, error);
                        });


                    })
                })
            }
            this.allPercentage = combineLatest(allPercentage)
                .pipe(
                    map((percentages) => {
                        let result = 0;
                        for (const percentage of percentages) {
                            result = result + percentage;
                        }
                        return result / percentages.length;
                    }),
                    tap()
                );
        }
        callback(true, null);
    }

    @action
    deleteFileByCompany(company, collection, filename) {
        const fileRef = this.storage.ref(company + '/' + collection + '/' + filename);
        fileRef.delete()
    }

    @action
    deleteFileDataByCompany(company, collection, companyKey, data, callback) {
        const batch = this.ds.batch()
        let collectionRef = collection;
        if (collectionRef == 'register_submit_crf') {
            collectionRef = 'register_submit_form'
        }
        const fileRef = this.ds.settingFireByCompanyRef(collectionRef, companyKey).doc(data.refKey).collection('files').doc(data.key)
        const gFileRef = this.ds.flileFireRefByCompany(companyKey).doc(data.key)
        batch.delete(fileRef)
        batch.delete(gFileRef)
        batch.commit().then(() => {
            this.deleteFileByCompany(company, collection, data.filename)
            this.process = false;
            callback(true, null);
        }).catch((error) => {
            this.process = false;
            callback(false, error);
        })
    }
    @action
    addFileManagerByCompany(route: string, company, collection: any, companyKey, refKey: any, user: any, filelist: any, callback) {
        let collectionRef = collection;
        if (collectionRef == 'register_submit_crf') {
            collectionRef = 'register_submit_form'
        }
        if (filelist) {
            this.process = true;
            this.uploads = [];
            const batch = this.ds.batch();

            const allPercentage: Observable<number>[] = [];
            for (const file of filelist) {
                // const fkey = this.afs.createId();
                const filename = Math.random().toString(36).substring(7) + new Date().getTime() + '-' + file.name;
                const path = `${company}/${collection}/${filename}`;
                // const path = `filemanager/${type.key}/${filename}`;
                const ref = this.storage.ref(path);
                const task = this.storage.upload(path, file);
                const _percentage$ = task.percentageChanges();
                allPercentage.push(_percentage$);

                const uploadTrack = {
                    fileName: filename,
                    percentage: _percentage$
                }
                this.uploads.push(uploadTrack);
                const key = this.afs.createId();
                const _t = task.then((f) => {
                    f.ref.getDownloadURL().then((url) => {
                        let fileType = file.type.split('/').slice(0, -1).join('/')
                        const name = ConvertService.toCapitalize(`${file.name}`)
                        const keyword = generateKeywords([name]);
                        const data = {
                            key: key,
                            keyword: keyword,
                            filename: filename,
                            originname: file.name,
                            downloadUrl: url,
                            type: file.type,
                            fileType: fileType,
                            fileSize: file.size,
                            created_at: new Date(),
                            created_by: user,
                            folder: collection,
                            route: route,
                            refKey: refKey,
                            refDoc: this.ds.registerCollectionRef(companyKey, collectionRef).doc(refKey).ref
                        }
                        this.ds.settingFireByCompanyRef(collectionRef, companyKey).doc(refKey).collection('files').doc(key).set(data).then(() => {
                            this.ds.flileRefByCompany(companyKey).doc(key).set(data).then(() => {
                                this.process = false;
                                callback(true, null);
                            }).catch(error => {
                                this.process = false;
                                callback(false, error);
                            });
                        }).catch(error => {
                            this.process = false;
                            callback(false, error);
                        });

                    })
                })
            }
            this.allPercentage = combineLatest(allPercentage)
                .pipe(
                    map((percentages) => {
                        let result = 0;
                        for (const percentage of percentages) {
                            result = result + percentage;
                        }
                        return result / percentages.length;
                    }),
                    tap()
                );
        }
        callback(true, null);
    }

}


const createKeywords = name => {
    const arrName = [];
    let curName = '';
    name.split('').forEach(letter => {
        curName += letter;
        arrName.push(curName);
    });
    return arrName;
}

const generateKeywords = names => {
    const [keyword] = names;
    const suffix = keyword.length > 0 ? `${keyword}` : '';
    const keywordName = createKeywords(`${suffix}`);
    return [
        ...new Set([
            '~N/A~',
            ...keywordName
        ])
    ];
}
