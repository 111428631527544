import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Auth } from 'src/app/stores/auth.store';
import { DataService } from 'src/app/services/data.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { pushToObject } from 'src/app/services/convert.service';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  authSubscription: Subscription;
  idleSubscription: Subscription;


  constructor(
    private ds: DataService,
    public auth: Auth,
    private afsAuth: AngularFireAuth,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {
    this.auth.fetchCanActive();
  }

  async ngOnInit() {

    await this.auth.fetchSelectedCompany();
    const { selectedCompany } = this.auth;

    if (!selectedCompany) {
      this.router.navigate(['/login/choose-company'])
    }
    this.authSubscription = this.afsAuth.authState.subscribe(user => {
      if (user) {
        setTimeout(() => {
          this.subscription = this.ds.userprofileRef().doc(user.uid).valueChanges().subscribe(async (uData: any) => {
            if (uData) {
              // console.log('user', user);
              
              if (uData.session.refreshToken !== user.refreshToken) {
                this.auth.signOut(success => {
                  if (success) {
                    this.auth.isLoginByOtherDevice = true;
                    this.router.navigate(['/login'])
                  }
                })
              } else {
                this.auth.isLoginByOtherDevice = false;
              }

              const { selected_company } = uData;
              if (selected_company && selected_company?.key) {
                const companyData = pushToObject(await this.ds.collectionFireRef('company').doc(selected_company?.key).get())
                if (companyData?.status?.key === 2) {
                  const uRef = this.ds.userFireRef().doc(uData?.key);
                  uRef.update({ selected_company: null }).then(() => {
                    this.auth.signOut(() => {
                      this.router.navigate(['/login'])
                    });
                  }).catch(error => {
                    console.log('error', error);
                  }).finally(() => { })
                }
              }

            }
          })
        }, 5000);
      }
    })

  }
  ngAfterViewInit(): void {
    this.idleSubscription = this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      console.log('session expired', isTimedOut);
      if (isTimedOut) {
        this.onLogOut();
      }
    });
  }



  onLogOut() {
    const uRef = this.ds.userFireRef().doc(this.auth.user.uid);
    uRef.update({ selected_company: null }).then(() => {
      this.auth.signOut(() => {
        this.router.navigate(['/login'])
      });
    }).catch(error => {
      console.log('error', error);
    }).finally(() => { })
  }
  ngOnDestroy() {
    this.authSubscription && this.authSubscription.unsubscribe();
    this.subscription && this.subscription.unsubscribe();
    this.idleSubscription && this.idleSubscription.unsubscribe();
    this.auth.subscriptionUser && this.auth.subscriptionUser.unsubscribe();
    this.auth.subscriptionUserRole && this.auth.subscriptionUserRole.unsubscribe();
    this.bnIdle.resetTimer();
    this.bnIdle.stopTimer();
  }

}
