import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-not-action',
  templateUrl: './confirm-not-action.component.html',
  styleUrls: ['./confirm-not-action.component.scss']
})
export class ConfirmNotActionComponent implements OnInit {

  msg: any;
  constructor(public dialogRef: MatDialogRef<ConfirmNotActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.msg = this.data;
  }

  onClose() {
    this.dialogRef.close('no')
  }

}
