
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { NetInfoComponent } from './components/net-info/net-info.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { SignupComponent } from './pages/auth/signup/signup.component';
import { AuthlayoutComponent } from './shared/authlayout/authlayout.component';
import { ChooseCompanyComponent } from './pages/choose-company/choose-company.component'
import { TabLayoutComponent } from './shared/tab-layout/tab-layout.component';
import { ItemListInputComponent } from './components/item-list-input/item-list-input.component';
import { OptionsComponent } from './pages/settings/options/options.component';
import { ResponsiveModule } from 'ngx-responsive'
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FileManagerComponent } from './pages/file-manager/file-manager.component';
import { FileDataComponent } from './pages/file-manager/file-data/file-data.component';
import { QuillModule } from "ngx-quill";
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './cores/core.module';
import { AppSharedModule } from './shared/shared.module';
import { StockReleaseDateComponent } from './components/stock-release-date/stock-release-date.component';
import { DynamicPrintOptionComponent } from './pages/dynamic-print/dynamic-print-option/dynamic-print-option.component';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    LayoutComponent,
    NetInfoComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    SignupComponent,
    AuthlayoutComponent,
    ChooseCompanyComponent,
    TabLayoutComponent,
    ItemListInputComponent,
    OptionsComponent,
    FilePreviewComponent,
    FileManagerComponent,
    FileDataComponent,
    StockReleaseDateComponent,
    DynamicPrintOptionComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AppSharedModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    ResponsiveModule.forRoot(),
    QuillModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // UserIdleModule.forRoot({idle: 30, timeout: 30, ping: 120})
  ],
  providers: [
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
