import { Component, OnInit, Inject } from '@angular/core';
import { MappingService, toNumber } from 'src/app/services/mapping.service';
import { sum, pushToObject } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-check-stock-balance',
  templateUrl: './check-stock-balance.component.html',
  styleUrls: ['./check-stock-balance.component.scss']
})
export class CheckStockBalanceComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CheckStockBalanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ds: DataService
  ) { }

  materials = [];
  canSubmit = true;
  async ngOnInit() {
    const {isRoleBack, roleBackType} = this.data;
    const materialData = MappingService.groupBy(this.data.materials, "materialKey", "materialKey");
    this.materials = await Promise.all(materialData.map(async (m) => {
      const { companyKey, materialKey } = m
      const materialDocs = await this.ds.settingRefByCompany("register_material", companyKey).doc(materialKey).get().toPromise();
      let materialData = pushToObject(materialDocs);
      const totalQty = sum(this.data.materials.filter(d => d.materialKey === m.materialKey), 'qty');
      
      let balance = (roleBackType?.key == 1)?toNumber(materialData?.stockQty) - toNumber(totalQty):toNumber(materialData?.stockQty) + toNumber(totalQty);
      if(isRoleBack && roleBackType && roleBackType.key === 1){
        balance = toNumber(materialData.stockQty) + toNumber(totalQty);
      }
      if(isRoleBack && roleBackType && roleBackType.key === 2){
        balance = toNumber(materialData.stockQty) - toNumber(totalQty);
      }
      return {
        ...m,
        totalQty: totalQty,
        stockQty: toNumber(materialData.stockQty),
        balance: balance,
        validStock: balance >= 0 ? true : false,
      }
    }))

    this.canSubmit = this.materials.filter(m => !m.validStock).length > 0 ? false : true;

  }

  onSubmit() {
    this.canSubmit = this.materials.filter(m => !m.validStock).length > 0 ? false : true;
    // if (this.canSubmit && this.data.isValidDate) {
    this.dialogRef.close('yes')
    // }
  }

  onClose() {
    this.dialogRef.close('no')
  }

}
