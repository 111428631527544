<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <!-- <div class="dialog-header">
        <div class="title">
            Filter options
        </div>
    </div> -->
    <div class="dialog-content">
        <div class="form-wrapper" [formGroup]="form">
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>CO Material</mat-label>
                    <input type="text" #focusInput placeholder="Select material" aria-label="" matInput
                        formControlName="material" [matAutocomplete]="autoProduct">
                    <mat-autocomplete (optionSelected)="onOptionSelected($event)" #autoProduct="matAutocomplete"
                        [displayWith]="displayItem">
                        <mat-option *ngFor="let option of MATERIAL_LIST" [value]="option">
                            <span style="display: flex; flex-direction: row;">
                                <span style="flex: 1;">{{ option?.code}}<span *ngIf="option?.code">-</span>{{option?.name}}</span>
                                <!-- <strong>{{option?.stockQty |number: '.2-2'}} Stock</strong> -->
                            </span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.controls['material'].hasError('required')">
                        <div>This field is required.</div>
                    </mat-error>
                    <mat-error *ngIf="form.controls['material'].hasError('validKey')">
                        <div>You can only selected material from list.</div>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>From date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerFromDate" placeholder="From date"
                        formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>To date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerToDate" placeholder="To date"
                        formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerToDate></mat-datepicker>

                    <mat-error *ngIf="form.controls['toDate'].hasError('nameAvailable')"
                    class="error-message">
                    To date filter can't be smaller then from date filter.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <app-primary-button (onPress)="_onFilter(form.value)" [disable]="!form.valid" label="Search">
        </app-primary-button>
        <button mat-button class="mat-puc cancel" (click)="clear(form.value)">
            Clear
        </button>
    </div>
</form>