<div class="task-wrapper chapternav">
    <!-- <mat-progress-bar *ngIf="env?.loading" class="top-nav-progress" mode="indeterminate"></mat-progress-bar> -->
    <div class="search-engine">
        <h2>Quick Search</h2>

        <div class="search-options-lists">
            <button mat-button (click)="onSelectFilter(item)" class="enbutton primary" [class.active]="item?.key === selectedFilter?.key" *ngFor="let item of filterBy">
                {{ item.text }}
            </button>
        </div>
        <form [formGroup]="form" class="search-nav" [ngClass]="{ active: formFocus }">
            <!-- <div class="enroll-filter">
                <mat-select (selectionChange)="_selectionChange($event)" formControlName="searchType"
                    [compareWith]="compareObjects">
                    <mat-option *ngFor="let item of filterBy" [value]="item">
                        {{ item.text }}
                    </mat-option>
                </mat-select>
            </div> -->
            <input type="text" formControlName="search" [matAutocomplete]="auto" (focus)="_onFocus(true)"
                (blur)="_onFocus(false)" appFocusSearch placeholder="Search" />
            <mat-autocomplete [displayWith]="displayItem.bind(this)" #auto="matAutocomplete" class="mat-result-items">
                <div class="mat-auto-header"><strong></strong></div>

                <mat-option class="options-card" (click)="_optionSelected(state)" *ngFor="let state of filterData" [value]="state[selectedFilter?.field]">
                    <span class="flex1 mat-space">{{ state[selectedFilter?.field] }}</span>
                    <small>{{ state?.puc_id }}</small>
                </mat-option>

                <mat-option [disabled]="true" class="mat-empty-result" *ngIf="!loading && filterData.length === 0 && search?.value">
                    <img
                        src="https://mobilecenter.azureedge.net/generated/img/no-filtered-apps-5e1a9c8b30813a99f20a80479de65977.svg" />
                    <h3>
                        Sorry, we couldn't find any results for "{{ search?.value }}"
                    </h3>
                </mat-option>

            </mat-autocomplete>
            <button mat-icon-button (click)="_onSearch(form.value)">
                <mat-icon aria-label="search">search</mat-icon>
            </button>
        </form>
    </div>
    <div class="" *ngIf="selectedItem" style="width: 100%; background-color: #fff;">
        <div class="container noShadow" style="width:100%; max-width: 1200px; margin: 0 auto; position: relative;">
            <button mat-icon-button (click)="clearResult()" class="cl-btn">
                <mat-icon class="mat-18">clear</mat-icon>
            </button>
            <app-search-result [data]="selectedItem" [collection]="selectedFilter?.collection"
                [title]="selectedFilter?.text + ' ' + 'Information'" [isHide]="true" [isHideComment]="true">
            </app-search-result>
        </div>
    </div>
    <!-- <div id="adobe-dc-view"></div> -->
 
    <!-- <div class="task-content" *ngIf="!selectedItem">
        <div class="task-item ani-col" [routerLink]="['/register-item']">
            <div class="task-icon task-icon1 orang">
                <i class="material-icons">person_add</i>
            </div>
            <h2 class="task-title">Export Invoice</h2>
        </div>

        <div class="task-item ani-col" [routerLink]="['/register-invoice/']">
            <div class="task-icon task-icon1 purple">
                <i class="material-icons">assignment</i>
            </div>
            <h2 class="task-title">Import Invoice</h2>
        </div>
        <div class="task-item ani-col" [routerLink]="['/register-cut-stock']">
            <div class="task-icon task-icon1 sky">
                <i class="material-icons">spa</i>
            </div>
            <h2 class="task-title">Register Cut Stock</h2>
        </div>
        <div class="task-item ani-col" [routerLink]="['/inventory-balance/list']">
            <div class="task-icon task-icon1 red">
                <i class="material-icons">recent_actors</i>
            </div>
            <h2 class="task-title">Inventory Balance</h2>
        </div>
        <div class="task-item ani-col" [routerLink]="['/reports/preview/soo']">
            <div class="task-icon task-icon1">
                <i class="material-icons">home</i>
            </div>
            <h2 class="task-title">Reporting</h2>
        </div>
    </div> -->
</div>