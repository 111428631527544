import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../cores/material.module';
import { DocKeyCompanyRefPipe, ToDatePipe, DocKeyRefPipe, DocRefPipe } from '../pipes/doc-ref.pipe';
import { UserAvatarPipe } from '../pipes/userinfo.pipe';
import { DocDocDocRefPipe, DocDocRefPipe } from '../pipes/doc-doc-ref.pipe';
import { userPermissionRolePipe, userPermissionByAppPipe, userPermissionPipe } from '../pipes/permissions.pipe';
import { sumAdmFeePipe, sumAmountPipe, sumEmfFeePipe, sumFeePipe } from '../pipes/sum-fee.pipe';
import { DocRefDatePipe } from '../pipes/doc-ref-date.pipe';
import { fileDocRefPipe } from '../pipes/file-doc-ref.pipe';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { colCountPipe, CoRefDocPipe } from '../pipes/get-co.pipe';
import { GetReferenceObjectPipe } from '../pipes/get-reference-object.pipe';
import { shippingDatePipe } from '../pipes/get-shipping-date.pipe';
import { coStatusPipe, isSubmittedStatusPipe } from '../pipes/getcostatus.pipe';
import { filterDataPipe, findCompanyPipe, groupByPipe, stockGroupByPipe } from '../pipes/groupBy.pipe';
import { IconDrivePipe } from '../pipes/icon-drive.pipe';
import { numFormatPipe } from '../pipes/num-format.pipe';
import { SafePipe } from '../pipes/safeHtml.pipe';
import { StateDocRefPipe } from '../pipes/state-doc-ref.pipe';
import { SumPermitItemPipe } from '../pipes/sum-item-permit.pipe';
import { sumRgComControlPipe } from '../pipes/sumcomcontrol.pipe';
import { sumRgControlPipe } from '../pipes/sumcontrol.pipe';
import { sumRgInvPipe, sumTotalPipe } from '../pipes/sumgrnv.pipe';
import { sumAdjItemPipe, sumItemPipe, totalMaterialColPipe } from '../pipes/sumItem.pipe';
import { sumRgPermitPipe } from '../pipes/sumpermit.pipe';
import { DateOnlyPipe } from '../pipes/date-only.pipe';
import { DateTimePipe, isDifDayPipe } from '../pipes/date-time.pipe';
import { SumCutStockItemQtyPipe } from '../pipes/sum-cutStock-item-qty.pipe';
import { SumItemMaterialPipe } from '../pipes/sum-item-material.pipe';
import { SumMaterialPipe } from '../pipes/sum-material.pipe';
import { SumStockPipe } from '../pipes/sum-stock.pipe';
import { CalCutStockBalancePipe } from '../pipes/cal-cut-stock-balance.pipe';
import { CalStockBalancePipe } from '../pipes/cal-stock-balance.pipe';
import { CalStockTotalPipe } from '../pipes/cal-stock-total.pipe';
import { SumGroupMaterialPipe } from '../pipes/sum-group-material.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MomentModule } from 'ngx-moment';
import { HttpModule } from '@angular/http';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FilterListingComponent } from '../components/filter-listing/filter-listing.component';
import { NsFilterListingComponent } from '../components/ns-filter-listing/ns-filter-listing.component';
import { ResponsiveModule } from 'ngx-responsive';
import { QuillModule } from 'ngx-quill';
import { PlaceholderComponent } from '../components/placeholder/placeholder.component';
import { PrimaryButtonComponent } from '../components/primary-button/primary-button.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { LocalGroupCutStockMaterialComponent, GroupCutStockMaterialComponent } from '../components/group-cut-stock-material/group-cut-stock-material.component';
import { StockApplicationDetailComponent } from '../components/stock-application-detail/stock-application-detail.component';
import { RegistrationDetailComponent } from '../components/registration-detail/registration-detail.component';
import { ColumnVisibleComponent } from '../components/column-visible/column-visible.component';
import { EmptyComponent } from '../components/empty/empty.component';
import { GroupStockMaterialComponent } from '../components/group-stock-material/group-stock-material.component';
import { GroupVoidStockComponent } from '../components/group-void-stock/group-void-stock.component';
import { LineLoadingComponent } from '../components/line-loading/line-loading.component';
import { RegisterDetailItemComponent } from '../components/register-detail-item/register-detail-item.component';
import { ReapplyAppDetailComponent } from '../components/reapply-app-detail/reapply-app-detail.component';
import { StickyPolyFillDirective } from '../directives/sticky.directive';
import { ScrollableDirective } from '../scrollable.directive';
import { OutstandingDetailComponent } from '../pages/register-co/outstanding-detail/outstanding-detail.component';
import { ApplicationDetailComponent } from '../components/application-detail/application-detail.component';
import { ApplicationCoRegisterDetailComponent } from '../components/application-co-register-detail/application-co-register-detail.component';
import { ApplicationDetailPrintComponent } from '../components/application-detail-print/application-detail-print.component';
import { DuplicateAppDetailPrintComponent } from '../components/duplicate-app-detail-print/duplicate-app-detail-print.component';
import { DuplicateAppDetailComponent } from '../components/duplicate-app-detail/duplicate-app-detail.component';
import { ReapplyAppDetailPrintComponent } from '../components/reapply-app-detail-print/reapply-app-detail-print.component';
import { RegistrationDetailPrintComponent } from '../components/registration-detail-print/registration-detail-print.component';
import { StockApplicationPrintComponent } from '../components/stock-application-print/stock-application-print.component';
import { SubmitApplicationDetailComponent } from '../components/submit-application-detail/submit-application-detail.component';
import { SubmitApplicationPrintComponent } from '../components/submit-application-print/submit-application-print.component';
import { SettingsDetailComponent } from '../components/settings-detail/settings-detail.component';
import { CompanyFieldComponent } from '../components/company-field/company-field.component';
import { ConfirmDeleteComponent } from '../components/confirm-delete/confirm-delete.component';
import { ConfirmSuccessComponent } from '../components/confirm-success/confirm-success.component';
import { DataPreviewComponent } from '../components/data-preview/data-preview.component';
import { DocRefComponent } from '../components/doc-ref/doc-ref.component';
import { FilterSubmitListingComponent } from '../components/filter-submit-listing/filter-submit-listing.component';
import { RegisterItemDetailComponent } from '../components/register-item-detail/register-item-detail.component';
import { AutofocusDirective } from '../directives/auto-focus.directive';
import { FilterReportComponent, FilterStockReportComponent } from '../components/filter-report/filter-report.component';
import { DeleteComponent } from '../components/delete/delete.component';
import { CancelComponent } from '../components/cancel/cancel.component';
import { AvatarComponent, ChangePasswordComponent } from '../pages/user/change-password/change-password.component';
import { CheckStockBalanceComponent } from '../components/check-stock-balance/check-stock-balance.component';
import { ConfirmNotActionComponent } from '../components/confirm-not-action/confirm-not-action.component';
import { FilterInventoryDetailComponent } from '../components/filter-inventory-detail/filter-inventory-detail.component';
import { LatterPreviewComponent } from '../components/latter-preview/latter-preview.component';
import { SearchResultComponent } from '../components/search-result/search-result.component';
import { ForgotPasswordComponent } from '../pages/user/forgot-password/forgot-password.component';
import { PlaceholderCardComponent } from '../components/placeholder-card/placeholder-card.component';
import { RegisterItemMaterialsComponent } from '../components/register-item-materials/register-item-materials.component';
import { PrintRegisterItemComponent } from '../pages/items/print-register-item/print-register-item.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileViewListsComponent } from '../components/file-view-lists/file-view-lists.component';
import { HistoryListingComponent } from '../components/history-listing/history-listing.component';
const modules = [
  InfiniteScrollModule,

  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  MobxAngularModule,
  NgPipesModule,
  MomentModule,
  MatSelectFilterModule,
  HttpClientModule,
  EditorModule,
  HttpModule,
  ResponsiveModule,
  QuillModule,
  VirtualScrollerModule,
];
const components = [
  isDifDayPipe,
  colCountPipe,
  stockGroupByPipe,
  FileViewListsComponent,
  sumAdjItemPipe,
  HistoryListingComponent,

  sumAmountPipe,
  FilterStockReportComponent,
  findCompanyPipe,
  isSubmittedStatusPipe,
  PrintRegisterItemComponent,
  PlaceholderCardComponent,
  RegisterItemMaterialsComponent,
  FilterInventoryDetailComponent,
  CheckStockBalanceComponent,
  ConfirmNotActionComponent,
  SearchResultComponent,
  ForgotPasswordComponent,
  ChangePasswordComponent,
  LatterPreviewComponent,
  AvatarComponent,
  AutofocusDirective,
  FilterReportComponent,
  DeleteComponent,
  CancelComponent,
  RegisterItemDetailComponent,
  FilterSubmitListingComponent,
  ConfirmDeleteComponent,
  ConfirmSuccessComponent,
  DocRefComponent,
  DataPreviewComponent,
  CompanyFieldComponent,
  SettingsDetailComponent,
  RegistrationDetailPrintComponent,
  SubmitApplicationPrintComponent,
  SubmitApplicationDetailComponent,
  ApplicationDetailPrintComponent,
  StockApplicationPrintComponent,
  ApplicationCoRegisterDetailComponent,
  ReapplyAppDetailPrintComponent,
  DuplicateAppDetailPrintComponent,
  DuplicateAppDetailComponent,
  ApplicationDetailComponent,
  OutstandingDetailComponent,
  StickyPolyFillDirective,
  ScrollableDirective,
  UserAvatarPipe,
  DocKeyCompanyRefPipe,
  ToDatePipe,
  DocKeyRefPipe,
  sumAdmFeePipe,
  sumEmfFeePipe,
  DocDocDocRefPipe,
  userPermissionRolePipe,
  userPermissionByAppPipe,
  userPermissionPipe,
  StateDocRefPipe,
  fileDocRefPipe,
  FileSizePipe,
  IconDrivePipe,
  groupByPipe,
  SafePipe,
  SumPermitItemPipe,
  sumRgControlPipe,
  sumRgPermitPipe,
  sumRgComControlPipe,
  sumRgInvPipe,
  CoRefDocPipe,
  coStatusPipe,
  sumItemPipe,
  totalMaterialColPipe,
  shippingDatePipe,
  numFormatPipe,
  sumFeePipe,
  DocRefDatePipe,
  DocDocRefPipe,
  GetReferenceObjectPipe,
  DateOnlyPipe,
  DateTimePipe,
  DocRefPipe,
  SumStockPipe,
  SumMaterialPipe,
  SumItemMaterialPipe,
  SumCutStockItemQtyPipe,
  SumGroupMaterialPipe,
  CalStockBalancePipe,
  CalStockTotalPipe,
  CalCutStockBalancePipe,
  FilterListingComponent,
  NsFilterListingComponent,
  PlaceholderComponent,
  PrimaryButtonComponent,
  SpinnerComponent,
  LocalGroupCutStockMaterialComponent,
  GroupCutStockMaterialComponent,
  StockApplicationDetailComponent,
  RegistrationDetailComponent,
  ColumnVisibleComponent,
  EmptyComponent,
  GroupStockMaterialComponent,
  GroupVoidStockComponent,
  LineLoadingComponent,
  RegisterDetailItemComponent,
  ReapplyAppDetailComponent,
  sumTotalPipe,
  filterDataPipe
];
@NgModule({

  declarations: [
    ...components
  ],
  
  imports: [
    ...modules
  ],

  exports: [
    ...components,
    ...modules
  ],
  providers:[
    
  ]

})
export class AppSharedModule { }
