<div class="page-section" *mobxAutorun>
  <div class="sticky-block">
    <div class="action-bar">
      <div class="nav-content">
        <h3 class="head-title">
          Options
        </h3>
        <div class="flex1"></div>
      </div>
    </div>
    <nav mat-tab-nav-bar class="cs-mat-tab-link">
      <a mat-tab-link [routerLink]="['/' + link.path]" *ngFor="let link of tabs" routerLinkActive
        #rla="routerLinkActive" [active]="rla.isActive">{{link.label}}</a>
      <div class="flex-1"></div>
    </nav>
  </div>
  <div class="cs-container">
    <ng-container *ngIf="!store?.loading">
      <div class="scrollable-table">
        <div class="core-form">
          <div class="apps-container">
            <form [formGroup]="form" class="form-groups">
              <div class="form-header">
                <h4>Submit Applications</h4>
              </div>
              <div class="form-body">
                <mat-form-field appearance="outline">
                  <mat-label>CRF Code</mat-label>
                  <input type="text" placeholder="CRF Code" aria-label="" matInput formControlName="crfCode"
                    [matAutocomplete]="auto">
                  <mat-autocomplete (optionSelected)="create(form.value)" #auto="matAutocomplete" [displayWith]="displayItemName">
                    <mat-option *ngFor="let option of filteredStates" [value]="option">
                        {{ option?.code }} - {{ option?.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="error-message" *ngIf="form.controls['crfCode'].hasError('validKey')">
                    <div>You can only select from list.</div>
                  </mat-error>
                  <mat-error *ngIf="form.controls['crfCode'].hasError('required')">
                    This field is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Department</mat-label>
                    <input type="text" placeholder="Department" aria-label="" matInput formControlName="department"
                      [matAutocomplete]="autoDepartment">
                    <mat-autocomplete (optionSelected)="create(form.value)" #autoDepartment="matAutocomplete" [displayWith]="displayItem">
                      <mat-option *ngFor="let option of filteredDepartmentStates" [value]="option">
                          {{ option?.name_en }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error class="error-message" *ngIf="form.controls['department'].hasError('validKey')">
                      <div>You can only select from list.</div>
                    </mat-error>
                    <mat-error *ngIf="form.controls['department'].hasError('required')">
                      This field is required.
                    </mat-error>
                  </mat-form-field>
              </div>
            </form>
          </div>
        </div>
      </div>


    </ng-container>

    <!-- <div>
      <button mat-button (click)="subscribelocalInv()">Show local INV Notification</button>
    </div> -->
    <app-spinner *ngIf="store?.loading"></app-spinner>
  </div>
</div>