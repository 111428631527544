import { DataService } from '../../services/data.service';
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { ConvertService, pushObjArray, pushReplaceObjArray, pushToArray, pushToObject, sumData } from 'src/app/services/convert.service';
import * as moment from 'moment'
import * as _ from 'lodash';
import { StockOpenBalanceConverter } from 'src/app/services/mapping.service';
@Injectable({ providedIn: "root" })
export class ReportingStore {
    @observable data: any[] = [];
    @observable allData: any[] = [];
    @observable openData: any[] = [];
    @observable singleData: any = null;
    @observable process: boolean = false;
    @observable loading: boolean = false;
    @observable empty: boolean = false;
    @observable filterStatus: any = 0;
    @observable lastVisible: any = null;
    @observable done: boolean = false;
    @observable fetching: boolean = false;
    @observable selectedItem: any = null;

    @observable totalAmountFOB: number = 0;
    @observable totalQtyUnit: number = 0;
    @observable totalQtyPacking: number = 0;
    @observable totalAmountFOBSOO: number = 0;
    @observable totalQtyCNT: number = 0;
    @observable totalQtyPCS: number = 0;


    @observable orderBy: any = "code";
    @observable registerStatus: any = null;
    @observable companyKey: any = null;
    @observable typeKey: any = null;
    constructor(private ds: DataService) { }



    @action
    addReportHistory(item:any, callback) {
        const data = {
            key:this.ds.createId(),
            ...item,
        }
        this.process = true;
        const batch = this.ds.batch();
        const ref = this.ds.settingBatchRefByCompany("report_history", data.companyKey).doc(data.key);
        batch.set(ref, data)
        batch.commit().then(() => {
            callback(true, null);
        }).catch((error) => {
            alert(error);
            callback(false, error)
            console.log('error', error);
            
        }).finally(() => {
            this.process = false;
        })
    }


  



    @action
    async fetchResoleRegister(collectionName: string, companyKey: any) {
        const data = pushToArray(await this.ds.settingRefByCompany(collectionName, companyKey).get().toPromise())
        return data;
    }
    @action
    async fetchRegisterListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = pushToArray(await this.ds.fetchReportDataRef(collectionName, filterForm, this.registerStatus, orderBy, this.companyKey).get().toPromise())
        this.data = data
        callback(data);
        this.loading = false
        this.fetching = false;

    }

    @action
    async fetchRegisterCutStockListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = pushToArray(await this.ds.fetchReportCutStockDataRef(collectionName, filterForm, this.registerStatus, orderBy, this.companyKey).get().toPromise())
        this.data = data
        callback(data);
        this.loading = false
        this.fetching = false;

    }

    @action
    async fetchRegisterInvListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const ref = this.ds.fetchReportInvDataRef(collectionName, filterForm, this.registerStatus, orderBy, this.companyKey);
        const data = pushToArray(await ref.get().toPromise())
        this.data = data
        callback(data);
        this.loading = false
        this.fetching = false;

    }




    @action
    async fetchRegisterAdjustListing(orderBy: string, filterForm: any, registerStatus: any, companyKey: any, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;

        const data = pushToArray(await this.ds.fetchReportDataRef('register_adjustment_stock', filterForm, this.registerStatus, orderBy, this.companyKey).get().toPromise())

        callback(data);
        this.loading = false
        this.fetching = false;

    }


    @action
    async fetchRegisterInventoryListing(orderBy: string, filterForm: any, materialKey: any, companyKey: any, materials, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.companyKey = companyKey;
        this.fetching = false;
        const { fromDate } = filterForm;
        this.data = [];
        this.allData = [];
        this.openData = null;
        if (materialKey === 'all') {
            this.allData = await Promise.all(materials.map(async m => {
                const openData = await Promise.resolve(this.executeOpenBalance(fromDate, m.key, orderBy, this.companyKey))
                const data = pushToArray(await this.ds.fetchReportInventoryDataRef(filterForm, m.key, orderBy, this.companyKey).get().toPromise());
                const totalImport = sumData(data.filter(f => f.movementType.factor === 1), 'qty');
                const totalCut = sumData(data.filter(f => f.movementType.factor === -1), 'qty');
                return {
                    ...m,
                    totalImport: totalImport,
                    totalCut: totalCut,
                    totalBalance: (totalImport - totalCut) + openData
                }
            }))
        } else {
            this.openData = await Promise.resolve(this.executeOpenBalance(fromDate, materialKey, orderBy, this.companyKey))
            // this.ds.fetchReportInventoryDataRef(filterForm, materialKey, orderBy, this.companyKey).valueChanges().subscribe(data=>{
            // })
            const data = pushToArray(await this.ds.fetchReportInventoryDataRef(filterForm, materialKey, orderBy, this.companyKey).get().toPromise());

            this.data = _.sortBy(data, ['release_date', 'updated_at'])
            //ConvertService.orderBy(data, 'release_date');

        }

        callback(this.data, this.allData, this.openData);
        this.loading = false
        this.fetching = false;

    }

    openDataLists
    @action
    async executeOpenBalance(fromDate, materialKey, orderBy, companyKey) {
        const firstOpenData = pushToArray(await this.ds.fetchReportOpenInventoryDataFirstRef(fromDate, materialKey, orderBy, companyKey).withConverter(StockOpenBalanceConverter).get());
        const data = firstOpenData[0];
        if (data) {
            const { release_date } = data;
            const fromDateKey = moment(release_date.toDate()).startOf('day').toDate();
            const toDateKey = moment(release_date.toDate()).endOf('day').toDate();
            const openData = pushToArray(await this.ds.fetchReportOpenInventoryDataRef(fromDateKey, toDateKey, materialKey, orderBy, this.companyKey).withConverter(StockOpenBalanceConverter).get());
            this.openDataLists = ConvertService.orderByDesc(openData, 'updated_at');
            const { stockBalance } = this.openDataLists[0]
            const totalOpenBalance = stockBalance;
            if (totalOpenBalance === 0) {
                const { release_date } = data;
                return this.executeOpenBalance(release_date.toDate(), materialKey, orderBy, companyKey);
            } else {
                return totalOpenBalance
            }
        } else {
            return 0
        }
    }


    @action
    async fetchRegisterOutstandingListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, registerCutStock, registerCutStockKey, outstandingStock, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = await this.ds.fetchReportStockDataRef(collectionName, filterForm, this.registerStatus, orderBy, this.companyKey, registerCutStock, registerCutStockKey, outstandingStock).get().toPromise()
        this.data = pushToArray(data);
        if (!data) {
            this.loading = false;
            this.fetching = false;
            callback(this.data)
            return false;
        }
        callback(this.data);
        this.loading = false
        this.fetching = false;

    }

    @action
    async fetchNoneCutStockCOListing(collectionName: string, orderBy: string, filterForm: any, registerStatus: any, companyKey: any, registerCutStockKey, outstandingStock, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = await this.ds.fetchReportCOStockDataRef(collectionName, filterForm, this.registerStatus, orderBy, this.companyKey, registerCutStockKey, outstandingStock).get().toPromise()
        this.data = pushToArray(data);
        if (!data) {
            this.loading = false;
            this.fetching = false;
            callback(this.data)
            return false;
        }
        callback(this.data);
        this.loading = false
        this.fetching = false;

    }


    @action
    async fetchRegisterLocalCutStockOutstandingListing(collectionName: string, outstandingStock, filterForm: any, registerStatus: any, companyKey: any, orderBy: string, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = await this.ds.lazyRegisterOutstandingLocalStockReportRef(collectionName, outstandingStock, null, filterForm, registerStatus, this.companyKey, orderBy).get().toPromise()
        this.data = pushToArray(data);
        if (!data) {
            this.loading = false;
            this.fetching = false;
            callback(this.data)
            return false;
        }
        callback(this.data);
        this.loading = false
        this.fetching = false;

    }


    @action
    async fetchRegisterLocalImportStockOutstandingListing(collectionName: string, outtandingField: string, outtandingFieldKey: string, outtandingOtherField: string, filterForm: any, registerStatus: any, companyKey: any, orderBy, callback) {
        this.orderBy = orderBy;
        this.loading = true;
        this.lastVisible = null;
        this.filterStatus = 0;
        this.registerStatus = registerStatus;
        this.companyKey = companyKey;
        this.fetching = false;
        const data = await this.ds.lazyRegisterOutstandingLocalImportReportRef(collectionName, outtandingField, outtandingFieldKey, outtandingOtherField, this.lastVisible, filterForm, this.registerStatus, this.companyKey, this.orderBy).get().toPromise()
        this.data = pushToArray(data);
        if (!data) {
            this.loading = false;
            this.fetching = false;
            callback(this.data)
            return false;
        }
        callback(this.data);
        this.loading = false
        this.fetching = false;

    }

    @action
    async resetSelectedItem() {
        this.selectedItem = null
        this.totalAmountFOB = 0;
        this.totalQtyUnit = 0;
        this.totalQtyPacking = 0;
        this.totalAmountFOBSOO = 0;
        this.totalQtyCNT = 0;
        this.totalQtyPCS = 0;
    }
    @action
    resetStore() {
        this.totalAmountFOB = 0;
        this.totalQtyUnit = 0;
        this.totalQtyPacking = 0;
        this.totalAmountFOBSOO = 0;
        this.totalQtyCNT = 0;
        this.totalQtyPCS = 0;
    }

}
