import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'sumPermitItem'
})
export class SumPermitItemPipe implements PipeTransform {

  async transform(data: Array<any>): Promise<any> {
    let total = 0
    let arrayData = [];
    if (data && data.length > 0) {
      for (const item of data) {
        if(!item.itemRef) return
        const d = await item.itemRef.get()
        const { permit_number } = d.data();
        if (permit_number) {
          arrayData.push(d.data());
        }
      }

      const dataPermit = MappingService.groupBy(arrayData, "permit_number", "permit_number");

      total = dataPermit.length;
      return total;
    }
    return total;
  }

}
