import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { orderBy, pushToArray } from '../services/convert.service';
import { AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({providedIn:'root'})
export class OptionStore {
    @observable data: any = null;
    @observable process: boolean = false;
    @observable loading: boolean = true;

    constructor(
        private ds: DataService,
    ) { }

    @action
    fetchData(callback?) {
        this.loading = true;
        this.ds.environmentRef().valueChanges().subscribe(docs => {
            this.data = docs;
            this.loading = false;
            callback(docs);
        })
    }

    @action
    fetchCoForm(callback?) {
        this.loading = true;
        this.ds.coformRef().valueChanges().subscribe(docs => {
            this.loading = false;
            callback(orderBy(docs, "code"))
        })
    }

    @action
    fetchDepartment(callback?) {
        this.loading = true;
        this.ds.departmentRef().valueChanges().subscribe(docs => {
            this.loading = false;
            callback(orderBy(docs, "code"))
        })
    }

    @action
    saveOptions(item: any, callback) {
        this.ds.environmentRef().update({
            ...item,
        }).then(() => {
            callback(true);
        }).catch(error => {
            alert(error);
            callback(false, null)
        })
    }

    @action
    async fetchItem(ref:AngularFirestoreCollection){
        const docs=await ref.get().toPromise()
        return pushToArray(docs)
    }

}
