
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shippingDate'
})
export class shippingDatePipe implements PipeTransform {

    transform(array: any): any {
        // const mm = array.map(f => f.registerCoRef)
        if(!array) return null
        return array.registerCoRef.get().then(doc => {
            const date = doc.data().etd
            if (date) {
                return date.toDate()
            }else{
                return null
            }
        })
    }

}
