<div class="scrollable-table" style="margin: 0;" id="print" *ngIf="materials?.length>0">
    <table class="table-wrapper tb-master">
        <thead class="table-header">
            <tr class="table-row">
                <th class="table-col center">No.</th>
                <!-- <th class="table-col center">INV No</th> -->
                <!-- <th class="table-col center">INV Date</th> -->
                <th class="table-col center">Material Code</th>
                <th class="table-col center">Material Name</th>
                <!-- <th class="table-col ">UOM</th> -->
                <th class="table-col center">Quantity</th>
                <!-- <th class="table-col center">Currency</th> -->
                <!-- <th class="table-col center">Price</th> -->
                <!-- <th class="table-col center">Total</th> -->
                <th class="table-col center">N.W</th>
                <th class="table-col center">G.W</th>
            </tr>
        </thead>
        <tbody class="table-body">
            <tr class="table-row" *ngFor="let item of groupedData;let i=index">
                <td class="table-col">{{i+1}}</td>
                <!-- <td class="table-col ">
                    {{item?.itemRef|docRef:'invoice_number'|async}}</td>
                <td class="table-col ">
                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}</td> -->
                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                <!-- <td class="table-col"></td> -->
                <td class="table-col right">
                    {{item?.import_qty | number : '.2-2'}}
                    {{item?.uomRef|docRef:'code'|async}}
                </td>
                <!-- <td class="table-col ">{{item?.import_currencyRef|docRef:'name'|async}}</td> -->
                <!-- <td class="table-col right">{{item?.import_cost | number : '.2-2'}}</td> -->
                <!-- <td class="table-col right">{{item?.import_totalCost | number : '.2-2'}}</td> -->
                <td class="table-col right">{{item?.import_qty_NW  | number : '.2-2'}}
                    {{item?.import_qty_NW_UOMRef|docRef:'code'|async}}</td>
                <td class="table-col right">{{item?.import_qty_GW | number : '.2-2'}}
                    {{item?.import_qty_GW_UOMRef|docRef:'code'|async}}</td>
            </tr>
        </tbody>
    </table>
</div>