import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { sum } from 'src/app/services/convert.service';
import { DataService } from 'src/app/services/data.service';
import { MappingService, pushToObject, toNumber } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-stock-release-date',
  templateUrl: './stock-release-date.component.html',
  styleUrls: ['./stock-release-date.component.scss']
})
export class StockReleaseDateComponent implements OnInit {
  form: FormGroup;
  date;
  constructor(
    public dialogRef: MatDialogRef<StockReleaseDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ds: DataService,
    private fb: FormBuilder
  ) { }

  materials = [];
  canSubmit = true;
  async ngOnInit() {
    this.form = this.fb.group({
      application_date: [null, Validators.compose([Validators.required]), this.checkExistRef.bind(this)]
    })
  }
  async checkExistRef(control: AbstractControl) {
    if (control) {
      const { value } = control;
      if (!value) return null;
      const compareDate = this.data?.dateData?.toDate()
      if (value && compareDate) {
        if (value >= compareDate) {
          return null;
        } else {
          return { nameAvailable: true }
        }
      } return null;
    }
  }
  onSubmit(f) {
    if(this.form.valid){
      this.dialogRef.close(f)
    }
  }

  onClose() {
    this.dialogRef.close('no')
  }
}
