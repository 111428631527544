import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { RegistrationDetailPrintComponent } from '../registration-detail-print/registration-detail-print.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CommentStore } from 'src/app/stores/comment.store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Auth } from 'src/app/stores/auth.store';
import { statusObj } from 'src/app/dummy/status';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { pushToObject, sum, orderBy, ConvertService } from 'src/app/services/convert.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LatterPreviewComponent } from '../latter-preview/latter-preview.component';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registration-detail',
  templateUrl: './registration-detail.component.html',
  styleUrls: ['./registration-detail.component.scss'],
})
export class RegistrationDetailComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  @Input() printTemplate: number;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Input() isDeleted: boolean;
  @Input() isHideComment: boolean;
  @Input() materials: any;

  @Output() onDelete = new EventEmitter();
  @ViewChild('content') content: ElementRef;

  @ViewChild('editor') editor;
  @Input() isRelease: boolean;
  @Output() onRelease = new EventEmitter();

  @Input() canDelete: boolean;

  modules = {};
  selectedFiles: any;

  form: FormGroup;
  comment: AbstractControl;

  formUpload: FormGroup;
  file: AbstractControl;
  id;
  ismobile = false;
  soo;
  submitForm;
  submitCrf;
  returnApp;
  registerCutStock;
  deliveryReturnDoc;
  deliveryCo;
  soo_statement;
  totalAmountFOBSOO: number = 0;
  totalQtyCNT: number = 0;
  totalQtyPCS: number = 0;
  totalAmountFOB: number = 0;
  totalQtyUnit: number = 0;
  totalQtyPacking: number = 0;
  registerImportInvoiceInfo;
  registerImportStock;
  deliveryImportStock;
  printTemplateData;
  auditData;
  reApplyForm;
  CONew;
  deliveryCutStock;
  subscriptionRoute: Subscription;

  constructor(
    public store: SettingStore,
    public ps: PrintService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public cm: CommentStore,
    private afs: AngularFirestore,
    public auth: Auth,
    private snackBar: MatSnackBar,
    public fm: FileManagerStore,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private ds: DataService,

  ) {
    this.modules = {
      // imageResize: {},
      syntax: false,
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['formula'],
        ['image', 'code-block']
      ],
      // theme: 'bubble'
    }
  }

  hideBar() {
    if (!this.isHideComment) {

      this.isHideComment = true;
    } else {

      this.isHideComment = false;
    }
  }
  _onRelease(item?: any) {
    this.onRelease.emit(item);
  }
  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
    this.form = this.fb.group({
      comment: [null, Validators.required]
    })
    this.formUpload = this.fb.group({
      file: [null, Validators.required]
    });

    this.comment = this.form.controls['comment']
    this.file = this.form.controls['file']

    this.subscriptionRoute = this.route.params.subscribe(async param => {
      if (param && param.id) {
        this.id = param.id;
        this.store.detailCollectionByCompany(this.collection === 'register_adjustment_stock_under' ? 'register_adjustment_stock' : this.collection, param.id, this.auth.selectedCompany.key, async data => {
          if (data) {
            this.data = data;
            if (this.collection == 'register_soo') {
              this.totalAmountFOB = sum(data.items, "amount_FOB");
              this.totalQtyUnit = sum(data.items, "qty_unit");
              this.totalQtyPacking = sum(data.items, "qty_packing");
              this.totalAmountFOBSOO = sum(data.items, "amount");
              this.totalQtyCNT = sum(data.items, "quality_cnt");
              this.totalQtyPCS = sum(data.items, "quality_pcs");
            }

            if (this.collection == 'register_co') {
              const { submit_sooRef, submit_crfRef, submit_fromRef, returnAppRef, registerCutStockRef, deliveryReturnDocRef, deliveryCoRef, soo_statementRef } = data

              if (submit_sooRef) {
                this.soo = pushToObject(await submit_sooRef.get())
                const { soo_statementRef } = this.soo;
                if (soo_statementRef) {
                  this.soo_statement = pushToObject(await soo_statementRef.get())
                }
              }
              if (submit_crfRef) {
                this.submitCrf = pushToObject(await submit_crfRef.get())
              }
              if (submit_fromRef) {
                this.submitForm = pushToObject(await submit_fromRef.get())
              }

              if (returnAppRef) {
                this.returnApp = pushToObject(await returnAppRef.get())
              }

              if (deliveryReturnDocRef) {
                this.deliveryReturnDoc = pushToObject(await deliveryReturnDocRef.get())
              }

              if (deliveryCoRef) {
                this.deliveryCo = pushToObject(await deliveryCoRef.get())
              }
              if (registerCutStockRef) {
                this.registerCutStock = pushToObject(await registerCutStockRef.get())
              }
            }
            if (this.collection == 'register_invoice' || this.collection == 'register_local_invoice') {
              const { registerImportInvoiceInfoRef, registerImportStockRef } = data
              if (registerImportInvoiceInfoRef) {
                this.registerImportInvoiceInfo = pushToObject(await registerImportInvoiceInfoRef.get())
              }

              if (registerImportStockRef) {
                this.registerImportStock = pushToObject(await registerImportStockRef.get())
                const { deliveryImportStockRef } = this.registerImportStock;
                if (deliveryImportStockRef) {
                  this.deliveryImportStock = pushToObject(await deliveryImportStockRef.get())
                }
              }
            }
            if (this.collection == 'register_reapply_item') {
              const { reApplyFormRef, CONewKey, oldCO } = data;
              const { returnAppRef } = oldCO || {}
              if (reApplyFormRef) {
                this.reApplyForm = pushToObject(await reApplyFormRef.get());
              }
              if (returnAppRef) {
                this.returnApp = returnAppRef ? pushToObject(await returnAppRef.get()) : null;
              }
              if (CONewKey) {
                const co = pushToObject(await this.ds.registerCoCollectionRefDoc(this.auth.selectedCompany.key, CONewKey).get().toPromise());
                const { registerCutStockRef, deliveryCoRef } = co || {};
                this.deliveryCo = deliveryCoRef ? pushToObject(await deliveryCoRef.get()) : null;
                this.registerCutStock = registerCutStockRef ? pushToObject(await registerCutStockRef.get()) : null;
                if (this.registerCutStock) {
                  const { deliveryCutStockRef } = this.registerCutStock || {}
                  this.deliveryCutStock = deliveryCutStockRef ? pushToObject(await deliveryCutStockRef.get()) : null;
                }
              }

            }

            this.cm.fetchCommentByCompany(this.collection, data.companyKey, data.key);
          }
        });
      }
    })

    // if (this.printTemplate) {
    //   this.store.fetchCollectionPrintTemplate(this.auth.selectedCompany.key, Number(this.printTemplate), data => {
    //     this.printTemplateData = ConvertService.orderByDesc(data, 'updated_at')
    //   })
    // }


  }
  ngOnDestroy(): void {
    this.subscriptionRoute && this.subscriptionRoute.unsubscribe();
  }

  editItem = null;
  editComment(item) {
    this.editItem = item
    this.form.patchValue({
      comment: item.comment
    })
  }

  postComment(f, data) {
    if (this.form.valid) {
      const isEdit = this.editItem ? true : false;
      const key = this.editItem ? this.editItem.key : this.afs.createId()
      const item = {
        key: key,
        refKey: data.key,
        comment: f.comment,
        created_at: this.editItem ? this.editItem.created_at : new Date(),
        created_by: this.editItem ? this.editItem.created_by : this.auth.user,
        updated_at: new Date(),
        updated_by: this.auth.user,
        status: statusObj.active,
        isEdit: isEdit,
      }
      this.cm.addCommentByCompany(this.collection, this.auth.selectedCompany.key, item, (success, res) => {
        // this.snackBar.open("Comment has been submit successful.", "Done", { duration: 100 });
        this.editItem = null;
        this.form.reset();
      })
    }

  }

  onSelectedFile(event) {
    this.selectedFiles = event;
    this.uploadFile()
  }

  uploadFile() {
    if (this.formUpload.valid) {
      this.fm.addFileManagerByCompany(this.router.url, this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, this.id, this.auth.user, this.selectedFiles, success => { })
    }
  }
  deleteFile(file) {
    this.fm.deleteFileDataByCompany(this.auth.selectedCompany.name_en, this.collection, this.auth.selectedCompany.key, file, (success, res) => {
      if (success)
        this.snackBar.open("File has been delete successful.", "Done", { duration: 100 });
    })
  }

  printMasterList() {
    const item = this.data
    const collection = this.collection
    let dialogRef = this.dialog.open(RegistrationDetailPrintComponent, {
      data: { item, collection, printTemplateData: this.printTemplateData },
      panelClass: 'print-master-panel',
      width: '',
      height: '',
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  preview(item: any) {
    let dialogRef = this.dialog.open(LatterPreviewComponent, {
      data: { item: item, collection: this.collection },
      panelClass: 'print-master-panel',
      width: '',
      height: '',
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }
  delete(item: any) {
    this.onDelete.emit(item);
  }

  isSubmit(data) {
    if (!data) return true;
    let show = true;
    const { submit_from, submit_sooKey, soo_statement, delivery_exportKey, submit_fromKey, submit_crfKey, submit_crf, registerImportInvoiceInfoKey } = data;
    if (submit_crfKey || submit_fromKey || submit_sooKey || delivery_exportKey || soo_statement || registerImportInvoiceInfoKey)
      show = false
    return show;
  }
}
