<div class="cs-form-container">
    <div class="form-wrapper" style="padding:33px 0;" id="print">
        <div class="hd-row" style="padding:0 33px 16px 33px">
            <h4 class="hd-title">{{title}}</h4>
            <div class="flex-1"></div>
        </div>

        <div class="" style="padding: 33px 0" *ngIf="!store?.process" #content id="contentToConvert">

            <ng-container *ngIf="collection === 'local_sale_inv'">
                <div class="" *ngIf="!store?.process" #content id="contentToConvert">

                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Local Sale Invoice
                            </ng-template>

                            <div style="padding: 33px">
                                <ng-container>
                                    <div class="dl-items">
                                        <div class="dl-title"> CO Form</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.co_formRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Local Sale Invoice Number</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.invoice_number?data?.invoice_number:null}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Local Sale Invoice Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.invoice_date?.toDate() | date}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Product Name</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.product_name}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> CAT</div>
                                        <div class="dl-text">:
                                            <div class="data" *ngIf="data?.cat > 0">
                                                {{data?.cat | number:'2.'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Amount</div>
                                        <div class="dl-text">:
                                            <div class="data" *ngIf="data?.amount_FOB > 0">
                                                {{data?.amount_FOB | currency:' ':'code'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Destination</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.countryRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">

                                        <div class="dl-title"> Route</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.routeRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Qty Packing</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.qty_packing | number:'1.'}}
                                                <span *ngIf="data?.qty_packing_UOMRef">

                                                    {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Qty Unit</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.qty_unit | number:'1.'}}
                                                <span *ngIf="data?.qty_unit_UOMRef">

                                                    {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Shipping Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.shipping_date?.toDate() | date}}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="dl-items">
                                        <div class="dl-title"> Last Update</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.updated_at?.toDate() | date:'medium'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Status</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.status?.text}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon *ngIf="material?.length > 0" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                CO Material
                            </ng-template>
                            <div style="padding: 33px">
                                <div class="scrollable-table" style="margin: 0;" id="print"
                                    *ngIf="material?.length > 0">
                                    <table class="table-wrapper tb-master">
                                        <thead class="table-header">
                                            <tr class="table-row">
                                                <th class="table-col center">No.</th>
                                                <th class="table-col center">Code</th>
                                                <th class="table-col center">Description</th>
                                                <!-- <th class="table-col center">UOM</th> -->
                                                <th class="table-col center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <tr class="table-row" *ngFor="let item of material;let i=index">
                                                <td class="table-col">{{i+1}}</td>
                                                <td class="table-col">
                                                    {{item?.materialRef|docRef:'code'|async}}
                                                </td>
                                                <td class="table-col">
                                                    {{item?.materialRef|docRef:'name'|async}}
                                                </td>
                                                <!-- <td class="table-col">
                                                </td> -->
                                                <td class="table-col right">
                                                    {{item?.qty | number}}
                                                    {{item?.uomRef|docRef:'code'|async}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="dl-items" *ngIf="material?.length == 0"
                                    style="padding: 64px; font-size: 16px; justify-content: center;">
                                    No material!
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>

                                <mat-icon *ngIf="deliverExport" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Delivery Local Sale Invoice
                            </ng-template>
                            <div style="padding: 33px">
                                <ng-container *ngIf="deliverExport">
                                    <div class="dl-items">
                                        <div class="dl-title"> Reference No.</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{deliverExport?.refNo}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Application Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{deliverExport?.application_date?.toDate() | date}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="dl-items" *ngIf="!deliverExport"
                                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                                    Not yet register delivery export inspect!
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>

                                <mat-icon *ngIf="registerLocalCutStock" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Local Sale Stock
                            </ng-template>
                            <div style="padding: 33px">
                                <ng-container *ngIf="registerLocalCutStock">
                                    <div class="dl-items">
                                        <div class="dl-title"> Reference No.</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{registerLocalCutStock?.refNo}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Application Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{registerLocalCutStock?.application_date?.toDate() | date}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="dl-items" *ngIf="!registerLocalCutStock"
                                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                                    Not yet register delivery export inspect!
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>

                                <mat-icon *ngIf="deliveryCutStock" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Delivery Local Sale Stock
                            </ng-template>
                            <div style="padding: 33px">
                                <ng-container *ngIf="deliveryCutStock">
                                    <div class="dl-items">
                                        <div class="dl-title"> Reference No.</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{deliveryCutStock?.refNo}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Application Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{deliveryCutStock?.application_date?.toDate() | date}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="dl-items" *ngIf="!deliveryCutStock"
                                    style="padding: 64px; font-size: 16px;  justify-content: center;">
                                    Not yet register delivery export inspect!
                                </div>
                            </div>
                        </mat-tab>

                    </mat-tab-group>

                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'register_local_invoice'">

                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Local Purchase Invoice
                        </ng-template>

                        <div style="padding: 33px">
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Number</div>
                                <div class="dl-text">: {{data?.invoice_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Date</div>
                                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title">Export To</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.export_to}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">CAT</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.cat?(data?.cat | number:'1.') : ''}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Import Country</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.countryRef | docRef: 'name' | async}}
                                    </div>
                                </div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title"> CO Material</div>
                                <div class="dl-text">:</div>
                            </div>

                            <div class="scrollable-table" style="margin: 0;" id="print">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col center">No.</th>
                                            <th class="table-col center">Material Code</th>
                                            <th class="table-col center">Material Name</th>
                                            <th class="table-col center">UOM</th>
                                            <th class="table-col center">Quantity</th>
                                            <th class="table-col center">Currency</th>
                                            <th class="table-col center">Price</th>
                                            <th class="table-col center">Total</th>
                                            <th class="table-col center">N.W</th>
                                            <th class="table-col center">G.W</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                            <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                                            </td>
                                            <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                                            <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td>
                                            <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                            <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                                            </td>
                                            <td class="table-col right">{{item?.qty_NW}}
                                                {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty_GW}}
                                                {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Last Update</div>
                                <div class="dl-text" *ngIf="data?.updated_at">:
                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                </div>
                                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                            </div>
                            <div class="dl-items" *ngIf="data?.status">
                                <div class="dl-title">Status</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.status?.text}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportInvoiceInfo" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Local Purchase Invoice Information

                        </ng-template>
                        <div style="padding: 33px">
                            <div *ngIf="registerImportInvoiceInfo">
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.invoice_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.invoice_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.permit_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.permit_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.custom_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.custom_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.camControl_number}}
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.camControl_date?.toDate() | date}}
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Bill Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.bill_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Application Form</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.co_formRef | docRef: 'name' | async}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items" *ngIf="!registerImportInvoiceInfo"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No CO Import Invoice Information!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Local Purchase Stock

                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="registerImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!registerImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not Import Stock!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="deliveryImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Delivery Local Purchase Stock

                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="deliveryImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliveryImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet do delivery CO import stock!
                            </div>
                        </div>
                    </mat-tab>



                </mat-tab-group>
            </ng-container>
            <ng-container *ngIf="collection == 'hs_codes'">
                <div class="dl-items">
                    <div class="dl-title">Code</div>
                    <div class="dl-text">:
                        <div class="data"> {{data?.code?data?.code:""}}</div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">UOM</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.uom?.description_en?data?.uom?.description_en:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Description (EN)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.description_en?data?.description_en:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Description (KH)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.description_kh?data?.description_kh:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CD</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.cd?data?.cd:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> ST</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.st?data?.st:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> VAT</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.vat?data?.vat:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> ET</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.et?data?.et:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Sub Item Of</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.subItem?data?.subItem.code:""}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'settings_country'">

                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Country
                        </ng-template>

                        <div style="padding: 0">
                            <div class="dl-items">
                                <div class="dl-title">Country Code</div>
                                <div class="dl-text">:
                                    <div class="data">{{data?.code?data?.code:""}}</div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Country Name</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.name?data?.name:""}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Department</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.department | getReferenceObject: true : 'name_en' | async}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">CO Form</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        <ng-container *ngIf="data && data?.co_form && data?.co_form?.length>0">
                                            <ng-container *ngFor="let f of data?.co_form;let i=index; let fs=first;">
                                                {{f | getReferenceObject: fs : 'name' | async}}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="portData?.length > 0" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Country Port

                        </ng-template>
                        <div style="padding: 0">
                            <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="portData?.length > 0">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col ">No.</th>
                                            <th class="table-col ">Copuntry Port Code</th>
                                            <th class="table-col ">Copuntry Port Name</th>
                                            <th class="table-col ">status</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of portData;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">
                                                {{item?.code}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.name}}
                                            </td>
                                            <td class="table-col">{{item?.status?.text}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="dl-items" *ngIf="portData?.length == 0"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No Country Port
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="data?.departmentRef" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Department
                        </ng-template>
                        <div style="padding: 0">
                            <ng-container *ngIf="data?.departmentRef">
                                <div class="dl-items">
                                    <div class="dl-title"> Department Name (EN)</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.department | getReferenceObject: true : 'name_en' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Department Name (KH)</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.department | getReferenceObject: true : 'name_kh' | async}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!data?.departmentRef"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Country No Department Yet!
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </ng-container>
            <ng-container *ngIf="collection === 'register_item'">
                <div class="" *ngIf="!store?.process" #content id="contentToConvert">

                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                CO Export Invoice
                            </ng-template>

                            <ng-container>
                                <div class="dl-items">
                                    <div class="dl-title"> CO Form</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.co_formRef | docRef:'name' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Export Invoice Number</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.invoice_number?data?.invoice_number:null}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Export Invoice Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.invoice_date?.toDate() | date}}
                                        </div>
                                    </div>

                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Product Name</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.product_name}}
                                        </div>
                                    </div>

                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> CAT</div>
                                    <div class="dl-text">:
                                        <div class="data" *ngIf="data?.cat > 0">
                                            {{data?.cat | number:'2.'}}
                                        </div>
                                    </div>

                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Amount</div>
                                    <div class="dl-text">:
                                        <div class="data" *ngIf="data?.amount_FOB > 0">
                                            {{data?.amount_FOB | currency:' ':'code'}}
                                        </div>
                                    </div>

                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Destination</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.countryRef | docRef:'name' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">

                                    <div class="dl-title"> Route</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.routeRef | docRef:'name' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Qty Packing</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.qty_packing | number:'1.'}}
                                            <span *ngIf="data?.qty_packing_UOMRef">

                                                {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Qty Unit</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.qty_unit | number:'1.'}}
                                            <span *ngIf="data?.qty_unit_UOMRef">

                                                {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </ng-container>

                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon *ngIf="material?.length > 0" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                CO Material

                            </ng-template>
                            <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="material?.length > 0">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col ">No.</th>
                                            <th class="table-col ">Code</th>
                                            <th class="table-col ">Description</th>
                                            <th class="table-col ">UOM</th>
                                            <th class="table-col ">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of material;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">
                                                {{item?.materialRef|docRef:'code'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.materialRef|docRef:'name'|async}}
                                            </td>
                                            <td class="table-col">{{item?.uomRef|docRef:'code'|async}}
                                            </td>
                                            <td class="table-col right">{{item?.qty | number}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="dl-items" *ngIf="material?.length == 0"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No material!
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon *ngIf="data?.registerCoKey" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Export CO

                            </ng-template>
                            <ng-container *ngIf="data?.registerCoRef">
                                <div class="dl-items">
                                    <div class="dl-title"> CO Number</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.registerCoRef | docRef:'co_number' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> CO Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </div>
                                    </div>
                                </div>

                                <div class="dl-items">
                                    <div class="dl-title"> Shipping Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.registerCoRef | docRefDate:'etd' | async | date}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> ADM Fee</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.registerCoRef | docRef:'admFee' | async | number}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> EMF Fee</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.registerCoRef | docRef:'emfFee' | async | number}}
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <div class="dl-items" *ngIf="!data?.registerCoRef"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register CO!
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon *ngIf="data?.pickUpBillKey" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Export Bill

                            </ng-template>
                            <ng-container *ngIf="data?.pickUpBillRef">
                                <div class="dl-items">
                                    <div class="dl-title"> Bill Number</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.pickUpBillRef | docRef:'bill_number' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Bill Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.pickUpBillRef | docRefDate:'bill_date' | async | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <div class="dl-items" *ngIf="!data?.pickUpBillRef"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register pickup bill!
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>

                                <mat-icon *ngIf="data?.inspectionKey" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Export Inspect

                            </ng-template>
                            <ng-container *ngIf="data?.inspectionRef">
                                <div class="dl-items">
                                    <div class="dl-title"> Inspect Number</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.inspectionRef | docRef:'inspect_number' | async}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Inspect Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{data?.inspectionRef | docRefDate:'inspect_date' | async | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!data?.inspectionRef"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register pickup inspect!
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>

                                <mat-icon *ngIf="deliverExport" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Delivery Export Inspect

                            </ng-template>
                            <ng-container *ngIf="deliverExport">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No.</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliverExport?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliverExport?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliverExport"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register delivery export inspect!
                            </div>
                        </mat-tab>

                    </mat-tab-group>

                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'register_product'">
                <div class="dl-items">
                    <div class="dl-title">Code</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.code?data?.code:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Bar Code</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.barCode?data?.barCode:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Description (EN)</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.name_en?data?.name_en:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Description (KH)</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.name_kh?data?.name_kh:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">UOM</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.uomRef | docRef : 'description_en' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Price</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.price?data?.price:"" | currency}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Sub Item Of</div>
                    <div class="dl-text">
                        :
                        <div class="data">
                            {{data?.subItem?data?.subItem.code:""}}
                        </div>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="collection == 'register_material'">
                <div class="dl-items">
                    <div class="dl-title"> Code</div>
                    <div class="dl-text">: {{data?.code}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Name</div>
                    <div class="dl-text">: {{data?.name}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">UOM</div>
                    <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'register_import_material'">
                <div class="dl-items">
                    <div class="dl-title"> Code</div>
                    <div class="dl-text">: {{data?.code}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Name</div>
                    <div class="dl-text">: {{data?.name}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">UOM</div>
                    <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'supplier_group'">
                <div class="dl-items">
                    <div class="dl-title">Name (EN)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.name_en?data?.name_en:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Name (KH)</div>
                    <div class="dl-text">:
                        <div class="data">{{data?.name_kh?data?.name_kh:""}}</div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'customer_group'">
                <div class="dl-items">
                    <div class="dl-title">Name (EN)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.name_en?data?.name_en:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Name (KH)</div>
                    <div class="dl-text">:
                        <div class="data">{{data?.name_kh?data?.name_kh:""}}</div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'customer'">
                <div class="dl-items">
                    <div class="dl-title">Customer ID</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.code?data?.code:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Customer Name</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.name?data?.name:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Customer Group</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.customerGroupRef | docRef:'name_en' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Customer Type</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.customer_type?data?.customer_type?.text:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Email</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.email?data?.email:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Country</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef:'name' | async }}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Address</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.address?data?.address:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Phone Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.telephone_number?data?.telephone_number:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Postal Code</div>
                    <div class="dl-text">:
                        <div class="data">{{data?.postal_code?data?.postal_code:""}}</div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Fax Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.fax_number?data?.fax_number:""}}
                        </div>
                    </div>
                </div>
                <!-- <div class="dl-items">
                    <div class="dl-title">Credit Limit</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.credit_limit?data?.credit_limit:""}}
                        </div>
                    </div>
                </div> -->
                <!-- <div class="dl-items">
                    <div class="dl-title">Discount percentage</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.discount_percentage?data?.discount_percentage:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Discount amount</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.discount_amount?data?.discount_amount:""}}
                        </div>
                    </div>
                </div> -->
                <div class="dl-items">
                    <div class="dl-title">TIN No.</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.tin_no?data?.tin_no:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Account name</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.account_name?data?.account_name:""}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'supplier'">
                <div class="dl-items">
                    <div class="dl-title">Supplier ID</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.code?data?.code:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Supplier Name</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.name?data?.name:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Supplier Group</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.supplierGroupRef | docRef:'name_en' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Supplier Type</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.supplier_type?data?.supplier_type?.text:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Email</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.email?data?.email:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Country</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef:'name' | async }}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Address</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.address?data?.address:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Phone Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.telephone_number?data?.telephone_number:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Postal Code</div>
                    <div class="dl-text">:
                        <div class="data">{{data?.postal_code?data?.postal_code:""}}</div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Fax Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.fax_number?data?.fax_number:""}}
                        </div>
                    </div>
                </div>
                <!-- <div class="dl-items">
                    <div class="dl-title">Credit Limit</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.credit_limit?data?.credit_limit:""}}
                        </div>
                    </div>
                </div> -->
                <!-- <div class="dl-items">
                    <div class="dl-title">Discount percentage</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.discount_percentage?data?.discount_percentage:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Discount amount</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.discount_amount?data?.discount_amount:""}}
                        </div>
                    </div>
                </div> -->
                <div class="dl-items">
                    <div class="dl-title">TIN No.</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.tin_no?data?.tin_no:""}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Account name</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.account_name?data?.account_name:""}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'register_invoice'">

                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            CO Import Invoice
                        </ng-template>

                        <div style="padding: 0">
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Number</div>
                                <div class="dl-text">: {{data?.invoice_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Date</div>
                                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title">Export To</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.export_to}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">CAT</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.cat?(data?.cat | number:'1.') : ''}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Import Country</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.countryRef | docRef: 'name' | async}}
                                    </div>
                                </div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title"> CO Material</div>
                                <div class="dl-text">:</div>
                            </div>

                            <div class="scrollable-table" style="margin: 0;" id="print">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col ">No.</th>
                                            <th class="table-col ">Material Code</th>
                                            <th class="table-col ">Material Name</th>
                                            <th class="table-col ">UOM</th>
                                            <th class="table-col ">Quantity</th>
                                            <th class="table-col ">Currency</th>
                                            <th class="table-col ">Price</th>
                                            <th class="table-col ">Total</th>
                                            <th class="table-col ">N.W</th>
                                            <th class="table-col ">G.W</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                            <td class="table-col">{{item?.qty_UOMRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                                            <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td>
                                            <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                            <td class="table-col right">{{item?.totalCost | number : '.2-2'}}</td>
                                            <td class="table-col right">{{item?.qty_NW}}
                                                {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty_GW}}
                                                {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportInvoiceInfo" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            CO Import Invoice Information

                        </ng-template>
                        <div style="padding: 0">
                            <div>
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.invoice_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.invoice_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.permit_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.permit_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.custom_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.custom_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.camControl_number}}
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.camControl_date?.toDate() | date}}
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Bill Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.bill_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Application Form</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.co_formRef | docRef: 'name' | async}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items" *ngIf="!registerImportInvoiceInfo"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No CO Import Invoice Information!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            CO Import Stock

                        </ng-template>
                        <div style="padding: 0">
                            <ng-container *ngIf="registerImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!registerImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not Import Stock!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="deliveryImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Delivery CO Import Stock

                        </ng-template>
                        <div style="padding: 0">
                            <ng-container *ngIf="deliveryImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliveryImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet do delivery CO import stock!
                            </div>
                        </div>
                    </mat-tab>



                </mat-tab-group>
            </ng-container>



            <ng-container *ngIf="collection == 'register_import_invoice'">
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">: {{data?.invoice_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Import Country</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef: 'name' | async}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Permit Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.permit_number}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Permit Date</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.permit_date?.toDate() | date}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Custom Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.custom_number}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Custom Date</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.custom_date?.toDate() | date}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Bill Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.bill_number}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Customs Materials</div>
                    <div class="dl-text">:</div>
                </div>

                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col center">No.</th>
                                <th class="table-col center">Customs Material Code</th>
                                <th class="table-col center">Customs Material Name</th>
                                <th class="table-col center">UOM</th>
                                <th class="table-col center">Quantity</th>
                                <th class="table-col center">Unit Price</th>
                                <th class="table-col center">Amount</th>
                                <th class="table-col center">N.W</th>
                                <th class="table-col center">G.W</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                <td class="table-col right">{{item?.qty_UOMRef|docRef:'code'|async}}
                                </td>
                                <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                                <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                <td class="table-col right">{{item?.totalCost | number : '.2-2'}}
                                </td>
                                <td class="table-col right">{{item?.qty_NW}}
                                    {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                                <td class="table-col right">{{item?.qty_GW}}
                                    {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                            </tr>
                            <tr class="table-row">
                                <td class="table-col right" colspan="4">
                                    Total
                                </td>
                                <td class="table-col right">
                                    {{ (data?.material | sumAmount:'qty') | number: '.2-2'}}
                                </td>
                                <td class="table-col">

                                </td>
                                <td class="table-col right">
                                    {{ (data?.material | sumAmount:'totalCost') | number : '.2-2'}}
                                </td>
                                <td class="table-col right">
                                    {{ (data?.material | sumAmount:'qty_NW') | number: '.2-2'}}
                                </td>
                                <td class="table-col right">
                                    {{ (data?.material | sumAmount:'qty_GW') | number: '.2-2'}}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>




            <ng-container *ngIf="collection == 'register_invoice_info'">
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">: {{data?.invoice_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Permit Number</div>
                    <div class="dl-text">: {{data?.permit_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Permit Date</div>
                    <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Custom Number</div>
                    <div class="dl-text">: {{data?.custom_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Custom Date</div>
                    <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Cam Control Number</div>
                    <div class="dl-text">: {{data?.camControl_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Cam Control Date</div>
                    <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Bill Number</div>
                    <div class="dl-text">: {{data?.bill_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Application Form</div>
                    <div class="dl-text">: {{data?.co_formRef | docRef: 'name' | async}}</div>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_co'">

                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <!-- <mat-icon class="example-tab-icon">check</mat-icon> -->
                            Export CO
                        </ng-template>

                        <div style="padding: 33px">
                            <div class="dl-items">
                                <div class="dl-title">Export CO Number</div>
                                <div class="dl-text">: {{data?.co_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Export CO Date</div>
                                <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Shipping Date</div>
                                <div class="dl-text">:
                                    {{data?.etd?.toDate() | date}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">ADM Fee (KHR)</div>
                                <div class="dl-text">:
                                    <div class="data" *ngIf="data?.admFee">
                                        {{data?.admFee | number:'1.'}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">EMF Fee (KHR)</div>
                                <div class="dl-text">:
                                    <div class="data" *ngIf="data?.emfFee">
                                        {{data?.emfFee | number:'1.'}}
                                    </div>
                                </div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title"> CO Form</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docDocRef: 'co_formRef' : 'name' | async}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Export Invoice Number</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docRef:'invoice_number' | async}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Export Invoice Date</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docRefDate:'invoice_date' | async | date}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> CAT</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docRef:'cat' | async | number:'1.'}}
                                </div>
                            </div>


                            <div class="dl-items">
                                <div class="dl-title"> Amount </div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Destination</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Route</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docDocRef: 'routeRef' : 'name' | async}}
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Qty Packing</div>
                                <div class="dl-text">:
                                    {{data?.register_itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                    <span style="padding-left: 10px;"
                                        *ngIf="(data?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'description_en'  | async)">

                                        {{data?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                                    </span>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Qty Unit</div>
                                <div class="dl-text">
                                    :
                                    {{data?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                    <span style="padding-left: 10px;"
                                        *ngIf="(data?.register_itemRef | docRef: 'qty_unit_UOMRef' : 'description_en'  | async)">

                                        {{data?.register_itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                                    </span>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Last Update</div>
                                <div class="dl-text" *ngIf="data?.updated_at">:
                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                </div>
                                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                            </div>
                            <div class="dl-items" *ngIf="data?.status">
                                <div class="dl-title">Status</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.status?.text}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="soo" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            SOO
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="soo">
                                <div class="dl-items">
                                    <div class="dl-title"> SOO Number</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{soo?.soo_number?soo?.soo_number:soo?.co_number}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> SOO Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{(soo?.soo_date?soo?.soo_date:soo?.co_date)?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="dl-items" *ngIf="!soo"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No SOO
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="soo_statement" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            SOO Statement
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="soo_statement">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{soo_statement?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> SOO Statement Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{soo_statement?.soo_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="dl-items" *ngIf="!soo_statement"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No SOO Statement
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="submitForm" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Submit Form
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="submitForm">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{submitForm?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{submitForm?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <div class="dl-items" *ngIf="!submitForm"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register submit form!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>

                            <mat-icon *ngIf="submitCrf" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Submit CRF
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="submitCrf">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{submitCrf?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{submitCrf?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!submitCrf"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register submit crf!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="deliveryCo" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Delivery Export CO
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="deliveryCo">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryCo?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryCo?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliveryCo"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register delivery CO!
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="returnApp" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Register Return
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="returnApp">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{returnApp?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{returnApp?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!returnApp"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register return!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="deliveryReturnDoc" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Delivery Return
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="deliveryReturnDoc">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryReturnDoc?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryReturnDoc?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliveryReturnDoc"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register delivery return!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerCutStock" class="mat-18" style="color:#1a73e8">check
                            </mat-icon>
                            Register CO Cut Stock
                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="registerCutStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerCutStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerCutStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!registerCutStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet register cut stock!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <!-- <mat-icon class="example-tab-icon">check</mat-icon> -->
                            Delivery CO Cut Stock
                        </ng-template>
                        <div style="padding: 33px">

                        </div>
                    </mat-tab>

                </mat-tab-group>




            </ng-container>

            <ng-container *ngIf="collection == 'register_bom'">
                <div class="dl-items">
                    <div class="dl-title"> Product</div>
                    <div class="dl-text">:
                        {{data?.productRef | docRef: 'name_en' | async}}
                    </div>
                </div>

                <div class="form-contain" *ngIf="data?.material?.length > 0" style="margin-top: 20px;">
                    <h4>CO Material</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Material Code</th>
                                    <th class="table-col ">CO Material Name</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                                <tr class="table-row" *ngIf="data?.material?.length>0">
                                    <td class="table-col" colspan="4">
                                        <strong>Total:</strong>
                                    </td>
                                    <td class="table-col">
                                        {{data?.material| sumItem: 'qty'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_master_list'">
                <div class="dl-items">
                    <div class="dl-title"> Master list code</div>
                    <div class="dl-text">: {{data?.code}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Master list ID</div>
                    <div class="dl-text">: {{data?.masterId}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Approved Date</div>
                    <div class="dl-text">: {{data?.approvedDate?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> FromDate Date</div>
                    <div class="dl-text">: {{data?.fromDate?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> ToDate Date</div>
                    <div class="dl-text">: {{data?.toDate?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Duration</div>
                    <div class="dl-text">: <span *ngIf="data?.duration">{{data?.duration}} days</span></div>
                </div>

                <div class="form-contain" *ngIf="data?.detail?.length > 0" style="margin-top: 20px;">
                    <h4>Master List Item</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">Material Code</th>
                                    <th class="table-col ">Material Name</th>
                                    <th class="table-col ">Model / Country</th>
                                    <th class="table-col ">Serial No.</th>
                                    <th class="table-col ">New/Used</th>
                                    <th class="table-col ">Unit</th>
                                    <th class="table-col ">Quantity</th>
                                    <th class="table-col ">Unit Price</th>
                                    <th class="table-col ">Total Price</th>
                                    <th class="table-col ">Remarks</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr *ngFor="let item of data?.detail;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col ">{{item?.countryRef|docRef:'name'|async}}</td>
                                    <td class="table-col ">{{item?.serialNo}}</td>
                                    <td class="table-col ">{{item?.isNew?'New':'Used'}}</td>
                                    <td class="table-col ">{{item?.uom?.code}}</td>
                                    <td class="table-col right">{{item?.qty}}</td>
                                    <td class="table-col right">{{item?.unitPrice|currency}}</td>
                                    <td class="table-col right">{{item?.total|currency}}</td>
                                    <td class="table-col ">{{item?.Remarks}}</td>
                                </tr>
                                <tr class="table-row" *ngIf="data?.detail?.length>0">
                                    <td class="table-col" colspan="9">
                                        <strong>Total:</strong>
                                    </td>
                                    <td class="table-col">
                                        {{data?.detail| sumItem: 'total' | currency}}
                                    </td>
                                    <td class="table-col "></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_purchase_order'">
                <div class="dl-items">
                    <div class="dl-title"> Purchase Number</div>
                    <div class="dl-text">: {{data?.purchase_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Purchase Date</div>
                    <div class="dl-text">: {{data?.purchase_date?.toDate() | date}}</div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Supplier</div>
                    <div class="dl-text">:
                        {{data?.supplierRef | docRef: 'name' | async}}
                    </div>
                </div>

                <div class="form-contain" *ngIf="data?.material?.length > 0" style="margin-top: 20px;">
                    <h4>CO Material</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Material Code</th>
                                    <th class="table-col ">CO Material Name</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                    <th class="table-col ">Unit Price</th>
                                    <th class="table-col ">Total</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                    <td class="table-col right">{{item?.price | currency}}</td>
                                    <td class="table-col right">{{item?.total | currency}}</td>
                                </tr>
                                <tr class="table-row" *ngIf="data?.material?.length>0">
                                    <td class="table-col" colspan="6">
                                        <strong>Total:</strong>
                                    </td>
                                    <td class="table-col">
                                        {{data?.material| sumItem: 'total' | currency}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_reapply_item'">
                <div class="dl-items">
                    <div class="dl-title"> CO Number</div>
                    <div class="dl-text">: {{data?.co_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Date</div>
                    <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Shipping Date</div>
                    <div class="dl-text">:
                        {{data?.etd?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">ADM Fee (KHR)</div>
                    <div class="dl-text">:
                        <div class="data" *ngIf="data?.admFee">
                            {{data?.admFee | number:'1.'}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">EMF Fee (KHR)</div>
                    <div class="dl-text">:
                        <div class="data" *ngIf="data?.emfFee">
                            {{data?.emfFee | number:'1.'}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        {{data?.invoice_number}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        {{data?.invoice_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.cat | number:'1.'}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                        {{data?.amount_FOB | currency:' ':'code'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        {{data?.countryRef | docRef : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.routeRef | docRef : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                        {{data?.qty_packing | number:'1.'}}
                        <span *ngIf="(data?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                            {{data?.qty_packing_UOMRef | docRef : 'code' | async}}
                        </span>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">
                        :
                        {{data?.qty_unit | number:'1.'}}
                        <span *ngIf="(data?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                            {{data?.qty_unit_UOMRef | docRef : 'code' | async}}
                        </span>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Code</th>
                                <th class="table-col ">Description</th>
                                <th class="table-col ">UOM</th>
                                <th class="table-col ">Quantity</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                <td class="table-col right">{{item?.qty | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ng-container>
            <ng-container *ngIf="collection == 'register_duplicate_item'">
                <div class="dl-items">
                    <div class="dl-title"> CO Number</div>
                    <div class="dl-text">: {{data?.co_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Date</div>
                    <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Shipping Date</div>
                    <div class="dl-text">:
                        {{data?.etd?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">ADM Fee (KHR)</div>
                    <div class="dl-text">:
                        <div class="data" *ngIf="data?.admFee">
                            {{data?.admFee | number:'1.'}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">EMF Fee (KHR)</div>
                    <div class="dl-text">:
                        <div class="data" *ngIf="data?.emfFee">
                            {{data?.emfFee | number:'1.'}}
                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef: 'co_formRef' : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        {{data?.invoice_number}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        {{data?.invoice_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.cat | number:'1.'}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                        {{data?.amount_FOB | currency:' ':'code'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        {{data?.countryRef | docRef : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.routeRef | docRef : 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                        {{data?.qty_packing | number:'1.'}}
                        <span *ngIf="(data?.qty_packing_UOMRef | docRef : 'description_en'  | async)">

                            {{data?.qty_packing_UOMRef | docRef : 'code' | async}}
                        </span>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">
                        :
                        {{data?.qty_unit | number:'1.'}}
                        <span *ngIf="(data?.qty_unit_UOMRef | docRef : 'description_en'  | async)">

                            {{data?.qty_unit_UOMRef | docRef : 'code' | async}}
                        </span>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Code</th>
                                <th class="table-col ">Description</th>
                                <th class="table-col ">UOM</th>
                                <th class="table-col ">Quantity</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                <td class="table-col right">{{item?.qty | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'pickup_bill'">
                <div class="dl-items">
                    <div class="dl-title"> Bill Number</div>
                    <div class="dl-text">: {{data?.bill_number?data?.bill_number:null}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Bill Date</div>
                    <div class="dl-text" *ngIf="data?.bill_date">: {{data?.bill_date?.toDate() | date}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.bill_date">: </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Route</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">CO Form</th>
                                <th class="table-col ">Amount</th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col"><span class="dot-status"
                                        [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.routeRef | docRef : 'name' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.countryRef | docRef : 'name' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.co_formRef | docRef : 'name' | async}}
                                </td>

                                <td class="table-col">
                                    {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                    <span *ngIf="data?.qty_packing_UOMRef">

                                        {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                                    </span>
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                    <span *ngIf="data?.qty_unit_UOMRef">

                                        {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'pickup_inspect'">
                <div class="dl-items">
                    <div class="dl-title"> Inspect Number</div>
                    <div class="dl-text">: {{data?.inspect_number?data?.inspect_number:null}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Inspect Date</div>
                    <div class="dl-text">: {{data?.inspect_date?.toDate() | date}}
                    </div>
                </div>


                <div class="dl-items">
                    <div class="dl-title"> CO Export Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Route</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">CO Form</th>
                                <th class="table-col ">Amount</th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col"><span class="dot-status"
                                        [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.routeRef | docRef : 'name' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.countryRef | docRef : 'name' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.co_formRef | docRef : 'name' | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                    <span *ngIf="data?.qty_packing_UOMRef">

                                        {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code' | async}}
                                    </span>
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                    <span *ngIf="data?.qty_unit_UOMRef">

                                        {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code' | async}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Customs Material</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="materials?.length > 0">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col center">No.</th>
                                <th class="table-col ">Customs Material Code</th>
                                <th class="table-col ">Customs Material Name</th>

                                <th class="table-col ">Quantity</th>
                                <th class="table-col ">Unit Price</th>
                                <th class="table-col ">Amount</th>

                                <!-- <th class="table-col ">UOM</th> -->
                                <th class="table-col ">N.W</th>
                                <th class="table-col ">G.W</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of materials;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                <td class="table-col right">
                                    {{item?.qtyUnit | number}}
                                    {{item?.qty_UOMRef|docRef:'code'|async}}
                                </td>
                                <td class="table-col right">{{item?.unitPrice}}</td>
                                <td class="table-col right">{{item?.totalCost}}</td>
                                <td class="table-col right">
                                    {{item?.qtyNw | number}}
                                    <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                    KG
                                </td>
                                <td class="table-col right">
                                    {{item?.qtyGw | number}}
                                    <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                    KG
                                </td>
                            </tr>
                            <tr class="table-row">
                                <td class="table-col right" colspan="3">
                                    Total
                                </td>
                                <td class="table-col right">
                                    {{ (materials | sumAmount:'qtyUnit') | number: '.2-2'}}
                                </td>
                                <td class="table-col">

                                </td>
                                <td class="table-col right">
                                    {{ (materials | sumAmount:'totalCost') | number : '.2-2'}}
                                </td>
                                <td class="table-col right">
                                    {{ (materials | sumAmount:'qtyNw') | number}}
                                </td>
                                <td class="table-col right">
                                    {{ (materials | sumAmount:'qtyGw') | number}}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>


            </ng-container>
            <ng-container *ngIf="collection == 'register_soo'">
                <ng-container *ngIf="data?.type?.key === 1;">
                    <div class="dl-items">
                        <div class="dl-title"> SOO Number</div>
                        <div class="dl-text">: {{data?.soo_number?data?.soo_number:null}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> SOO Date</div>
                        <div class="dl-text">: {{data?.soo_date?.toDate() | date}}</div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> Items</div>
                        <div class="dl-text">:</div>
                    </div>
                    <div class="scrollable-table" style="margin: 0;" id="print">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Number</th>
                                    <th class="table-col ">CO Date</th>
                                    <th class="table-col ">Invoice Number</th>
                                    <th class="table-col ">Invoice Date</th>

                                    <th class="table-col ">Shipping Date</th>
                                    <th class="table-col ">Route</th>
                                    <th class="table-col ">Destination</th>

                                    <th class="table-col ">Amount </th>
                                    <th class="table-col ">Qty Packing</th>
                                    <th class="table-col ">Qty Unit</th>
                                    <th class="table-col ">Inspect No</th>
                                    <th class="table-col ">Inspect Date</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                    <td class="table-col">
                                        <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.co_number}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.co_date?.toDate() | date}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.invoice_number}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.invoice_date?.toDate() | date}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.etd?.toDate() | date}}
                                    </td>

                                    <td class="table-col">
                                        {{item?.routeRef | docRef:'name' | async}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.countryRef | docRef:'name' | async}}
                                    </td>

                                    <td class="table-col right">
                                        {{item?.amount_FOB | currency:' ':'code'}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.qty_packing | number:'1.'}}
                                        <span style="padding-left: 10px;" *ngIf="item?.qty_packing_UOMRef">

                                            {{item?.qty_packing_UOMRef | docRef : 'code' | async}}
                                        </span>
                                    </td>
                                    <td class="table-col right">
                                        {{item?.qty_unit | number:'1.'}}
                                        <span style="padding-left: 10px;" *ngIf="item?.qty_unit_UOMRef">

                                            {{item?.qty_unit_UOMRef | docRef : 'code' | async}}
                                        </span>
                                    </td>
                                    <td class="table-col">
                                        {{item?.bill_number}}
                                    </td>
                                    <td class="table-col">
                                        {{item?.bill_date?.toDate() | date}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf="data?.type?.key === 2;">
                    <div class="dl-items">
                        <div class="dl-title"> CO Number</div>
                        <div class="dl-text">: {{data?.co_number}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> CO Date</div>
                        <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> Items</div>
                        <div class="dl-text">:</div>
                    </div>
                    <div class="scrollable-table" style="margin: 0;" id="print">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">SOO Number</th>
                                    <th class="table-col ">SOO Date</th>
                                    <th class="table-col ">Amount</th>
                                    <th class="table-col ">Quality Packing</th>
                                    <th class="table-col "> Quality Unit</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                    <td class="table-col">
                                        <span class="dot-status" [ngClass]="item?.isSubmit?'s6':'s1'"></span>{{i+1}}
                                    </td>
                                    <td class="table-col">{{item?.soo_number}}</td>
                                    <td class="table-col">
                                        {{item?.soo_date?.toDate() | date}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.amount | currency:' ':'code'}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.quality_cnt}}
                                    </td>
                                    <td class="table-col right">
                                        {{item?.quality_pcs}}
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </ng-container>


            <ng-container *ngIf="collection == 'register_soo_statement'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo?data?.refNo:null}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> SOO Statement Date</div>
                    <div class="dl-text" *ngIf="data?.soo_date">: {{data?.soo_date?.toDate() | date}}</div>
                    <div class="dl-text" *ngIf="!data?.soo_date">: </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col center" rowspan="2">No.</th>
                                <th class="table-col center" colspan="5">SOO Information</th>

                                <th class="table-col center" colspan="10">Export CO And Export Invoice
                                    Information</th>


                            </tr>
                            <tr class="table-row">
                                <th class="table-col center">SOO NO</th>
                                <th class="table-col center">SOO DATE</th>
                                <th class="table-col center">Amount</th>
                                <th class="table-col center">Quality Packing</th>
                                <th class="table-col center">Quality Unit</th>
                                <th class="table-col center">CO NO</th>
                                <th class="table-col center">CO Date</th>
                                <th class="table-col center">INVOICE NO</th>
                                <th class="table-col center">INVOICE DATE</th>
                                <th class="table-col center">Shipping Date</th>
                                <th class="table-col center">Amount</th>
                                <th class="table-col center">Quality Packing</th>
                                <th class="table-col center">Quality Unit</th>
                                <th class="table-col center">DESTINATION</th>

                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <ng-container *ngFor="let array of data?.items;let i=index">
                                <ng-container *ngIf="array.type.key === 1">
                                    <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{i+1}}</td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef|docRef:'soo_number'|async}}
                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef | docRefDate:'soo_date' | async | date}}
                                        </td>

                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef|docRef:'amount' | async | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef|docRef:'quality_cnt' | async | number:'1.' }}

                                        </td>
                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef|docRef:'quality_pcs' | async | number:'1.' }}

                                        </td>


                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'co_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.register_itemRef | docRef: 'invoice_number' | async}}
                                        </td>

                                        <td class="table-col">
                                            {{item?.register_itemRef | docRefDate: 'invoice_date' | async | date}}
                                        </td>

                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'etd' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef: 'amount_FOB' | async | currency:'
                                            ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef: 'qty_packing' | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef: 'qty_unit' | async}}

                                        </td>

                                        <td class="table-col">
                                            {{item?.register_itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                        </td>


                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col" colspan="3">
                                            Total
                                        </td>
                                        <td class="table-col right">
                                            {{array?.itemRef|docRef:'amount' | async | currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{array?.itemRef|docRef:'quality_cnt' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col right">
                                            {{array?.itemRef|docRef:'quality_pcs' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col right">
                                            {{array?.amount_FOB | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{array?.qty_packing | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{array?.qty_unit | number:'1.'}}
                                        </td>
                                        <td class="table-col">

                                        </td>



                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="array.type.key === 2">
                                    <tr class="table-row" *ngFor="let item of array?.soo_items;let s=index">
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{i+1}}</td>
                                        <td class="table-col">
                                            {{item?.soo_number}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.soo_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.amount | currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.quality_cnt | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.quality_pcs | number:'1.'}}
                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef | docRef: 'co_number' | async}}
                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{(array?.itemRef | docRef: 'co_date' | async)?.toDate() | date}}

                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{(array?.itemRef | docRef: 'invoice_number' | async)}}

                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{(array?.itemRef | docRef: 'invoice_date' | async)?.toDate() | date}}

                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{(array?.itemRef | docRef: 'etd' | async)?.toDate() | date}}

                                        </td>
                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{(array?.itemRef | docRef: 'amount_FOB' | async | currency:' ':'code')}}

                                        </td>
                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef | docRef: 'qty_packing' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col right" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef | docRef: 'qty_unit' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col" [rowSpan]="array?.soo_items?.length" *ngIf="s == 0">
                                            {{array?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                        </td>


                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col" colspan="3">
                                            Total
                                        </td>
                                        <td class="table-col right">
                                            {{array?.amount | currency:' ':'code'}}
                                        </td>
                                        <td class="table-col right">
                                            {{array?.quality_cnt | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{array?.quality_pcs | number:'1.'}}
                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col">

                                        </td>
                                        <td class="table-col right">
                                            {{(array?.itemRef | docRef: 'amount_FOB' | async | currency:' ':'code')}}

                                        </td>
                                        <td class="table-col right">
                                            {{array?.itemRef | docRef: 'qty_packing' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col right">
                                            {{array?.itemRef | docRef: 'qty_unit' | async | number:'1.'}}


                                        </td>
                                        <td class="table-col">

                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'register_submit_form'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Product Type</div>
                    <div class="dl-text">:
                        {{data?.productTypeRef | docRef: 'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.routeRef | docRef: 'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master ">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col " rowspan="2">No.</th>
                                <th class="table-col " colspan="2"> CO Reference</th>
                                <th class="table-col " rowspan="2"> Quantity Unit</th>
                                <th class="table-col " rowspan="2"> Amount</th>
                                <th class="table-col " rowspan="2"> CAT</th>
                                <th class="table-col " colspan="2"> Invoice</th>
                                <th class="table-col " colspan="2"> Join Inspection</th>
                                <th class="table-col " rowspan="2"> Bill No.</th>
                                <th class="table-col " rowspan="2"> Destination</th>
                                <th class="table-col " colspan="2"> Service Fee</th>
                            </tr>
                            <tr class="table-row">
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <th class="table-col ">ADM Fee </th>
                                <th class="table-col ">EMF Fee</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRef :'co_number' | async}}
                                </td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef:'amount_FOB' | async}}

                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'cat' | async}}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                <td class="table-col ">

                                    {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                </td>
                                <td class="table-col right">
                                    {{item?.registerCoRef | docRef:'admFee' | async }}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerCoRef | docRef:'emfFee' | async}}
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>


            <ng-container *ngIf="collection == 'register_return_application'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Application Date</div>
                    <div class="dl-text">: {{data?.application_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}

                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Product</div>
                    <div class="dl-text">:
                        {{data?.productTypeRef | docRef:'name' | async}}

                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.routeRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master ">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col " rowspan="2">No.</th>
                                <th class="table-col " colspan="2"> CO Reference</th>
                                <th class="table-col " rowspan="2"> Quantity Unit</th>
                                <th class="table-col " rowspan="2"> Amount</th>
                                <th class="table-col " rowspan="2"> CAT</th>
                                <th class="table-col " colspan="2"> Invoice</th>
                                <th class="table-col " colspan="2"> Joint Inspection</th>
                                <th class="table-col " rowspan="2"> Bill No.</th>
                                <th class="table-col " rowspan="2"> Destination</th>
                                <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                            </tr>
                            <tr class="table-row">
                                <th class="table-col ">No</th>
                                <th class="table-col " style="width: 88px;">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col " style="width: 88px;">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col " style="width: 88px;">Date</th>
                                <!-- <th class="table-col ">ADM Fee </th>
                                <th class="table-col ">EMF Fee</th> -->
                            </tr>
                        </thead>
                        <tbody class="table-body" *ngIf="data">
                            <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRef :'co_number' | async}}
                                </td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef| docRef:'inspect_number' | async }}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef | docRefDate:'inspect_date' | async | date}}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                <td class="table-col ">

                                    {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                </td>
                                <!-- <td class="table-col ">
                                    {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                </td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                </td> -->
                            </tr>
                            <tr class="table-row">
                                <td class="table-col center" colspan="3">
                                    <strong>Total:</strong>
                                </td>
                                <td class="table-col right">
                                    {{detail?.totalQuantity| number:'1.'}}
                                </td>
                                <td class="table-col right">
                                    {{detail?.totalAmount| currency:' ':'code'}}
                                </td>
                                <td class="table-col ">
                                </td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'register_import_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">: {{data?.khmerDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">: {{data?.lunarDate}}</div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col center" rowspan="2">No.</th>
                                <th class="table-col center" rowspan="2">Import Country</th>
                                <th class="table-col center" colspan="2">Invoice</th>
                                <th class="table-col center" colspan="2">Permit</th>
                                <th class="table-col center" colspan="2">Custom</th>
                                <th class="table-col center" colspan="2">Cam Control</th>

                                <th class="table-col center" rowspan="2">Purchase Number</th>
                                <th class="table-col center" rowspan="2">CAT</th>
                                <th class="table-col center" rowspan="2">Qty(Order)</th>
                                <th class="table-col center" rowspan="2">UOM</th>
                                <th class="table-col center" rowspan="2">Export to</th>
                            </tr>
                            <tr class="table-row">
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'countryRef' : 'name' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'permit_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'custom_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'camControl_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'purchase_number'|async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef|docRef:'cat'|async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef|docRef:'qty_order'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'description_en' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'export_to'|async}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-group-stock-material *ngIf="data" [key]="data?.key"></app-group-stock-material>
            </ng-container>

            <ng-container *ngIf="collection == 'register_cut_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">: {{data?.khmerDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">: {{data?.lunarDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Document Type</div>
                    <div class="dl-text">:
                        {{data?.description}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Items</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col center" rowspan="2">No.</th>
                                <th class="table-col center" colspan="2">CO</th>
                                <th class="table-col center" colspan="2">Invoice</th>
                                <th class="table-col center" rowspan="2">Shipping Date</th>
                                <th class="table-col center" rowspan="2">Description</th>
                                <th class="table-col center" rowspan="2">Route</th>
                                <th class="table-col center" rowspan="2">Destination</th>
                                <th class="table-col center" rowspan="2">CAT</th>
                                <th class="table-col center" rowspan="2">Amount</th>
                                <th class="table-col center" rowspan="2">Qty Packing</th>
                                <th class="table-col center" rowspan="2">UOM</th>
                                <th class="table-col center" rowspan="2">Qty Unit</th>
                                <th class="table-col center" rowspan="2">UOM</th>
                            </tr>
                            <tr class="table-row">
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                                <th class="table-col center">Number</th>
                                <th class="table-col center">Date</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                                <td class="table-col">
                                    {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                                <td class="table-col">
                                    {{item?.registerItemRef | docRef : 'invoice_number' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.registerItemRef| docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef| docRefDate:'etd' | async | date}}</td>
                                <td class="table-col">
                                    {{item?.itemName}}
                                </td>
                                <td class="table-col">
                                    {{item?.registerItemRef | docDocRef: 'routeRef' : 'name' | async}}</td>
                                <td class="table-col">
                                    {{item?.registerItemRef | docDocRef: 'countryRef' : 'name' | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'cat' | async}}
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'amount_FOB' | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'qty_packing' | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.registerItemRef | docDocRef: 'qty_packing_UOMRef' : 'description_en' |
                                    async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'qty_unit' | async | number:'1.'}}
                                </td>
                                <td class="table-col">
                                    {{item?.registerItemRef | docDocRef: 'qty_unit_UOMRef' : 'description_en' | async}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-group-cut-stock-material *ngIf="data" [key]="data?.key"></app-group-cut-stock-material>
            </ng-container>

            <ng-container *ngIf="collection == 'register_ob_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">: {{data?.khmerDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">: {{data?.lunarDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Code</th>
                                <th class="table-col ">Description</th>
                                <th class="table-col ">UOM</th>
                                <th class="table-col ">Quantity</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <ng-container>
                                <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'register_adjustment_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">: {{data?.khmerDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">: {{data?.lunarDate}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.materials?.length>0">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Code</th>
                                <th class="table-col ">Description</th>
                                <th class="table-col ">UOM</th>
                                <th class="table-col ">Quantity</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <ng-container>
                                <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>


            </ng-container>

            <div class="dl-items">
                <div class="dl-title"> Last Update</div>
                <div class="dl-text" *ngIf="data?.updated_at">: {{data?.updated_at?.toDate() | date:'medium'}}
                </div>
                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
            </div>
            <div class="dl-items" *ngIf="data?.status">
                <div class="dl-title">Status</div>
                <div class="dl-text">:
                    <div class="data">
                        {{data?.status?.text}}
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="store?.process">
            <div style="padding: 33px;">
                <app-line-loading></app-line-loading>
                <app-line-loading></app-line-loading>
            </div>
        </ng-container>

    </div>
</div>