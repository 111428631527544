<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">
        <!-- <div class="title">
                Print Preview
            </div> -->
            
        <ng-container *ngIf="data?.collection === 'register_item_usage' || data?.collection === 'register_item' || data?.collection === 'local_sale_inv' || data?.collection === 'local_sale_inv_usage'">
            <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="">
                <mat-icon>more_vert</mat-icon>
                {{selectedItem?.text}}
            </button>
            <mat-menu #menuCountry="matMenu">
                <ng-container *ngFor="let item of CoMaterialPrintLists;let i=index">
                    <button mat-menu-item [class.active]="selectedItem?.key === item?.key"
                        (click)="_onSelectedItem(item)">
                        <mat-icon>dialpad</mat-icon>
                        <span>{{item?.text}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content report-wrapper" id="printms">

        <div style="flex:1; display: flex; flex-direction: column;">
            <table style="width: 100%;">

                <!-- <thead>
                        <tr>
                            <td>
                                <div class="page-header-space"></div>
                            </td>
                        </tr>
                    </thead> -->

                <tbody>

                    <tr>
                        <td>
                            <div class="page">

                                <ng-container *ngIf="data?.collection == 'register_item'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">CO Export Invoice</h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Route</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.routeRef | docRef:'name' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Amount</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.amount_FOB | currency:' ':'code'}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Destination</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Form</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.co_formRef | docRef:'code' | async}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Packing</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_packing | number:'1.'}}
                                                    {{data?.item?.qty_packing_UOMRef | docRef:'code' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Qty Unit</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_unit | number:'1.'}}
                                                    {{data?.item?.qty_unit_UOMRef | docRef:'code' | async}}
                                                </td>
                                            </tr>



                                            <tr>
                                                <td>
                                                    <div class="pml-name">{{selectedItem?.text}}</div>
                                                </td>
                                                <td>
                                                    : 
                                                </td>
                                            </tr>
                                        </table>


                                    </div>
                                    <!-- <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Code</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->

                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qtyNw | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'local_sale_inv'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Local Sale Invoice</h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Sale Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Sale Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Route</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.routeRef | docRef:'name' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Amount</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.amount_FOB | currency:' ':'code'}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">Destination</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.countryRef | docRef:'name' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">CO Form</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.co_formRef | docRef:'code' | async}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Qty Packing</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_packing | number:'1.'}}
                                                    {{data?.item?.qty_packing_UOMRef | docRef:'code' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name"> Qty Unit</div>

                                                </td>
                                                <td>
                                                    : {{data?.item?.qty_unit | number:'1.'}}
                                                    {{data?.item?.qty_unit_UOMRef | docRef:'code' | async}}
                                                </td>
                                            </tr>



                                            <tr>
                                                <td>
                                                    <div class="pml-name">{{selectedItem?.text}}</div>
                                                </td>
                                                <td>
                                                    : 
                                                </td>
                                            </tr>

                                        </table>


                                    </div>
                                    <!-- <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Code</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row" *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_item_usage'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    
                                                    {{selectedItem?.key === 1?'CO Export Invoice Usage':'Customs Export Invoice Usage'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.register_itemRef | docRef:'invoice_number' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Export Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">{{selectedItem?.text}}</div>
                                                </td>
                                                <td>
                                                    : 
                                                </td>
                                            </tr>



                                        </table>


                                    </div>
                                    
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col ">Customs Material Code</th>
                                                    <th class="table-col ">Customs Material Name</th>
                                                    <th class="table-col center">Quantity</th>
                                                    <th class="table-col center">Unit Price</th>
                                                    <th class="table-col center">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                                        KG
                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        <!-- {{item?.uomRef|docRef:'code'|async}} -->
                                                        KG
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'local_sale_inv_usage'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">Local Sale Invoice Usage
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Sale Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.register_itemRef | docRef:'invoice_number' | async}}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Sale Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="pml-name">{{selectedItem?.text}}</div>
                                                </td>
                                                <td>
                                                    : 
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                      
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 1">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.material;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qty | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="scrollable-table" style="margin: 0;" *ngIf="selectedItem?.key === 2">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">Customs Material Code</th>
                                                    <th class="table-col center">Customs Material Name</th>
                                                    <th class="table-col ">Quantity</th>
                                                    <th class="table-col ">Unit Price</th>
                                                    <th class="table-col ">Amount</th>
                                                    <th class="table-col center">N.W</th>
                                                    <th class="table-col center">G.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col right">
                                                        {{item?.qtyUnit | number}}
                                                        {{item?.qty_UOMRef|docRef:'code'|async}}

                                                    </td>
                                                    <td class="table-col right">{{item?.unitPrice | number}}</td>
                                                    <td class="table-col right">{{item?.totalCost | number}}</td>

                                                    <td class="table-col right">
                                                        {{item?.qtyNw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>

                                                    <td class="table-col right">
                                                        {{item?.qtyGw | number}}
                                                        {{item?.uomRef|docRef:'code'|async}}

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>

                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>

            <div class="page-footer">
                <div class="d-flex print-date">
                    <div class="enf"
                        style="margin-right: 12px; color:#555; font-size: 8px !important; text-align: left;">
                        <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address'"></app-company-field>

                    </div>
                    <div class="flex-1"></div>
                    <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                    </div>
                    <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                </div>
            </div>
        </div>

    </div>
    <div class="dialog-footer" style="padding: 24px;">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"><span>Cancel</span></a>
        <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
            style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
            <mat-icon class="mat-18">print</mat-icon>
            Print
        </button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>
</form>