<div class="cs-form-container new-data">
    <div class="form-wrapper" style="padding:33px 0;" id="print">
        <div class="hd-row" style="padding:0 33px 16px 33px">
            <h4 class="hd-title">{{title}}</h4>
            <div class="flex-1"></div>
        </div>

        <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">

            <ng-container *ngIf="collection == 'register_material'">
                <div class="dl-items">
                    <div class="dl-title"> Code</div>
                    <div class="dl-text">: {{data?.code}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Name</div>
                    <div class="dl-text">: {{data?.name}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">UOM</div>
                    <div class="dl-text">: {{data?.uomRef | docRef:'code' | async}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="collection === 'local_sale_inv'">
                <div class="" *ngIf="!store?.process" #content id="contentToConvert">

                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Local Sale Invoice
                            </ng-template>

                            <div style="padding: 33px">
                                <ng-container>
                                    <div class="dl-items">
                                        <div class="dl-title"> CO Form</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.co_formRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Export Invoice Number</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.invoice_number?data?.invoice_number:null}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Export Invoice Date</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.invoice_date?.toDate() | date}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Product Name</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.product_name}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> CAT</div>
                                        <div class="dl-text">:
                                            <div class="data" *ngIf="data?.cat > 0">
                                                {{data?.cat | number:'2.'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Amount</div>
                                        <div class="dl-text">:
                                            <div class="data" *ngIf="data?.amount_FOB > 0">
                                                {{data?.amount_FOB | currency:' ':'code'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Destination</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.countryRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">

                                        <div class="dl-title"> Route</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.routeRef | docRef:'name' | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Qty Packing</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.qty_packing | number:'1.'}}
                                                <span *ngIf="data?.qty_packing_UOMRef">

                                                    {{data?.qty_packing_UOMRef | docRef:'code' | async}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title"> Qty Unit</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.qty_unit | number:'1.'}}
                                                <span *ngIf="data?.qty_unit_UOMRef">

                                                    {{data?.qty_unit_UOMRef | docRef:'code' | async}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-items">
                                        <div class="dl-title"> Last Update</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.updated_at?.toDate() | date:'medium'}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="dl-items">
                                        <div class="dl-title">Status</div>
                                        <div class="dl-text">:
                                            <div class="data">
                                                {{data?.status?.text}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon *ngIf="material?.length > 0" class="mat-18" style="color:#1a73e8">
                                    check
                                </mat-icon>
                                Material Usage
                            </ng-template>
                            <div style="padding: 33px">
                                <div class="scrollable-table" style="margin: 0;" id="print"
                                    *ngIf="material?.length > 0">
                                    <table class="table-wrapper tb-master">
                                        <thead class="table-header">
                                            <tr class="table-row">
                                                <th class="table-col center">No.</th>
                                                <th class="table-col center">Code</th>
                                                <th class="table-col center">Description</th>
                                                <!-- <th class="table-col center">UOM</th> -->
                                                <th class="table-col center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <tr class="table-row" *ngFor="let item of material;let i=index">
                                                <td class="table-col">{{i+1}}</td>
                                                <td class="table-col">
                                                    {{item?.materialRef|docRef:'code'|async}}
                                                </td>
                                                <td class="table-col">
                                                    {{item?.materialRef|docRef:'name'|async}}
                                                </td>
                                                <!-- <td class="table-col">
                                                </td> -->
                                                <td class="table-col right">
                                                    {{item?.qty | number}}
                                                    {{item?.uomRef|docRef:'code'|async}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="dl-items" *ngIf="material?.length == 0"
                                    style="padding: 64px; font-size: 16px; justify-content: center;">
                                    No material!
                                </div>
                            </div>
                        </mat-tab>


                    </mat-tab-group>

                </div>
            </ng-container>
            <!-- REGISTER DUPLICATE ITEM -->
            <ng-container *ngIf="collection == 'register_duplicate_item'">
                <div style="padding: 33px">
                    <div class="dl-items">
                        <div class="dl-title"> CO Number</div>
                        <div class="dl-text">: {{data?.co_number}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> CO Date</div>
                        <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Shipping Date</div>
                        <div class="dl-text">:
                            {{data?.etd?.toDate() | date}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">ADM Fee (KHR)</div>
                        <div class="dl-text">:
                            <div class="data" *ngIf="data?.admFee">
                                {{data?.admFee | number:'1.'}}
                            </div>
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">EMF Fee (KHR)</div>
                        <div class="dl-text">:
                            <div class="data" *ngIf="data?.emfFee">
                                {{data?.emfFee | number:'1.'}}
                            </div>
                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> CO Form</div>
                        <div class="dl-text">:
                            {{data?.co_formRef | docRef: 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Invoice Number</div>
                        <div class="dl-text">:
                            {{data?.invoice_number}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Invoice Date</div>
                        <div class="dl-text">:
                            {{data?.invoice_date?.toDate() | date}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> CAT</div>
                        <div class="dl-text">:
                            {{data?.cat | number:'1.'}}
                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> Amount</div>
                        <div class="dl-text">:
                            {{data?.amount_FOB | currency:' ':'code'}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Destination</div>
                        <div class="dl-text">:
                            {{data?.countryRef | docRef : 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Route</div>
                        <div class="dl-text">:
                            {{data?.routeRef | docRef : 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Qty Packing</div>
                        <div class="dl-text">:
                            {{data?.qty_packing | number:'1.'}}
                            {{data?.qty_packing_UOMRef | docRef : 'code'  | async}}

                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Qty Unit</div>
                        <div class="dl-text">
                            :
                            {{data?.qty_unit | number:'1.'}}

                            {{data?.qty_unit_UOMRef | docRef : 'code'  | async}}
                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> CO Material</div>
                        <div class="dl-text">:</div>
                    </div>

                    <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col center">No.</th>
                                    <th class="table-col center">Code</th>
                                    <th class="table-col center">Description</th>
                                    <!-- <th class="table-col center">UOM</th> -->
                                    <th class="table-col center">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <!-- <td class="table-col"></td> -->
                                    <td class="table-col right">
                                        {{item?.qty | number}}
                                        {{item?.uomRef|docRef:'code'|async}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <!-- REGISTER REAPPLY ITEM -->
            <ng-container *ngIf="collection == 'register_reapply_item'">
                <div style="padding: 33px">
                    <div class="dl-items">
                        <div class="dl-title"> CO Number</div>
                        <div class="dl-text">: {{data?.co_number}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> CO Date</div>
                        <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Shipping Date</div>
                        <div class="dl-text">:
                            {{data?.etd?.toDate() | date}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">ADM Fee (KHR)</div>
                        <div class="dl-text">:
                            <div class="data" *ngIf="data?.admFee">
                                {{data?.admFee | number:'1.'}}
                            </div>
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title">EMF Fee (KHR)</div>
                        <div class="dl-text">:
                            <div class="data" *ngIf="data?.emfFee">
                                {{data?.emfFee | number:'1.'}}
                            </div>
                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> CO Form</div>
                        <div class="dl-text">:
                            {{data?.co_formRef | docRef: 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Invoice Number</div>
                        <div class="dl-text">:
                            {{data?.invoice_number}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Invoice Date</div>
                        <div class="dl-text">:
                            {{data?.invoice_date?.toDate() | date}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> CAT</div>
                        <div class="dl-text">:
                            {{data?.cat | number:'1.'}}
                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> Amount</div>
                        <div class="dl-text">:
                            {{data?.amount_FOB | currency:' ':'code'}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Destination</div>
                        <div class="dl-text">:
                            {{data?.countryRef | docRef : 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Route</div>
                        <div class="dl-text">:
                            {{data?.routeRef | docRef : 'name'  | async}}
                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Qty Packing</div>
                        <div class="dl-text">:
                            {{data?.qty_packing | number:'1.'}}
                            {{data?.qty_packing_UOMRef | docRef : 'code'  | async}}

                        </div>
                    </div>
                    <div class="dl-items">
                        <div class="dl-title"> Qty Unit</div>
                        <div class="dl-text">
                            :
                            {{data?.qty_unit | number:'1.'}}
                            {{data?.qty_unit_UOMRef | docRef : 'code'  | async}}

                        </div>
                    </div>

                    <div class="dl-items">
                        <div class="dl-title"> CO Material</div>
                        <div class="dl-text">:</div>
                    </div>

                    <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length > 0">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">Code</th>
                                    <th class="table-col ">Description</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <!-- REGISTER ITEM STICK -->
            <ng-container *ngIf="collection == 'register_item_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_number?data?.invoice_number:null}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_date?.toDate() | date}}
                        </div>
                    </div>

                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.cat | number:'1.'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef:'name' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty (Order)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.qty_order | number:'1.'}}
                            <span *ngIf="data?.qty_order_UOMRef">

                                {{data?.qty_order_UOMRef | docRef:'code' | async}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Cost (Order)</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.cost_order | number:'1.'}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.updated_at?.toDate() | date:'medium'}}
                        </div>
                    </div>

                </div>

                <div class="form-contain" style="margin-top: 20px;">
                    <h4>CO Material</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length>0">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Material Code</th>
                                    <th class="table-col ">CO Material Name</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <app-empty *ngIf="data?.material?.length===0" [subtitle]="'This Item No Material'"></app-empty>
                </div>

            </ng-container>

            <!-- REGISTER IMPORT STOCK -->
            <ng-container *ngIf="collection == 'register_import_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Import Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table"  style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Permit Number</th>
                                <th class="table-col ">Permit Date</th>
                                <th class="table-col ">Custom Number</th>
                                <th class="table-col ">Custom Date</th>
                                <th class="table-col ">Cam Control Number</th>
                                <th class="table-col ">Cam Control Date</th>
                                <th class="table-col ">Purchase Number</th>
                                <th class="table-col ">Export To</th>
                                <th class="table-col ">CAT</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">Qty (Order)</th>
                                <th class="table-col ">UOM</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'permit_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'custom_number'|async}}
                                </td>

                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'camControl_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                </td>

                                <td class="table-col">
                                    {{item?.itemRef|docRef:'purchase_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'export_to'|async}}
                                </td>
                                <td class="table-col right">

                                    {{item?.itemRef|docRef:'cat'|async}}

                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef|docRef:'qty_order'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'code'  | async}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-group-stock-material *ngIf="data" [data]="data" [key]="data?.key"></app-group-stock-material>
            </ng-container>

            <ng-container *ngIf="collection == 'register_local_import_stock'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Local Purchase Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table"  style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Permit Number</th>
                                <th class="table-col ">Permit Date</th>
                                <th class="table-col ">Custom Number</th>
                                <th class="table-col ">Custom Date</th>
                                <th class="table-col ">Cam Control Number</th>
                                <th class="table-col ">Cam Control Date</th>
                                <th class="table-col ">Purchase Number</th>
                                <th class="table-col ">Export To</th>
                                <th class="table-col ">CAT</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">Qty (Order)</th>
                                <th class="table-col ">UOM</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'permit_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'custom_number'|async}}
                                </td>

                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'camControl_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                </td>

                                <td class="table-col">
                                    {{item?.itemRef|docRef:'purchase_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'export_to'|async}}
                                </td>
                                <td class="table-col right">

                                    {{item?.itemRef|docRef:'cat'|async}}

                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef|docRef:'qty_order'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'code'  | async}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-group-stock-material *ngIf="data" [data]="data" [key]="data?.key"></app-group-stock-material>
            </ng-container>

            <!-- REGISTER CUT STOCK ITEM -->
            <ng-container *ngIf="collection == 'register_cut_stock_item'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Description</div>
                    <div class="dl-text">:
                        {{data?.description}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Export Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">CO Number</th>
                                <th class="table-col ">CO Date</th>
                                <th class="table-col ">Shipping Date</th>
                                <th class="table-col ">ADM Fee (KHR)</th>
                                <th class="table-col ">EMF Fee (KHR)</th>
                                <!-- <th class="table-col ">CO Form</th> -->
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">CAT</th>
                                <th class="table-col ">Amount</th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                                <td class="table-col">
                                    {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                                <td class="table-col">
                                    {{item?.itemRef| docRefDate:'etd' | async | date}}</td>

                                <td class="table-col right">{{item?.itemRef|docRef:'admFee'|async}}</td>
                                <td class="table-col right">{{item?.itemRef|docRef:'emfFee'|async}}</td>

                                <td class="table-col">
                                    {{item?.itemRef | docDocRef: 'register_itemRef' : 'invoice_number'  | async}}
                                </td>
                                <td class="table-col">
                                    {{(item?.itemRef | docDocRef: 'register_itemRef' : 'invoice_date'  | async)?.toDate() | date}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docDocRef: 'register_itemRef' : 'cat' | async}}</td>

                                <td class="table-col right">
                                    {{item?.itemRef | docDocRef: 'register_itemRef' : 'amount_FOB' | async}}</td>


                                <td class="table-col right">
                                    {{item?.itemRef | docDocRef: 'register_itemRef' : 'qty_packing' | async  | number:'1.'}}</td>
                                <td class="table-col right">
                                    {{item?.itemRef | docDocRef: 'register_itemRef' : 'qty_unit' | async  | number:'1.' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-group-cut-stock-material *ngIf="data" [key]="data?.key"></app-group-cut-stock-material>
            </ng-container>

            <ng-container *ngIf="collection == 'local_cut_stock_item'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">Description</div>
                    <div class="dl-text">:
                        {{data?.description}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title">Application Date</div>
                    <div class="dl-text">:
                        {{data?.application_date?.toDate() | date}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Local Sale Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Shipping Date</th>
                                <!-- <th class="table-col ">ADM Fee (KHR)</th>
                                <th class="table-col ">EMF Fee (KHR)</th> -->
                                <!-- <th class="table-col ">CO Form</th> -->
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">CAT</th>
                                <th class="table-col ">Amount</th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                                <td class="table-col">{{i+1}}</td>

                                <td class="table-col">
                                    {{item?.registerItemRef| docRefDate:'shipping_date' | async | date}}</td>

                                <!-- <td class="table-col right">{{item?.itemRef|docRef:'admFee'|async}}</td>
                                <td class="table-col right">{{item?.itemRef|docRef:'emfFee'|async}}</td> -->

                                <td class="table-col">
                                    {{item?.registerItemRef | docRef : 'invoice_number'  | async}}
                                </td>
                                <td class="table-col">
                                    {{(item?.registerItemRef | docRef : 'invoice_date'  | async)?.toDate() | date}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'cat' | async}}</td>

                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'amount_FOB' | async}}</td>


                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'qty_packing' | async  | number:'1.'}}</td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef : 'qty_unit' | async  | number:'1.' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Material</div>
                    <div class="dl-text">:</div>
                </div>

                <app-local-group-cut-stock-material *ngIf="data" [key]="data?.key"></app-local-group-cut-stock-material>
            </ng-container>

            <!-- REGISTER CUT STICK -->
            <ng-container *ngIf="collection == 'register_cut_stock'">
                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.co_formRef | docRef:'code' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_number?data?.invoice_number:null}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_date?.toDate() | date}}
                        </div>
                    </div>

                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.cat | number:'1.'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.amount_FOB | number:'1.'}}
                        </div>
                    </div>

                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef:'name' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">

                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.routeRef | docRef:'name' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.qty_packing | number:'1.'}}
                            {{data?.qty_packing_UOMRef | docRef:'code' | async}}

                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.qty_unit | number:'1.'}}
                            {{data?.qty_unit_UOMRef | docRef:'code' | async}}

                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.updated_at?.toDate() | date:'medium'}}
                        </div>
                    </div>

                </div>

                <div class="form-contain" style="margin-top: 20px;">
                    <h4>CO Material</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length>0">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Material Code</th>
                                    <th class="table-col ">CO Material Name</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <app-empty *ngIf="data?.material?.length===0" [subtitle]="'This Item No Material'"></app-empty>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'local_cut_stock'">
                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.co_formRef | docRef:'code' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_number?data?.invoice_number:null}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.invoice_date?.toDate() | date}}
                        </div>
                    </div>

                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.cat | number:'1.'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.amount_FOB | number:'1.'}}
                        </div>
                    </div>

                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.countryRef | docRef:'name' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">

                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.routeRef | docRef:'name' | async}}
                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.qty_packing | number:'1.'}}
                            {{data?.qty_packing_UOMRef | docRef:'code' | async}}

                        </div>
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.qty_unit | number:'1.'}}
                            {{data?.qty_unit_UOMRef | docRef:'code' | async}}

                        </div>
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text">:
                        <div class="data">
                            {{data?.updated_at?.toDate() | date:'medium'}}
                        </div>
                    </div>

                </div>

                <div class="form-contain" style="margin-top: 20px;">
                    <h4>CO Material</h4>
                    <div class="scrollable-table" style="margin: 0;" id="print" *ngIf="data?.material?.length>0">
                        <table class="table-wrapper tb-master">
                            <thead class="table-header">
                                <tr class="table-row">
                                    <th class="table-col ">No.</th>
                                    <th class="table-col ">CO Material Code</th>
                                    <th class="table-col ">CO Material Name</th>
                                    <th class="table-col ">UOM</th>
                                    <th class="table-col ">Quantity</th>
                                </tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                    <td class="table-col">{{i+1}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                    <td class="table-col right">{{item?.qty | number}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <app-empty *ngIf="data?.material?.length===0" [subtitle]="'This Item No Material'"></app-empty>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_co'">
                <div class="dl-items">
                    <div class="dl-title"> CO Number</div>
                    <div class="dl-text">: {{data?.co_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Date</div>
                    <div class="dl-text">: {{data?.co_date?.toDate() | date}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Shipping Date</div>
                    <div class="dl-text">:
                        {{data?.etd?.toDate() | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">ADM Fee (KHR)</div>
                    <div class="dl-text">:
                        {{data?.admFee | currency:' ':'code'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title">EMF Fee (KHR)</div>
                    <div class="dl-text">:
                        {{data?.emfFee | currency:' ':'code'}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docDocRef: 'co_formRef' : 'name'  | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Number</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docRef:'invoice_number' | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Invoice Date</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docRefDate:'invoice_date' | async | date}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CAT</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docRef:'cat' | async | number:'1.'}}
                    </div>
                </div>


                <div class="dl-items">
                    <div class="dl-title"> Amount</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Destination</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docDocRef: 'routeRef' : 'name'  | async}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Packing</div>
                    <div class="dl-text">:
                        {{data?.register_itemRef | docRef:'qty_packing' | async | number:'1.'}}
                        {{data?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}

                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Qty Unit</div>
                    <div class="dl-text">
                        :
                        {{data?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}
                        {{data?.register_itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}

                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="collection == 'register_return_application'">
                <div class="dl-items">
                    <div class="dl-title"> Reference No.</div>
                    <div class="dl-text">: {{data?.refNo}}</div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Title</div>
                    <div class="dl-text">:
                        {{data?.titleRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> CO Form</div>
                    <div class="dl-text">:
                        {{data?.co_formRef | docRef:'name' | async}}

                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Product</div>
                    <div class="dl-text">:
                        {{data?.productTypeRef | docRef:'name' | async}}

                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Route</div>
                    <div class="dl-text">:
                        {{data?.routeRef | docRef:'name' | async}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Khmer Date</div>
                    <div class="dl-text">:
                        {{data?.khmerDate}}
                    </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Khmer Lunar Date</div>
                    <div class="dl-text">:
                        {{data?.lunarDate}}
                    </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Export CO</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master ">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col " rowspan="2">No.</th>
                                <th class="table-col " colspan="2"> CO Reference</th>
                                <th class="table-col " rowspan="2"> Quantity Unit</th>
                                <th class="table-col " rowspan="2"> Amount</th>
                                <th class="table-col " rowspan="2"> CAT</th>
                                <th class="table-col " colspan="2"> Invoice</th>
                                <th class="table-col " colspan="2"> Joint Inspection</th>
                                <th class="table-col " rowspan="2"> Bill No.</th>
                                <th class="table-col " rowspan="2"> Destination</th>
                                <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                            </tr>
                            <tr class="table-row">
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <th class="table-col ">No</th>
                                <th class="table-col ">Date</th>
                                <!-- <th class="table-col ">ADM Fee </th>
                                <th class="table-col ">EMF Fee</th> -->
                            </tr>
                        </thead>
                        <tbody class="table-body" *ngIf="data?.registerCoItems">
                            <tr *ngFor="let item of data?.registerCoItems;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRef :'co_number' | async}}
                                </td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                                </td>
                                <td class="table-col right">
                                    {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                </td>
                                <td class="table-col ">
                                    {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                </td>
                                <td class="table-col ">
                                    {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                <td class="table-col ">

                                    {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                </td>
                                <!-- <td class="table-col ">
                                    {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                </td>
                                <td class="table-col ">
                                    {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                </td> -->
                            </tr>
                            <tr class="table-row">
                                <td class="table-col center" colspan="3">
                                    <strong>Total:</strong>
                                </td>
                                <td class="table-col ">
                                    {{detail?.totalQuantity| number:'1.'}}
                                </td>
                                <td class="table-col ">
                                    {{detail?.totalAmount| currency:' ':'code'}}
                                </td>
                                <td class="table-col ">
                                    <!-- {{detail?.totalCat| number:'1.'}} -->
                                </td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <td class="table-col "></td>
                                <!-- <td class="table-col ">
                                    {{detail?.totalADMFee | number:'1.'}}
                                </td>
                                <td class="table-col ">
                                    {{detail?.totalEMFFee | number:'1.'}}
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'pickup_bill'">
                <div class="dl-items">
                    <div class="dl-title"> Bill Number</div>
                    <div class="dl-text">: {{data?.bill_number?data?.bill_number:null}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Bill Date</div>
                    <div class="dl-text" *ngIf="data?.bill_date">: {{data?.bill_date?.toDate() | date}}</div>
                    <div class="dl-text" *ngIf="!data?.bill_date">: </div>
                </div>

                <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">: {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Export Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Route</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">CO Form</th>
                                <th class="table-col ">Amount </th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.routeRef | docRef : 'name'  | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.countryRef | docRef : 'name'  | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.co_formRef | docRef : 'name'  | async}}
                                </td>

                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                    {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}

                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                    {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
            <ng-container *ngIf="collection == 'pickup_inspect'">
                <div class="dl-items">
                    <div class="dl-title"> Inspect Number</div>
                    <div class="dl-text">: {{data?.inspect_number}}</div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> Inspect Date</div>
                    <div class="dl-text">: {{data?.inspect_date?.toDate() | date}}</div>
                </div>



                <div class="dl-items">
                    <div class="dl-title"> Last Update</div>
                    <div class="dl-text" *ngIf="data?.updated_at">: {{data?.updated_at?.toDate() | date:'medium'}}
                    </div>
                    <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                </div>
                <div class="dl-items">
                    <div class="dl-title"> CO Export Invoice</div>
                    <div class="dl-text">:</div>
                </div>
                <div class="scrollable-table" style="margin: 0;" id="print">
                    <table class="table-wrapper tb-master">
                        <thead class="table-header">
                            <tr class="table-row">
                                <th class="table-col ">No.</th>
                                <th class="table-col ">Invoice Number</th>
                                <th class="table-col ">Invoice Date</th>
                                <th class="table-col ">Route</th>
                                <th class="table-col ">Destination</th>
                                <th class="table-col ">CO Form</th>
                                <th class="table-col ">Amount</th>
                                <th class="table-col ">Qty Packing</th>
                                <th class="table-col ">Qty Unit</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                <td class="table-col">{{i+1}}</td>
                                <td class="table-col">
                                    {{item?.itemRef|docRef:'invoice_number'|async}}
                                </td>
                                <td class="table-col">
                                    {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                </td>
                                <td class="table-col">
                                    {{item?.routeRef | docRef : 'name'  | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.countryRef | docRef : 'name'  | async}}
                                </td>
                                <td class="table-col">
                                    {{item?.co_formRef | docRef : 'name'  | async}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                    {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}

                                </td>
                                <td class="table-col right">
                                    {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                    {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngIf="collection == 'register_invoice'">

                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Item
                        </ng-template>

                        <div style="padding: 33px">
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Number</div>
                                <div class="dl-text">: {{data?.invoice_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Invoice Date</div>
                                <div class="dl-text">: {{data?.invoice_date?.toDate() | date}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Permit Number</div>
                                <div class="dl-text">: {{data?.permit_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Permit Date</div>
                                <div class="dl-text">: {{data?.permit_date?.toDate() | date}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Custom Number</div>
                                <div class="dl-text">: {{data?.custom_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Custom Date</div>
                                <div class="dl-text">: {{data?.custom_date?.toDate() | date}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Cam Control Number</div>
                                <div class="dl-text">: {{data?.camControl_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Cam Control Date</div>
                                <div class="dl-text">: {{data?.camControl_date?.toDate() | date}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Bill Number</div>
                                <div class="dl-text">: {{data?.bill_number}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Application Form</div>
                                <div class="dl-text">: {{data?.co_formRef | docRef: 'name'  | async}}</div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">Export To</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.export_to}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title">CAT</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.cat | number:'1.'}}
                                    </div>
                                </div>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Import Country</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.countryRef | docRef: 'name'  | async}}
                                    </div>
                                </div>
                            </div>

                            <div class="dl-items">
                                <div class="dl-title"> CO Material</div>
                                <div class="dl-text">:</div>
                            </div>

                            <div class="scrollable-table" style="margin: 0;" id="print">
                                <table class="table-wrapper tb-master">
                                    <thead class="table-header">
                                        <tr class="table-row">
                                            <th class="table-col ">No.</th>
                                            <th class="table-col ">Material Code</th>
                                            <th class="table-col ">Material Name</th>
                                            <th class="table-col ">UOM</th>
                                            <th class="table-col ">Quantity</th>
                                            <th class="table-col ">Currency</th>
                                            <th class="table-col ">Price</th>
                                            <th class="table-col ">Total</th>
                                            <th class="table-col ">N.W</th>
                                            <th class="table-col ">G.W</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr class="table-row" *ngFor="let item of data?.material;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                            <td class="table-col">{{item?.qty_UOMRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty | number : '.2-2'}}</td>
                                            <td class="table-col">{{item?.currencyRef|docRef:'name'|async}}</td>
                                            <td class="table-col right">{{item?.price | number : '.2-2'}}</td>
                                            <td class="table-col right">{{item?.totalCost | number : '.2-2'}}</td>
                                            <td class="table-col right">{{item?.qty_NW}}
                                                {{item?.qty_NW_UOMRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty_GW}}
                                                {{item?.qty_GW_UOMRef|docRef:'code'|async}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="dl-items">
                                <div class="dl-title"> Last Update</div>
                                <div class="dl-text" *ngIf="data?.updated_at">:
                                    {{data?.updated_at?.toDate() | date:'medium'}}
                                </div>
                                <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                            </div>
                            <div class="dl-items" *ngIf="data?.status">
                                <div class="dl-title">Status</div>
                                <div class="dl-text">:
                                    <div class="data">
                                        {{data?.status?.text}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportInvoiceInfo" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            CO Import Invoice Information

                        </ng-template>
                        <div style="padding: 33px">
                            <div>
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.invoice_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Invoice Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.invoice_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.permit_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Permit Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.permit_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.custom_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Custom Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.custom_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.camControl_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Cam Control Date</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.camControl_date?.toDate() | date}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Bill Number</div>
                                    <div class="dl-text">: {{registerImportInvoiceInfo?.bill_number}}</div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title">Application Form</div>
                                    <div class="dl-text">:
                                        {{registerImportInvoiceInfo?.co_formRef | docRef: 'name'  | async}}</div>
                                </div>
                            </div>
                            <div class="dl-items" *ngIf="!registerImportInvoiceInfo"
                                style="padding: 64px; font-size: 16px; justify-content: center;">
                                No CO Import Invoice Information!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="registerImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            CO Import Stock

                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="registerImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{registerImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!registerImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not Import Stock!
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="deliveryImportStock" class="mat-18" style="color:#1a73e8">
                                check
                            </mat-icon>
                            Delivery CO Import Stock

                        </ng-template>
                        <div style="padding: 33px">
                            <ng-container *ngIf="deliveryImportStock">
                                <div class="dl-items">
                                    <div class="dl-title"> Reference No</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.refNo}}
                                        </div>
                                    </div>
                                </div>
                                <div class="dl-items">
                                    <div class="dl-title"> Application Date</div>
                                    <div class="dl-text">:
                                        <div class="data">
                                            {{deliveryImportStock?.application_date?.toDate() | date}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="dl-items" *ngIf="!deliveryImportStock"
                                style="padding: 64px; font-size: 16px;  justify-content: center;">
                                Not yet do delivery CO import stock!
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>

        </div>
        <ng-container *ngIf="store?.process">
            <div style="padding: 33px;">
                <app-line-loading></app-line-loading>
                <app-line-loading></app-line-loading>
            </div>
        </ng-container>

    </div>
</div>
