<form class="dialog-wrapper form-dialog-wrapper">
    <div class="dialog-header">
        <!-- <div class="title">
                    Print Preview
                </div> -->
        <ng-container *ngIf="data?.collection === 'local_cut_stock' && onSelectedItem?.key === 1">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_local_cut_stock_print" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.collection === 'local_cut_stock' && onSelectedItem?.key === 2">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_local_cut_stock_print2" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.collection === 'register_cut_stock' && onSelectedItem?.key === 1">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_cut_stock_print" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.collection === 'register_cut_stock' && onSelectedItem?.key === 2">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_cut_stock_print2" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.collection === 'register_import_stock' && onSelectedItem?.key === 2">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_import_stock_print" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>



        <ng-container
            *ngIf="data?.collection === 'register_import_stock' && onSelectedItem?.key == 2 && importStockByCountry?.length > 0">
            <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
                {{selectedCountry?.countryRef | docRef : 'name' | async}}
            </button>
            <mat-menu #menuCountry="matMenu">
                <ng-container *ngFor="let item of importStockByCountry;let i=index">
                    <button mat-menu-item [class.active]="selectedCountry?.key === item?.key"
                        (click)="_onSelectedCountry(item)">
                        <mat-icon>dialpad</mat-icon>
                        <span>{{item?.countryRef | docRef : 'name' | async}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <ng-container *ngIf="data?.collection === 'register_local_import_stock' && onSelectedItem?.key === 2">
            <button mat-icon-button mat-button matTooltip="Show/Hide Column" [matMenuTriggerFor]="menuColumn"
                aria-label="Print">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menuColumn="matMenu">
                <app-column-visible collection="register_import_stock_print" [data]="COLUMNS">
                </app-column-visible>
            </mat-menu>
        </ng-container>

        <ng-container
            *ngIf="data?.collection === 'register_local_import_stock' && onSelectedItem?.key == 2 && importStockByCountry?.length > 0">
            <button mat-button [matMenuTriggerFor]="menuCountry" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
                {{selectedCountry?.countryRef | docRef : 'name' | async}}
            </button>
            <mat-menu #menuCountry="matMenu">
                <ng-container *ngFor="let item of importStockByCountry;let i=index">
                    <button mat-menu-item [class.active]="selectedCountry?.key === item?.key"
                        (click)="_onSelectedCountry(item)">
                        <mat-icon>dialpad</mat-icon>
                        <span>{{item?.countryRef | docRef : 'name' | async}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <ng-container *ngIf="data?.collection !== 'register_invoice_info' && data?.collection !== 'register_local_invoice_info'">
            <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                *ngIf="data?.collection === 'register_cut_stock' || 'register_import_stock'">
                <mat-icon>more_vert</mat-icon>
                {{onSelectedItem?.text}}
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let item of cutStockPrintLists;let i=index">
                    <button mat-menu-item [class.active]="onSelectedItem?.key === item?.key"
                        (click)="selectedOption(item)">
                        <mat-icon>dialpad</mat-icon>
                        <span>{{item?.text}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="data?.printTemplateData?.length > 0">
            <button mat-button [matMenuTriggerFor]="menu">Print Templates</button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let item of data?.printTemplateData">
                    <button mat-menu-item [class.active]="selectedTemplate?.key === item?.key"
                        (click)="onSelectTemplate(item)">{{item?.title}}</button>
                </ng-container>
            </mat-menu>
        </ng-container>
        <div class="flex1"></div>

        <a mat-dialog-close class="close-dialog" (click)="close()">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content report-wrapper" id="printms">
        <div style="flex:1; display: flex; flex-direction: column;">
            <table style="width: 100%;">
                <tbody>
                    <tr>
                        <td>
                            <div class="page">

                                <ng-container
                                    *ngIf="data?.collection == 'register_local_import_stock' && onSelectedItem?.key == 1">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info f-14">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info"
                                                style="text-align: left; width: 100%; position: relative;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold"
                                                            style="font-size: 14px !important; line-height: 26px;"
                                                            *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold"
                                                            style="font-size: 14px !important; line-height: 26px;"
                                                            *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf"
                                                        style="font-size: 13px !important; line-height: 26px;">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf" style="font-size: 14px !important; line-height: 26px;">
                                                    Reference No:
                                                    <span class="enf"
                                                        style="font-size: 14px !important; line-height: 26px;">{{data?.item?.refNo}}</span>
                                                </div>

                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; bottom: -33px;">
                                                    <div class="khf"
                                                        style="text-align: right;  font-size: 13px !important; line-height: 26px;">
                                                        <p>
                                                            {{data?.item?.lunarDate}}
                                                        </p>
                                                        <p style="margin-bottom: 6px;">
                                                            {{data?.item?.khmerDate}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center;padding: 20px 0;">
                                            <div style="text-align: center; font-size: 13px !important; line-height: 26px; text-decoration: underline;"
                                                class="bold khf">
                                                <h3 class="bold khf"
                                                    style="font-size: 13px !important; line-height: 26px;">សូមគោរពជូន
                                                </h3>
                                                <h3 class="bold khf"
                                                    style="font-size: 13px !important; line-height: 26px;">
                                                    {{data?.item?.titleKey | docKeyRef:'settings_title':'name_kh' | async}}នាំចេញ-នាំចូល
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap; margin-right: 8px; font-size: 13px !important; line-height: 24px;">កម្មវត្ថុ:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                សំណើសុំបញ្ចូលតួលេខសន្និធិវត្ថុធាតុដើម
                                                                <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">Form
                                                                    "{{data?.item?.co_formRef | docRef:'name' | async}}"
                                                                </span>

                                                                ដែលមានយថាប្រភេទ និង បរិមាណដូចខាងក្រោម៖
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end;margin-left: 40px;margin-top: 10px;">
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td class="khf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        - យថាប្រភេទៈ
                                                    </td>
                                                    <td>
                                                        <div class="khf"
                                                            style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                            - បរិមាណសរុបៈ
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let item of importMaterial1">
                                                    <td class="enf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <td class="enf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        {{item?.total | number : '1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;margin-right: 8px;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap; font-size: 13px !important; line-height: 24px;">ប្រភេទ:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                - នាំចូល
                                                                <span class="enf" *ngIf="importStockByCountry?.length>0"
                                                                    style="font-size: 13px !important; line-height: 24px;">
                                                                    <ng-container
                                                                        *ngFor="let item of importStockByCountry; let last = last">
                                                                        <span *ngIf="!last">{{item?.countryRef | docRef
                                                                            : 'name' | async}},
                                                                        </span>
                                                                        <span *ngIf="last">{{item?.countryRef | docRef :
                                                                            'name' | async}}</span>
                                                                    </ng-container>
                                                                    <!-- <span>
                                                                        {{selectedCountry?.countryRef | docRef : 'name'  | async}}
                                                                    </span> -->
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap;margin-right: 8px; font-size: 13px !important; line-height: 24px;">យោង:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                ឯកសារជូនភ្ជាប់ចំនួន <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">{{registerItems?.length}}</span>
                                                                ច្បាប់។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end; margin-top: 20px;margin-left: 40px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf" style="white-space: nowrap;">
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                តបតាមកម្មវត្ថុ
                                                                និងយោងខាងលើដោយក្រុមហ៊ុនយើងខ្ញុំបាននាំចូលវត្ថុធាតុដើម
                                                                សំរាប់ផលិត មុខទំនិញ
                                                                <br />
                                                                <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">CAT...5...10...21...348..28...27...</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap;margin-right: 8px; font-size: 13px !important; line-height: 24px;">សូមបញ្ជាក់ថាៈ
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                ឯកសារនាំចូលដែលបានស្នើរសុំខាងលើនេះ
                                                                ពិតជាត្រឹមត្រូវពុំមានការក្លែងបន្លំ ឬ កោសលុបឡើយ
                                                                បើមានការក្លែងបន្លំ
                                                                <br />
                                                                ឯកសារក្រុមហ៊ុនយើងខ្ញុំសូមទទួលខុសត្រូវទាំងស្រុងចំពោះមុខច្បាប់។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end; margin-top: 20px;margin-left: 40px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf" style="white-space: nowrap;">
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                សេចក្តីដូចបានជំរាបជូនខាងលើ សូមលោកនាយកមេត្តាបញ្ចូលសន្និធិ
                                                                ដល់ក្រុមហ៊ុនយើងខ្ញុំដោយអនុគ្រោះ។
                                                                <br />
                                                                សូមលោកនាយក មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីខ្ញុំ។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                        margin: 0 auto;
                                        padding: 44px;">
                                        <div class="enf" style="text-align: center;">

                                        </div>
                                        <div class="khf bold" style="text-align: center; font-size: 13px !important; line-height: 24px;">
                                            ជ.ប្រធានក្រុមហ៊ុន
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="data?.collection == 'register_local_import_stock' && onSelectedItem?.key == 2">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">
                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>
                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline; font-size: 13px;">
                                                    តារាងតាមដានវត្ថុធាតុដើមដែលបាននាំចូលមកពី
                                                    {{selectedCountry?.countryRef | docRef : 'name' | async}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master tb-import-master">
                                        <thead class="table-header">
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.importcountry?.value">ប្រទេសនាំចូល</th>
                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.rawmaterial?.value">វត្ថុធាតុដើមចំបង</th>
                                                <th class="table-col center" colspan="3" *ngIf="COLUMNS?.volume?.value">
                                                    បរិមាណ
                                                </th>
                                                <th class="table-col center" [colSpan]="getCm(COLUMNS?.invoicenumber?.value,
                                                COLUMNS?.customspermitno?.value,
                                                COLUMNS?.customsno?.value,
                                                COLUMNS?.camcontrolno?.value,
                                                COLUMNS?.ordernumber?.value,
                                                COLUMNS?.cat?.value,
                                                COLUMNS?.amount?.value
                                                )" *ngIf="COLUMNS?.invoicenumber?.value ||
                                                COLUMNS?.customspermitno?.value ||
                                                COLUMNS?.customsno?.value ||
                                                COLUMNS?.camcontrolno?.value ||
                                                COLUMNS?.ordernumber?.value ||
                                                COLUMNS?.cat?.value ||
                                                COLUMNS?.amount?.value ">យោងឯកសារនាំចូល</th>

                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.exportto?.value">ទិសដៅនាំចេញ</th>
                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value">បរិមាណ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value"> ឯកតា
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value"> USD
                                                </th>

                                                <th class="table-col center" *ngIf="COLUMNS?.invoicenumber?.value">
                                                    វិក័យប័ត្រលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.customspermitno?.value">
                                                    លិខិតអនុញ្ញាតគយលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.customsno?.value">
                                                    ប្រតិវេទន៍គយលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.camcontrolno?.value">
                                                    កាំកុងត្រូលលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.ordernumber?.value">
                                                    បញ្ជាទិញលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.cat?.value">CAT
                                                    មុខទំនិញផលិត</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.amount?.value">បរិមាណផលិត
                                                    <!-- PCS -->
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{i+1}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.importcountry?.value">
                                                            {{data?.countryRef | docRef: 'name' | async}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.rawmaterial?.value">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col right" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.qty_NW | number : '1.'}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.uomRef|docRef:'code'|async}}
                                                        </td>
                                                        <td class="table-col right" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.totalCost | number : '1.'}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.invoicenumber?.value">
                                                            {{data?.invoice_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.customspermitno?.value">
                                                            {{data?.permit_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.customsno?.value">
                                                            {{data?.custom_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.camcontrolno?.value">
                                                            {{data?.camControl_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.ordernumber?.value">
                                                            {{data?.purchase_number}}
                                                        </td>
                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.cat?.value">
                                                            <span *ngIf="(data?.itemRef|docRef:'cat'|async) > 0">
                                                                {{data?.cat}}
                                                            </span>
                                                        </td>
                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.amount?.value">
                                                            <span *ngIf="(data?.qty_order) > 0">
                                                                {{data?.qty_order}}
                                                                {{data?.qty_order_uomRef|docRef:'code' |
                                                                async}}
                                                            </span>
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.exportto?.value">
                                                            {{data?.export_to}}
                                                        </td>

                                                    </tr>
                                                </ng-container>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col center" [rowSpan]="itemMaterialGroups?.length + 1"
                                                    [colSpan]="COLUMNS?.importcountry?.value?2:1">Total:
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let item of groupedData">
                                                <tr class="table-row">
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qty_NW | number : '1.'}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.totalCost | number : '1.'}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                        margin: 0 auto;
                                        padding: 44px;">
                                        <div class="enf" style="text-align: center;">

                                        </div>
                                        <div class="khf bold" style="text-align: center;">
                                            ជ.ប្រធានក្រុមហ៊ុន
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="data?.collection == 'register_import_stock' && onSelectedItem?.key == 1">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info f-14">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info"
                                                style="text-align: left; width: 100%; position: relative;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold"
                                                            style="font-size: 14px !important; line-height: 26px;"
                                                            *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold"
                                                            style="font-size: 14px !important; line-height: 26px;"
                                                            *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf"
                                                        style="font-size: 13px !important; line-height: 26px;">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf" style="font-size: 14px !important; line-height: 26px;">
                                                    Reference No:
                                                    <span class="enf"
                                                        style="font-size: 14px !important; line-height: 26px;">{{data?.item?.refNo}}</span>
                                                </div>

                                                <div class="d-flex"
                                                    style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; bottom: -33px;">
                                                    <div class="khf"
                                                        style="text-align: right;  font-size: 13px !important; line-height: 26px;">
                                                        <p>
                                                            {{data?.item?.lunarDate}}
                                                        </p>
                                                        <p style="margin-bottom: 6px;">
                                                            {{data?.item?.khmerDate}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center;padding: 20px 0;">
                                            <div style="text-align: center; font-size: 13px !important; line-height: 26px; text-decoration: underline;"
                                                class="bold khf">
                                                <h3 class="bold khf"
                                                    style="font-size: 13px !important; line-height: 26px;">សូមគោរពជូន
                                                </h3>
                                                <h3 class="bold khf"
                                                    style="font-size: 13px !important; line-height: 26px;">
                                                    {{data?.item?.titleKey | docKeyRef:'settings_title':'name_kh' | async}}នាំចេញ-នាំចូល
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap; margin-right: 8px; font-size: 13px !important; line-height: 24px;">កម្មវត្ថុ:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                សំណើសុំបញ្ចូលតួលេខសន្និធិវត្ថុធាតុដើម
                                                                <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">Form
                                                                    "{{data?.item?.co_formRef | docRef:'name' | async}}"
                                                                </span>

                                                                ដែលមានយថាប្រភេទ និង បរិមាណដូចខាងក្រោម៖
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end;margin-left: 40px;margin-top: 10px;">
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td class="khf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        - យថាប្រភេទៈ
                                                    </td>
                                                    <td>
                                                        <div class="khf"
                                                            style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                            - បរិមាណសរុបៈ
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let item of importMaterial1">
                                                    <td class="enf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <td class="enf"
                                                        style="width: 50%; font-size: 13px !important; line-height: 24px;">
                                                        {{item?.total | number : '1.'}}
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;margin-right: 8px;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap; font-size: 13px !important; line-height: 24px;">ប្រភេទ:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                - នាំចូល
                                                                <span class="enf" *ngIf="importStockByCountry?.length>0"
                                                                    style="font-size: 13px !important; line-height: 24px;">
                                                                    <ng-container
                                                                        *ngFor="let item of importStockByCountry; let last = last">
                                                                        <span *ngIf="!last">{{item?.countryRef | docRef
                                                                            : 'name' | async}},
                                                                        </span>
                                                                        <span *ngIf="last">{{item?.countryRef | docRef :
                                                                            'name' | async}}</span>
                                                                    </ng-container>
                                                                    <!-- <span>
                                                                        {{selectedCountry?.countryRef | docRef : 'name'  | async}}
                                                                    </span> -->
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap;margin-right: 8px; font-size: 13px !important; line-height: 24px;">យោង:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                ឯកសារជូនភ្ជាប់ចំនួន <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">{{registerItems
                                                                    | sumPermitItem | async}}</span>
                                                                ច្បាប់។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end; margin-top: 20px;margin-left: 40px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf" style="white-space: nowrap;">
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                តបតាមកម្មវត្ថុ
                                                                និងយោងខាងលើដោយក្រុមហ៊ុនយើងខ្ញុំបាននាំចូលវត្ថុធាតុដើម
                                                                សំរាប់ផលិត មុខទំនិញ
                                                                <br />
                                                                <span class="enf"
                                                                    style="font-size: 13px !important; line-height: 24px;">CAT...5...10...21...348..28...27...</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: 20px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf"
                                                                style="white-space: nowrap;margin-right: 8px; font-size: 13px !important; line-height: 24px;">សូមបញ្ជាក់ថាៈ
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                ឯកសារនាំចូលដែលបានស្នើរសុំខាងលើនេះ
                                                                ពិតជាត្រឹមត្រូវពុំមានការក្លែងបន្លំ ឬ កោសលុបឡើយ
                                                                បើមានការក្លែងបន្លំ
                                                                <br />
                                                                ឯកសារក្រុមហ៊ុនយើងខ្ញុំសូមទទួលខុសត្រូវទាំងស្រុងចំពោះមុខច្បាប់។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: flex-end; margin-top: 20px;margin-left: 40px;">
                                            <div class="info">
                                                <table>
                                                    <tr>
                                                        <td style="white-space: nowrap;">
                                                            <strong class="bold khf" style="white-space: nowrap;">
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div class="khf"
                                                                style="font-size: 13px !important; line-height: 24px;">
                                                                សេចក្តីដូចបានជំរាបជូនខាងលើ សូមលោកនាយកមេត្តាបញ្ចូលសន្និធិ
                                                                ដល់ក្រុមហ៊ុនយើងខ្ញុំដោយអនុគ្រោះ។
                                                                <br />
                                                                សូមលោកនាយក មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីខ្ញុំ។
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                        margin: 0 auto;
                                        padding: 44px;">
                                        <div class="enf" style="text-align: center;">

                                        </div>
                                        <div class="khf bold" style="text-align: center; font-size: 13px !important; line-height: 24px;">
                                            ជ.ប្រធានក្រុមហ៊ុន
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="data?.collection == 'register_import_stock' && onSelectedItem?.key == 2">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">
                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>
                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline; font-size: 13px;">
                                                    តារាងតាមដានវត្ថុធាតុដើមដែលបាននាំចូលមកពី
                                                    {{selectedCountry?.countryRef | docRef : 'name' | async}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master tb-import-master">
                                        <thead class="table-header">
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.importcountry?.value">ប្រទេសនាំចូល</th>
                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.rawmaterial?.value">វត្ថុធាតុដើមចំបង</th>
                                                <th class="table-col center" colspan="3" *ngIf="COLUMNS?.volume?.value">
                                                    បរិមាណ
                                                </th>
                                                <th class="table-col center" [colSpan]="getCm(COLUMNS?.invoicenumber?.value,
                                                COLUMNS?.customspermitno?.value,
                                                COLUMNS?.customsno?.value,
                                                COLUMNS?.camcontrolno?.value,
                                                COLUMNS?.ordernumber?.value,
                                                COLUMNS?.cat?.value,
                                                COLUMNS?.amount?.value
                                                )" *ngIf="COLUMNS?.invoicenumber?.value ||
                                                COLUMNS?.customspermitno?.value ||
                                                COLUMNS?.customsno?.value ||
                                                COLUMNS?.camcontrolno?.value ||
                                                COLUMNS?.ordernumber?.value ||
                                                COLUMNS?.cat?.value ||
                                                COLUMNS?.amount?.value ">យោងឯកសារនាំចូល</th>

                                                <th class="table-col center" rowspan="2"
                                                    *ngIf="COLUMNS?.exportto?.value">ទិសដៅនាំចេញ</th>
                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value">បរិមាណ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value"> ឯកតា
                                                </th>
                                                <th class="table-col center" *ngIf="COLUMNS?.volume?.value"> USD
                                                </th>

                                                <th class="table-col center" *ngIf="COLUMNS?.invoicenumber?.value">
                                                    វិក័យប័ត្រលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.customspermitno?.value">
                                                    លិខិតអនុញ្ញាតគយលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.customsno?.value">
                                                    ប្រតិវេទន៍គយលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.camcontrolno?.value">
                                                    កាំកុងត្រូលលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.ordernumber?.value">
                                                    បញ្ជាទិញលេខ</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.cat?.value">CAT
                                                    មុខទំនិញផលិត</th>
                                                <th class="table-col center" *ngIf="COLUMNS?.amount?.value">បរិមាណផលិត
                                                    <!-- PCS -->
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{i+1}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.importcountry?.value">
                                                            {{data?.countryRef | docRef: 'name' | async}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.rawmaterial?.value">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col right" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.qty_NW | number : '1.'}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.uomRef|docRef:'code'|async}}
                                                        </td>
                                                        <td class="table-col right" *ngIf="COLUMNS?.volume?.value">
                                                            {{item?.totalCost | number : '1.'}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.invoicenumber?.value">
                                                            {{data?.invoice_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.customspermitno?.value">
                                                            {{data?.permit_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.customsno?.value">
                                                            {{data?.custom_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.camcontrolno?.value">
                                                            {{data?.camControl_number}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.ordernumber?.value">
                                                            {{data?.purchase_number}}
                                                        </td>
                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.cat?.value">
                                                            <span *ngIf="(data?.itemRef|docRef:'cat'|async) > 0">
                                                                {{data?.cat}}
                                                            </span>
                                                        </td>
                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.amount?.value">
                                                            <span *ngIf="(data?.qty_order) > 0">
                                                                {{data?.qty_order}}
                                                                {{data?.qty_order_uomRef|docRef:'code' |
                                                                async}}
                                                            </span>
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.exportto?.value">
                                                            {{data?.export_to}}
                                                        </td>

                                                    </tr>
                                                </ng-container>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col center" [rowSpan]="itemMaterialGroups?.length + 1"
                                                    [colSpan]="COLUMNS?.importcountry?.value?2:1">Total:
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let item of groupedData">
                                                <tr class="table-row">
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'name'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.qty_NW | number : '1.'}}
                                                    </td>
                                                    <td class="table-col">
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        {{item?.totalCost | number : '1.'}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                        margin: 0 auto;
                                        padding: 44px;">
                                        <div class="enf" style="text-align: center;"></div>
                                        <div class="khf bold" style="text-align: center;">
                                            ជ.ប្រធានក្រុមហ៊ុន
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'local_cut_stock' && onSelectedItem?.key == 1">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center;margin-bottom: 10px;">
                                            <div style="text-align: center; text-decoration: underline;"
                                                class="bold enf">
                                                <h3>គោរពជូន</h3>

                                                <h3>{{data?.item?.titleRef | docRef:'name_kh' | async}}
                                                    ក្រសួងពាណិជ្ជកម្ម
                                                </h3>

                                                <h3>ពាក្យស្នើសុំពិនិត្យលទ្ធភាពផលលិតកម្ម នៃផលិតផលនាំចេញ
                                                    ({{data?.item?.description}})</h3>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex" style="align-items: flex-end; margin-bottom: 6px;">
                                        <strong class="bold khf" style="white-space: nowrap;">កម្មវត្ថុ:
                                        </strong>
                                        <div class="khf" *ngIf="auth?.selectedCompany?.address">
                                            សំណើរសុំពិនិត្យលទ្ធភាពផលិតកម្ម
                                            នៅថ្ងៃទី....................ខែ....................ឆ្នាំ....................
                                            វេលាម៉ោង.........................ដូចមានក្នុងតារាងខាងក្រោម៖
                                        </div>

                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master">
                                        <thead class="table-header">

                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="3" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.invoicenumber?.value">វិក័យប័ត្រ លេខ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportitem?.value">មុខទំនិញនាំចេញ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.cat?.value">CAT
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportvalume?.value">បរិមាណនាំចេញ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.conumber?.value">C/O លេខ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.codate?.value">
                                                    CO Date</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.country?.value">ប្រទេសគោលដៅ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.route?.value">
                                                    SEA/AIR</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">



                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <tr class="table-row"
                                                    *ngIf="registerItems | sumCutStockItemQty | async; else templateLoading">
                                                    <td class="table-col center">
                                                        {{i+1}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.invoicenumber?.value">
                                                        {{data?.registerItemRef | docRef : 'invoice_number' | async}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.exportitem?.value">
                                                        {{data?.itemName}}
                                                    </td>
                                                    <td class="table-col center" *ngIf="COLUMNS?.cat?.value">
                                                        <span
                                                            *ngIf="(data?.registerItemRef | docRef : 'cat'  | async) > 0">{{data?.registerItemRef
                                                            | docRef : 'cat' | async}}</span>
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.exportvalume?.value">
                                                        {{data?.registerItemRef | docRef : 'qty_unit' | async | number :
                                                        '1.'}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.conumber?.value">
                                                        {{data?.itemRef|docRef:'co_number'|async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.codate?.value">
                                                        {{data?.itemRef| docRefDate:'co_date' | async | date}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.country?.value">
                                                        {{data?.registerItemRef | docDocRef: 'countryRef' : 'name' |
                                                        async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.route?.value">
                                                        {{data?.registerItemRef | docDocRef: 'routeRef' : 'name' |
                                                        async}}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col" colspan="2">
                                                    សរុប:
                                                </td>
                                                <td class="table-col" colspan="1">
                                                    {{registerItems?.length}} ច្បាប់
                                                </td>
                                                <td class="table-col right" colspan="2">
                                                    <strong>{{registerItems | sumCutStockItemQty | async | number :
                                                        '1.'}}</strong>
                                                </td>
                                            </tr>

                                            <ng-template #templateLoading>
                                                <app-spinner></app-spinner>

                                            </ng-template>
                                        </tbody>
                                    </table>

                                    <div style="margin-top: 12px;">
                                        <div class="khf">
                                            សូម
                                            <span class="khf bold">{{data?.item?.titleRef | docRef:'name_kh' |
                                                async}}</span>
                                            មេត្តាអនុញ្ញាតតាមសំណើខាងលើដោយអនុគ្រោះ ។
                                        </div>
                                        <div class="khf">
                                            សូម
                                            <span class="khf bold">{{data?.item?.titleRef | docRef:'name_kh' |
                                                async}}</span>
                                            មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីខ្ញុំ ។
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                            <div class="khf" style="text-align: center;"></div>
                                            <div class="khf bold">ជ.នាយកក្រុមហ៊ុន</div>
                                        </div>

                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'local_cut_stock' && onSelectedItem?.key == 2">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center; text-decoration: underline;"
                                                class="bold enf">
                                                <h3>របាយការណ៍ស្ដីពីបទដ្ឋាននៃការប្រើប្រាស់វត្ថុធាតុដើម
                                                    ({{data?.item?.description}})</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master tb-incress-size">
                                        <thead class="table-header">

                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="3" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.invoicenumber?.value">វិក័យប័ត្រ លេខ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportitem?.value">មុខទំនិញនាំចេញ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.cat?.value">
                                                    CAT</th>
                                                <th class="table-col center"
                                                    [colSpan]="(COLUMNS?.numberofproducts?.value&&COLUMNS?.amountmaterial?.value)?5:(COLUMNS?.numberofproducts?.value?2:3)"
                                                    *ngIf="COLUMNS?.numberofproducts?.value || COLUMNS?.amountmaterial?.value">
                                                    បរិមាណនាំចេញ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.conumber?.value">C/O លេខ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.codate?.value">
                                                    CO Date</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.country?.value">ប្រទេសគោលដៅ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.route?.value">
                                                    SEA/AIR</th>

                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" colspan="2"
                                                    *ngIf="COLUMNS?.numberofproducts?.value">ចំនួនផលិតផល</th>
                                                <th class="table-col center" colspan="3"
                                                    *ngIf="COLUMNS?.amountmaterial?.value">
                                                    បរិមាណវត្ថុធាតុដើមប្រើប្រាស់</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{i+1}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.invoicenumber?.value">
                                                            {{data?.registerItemRef | docRef : 'invoice_number' |
                                                            async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.exportitem?.value">
                                                            {{data?.itemName}}
                                                        </td>

                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.cat?.value">
                                                            <span
                                                                *ngIf="(data?.registerItemRef | docRef : 'cat'  | async) > 0">{{data?.registerItemRef
                                                                | docRef : 'cat' | async}}</span>
                                                        </td>


                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.numberofproducts?.value">
                                                            {{data?.registerItemRef | docRef : 'qty_unit' | async |
                                                            number : '1.'}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.numberofproducts?.value">
                                                            {{data?.registerItemRef | docDocRef: 'qty_unit_UOMRef' :
                                                            'description_en' | async}}
                                                        </td>

                                                        <td class="table-col" *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col right"
                                                            *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.qty | number : '1.'}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.uomRef|docRef:'code'|async}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.conumber?.value">
                                                            {{data?.itemRef|docRef:'co_number'|async}}
                                                        </td>


                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.codate?.value">
                                                            {{data?.itemRef| docRefDate:'co_date' | async | date}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.country?.value">
                                                            {{data?.registerItemRef | docDocRef: 'countryRef' : 'name' |
                                                            async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.route?.value">
                                                            {{data?.registerItemRef | docDocRef: 'routeRef' : 'name' |
                                                            async}}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col center" [rowSpan]="itemMaterialGroups?.length + 2"
                                                    colspan="1">Total:
                                                </td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-col">Qty
                                                </td>
                                                <td class="table-col right">
                                                    <strong>{{registerItems | sumCutStockItemQty | async | number :
                                                        '1.'}}</strong>
                                                </td>
                                                <td class="table-col center">
                                                    <strong>{{registerItems[0].registerItemRef |
                                                        docDocRef:'qty_unit_UOMRef':'code' | async }}</strong>
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let item of itemMaterialGroups">
                                                <tr class="table-row">
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        <strong>{{itemMaterials | sumItemMaterial : 'qty' : item?.key |
                                                            number : '1.'}}</strong>
                                                    </td>
                                                    <td class="table-col center">
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                                        <div class="enf" style="text-align: center;"></div>
                                        <div class="khf bold">ជ.នាយកក្រុមហ៊ុន</div>

                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.collection == 'register_cut_stock' && onSelectedItem?.key == 1">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center;margin-bottom: 10px;">
                                            <div style="text-align: center; text-decoration: underline;"
                                                class="bold enf">
                                                <h3>គោរពជូន</h3>

                                                <h3>{{data?.item?.titleRef | docRef:'name_kh' | async}}
                                                    ក្រសួងពាណិជ្ជកម្ម
                                                </h3>

                                                <h3>ពាក្យស្នើសុំពិនិត្យលទ្ធភាពផលលិតកម្ម នៃផលិតផលនាំចេញ
                                                    ({{data?.item?.description}})</h3>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex" style="align-items: flex-end; margin-bottom: 6px;">
                                        <strong class="bold khf" style="white-space: nowrap;">កម្មវត្ថុ:
                                        </strong>
                                        <div class="khf" *ngIf="auth?.selectedCompany?.address">
                                            សំណើរសុំពិនិត្យលទ្ធភាពផលិតកម្ម
                                            នៅថ្ងៃទី....................ខែ....................ឆ្នាំ....................
                                            វេលាម៉ោង.........................ដូចមានក្នុងតារាងខាងក្រោម៖
                                        </div>

                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master">
                                        <thead class="table-header">

                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="3" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.invoicenumber?.value">វិក័យប័ត្រ លេខ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportitem?.value">មុខទំនិញនាំចេញ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.cat?.value">CAT
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportvalume?.value">បរិមាណនាំចេញ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.conumber?.value">C/O លេខ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.codate?.value">
                                                    CO Date</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.country?.value">ប្រទេសគោលដៅ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.route?.value">
                                                    SEA/AIR</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">



                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <tr class="table-row"
                                                    *ngIf="registerItems | sumCutStockItemQty | async; else templateLoading">
                                                    <td class="table-col center">
                                                        {{i+1}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.invoicenumber?.value">
                                                        {{data?.registerItemRef | docRef : 'invoice_number' | async}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.exportitem?.value">
                                                        {{data?.itemName}}
                                                    </td>
                                                    <td class="table-col center" *ngIf="COLUMNS?.cat?.value">
                                                        <span
                                                            *ngIf="(data?.registerItemRef | docRef : 'cat'  | async) > 0">{{data?.registerItemRef
                                                            | docRef : 'cat' | async}}</span>
                                                    </td>
                                                    <td class="table-col right" *ngIf="COLUMNS?.exportvalume?.value">
                                                        {{data?.registerItemRef | docRef : 'qty_unit' | async | number :
                                                        '1.'}}
                                                    </td>

                                                    <td class="table-col" *ngIf="COLUMNS?.conumber?.value">
                                                        {{data?.itemRef|docRef:'co_number'|async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.codate?.value">
                                                        {{data?.itemRef| docRefDate:'co_date' | async | date}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.country?.value">
                                                        {{data?.registerItemRef | docDocRef: 'countryRef' : 'name' |
                                                        async}}
                                                    </td>
                                                    <td class="table-col" *ngIf="COLUMNS?.route?.value">
                                                        {{data?.registerItemRef | docDocRef: 'routeRef' : 'name' |
                                                        async}}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col" colspan="2">
                                                    សរុប:
                                                </td>
                                                <td class="table-col" colspan="1">
                                                    {{registerItems?.length}} ច្បាប់
                                                </td>
                                                <td class="table-col right" colspan="2">
                                                    <strong>{{registerItems | sumCutStockItemQty | async | number :
                                                        '1.'}}</strong>
                                                </td>
                                            </tr>

                                            <ng-template #templateLoading>
                                                <app-spinner></app-spinner>

                                            </ng-template>
                                        </tbody>
                                    </table>

                                    <div style="margin-top: 12px;">
                                        <div class="khf">
                                            សូម
                                            <span class="khf bold">{{data?.item?.titleRef | docRef:'name_kh' |
                                                async}}</span>
                                            មេត្តាអនុញ្ញាតតាមសំណើខាងលើដោយអនុគ្រោះ ។
                                        </div>
                                        <div class="khf">
                                            សូម
                                            <span class="khf bold">{{data?.item?.titleRef | docRef:'name_kh' |
                                                async}}</span>
                                            មេត្តាទទួលនូវការគោរពដ៏ខ្ពង់ខ្ពស់អំពីខ្ញុំ ។
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: space-between; padding: 12px 68px;">
                                            <div class="khf" style="text-align: center;">

                                            </div>
                                            <div class="khf bold">ជ.នាយកក្រុមហ៊ុន</div>
                                        </div>

                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="data?.collection == 'register_cut_stock' && onSelectedItem?.key == 2">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Reference No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    Date:
                                                    <span class="enf">{{data?.item?.application_date?.toDate() |
                                                        date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center; text-decoration: underline;"
                                                class="bold enf">
                                                <h3>របាយការណ៍ស្ដីពីបទដ្ឋាននៃការប្រើប្រាស់វត្ថុធាតុដើម
                                                    ({{data?.item?.description}})</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master tb-incress-size">
                                        <thead class="table-header">

                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="3" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.invoicenumber?.value">វិក័យប័ត្រ លេខ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.exportitem?.value">មុខទំនិញនាំចេញ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.cat?.value">
                                                    CAT</th>
                                                <th class="table-col center"
                                                    [colSpan]="(COLUMNS?.numberofproducts?.value&&COLUMNS?.amountmaterial?.value)?5:(COLUMNS?.numberofproducts?.value?2:3)"
                                                    *ngIf="COLUMNS?.numberofproducts?.value || COLUMNS?.amountmaterial?.value">
                                                    បរិមាណនាំចេញ</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.conumber?.value">C/O លេខ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.codate?.value">
                                                    CO Date</th>
                                                <th class="table-col center" rowspan="3"
                                                    *ngIf="COLUMNS?.country?.value">ប្រទេសគោលដៅ</th>
                                                <th class="table-col center" rowspan="3" *ngIf="COLUMNS?.route?.value">
                                                    SEA/AIR</th>

                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" colspan="2"
                                                    *ngIf="COLUMNS?.numberofproducts?.value">ចំនួនផលិតផល</th>
                                                <th class="table-col center" colspan="3"
                                                    *ngIf="COLUMNS?.amountmaterial?.value">
                                                    បរិមាណវត្ថុធាតុដើមប្រើប្រាស់</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{i+1}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.invoicenumber?.value">
                                                            {{data?.registerItemRef | docRef : 'invoice_number' |
                                                            async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.exportitem?.value">
                                                            {{data?.itemName}}
                                                        </td>

                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.cat?.value">
                                                            <span
                                                                *ngIf="(data?.registerItemRef | docRef : 'cat'  | async) > 0">{{data?.registerItemRef
                                                                | docRef : 'cat' | async}}</span>
                                                        </td>


                                                        <td class="table-col right" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.numberofproducts?.value">
                                                            {{data?.registerItemRef | docRef : 'qty_unit' | async |
                                                            number : '1.'}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.numberofproducts?.value">
                                                            {{data?.registerItemRef | docDocRef: 'qty_unit_UOMRef' :
                                                            'description_en' | async}}
                                                        </td>

                                                        <td class="table-col" *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col right"
                                                            *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.qty | number : '1.'}}
                                                        </td>
                                                        <td class="table-col" *ngIf="COLUMNS?.amountmaterial?.value">
                                                            {{item?.uomRef|docRef:'code'|async}}
                                                        </td>

                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.conumber?.value">
                                                            {{data?.itemRef|docRef:'co_number'|async}}
                                                        </td>


                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.codate?.value">
                                                            {{data?.itemRef| docRefDate:'co_date' | async | date}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.country?.value">
                                                            {{data?.registerItemRef | docDocRef: 'countryRef' : 'name' |
                                                            async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0 && COLUMNS?.route?.value">
                                                            {{data?.registerItemRef | docDocRef: 'routeRef' : 'name' |
                                                            async}}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>

                                            <tr class="table-row">
                                                <td class="table-col center" [rowSpan]="itemMaterialGroups?.length + 2"
                                                    colspan="1">Total:
                                                </td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-col">Qty
                                                </td>
                                                <td class="table-col right">
                                                    <strong>{{registerItems | sumCutStockItemQty | async | number :
                                                        '1.'}}</strong>
                                                </td>
                                                <td class="table-col center">
                                                    <strong>{{registerItems[0].registerItemRef |
                                                        docDocRef:'qty_unit_UOMRef':'code' | async }}</strong>
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let item of itemMaterialGroups">
                                                <tr class="table-row">
                                                    <td class="table-col">
                                                        {{item?.materialRef|docRef:'code'|async}}
                                                    </td>
                                                    <td class="table-col right">
                                                        <strong>{{itemMaterials | sumItemMaterial : 'qty' : item?.key |
                                                            number : '1.'}}</strong>
                                                    </td>
                                                    <td class="table-col center">
                                                        {{item?.uomRef|docRef:'code'|async}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                        margin: 0 auto;
                                        padding: 44px;">
                                        <div class="enf" style="text-align: center;"></div>
                                        <div class="khf bold">ជ.នាយកក្រុមហ៊ុន</div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_adjustment_stock'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Refer No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span class="enf">{{data?.item?.application_date?.toDate() |
                                                            date}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    តារាងតាមដានវត្ថុធាតុដើមដែលបាននាំចូលពី</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master">
                                        <thead class="table-header">
                                            <!-- <tr class="table-row">
                                                <td class="table-col" colspan="10">
                                                </td>
                                            </tr> -->
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="2">
                                                    វត្ថុធាតុដើមចំបង
                                                </th>

                                                <th class="table-col center" colspan="2">បរិមាណ</th>
                                                <th class="table-col center" colspan="7">យោងឯកសារនាំចូល</th>

                                                <th class="table-col center" rowspan="2">ទិសដៅនាំចេញ</th>

                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center">Qty</th>
                                                <th class="table-col center">គិតជា USD</th>
                                                <th class="table-col center">វិក័យប័ត្រលេខ</th>
                                                <th class="table-col center">លិខិតអនុញ្ញាតគយលេខ</th>
                                                <th class="table-col center">ប្រតិវេទន៍គយលេខ</th>
                                                <th class="table-col center">កាំកុងត្រូលលេខ</th>
                                                <th class="table-col center">បញ្ជាទិញលេខ</th>
                                                <th class="table-col center">CAT មុខទំនិញផលិត</th>
                                                <th class="table-col center">បរិមាណផលិត PCS</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center">
                                                            {{i+1}}
                                                        </td>

                                                        <td class="table-col">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col">
                                                            {{item?.materialRef|docRef:'qty'|async}}
                                                        </td>
                                                        <td class="table-col">

                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>

                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                            <tr class="table-row">
                                                <td class="table-col center" rowspan="3" colspan="2">Total:</td>
                                                <td class="table-col center">Inspection</td>
                                                <td class="table-col center">Invoice</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-col center">
                                                    {{data?.item?.registerCoItems?.length}}
                                                </td>
                                                <td class="table-col center">
                                                    <!-- {{invoice}} -->
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                                        <div class="enf" style="text-align: center;">
                                            Received.by / Date
                                        </div>
                                        <div class="enf" style="text-align: center;">
                                            Deliver.by / Date
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="data?.collection == 'register_ob_stock'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <span class="enf">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                </div>
                                                <div class="enf">

                                                    Refer No:
                                                    <span class="enf">{{data?.item?.refNo}}</span>

                                                </div>
                                                <div class="enf">
                                                    <p>
                                                        Date:
                                                        <span class="enf">{{data?.item?.application_date?.toDate() |
                                                            date}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: flex-end; position: absolute; right: 14px; top: 58px;">
                                            <div class="khf" style="text-align: right;">
                                                <p>
                                                    {{data?.item?.lunarDate}}
                                                </p>
                                                <p style="margin-bottom: 6px;">
                                                    {{data?.item?.khmerDate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: center; justify-content: center;">
                                            <div style="text-align: center;" class="bold enf">
                                                <h3 style="text-decoration: underline;">
                                                    តារាងតាមដានវត្ថុធាតុដើមដែលបាននាំចូលពី</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table-wrapper tb-master-print tb-master">
                                        <thead class="table-header">
                                            <!-- <tr class="table-row">
                                                <td class="table-col" colspan="10">
                                                </td>
                                            </tr> -->
                                            <tr class="table-row">
                                                <th class="table-col center" rowspan="2" style="width: 26px;">
                                                    <div class="prin-br">
                                                        ល.រ
                                                    </div>
                                                </th>
                                                <th class="table-col center" rowspan="2">
                                                    វត្ថុធាតុដើមចំបង
                                                </th>

                                                <th class="table-col center" colspan="2">បរិមាណ</th>
                                                <th class="table-col center" colspan="7">យោងឯកសារនាំចូល</th>

                                                <th class="table-col center" rowspan="2">ទិសដៅនាំចេញ</th>

                                            </tr>
                                            <tr class="table-row">
                                                <th class="table-col center">Qty</th>
                                                <th class="table-col center">គិតជា USD</th>
                                                <th class="table-col center">វិក័យប័ត្រលេខ</th>
                                                <th class="table-col center">លិខិតអនុញ្ញាតគយលេខ</th>
                                                <th class="table-col center">ប្រតិវេទន៍គយលេខ</th>
                                                <th class="table-col center">កាំកុងត្រូលលេខ</th>
                                                <th class="table-col center">បញ្ជាទិញលេខ</th>
                                                <th class="table-col center">CAT មុខទំនិញផលិត</th>
                                                <th class="table-col center">បរិមាណផលិត PCS</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">

                                            <ng-container *ngFor="let data of submitItem;let i=index">
                                                <ng-container *ngFor="let item of data?.material;let s=index">
                                                    <tr class="table-row">
                                                        <td class="table-col center">
                                                            {{i+1}}
                                                        </td>

                                                        <td class="table-col">
                                                            {{item?.materialRef|docRef:'name'|async}}
                                                        </td>
                                                        <td class="table-col">
                                                            {{item?.materialRef|docRef:'qty'|async}}
                                                        </td>
                                                        <td class="table-col">

                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>
                                                        <td class="table-col" [rowSpan]="data?.material?.length"
                                                            *ngIf="s == 0">
                                                            {{data?.itemRef|docRef:'invoice_number'|async}}
                                                        </td>

                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                            <tr class="table-row">
                                                <td class="table-col center" rowspan="3" colspan="2">Total:</td>
                                                <td class="table-col center">Inspection</td>
                                                <td class="table-col center">Invoice</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-col center">
                                                    {{data?.item?.registerCoItems?.length}}
                                                </td>
                                                <td class="table-col center">
                                                    <!-- {{invoice}} -->
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <div class="d-flex" style="align-items: center; justify-content: space-between;width: 80%;
                                    margin: 0 auto;
                                    padding: 44px;">
                                        <div class="enf" style="text-align: center;">
                                            Received.by / Date
                                        </div>
                                        <div class="enf" style="text-align: center;">
                                            Deliver.by / Date
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_invoice_info'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">

                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'CO Import Invoice Information'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Import Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Permit Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.permit_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Permit Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.permit_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Custom Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.custom_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Custom Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.custom_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Cam Control Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.camControl_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Cam Control Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.camControl_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Bill Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.bill_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Application Form</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_formRef | docRef: 'name' | async}}
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data?.collection == 'register_local_invoice_info'">
                                    <div class="report-header">
                                        <div class="d-flex">
                                            <div class="flex-1"></div>
                                            <div class="title-info bavacana">
                                                <div>ព្រះរាជាណាចក្រកម្ពុជា</div>
                                                <div>ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                                                <div class="star">************</div>
                                            </div>
                                        </div>
                                        <div class="d-flex" style="align-items: flex-end; margin-top: -28px;">
                                            <div class="info" style="text-align: left;">
                                                <div class="d-flex">
                                                    <div *ngIf="!selectedTemplate">
                                                        <div class="khf bold" *ngIf="auth?.selectedCompany?.name_kh">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_kh'"></app-company-field>
                                                        </div>
                                                        <div class="enf bold" *ngIf="auth?.selectedCompany?.name_en">
                                                            <app-company-field [key]="auth?.selectedCompany?.key"
                                                                [feild]="'name_en'"></app-company-field>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedTemplate">
                                                        <div class="khf bold">
                                                            {{selectedTemplate?.company_kh}}
                                                        </div>
                                                        <div class="enf bold">
                                                            {{selectedTemplate?.company_en}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="enf" *ngIf="!selectedTemplate">
                                                        <app-company-field [key]="auth?.selectedCompany?.key"
                                                            [feild]="'address_kh'"></app-company-field>
                                                    </span>
                                                    <span class="enf" *ngIf="selectedTemplate">
                                                        {{selectedTemplate?.address_en}}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            style="align-items: center; justify-content: center; margin-top: 16px;">
                                            <div style="text-align: center;" class="bold enf">

                                                <h3 style="text-decoration: underline;">
                                                    {{selectedTemplate?selectedTemplate?.title:'Local Purchase Invoice Information'}}
                                                </h3>
                                            </div>
                                        </div>
                                        <table class="pitem-table">
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Purchase Invoice Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Local Purchase Invoice Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.invoice_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Permit Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.permit_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Permit Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.permit_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Custom Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.custom_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Custom Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.custom_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Cam Control Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.camControl_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Cam Control Date</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.camControl_date?.toDate() | date}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Bill Number</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.bill_number}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Application Form</div>
                                                </td>
                                                <td>
                                                    : {{data?.item?.co_formRef | docRef: 'name' | async}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="pml-name">Customs Material</div>
                                                </td>
                                                <td>
                                                    : 
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                    <!-- <div class="scrollable-table" style="margin: 0;">
                                        <table class="pitem-table table-wrapper tb-master ">
                                            <thead class="table-header">
                                                <tr class="table-row">
                                                    <th class="table-col center">No.</th>
                                                    <th class="table-col center">CO Material</th>
                                                    <th class="table-col center">Description</th>
                                                    <th class="table-col center">UOM</th>
                                                    <th class="table-col center">N.W</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="table-row"
                                                    *ngFor="let item of data?.item?.importMaterial;let i=index">
                                                    <td class="table-col">{{i+1}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                                    <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                                    <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                                    <td class="table-col right">{{item?.qtyNw | number:'1.'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <div class="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>

            <div class="page-footer">
                <div class="d-flex">

                    <div class="enf"
                        style="margin-right: 12px; color:#555; font-size: 8px !important; text-align: left;">
                        <ng-container *ngIf="!selectedTemplate">
                            <app-company-field [key]="auth?.selectedCompany?.key" [feild]="'address'">
                            </app-company-field>
                        </ng-container>
                        <ng-container *ngIf="selectedTemplate">
                            {{selectedTemplate?.address_en}}
                        </ng-container>
                    </div>
                    <div class="flex-1"></div>
                    <div class="enf" style="margin-right: 12px; font-size: 8px !important;">{{data?.item?.refNo}}
                    </div>
                    <div class="enf" style="font-size: 8px !important;">Print On: {{printDate | date}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer" style="padding: 24px;">

        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="close()"><span>Cancel</span></a>
        <button mat-button class="mat-puc" cdkFocusInitial (click)="print()"
            style="border: 1px solid #727273;margin-left: 12px;background: #fff;color: #333;">
            <mat-icon class="mat-18">print</mat-icon>
            Print
        </button>
    </div>
    <app-spinner *ngIf="loading"></app-spinner>
</form>