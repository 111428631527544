import { Component, OnInit, Inject } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { Auth } from 'src/app/stores/auth.store';
import { MappingService } from 'src/app/services/mapping.service';
import { cutStockOption, importStockOption } from 'src/app/dummy/status';
import { sum } from 'src/app/services/convert.service';
import { from } from 'rxjs';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { groupBy, mergeMap, map, toArray } from 'rxjs/operators';
import { SettingStore } from 'src/app/stores/settings/setting.store';

@Component({
  selector: 'app-stock-application-print',
  templateUrl: './stock-application-print.component.html',
  styleUrls: ['./stock-application-print.component.scss']
})
export class StockApplicationPrintComponent implements OnInit {

  submitItem = [];
  itemMaterials = [];
  itemMaterialGroups = [];
  importMaterial1 = [];
  registerItems = [];
  printDate: Date;
  loading;
  COLUMNS: any = null;
  COLUMNSInspection: any = null;

  onSelectedItem: any;
  cutStockPrintLists = cutStockOption;
  importStockByCountry: any = [];
  selectedCountry: any = null;
  groupedData: any = [];
  selectedTemplate = null

  constructor(
    public dialogRef: MatDialogRef<StockApplicationPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ps: PrintService,
    public auth: Auth,
    private store:SettingStore

  ) { }

  ngOnInit() {
    this.printDate = new Date();
    console.log('data',this.data);
    
    if (this.data.collection == 'register_import_stock' || this.data.collection == 'register_local_import_stock') {
      this.cutStockPrintLists = importStockOption

      this.onSelectedItem = this.cutStockPrintLists[0];
      const data = this.data.item.items;

      this.importStockByCountry = MappingService.groupBy(data, "countryKey", "countryKey");
      this.selectedCountry = this.importStockByCountry.length > 0 ? this.importStockByCountry[0] : null;
      const dataByCountry = data.filter(m => m.countryKey === this.selectedCountry.countryKey)

      this.submitItem = dataByCountry.map(s => ({ ...s }))
      const b = 12 - this.submitItem.length;
      if (this.submitItem.length <= 12) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = dataByCountry.map(s => ({ ...s }))
      }

      this.registerItems = this.data.item.items;
      if (this.onSelectedItem.key == 2) this.registerItems = this.submitItem;
      if (this.registerItems.length > 0) {
        this.registerItems.forEach(m => {
          if (m.material && m.material.length > 0) {
            this.itemMaterials = this.itemMaterials.concat(m.material);
          }
        })
        this.itemMaterialGroups = MappingService.groupBy(this.itemMaterials, "key", "key");
        const itemArray: Array<any> = MappingService.groupBy(this.itemMaterials, "key", "key");
        const materialArray: Array<any> = MappingService.groupBy(this.itemMaterials, "materialKey", "materialKey");
        materialArray.forEach(m => {
          const materialTotal = itemArray.filter(i => i.materialKey === m.materialKey);
          const total = sum(materialTotal, "qty_NW");
          this.importMaterial1.push({ ...m, total: total })
        })
        if (this.itemMaterials && this.itemMaterials.length > 0) {
          from(this.itemMaterials)
            .pipe(
              groupBy(x => x.materialKey), // using groupBy from Rxjs
              mergeMap(group => group.pipe(toArray())),// GroupBy dont create a array object so you have to flat it
              // flatMap(group => group.toArray())// GroupBy dont create a array object so you have to flat it
              map(g => {// mapping 
                return {
                  materialKey: g[0].key,
                  materialRef: g[0].materialRef,
                  uomRef: g[0].uomRef,
                  qty_NW: _.sumBy(g, 'qty_NW'), // using lodash to sum quantity
                  totalCost: _.sumBy(g, 'totalCost'),
                  // using lodash to sum price
                }
              }),
              toArray() //.toArray because I guess you want to loop on it with ngFor      
            )

            .subscribe(d => this.groupedData = d);
        }

      }

      this.auth.fetchColumns('register_import_stock_print', doc => {
        if (doc) {
          this.COLUMNS = doc;
          this.loading = false;
        }
      })

    }


    if (this.data.collection == 'register_cut_stock') {

      this.onSelectedItem = this.cutStockPrintLists[0];

      const data = this.data.item.registerCo;
      this.submitItem = data.map(s => ({ ...s }))
      const b = 12 - this.submitItem.length;
      if (this.submitItem.length <= 12) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = data.map(s => ({ ...s }))
      }

      if (this.submitItem.length > 0) {
        this.submitItem.forEach(m => {
          if (m.material && m.material.length > 0) {
            this.itemMaterials = this.itemMaterials.concat(m.material);
          }
        })
        this.itemMaterialGroups = MappingService.groupBy(this.itemMaterials, "key", "key");
        this.registerItems = this.data.item.registerCo;
      }
      this.auth.fetchColumns('register_cut_stock_print', doc => {
        if (doc) {
          this.COLUMNS = doc;
        }
      })

    }


    if (this.data.collection == 'local_cut_stock') {

      this.onSelectedItem = this.cutStockPrintLists[0];

      const data = this.data.item.registerCo;
      this.submitItem = data.map(s => ({ ...s }))
      const b = 12 - this.submitItem.length;
      if (this.submitItem.length <= 12) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = data.map(s => ({ ...s }))
      }

      if (this.submitItem.length > 0) {
        this.submitItem.forEach(m => {
          if (m.material && m.material.length > 0) {
            this.itemMaterials = this.itemMaterials.concat(m.material);
          }
        })
        this.itemMaterialGroups = MappingService.groupBy(this.itemMaterials, "key", "key");
        this.registerItems = this.data.item.registerCo;
      }
      this.auth.fetchColumns('register_local_cut_stock_print', doc => {
        if (doc) {
          this.COLUMNS = doc;
        }
      })

    }


  }
  close() {
    this.dialogRef.close()
  }
  print() {
    if ((this.data.collection == 'register_import_stock' && this.onSelectedItem.key == 1) || (this.data.collection == 'register_local_import_stock' && this.onSelectedItem.key == 1) || (this.data.collection == 'register_cut_stock' && this.onSelectedItem.key == 1) || (this.data.collection == 'local_cut_stock' && this.onSelectedItem.key == 1)){
      this.ps.print('#printms', 'a4');
    }
    else{
      this.ps.print('#printms', 'a4l');
    }

    this.store.printHistory(this.data?.item, this.auth.user, success=>{})

  }
  getCm(a, b, c, d, e, f, g) {
    let mm = [];
    mm.push(a, b, c, d, e, f, g)
    let value = mm.filter(f => f === true)
    return value.length
  }
  onSelectTemplate(item) {
    this.selectedTemplate = item;
  }
  selectedOption(item) {
    this.onSelectedItem = item;
    if (this.data.collection == 'register_import_stock' || this.data.collection == 'register_local_import_stock') {
      this._onSelectedCountry(this.selectedCountry);
      this.auth.fetchColumns('register_import_stock_print', doc => {
        if (doc) {
          this.COLUMNS = doc;
        }
      })
    }

    if (this.data.collection == 'register_cut_stock') {
      if (this.onSelectedItem.key === 1) {
        this.auth.fetchColumns('register_cut_stock_print', doc => {
          if (doc) {
            this.COLUMNS = doc;
          }
        })
      } else {
        this.auth.fetchColumns('register_cut_stock_print2', doc => {
          if (doc) {
            this.COLUMNS = doc;
          }
        })
      }
    }


    if (this.data.collection == 'local_cut_stock') {
      if (this.onSelectedItem.key === 1) {
        this.auth.fetchColumns('register_local_cut_stock_print', doc => {
          if (doc) {
            this.COLUMNS = doc;
          }
        })
      } else {
        this.auth.fetchColumns('register_local_cut_stock_print2', doc => {
          if (doc) {
            this.COLUMNS = doc;
          }
        })
      }
    }
  }

  _onSelectedCountry(item) {
    this.selectedCountry = item;
    this.itemMaterialGroups = [];
    this.importMaterial1 = [];
    this.itemMaterials = [];
    if (this.data.collection == 'register_import_stock' || this.data.collection == 'register_local_import_stock') {
      const data = this.data.item.items;
      const dataByCountry = data.filter(m => m.countryKey === this.selectedCountry.countryKey)

      this.submitItem = dataByCountry.map(s => ({ ...s }))
      const b = 12 - this.submitItem.length;
      if (this.submitItem.length <= 12) {
        for (let i = 0; i < b; i++) {
          this.submitItem.push({
            pushFeild1: null,
            pushFeild2: null,
            pushFeild3: null,
          })
        }
      } else {
        this.submitItem = dataByCountry.map(s => ({ ...s }))
      }

      this.registerItems = this.data.item.items;
      if (this.onSelectedItem.key == 2) this.registerItems = this.submitItem;
      if (this.registerItems.length > 0) {
        this.registerItems.forEach(m => {
          if (m.material && m.material.length > 0) {
            this.itemMaterials = this.itemMaterials.concat(m.material);
          }
        })

        this.itemMaterialGroups = MappingService.groupBy(this.itemMaterials, "key", "key");
        const itemArray: Array<any> = MappingService.groupBy(this.itemMaterials, "key", "key");
        const materialArray: Array<any> = MappingService.groupBy(this.itemMaterials, "materialKey", "materialKey");

        materialArray.forEach(m => {
          const materialTotal = itemArray.filter(i => i.materialKey === m.materialKey);
          const total = sum(materialTotal, "qty_NW");
          this.importMaterial1.push({ ...m, total: total })
        })
        if (this.itemMaterials && this.itemMaterials.length > 0) {
          from(this.itemMaterials)
            .pipe(
              groupBy(x => x.materialKey), // using groupBy from Rxjs
              mergeMap(group => group.pipe(toArray())),// GroupBy dont create a array object so you have to flat it
              map(g => {// mapping 
                return {
                  materialKey: g[0].key,
                  materialRef: g[0].materialRef,
                  uomRef: g[0].uomRef,
                  qty_NW: _.sumBy(g, 'qty_NW'), // using lodash to sum quantity
                  totalCost: _.sumBy(g, 'totalCost'),
                  // using lodash to sum price
                }
              }),
              toArray() //.toArray because I guess you want to loop on it with ngFor      
            )

            .subscribe(d => this.groupedData = d);
        }

      }
    }
  }

}
