import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { SettingStore } from 'src/app/stores/settings/setting.store';
import { PrintService } from 'src/app/services/print.service';
import { CommentStore } from 'src/app/stores/comment.store';
import { Auth } from 'src/app/stores/auth.store';
import { FileManagerStore } from 'src/app/stores/filemanager.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { pushToObject } from 'src/app/services/convert.service';

@Component({
  selector: 'app-data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['./data-preview.component.scss']
})
export class DataPreviewComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  @Input() link: string;
  @Input() backlink: string;
  @Input() collection: string;
  @Input() isHide: boolean;
  @Input() isHideComment: boolean;

  @Output() onDelete = new EventEmitter();
 
  ismobile = false;
  material;
  registerImportInvoiceInfo;
  registerImportStock;
  deliveryImportStock;
  constructor(
    public store: SettingStore,
    public ps: PrintService,
    public cm: CommentStore,
    public auth: Auth,
    public fm: FileManagerStore,
    public breakpointObserver: BreakpointObserver,
  ) {

  }
  async ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.ismobile = state.matches;
        } else {
          this.ismobile = state.matches;
        }
      });
   
    if(this.data){
      const {registerItemUsageRef} = this.data
      
      if(registerItemUsageRef){
        const item = pushToObject(await registerItemUsageRef.get())
        this.material  = item.material;
      }
    }

  }

}
