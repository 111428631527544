<aside class="sidebar" *mobxAutorun>
  <div class="sidebar-brand" style="justify-content: space-between;">
    <div class="brand-logo"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
      <img class="logo" src="../../../../assets/images/camwinlogo.jpg" alt="" style="width: 126px;">
    </div>

    <div class="sidebar-toggle-button" (click)="togglesidebar()" *ngIf="isMobile">
      <div class="arrow-icon"></div>
    </div>

  </div>

  <div class="sidebar-list-wrapper" *ngIf="auth?.userRoles">

    <mat-accordion [multi]="false">

      <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)"
        *ngIf="auth?.userRoles | userPermission:'File Manager'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.one">
          <mat-panel-title>
            File Manager
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item" *ngFor="let item of fileMgs">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="[item?.route]">
              <div class="item-name">{{item?.name}}</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)"
        *ngIf="auth?.userRoles | userPermission:'Import Application'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.two">
          <mat-panel-title>
            Import Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_material'">
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/item-registration/register-material']">
              <!-- <div class="icon">
                <i class="material-icons">control_camera</i>
              </div> -->
              <div class="item-name">Register CO Material</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_import_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-invoice']">
              <!-- <div class="icon">
                <i class="material-icons">shop</i>
              </div> -->
              <div class="item-name">Register CO Import INV</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_import_inv_information'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-invoice-info']">
              <!-- <div class="icon">
                <i class="material-icons">shop</i>
              </div> -->
              <div class="item-name">Register CO Import INV Information</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_import_material'">
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/item-registration/register-import-material']">
              <!-- <div class="icon">
                <i class="material-icons">control_camera</i>
              </div> -->
              <div class="item-name">Register Customs Material</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_import_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-import-invoice']">
              <!-- <div class="icon">
                <i class="material-icons">shop</i>
              </div> -->
              <div class="item-name">Register Customs Import INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_master_list'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-master-list']">
           
              <div class="item-name">Register Master List</div>
            </div>
          </div>

        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.three" (closed)="onClosed(3)" (opened)="onOpened(3)"
        *ngIf="auth?.userRoles | userPermission:'Export Application'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.three">
          <mat-panel-title>
            Export Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_export_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-item']">
              <!-- <div class="icon">
                <i class="material-icons">shop</i>
              </div> -->
              <div class="item-name">Register CO Export INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_export_inv_usage'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-export-inv-usage']">
              <!-- <div class="icon">
                <i class="material-icons">shop</i>
              </div> -->
              <div class="item-name">Register CO Export INV Usage</div>
            </div>
          </div>

         


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_export_co'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-co']">
              <!-- <div class="icon">
                <i class="material-icons">how_to_reg</i>
              </div> -->
              <div class="item-name">Register Export CO</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_export_bill'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/pickup-bill']">
              <!-- <div class="icon">
                <i class="material-icons">list_alt</i>
              </div> -->
              <div class="item-name">Register Export Bill</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_export_inspect'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/pickup-inspect']">
              <!-- <div class="icon">
                <i class="material-icons">description</i>
              </div> -->
              <div class="item-name">Register Export Inspect</div>
            </div>
          </div>

        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.four" (closed)="onClosed(4)" (opened)="onOpened(4)"
        *ngIf="auth?.userRoles | userPermission:'Submit Applications'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.four">
          <mat-panel-title>
            Submit Applications
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_soo'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-soo']">
              <!-- <div class="icon">
                <i class="material-icons">spa</i>
              </div> -->
              <div class="item-name">Register SOO</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_soo_statement'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-soo-statement']">
              <!-- <div class="icon">
                <i class="material-icons">spa</i>
              </div> -->
              <div class="item-name">Register SOO Statement</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_submit_form'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-submit-form']">
              <!-- <div class="icon">
                <i class="material-icons">format_indent_increase</i>
              </div> -->
              <div class="item-name">Register Submit Form</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_submit_crf'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-submit-crf']">
              <!-- <div class="icon">
                <i class="material-icons">format_shapes</i>
              </div> -->
              <div class="item-name">Register Submit CRF</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_cancel_co'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-cancel-co']">
              <!-- <div class="icon">
                <i class="material-icons">cancel_presentation</i>
              </div> -->
              <div class="item-name">Register Cancel CO</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_cancel_letter'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/cancel-co-letter']">
              <!-- <div class="icon">
                <i class="material-icons">import_contacts</i>
              </div> -->
              <div class="item-name">Register Cancel Letter</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_other_letter'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/other-letter']">
              <!-- <div class="icon">
                <i class="material-icons">import_contacts</i>
              </div> -->
              <div class="item-name">Register Other Letter</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_letter_form'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/letter-form']">
              <!-- <div class="icon">
                <i class="material-icons">import_contacts</i>
              </div> -->
              <div class="item-name">Register Letter Form</div>
            </div>
          </div>

        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.five" (closed)="onClosed(5)" (opened)="onOpened(5)"
        *ngIf="auth?.userRoles | userPermission:'Return Application'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.five">
          <mat-panel-title>
            Return Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_return'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-return-application']">
              <!-- <div class="icon">
                <i class="material-icons">assignment_return</i>
              </div> -->
              <div class="item-name">Register Return</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_reapply_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/re-apply-register-item']">
              <!-- <div class="icon">
                <i class="material-icons">drafts</i>
              </div> -->
              <div class="item-name">Register Re-apply INV</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_reapply_form'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-re-apply-application']">
              <!-- <div class="icon">
                <i class="material-icons">drafts</i>
              </div> -->
              <div class="item-name">Register Re-apply Form</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_duplicate_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-duplicate-item']">
              <!-- <div class="icon">
                <i class="material-icons">reply_all</i>
              </div> -->
              <div class="item-name">Register Duplicate INV</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_duplicate_form'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-duplicate-application']">
              <!-- <div class="icon">
                <i class="material-icons">reply_all</i>
              </div> -->
              <div class="item-name">Register Duplicate Form</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_reapply_letter'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/re-apply-letter']">
              <!-- <div class="icon">
                <i class="material-icons">import_contacts</i>
              </div> -->
              <div class="item-name">Register Re-apply Letter</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_duplicate_letter'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/duplicate-letter']">
              <!-- <div class="icon">
                <i class="material-icons">import_contacts</i>
              </div> -->
              <div class="item-name">Register Duplicate Letter</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.six" (closed)="onClosed(6)" (opened)="onOpened(6)"
        *ngIf="auth?.userRoles | userPermission:'Stock Application'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.six">
          <mat-panel-title>
            Stock Application
          </mat-panel-title>
          <!-- <mat-panel-description *ngIf="!expansions.six">
                        Adjustment Stock, Register Cut Stock, Register Import Stock
                    </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'inventory_co_balance'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/inventory-balance']">
              <!-- <div class="icon">
                <i class="material-icons">how_to_vote</i>
              </div> -->
              <div class="item-name">Inventory CO Balance</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_ob_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-ob-stock']">
              <!-- <div class="icon">
                <i class="material-icons">folder_open</i>
              </div> -->
              <div class="item-name">Register OB Stock</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_adjusted_over'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-adjustment-stock']">
              <!-- <div class="icon">
                <i class="material-icons">playlist_add_check</i>
              </div> -->
              <div class="item-name">Register Adjusted Over</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_adjusted_under'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-adjustment-stock-decrease']">
              <!-- <div class="icon">
                <i class="material-icons">format_indent_decrease</i>
              </div> -->
              <div class="item-name">Register Adjusted Under</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_import_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-import-stock']">
              <!-- <div class="icon">
                <i class="material-icons">add_box</i>
              </div> -->
              <div class="item-name">Register CO Import Stock</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_co_cut_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-cut-stock']">
              <!-- <div class="icon">
                <i class="material-icons">new_releases</i>
              </div> -->
              <div class="item-name">Register CO Cut Stock</div>
            </div>
          </div>


        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.seven" (closed)="onClosed(7)" (opened)="onOpened(7)"
        *ngIf="(auth?.userRoles | userPermission:'Delivery Application') || (auth?.userRoles | userPermissionByApp:'register_delivery_local_import_stock') || (auth?.userRoles | userPermissionByApp:'local_delivery') || (auth?.userRoles | userPermissionByApp:'local_delivery_cut_stock') ">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.seven">
          <mat-panel-title>
            Delivery Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'delivery_export_inspect'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/delivery-export']">
              <!-- <div class="icon">
                <i class="material-icons">book</i>
              </div> -->
              <div class="item-name">Delivery Export Inspect</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'delivery_return'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/delivery-return-application']">
              <!-- <div class="icon">
                <i class="material-icons">laptop_windows</i>
              </div> -->
              <div class="item-name">Delivery Return</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'delivery_export_co'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-delivery-co-application']">
              <!-- <div class="icon">
                <i class="material-icons">local_shipping</i>
              </div> -->
              <div class="item-name">Delivery Export CO</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'delivery_co_import_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/delivery-import-stock']">
              <!-- <div class="icon">
                <i class="material-icons">assignment</i>
              </div> -->
              <div class="item-name">Delivery CO Import Stock</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'delivery_co_cut_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/delivery-cut-stock']">
              <!-- <div class="icon">
                <i class="material-icons">view_carousel</i>
              </div> -->
              <div class="item-name">Delivery CO Cut Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'local_delivery'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-delivery']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Delivery Local Sale INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'local_delivery_cut_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/local-delivery-cut-stock']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Delivery Local Sale Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_delivery_local_import_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/delivery-local-import-stock']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Delivery Local Purchase Stock</div>
            </div>
          </div>

        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="expansions.ten" (closed)="onClosed(10)" (opened)="onOpened(10)"
        *ngIf="auth?.userRoles | userPermission:'Local Inv Application'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.nine">
          <mat-panel-title>
            Local Sale Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'local_sale_inv'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-inv']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Sale INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'local_sale_inv_usage'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-inv-usage']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Sale INV Usage</div>
            </div>
          </div>



          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'local_cut_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-cut-stock']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Sale Stock</div>
            </div>
          </div>




        </div>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="expansions.eleven" (closed)="onClosed(11)" (opened)="onOpened(11)"
        *ngIf="auth?.userRoles | userPermission:'Local Purchase Inv App'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.eleven">
          <mat-panel-title>
            Local Purchase Application
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_local_invoice'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-invoice']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Purchase INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_local_invoice_info'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-invoice-info']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Purchase INV Information</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'register_local_import_stock'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/register-local-import-stock']">
              <!-- <div class="icon">
                <i class="material-icons">settings_backup_restore</i>
              </div> -->
              <div class="item-name">Local Purchase Stock</div>
            </div>
          </div>

        </div>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="expansions.nine" (closed)="onClosed(9)" (opened)="onOpened(9)"
        *ngIf="auth?.userRoles | userPermission:'Reports'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.nine">
          <mat-panel-title>
            Reports
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_export_co_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/co']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Export CO</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_export_cancel_co_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/cancel-co']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Export Cancel CO</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_soo_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/soo']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">SOO</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_export_inspect_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/inspect']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Export Inspect</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_import_invoice_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/invoice']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Import Invoice</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_export_invoice_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/export-inv']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Export Invoice</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_none_cut_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/c-outstanding']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Outstanding Cut Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_none_local_cut_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/lc-outstanding']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Outstanding Local Sale Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_none_import_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/i-outstanding']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Outstanding Import Stock</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_none_local_import_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/li-outstanding']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Outstanding Local Purchase Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_cut_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/cutstock']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Cut Stock</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_import_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/importstock']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Import Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_local_invoice_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/local-purchase']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Local Purchase INV</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_local_import_stock_report'">
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/reports/preview/local-purchase-stock']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Local Purchase Stock</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_local_sale_inv_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/local-sale']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Local Sale INV</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_local_cut_stock_report'">
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/reports/preview/cutstock-local-sale-inv']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Local Sale Stock</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_duplicate_item_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/duplicate-inv']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Duplicate Invoice</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_reapply_item_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/reapply-inv']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Re-apply Invoice</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_inventory_balance_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/inventory-report']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Inventory CO Balance</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionRole:'read_register_adjust_stock_report'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/reports/preview/adj']">
              <!-- <div class="icon">
                <i class="material-icons">content_paste</i>
              </div> -->
              <div class="item-name">Adjustment Report</div>
            </div>
          </div>


        </div>
      </mat-expansion-panel>




      <mat-expansion-panel [expanded]="expansions.eight" (closed)="onClosed(8)" (opened)="onOpened(8)"
        *ngIf="auth?.userRoles | userPermission:'Administrator'">
        <mat-expansion-panel-header expandedHeight="38px" collapsedHeight="38px" [class.active]="expansions.eight">
          <mat-panel-title>
            Administrator
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="list-content">

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'users'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/user-management/users']">
              <!-- <div class="icon">
                <i class="material-icons">person_outline</i>
              </div> -->
              <div class="item-name">Users</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'user_roles'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/user-management/user-roles']">
              <!-- <div class="icon">
                <i class="material-icons">person_outline</i>
              </div> -->
              <div class="item-name">User Roles</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'company'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/company']">
              <!-- <div class="icon">
                <i class="material-icons">account_balance</i>
              </div> -->
              <div class="item-name">Company</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'departments'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/department']">
              <!-- <div class="icon">
                <i class="material-icons">public</i>
              </div> -->
              <div class="item-name">Departments</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'co_form'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/co-form']">
              <!-- <div class="icon">
                <i class="material-icons">folder_open</i>
              </div> -->
              <div class="item-name">CO Form</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'uom'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/uom']">
              <!-- <div class="icon">
                <i class="material-icons">aspect_ratio</i>
              </div> -->
              <div class="item-name">UOM</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'country'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/country']">
              <!-- <div class="icon">
                <i class="material-icons">outlined_flag</i>
              </div> -->
              <div class="item-name">Country</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'country_port'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/country-port']">
              <!-- <div class="icon">
                <i class="material-icons">language</i>
              </div> -->
              <div class="item-name">Country Port</div>
            </div>
          </div>

          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'application_route'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/route']">
              <!-- <div class="icon">
                <i class="material-icons">local_shipping</i>
              </div> -->
              <div class="item-name">Application Route</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'application_product_type'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/product-type']">
              <!-- <div class="icon">
                <i class="material-icons">all_out</i>
              </div> -->
              <div class="item-name">Application Product Type</div>
            </div>
          </div>
          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'application_title'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/title']">
              <!-- <div class="icon">
                <i class="material-icons">title</i>
              </div> -->
              <div class="item-name">Application Title</div>
            </div>
          </div>


          <div class="list-item" *ngIf="auth?.userRoles | userPermissionByApp:'hs_code'">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/view-and-register/hs-code']">
              <!-- <div class="icon">
                <i class="material-icons">code</i>
              </div> -->
              <div class="item-name">HS Code</div>
            </div>
          </div>
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/options']">
              <!-- <div class="icon">
                <i class="material-icons">tune</i>
              </div> -->
              <div class="item-name">Options</div>
            </div>
          </div>


        </div>
      </mat-expansion-panel>



    </mat-accordion>
  </div>
  <div class="sidebar-footer">
    <div class="signout" (click)="onLogOut()">
      <div class="icon">
        <i class="material-icons"> lock_open </i>
      </div>
      <div class="item-name">
        Sign Out
      </div>
    </div>
    <div class="sidebar-toggle-button" (click)="togglesidebar()">
      <div class="arrow-icon"></div>
    </div>
  </div>
</aside>