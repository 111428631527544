<form class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <!-- <div class="dialog-header">
        <div class="title">
            Filter options
        </div>
    </div> -->
    <div class="dialog-content">
        <div class="form-wrapper" [formGroup]="form">
            <div class="form-group" *ngIf="showBreakdownMaterial">
                <mat-checkbox class="" formControlName="isShowBreakdownMaterial">Show Breakdown Customs Material</mat-checkbox>
            </div>

            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>From date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerFromDate" placeholder="From date"
                        formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>To date</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="pickerToDate" placeholder="To date"
                        formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerToDate></mat-datepicker>

                    <mat-error *ngIf="form.controls['toDate'].hasError('nameAvailable')"
                    class="error-message">
                    To date filter can't be smaller then from date filter.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <app-primary-button (onPress)="_onFilter(form.value)" [disable]="!form.valid" label="Search">
        </app-primary-button>
        <button mat-button class="mat-puc cancel" (click)="clear()">
            Clear
        </button>
    </div>
</form>