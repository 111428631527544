<mat-sidenav-container style="min-height: calc(100vh - 168px)">
    <mat-sidenav #sidenav [mode]="ismobile ? 'over' : 'over'" [opened]="false" [position]="'end'"
        class="content-side" [fixedInViewport]="true" [fixedTopGap]="103" [style.width]="ismobile?'100%':'50%'"
        [fixedBottomGap]="0">
        <button mat-icon-button (click)="sidenav.toggle()"
            style="position: absolute; right: 24px; z-index: 999; top: 4px;">
            <mat-icon class="mat-18">clear</mat-icon>
        </button>
        <mat-tab-group style="height: 100%; ">

            <mat-tab style="height: 100%;">
                <ng-template mat-tab-label>
                    Comment
                </ng-template>
                <div class="comment-body">
                    <div class="comment-list">
                        <div class="chat">
                            <div class="msg" [ngClass]="auth?.user?.key === msg.created_by?.key?'msg-owner':''"
                                *ngFor="let msg of cm?.data;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                            {{ msg?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="msg-item">
                                    <div class="c-content"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        <div class="c-comment" [innerHtml]="msg?.comment"></div>
                                        <div *ngIf="msg?.isEdit" style="display: flex; align-items: center;">
                                            <mat-icon class="mat-18"
                                                style="font-size: 10px; display: flex; align-items: center;">edit
                                            </mat-icon>
                                            <span style="font-size: 10px;">Edited</span>
                                        </div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === msg?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editComment(msg)">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="date"
                                        [ngClass]="auth?.user?.key === msg?.created_by?.key?'msg-owner':''">
                                        {{ msg.created_at?.toDate()|date:'medium' }} ~
                                        {{ msg.created_at?.toDate() | amTimeAgo }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <form class="editor-block" style="position: relative;" [formGroup]="form">
                        <quill-editor [formControl]="comment" [styles]="{height: '150px'}" #editor [modules]="modules"
                            customToolbarPosition="top">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected value="roboto">Roboto</option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>

                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>

                                <span class="ql-formats">

                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-align" [title]="'Aligment2'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                    <select class="ql-color" [title]="'Color'">
                                    </select>
                                    <select class="ql-background" [title]="'BackgroundColor'">
                                    </select>
                                </span>
                                <div class="custom-tb">
                                    <span class="ql-formats">
                                        <button class="ql-bold" [title]="'Bold'"></button>
                                        <button class="ql-italic" [title]="'Italic'"></button>
                                        <button class="ql-underline" [title]="'Underline'"></button>
                                        <button class="ql-strike" [title]="'Strike'"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                    </span>
                                    <button class="mat-button mat-puc comment-btn" [disabled]="!form.valid"
                                        (click)="postComment(form.value, data)">Post
                                    </button>
                                </div>

                            </div>


                        </quill-editor>
                        <div style="height: 68px;"></div>
                    </form>
                </div>
            </mat-tab>

            <mat-tab style="height: 100%; ">
                <ng-template mat-tab-label>
                    Files
                </ng-template>
                <div class="comment-body file-manager">
                    <mat-progress-bar *ngIf="fm?.process && fm?.allPercentage | async as pct" mode="determinate"
                        [value]="pct">
                    </mat-progress-bar>
                    <div class="comment-list">
                        <div class="chat">
                            <div class="file-lists" *ngFor="let file of cm?.files;">
                                <div class="user">
                                    <div class="avatar">
                                        <div class="name"
                                            [ngClass]="auth?.user?.key === file?.created_by?.key?'msg-owner':''">
                                            {{ file?.created_by?.name }}</div>
                                    </div>
                                </div>
                                <div class="fl-item">
                                    <ng-container *ngIf="file?.fileType === 'image'">
                                        <mat-icon class="mat-18">

                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
                                            </svg>
                                        </mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="file?.fileType === 'application'">

                                        <mat-icon class="mat-18">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M9 2.003V2h10.998C20.55 2 21 2.455 21 2.992v18.016a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6-5.997zM5.83 8H9V4.83L5.83 8zM11 4v5a1 1 0 0 1-1 1H5v10h14V4h-8z" />
                                            </svg>
                                        </mat-icon>

                                    </ng-container>
                                    <ng-container *ngIf="file?.fileType === 'audio'">
                                        <mat-icon class="mat-18">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M16 8v2h-3v4.5a2.5 2.5 0 1 1-2-2.45V8h4V4H5v16h14V8h-3zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z" />
                                            </svg>
                                        </mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="file?.fileType === 'video'">
                                        <mat-icon class="mat-18">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 5v14h8V5H8zM4 5v2h2V5H4zm14 0v2h2V5h-2zM4 9v2h2V9H4zm14 0v2h2V9h-2zM4 13v2h2v-2H4zm14 0v2h2v-2h-2zM4 17v2h2v-2H4zm14 0v2h2v-2h-2z" />
                                            </svg>
                                        </mat-icon>
                                    </ng-container>

                                    <a [href]="file?.downloadUrl" target="_blank" style="margin-left: 12px; flex:1">
                                        {{file?.originname}}
                                    </a>

                                    <ng-container *ngIf="auth?.userRoles | userPermissionRole:'delete_file_manager'">
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                            *ngIf="auth?.user?.key === file?.created_by?.key"
                                            aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteFile(file)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Delete</span>
                                            </button>
                                        </mat-menu>
                                    </ng-container>

                                </div>

                                <div class="date" style="font-size: 11px; color: #686868;"
                                    [ngClass]="auth?.user?.key === file.created_by?.key?'msg-owner':''">
                                    {{ file.created_at?.toDate()|date:'medium' }} ~
                                    {{ file.created_at?.toDate() | amTimeAgo }}</div>
                            </div>

                        </div>
                    </div>
                    <div style="padding: 12px; text-align: center;">

                        <form [formGroup]="formUpload">
                            <ng-container *ngIf="auth?.userRoles | userPermissionRole:'create_file_manager'">
                                <a type="button" mat-button class="attach_file upload-button"
                                    (click)="fileInput.click()">
                                    <mat-icon class="mat-18">attach_file</mat-icon>
                                    <span>Add New Attachment</span>
                                    <input #fileInput type="file" multiple
                                        accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rar,.zip' #file
                                        formControlName="file" (change)="onSelectedFile(file.files)"
                                        style="display:none;" />
                                </a>
                            </ng-container>

                        </form>

                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="cs-form-container" *mobxAutorun>
            <div class="form-wrapper" style="padding:33px 0;" id="print">
                <div class="hd-row" style="padding:0 33px 16px 33px">
                    <h4 class="hd-title">{{title}}</h4>
                    <div class="flex-1"></div>
                    <div *ngIf="data">
                        <ng-container *ngIf="!isHide">
                            <div style="display: flex;">
                                <ng-container *ngIf="getStatus(data)">
                                    <button mat-icon-button (click)="printMasterList()" style="margin-right: 8px;"
                                        class="hideOnPrint" matTooltip="Print">
                                        <mat-icon class="mat-18">print</mat-icon>
                                    </button>
                                    <ng-container *ngIf="!(isReturn || isCutStock || isDelivery)">
                                        <button mat-icon-button (click)="delete(data)" style="margin-right: 8px;"
                                            class="hideOnPrint" matTooltip="Delete">
                                            <mat-icon class="mat-18">delete</mat-icon>
                                        </button>
                                    </ng-container>

                                    <button mat-icon-button [routerLink]="link " style="margin-right: 8px;"
                                        class="hideOnPrint" matTooltip="Edit">
                                        <mat-icon class="mat-18">edit</mat-icon>
                                    </button>
                                </ng-container>
                                <button mat-icon-button class="hideOnPrint" matTooltip="app" (click)="sidenav.toggle()">
                                    <mat-icon class="mat-18">insert_comment</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="" style="padding: 33px" *ngIf="!store?.process" #content id="contentToConvert">
                    <ng-container *ngIf="collection == 'pickup_inspect'">
                        <div class="dl-items">
                            <div class="dl-title"> Inspect Number</div>
                            <div class="dl-text">: {{data?.bill_number?data?.bill_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Inspect Date</div>
                            <div class="dl-text" *ngIf="data?.bill_date">: {{data?.bill_date?.toDate() | date}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.bill_date">: </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">Invoice Date</th>
                                        <th class="table-col ">Route</th>
                                        <th class="table-col ">Country</th>
                                        <th class="table-col ">CO Form</th>
                                        <th class="table-col ">Amount</th>
                                        <th class="table-col ">Qty Packing</th>
                                        <th class="table-col ">Qty Unit</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'invoice_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.routeRef | docRef : 'name'  | async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.countryRef | docRef : 'name'  | async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.co_formRef | docRef : 'name'  | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docRef:'amount_FOB' | async | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                            <span *ngIf="data?.qty_packing_UOMRef">

                                                {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}
                                            </span>
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                            <span *ngIf="data?.qty_unit_UOMRef">

                                                {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_soo'">
                        <div class="dl-items">
                            <div class="dl-title"> SOO Number</div>
                            <div class="dl-text">: {{data?.soo_number?data?.soo_number:null}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> SOO Date</div>
                            <div class="dl-text" *ngIf="data?.soo_date">: {{data?.soo_date?.toDate() | date}}</div>
                            <div class="dl-text" *ngIf="!data?.soo_date">: </div>
                        </div>



                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">CO Number</th>
                                        <th class="table-col ">CO Date</th>
                                        <!-- <th class="table-col ">CO Form</th> -->
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">Invoice Date</th>
                                        <th class="table-col ">Amount</th>
                                        <th class="table-col ">Qty Packing</th>
                                        <th class="table-col ">Qty Unit</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'co_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <!-- <td class="table-col">
                                            {{data?.register_itemRef | docDocRef: 'co_formRef' : 'name'  | async}}
                                        </td> -->
                                        <td class="table-col">
                                            {{item?.register_itemRef | docRef:'invoice_number' | async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.register_itemRef | docRefDate:'invoice_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef:'amount_FOB' | async | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                            <span *ngIf="data?.qty_packing_UOMRef">

                                                {{item?.register_itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}
                                            </span>
                                        </td>
                                        <td class="table-col right">
                                            {{item?.register_itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                            <span *ngIf="data?.qty_unit_UOMRef">

                                                {{item?.register_itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_submit_form'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Product Type</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef: 'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef: 'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef: 'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col " rowspan="2">No.</th>
                                        <th class="table-col " colspan="2"> CO Reference</th>
                                        <th class="table-col " rowspan="2"> Quantity Unit</th>
                                        <th class="table-col " rowspan="2"> Amount</th>
                                        <th class="table-col " rowspan="2"> CAT</th>
                                        <th class="table-col " colspan="2"> Invoice</th>
                                        <th class="table-col " colspan="2"> Joint Inspection</th>
                                        <th class="table-col " rowspan="2"> Bill No.</th>
                                        <th class="table-col " rowspan="2"> Destination</th>
                                        <th class="table-col " colspan="2"> Service Fee</th>
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of detailData;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.co_number}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.co_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.qty_unit | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.amount_FOB | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.cat | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_number }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.invoice_date?.toDate() | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.inspect_number }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.inspect_date?.toDate() | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.bill_number }}</td>
                                        <td class="table-col ">

                                            {{item?.countryRef | docRef:'name' | async }}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.admFee | number:'1.' }}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.emfFee | number:'1.'}}
                                        </td>


                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                            <!-- {{detail?.totalCat| number:'1.'}} -->
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col right">
                                            {{detail?.totalADMFee | number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalEMFFee | number:'1.'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_return_application'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}

                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Product</div>
                            <div class="dl-text">:
                                {{data?.productTypeRef | docRef:'name' | async}}

                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Route</div>
                            <div class="dl-text">:
                                {{data?.routeRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col " rowspan="2">No.</th>
                                        <th class="table-col " colspan="2"> CO Reference</th>
                                        <th class="table-col " rowspan="2"> Quantity Unit</th>
                                        <th class="table-col " rowspan="2"> Amount</th>
                                        <th class="table-col " rowspan="2"> CAT</th>
                                        <th class="table-col " colspan="2"> Invoice</th>
                                        <th class="table-col " colspan="2"> Joint Inspection</th>
                                        <th class="table-col " rowspan="2"> Bill No.</th>
                                        <th class="table-col " rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of detailData;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef :'co_number' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td>
                                        <td class="table-col ">

                                            {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                            <!-- {{detail?.totalCat| number:'1.'}} -->
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <!-- <td class="table-col ">
                                            {{detail?.totalADMFee | number:'1.'}}
                                        </td>
                                        <td class="table-col ">
                                            {{detail?.totalEMFFee | number:'1.'}}
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_co_application'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master ">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col " rowspan="2">No.</th>
                                        <th class="table-col " colspan="2"> CO Reference</th>
                                        <th class="table-col " rowspan="2"> Quantity Unit</th>
                                        <th class="table-col " rowspan="2"> Amount</th>
                                        <th class="table-col " rowspan="2"> CAT</th>
                                        <th class="table-col " colspan="2"> Invoice</th>
                                        <!-- <th class="table-col " colspan="2"> Joint Inspection</th>
                                        <th class="table-col " rowspan="2"> Bill No.</th> -->
                                        <th class="table-col " rowspan="2"> Destination</th>
                                        <!-- <th class="table-col " colspan="2"> Service Fee</th> -->
                                    </tr>
                                    <tr class="table-row">
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <!-- <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th> -->
                                        <th class="table-col ">No</th>
                                        <th class="table-col ">Date</th>
                                        <!-- <th class="table-col ">ADM Fee </th>
                                        <th class="table-col ">EMF Fee</th> -->
                                    </tr>
                                </thead>
                                <tbody class="table-body" *ngIf="data?.registerCoItems">
                                    <tr *ngFor="let item of detailData;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef :'co_number' | async}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRefDate:'co_date' | async | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'qty_unit' | async | number:'1.'}}

                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}

                                        </td>
                                        <td class="table-col right">
                                            {{item?.registerItemRef | docRef :'cat' | async | number:'1.'}}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRef:'invoice_number' | async }}

                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerItemRef | docRefDate:'invoice_date' | async | date}}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.pickupInspectionRef| docRef:'bill_number' | async }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupInspectionRef | docRefDate:'bill_date' | async | date}}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.pickupBillRef| docRef:'bill_number' | async }}</td> -->
                                        <td class="table-col ">

                                            {{item?.registerItemRef | docDocRef:'countryRef':'name' | async }}

                                        </td>
                                        <!-- <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'admFee' | async | number:'1.' }}
                                        </td>
                                        <td class="table-col ">
                                            {{item?.registerCoRef | docRef:'emfFee' | async | number:'1.'}}
                                        </td> -->
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-col center" colspan="3">
                                            <strong>Total:</strong>
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalQuantity| number:'1.'}}
                                        </td>
                                        <td class="table-col right">
                                            {{detail?.totalAmount| currency:' ':'code'}}
                                        </td>
                                        <td class="table-col ">
                                            <!-- {{detail?.totalCat| number:'1.'}} -->
                                        </td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <!-- <td class="table-col "></td>
                                        <td class="table-col "></td>
                                        <td class="table-col "></td> -->
                                        <!-- <td class="table-col ">
                                            {{detail?.totalADMFee | number:'1.'}}
                                        </td>
                                        <td class="table-col ">
                                            {{detail?.totalEMFFee | number:'1.'}}
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_delivery_export_document'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Khmer Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Last Update</div>
                            <div class="dl-text" *ngIf="data?.updated_at">:
                                {{data?.updated_at?.toDate() | date:'medium'}}
                            </div>
                            <div class="dl-text" *ngIf="!data?.updated_at">: </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Inspect Number</th>
                                        <th class="table-col ">Inspect Date</th>
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">Invoice Date</th>
                                        <th class="table-col ">Route</th>
                                        <th class="table-col ">Destination</th>
                                        <th class="table-col ">CO Form</th>
                                        <th class="table-col ">Amount</th>
                                        <th class="table-col ">Qty Packing</th>
                                        <th class="table-col ">Qty Unit</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <!-- <tr class="table-row" *ngFor="let item of data?.registerCoItems;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">{{item?.itemRef|docRef:"bill_number"|async}}</td>
                                        <td class="table-col">
                                            {{(item?.itemRef|docRef:"bill_date"|async)?.toDate() | date}}</td>
                                    </tr> -->
                                    <ng-container *ngFor="let data of detailData;let i=index">
                                        <tr class="table-row" *ngFor="let item of data?.inspectItem;let s=index">
                                            <td class="table-col"><span class="dot-status"
                                                    [ngClass]="data?.isSubmit?'s1':''"></span>{{s+1}}</td>
                                            <td class="table-col" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                                                {{data?.itemRef|docRef:'bill_number'|async}}
                                            </td>
                                            <td class="table-col" [rowSpan]="data?.inspectItem?.length" *ngIf="s == 0">
                                                {{data?.itemRef|docRefDate:'bill_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef|docRef:'invoice_number'|async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.routeRef | docRef : 'name'  | async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.countryRef | docRef : 'name'  | async}}
                                            </td>
                                            <td class="table-col">
                                                {{item?.co_formRef | docRef : 'name'  | async}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'amount_FOB' | async | currency:' ':'code'}}
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'qty_packing' | async | number:'1.'}}
                                                <span *ngIf="data?.qty_packing_UOMRef">

                                                    {{item?.itemRef | docDocRef: 'qty_packing_UOMRef' : 'code'  | async}}
                                                </span>
                                            </td>
                                            <td class="table-col right">
                                                {{item?.itemRef | docRef:'qty_unit' | async | number:'1.'}}
                                                <span *ngIf="data?.qty_unit_UOMRef">

                                                    {{item?.itemRef | docDocRef: 'qty_unit_UOMRef' : 'code'  | async}}
                                                </span>
                                            </td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>


                    </ng-container>

                    <ng-container *ngIf="collection == 'register_ob_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNumber}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Date</div>
                            <div class="dl-text">:
                                {{data?.application_date?.toDate() | date}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CO Material</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print"
                            *ngIf="data?.materials?.length>0">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Code</th>
                                        <th class="table-col ">Description</th>
                                        <th class="table-col ">UOM</th>
                                        <th class="table-col ">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <ng-container>
                                        <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                            <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.qty | number}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_adjustment_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> Date</div>
                            <div class="dl-text">:
                                {{data?.application_date?.toDate() | date}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title"> CO Material</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print"
                            *ngIf="data?.materials?.length>0">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Code</th>
                                        <th class="table-col ">Description</th>
                                        <th class="table-col ">UOM</th>
                                        <th class="table-col ">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <ng-container>
                                        <tr class="table-row" *ngFor="let item of data?.materials;let i=index">
                                            <td class="table-col">{{i+1}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'code'|async}}</td>
                                            <td class="table-col">{{item?.materialRef|docRef:'name'|async}}</td>
                                            <td class="table-col">{{item?.uomRef|docRef:'code'|async}}</td>
                                            <td class="table-col right">{{item?.qty | number}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_import_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Application Date</div>
                            <div class="dl-text">:
                                {{data?.application_date?.toDate() | date}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">Title</div>
                            <div class="dl-text">:
                                {{data?.titleRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table"  style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">Invoice Date</th>
                                        <th class="table-col ">Permit Number</th>
                                        <th class="table-col ">Permit Date</th>
                                        <th class="table-col ">Custom Number</th>
                                        <th class="table-col ">Custom Date</th>
                                        <th class="table-col ">Cam Control Number</th>
                                        <th class="table-col ">Cam Control Date</th>
                                        <th class="table-col ">Purchase Number</th>
                                        <th class="table-col ">Export To</th>
                                        <th class="table-col ">CAT</th>
                                        <th class="table-col ">Destination</th>
                                        <th class="table-col ">Qty (Order)</th>
                                        <th class="table-col ">UOM</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.items;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'invoice_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'invoice_date' | async | date}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'permit_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'permit_date' | async | date}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'custom_number'|async}}
                                        </td>

                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'custom_date' | async | date}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'camControl_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docRefDate:'camControl_date' | async | date}}
                                        </td>

                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'purchase_number'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef|docRef:'export_to'|async}}
                                        </td>
                                        <td class="table-col right">

                                            {{item?.itemRef|docRef:'cat'|async}}

                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docDocRef: 'countryRef' : 'name'  | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef|docRef:'qty_order'|async}}
                                        </td>
                                        <td class="table-col">
                                            {{item?.itemRef | docDocRef: 'qty_order_UOMRef' : 'description_en'  | async}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Material</div>
                            <div class="dl-text">:</div>
                        </div>

                        <app-group-stock-material *ngIf="data" [key]="data?.key"></app-group-stock-material>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_cut_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Description</div>
                            <div class="dl-text">:
                                {{data?.description}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title">Application Date</div>
                            <div class="dl-text">:
                                {{data?.application_date?.toDate() | date}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">CO Number</th>
                                        <th class="table-col ">CO Date</th>
                                        <th class="table-col ">Shipping Date</th>
                                        <th class="table-col ">ADM Fee (KHR)</th>
                                        <th class="table-col ">EMF Fee (KHR)</th>
                                        <!-- <th class="table-col ">CO Form</th> -->
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">Invoice Date</th>
                                        <th class="table-col ">CAT</th>
                                        <th class="table-col ">Amount </th>
                                        <th class="table-col ">Qty Packing</th>
                                        <th class="table-col ">Qty Unit</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef| docRefDate:'etd' | async | date}}</td>

                                        <td class="table-col right">{{item?.itemRef|docRef:'admFee'|async}}</td>
                                        <td class="table-col right">{{item?.itemRef|docRef:'emfFee'|async}}</td>

                                        <td class="table-col">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'invoice_number'  | async}}
                                        </td>
                                        <td class="table-col">
                                            {{(item?.itemRef | docDocRef: 'register_itemRef' : 'invoice_date'  | async)?.toDate() | date}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'cat' | async}}</td>

                                        <td class="table-col right">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'amount_FOB' | async}}
                                        </td>


                                        <td class="table-col right">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'qty_packing' | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'qty_unit' | async | number:'1.'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Material</div>
                            <div class="dl-text">:</div>
                        </div>

                        <app-group-cut-stock-material *ngIf="data" [key]="data?.key"></app-group-cut-stock-material>
                    </ng-container>

                    <ng-container *ngIf="collection == 'register_void_stock'">
                        <div class="dl-items">
                            <div class="dl-title"> Reference No.</div>
                            <div class="dl-text">: {{data?.refNo}}</div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Khmer Date</div>
                            <div class="dl-text">:
                                {{data?.khmerDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Lunar Date</div>
                            <div class="dl-text">:
                                {{data?.lunarDate}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">CO Form</div>
                            <div class="dl-text">:
                                {{data?.co_formRef | docRef:'name' | async}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Description</div>
                            <div class="dl-text">:
                                {{data?.description}}
                            </div>
                        </div>
                        <div class="dl-items">
                            <div class="dl-title">Application Date</div>
                            <div class="dl-text">:
                                {{data?.application_date?.toDate() | date}}
                            </div>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> Items</div>
                            <div class="dl-text">:</div>
                        </div>
                        <div class="scrollable-table" style="margin: 0;" id="print">
                            <table class="table-wrapper tb-master">
                                <thead class="table-header">
                                    <tr class="table-row">
                                        <th class="table-col ">No.</th>
                                        <th class="table-col ">CO Number</th>
                                        <th class="table-col ">CO Date</th>
                                        <th class="table-col ">Invoice Number</th>
                                        <th class="table-col ">CAT</th>
                                        <th class="table-col ">Total Qty</th>
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr class="table-row" *ngFor="let item of data?.registerCo;let i=index">
                                        <td class="table-col">{{i+1}}</td>
                                        <td class="table-col">{{item?.itemRef|docRef:'co_number'|async}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef| docRefDate:'co_date' | async | date}}</td>
                                        <td class="table-col">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'invoice_number'  | async}}
                                        </td>
                                        <td class="table-col right">
                                            {{item?.itemRef | docDocRef: 'register_itemRef' : 'cat' | async}}</td>
                                        <td class="table-col right">{{item?.qty}} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="dl-items">
                            <div class="dl-title"> CO Material</div>
                            <div class="dl-text">:</div>
                        </div>

                        <app-group-void-stock *ngIf="data" [key]="data?.key"></app-group-void-stock>
                    </ng-container>

                    <div class="dl-items" *ngIf="data?.status">
                        <div class="dl-title">Status</div>
                        <div class="dl-text">:
                            <div class="data">
                                {{data?.status?.text}}
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="store?.process">
                    <div style="padding: 33px;">
                        <app-line-loading></app-line-loading>
                        <app-line-loading></app-line-loading>
                    </div>
                </ng-container>

            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
